<template>
  <ElRow v-bind="attributes">
    <slot />
  </ElRow>
</template>

<script>
export default {
  props: {
    column: Boolean,
    wrap: Boolean,
    alignStart: Boolean,
    alignEnd: Boolean,
    alignCenter: Boolean,
    justifyStart: Boolean,
    justifyEnd: Boolean,
    justifyCenter: Boolean,
    justifySpaceBetween: Boolean,
    justifySpaceAround: Boolean,
    fillHeight: Boolean,
  },
  computed: {
    attributes () {
      return {
        type: 'flex',
        align: this.align,
        justify: this.justify,
        style: this.styles,
      }
    },
    styles () {
      return {
        'height': this.fillHeight ? '100%' : undefined,
        'flex': '1 1 auto',
        'flex-wrap': this.wrap ? 'wrap' : 'nowrap',
        'flex-direction': this.column ? 'column' : undefined,
        'min-width': 0,
      }
    },
    align () {
      if (this.alignStart) {
        return 'top'
      } else if (this.alignEnd) {
        return 'bottom'
      } else if (this.alignCenter) {
        return 'middle'
      } else {
        return undefined
      }
    },
    justify () {
      if (this.justifyStart) {
        return 'start'
      } else if (this.justifyEnd) {
        return 'end'
      } else if (this.justifyCenter) {
        return 'center'
      } else if (this.justifySpaceBetween) {
        return 'space-between'
      } else if (this.justifySpaceAround) {
        return 'space-around'
      } else {
        return undefined
      }
    },
  },
}
</script>

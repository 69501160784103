import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "sub-title" }
const _hoisted_3 = { class: "lead-items" }
const _hoisted_4 = { class: "theader" }
const _hoisted_5 = { class: "table-row theader-row" }
const _hoisted_6 = { class: "tbody" }
const _hoisted_7 = { class: "table-cell header-cell truncate-text" }
const _hoisted_8 = { class: "truncate-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_BSortText = _resolveComponent("BSortText")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BRadio = _resolveComponent("BRadio")!
  const _component_VWait = _resolveComponent("VWait")!
  const _component_BModalBody = _resolveComponent("BModalBody")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BModalFooter = _resolveComponent("BModalFooter")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('duplicatedList.selectLeadItems.subTitle')), 1)
    ]),
    _createVNode(_component_BModalBody, { style: { padding: 0 } }, {
      default: _withCtx(() => [
        _createVNode(_component_VWait, { for: "fetchingDataWait" }, {
          waiting: _withCtx(() => [
            _withDirectives(_createVNode(_component_BPlaceholder, { "element-loading-background": "transparent" }, null, 512), [
              [_directive_loading, true]
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnHeader, ({key, name}) => {
                    return (_openBlock(), _createBlock(_component_BSortText, {
                      key: key,
                      class: "table-cell header-cell",
                      "item-text": name,
                      "is-display-only": ""
                    }, null, 8, ["item-text"]))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leadItems, ({groupKey, key, label, radioValues}) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `${groupKey}.${key}`,
                    class: "table-row tbody-row"
                  }, [
                    _createElementVNode("div", _hoisted_7, [
                      (groupKey === 'master' && key === 'id')
                        ? (_openBlock(), _createBlock(_component_BTooltip, {
                            key: 0,
                            right: "",
                            content: _ctx.$t('duplicatedList.selectLeadItems.masterDescription')
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(label), 1)
                            ]),
                            _: 2
                          }, 1032, ["content"]))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(_toDisplayString(label), 1)
                          ], 64))
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(radioValues, ({label, displayValue}) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: label,
                        class: "table-cell"
                      }, [
                        _createVNode(_component_BRadio, {
                          label: label,
                          "model-value": _ctx.getSelectedLeadItem(groupKey, key),
                          class: "radio-item",
                          "onUpdate:modelValue": ($event: any) => (_ctx.onChangeSelectLeadItem(groupKey, key, $event))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_8, _toDisplayString(displayValue || '-'), 1)
                          ]),
                          _: 2
                        }, 1032, ["label", "model-value", "onUpdate:modelValue"])
                      ]))
                    }), 128))
                  ]))
                }), 128))
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_BModalFooter, null, {
      default: _withCtx(() => [
        _createVNode(_component_BLayout, {
          class: "mt-200",
          "justify-center": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BBtn, {
              type: "primary",
              disabled: !_ctx.isValidSelectedItems,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click-next')))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.toNext')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "trigger" }
const _hoisted_2 = { class: "trigger-title" }
const _hoisted_3 = {
  key: 0,
  class: "my-50"
}

import { ref } from 'vue';
import { TriggerBody } from '@/api/openapi';

type TProps = {
  waitFor: string;
  editable?: boolean;
  isDisplayOnly?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'TriggerForm',
  props: /*@__PURE__*/_mergeModels({
    waitFor: {},
    editable: { type: Boolean, default: true },
    isDisplayOnly: { type: Boolean, default: false }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const props = __props;
const triggerBody = _useModel<TriggerBody>(__props, "modelValue");

const isNameEditing = ref(false);

const handleUpdateName = async (name: string) => {
  isNameEditing.value = false;
  triggerBody.value.name = name;
};

return (_ctx: any,_cache: any) => {
  const _component_BEditableContent = _resolveComponent("BEditableContent")!
  const _component_VWait = _resolveComponent("VWait")!

  return (_openBlock(), _createBlock(_component_VWait, { for: _ctx.waitFor }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.isDisplayOnly)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(triggerBody.value.name), 1))
            : (_openBlock(), _createBlock(_component_BEditableContent, {
                key: 1,
                editing: isNameEditing.value,
                "onUpdate:editing": _cache[0] || (_cache[0] = ($event: any) => ((isNameEditing).value = $event)),
                uneditable: !props.editable,
                value: triggerBody.value.name,
                placeholder: _ctx.$t('trigger.name'),
                "max-length": 80,
                size: "small",
                "settle-type": "blur",
                required: "",
                "is-display-value-or-place-holder": "",
                onCheck: handleUpdateName
              }, null, 8, ["editing", "uneditable", "value", "placeholder"]))
        ])
      ])
    ]),
    _: 1
  }, 8, ["for"]))
}
}

})
import ElementPlus from 'element-plus'
import locale from 'element-plus/dist/locale/ja.mjs'
import './element-variables.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { App } from 'vue';

export default {
  install(app: App<Element>) {
    app.use(ElementPlus, { locale });
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
      app.component(key, component);
    }
  },
};

/**
 * arrayが同等かどうかを判定します。並び順は考慮しません
 * また、現状numberとstringの配列のみに対応しています
 * ※計算コストがかかるため、大きすぎる配列を入れないでください
 * FIXME: src/services/object-compare-service.ts::isSameObject()の内部処理と比較処理を共通化したい
 * @param a (number|string)[]
 * @param b (number|string)[]
 * @returns boolean
 */
const equals = (a: (number|string)[], b: (number|string)[]): boolean =>
  a.length === b.length && JSON.stringify([...a].sort()) === JSON.stringify([...b].sort())

export {
  equals,
}

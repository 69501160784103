import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "trigger-leads-container" }

import { ref, watch } from 'vue';
import { Trigger, TriggerTarget, TriggerTargetStatus, PaginationMeta, TriggerTargetStatusOverview, TriggerTargetApiGetTriggerTargetsRequest } from '@/api/openapi';
import { TriggerTargetApiService } from '@/api/user/resources/trigger_target';
import LeadLink from '@/components/organisms/user/general/LeadLink.vue';
import InstanceList from '@/components/organisms/user/trigger/InstanceList.vue'
import InstancesSummary from '@/components/organisms/user/trigger/InstancesSummary.vue'
import { TCheck, useCheckAll } from '@/composable/check-all';
import { useWait } from '@/composable/vue-wait';

type TEmits = {
  'after:moveInstanceListPage': [value: number]
  'update:selectedTriggerTargetIds': [values: number[]];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'TriggerLeads',
  props: {
    "modelValue": {},
    "modelModifiers": {},
    "ownerId": { default: null },
    "ownerIdModifiers": {},
    "page": { default: 1 },
    "pageModifiers": {},
  },
  emits: /*@__PURE__*/_mergeModels(["after:moveInstanceListPage", "update:selectedTriggerTargetIds"], ["update:modelValue", "update:ownerId", "update:page"]),
  setup(__props, { emit: __emit }) {

const emit = __emit;

const {
  initializeChecks,
} = useCheckAll();

const trigger = _useModel<Trigger>(__props, "modelValue");
const ownerId = _useModel<number | null>(__props, 'ownerId');
const page = _useModel<number | null>(__props, 'page');

const triggerTargets = ref<TriggerTarget[]>([]);
const paginationMeta = ref<PaginationMeta>(null);
const statusOverview = ref<TriggerTargetStatusOverview>(null);
const selectedStatus = ref<TriggerTargetStatus | null>(null);

watch([
  () => trigger.value,
  () => page.value,
  () => ownerId.value,
  () => selectedStatus.value,
], ([
  newTrigger,
]) => {
  if (newTrigger) fetchTriggerTargets();
})

const api = new TriggerTargetApiService();

const {
  doActionWithWait,
} = useWait();

const fetchTriggerTargets = async () => {
  if (!trigger.value?.id) return;

  await doActionWithWait('fetchTriggerTargetsWait', async () => {
    const request = {
      triggerId: trigger.value.id,
      currentPage: page.value,
    };
    if (ownerId.value) {
      request.ownerId = ownerId.value;
    }
    if (selectedStatus.value) {
      request.status = selectedStatus.value;
    }
    const { data: { triggerTargets: triggerTargetsRes, meta, overview } } = await api.getTriggerTargets({ request });
    triggerTargets.value = triggerTargetsRes;
    paginationMeta.value = meta;
    statusOverview.value = overview;
  });
};

const handleSelectedTriggerTargetIdsUpdate = (values: number[]) => {
  emit('update:selectedTriggerTargetIds', values);
};
const handleAfterMovePage = (value: number) => {
  emit('after:moveInstanceListPage', value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(InstancesSummary, {
      modelValue: selectedStatus.value,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((selectedStatus).value = $event)),
        _ctx.handleChangeStatus
      ],
      "status-overview": statusOverview.value
    }, null, 8, ["modelValue", "status-overview", "onUpdate:modelValue"]),
    _createVNode(InstanceList, {
      modelValue: triggerTargets.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((triggerTargets).value = $event)),
      "pagination-meta": paginationMeta.value,
      page: page.value,
      class: "instance-list",
      "onUpdate:selectedTriggerTargetIds": handleSelectedTriggerTargetIdsUpdate,
      "onAfter:movePage": handleAfterMovePage
    }, null, 8, ["modelValue", "pagination-meta", "page"])
  ]))
}
}

})
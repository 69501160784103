import axios, { AxiosResponse } from 'axios'
import ApiService, { ApiArguments } from '@/api/api_service';

export class OpenAiApiService extends ApiService {

  constructor() {
    super(true);
  }


  generateReplyContentByOpenSearch(
    apiArguments: ApiArguments<{ token: string, originalMail: string, responseMail: string }>,
  ): Promise<
      AxiosResponse<boolean>
    > {
    const { token, originalMail, responseMail } = apiArguments
    const httpInstance = axios.create({
      baseURL: 'https://api.biscuet.jp',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
      },
      cancelToken: token,
    });
    return httpInstance.post(`/email_reply`, { original_mail: originalMail, response_mail: responseMail }, {
      timeout: 120000,
    })
  }

}

<template>
  <BCard>
    <BListItem class="mb-400">
      <template #header>
        <span>{{ $t(`clientLimitSetting.activeUserLimit`) }}</span>
      </template>
      <BInput v-model="inputActiveUserLimit" v-model:valid="activeUserLimitValid" type="number" :rules="[requiredRule]"
        :max-digits="6" :placeholder="$t('clientLimitSetting.placeholder.limitNum')" />
    </BListItem>
    <BListItem class="mb-400">
      <template #header>
        <span>{{ $t(`clientLimitSetting.freeTagLimit`) }}</span>
      </template>
      <BInput v-model="inputFreeTagLimit" v-model:valid="freeTagLimitValid" type="number" :rules="[requiredRule]"
        :max-digits="6" :placeholder="$t('clientLimitSetting.placeholder.limitNum')" />
    </BListItem>
    <BListItem class="mb-400">
      <template #header>
        <span>{{ $t(`clientLimitSetting.customFieldLimit`) }}</span>
      </template>
      <BInput v-model="inputCustomFieldLimit" v-model:valid="customFieldLimitValid" type="number"
        :rules="[requiredRule]" :max-digits="6" :placeholder="$t('clientLimitSetting.placeholder.limitNum')" />
    </BListItem>
    <BListItem class="mb-400">
      <template #header>
        <span>{{ $t('clientLimitSetting.customFieldOptionRegistrationLimit') }}</span>
      </template>
      <BInput v-model="inputCustomFieldOptionRegistrationLimit" v-model:valid="customFieldOptionRegistrationLimitValid" type="number"
        :rules="[requiredRule]" :max-digits="3" :placeholder="$t('clientLimitSetting.placeholder.limitNum')" />
    </BListItem>
    <BListItem class="mb-400">
      <template #header>
        <span>{{ $t('clientLimitSetting.customFieldMultiOptionRegistrationLimit') }}</span>
      </template>
      <BInput v-model="inputCustomFieldMultiOptionRegistrationLimit" v-model:valid="customFieldMultiOptionRegistrationLimitValid" type="number"
        :rules="[requiredRule]" :max-digits="3" :placeholder="$t('clientLimitSetting.placeholder.limitNum') " />
    </BListItem>

    <BListItem class="mb-400">
      <template #header>
        <span>{{ $t('clientLimitSetting.sequenceLimit') }}</span>
      </template>
      <BInput v-model="inputSequenceLimit" v-model:valid="sequenceLimitValid" type="number" :rules="[requiredRule]"
        :max-digits="3" :placeholder="$t('clientLimitSetting.placeholder.limitNum') " />
    </BListItem>

    <BListItem class="mb-400">
      <template #header>
        <span>{{ $t(`clientLimitSetting.leadRegistrationLimit`) }}</span>
      </template>
      <BInput v-model="inputLeadRegistrationLimit" v-model:valid="leadRegistrationLimitValid" type="number"
        :rules="[requiredRule]" :max-digits="6" :placeholder="$t('clientLimitSetting.placeholder.limitNum')" />
    </BListItem>
    <BListItem class="mb-400">
      <template #header>
        <span>{{ $t(`clientLimitSetting.monthlyEmailsLimit`) }}</span>
      </template>
      <BInput v-model="inputMonthlyEmailsLimit" v-model:valid="monthlyEmailsLimitValid" type="number"
        :rules="[requiredRule]" :max-digits="6" :placeholder="$t('clientLimitSetting.placeholder.limitNum')" />
    </BListItem>
    <BListItem class="mb-400">
      <template #header>
        <span>{{ $t(`clientLimitSetting.mailTemplateRegistrationLimit`) }}</span>
      </template>
      <BInput v-model="inputMailTemplateRegistrationLimit" v-model:valid="mailTemplateRegistrationLimitValid"
        type="number" :rules="[requiredRule]" :max-digits="3"
        :placeholder="$t('clientLimitSetting.placeholder.limitNum')" />
    </BListItem>
    <BListItem class="mb-400">
      <template #header>
        <span>{{ $t('clientLimitSetting.leadStageLimit') }}</span>
      </template>
      <BInput v-model="inputLeadStageLimit" v-model:valid="leadStageLimitValid" type="number" :rules="[requiredRule]"
        :max-digits="2" :placeholder="$t('clientLimitSetting.placeholder.limitNum')" />
    </BListItem>
    <BListItem class="mb-400">
      <template #header>
        <span>{{ $t('clientLimitSetting.sequenceLimit') }}</span>
      </template>
      <BInput v-model="inputSequenceLimit" v-model:valid="sequenceLimitValid" type="number" :rules="[requiredRule]"
        :max-digits="3" :placeholder="$t('clientLimitSetting.placeholder.limitNum')" />
    </BListItem>
    <BLayout align-center justify-center>
      <BBtn class="mr-400" text @click="cancel">
        {{ $t(`general.cancel.text`) }}
      </BBtn>
      <BBtn class="ml-400" type="primary" :loading="loading" :disabled="!isValid" @click="save">
        {{ $t(`general.save.text`) }}
      </BBtn>
    </BLayout>
  </BCard>
</template>

<script>
import inputValidation from '@/mixins/input_validation'

export default {
  mixins: [inputValidation],
  props: {
    activeUserLimit: Number,
    freeTagLimit: Number,
    customFieldLimit: Number,
    customFieldOptionRegistrationLimit: Number,
    customFieldMultiOptionRegistrationLimit: Number,
    leadRegistrationLimit: Number,
    monthlyEmailsLimit: Number,
    mailTemplateRegistrationLimit: Number,
    leadStageLimit: Number,
    sequenceLimit: Number,
    loading: Boolean,
  },
  emits: [
    'save',
    'cancel',
  ],
  data () {
    return {
      activeUserLimitValid: false,
      freeTagLimitValid: false,
      customFieldLimitValid: false,
      customFieldOptionRegistrationLimitValid: false,
      customFieldMultiOptionRegistrationLimitValid: false,
      leadRegistrationLimitValid: false,
      monthlyEmailsLimitValid: false,
      mailTemplateRegistrationLimitValid: false,
      leadStageLimitValid: false,
      sequenceLimitValid: false,
      inputActiveUserLimit: null,
      inputFreeTagLimit: null,
      inputCustomFieldLimit: null,
      inputLeadRegistrationLimit: null,
      inputMonthlyEmailsLimit: null,
      inputMailTemplateRegistrationLimit: null,
      inputLeadStageLimit: null,
      inputSequenceLimit: null,
    }
  },
  computed: {
    isValid () {
      return this.activeUserLimitValid
        && this.freeTagLimitValid
        && this.customFieldLimitValid
        && this.customFieldOptionRegistrationLimitValid
        && this.customFieldMultiOptionRegistrationLimitValid
        && this.leadRegistrationLimitValid
        && this.monthlyEmailsLimitValid
        && this.mailTemplateRegistrationLimitValid
        && this.leadStageLimitValid
        && this.sequenceLimitValid
    },
  },
  watch: {
    activeUserLimit (newVal) {
      this.inputActiveUserLimit = newVal
    },
    freeTagLimit (newVal) {
      this.inputFreeTagLimit = newVal
    },
    customFieldLimit (newVal) {
      this.inputCustomFieldLimit = newVal
    },
    customFieldOptionRegistrationLimit(newVal) {
      this.inputCustomFieldOptionRegistrationLimit = newVal
    },
    customFieldMutiOptionRegistrationLimit(newVal) {
      this.inputCustomFieldMultiOptionRegistrationLimit = newVal
    },
    leadRegistrationLimit (newVal) {
      this.inputLeadRegistrationLimit = newVal
    },
    monthlyEmailsLimit (newVal) {
      this.inputMonthlyEmailsLimit = newVal
    },
    mailTemplateRegistrationLimit (newVal) {
      this.inputMailTemplateRegistrationLimit = newVal
    },
    leadStageLimit (newVal) {
      this.inputLeadStageLimit = newVal
    },
    sequenceLimit (newVal) {
      this.inputSequenceLimit = newVal
    },
  },
  created () {
    this.reset()
  },
  methods: {
    save () {
      if (!this.isValid) return
      const clientLimitSetting = {
        activeUserLimit: this.inputActiveUserLimit,
        freeTagLimit: this.inputFreeTagLimit,
        customFieldLimit: this.inputCustomFieldLimit,
        customFieldOptionRegistrationLimit: this.inputCustomFieldOptionRegistrationLimit,
        customFieldMultiOptionRegistrationLimit: this.inputCustomFieldMultiOptionRegistrationLimit,
        leadRegistrationLimit: this.inputLeadRegistrationLimit,
        monthlyEmailsLimit: this.inputMonthlyEmailsLimit,
        mailTemplateRegistrationLimit: this.inputMailTemplateRegistrationLimit,
        leadStageLimit: this.inputLeadStageLimit,
        sequenceLimit: this.inputSequenceLimit,
      }
      this.$emit('save', clientLimitSetting)
    },
    cancel () {
      this.$emit('cancel')
    },
    reset () {
      this.inputActiveUserLimit = this.activeUserLimit
      this.inputFreeTagLimit = this.freeTagLimit
      this.inputCustomFieldOptionRegistrationLimit = this.customFieldOptionRegistrationLimit
      this.inputCustomFieldMultiOptionRegistrationLimit = this.customFieldMultiOptionRegistrationLimit
      this.inputCustomFieldLimit = this.customFieldLimit
      this.inputLeadRegistrationLimit = this.leadRegistrationLimit
      this.inputMonthlyEmailsLimit = this.monthlyEmailsLimit
      this.inputMailTemplateRegistrationLimit = this.mailTemplateRegistrationLimit
      this.inputLeadStageLimit = this.leadStageLimit
      this.inputSequenceLimit = this.sequenceLimit
    },
  },
}
</script>

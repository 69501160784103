import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OAuthCallback = _resolveComponent("OAuthCallback")!

  return (_openBlock(), _createBlock(_component_OAuthCallback, {
    "service-name": _ctx.$t('oauthCallback.service.zoom'),
    "wait-loader": "createZoomSettingWait",
    success: _ctx.success
  }, null, 8, ["service-name", "success"]))
}
<template>
  <div class="b-next-action-label">
    <BPopover
      placement="top"
      width="200"
      trigger="hover"
    >
      <template #reference>
        <BLabel
          round
          type="warning"
        >
          <span class="next-action-name">{{ nextAction.name }}</span>
        </BLabel>
      </template>
      <div>
        <BListItem
          v-if="nextAction.reservedAt"
          class="list-item"
        >
          <template #header>
            <span>{{ $t(`nextAction.reservedAt`) }}</span>
          </template>
          <div>{{ reservedAt }}</div>
        </BListItem>
        <BListItem class="list-item">
          <template #header>
            <span>{{ $t('nextAction.owner') }}</span>
          </template>
          <span>{{ nextActionOwner }}</span>
        </BListItem>
        <BListItem
          v-if="nextAction.content"
          class="list-item"
        >
          <template #header>
            <span>{{ $t(`nextAction.content`) }}</span>
          </template>
          <div>{{ nextAction.content }}</div>
        </BListItem>
      </div>
    </BPopover>
  </div>
</template>

<script>
import { formatShorterDateTime } from '@/utils/date-time';

export default {
  props: {
    nextAction: {
      type: Object,
      default () {
        return {} // { id: xx, actionType: xx, reservedAt: xx, content: xx }
      },
    },
  },
  computed: {
    reservedAt () {
      return formatShorterDateTime(this.nextAction.reservedAt)
    },
    nextActionOwner () {
      return this.nextAction.owner?.name || '-'
    },
  },
}
</script>

<style lang="scss" scoped>
  .b-next-action-label {
    .next-action-name{
      max-width: 192px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .b-next-action-label + .b-next-action-label {
    margin-left: $basespace-100;
  }

  .list-item + .list-item {
    margin-top: $basespace-200;
  }
</style>

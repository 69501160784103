<template>
  <ElDialog
    v-bind="attributes"
    :model-value="isVisible"
  >
    <slot />
  </ElDialog>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
  },
  computed: {
    attributes () {
      return {
        'width': this.width,
        'top': '10vh',
        'close-on-click-modal': false,
        'show-close': false,
      }
    },
  },
}
</script>

import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
  onAuthStateChanged,
  getAuth,
} from 'firebase/auth'

export default class AuthService {
  readonly ROLE_KEY = 'role'
  signinWithEmailAndPassword (email, password) {
    const auth = getAuth()
    return signInWithEmailAndPassword(auth, email, password)
  }
  async signinWithGoogle () {
    try {
      const provider = new GoogleAuthProvider()
      const auth = getAuth()
      await signInWithPopup(auth, provider)
    } catch (error) {
      alert(error.message)
      return Promise.reject(error)
    }
  }
  signout () {
    const auth = getAuth()
    return signOut(auth)
      .then(() => {
        localStorage.removeItem(this.ROLE_KEY)
      })
      .catch(err => {
        alert(err.message)
      })
  }
  signup (email, password) {
    // NOTE: 使われていない
    const auth = getAuth()
    return createUserWithEmailAndPassword(auth, email, password)
      .then(res => {
        alert(`Create account: ${res.user.email}`)
      })
      .catch(err => {
        alert(err.message)
      })
  }
  resetPassword (email) {
    const auth = getAuth()
    return sendPasswordResetEmail(auth, email)
  }
  currentUser () {
    const auth = getAuth()
    return auth.currentUser
  }
  currentRole () {
    return localStorage.getItem(this.ROLE_KEY)
  }
  changeRole (role) {
    localStorage.setItem(this.ROLE_KEY, role)
  }
  async getAuthHeader () {
    const auth = getAuth()
    if (!auth.currentUser) return null
    const token = await auth.currentUser.getIdToken()
    return {
      'Authorization': `Bearer ${token}`,
      'X-Bales-Role': localStorage.getItem(this.ROLE_KEY),
    }
  }
  // 同期的に firebase の auth 結果を待ちたい時に使う
  async authAsync () {
    const auth = getAuth()
    return new Promise((resolve, reject) => {
      onAuthStateChanged(auth, (user)  => {
        resolve(user)
      })
    })
  }
}

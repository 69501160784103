import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BInputDateTime = _resolveComponent("BInputDateTime")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_NextActionFormBody = _resolveComponent("NextActionFormBody")!
  const _component_BCard = _resolveComponent("BCard")!

  return (_openBlock(), _createBlock(_component_BCard, {
    class: "next-action-card",
    small: "",
    background: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BTooltip, {
        top: "",
        content: _ctx.$t('general.delete.text')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BBtn, {
            class: "close-btn",
            size: "small",
            fab: "",
            flat: "",
            onClick: $options.handleCloseNextAction
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BIcon, { size: "small" }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" close ")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["content"]),
      _createVNode(_component_NextActionFormBody, {
        modelValue: $options.internalCommonNextAction,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($options.internalCommonNextAction) = $event)),
        "is-valid": $data.isCommonNextActionValid,
        "onUpdate:isValid": _cache[3] || (_cache[3] = ($event: any) => (($data.isCommonNextActionValid) = $event)),
        "call-target-id": $props.callTargetId,
        "is-short": ""
      }, {
        dateTime: _withCtx(() => [
          _createVNode(_component_BListItem, { class: "mb-200" }, {
            header: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('nextAction.reservedAt')), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_BInputDateTime, {
                date: $options.reservedDate,
                "onUpdate:date": _cache[0] || (_cache[0] = ($event: any) => (($options.reservedDate) = $event)),
                time: $options.reservedTime,
                "onUpdate:time": _cache[1] || (_cache[1] = ($event: any) => (($options.reservedTime) = $event)),
                "is-step": "",
                clearable: ""
              }, null, 8, ["date", "time"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "is-valid", "call-target-id"])
    ]),
    _: 1
  }))
}
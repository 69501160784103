/* global FS */
import smartlookClient from 'smartlook-client'
import AuthService from '@/services/auth_service'
import Waiting from '@/store/waiting'

export default class CurrentUserActions {
  constructor(Api, types) {
    const actions = {
      async getCurrentUserAction({ commit }, args) {
        await waiting.check('getCurrentUserAction', async () => {
          try {
            const res = await Api.getCurrentUser(args)
            new AuthService().changeRole(res.data.currentUser.role)
            if (location.host.match(process.env.VUE_APP_SMARTLOOK_ENABLE_REG)) {
              smartlookClient.identify(res.data.currentUser.id, {
                'id': res.data.currentUser.id,
                'name': res.data.currentUser.name,
                'email': res.data.currentUser.email,
                'clientName': res.data.currentUser.clientName,
              });
            }
            commit(types.SET_CURRENT_USER, Object.values(res.data)[0])
          } catch (err) {
            commit(types.CLEAR_CURRENT_USER)
            throw err
          }
        })
      },
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import {
  CustomActionOptionApi,
  CustomActionOption,
  CustomActionOptionApiPostCustomActionOptionRequest,
  CustomActionOptionApiPutCustomActionOptionRequest,
  CustomActionOptionApiDeleteCustomActionOptionRequest,
} from '@/api/openapi';

export class CustomActionOptionApiService extends ApiService {
  api = new CustomActionOptionApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true)
  }

  async createCustomActionOption({
    request,
    options,
    errorHandlers,
  }: ApiArguments<CustomActionOptionApiPostCustomActionOptionRequest>): Promise<AxiosResponse<CustomActionOption>> {
    return this.checkResponse<CustomActionOption>(
      this.api.postCustomActionOption(request, options),
      errorHandlers,
    );
  }

  async updateCustomActionOption({
    request,
    options,
    errorHandlers,
  }: ApiArguments<CustomActionOptionApiPutCustomActionOptionRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.putCustomActionOption(request, options),
      errorHandlers,
    );
  }

  async deleteCustomActionOption({
    request,
    options,
    errorHandlers,
  }: ApiArguments<CustomActionOptionApiDeleteCustomActionOptionRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.deleteCustomActionOption(request, options),
      errorHandlers,
    );
  }
}

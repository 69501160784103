import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "sequence-apply-modal" }

import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { FailedCallTarget } from '@/api/openapi';
import FailedCallTargetsModal from '@/components/organisms/user/general/modal/FailedCallTargetsModal.vue'
import { TSequenceOwnerValue } from '@/components/organisms/user/general/sequence/SelectSequenceOwner.vue';
import SequenceApply from '@/components/organisms/user/general/sequence/SequenceApply.vue';
import { KEY_OF_MODAL, KEY_OF_TOAST } from '@/injection-keys';
import { bitterAlert } from '@/plugins/BBitterAlert';
import { TBmodalDelegate } from '@/plugins/BModal/BModal.vue'
import LeadSelect from './LeadSelect.vue';

type TProps = {
  modalName: string;
  sequenceMasterId: number;
};
type TStep = 'select' | 'apply';


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceApplyModal',
  props: {
    modalName: {},
    sequenceMasterId: {}
  },
  emits: ['close', 'afterApply'],
  setup(__props: any, { emit: __emit }) {


const emit = __emit;

const i18n = useI18n();
const toast = inject(KEY_OF_TOAST);
const modal = inject(KEY_OF_MODAL);

const step = ref<TStep>('select');
const selectedCallTargetIds = ref<number[]>([]);
const selectedOwnerId = ref<TSequenceOwnerValue>(undefined);
const internalFailedCallTargets = ref<FailedCallTarget[]>([]);

const beforeClose = () => {
  step.value = 'select';
}
const shouldClose = async (): Promise<boolean> => {
  return step.value === 'select' || await bitterAlert.show({
    text: i18n.t('sequence.leadDetail.confirmToCloseModal'),
  });
}
const bModalDelegate = ref<TBmodalDelegate>({
  shouldClose,
  beforeClose,
});

const handleCloseClick = async () => {
  await shouldClose() && closeModal();
};
const handleSelectCallTargetIds = (callTargetIds: number[], ownerId: TSequenceOwnerValue) => {
  selectedCallTargetIds.value = callTargetIds;
  selectedOwnerId.value = ownerId;
  step.value = 'apply';
};
const handleBackToSelect = () => {
  step.value = 'select';
};
const handleAfterApply = (failedCallTargets: FailedCallTarget[]) => {
  emit('afterApply');

  internalFailedCallTargets.value = failedCallTargets;
  if (failedCallTargets.length) {
    modal.show('failedCallTargetsModal')
    return
  }

  toast.show(i18n.t('general.done.of', { action: i18n.t('sequence.of', { item: i18n.t('sequence.lead') }) }))
  closeModal();
};

const closeModal = () => {
  emit('close');
};

return (_ctx: any,_cache: any) => {
  const _component_BModalHeader = _resolveComponent("BModalHeader")!
  const _component_BHeightAdjuster = _resolveComponent("BHeightAdjuster")!
  const _component_BModal = _resolveComponent("BModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BModal, {
      "modal-name": _ctx.modalName,
      width: "1380px",
      height: "880px",
      delegate: bModalDelegate.value
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BHeightAdjuster, null, {
          default: _withCtx(() => [
            (step.value === 'select')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_BModalHeader, {
                    title: _ctx.$t('sequence.selectLead'),
                    onModalClose: handleCloseClick
                  }, null, 8, ["title"]),
                  _createVNode(LeadSelect, {
                    "sequence-master-id": _ctx.sequenceMasterId,
                    onSelectCallTargetIds: handleSelectCallTargetIds
                  }, null, 8, ["sequence-master-id"])
                ], 64))
              : _createCommentVNode("", true),
            (step.value === 'apply')
              ? (_openBlock(), _createBlock(SequenceApply, {
                  key: 1,
                  "call-target-ids": selectedCallTargetIds.value,
                  "owner-id": selectedOwnerId.value,
                  "sequence-master-id": _ctx.sequenceMasterId,
                  onClickClose: handleCloseClick,
                  onAfterApply: handleAfterApply,
                  onBackToSequenceList: handleBackToSelect
                }, null, 8, ["call-target-ids", "owner-id", "sequence-master-id"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(FailedCallTargetsModal, {
          title: _ctx.$t('sequence.message.sequenceRegistrationFailure'),
          "failed-call-targets": internalFailedCallTargets.value
        }, null, 8, ["title", "failed-call-targets"])
      ]),
      _: 1
    }, 8, ["modal-name", "delegate"])
  ]))
}
}

})
import { computed, readonly, ref, toRaw } from 'vue';
import { useI18n } from 'vue-i18n'
import { TriggerEventForm, TriggerEventBody, TriggerEventEventType, TriggerEventEventTypeWithGroup } from '@/api/openapi';
import { TriggerEventFormApiService } from '@/api/user/resources/trigger_event_form';
import { TriggerEventTypeApiService } from '@/api/user/resources/trigger_event_type';
import { useWait } from '@/composable/vue-wait';

const useTriggerEvent = (needsInitialFetch = () => true) => {
  const i18n = useI18n();

  const eventTypes = ref<TriggerEventEventTypeWithGroup[]>([])

  const groupedEventTypes = computed(() => {
    return eventTypes.value.reduce((acc, eventType) => {
      let group = acc.find((group) => group.key === eventType.group);
      if (!group) {
        group = {
          key: eventType.group,
          label: i18n.t(`trigger.eventGroups.${eventType.group}`),
          options: [],
        };
        acc.push(group);
      }
      group.options.push({
        value: eventType.eventType,
        name: i18n.t(`trigger.eventTypes.${eventType.eventType}`),
      });
      return acc;
    }, []);
  });

  const fetchEventTypes = async () => {
    const api = new TriggerEventTypeApiService();
    const { data } = await api.getTriggerEventTypes();
    eventTypes.value = data.triggerEventTypes;
  };

  const makeInitialTriggerEvent = (triggerEvent: TriggerEventBody): TriggerEventBody => {
    return {
      leadViewId: null,
      eventType: null,
      filterParams: {},
      eventDetail: {},
      ...structuredClone(toRaw(triggerEvent) || {}),
    };
  };

  if (needsInitialFetch()) {
    fetchEventTypes();
  }

  return {
    groupedEventTypes: readonly(groupedEventTypes),
    makeInitialTriggerEvent,
  };
};

const useTriggerEventForm = () => {
  const { doActionWithWait, wait } = useWait();

  const forms = ref<TriggerEventForm[]>([]);
  const fetchForms = async (eventType: TriggerEventEventType) => {
    await doActionWithWait('fetchTriggerEventFormsWait', async () => {
      const api = new TriggerEventFormApiService();
      const { data } = await api.getTriggerEventForms({
        request: {
          eventType,
        },
      });
      forms.value = data.triggerEventForms;
    });
  };
  
  const loading = computed(() => {
    return wait.is('fetchTriggerEventFormsWait');
  });

  return {
    loading: readonly(loading),
    forms,
    fetchForms,
  };
};

export {
  useTriggerEvent,
  useTriggerEventForm,
}

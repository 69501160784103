<template>
  <FilterDetailPopover
    :title="title"
    :is-display-only="isDisplayOnly"
    :key-name="keyName"
    @hide="popoverHide"
    @show="popoverShow"
  >
    <template #reference>
      <BLayout
        align-center
      >
        <BListItem>
          <template #header>
            <BLayout
              justify-space-between
            >
              <div>{{ title }}</div>
              <div
                class="grouping-text is-include"
              >
                {{ groupingText }}
              </div>
            </BLayout>
          </template>
          <div>{{ value }}</div>
        </BListItem>
      </BLayout>
    </template>
    <div>
      <BInput
        ref="bInput"
        v-model="inputItem"
        :placeholder="$t('general.search.placeholder')"
      />
    </div>
  </FilterDetailPopover>
</template>

<script>
import lodash from 'lodash'
import FilterDetailPopover from '@/components/organisms/user/leadList/filter/FilterDetailPopover.vue'
import { getIncExc } from '@/utils/object'

export default {
  components: {
    FilterDetailPopover,
  },
  props: {
    leadListFilterParams: {
      type: Object,
      default () {
        return {}
      },
    },
    isDisplayOnly: {
      type: Boolean,
      default: false,
    },
    keyName: {
      type: String,
      require: true,
    },
  },
  emits: [
    'hide',
  ],
  data () {
    return {
      inputItem: '',
    }
  },
  computed: {
    title () {
      return this.$t(`filterSelect.duplicateInfo.item.${this.keyName}`)
    },
    value () {
      if (this.target == null || this.targetDetail.length === 0) return '-'
      return this.targetDetail
    },
    groupingText () {
      if (this.target == null || this.targetDetail.length === 0) return
      return this.$t('general.filter.match')
    },
    target () {
      return this.leadListFilterParams[this.keyName]
    },
    targetDetail () {
      if (this.target == null) return []
      return this.target[this.incExc].or
    },
    incExc () {
      return getIncExc(this.target)
    },
  },
  methods: {
    popoverShow () {
      this.inputItem = lodash.cloneDeep(this.targetDetail)
      // MEMO: フィルター選択後、そのままInputを開いたときにフォーカスが外れるので
      setTimeout(() => this.$refs.bInput.forceFocus(), 200)
    },
    popoverHide () {
      this.$emit('hide', { ['inc']: { or: this.inputItem } })
    },
  },
}
</script>

<style lang="scss" scoped>
  .grouping-text {
    color: $basecolor-cold;

    &.is-include {
      color: $basecolor-hot;
    }
  }
</style>

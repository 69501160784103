<template>
  <div class="filter-control-panel-wrapper">
    <Transition name="fade">
      <div
        v-if="selectedFilterDetailKeyName && filterControlPanelVisible"
        class="filter-background"
      />
    </Transition>
    <div
      class="filter-control-panel"
      :class="classes"
    >
      <BLayout
        class="panel-header"
        align-center
        justify-end
      >
        <div class="header-text fw-bold">
          {{ $t('general.filter.item') }}
        </div>
        <BBtn
          flat
          fab
          size="small"
          @click="hideFilterControlPanelVisible"
        >
          <BIcon>chevron_left</BIcon>
        </BBtn>
      </BLayout>
      <FilterControlPanelBody
        :lead-list-filter-params="leadListFilterParams"
      />
      <div class="panel-footer">
        <BLayout
          align-center
          justify-center
        >
          <BFlex :span="12">
            <BBtn
              text
              @click="resetFilter"
            >
              <span class="footer-text reset">{{
                $t('general.reset.text')
              }}</span>
            </BBtn>
          </BFlex>
        </BLayout>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import lodash from 'lodash'
import { defineComponent } from 'vue'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import FilterControlPanelBody from '@/components/organisms/user/leadList/filter/FilterControlPanelBody.vue';
import queryParameter from '@/mixins/query_parameter'

export default defineComponent({
  name: 'FilterControlPanel',
  components: {
    FilterControlPanelBody,
  },
  mixins: [queryParameter],
  computed: {
    ...mapGetters('userUi', [
      'filterControlPanelVisible',
      'selectedFilterDetailKeyName',
      'leadListFilterParams',
      'leadListPageParams',
    ]),
    classes() {
      return {
        'is-visible': this.isFiltering,
      }
    },
    isFiltering() {
      let isFilterParamExistExceptCurrentPage = false
      const filterParams = lodash.cloneDeep(this.leadListFilterParams)
      // sequencesApplyModalのfilter用対応でsequenceのフィルターはpanel表示しに判定対象にしない
      delete filterParams.sequence_latest_statuses
      for (const key in filterParams) {
        if (key !== 'currentPage') isFilterParamExistExceptCurrentPage = true
      }
      return (
        this.filterControlPanelVisible
          && isFilterParamExistExceptCurrentPage
      )
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      getCallTargetsAction: 'getCallTargetsWait',
      getCallTargetsMetaAction: 'getCallTargetsMetaWait',
    }),
    ...mapWaitingActions('userUi', {
      setFilterControlPanelVisibleAction: 'setFilterControlPanelVisibleWait',
      setLeadListFilterParamsAction: 'setLeadListFilterParamsWait',
      setLeadListPageParamsAction: 'setLeadListPageParamsWait',
    }),
    hideFilterControlPanelVisible() {
      this.setFilterControlPanelVisibleAction(false)
    },
    resetFilter() {
      const filterParams = lodash.cloneDeep(this.leadListFilterParams)
      // sequencesApplyModalのfilter用対応でsequenceのフィルターはクリアしない
      const filterResetParams = {}
      if (filterParams.hasOwnProperty('sequence_latest_statuses')) {
        filterResetParams.sequence_latest_statuses = filterParams.sequence_latest_statuses
      }
      this.hideFilterControlPanelVisible()

      this.deleteAllQueryParameters({})
      this.setLeadListFilterParamsAction(filterResetParams)
      this.setLeadListPageParamsAction(
        Object.assign(lodash.cloneDeep(this.leadListPageParams), { currentPage: 1 }),
      )

      this.getCallTargetsAction()
      this.getCallTargetsMetaAction()
    },
  },
});
</script>

<style lang="scss" scoped>
  .filter-background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.65);
    z-index: 2;
  }

  .filter-control-panel {
    @include m-fixed-width($filter-control-panel-width);
    @include m-fixed-height(100vh);
    position: absolute;
    z-index: 8;
    top: 50px;
    left: 0;
    background-color: $bgcolor-white;
    transition: all 300ms $standard-easing;
    opacity: 0;
    transform: translateX(-($filter-control-panel-width));

    &.is-visible {
      transform: translateX(0);
      transition: all 300ms $standard-easing;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 1px 2px;
      border-right: 1px solid $bdcolor-light;
      opacity: 1;
    }
  }

  .panel-header {
    @include m-fixed-width(100%);
    @include m-fixed-height($filter-control-panel-header-height);
    padding: 0 $basespace-150;
    .header-text {
      text-align: center;
      padding-right: $basespace-500;
    }
  }

  .panel-footer {
    @include m-fixed-height(50px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 $basespace-300;
    text-align: center;
    background-color: $bgcolor-white;

    .footer-text {
      font-size: 12px;
    }
    .reset {
      color: $textcolor-light;
    }
    .save {
      color: $basecolor-primary;
    }
  }
</style>

import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sequence-next-action-container" }
const _hoisted_2 = { class: "mt-100" }
const _hoisted_3 = {
  key: 0,
  class: "time-items"
}
const _hoisted_4 = { class: "mt-100" }
const _hoisted_5 = { class: "mt-100 next-action-content" }

import { computed, nextTick, ref, watch } from 'vue';
import { NextActionTypeOrDefaultType } from '@/api/user/models/next_action_types';
import { formatDateTime } from '@/utils/date-time';
import { TFormNextActionSequenceStep } from './types';

type TProps = {
  sequenceStep?: TFormNextActionSequenceStep;
  isOpen?: boolean;
  nextActionType?: NextActionTypeOrDefaultType;
};
type TEmits = {
  'click:cancel': [];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceNextActionDetail',
  props: {
    sequenceStep: { default: null },
    isOpen: { type: Boolean, default: false },
    nextActionType: { default: null }
  },
  emits: ["click:cancel"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const internalIsOpen = ref(props.isOpen);

const doneAt = computed<string>(() => {
  return formatDateTime(props.sequenceStep?.doneAt);
});

watch(() => props.isOpen, (newValue) => {
  nextTick(() => {
    internalIsOpen.value = newValue;
  })
});

const handleDrawerCancel = () => {
  emit('click:cancel');
};

return (_ctx: any,_cache: any) => {
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BDrawerMenu = _resolveComponent("BDrawerMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.sequenceStep)
      ? (_openBlock(), _createBlock(_component_BDrawerMenu, {
          key: 0,
          modelValue: internalIsOpen.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((internalIsOpen).value = $event)),
          title: _ctx.$t('sequence.step.types.nextAction'),
          onCancel: handleDrawerCancel
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BListItem, { class: "mb-300" }, {
              header: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('nextAction.actionType.title')), 1)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.nextActionType?.name), 1)
              ]),
              _: 1
            }),
            (doneAt.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_BListItem, { class: "mb-300" }, {
                    header: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('nextAction.doneAt')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, _toDisplayString(doneAt.value), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_BListItem, { class: "mb-300" }, {
              header: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('sequence.nextAction.nextActionContent')), 1)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.sequenceStep.nextActionContent), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "title"]))
      : _createCommentVNode("", true)
  ]))
}
}

})
import { types } from './mutations'

export default {
  setSelectedFreeTagInfoAction({ commit }, args) {
    const selectedFreeTagInfo = args
    commit(types.SET_SELECTED_FREE_TAG_INFO, selectedFreeTagInfo)
  },
  isUpdateCallListAction({ commit }, args) {
    const isUpdateCallList = args
    commit(types.IS_UPDATE_CALL_LIST, isUpdateCallList)
  },
  setSelectedCallResultAction({ commit }, args) {
    const selectedCallResult = args
    commit(types.SET_SELECTED_CALL_RESULT, selectedCallResult)
  },
  setSelectedOpportunityAction({ commit }, args) {
    const selectedOpportunity = args
    commit(types.SET_SELECTED_OPPORTUNITY, selectedOpportunity)
  },
  setSelectedNextActionAction({ commit }, args) {
    const selectedNextAction = args
    commit(types.SET_SELECTED_NEXT_ACTION, selectedNextAction)
  },
  setSelectedSequenceInstanceIdAction({ commit }, args) {
    const selectedSequenceInstanceId = args
    commit(types.SET_SELECTED_SEQUENCE_INSTANCE_ID, selectedSequenceInstanceId)
  },
  setZoomPhoneWidgetVisibleAction({ commit }, args) {
    const zoomPhoneWidgetVisible = args
    commit(types.SET_ZOOM_PHONE_WIDGET_VISIBLE, zoomPhoneWidgetVisible)
  },
  setMiitelWidgetVisibleAction({ commit }, args) {
    const miitelWidgetVisible = args
    commit(types.SET_MIITEL_WIDGET_VISIBLE, miitelWidgetVisible)
  },
  setTargetCallResultFormVisibleAction({ dispatch, commit }, args) {
    dispatch('setTargetMenuVisibleResetAction')
    const targetCallResultFormVisible = args
    commit(types.SET_TARGET_CALL_RESULT_FORM_VISIBLE, targetCallResultFormVisible)
  },
  setTargetOpportunityFormVisibleAction({ dispatch, commit }, args) {
    dispatch('setTargetMenuVisibleResetAction')
    const targetOpportunityFormVisible = args
    commit(types.SET_TARGET_OPPORTUNITY_FORM_VISIBLE, targetOpportunityFormVisible)
  },
  setTargetFreeActionFormVisibleAction({ dispatch, commit }, args) {
    dispatch('setTargetMenuVisibleResetAction')
    const targetFreeActionFromVisible = args
    commit(types.SET_TARGET_FREE_ACTION_FORM_VISIBLE, targetFreeActionFromVisible)
  },
  setTargetSequenceInstanceFormVisibleAction({ dispatch, commit }, args) {
    dispatch('setTargetMenuVisibleResetAction')
    const targetSequenceInstanceFormVisible = args
    commit(types.SET_TARGET_SEQUENCE_INSTANCE_FORM_VISIBLE, targetSequenceInstanceFormVisible)
  },
  setTargetSingleNextActionFormVisibleAction({ dispatch, commit }, args) {
    dispatch('setTargetMenuVisibleResetAction')
    const targetSingleNextActionFormVisible = args
    commit(types.SET_TARGET_SINGLE_NEXT_ACTION_FORM_VISIBLE, targetSingleNextActionFormVisible)
  },
  setTargetMailFormVisibleAction({ dispatch, commit }, args) {
    dispatch('setTargetMenuVisibleResetAction')
    const targetMailFormVisible = args
    commit(types.SET_TARGET_MAIL_FORM_VISIBLE, targetMailFormVisible)
  },
  setTargetMenuVisibleResetAction({ commit }) {
    commit(types.SET_TARGET_MENU_VISIBLE_RESET)
  },
  setTargetHeaderMoveButtonDisabledAction({ commit }, args) {
    const targetHeaderMoveButtonDisabled = args
    commit(types.SET_TARGET_HEADER_MOVE_BUTTON_DISABLED, targetHeaderMoveButtonDisabled)
  },
  setLeadListFilterParamsAction({ commit }, args) {
    const leadListFilterParams = args
    commit(types.SET_LEAD_LIST_FILTER_PARAMS, leadListFilterParams)
  },
  setLeadListPageParamsAction({ commit }, args) {
    const leadListPageParams = args
    commit(types.SET_LEAD_LIST_PAGE_PARAMS, leadListPageParams)
  },
  setIsLeadOpenByLeadListAction({ commit }, args) {
    const isLeadOpenByLeadList = args
    commit(types.SET_IS_LEAD_OPEN_BY_LEAD_LIST, isLeadOpenByLeadList)
  },
  setLeadListSelectedColumnsAction({ commit }, args) {
    const leadListSelectedColumns = args
    commit(types.SET_LEAD_LIST_SELECTED_COLUMNS, leadListSelectedColumns)
  },
  resetLeadListSelectedColumnsAction({ commit }) {
    commit(types.SET_LEAD_LIST_SELECTED_COLUMNS, [
      'companyName',
      'targetName',
      'lastActivityAt',
      'lastActivityTypeText',
      'lastActivityComment',
      'nextActions',
      'callTargetCreatedAt',
      'operatorName',
    ])
  },
  setLeadListSortableColumnsAction({ commit }, args) {
    const leadListSortableColumns = args
    commit(types.SET_LEAD_LIST_SORTABLE_COLUMNS, leadListSortableColumns)
  },
  setLeadListColumnWidthsAction({ commit }, args) {
    const leadListColumnWidths = args
    commit(types.SET_LEAD_LIST_COLUMN_WIDTHS, leadListColumnWidths)
  },
  setFilterControlPanelVisibleAction({ commit }, args) {
    const filterControlPanelVisible = args
    commit(types.SET_FILTER_CONTROL_PANEL_VISIBLE, filterControlPanelVisible)
  },
  setSelectedFilterDetailKeyNameAction({ commit }, args) {
    const selectedFilterDetailKeyName = args
    commit(types.SET_SELECTED_FILTER_DETAIL_KEY_NAME, selectedFilterDetailKeyName)
  },
  setLeadListSortKeyAction({ commit }, args) {
    const leadListSortKey = args
    commit(types.SET_LEAD_LIST_SORT_KEY, leadListSortKey)
  },
  setLeadListSortOrderAction({ commit }, args) {
    const leadListSortOrder = args
    commit(types.SET_LEAD_LIST_SORT_ORDER, leadListSortOrder)
  },
  setPendingCsvImportTaskIdsAction({ commit }, args) {
    commit(types.SET_PENDING_CSV_IMPORT_TASK_IDS, args)
  },
  addPendingCsvImportTaskIdAction({ commit }, args) {
    commit(types.ADD_PENDING_CSV_IMPORT_TASK_ID, args)
  },
  setPendingExcelImportTaskIdsAction({ commit }, args) {
    commit(types.SET_PENDING_EXCEL_IMPORT_TASK_IDS, args)
  },
  addPendingExcelImportTaskIdAction({ commit }, args) {
    commit(types.ADD_PENDING_EXCEL_IMPORT_TASK_ID, args)
  },
  setPendingCsvOverwriteTaskIdsAction({ commit }, args) {
    commit(types.SET_PENDING_CSV_OVERWRITE_TASK_IDS, args)
  },
  addPendingCsvOverwriteTaskIdAction({ commit }, args) {
    commit(types.ADD_PENDING_CSV_OVERWRITE_TASK_ID, args)
  },
  setPendingExcelOverwriteTaskIdsAction({ commit }, args) {
    commit(types.SET_PENDING_EXCEL_OVERWRITE_TASK_IDS, args)
  },
  addPendingExcelOverwriteTaskIdAction({ commit }, args) {
    commit(types.ADD_PENDING_EXCEL_OVERWRITE_TASK_ID, args)
  },
  clearLeadListFilterParamsAction({ commit }, args) {
    commit(types.CLEAR_LEAD_LIST_FILTER_PARAMS, args)
  },
  clearLeadListSortKeyAction({ commit }) {
    commit(types.SET_LEAD_LIST_SORT_KEY, 'callTargetCreatedAt')
  },
  clearLeadListSortOrderAction({ commit }) {
    commit(types.SET_LEAD_LIST_SORT_ORDER, 'desc')
  },
  setPendingSfImportTaskIdsAction({ commit }, args) {
    commit(types.SET_PENDING_SF_IMPORT_TASK_IDS, args)
  },
  addPendingSfImportTaskIdAction({ commit }, args) {
    commit(types.ADD_PENDING_SF_IMPORT_TASK_ID, args)
  },
  setDoReloadLeadMergeStatusAction({ commit }, args) {
    commit(types.SET_DO_RELOAD_LEAD_MERGE_STATUS, args)
  },
  setCurrentCallTargetIdAction({ commit }, args) {
    commit(types.SET_CURRENT_CALL_TARGET_ID, args)
  },
  clearCurrentCallTargetIdAction({ commit }) {
    commit(types.SET_CURRENT_CALL_TARGET_ID, null)
  },
  setIsDisabledBIconWithActionMenuCloseAction({ commit }, args) {
    commit(types.SET_IS_DISABLED_B_ICON_WITH_ACTION_MENU_CLOSE, args)
  },
}

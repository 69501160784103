import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-300" }
const _hoisted_2 = {
  key: 0,
  class: "import-confirm-list my-200"
}
const _hoisted_3 = {
  key: 1,
  class: "import-confirm-list my-200"
}

import { ref } from 'vue';
import api from '@/api/admin/resources/salesforce';
  
  type TProps = {
    id: number;
  };

export default /*@__PURE__*/_defineComponent({
  __name: 'SalesforceDescribe',
  props: {
    id: {}
  },
  setup(__props: any) {

const props = __props;
  
const loading = ref<boolean>(false)
const targetObject = ref<string>('')
const describe = ref<object>({})
const describeLayouts = ref<object>({})
  
const errorHandler = (error) => {
  describe.value = {}
  describeLayouts.value = {}
  loading.value = false
}
  
const search = async () => {
  loading.value = true
  describe.value = {}
  describeLayouts.value = {}
  const result = await api.searchSalesforceDescribe({
    targetObject: targetObject.value,
    clientId: props.id,
  }).catch(errorHandler)
  if (result == undefined) return;

  describe.value = JSON.stringify(result.data.salesforce.describe, null, 2)
  describeLayouts.value = JSON.stringify(result.data.salesforce.describeLayouts, null, 2)
  loading.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createBlock(_component_BLayout, {
    column: "",
    class: "my-600"
  }, {
    default: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('import.salesforce.describeTitle')), 1),
      _createVNode(_component_BLayout, { "align-center": "" }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('import.salesforce.nameTargetObject')), 1),
          _createVNode(_component_BInput, {
            modelValue: targetObject.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((targetObject).value = $event)),
            class: "mx-200",
            placeholder: _ctx.$t('general.search.placeholder'),
            disabled: loading.value
          }, null, 8, ["modelValue", "placeholder", "disabled"]),
          _createVNode(_component_BBtn, {
            size: "small",
            type: "primary",
            disabled: loading.value,
            onClick: search
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.search.exec')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      }),
      _createVNode(_component_BLayout, {
        row: "",
        class: "my-600 separate-layout"
      }, {
        default: _withCtx(() => [
          (Object.keys(describe.value).length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('import.salesforce.describeTitle')), 1),
                _createElementVNode("pre", null, _toDisplayString(describe.value), 1)
              ]))
            : _createCommentVNode("", true),
          (Object.keys(describeLayouts.value).length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('import.salesforce.describeLayoutTitle')), 1),
                _createElementVNode("pre", null, _toDisplayString(describeLayouts.value), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})
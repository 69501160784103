import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "header-title" }

import { ref, computed } from 'vue';
import PlaceholderMenu from '@/components/organisms/user/general/PlaceholderMenu.vue';
import { useInsertText } from '@/composable/input';
import BInput from '@/plugins/biscuet-materials/components/atoms/BInput.vue';

type TProps = {
  title: string;
  loading: boolean;
};
type TEmit = {
  'click:draftSave': [];
  'click:confirm': [];
  'click:close': [];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'FormDrawer',
  props: /*@__PURE__*/_mergeModels({
    title: {},
    loading: { type: Boolean }
  }, {
    "isShow": { type: Boolean, ...{ default: false } },
    "isShowModifiers": {},
    "name": { default: '' },
    "nameModifiers": {},
    "content": { default: '' },
    "contentModifiers": {},
    "isValidName": { type: Boolean, ...{ default: false } },
    "isValidNameModifiers": {},
    "isValidContent": { type: Boolean, ...{ default: false } },
    "isValidContentModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["click:draftSave", "click:confirm", "click:close"], ["update:isShow", "update:name", "update:content", "update:isValidName", "update:isValidContent"]),
  setup(__props: any, { emit: __emit }) {


const emit = __emit;

const isShow = _useModel<boolean>(__props, 'isShow');

const name = _useModel<string>(__props, 'name');
const content = _useModel<string>(__props, 'content');
const isValidName = _useModel<boolean>(__props, 'isValidName');
const isValidContent = _useModel<boolean>(__props, 'isValidContent');

const contentRef = ref<typeof BInput | null>(null);
const isPlaceHolderMenuShow = ref<boolean>(false);

const { insertText: insertTextToContent } = useInsertText(contentRef, content);

const isValid = computed(() => {
  return isValidName.value && isValidContent.value;
});

const handleCancelCreateQuickText = async () => {
  emit('click:close');
};
const handleClickDraftSave = async () => {
  emit('click:draftSave');
};
const handleClickConfirm = async () => {
  emit('click:confirm');
};
const handleContentInsertPlaceholder = (placeholder: string) => {
  insertTextToContent(placeholder);
};

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BDrawerMenu = _resolveComponent("BDrawerMenu")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_BDrawerMenu, {
    modelValue: isShow.value,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((isShow).value = $event))
  }, {
    header: _withCtx(() => [
      _createVNode(_component_BLayout, {
        class: "menu-header",
        "align-center": "",
        "justify-space-between": ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
          _createVNode(_component_BTooltip, {
            top: "",
            content: _ctx.$t('general.confirm.info')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BBtn, {
                size: "small",
                type: "primary",
                outline: "",
                loading: _ctx.loading,
                disabled: !isValid.value,
                onClick: handleClickConfirm
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.confirm.moveTo')), 1),
                  _createVNode(_component_BIcon, null, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("keyboard_arrow_right")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["loading", "disabled"])
            ]),
            _: 1
          }, 8, ["content"])
        ]),
        _: 1
      })
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_BLayout, {
        class: "py-300",
        row: "",
        "align-center": "",
        "justify-center": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BBtn, {
            class: "mr-300",
            text: "",
            disabled: _ctx.loading,
            onClick: handleCancelCreateQuickText
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('general.close.text')), 1)
            ]),
            _: 1
          }, 8, ["disabled"]),
          _createVNode(_component_BBtn, {
            class: "ml-300",
            type: "primary",
            loading: _ctx.loading,
            disabled: !isValid.value,
            onClick: handleClickDraftSave
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('general.draftSave.text')), 1)
            ]),
            _: 1
          }, 8, ["loading", "disabled"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_BListItem, { class: "mb-300" }, {
          header: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('quickText.name')), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(BInput, {
              modelValue: name.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((name).value = $event)),
              valid: isValidName.value,
              "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => ((isValidName).value = $event)),
              "max-length": 255,
              placeholder: _ctx.$t('quickText.name'),
              required: "",
              validation: ""
            }, null, 8, ["modelValue", "valid", "placeholder"])
          ]),
          _: 1
        }),
        _createVNode(_component_BListItem, { class: "mb-300 content-wrapper" }, {
          header: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('quickText.content')), 1),
            _createVNode(PlaceholderMenu, {
              modelValue: isPlaceHolderMenuShow.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((isPlaceHolderMenuShow).value = $event)),
              onInsertItem: handleContentInsertPlaceholder
            }, null, 8, ["modelValue"])
          ]),
          default: _withCtx(() => [
            _createVNode(BInput, {
              ref_key: "contentRef",
              ref: contentRef,
              modelValue: content.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((content).value = $event)),
              valid: isValidContent.value,
              "onUpdate:valid": _cache[4] || (_cache[4] = ($event: any) => ((isValidContent).value = $event)),
              "max-length": 65535,
              placeholder: _ctx.$t('quickText.content'),
              rows: 16,
              type: "textarea",
              required: "",
              validation: ""
            }, null, 8, ["modelValue", "valid", "placeholder"])
          ]),
          _: 1
        })
      ])), [
        [_directive_loading, _ctx.loading]
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "navbar-left" }
const _hoisted_2 = { class: "page-title fs-400 fw-bold" }
const _hoisted_3 = { class: "ml-300" }
const _hoisted_4 = { class: "navbar-right" }
const _hoisted_5 = { class: "mt-50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BPagination = _resolveComponent("BPagination")!
  const _component_MailList = _resolveComponent("MailList")!
  const _component_BulkMailForm = _resolveComponent("BulkMailForm")!
  const _component_MailFormTransition = _resolveComponent("MailFormTransition")!
  const _component_OpenaiContentGenerate = _resolveComponent("OpenaiContentGenerate")!
  const _component_BContent = _resolveComponent("BContent")!
  const _directive_loading = _resolveDirective("loading")!

  return (_ctx.isAvailableBulkMailFeature)
    ? _withDirectives((_openBlock(), _createBlock(_component_BContent, {
        key: 0,
        class: "content"
      }, {
        navbar: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('mail.title')), 1),
            _createElementVNode("button", {
              class: "create-btn",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showNewForm && _ctx.showNewForm(...args)))
            }, [
              _createVNode(_component_BIcon, { class: "mail-icon" }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" email ")
                ])),
                _: 1
              }),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('mail.create')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_BPagination, {
              modelValue: _ctx.currentPage,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPage) = $event)),
              length: _ctx.allPageCount,
              "total-visible": 5
            }, null, 8, ["modelValue", "length"])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_MailList, {
              "bulk-mails": _ctx.bulkMails,
              onClick: _ctx.showEditForm,
              onDuplicate: _ctx.showDuplicatedNewForm
            }, null, 8, ["bulk-mails", "onClick", "onDuplicate"]),
            _createVNode(_component_MailFormTransition, { "is-visible": _ctx.formVisible }, {
              default: _withCtx(() => [
                _createVNode(_component_BulkMailForm, {
                  "bulk-mail": _ctx.bulkMail,
                  "onUpdate:bulkMail": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.bulkMail) = $event)),
                  "current-page": _ctx.currentPage,
                  "can-duplicate": true,
                  onCancel: _ctx.cancel,
                  onDuplicate: _ctx.showDuplicatedNewForm,
                  onChange: _ctx.resetOriginalBulkMail,
                  onBulkMailChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.bulkMail = $event))
                }, null, 8, ["bulk-mail", "current-page", "onCancel", "onDuplicate", "onChange"])
              ]),
              _: 1
            }, 8, ["is-visible"]),
            _createVNode(_component_OpenaiContentGenerate, {
              ref: "openai",
              "set-content": _ctx.setOpenAiContent
            }, null, 8, ["set-content"])
          ])
        ]),
        _: 1
      })), [
        [_directive_loading, _ctx.$wait.is(['getBulkMailsWait'])]
      ])
    : _createCommentVNode("", true)
}
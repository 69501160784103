import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "priority" }

import { computed, ref } from 'vue'
import { PriorityPriorityEnum } from '@/api/openapi';
import FilterDetailPopover from '@/components/organisms/user/leadList/filter/FilterDetailPopover.vue'
import FilterItemCheckbox from '@/components/organisms/user/leadList/filter/FilterItemCheckbox.vue'
import { i18nGlobal as i18n } from '@/i18n';
import priorityWithoutNotSet from '@/plugins/biscuet-materials/components/molecules/priority';

type TProps = {
  leadListFilterParams?: Record<string, {
    inc: {
      or: string[];
    };
    exc: {
      or: string[];
    };
  }>;
  isDisplayOnly?: boolean;
  keyName: string;
}
type TEmits = {
  'hide': [value: Record<string, { inc: { or: string[]; }; }>];
}

export default /*@__PURE__*/_defineComponent({
  __name: 'NextActionsPriority',
  props: {
    leadListFilterParams: { default: null },
    isDisplayOnly: { type: Boolean, default: false },
    keyName: {}
  },
  emits: ["hide"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit;
const isInclude = ref(true);
const checkedItem = ref([]);
const isSearchText = ref(false);

const title = computed(() => i18n.t(`filterSelect.nextActions.item.${props.keyName}`));

const candidateItems = computed(() => {
  return priorityWithoutNotSet.map((priority) => ({
    label: '',
    value: priority,
    labelPrefixComponent: 'BPriorityPreview',
    labelPrefixAttributes: { size: 'small', priority, class: 'mr-50' },
  }));
})

const valueForDisplay = computed(() => {
  if (target.value == null || targetDetail.value.length === 0) return '-'
  const values = targetDetail.value.map((item) => {
    return i18n.t(`general.priority.${item}`)
  })
  return values.join(' / ')
})

const target = computed(() => {
  if (props.leadListFilterParams == null) return null
  return props.leadListFilterParams[props.keyName];
})

const targetDetail = computed(() => {
  if (target.value == null) return []
  return target.value.inc.or
})

const popoverShow = () => {
  checkedItem.value = targetDetail.value
  isInclude.value = true
} 

const popoverHide = () => {
  emit('hide', { inc: { or: checkedItem.value } })
}

return (_ctx: any,_cache: any) => {
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BListItem = _resolveComponent("BListItem")!

  return (_openBlock(), _createBlock(FilterDetailPopover, {
    "is-include": isInclude.value,
    "onUpdate:isInclude": _cache[1] || (_cache[1] = ($event: any) => ((isInclude).value = $event)),
    title: title.value,
    "is-display-only": _ctx.isDisplayOnly,
    "key-name": _ctx.keyName,
    onHide: popoverHide,
    onShow: popoverShow
  }, {
    reference: _withCtx(() => [
      _createVNode(_component_BLayout, { "align-center": "" }, {
        default: _withCtx(() => [
          _createVNode(_component_BListItem, null, {
            header: _withCtx(() => [
              _createVNode(_component_BLayout, { "justify-space-between": "" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, _toDisplayString(title.value), 1)
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(valueForDisplay.value), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(FilterItemCheckbox, {
          "checked-item": checkedItem.value,
          "onUpdate:checkedItem": _cache[0] || (_cache[0] = ($event: any) => ((checkedItem).value = $event)),
          "candidate-items": candidateItems.value,
          "is-search-text": isSearchText.value,
          "no-blank-item": ""
        }, null, 8, ["checked-item", "candidate-items", "is-search-text"])
      ])
    ]),
    _: 1
  }, 8, ["is-include", "title", "is-display-only", "key-name"]))
}
}

})
<template>
  <div
    v-if="!isBc && display === 'header'"
    class="biscuet-notification truncate-text"
  >
    <span class="message truncate-text">{{ biscuetMessage }}</span>
    <BBtn
      size="small"
      class="redirect-btn"
      @click="redirectToBc"
    >
      <span>{{ biscuetBtnMessage }}</span>
    </BBtn>
  </div>
</template>

<script>
export default {
  props: {
    display: {
      type: String,
      default: 'header',
    },
  },
  data () {
    return {
      isHide: true,
      biscuetBtnMessage: '【balescloud.jpで開く】',
    }
  },
  computed: {
    biscuetMessage() {
      return `【お知らせ】URLが ${this.slug}.balescloud.jp に切り替わります！`
    },
    isBc() {
      return location.host.match('balescloud.jp')
    },
    slug() {
      return location.hostname.split('.')[0]
    },
  },
  methods: {
    redirectToBc() {
      location.href=`https://${this.slug}.balescloud.jp/app/login`
    },
  },
}
</script>

<style lang="scss" scoped>
.biscuet-notification {
  display: flex;
  width: auto;

  .redirect-btn {
    width: 150px;
    margin-left: 10px;
    margin-right: 10px;
    color: $basecolor-primary !important;
  }

  .message {
    line-height: 35px;
  }
}

.notification {
  position: relative;
  text-align: center;
  padding: $basespace-200 0;
  background-color: $basecolor-success;
  @include m-fixed-height(60px);

  .message {
    color: $textcolor-white;
  }

  .clear-btn {
    position: absolute;
    right: $basespace-400;
  }
}
</style>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SequenceOwner = _resolveComponent("SequenceOwner")!
  const _component_SequenceSteps = _resolveComponent("SequenceSteps")!
  const _component_SendingDrawer = _resolveComponent("SendingDrawer")!
  const _component_BDrawerMenu = _resolveComponent("BDrawerMenu")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_BDrawerMenu, {
    modelValue: _ctx.drawer,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.drawer) = $event)),
    width: "600px",
    class: "sequence-step-form",
    title: _ctx.sequenceInstance?.name,
    onCancel: _ctx.handleCancel
  }, {
    headerAddition: _withCtx(() => [
      _createVNode(_component_SequenceOwner, {
        "owner-name": _ctx.sequenceInstance?.owner?.name,
        "for-sequence-instance": ""
      }, null, 8, ["owner-name"])
    ]),
    default: _withCtx(() => [
      (_ctx.isVisible && !_ctx.isDisplaySendingAnimation)
        ? (_openBlock(), _createBlock(_component_SequenceSteps, {
            key: 0,
            "call-target": _ctx.callTarget,
            "sequence-instance-id": _ctx.sequenceInstance?.id,
            "owner-id": _ctx.sequenceInstance?.owner?.id,
            loading: _ctx.loading,
            onAfterUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('afterUpdate'))),
            onBeforeSend: _ctx.handleBeforeSend,
            onStopSending: _ctx.hideSendingAnimation
          }, null, 8, ["call-target", "sequence-instance-id", "owner-id", "loading", "onBeforeSend", "onStopSending"]))
        : _createCommentVNode("", true),
      (_ctx.isDisplaySendingAnimation)
        ? (_openBlock(), _createBlock(_component_SendingDrawer, {
            key: 1,
            width: 600,
            onSendingAnimationCompleted: _ctx.hideSendingAnimation
          }, null, 8, ["onSendingAnimationCompleted"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "title", "onCancel"])), [
    [_directive_loading, _ctx.loading]
  ])
}
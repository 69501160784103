import lodash from 'lodash'

export default class Waiting {
  constructor (actions) {
    this.waitings = Object.keys(actions).reduce((pre, action) => {
      pre[action] = null
      return pre
    }, {})
    this.waitingWithArgs = {};
  }

  async checkWithArg (action, actionArg, asyncFunc) {
    if (this.waitingWithArgs[action] != null && lodash.isEqual(this.waitingWithArgs[action].actionArg, actionArg)) {
      return this.waitingWithArgs[action].promise
    }
    try {
      const promise = asyncFunc()
      this.waitingWithArgs[action] = { promise, actionArg }
      return await promise
    } finally {
      this.waitingWithArgs[action] = null
    }
  }

  async check (action, asyncFunc) {
    if (this.waitings[action] != null) return this.waitings[action]
    try {
      const promise = asyncFunc()
      this.waitings[action] = promise
      return await promise
    } finally {
      this.waitings[action] = null
    }
  }
}

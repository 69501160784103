import Api from '@/api/admin'


import CallAnalyticsActions from '@/store/common/resources/call_analytics'
import CallListActions from '@/store/common/resources/call_list'
import CallResultActions from '@/store/common/resources/call_result'
import CallTargetActions from '@/store/common/resources/call_target'
import CallTargetFreeTagActions from '@/store/common/resources/call_target_free_tag'
import CallTargetNoteActions from '@/store/common/resources/call_target_note'
import ClientSendgridApiKeysActions from '@/store/common/resources/client_sendgrid_api_key'
import CurrentUserActions from '@/store/common/resources/current_user'
import CustomFieldActions from '@/store/common/resources/custom_field'
import FreeTagActions from '@/store/common/resources/free_tag'
import NextActionActions from '@/store/common/resources/next_action'
import Reminder from '@/store/common/resources/reminder'
import SendgridApiKeysActions from '@/store/common/resources/sendgrid_api_key'
import SendgridSubUserActions from '@/store/common/resources/sendgrid_sub_user'
import UserActions from '@/store/common/resources/user'
import { types } from './mutations'
import adminActions from './resources/admin'
import clientActions from './resources/client'
import ClientAvailableFeature from './resources/client_available_feature'
import ClientLimitSetting from './resources/client_limit_setting'
import ClientSendgridAuth from './resources/client_sendgrid_auth'
import MailVerification from './resources/mail_verification'

const currentUser = new CurrentUserActions(Api, types)
const user = new UserActions(Api, types)
const freeTag = new FreeTagActions(Api, types)
const callList = new CallListActions(Api, types)
const customField = new CustomFieldActions(Api, types)
const callTarget = new CallTargetActions(Api, types)
const callTargetFreeTag = new CallTargetFreeTagActions(Api, types)
const callTargetNote = new CallTargetNoteActions(Api, types)
const callResult = new CallResultActions(Api, types)
const nextAction = new NextActionActions(Api, types)
const reminder = new Reminder(Api, types)
const callAnalytics = new CallAnalyticsActions(Api, types)
const clientLimitSetting = new ClientLimitSetting(Api, types)
const clientAvailableFeature = new ClientAvailableFeature(Api, types)
const sendgridSubUser = new SendgridSubUserActions(Api, types)
const sendgridApiKey = new SendgridApiKeysActions(Api, types)
const clientSendgridApiKey = new ClientSendgridApiKeysActions(Api, types)
const clientSendgridAuth = new ClientSendgridAuth(Api, types)
const mailVerificationAction = new MailVerification(Api, types)

const actions = Object.assign(
  currentUser.actions,
  user.actions,
  freeTag.actions,
  callList.actions,
  customField.actions,
  callTarget.actions,
  callTargetFreeTag.actions,
  callTargetNote.actions,
  callResult.actions,
  nextAction.actions,
  reminder.actions,
  callAnalytics.actions,
  clientLimitSetting.actions,
  clientAvailableFeature.actions,
  sendgridSubUser.actions,
  sendgridApiKey.actions,
  clientSendgridApiKey.actions,
  clientSendgridAuth.actions,
  mailVerificationAction.actions,
  adminActions,
  clientActions,
)

export default actions

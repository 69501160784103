import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pop-up-box" }
const _hoisted_2 = { class: "ml-50 fw-bold" }
const _hoisted_3 = { class: "fw-bold" }
const _hoisted_4 = { class: "sender-group" }
const _hoisted_5 = { class: "header-group" }
const _hoisted_6 = { class: "text mr-100" }

import { computed, getCurrentInstance, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import MailTemplateSelectModal from '@/components/organisms/user/general/modal/MailTemplateSelectModal.vue';
import OpenaiContentGenerate from '@/components/organisms/user/mail/bulk/drawer/state/form/OpenaiContentGenerate.vue';
import MailContentEditor from '@/components/organisms/user/mail/common/drawer/state/form/MailContentEditor.vue';
import { makeQuillContent } from '@/components/organisms/user/mail/common/drawer/state/form/QuillContentEditor.vue';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import { bitterAlert } from '@/plugins/BBitterAlert';
import { TPartialFormDirectMailCommon, SenderData } from '../types';
import MailAddressList from './MailAddressList.vue';

type TProps = {
  modelValue: TPartialFormDirectMailCommon;
  isAvailableMailTemplateFeature: boolean;
  senderData: SenderData | null;
};
type TEmit = {
  'update:modelValue': [value: TPartialFormDirectMailCommon];
  'update:isValid': [valid: boolean];
  'closeOpenai': [cancel: () => void];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'FormDrawerBodyCommon',
  props: {
    modelValue: {},
    isAvailableMailTemplateFeature: { type: Boolean },
    senderData: {}
  },
  emits: ["update:modelValue", "update:isValid", "closeOpenai"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const i18n = useI18n();

const app = getCurrentInstance();
const $modal = app.appContext.config.globalProperties.$modal;

const senderNameDescription = i18n.t('mail.senderNameDescription');
const senderFromAddressDescription = i18n.t('mail.senderAddressDescription');
const { isAvailableAiFeatureToBeReleased } = useAvailableFeatureCheck()


const isReply = computed(() =>
  props.modelValue.replyToReceiveMailId != null,
);

const isValid = computed(() =>
  props.modelValue.subject.length > 0 && props.modelValue.content.length > 0,
);
watch(isValid, (newVal) => {
  emit('update:isValid', newVal);
}, { immediate: true });

const subject = computed({
  get: () => props.modelValue.subject,
  set: (v) => updateFormValues('subject', v),
});
const content = computed({
  get: () => props.modelValue.content,
  set: (v) => updateFormValues('content', v),
});
const mailAttachmentIds = computed({
  get: () => props.modelValue.mailAttachmentIds,
  set: (v) => updateFormValues('mailAttachmentIds', v),
});
const notificationSettingClick = computed({
  get: () => props.modelValue.click,
  set: (v) => updateFormValues('click', v),
});
const notificationSettingOpen = computed({
  get: () => props.modelValue.open,
  set: (v) => updateFormValues('open', v),
});
const sendCc = computed({
  get: () => props.modelValue.sendCc,
  set: (v) => updateFormValues('sendCc', v),
});
const sendBcc = computed({
  get: () => props.modelValue.sendBcc,
  set: (v) => updateFormValues('sendBcc', v),
});

const updateFormValues = (key: keyof TPartialFormDirectMailCommon, value: unknown) => {
  emit('update:modelValue', {
    ...props.modelValue,
    [key]: value,
  });
};
const applyMailTemplate = (mailTemplate: { subject: string, content: string }) => {
  const subject = isReply.value ? props.modelValue.subject : mailTemplate.subject;
  emit('update:modelValue', {
    ...props.modelValue,
    subject,
    content: mailTemplate.content,
  });
  $modal.hide('MailTemplateSelectModal');
};
const setOpenAiContent = (data: string) => {
  updateFormValues('content', makeQuillContent(data));
};

const onTemplateButtonClick = () => {
  if (!props.isAvailableMailTemplateFeature) {
    bitterAlert.show({
      title: i18n.t('general.error'),
      text: i18n.t('mail.mailTemplateIsNotAvailable'),
      closeOnClickOutside: true,
      buttonsCancel: false,
    })
    return
  }
  $modal.show('MailTemplateSelectModal');
};
const handleOpenAiModalShow = () => {
  $modal.show('openai-modal');
};
const handleOpenAiCloseModal = (cancel: () => void) => {
  emit('closeOpenai', cancel);
};

return (_ctx: any,_cache: any) => {
  const _component_BCustomIcon = _resolveComponent("BCustomIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BUnchangedItem = _resolveComponent("BUnchangedItem")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BCheckbox = _resolveComponent("BCheckbox")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BBtn, {
        text: "",
        class: "template-button",
        onClick: onTemplateButtonClick
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BCustomIcon, {
            "icon-class": "b-template",
            type: "accent"
          }),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('mail.applyMailTemplate')), 1)
        ]),
        _: 1
      }),
      (_unref(isAvailableAiFeatureToBeReleased))
        ? (_openBlock(), _createBlock(_component_BBtn, {
            key: 0,
            text: "",
            class: "openai-button",
            onClick: handleOpenAiModalShow
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BIcon, {
                type: "success",
                class: "openai-icon",
                size: "large"
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode(" psychology ")
                ])),
                _: 1
              }),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('openaiGenarate.mailGenerate')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    (_ctx.senderData != null)
      ? (_openBlock(), _createBlock(_component_BListItem, {
          key: 0,
          class: "mb-600"
        }, {
          header: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('mail.sender')), 1)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_BUnchangedItem, {
                class: "name",
                content: _ctx.senderData.senderName,
                "help-content": _unref(senderNameDescription)
              }, null, 8, ["content", "help-content"]),
              _createVNode(_component_BUnchangedItem, {
                class: "address",
                content: _ctx.senderData.senderEmail,
                "help-content": _unref(senderFromAddressDescription)
              }, null, 8, ["content", "help-content"])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(MailAddressList, {
      modelValue: sendCc.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((sendCc).value = $event)),
      label: _ctx.$t('mail.cc')
    }, null, 8, ["modelValue", "label"]),
    _createVNode(MailAddressList, {
      modelValue: sendBcc.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((sendBcc).value = $event)),
      label: _ctx.$t('mail.bcc')
    }, null, 8, ["modelValue", "label"]),
    _withDirectives(_createVNode(MailContentEditor, {
      class: "mb-600",
      subject: subject.value,
      content: content.value,
      "mail-attachment-ids": mailAttachmentIds.value,
      "enable-attachment": true,
      "unsubscribe-visible": false,
      "is-reply": isReply.value,
      onEditSubject: _cache[2] || (_cache[2] = ($event: any) => (subject.value = $event)),
      onEditContent: _cache[3] || (_cache[3] = ($event: any) => (content.value = $event)),
      onEditMailAttachmentIds: _cache[4] || (_cache[4] = ($event: any) => (mailAttachmentIds.value = $event))
    }, null, 8, ["subject", "content", "mail-attachment-ids", "is-reply"]), [
      [_directive_loading, _ctx.$wait.is(['generateContentByOpenSearchActionWait', 'generateReplyContentByOpenSearch'])]
    ]),
    _renderSlot(_ctx.$slots, "default"),
    _createVNode(_component_BListItem, null, {
      header: _withCtx(() => [
        _createElementVNode("span", null, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('mailNotificationEvent.formTitle')), 1),
            _createVNode(_component_BTooltip, { top: "" }, {
              content: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('mailNotificationEvent.description')), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_BIcon, { size: "small" }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode("help")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_BCheckbox, {
          modelValue: notificationSettingOpen.value,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((notificationSettingOpen).value = $event)),
          class: "ml-100 mt-100",
          label: _ctx.$t('mailNotificationEvent.open')
        }, null, 8, ["modelValue", "label"]),
        _createVNode(_component_BCheckbox, {
          modelValue: notificationSettingClick.value,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((notificationSettingClick).value = $event)),
          class: "ml-100 mt-100",
          label: _ctx.$t('mailNotificationEvent.click')
        }, null, 8, ["modelValue", "label"])
      ]),
      _: 1
    }),
    _renderSlot(_ctx.$slots, "sfSync"),
    (_ctx.isAvailableMailTemplateFeature)
      ? (_openBlock(), _createBlock(MailTemplateSelectModal, {
          key: 1,
          height: "85%",
          onClick: applyMailTemplate
        }))
      : _createCommentVNode("", true),
    _createVNode(OpenaiContentGenerate, {
      ref: "openai",
      "set-content": setOpenAiContent,
      onCloseModal: handleOpenAiCloseModal
    }, null, 512)
  ], 64))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "message" }

import { computed, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useGoogleSetting } from '@/composable/user/googleSetting/google-setting';

type TEmit = {
  'click:setting': [];
  'update:setting-error': [error: boolean];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'GoogleNotificationMessage',
  emits: ["click:setting", "update:setting-error"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const i18n = useI18n();
const router = useRouter();
const { googleSetting, fetchGoogleSetting } = useGoogleSetting();

onMounted(async () => {
  await fetchGoogleSetting();
  handleVisibility();
})

const showConnectErrorMessage = computed(() =>
  googleSetting.value?.isGmailImport === false,
);
const connectErrorMessage = computed(() =>
  i18n.t('googleNotification.connectErrorMessage') + i18n.t('general.goSetting'),
);

watch(showConnectErrorMessage, (newValue) => {
  emit('update:setting-error', newValue);
}, { immediate: true });

const showSettingGoogleApi = async () => {
  emit('click:setting')
  router.replace({
    name: 'Setting',
    params: { tab: 'user-setting', subTab: 'user-email' },
  })
};

const handleVisibility = () => {
  document.addEventListener('visibilitychange', async () => {
    if (document.visibilityState === 'visible') {
      await fetchGoogleSetting();
    }
  });
};

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!

  return (showConnectErrorMessage.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "notification-message",
        onClick: showSettingGoogleApi
      }, [
        _createVNode(_component_BIcon, {
          class: "mr-100",
          size: "small",
          type: "error"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" error ")
          ])),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, _toDisplayString(connectErrorMessage.value), 1)
      ]))
    : _createCommentVNode("", true)
}
}

})
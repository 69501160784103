import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "date" }
const _hoisted_2 = { class: "company-name" }
const _hoisted_3 = { class: "subject" }

import { computed } from 'vue';
import { PartialReceivedMail } from '@/api/openapi';
import { ReceivedMailApiService } from '@/api/user/resources/received_mail';
import LeadLink from '@/components/organisms/user/general/LeadLink.vue';
import { formatDate } from '@/utils/date-time';

type TProps = {
  item: PartialReceivedMail;
};
type TEmit = (e: 'afterClickEventSave') => void;


export default /*@__PURE__*/_defineComponent({
  __name: 'ReceivedMailNotificationItem',
  props: {
    item: {}
  },
  emits: ["afterClickEventSave"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const receivedAt = computed(() => {
  return formatDate(props.item.receiveAt)
})

const handleBeforeMoveToLead = async () => {
  const api = new ReceivedMailApiService()
  await api.postReceivedMailClick({
    request: {
      receivedMailId: props.item.id,
    },
  })
  emit('afterClickEventSave');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(LeadLink, {
    "call-target-id": _ctx.item.callTarget.id,
    tag: "div",
    class: "received-mail-notification-item",
    onBeforeMove: handleBeforeMoveToLead
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(receivedAt.value), 1),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.item.callTarget.companyName), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('receivedMailNotification.item.subject', {subject: _ctx.item.subject})), 1)
    ]),
    _: 1
  }, 8, ["call-target-id"]))
}
}

})
<template>
  <BListItem class="mb-600">
    <template #header>
      <span>
        <div class="header-group">
          <span class="text">{{ $t('mail.reserve') }}</span>
          <BCheckbox
            v-model="isSendNow"
            class="ml-100"
            :label="$t('mail.sendNow')"
          />
        </div>
      </span>
    </template>
    <div
      v-if="!isSendNow"
      class="datetime-input-group"
    >
      <BInputDateTime
        v-model:date="internalReservedAt"
        v-model:time="internalReservedAt"
      />
      <p
        v-if="!isValid"
        class="reserved-at-error"
      >
        {{ $t('mail.sendAtError') }}
      </p>
    </div>
  </BListItem>
</template>

<script lang="ts" setup>
import { computed, watch } from 'vue';
import { addMinutesToCurrentDateTime, isDateAfter } from '@/utils/date-time';

type TProps = {
  reservedAt: string | null;
};

const props = defineProps<TProps>();
const emit = defineEmits(['update:isSendNow', 'update:reservedAt', 'update:isValid']);

const internalReservedAt = computed<string>({
  get: () => props.reservedAt,
  set: (value) => emit('update:reservedAt', value),
});
const isValid = computed(() => validReservedAt(internalReservedAt.value));
const isSendNow = computed({
  get: () => !internalReservedAt.value,
  set: (v) => internalReservedAt.value = getDefaultReservedAt(v),
});

const validReservedAt = (reservedAt: string | null) => {
  if (!reservedAt) return true;
  return isDateAfter(reservedAt, addMinutesToCurrentDateTime(5));
};

watch(isValid, (newVal) => {
  emit('update:isValid', newVal)
}, { immediate: true });
const getDefaultReservedAt = (isSendNow: boolean) => isSendNow ? null : addMinutesToCurrentDateTime(30)
</script>

<style scoped lang="scss">
.datetime-input-group {
  max-width: 340px;
}
.reserved-at-error {
  display: block;
  color: $mars;
  margin-top: 10px;
  padding: 10px;
  padding-top: 0px;
}
.header-group {
  display: flex;
  .text {
    margin-right: 10px;
  }
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "b-editable-lead-stage-select" }
const _hoisted_2 = {
  key: 0,
  class: "btn-area"
}

import { computed, nextTick, ref, watch } from 'vue';
import { LeadStage } from '@/api/openapi';

type TProps = {
  modelValue: LeadStage | null;
  leadStages: LeadStage[];
  afterCheck?: (newLeadStage: LeadStage | null) => Promise<void>;
  hideBtnGroup?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BEditableLeadStageSelect',
  props: {
    modelValue: {},
    leadStages: {},
    afterCheck: { type: Function, default: undefined },
    hideBtnGroup: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const selectedLeadStage = (id: number | null): LeadStage | undefined => props.leadStages.find(ls => ls.id === id);
const getSelectedLeadStageId = (leadStage: LeadStage | null): number | null => leadStage ? leadStage.id : null;

const selectedLeadStageId = ref<number | null>(getSelectedLeadStageId(props.modelValue))
const editing = ref(false);
const loading = ref(false);
const refSelect = ref<HTMLElement>(null);

const valueChanged = computed<boolean>(() => {
  return selectedLeadStageId.value !== getSelectedLeadStageId(props.modelValue);
});
watch(props.modelValue, async (newVal) => {
  selectedLeadStageId.value = getSelectedLeadStageId(newVal);
}, { immediate: true });

const handleEdit = () => {
  editing.value = true;
  nextTick(() => refSelect.value.focus());
}
const handleCheck = async () => {
  loading.value = true;
  const newLeadStage = selectedLeadStage(selectedLeadStageId.value) ?? null;
  emit('update:modelValue', newLeadStage);
  try {
    if (props.afterCheck) {
      await props.afterCheck(newLeadStage);
    }
  } finally {
    loading.value = false;
  }
  editing.value = false;
};
const handleClose = () => editing.value = false;

return (_ctx: any,_cache: any) => {
  const _component_BLeadStageSelect = _resolveComponent("BLeadStageSelect")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BLeadStagePreview = _resolveComponent("BLeadStagePreview")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (editing.value)
      ? (_openBlock(), _createBlock(_component_BLayout, {
          key: 0,
          row: "",
          "align-center": "",
          "justify-space-between": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BLeadStageSelect, {
              ref_key: "refSelect",
              ref: refSelect,
              modelValue: selectedLeadStageId.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedLeadStageId).value = $event)),
              "lead-stages": _ctx.leadStages,
              loading: loading.value
            }, null, 8, ["modelValue", "lead-stages", "loading"]),
            (!_ctx.hideBtnGroup)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (valueChanged.value)
                    ? (_openBlock(), _createBlock(_component_BBtn, {
                        key: 0,
                        class: "mr-50",
                        size: "small",
                        fab: "",
                        flat: "",
                        loading: loading.value,
                        onClick: handleCheck
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_BIcon, {
                            class: "check-icon",
                            size: "small"
                          }, {
                            default: _withCtx(() => _cache[1] || (_cache[1] = [
                              _createTextVNode(" check ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["loading"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_BBtn, {
                    size: "small",
                    fab: "",
                    flat: "",
                    disabled: loading.value,
                    onClick: handleClose
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BIcon, {
                        class: "close-icon",
                        size: "small"
                      }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode(" close ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "preview-container",
          onClick: handleEdit
        }, [
          _createVNode(_component_BLeadStagePreview, {
            "lead-stage": _ctx.modelValue,
            "truncate-text": false
          }, null, 8, ["lead-stage"])
        ]))
  ]))
}
}

})
<template>
  <BLayout
    class="member-list-item"
    align-center
    justify-space-between
  >
    <div
      v-for="(column, index) in columnNames"
      :key="column"
      class="table-cell"
      :style="getBodyStyles(columnWidth[index], index)"
    >
      <template v-if="column === 'avatar'">
        <BAvatar
          class="mr-100"
          :size="36"
          :image-url="user.avatarUrl"
          :name="user.name"
          :user-id="user.id"
        />
      </template>
      <template v-else-if="column === 'name'">
        <div class="table-cell-content user-status">
          <div
            v-if="user.isActive"
            class="item active"
          />
          <div
            v-else-if="user.isInviting"
            class="item invited"
          />
          <div
            v-else
            class="item invalid"
          />
          <div
            class="truncate"
            :style="{width: `${columnWidth[index] - 25}px`}"
          >
            {{ user.name }}
          </div>
        </div>
      </template>
      <template v-else-if="column === 'type'">
        <template v-if="isEditing">
          <BLayout>
            <BSelect
              v-model="internalUser.role"
              class="select-user-role"
              :items="roleItems"
              item-text="text"
              item-value="value"
              required
            />
          </BLayout>
          <BLayout
            align-center
            justify-end
          >
            <BBtnSet
              class="ml-300"
              @close="handleEditingCancel"
              @check="handleUpdateUserRole"
            />
          </BLayout>
        </template>
        <template v-else>
          <div class="table-cell-content">
            {{ $t(`user.enum.${user.role}`) }}
          </div>
          <template v-if="$can('owner')">
            <BBtn
              size="small"
              fab
              flat
              @click="handleEditing"
            >
              <BIcon size="small">
                edit
              </BIcon>
            </BBtn>
          </template>
        </template>
      </template>
      <template v-else-if="['email', 'lastName', 'firstName'].includes(column)">
        <div
          class="table-cell-content truncate"
          :style="{width: `${columnWidth[index]}px`}"
        >
          {{ user[column] }}
        </div>
      </template>
      <template v-else-if="column === 'mfaSetting'">
        <ElTag
          class="mfa-setting-status"
          type="info"
          :effect="mfaSetting.status === 'authenticated' ? 'dark' : 'plain'"
        >
          {{ mfaSetting.name }}
        </ElTag>
      </template>
      <template v-else-if="column === 'userId'">
        <div class="table-cell-content user-id-cell">
          {{ user.id }}
        </div>
      </template>
    </div>
    <BLayout
      align-center
    >
      <slot />
    </BLayout>
  </BLayout>
</template>

<script lang="ts" setup>
import { cloneDeep } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { User } from '@/api/openapi'

type TProps = {
  user: User,
  columnNames: string[],
  columnWidth: number[],
};

const props = defineProps<TProps>();
const emit = defineEmits([
  'update',
]);

const i18n = useI18n();
const roleItems = [
  { text: i18n.t('user.enum.owner'), value: 'owner' },
  { text: i18n.t('user.enum.member'), value: 'member' },
];

const internalUser = ref<User | null>(null);
const isEditing = ref(false);

const mfaSetting = computed(() => {
  const status = internalUser.value?.mfaAuthenticated ? 'authenticated' : 'notAuthenticated';

  return {
    status,
    name: i18n.t(`mfaSetting.${status}`),
  };
});

watch(() => props.user, (newVal) => {
  internalUser.value = cloneDeep(newVal);
}, { immediate: true });

const handleEditing = () => {
  isEditing.value = true;
};
const handleEditingCancel = () => {
  isEditing.value = false;
};
const handleUpdateUserRole = () => {
  const updateUser = {
    id: internalUser.value.id,
    role: internalUser.value.role,
  };
  emit('update', updateUser);
  isEditing.value = false;
};
const getBodyStyles = (width: number, index: number) => {
  let style = `width: ${width}px; min-width: ${width}px; max-width: ${width}px;`;
  if (index === 0) style += 'position:sticky;left:0;z-index:1;';
  return style;
};
</script>

<style lang="scss" scoped>
.member-list-item {
  padding: $basespace-100 $basespace-600 $basespace-100 12px;
  border-bottom: 1px solid $bdcolor-base;

  &:hover {
    background: $bgcolor-dark;
  }
}

.select-user-role {
  width: 130px;
  max-width: 130px;
}

.user-status {
  display: flex;
  align-items: center;
}

.item {
  display: flex;
  align-items: center;

  &::before {
    content: '';
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 8px;
    background-color: $basecolor-success;
  }

  &.invited::before {
    background-color: $basecolor-warning;
  }

  &.invalid::before {
    background-color: $basecolor-error;
  }
}

.table-cell {
  display: flex;
  align-items: center;
  padding-right: $basespace-200;
  padding-left: 10px;
  min-width: 80px;
  @include m-fixed-height(40px);
  background-color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  .table-cell-content {
    font-weight: 700!important;
  }
}

.user-id-cell {
  margin-left: 30px;
}

.truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mfa-setting-status.el-tag--info {
  &.el-tag--dark {
    --el-tag-bg-color: #{$textcolor-light};
    --el-tag-border-color: #{$textcolor-light};
  }
  &.el-tag--plain {
    --el-tag-border-color: #{$textcolor-light};
    --el-tag-text-color: #{$textcolor-light};
  }
}
</style>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "message mb-600" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "before-change mb-600" }
const _hoisted_4 = { class: "after-change mb-600" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "before-change mb-600" }
const _hoisted_7 = { class: "after-change mb-600" }
const _hoisted_8 = {
  key: 0,
  class: "sub-title"
}
const _hoisted_9 = {
  key: 1,
  class: "sub-title"
}
const _hoisted_10 = {
  key: 2,
  class: "sub-title"
}
const _hoisted_11 = {
  key: 3,
  class: "sub-title"
}
const _hoisted_12 = {
  key: 4,
  class: "sub-title"
}
const _hoisted_13 = {
  key: 5,
  class: "sub-title"
}
const _hoisted_14 = {
  key: 6,
  class: "sub-title"
}
const _hoisted_15 = {
  key: 7,
  class: "sub-title"
}
const _hoisted_16 = { class: "before-change-filter-panel mb-600" }
const _hoisted_17 = { class: "before-change mb-600" }
const _hoisted_18 = { class: "after-change-filter-panel mb-600" }
const _hoisted_19 = { class: "after-change mb-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BModalHeader = _resolveComponent("BModalHeader")!
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BTextarea = _resolveComponent("BTextarea")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BSortText = _resolveComponent("BSortText")!
  const _component_BEmptyBox = _resolveComponent("BEmptyBox")!
  const _component_CustomFieldItem = _resolveComponent("CustomFieldItem")!
  const _component_BTabs = _resolveComponent("BTabs")!
  const _component_VWait = _resolveComponent("VWait")!
  const _component_BModalBody = _resolveComponent("BModalBody")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BModalFooter = _resolveComponent("BModalFooter")!
  const _component_BHeightAdjuster = _resolveComponent("BHeightAdjuster")!
  const _component_BModal = _resolveComponent("BModal")!

  return (_openBlock(), _createBlock(_component_BModal, {
    "modal-name": _ctx.modalName,
    width: _ctx.modalWidth,
    height: _ctx.modalHeight
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BHeightAdjuster, null, {
        default: _withCtx(() => [
          _createVNode(_component_BModalHeader, {
            title: _ctx.$t('leadView.title'),
            onModalClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$modal.hide(_ctx.modalName)))
          }, null, 8, ["title"]),
          _createVNode(_component_BModalBody, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('leadView.modalMessage')), 1),
              _createVNode(_component_VWait, { for: "getLeadViewActionWait" }, {
                default: _withCtx(() => [
                  _createVNode(_component_BTabs, {
                    position: "left",
                    "label-items": _ctx.tabLabelItems
                  }, {
                    form: _withCtx(() => [
                      _createVNode(_component_BLayout, { column: "" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_BListItem, null, {
                            header: _withCtx(() => [
                              _createElementVNode("div", null, _toDisplayString(_ctx.$t('leadView.name' )), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_BInput, {
                                modelValue: _ctx.leadViewName,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.leadViewName) = $event)),
                                valid: _ctx.valid,
                                "onUpdate:valid": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.valid) = $event)),
                                autofocus: "",
                                rules: [_ctx.requiredRule]
                              }, null, 8, ["modelValue", "valid", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_BListItem, { class: "mt-200" }, {
                            header: _withCtx(() => [
                              _createElementVNode("div", null, _toDisplayString(_ctx.$t('leadView.description' )), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_BTextarea, {
                                modelValue: _ctx.leadViewDescription,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.leadViewDescription) = $event)),
                                resize: "none",
                                autosize: { minRows: 4, maxRows: 10 }
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    sort: _withCtx(() => [
                      (_ctx.isSorterdColumnsChanged)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createVNode(_component_BLayout, null, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_3, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.beforeChange')), 1)
                                ]),
                                _createElementVNode("div", _hoisted_4, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.afterChange')), 1)
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_BLayout, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_BSortText, {
                                  class: "sort-box",
                                  "order-by": _ctx.leadView.sortOrder,
                                  "item-text": _ctx.getColumnName(_ctx.leadView.sortKey),
                                  "is-active": "",
                                  "is-display-only": ""
                                }, null, 8, ["order-by", "item-text"]),
                                _createVNode(_component_BSortText, {
                                  class: "sort-box",
                                  "order-by": _ctx.leadListSortOrder,
                                  "item-text": _ctx.getColumnName(_ctx.leadListSortKey),
                                  "is-active": "",
                                  "is-display-only": ""
                                }, null, 8, ["order-by", "item-text"])
                              ]),
                              _: 1
                            })
                          ]))
                        : (_openBlock(), _createBlock(_component_BEmptyBox, {
                            key: 1,
                            class: "pt-500 pb-500",
                            "display-only": ""
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.noChange')), 1)
                            ]),
                            _: 1
                          }))
                    ]),
                    filter: _withCtx(() => [
                      (_ctx.isFilterParamsChanged)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createVNode(_component_BLayout, null, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_6, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.beforeChange')), 1)
                                ]),
                                _createElementVNode("div", _hoisted_7, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.afterChange')), 1)
                                ])
                              ]),
                              _: 1
                            }),
                            (_ctx.filteredSystemItemComponentNames.length > 0)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t('filterSelect.systemInfo.title')), 1))
                              : _createCommentVNode("", true),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredSystemItemComponentNames, ([ key, value ]) => {
                              return (_openBlock(), _createBlock(_component_BLayout, { key: key }, {
                                default: _withCtx(() => [
                                  (_ctx.isOldExistFilterParam(value))
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
                                        key: 0,
                                        title: _ctx.$t(`filterSelect.systemInfo.item.${value}`),
                                        class: "filter-box",
                                        "lead-list-filter-params": _ctx.filterParams,
                                        "is-display-only": "",
                                        "key-name": value
                                      }, null, 8, ["title", "lead-list-filter-params", "key-name"]))
                                    : (_ctx.isNewExistFilterParam(value))
                                      ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                                          key: 1,
                                          class: "filter-b-empty-box",
                                          "display-only": ""
                                        }))
                                      : _createCommentVNode("", true),
                                  (_ctx.isNewExistFilterParam(value))
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
                                        key: 2,
                                        title: _ctx.$t(`filterSelect.systemInfo.item.${value}`),
                                        class: "filter-box",
                                        "lead-list-filter-params": _ctx.leadListFilterParams,
                                        "is-display-only": "",
                                        "key-name": value
                                      }, null, 8, ["title", "lead-list-filter-params", "key-name"]))
                                    : (_ctx.isOldExistFilterParam(value))
                                      ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                                          key: 3,
                                          class: "filter-b-empty-box",
                                          "display-only": ""
                                        }))
                                      : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128)),
                            (_ctx.filteredCompanyItemComponentNames.length > 0)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t('filterSelect.companyInfo.title')), 1))
                              : _createCommentVNode("", true),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredCompanyItemComponentNames, ([ key, value ]) => {
                              return (_openBlock(), _createBlock(_component_BLayout, { key: key }, {
                                default: _withCtx(() => [
                                  (_ctx.isOldExistFilterParam(value))
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
                                        key: 0,
                                        class: "filter-box",
                                        title: _ctx.$t(`filterSelect.companyInfo.item.${value}`),
                                        "lead-list-filter-params": _ctx.filterParams,
                                        "is-display-only": "",
                                        "key-name": value
                                      }, null, 8, ["title", "lead-list-filter-params", "key-name"]))
                                    : (_ctx.isNewExistFilterParam(value))
                                      ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                                          key: 1,
                                          class: "filter-b-empty-box",
                                          "display-only": ""
                                        }))
                                      : _createCommentVNode("", true),
                                  (_ctx.isNewExistFilterParam(value))
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
                                        key: 2,
                                        class: "filter-box",
                                        title: _ctx.$t(`filterSelect.companyInfo.item.${value}`),
                                        "lead-list-filter-params": _ctx.leadListFilterParams,
                                        "is-display-only": "",
                                        "key-name": value
                                      }, null, 8, ["title", "lead-list-filter-params", "key-name"]))
                                    : (_ctx.isOldExistFilterParam(value))
                                      ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                                          key: 3,
                                          class: "filter-b-empty-box",
                                          "display-only": ""
                                        }))
                                      : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128)),
                            (_ctx.filteredTargetItemComponentNames.length > 0)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('filterSelect.callTargetInfo.title')), 1))
                              : _createCommentVNode("", true),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredTargetItemComponentNames, ([ key, value ]) => {
                              return (_openBlock(), _createBlock(_component_BLayout, { key: key }, {
                                default: _withCtx(() => [
                                  (_ctx.isOldExistFilterParam(value))
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
                                        key: 0,
                                        class: "filter-box",
                                        title: _ctx.$t(`filterSelect.callTargetInfo.item.${value}`),
                                        "lead-list-filter-params": _ctx.filterParams,
                                        "is-display-only": "",
                                        "key-name": value
                                      }, null, 8, ["title", "lead-list-filter-params", "key-name"]))
                                    : (_ctx.isNewExistFilterParam(value))
                                      ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                                          key: 1,
                                          class: "filter-b-empty-box",
                                          "display-only": ""
                                        }))
                                      : _createCommentVNode("", true),
                                  (_ctx.isNewExistFilterParam(value))
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
                                        key: 2,
                                        class: "filter-box",
                                        title: _ctx.$t(`filterSelect.callTargetInfo.item.${value}`),
                                        "lead-list-filter-params": _ctx.leadListFilterParams,
                                        "is-display-only": "",
                                        "key-name": value
                                      }, null, 8, ["title", "lead-list-filter-params", "key-name"]))
                                    : (_ctx.isOldExistFilterParam(value))
                                      ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                                          key: 3,
                                          class: "filter-b-empty-box",
                                          "display-only": ""
                                        }))
                                      : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128)),
                            (_ctx.filteredHistoryItemComponentNames.length > 0 || _ctx.filteredBulkMailItemComponentNames.length > 0)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('filterSelect.historyInfo.title')), 1))
                              : _createCommentVNode("", true),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredHistoryItemComponentNames, ([ key, value ]) => {
                              return (_openBlock(), _createBlock(_component_BLayout, { key: key }, {
                                default: _withCtx(() => [
                                  (_ctx.isOldExistFilterParam(value))
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
                                        key: 0,
                                        class: "filter-box",
                                        "lead-list-filter-params": _ctx.filterParams,
                                        "is-display-only": "",
                                        title: _ctx.$t(`filterSelect.historyInfo.item.${value}`),
                                        "key-name": value
                                      }, null, 8, ["lead-list-filter-params", "title", "key-name"]))
                                    : (_ctx.isNewExistFilterParam(value))
                                      ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                                          key: 1,
                                          class: "filter-b-empty-box",
                                          "display-only": ""
                                        }))
                                      : _createCommentVNode("", true),
                                  (_ctx.isNewExistFilterParam(value))
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
                                        key: 2,
                                        class: "filter-box",
                                        "lead-list-filter-params": _ctx.leadListFilterParams,
                                        "is-display-only": "",
                                        title: _ctx.$t(`filterSelect.historyInfo.item.${value}`),
                                        "key-name": value
                                      }, null, 8, ["lead-list-filter-params", "title", "key-name"]))
                                    : (_ctx.isOldExistFilterParam(value))
                                      ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                                          key: 3,
                                          class: "filter-b-empty-box",
                                          "display-only": ""
                                        }))
                                      : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredBulkMailItemComponentNames, ([ key, value ]) => {
                              return (_openBlock(), _createBlock(_component_BLayout, { key: key }, {
                                default: _withCtx(() => [
                                  (_ctx.isOldExistFilterParam(value))
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
                                        key: 0,
                                        class: "filter-box",
                                        "lead-list-filter-params": _ctx.filterParams,
                                        "is-display-only": "",
                                        "key-name": value
                                      }, null, 8, ["lead-list-filter-params", "key-name"]))
                                    : (_ctx.isNewExistFilterParam(value))
                                      ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                                          key: 1,
                                          class: "filter-b-empty-box",
                                          "display-only": ""
                                        }))
                                      : _createCommentVNode("", true),
                                  (_ctx.isNewExistFilterParam(value))
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
                                        key: 2,
                                        class: "filter-box",
                                        "lead-list-filter-params": _ctx.leadListFilterParams,
                                        "is-display-only": "",
                                        "key-name": value
                                      }, null, 8, ["lead-list-filter-params", "key-name"]))
                                    : (_ctx.isOldExistFilterParam(value))
                                      ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                                          key: 3,
                                          class: "filter-b-empty-box",
                                          "display-only": ""
                                        }))
                                      : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128)),
                            (_ctx.filteredLeadAdditionalItemComponentNames.length > 0)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t('filterSelect.leadAdditionalInfo.title')), 1))
                              : _createCommentVNode("", true),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredLeadAdditionalItemComponentNames, ([ key, value ]) => {
                              return (_openBlock(), _createBlock(_component_BLayout, { key: key }, {
                                default: _withCtx(() => [
                                  (_ctx.isOldExistFilterParam(value))
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
                                        key: 0,
                                        title: _ctx.$t(`filterSelect.leadAdditionalInfo.item.${value}`),
                                        class: "filter-box",
                                        "lead-list-filter-params": _ctx.filterParams,
                                        "is-display-only": "",
                                        "key-name": value
                                      }, null, 8, ["title", "lead-list-filter-params", "key-name"]))
                                    : (_ctx.isNewExistFilterParam(value))
                                      ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                                          key: 1,
                                          class: "filter-b-empty-box",
                                          "display-only": ""
                                        }))
                                      : _createCommentVNode("", true),
                                  (_ctx.isNewExistFilterParam(value))
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
                                        key: 2,
                                        title: _ctx.$t(`filterSelect.leadAdditionalInfo.item.${value}`),
                                        class: "filter-box",
                                        "lead-list-filter-params": _ctx.leadListFilterParams,
                                        "is-display-only": "",
                                        "key-name": value
                                      }, null, 8, ["title", "lead-list-filter-params", "key-name"]))
                                    : (_ctx.isOldExistFilterParam(value))
                                      ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                                          key: 3,
                                          class: "filter-b-empty-box",
                                          "display-only": ""
                                        }))
                                      : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128)),
                            (_ctx.filteredCustomFieldItemsForPanel.length > 0)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.$t('filterSelect.customField.title')), 1))
                              : _createCommentVNode("", true),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredCustomFieldItemsForPanel, ({ fieldKey, before, after }) => {
                              return (_openBlock(), _createBlock(_component_BLayout, { key: fieldKey }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_CustomFieldItem, {
                                    info: before.info,
                                    "custom-field-item": before.item
                                  }, null, 8, ["info", "custom-field-item"]),
                                  _createVNode(_component_CustomFieldItem, {
                                    info: after.info,
                                    "custom-field-item": after.item
                                  }, null, 8, ["info", "custom-field-item"])
                                ]),
                                _: 2
                              }, 1024))
                            }), 128)),
                            (_ctx.filteredNextActionItemComponentNames.length > 0)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.$t('filterSelect.nextActions.title')), 1))
                              : _createCommentVNode("", true),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredNextActionItemComponentNames, ([ key, value ]) => {
                              return (_openBlock(), _createBlock(_component_BLayout, { key: key }, {
                                default: _withCtx(() => [
                                  (_ctx.isOldExistFilterParam(value))
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
                                        key: 0,
                                        class: "filter-box",
                                        "lead-list-filter-params": _ctx.filterParams,
                                        "key-name": value,
                                        "is-display-only": "",
                                        title: _ctx.$t(`filterSelect.nextActions.item.${value}`),
                                        "true-text": _ctx.getNextActionsCompletedPresentTrueText(value),
                                        "false-text": _ctx.getNextActionsCompletedPresentFalseText(value)
                                      }, null, 8, ["lead-list-filter-params", "key-name", "title", "true-text", "false-text"]))
                                    : (_ctx.isNewExistFilterParam(value))
                                      ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                                          key: 1,
                                          class: "filter-b-empty-box",
                                          "display-only": ""
                                        }))
                                      : _createCommentVNode("", true),
                                  (_ctx.isNewExistFilterParam(value))
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
                                        key: 2,
                                        class: "filter-box",
                                        "lead-list-filter-params": _ctx.leadListFilterParams,
                                        "key-name": value,
                                        "is-display-only": "",
                                        title: _ctx.$t(`filterSelect.nextActions.item.${value}`),
                                        "true-text": _ctx.getNextActionsCompletedPresentTrueText(value),
                                        "false-text": _ctx.getNextActionsCompletedPresentFalseText(value)
                                      }, null, 8, ["lead-list-filter-params", "key-name", "title", "true-text", "false-text"]))
                                    : (_ctx.isOldExistFilterParam(value))
                                      ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                                          key: 3,
                                          class: "filter-b-empty-box",
                                          "display-only": ""
                                        }))
                                      : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128)),
                            (_ctx.filteredDuplicateItemComponentNames.length > 0)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.$t('filterSelect.duplicateInfo.title')), 1))
                              : _createCommentVNode("", true),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredDuplicateItemComponentNames, ([ key, value ]) => {
                              return (_openBlock(), _createBlock(_component_BLayout, { key: key }, {
                                default: _withCtx(() => [
                                  (_ctx.isOldExistFilterParam(value))
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
                                        key: 0,
                                        class: "filter-box",
                                        "lead-list-filter-params": _ctx.filterParams,
                                        "key-name": value,
                                        "is-display-only": "",
                                        title: _ctx.$t(`filterSelect.duplicateInfo.item.${value}`)
                                      }, null, 8, ["lead-list-filter-params", "key-name", "title"]))
                                    : (_ctx.isNewExistFilterParam(value))
                                      ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                                          key: 1,
                                          class: "filter-b-empty-box",
                                          "display-only": ""
                                        }))
                                      : _createCommentVNode("", true),
                                  (_ctx.isNewExistFilterParam(value))
                                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
                                        key: 2,
                                        class: "filter-box",
                                        "lead-list-filter-params": _ctx.leadListFilterParams,
                                        "key-name": value,
                                        "is-display-only": "",
                                        title: _ctx.$t(`filterSelect.duplicateInfo.item.${value}`)
                                      }, null, 8, ["lead-list-filter-params", "key-name", "title"]))
                                    : (_ctx.isOldExistFilterParam(value))
                                      ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                                          key: 3,
                                          class: "filter-b-empty-box",
                                          "display-only": ""
                                        }))
                                      : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]))
                        : (_openBlock(), _createBlock(_component_BEmptyBox, {
                            key: 1,
                            class: "pt-500 pb-500",
                            "display-only": ""
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.noChange')), 1)
                            ]),
                            _: 1
                          }))
                    ]),
                    displyColumn: _withCtx(() => [
                      (_ctx.isDisplayColumnsChanged)
                        ? (_openBlock(), _createBlock(_component_BLayout, { key: 0 }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("div", _hoisted_17, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.beforeChange')), 1)
                                ]),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayColumns, (column) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: column,
                                    class: _normalizeClass(["column-box px-200 mb-100", {'is-deleted': _ctx.isNewExistLeadListColumn(column)}])
                                  }, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.getColumnName(column)), 1)
                                  ], 2))
                                }), 128))
                              ]),
                              _createElementVNode("div", _hoisted_18, [
                                _createElementVNode("div", _hoisted_19, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.afterChange')), 1)
                                ]),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leadListSelectedColumns, (column) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: column,
                                    class: _normalizeClass(["column-box px-200 mb-100", {'is-added': _ctx.isOldExistLeadListColumn(column)}])
                                  }, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.getColumnName(column)), 1)
                                  ], 2))
                                }), 128))
                              ])
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_BEmptyBox, {
                            key: 1,
                            class: "pt-500 pb-500",
                            "display-only": ""
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.noChange')), 1)
                            ]),
                            _: 1
                          }))
                    ]),
                    _: 1
                  }, 8, ["label-items"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_BModalFooter, null, {
            default: _withCtx(() => [
              _createVNode(_component_BLayout, {
                class: "mt-300 mx-400",
                "justify-center": "",
                "align-center": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BBtn, {
                    class: "mr-600",
                    text: "",
                    disabled: _ctx.$wait.is('updateLeadViewWait'),
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$modal.hide(_ctx.modalName)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.cancel.text')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"]),
                  _createVNode(_component_BBtn, {
                    type: "primary",
                    loading: _ctx.$wait.is('updateLeadViewWait'),
                    onClick: _ctx.handleUpdateLeadView
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.update')), 1)
                    ]),
                    _: 1
                  }, 8, ["loading", "onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-name", "width", "height"]))
}
<template>
  <FilterDetailPopover
    ref="popover"
    v-model:is-include="isInclude"
    :title="title"
    :width="260"
    :is-switch="isSwitch"
    :is-display-only="isDisplayOnly"
    :key-name="fieldKey"
    is-custom
    @hide="hide"
    @show="popoverShow"
  >
    <template #reference>
      <BLayout align-center>
        <BListItem>
          <template #header>
            <BLayout justify-space-between>
              <div class="item-title">
                {{ title }}
              </div>
              <div
                v-if="isSwitch"
                class="grouping-text"
                :class="{
                  'is-include': incExc === 'inc',
                  'inc-text': incExc === 'inc',
                  'exc-text': incExc === 'exc'
                }"
              >
                {{ groupingText }}
              </div>
            </BLayout>
          </template>
          <div>{{ value }}</div>
        </BListItem>
      </BLayout>
    </template>
    <div>
      <FilterItemCheckbox
        v-model:checked-item="checkedItem"
        :candidate-items="candidateItems"
        :blank-item="blankItem"
      />
    </div>
  </FilterDetailPopover>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterDetailPopover from '@/components/organisms/user/leadList/filter/FilterDetailPopover.vue'
import FilterItemCheckbox from '@/components/organisms/user/leadList/filter/FilterItemCheckbox.vue'
import { getIncExc } from '@/utils/object'

export default {
  components: {
    FilterDetailPopover,
    FilterItemCheckbox,
  },
  props: {
    title: String,
    value: String,
    customFieldItem: {
      type: Object,
      default() {
        return { inc: { or: null } }
      },
    },
    fieldKey: {
      type: String,
      required: true,
    },
    isDisplayOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'hide',
  ],
  data() {
    return {
      checkedItem: [],
      isInclude: false,
    }
  },
  computed: {
    ...mapGetters('user', ['customFields']),
    ...mapGetters('userUi', ['leadListFilterParams']),
    isSwitch() {
      return true
    },
    candidateItems() {
      if (this.customFields.length === 0) return []
      const customField = this.customFields.find((cf) => {
        return cf.fieldKey === this.fieldKey
      })
      if (!Array.isArray(customField?.options)) return []
      return customField.options.map((candidate) => {
        return { label: candidate.label, value: candidate.id }
      })
    },
    blankItem() {
      return { label: this.$t('general.blankInput'), value: -1 }
    },
    groupingText() {
      if (this.target == null || this.targetDetail.length === 0) return
      return this.$t(`general.filter.${this.incExc}`)
    },
    target() {
      return this.customFieldItem
    },
    targetDetail() {
      if (this.target == null) return []
      return this.target[this.incExc].or.map((v) => parseInt(v))
    },
    incExc() {
      return getIncExc(this.target)
    },
    grouping() {
      return this.isInclude ? 'inc' : 'exc'
    },
  },
  created() {
    this.isInclude = getIncExc(this.target) !== 'exc'
  },
  methods: {
    popoverShow() {
      this.checkedItem = this.cleansingToNumber(this.target[this.incExc].or)
      this.isInclude = this.incExc !== 'exc'
    },
    hide() {
      this.$emit('hide', { [this.grouping]: { or: this.checkedItem } })
    },
    cleansingToNumber(array) {
      try {
        if (!Array.isArray(array)) Error(`unexpected variable. ${array}`)
        return array.map((v) => parseInt(v))
      } catch (e) {
        console.error(e)
        return array
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .grouping-text {
    color: $basecolor-cold;
    margin-left: 2px;

    &.is-include {
      color: $basecolor-hot;
    }

    &.inc-text {
      min-width: 24px;
    }

    &.exc-text {
      min-width: 48px;
    }
  }
</style>

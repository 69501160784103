import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, toHandlers as _toHandlers, mergeProps as _mergeProps, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "side-menu-container" }
const _hoisted_2 = { class: "side-menu-container" }

import EditableSequenceSteps from '@/components/organisms/user/general/sequence/EditableSequenceSteps.vue';
import SequenceDirectMailForm from '@/components/organisms/user/general/sequence/SequenceDirectMailForm.vue';
import SequenceNextActionForm from '@/components/organisms/user/general/sequence/SequenceNextActionForm.vue';
import { TFormSequenceStep, TPartialFormDirectMailSequenceStep, TPartialFormNextActionSequenceStep, TStepHandlers } from '@/components/organisms/user/general/sequence/types';
import { useSequenceStepActions, useStepEditing, useStepForm } from '@/composable/user/sequence/step-actions';
import { useSequenceStepMasterApplyActions } from '@/composable/user/sequence/step-menu-actions';

type TProps = {
  sequenceStepMasterData: TFormSequenceStep[];
};
type TEmit = {
  'update': [sequenceSteps: TFormSequenceStep[]];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceSteps',
  props: {
    sequenceStepMasterData: {}
  },
  emits: ["update"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const { getStepsActions } = useSequenceStepMasterApplyActions();
const stepHandlers: TStepHandlers = {
  'click:edit': (index: number) => handleClickEdit(index),
  'click:sendImmediately': (index: number) => handleImmediatelyClick(index),
  'click:startImmediately': (index: number) => handleImmediatelyClick(index),
  'click:cancelSendImmediately': (index: number) => handleImmediatelyClick(index),
  'click:cancelStartImmediately': (index: number) => handleImmediatelyClick(index),
};

const handleUpdate = async (steps: TFormSequenceStep[]) => {
  emit('update', steps);
};

const {
  internalSequenceSteps,
  isMenuOpens,
  stepsActionsWithDisabled,
  closeMenu,
} = useSequenceStepActions(
  () => props.sequenceStepMasterData,
  getStepsActions,
  handleUpdate,
);
const {
  isNextActionFormOpen,
  isDirectMailFormOpen,
  openStepForm,
  closeStepForm,
  saveStep,
  saveStepAsDraft,
  saveStepAsReserve,
  formSelectedSequenceStep,
} = useStepForm();

const { openStepFormToEdit } = useStepEditing(internalSequenceSteps, openStepForm);

const handleClickEdit = (index: number) => {
  closeMenu(index),
  openStepFormToEdit(index);
};
const handleFormCancel = () => {
  closeStepForm();
};
const handleFormSave = (item: TFormSequenceStep) => {
  saveStep(item);
  closeStepForm();
};
const handleFormSaveAsDraft = (item: TFormSequenceStep) => {
  saveStepAsDraft(item);
  closeStepForm();
};
const handleFormSaveAsReserve = (item: TFormSequenceStep) => {
  saveStepAsReserve(item);
  closeStepForm();
};
const handleImmediatelyClick = (index: number) => {
  closeMenu(index);
  // NOTE: 変更されたメニュー文言がトランジション中に見えるのを防ぐため間を空ける
  setTimeout(async () => {
    internalSequenceSteps.value[index].immediately = !internalSequenceSteps.value[index].immediately;
  }, 500);
};

return (_ctx: any,_cache: any) => {
  const _component_VWait = _resolveComponent("VWait")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_VWait, {
      for: "fetchSequenceStepMasters",
      class: "sequence-steps-container"
    }, {
      default: _withCtx(() => [
        _createVNode(EditableSequenceSteps, _mergeProps({
          "is-menu-opens": _unref(isMenuOpens),
          "onUpdate:isMenuOpens": _cache[0] || (_cache[0] = ($event: any) => (_isRef(isMenuOpens) ? (isMenuOpens).value = $event : null)),
          "sequence-steps": _unref(internalSequenceSteps),
          "enabled-to-add-steps": false,
          "steps-actions-with-disabled": _unref(stepsActionsWithDisabled)
        }, _toHandlers(stepHandlers)), null, 16, ["is-menu-opens", "sequence-steps", "steps-actions-with-disabled"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(SequenceNextActionForm, {
        "sequence-step": (_unref(formSelectedSequenceStep) as TPartialFormNextActionSequenceStep),
        "is-open": _unref(isNextActionFormOpen),
        "onClick:cancel": handleFormCancel,
        "onClick:save": handleFormSave
      }, null, 8, ["sequence-step", "is-open"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(SequenceDirectMailForm, {
        mode: "form",
        "sequence-step": (_unref(formSelectedSequenceStep) as TPartialFormDirectMailSequenceStep),
        "is-open": _unref(isDirectMailFormOpen),
        "with-sender": false,
        "onClick:cancel": handleFormCancel,
        "onClick:saveAsDraft": handleFormSaveAsDraft,
        "onClick:saveAsReserve": handleFormSaveAsReserve
      }, null, 8, ["sequence-step", "is-open"])
    ])
  ], 64))
}
}

})
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CtiOpenButton = _resolveComponent("CtiOpenButton")!

  return (_openBlock(), _createBlock(_component_CtiOpenButton, {
    "tooltip-message": _ctx.$t('callTarget.header.miitel'),
    disabled: _ctx.disable,
    onClick: _ctx.handleClick
  }, null, 8, ["tooltip-message", "disabled", "onClick"]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BCustomIcon = _resolveComponent("BCustomIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BTooltip = _resolveComponent("BTooltip")!

  return (_openBlock(), _createBlock(_component_BTooltip, {
    top: "",
    content: _ctx.tooltipMessage,
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BBtn, {
        class: "mr-200",
        disabled: _ctx.disabled,
        size: "small",
        fab: "",
        flat: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BCustomIcon, { "icon-class": "b-call-call" })
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }, 8, ["content", "disabled"]))
}
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lead-target-form" }

import { inject } from 'vue';
import { useStore } from 'vuex'
import { LeadStage } from '@/api/openapi';
import { KEY_OF_WAIT } from '@/injection-keys';
import { key } from '@/store/index';

type TProps = {
  callTargetId: number;
  leadStage: LeadStage | null;
  leadStages: LeadStage[];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'LeadStageForm',
  props: {
    callTargetId: {},
    leadStage: {},
    leadStages: {}
  },
  emits: ['change'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const store = useStore(key);
const wait = inject(KEY_OF_WAIT);

const handleUpdate = async (newLeadStage: LeadStage | null) => {
  if (!newLeadStage) return;

  wait.start('postCallTargetLeadStageWait');
  await store.dispatch('user/postCallTargetLeadStageAction', {
    callTargetId: props.callTargetId,
    leadStage: newLeadStage,
  });
  wait.end('postCallTargetLeadStageWait');
  emit('change');

  wait.start('getLeadHistoriesWait');
  await store.dispatch('user/getLeadHistoriesAction', {
    callTargetId: props.callTargetId,
  });
  wait.end('getLeadHistoriesWait');
};

return (_ctx: any,_cache: any) => {
  const _component_BEditableLeadStageSelect = _resolveComponent("BEditableLeadStageSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BEditableLeadStageSelect, {
      "model-value": _ctx.leadStage,
      "lead-stages": _ctx.leadStages,
      "after-check": handleUpdate
    }, null, 8, ["model-value", "lead-stages"])
  ]))
}
}

})
<template>
  <BModal :modal-name="modalName">
    <BHeightAdjuster>
      <BModalHeader @modal-close="hide" />
      <div class="title-field">
        <div class="title">
          {{ $t('openaiGenarate.title') }}
        </div>
        <div>{{ $t('openaiGenarate.description') }}</div>
        <div>{{ $t('openaiGenarate.sourceDescription') }}</div>
      </div>
      <BModalBody>
        <BTextarea
          v-model="generateBaseText"
          resize="none"
          :autosize="{ minRows: 24, maxRows: 24 }"
        />
      </BModalBody>
      <BModalFooter>
        <BLayout justify-center>
          <BBtn
            type="primary"
            :loading="isLoading"
            :disabled="isDisabled"
            @click.stop="generateContentByOpenai"
          >
            <span>{{ $t(`openaiGenarate.generate`) }}</span>
          </BBtn>
        </BLayout>
      </BModalFooter>
    </BHeightAdjuster>
  </BModal>
</template>

<script lang="ts">
import axios, { CancelTokenSource } from 'axios';
import { mapWaitingActions } from 'vue-wait'
import BModalBody from '@/plugins/biscuet-materials/components/molecules/BModalBody.vue';

const openAiCancel = (cancelSource: CancelTokenSource | null) => {
  if (cancelSource == null || Object.keys(cancelSource).length == 0) return
  cancelSource?.cancel()
}

type TData = {
  generateBaseText: string;
  cancelSource: CancelTokenSource | null;
};

export default {
  components: {
    BModalBody,
  },
  props: {
    setContent: {
      type: Function,
      required: true,
    },
  },
  emits: [
    'close-modal',
  ],
  data(): TData {
    return {
      generateBaseText: '',
      cancelSource: null,
    }
  },
  computed: {
    modalName() {
      return 'openai-modal'
    },
    isLoading() {
      return this.$wait.is(['generateContentByOpenSearchActionWait'])
    },
    isDisabled() {
      return this.generateBaseText.trim().length == 0
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      generateContentByOpenSearchAction: 'generateContentByOpenSearchActionWait'
    }),
    async generateContentByOpenai() {
      this.cancelSource = axios.CancelToken.source()
      this.hide()
      const res = await this.generateContentByOpenSearchAction({ query: this.generateBaseText, token: this.cancelSource.token })
      this.generateBaseText = ''
      this.setContent(res.data)
    },
    cancel() {
      openAiCancel(this.cancelSource);
    },
    hide() {
      this.$modal.hide(this.modalName)
      this.$emit('close-modal', () => openAiCancel(this.cancelSource))
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  font-size: $fontsize-400;
  line-height: 40px;
}
.title-field {
  text-align: center;
  padding-bottom: 15px;
}

.select-sf-data-block {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 150px;

  &.lead-select {
    grid-template-rows: 50px 80px 80px 80px;
  }

  &.ac-select {
    grid-template-rows: 80px 80px 80px;
  }
}

:deep(.el-radio__input.is-checked .el-radio__inner) {
  border-color: $basecolor-primary;
  background: $basecolor-primary;
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeClass as _normalizeClass, unref as _unref, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "ml-100" }
const _hoisted_2 = {
  key: 0,
  class: "more-menu"
}
const _hoisted_3 = { class: "block-content" }
const _hoisted_4 = { class: "ml-100" }
const _hoisted_5 = {
  key: 0,
  class: "more-menu"
}

import { computed, ref, watch } from 'vue';
import { ApiArguments } from '@/api/api_service';
import { SequenceInstance, SequenceStepInstance, SequenceStepInstanceApiGetCurrentSequenceStepInstanceRequest } from '@/api/openapi';
import { SequenceStepInstanceApiService } from '@/api/user/resources/sequence_step_instance';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';
import { isDirectMailStepExecutedForSequenceStepInstance } from '@/composable/user/sequence/post-put-body';
import { TSequenceAction } from '@/types';

type TProps = {
  activeSequenceInstance: SequenceInstance | undefined;
};
type TEmit = {
  (e: 'update:sequenceStatus', action: TSequenceAction): void;
  (e: 'click:sequenceApply'): void;
  (e: 'click:sequenceWidgetOpen'): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceContainer',
  props: {
    activeSequenceInstance: {}
  },
  emits: ["update:sequenceStatus", "click:sequenceApply", "click:sequenceWidgetOpen"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const sequenceStepInstanceApi = new SequenceStepInstanceApiService();

const isSequenceMenuOpen = ref(false);

const { isAvailableSequenceFeature } = useAvailableFeatureCheck();

const isRegisteredSequenceInstance = computed(() =>
  props.activeSequenceInstance?.status === 'registered',
);
const isStoppedSequenceInstance = computed(() =>
  props.activeSequenceInstance?.status === 'stopped',
);

const isRestartable = computed(() => {
  return isStoppedSequenceInstance.value && currentSequenceStepInstance.value?.status !== 'failed';
});

const currentSequenceStepInstance = ref<SequenceStepInstance>();

const handleSequenceWidgetOpen = () => {
  if (!isAvailableSequenceFeature.value) return;
  emit('click:sequenceWidgetOpen');
};
const handleUpdateSequenceStatus = (action: TSequenceAction) => {
  if (!props.activeSequenceInstance || !isAvailableSequenceFeature.value) return;
  emit('update:sequenceStatus', action);
};
const handleClickSequenceApply = () => {
  if (!isAvailableSequenceFeature.value) return;
  isSequenceMenuOpen.value = false;
  emit('click:sequenceApply');
};

const isSending = computed(() => {
  return currentSequenceStepInstance.value ? isDirectMailStepExecutedForSequenceStepInstance(currentSequenceStepInstance.value) : false;
});

const fetchCurrentSequenceStepInstance = async (sequenceInstanceId: number) => {
  const apiArguments: ApiArguments<SequenceStepInstanceApiGetCurrentSequenceStepInstanceRequest> = {
    request: { sequenceInstanceId },
    errorHandlers: {
      404: notCurrentSequenceStepInstanceHandler,
    },
  };
  const res = await sequenceStepInstanceApi.getCurrentSequenceStepInstance(apiArguments);
  currentSequenceStepInstance.value = res.data;
};

const notCurrentSequenceStepInstanceHandler = () => {
  currentSequenceStepInstance.value = undefined
}

watch(() => props.activeSequenceInstance?.id, async (sequenceInstanceId) => {
  if (!sequenceInstanceId) return;
  await fetchCurrentSequenceStepInstance(sequenceInstanceId);
}, { immediate: true })

return (_ctx: any,_cache: any) => {
  const _component_BContentLoader = _resolveComponent("BContentLoader")!
  const _component_BCustomIcon = _resolveComponent("BCustomIcon")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BMoreMenu = _resolveComponent("BMoreMenu")!

  return (_ctx.$wait.is('getSequenceInstancesWait'))
    ? (_openBlock(), _createBlock(_component_BContentLoader, {
        key: 0,
        height: 24,
        width: 230
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("rect", {
            x: "0",
            y: "8.5",
            rx: "6",
            ry: "6",
            width: "160",
            height: "12"
          }, null, -1)
        ])),
        _: 1
      }))
    : (_ctx.activeSequenceInstance)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", {
            class: _normalizeClass(["block-content sequence-instance-name", { 'stopped': isStoppedSequenceInstance.value }]),
            onClick: handleSequenceWidgetOpen
          }, [
            _createVNode(_component_BLayout, { "align-center": "" }, {
              default: _withCtx(() => [
                (isStoppedSequenceInstance.value)
                  ? (_openBlock(), _createBlock(_component_BCustomIcon, {
                      key: 0,
                      "icon-class": "b-sequence",
                      type: "error",
                      size: "medium"
                    }))
                  : (_openBlock(), _createBlock(_component_BCustomIcon, {
                      key: 1,
                      "icon-class": "b-sequence",
                      type: "success",
                      size: "medium"
                    })),
                _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.activeSequenceInstance.name), 1)
              ]),
              _: 1
            })
          ], 2),
          (_unref(isAvailableSequenceFeature))
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_BMoreMenu, { left: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BTooltip, {
                      right: "",
                      content: _ctx.$t('mail.status.sending'),
                      disabled: !isSending.value
                    }, {
                      default: _withCtx(() => [
                        (isRegisteredSequenceInstance.value)
                          ? (_openBlock(), _createBlock(_component_BBtn, {
                              key: 0,
                              flat: "",
                              fit: "",
                              position: "center",
                              size: "small",
                              disabled: isSending.value,
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (handleUpdateSequenceStatus('stop')))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('general.stop.do')), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["content", "disabled"]),
                    _createVNode(_component_BTooltip, {
                      right: "",
                      content: _ctx.$t('mail.status.sending'),
                      disabled: !isSending.value
                    }, {
                      default: _withCtx(() => [
                        (isRestartable.value)
                          ? (_openBlock(), _createBlock(_component_BBtn, {
                              key: 0,
                              flat: "",
                              fit: "",
                              position: "center",
                              size: "small",
                              disabled: isSending.value,
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (handleUpdateSequenceStatus('restart')))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('general.restart.do')), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["content", "disabled"]),
                    _createVNode(_component_BTooltip, {
                      right: "",
                      content: _ctx.$t('mail.status.sending'),
                      disabled: !isSending.value
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BBtn, {
                          flat: "",
                          fit: "",
                          position: "center",
                          size: "small",
                          disabled: isSending.value,
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (handleUpdateSequenceStatus('unregister')))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('general.unregister.do')), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled"])
                      ]),
                      _: 1
                    }, 8, ["content", "disabled"])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BLayout, { "align-center": "" }, {
              default: _withCtx(() => [
                _createVNode(_component_BCustomIcon, {
                  "icon-class": "b-sequence",
                  type: "default",
                  size: "medium"
                }),
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('sequence.leadDetail.hasNoInstance')), 1)
              ]),
              _: 1
            })
          ]),
          (_unref(isAvailableSequenceFeature))
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_BMoreMenu, {
                  modelValue: isSequenceMenuOpen.value,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((isSequenceMenuOpen).value = $event)),
                  left: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BBtn, {
                      flat: "",
                      fit: "",
                      position: "center",
                      size: "small",
                      onClick: handleClickSequenceApply
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('sequence.leadDetail.apply')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]))
            : _createCommentVNode("", true)
        ], 64))
}
}

})
<template>
  <ElRadio
    v-bind="attributes"
    v-model="internalValue"
  >
    <slot />
  </ElRadio>
</template>

<script>
export default {
  props: {
    modelValue: [String, Number, Boolean],
    label: [String, Number, Boolean],
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  computed: {
    internalValue: {
      get() {
        return this.modelValue
      },
      set(newVal) {
        if (this.modelValue !== newVal) this.$emit('update:modelValue', newVal)
      },
    },
    attributes() {
      return {
        label: this.label,
        disabled: this.disabled,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .el-radio {
    height: auto;
  }
</style>

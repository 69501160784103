import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-300" }
const _hoisted_2 = {
  key: 0,
  class: "my-200"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  class: "import-confirm-list my-200"
}
const _hoisted_5 = { class: "lead-import-confirm-header" }
const _hoisted_6 = {
  key: 0,
  class: "lead-import-confirm-body"
}

import { ref } from 'vue';
import api from '@/api/admin/resources/salesforce';

type TProps = {
  id: number;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'SalesforceForm',
  props: {
    id: {}
  },
  setup(__props: any) {

const props = __props;

const loading = ref<boolean>(false)
const reportId = ref<string>('')
const rows = ref<Map<string, string>[]>([])
const reportCount = ref<number>(null)
const headers = ref<Map<string, string>[]>([])

const errorHandler = (error) => {
  rows.value = []
  reportCount.value = 0
  headers.value = []
  loading.value = false
}

const search = async () => {
  loading.value = true
  rows.value = []
  reportCount.value = 0
  headers.value = []
  const result = await api.searchSalesforceReport({
    reportId: reportId.value,
    clientId: props.id,
  }).catch(errorHandler)
  if (result == undefined) return;

  rows.value = result.data.sfReport.rows
  reportCount.value = result.data.sfReport.reportRowCount
  headers.value = result.data.sfReport.headers
  loading.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_ElTableColumn = _resolveComponent("ElTableColumn")!
  const _component_ElTable = _resolveComponent("ElTable")!

  return (_openBlock(), _createBlock(_component_BLayout, { column: "" }, {
    default: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('import.salesforce.reportSearchTitle')), 1),
      _createVNode(_component_BLayout, { "align-center": "" }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('import.salesforce.reportSearchKey')), 1),
          _createVNode(_component_BInput, {
            modelValue: reportId.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((reportId).value = $event)),
            class: "mx-200",
            placeholder: _ctx.$t('general.search.placeholder'),
            disabled: loading.value
          }, null, 8, ["modelValue", "placeholder", "disabled"]),
          _createVNode(_component_BBtn, {
            size: "small",
            type: "primary",
            disabled: loading.value,
            onClick: search
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.search.exec')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      }),
      (Object.keys(headers.value).length > 0)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.$t('import.salesforce.reportResultTitle')), 1))
        : _createCommentVNode("", true),
      (Object.keys(headers.value).length > 0 && rows.value.length == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('apiError.notFound')), 1))
        : _createCommentVNode("", true),
      (Object.keys(headers.value).length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", null, _toDisplayString(`${_ctx.$t('import.salesforce.targetCount')}${reportCount.value}`), 1)
            ]),
            (Object.keys(headers.value).length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_ElTable, {
                    "scrollbar-always-on": "true",
                    data: rows.value
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(headers.value, (header, index) => {
                        return (_openBlock(), _createBlock(_component_ElTableColumn, {
                          key: index,
                          prop: index,
                          label: `${header} \n ${index}`,
                          width: "180px"
                        }, null, 8, ["prop", "label"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["data"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})
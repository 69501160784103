import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ValueOf } from 'element-plus/es/components/table/src/table-column/defaults';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n'
import COLOR_CODES from '@/const/color_codes';
import { TSequenceStatus } from '@/types';

type TColor = {status: TSequenceStatus, colorCode: ValueOf<typeof COLOR_CODES>};

type TProps = {
  value: TSequenceStatus;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceInstanceStatusPreview',
  props: {
    value: {}
  },
  emits: ['click'],
  setup(__props: any) {

const props = __props;



const i18n = useI18n();

const colors: TColor[] = [
  {
    status: 'registered',
    colorCode: COLOR_CODES.GAIA,
  },
  {
    status: 'stopped',
    colorCode: COLOR_CODES.VENUS,
  },
  {
    status: 'done',
    colorCode: COLOR_CODES.URANUS,
  },
  {
    status: 'unregistered',
    colorCode: COLOR_CODES.NEPTUNE,
  },
];
const colorCode = computed(() => colors.find(c => c.status === props.value)?.colorCode);

return (_ctx: any,_cache: any) => {
  const _component_BColorPreview = _resolveComponent("BColorPreview")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BColorPreview, {
      class: "mr-50",
      "color-code": colorCode.value,
      size: "small"
    }, null, 8, ["color-code"]),
    _createTextVNode(" " + _toDisplayString(_unref(i18n).t(`sequence.status.${props.value}`)), 1)
  ], 64))
}
}

})
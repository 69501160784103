import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "header-group" }
const _hoisted_2 = { class: "text" }
const _hoisted_3 = {
  key: 0,
  class: "datetime-input-group"
}
const _hoisted_4 = {
  key: 0,
  class: "reserved-at-error"
}

import { computed, watch } from 'vue';
import { addMinutesToCurrentDateTime, isDateAfter } from '@/utils/date-time';

type TProps = {
  reservedAt: string | null;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ReservedAt',
  props: {
    reservedAt: {}
  },
  emits: ['update:isSendNow', 'update:reservedAt', 'update:isValid'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const internalReservedAt = computed<string>({
  get: () => props.reservedAt,
  set: (value) => emit('update:reservedAt', value),
});
const isValid = computed(() => validReservedAt(internalReservedAt.value));
const isSendNow = computed({
  get: () => !internalReservedAt.value,
  set: (v) => internalReservedAt.value = getDefaultReservedAt(v),
});

const validReservedAt = (reservedAt: string | null) => {
  if (!reservedAt) return true;
  return isDateAfter(reservedAt, addMinutesToCurrentDateTime(5));
};

watch(isValid, (newVal) => {
  emit('update:isValid', newVal)
}, { immediate: true });
const getDefaultReservedAt = (isSendNow: boolean) => isSendNow ? null : addMinutesToCurrentDateTime(30)

return (_ctx: any,_cache: any) => {
  const _component_BCheckbox = _resolveComponent("BCheckbox")!
  const _component_BInputDateTime = _resolveComponent("BInputDateTime")!
  const _component_BListItem = _resolveComponent("BListItem")!

  return (_openBlock(), _createBlock(_component_BListItem, { class: "mb-600" }, {
    header: _withCtx(() => [
      _createElementVNode("span", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('mail.reserve')), 1),
          _createVNode(_component_BCheckbox, {
            modelValue: isSendNow.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isSendNow).value = $event)),
            class: "ml-100",
            label: _ctx.$t('mail.sendNow')
          }, null, 8, ["modelValue", "label"])
        ])
      ])
    ]),
    default: _withCtx(() => [
      (!isSendNow.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_BInputDateTime, {
              date: internalReservedAt.value,
              "onUpdate:date": _cache[1] || (_cache[1] = ($event: any) => ((internalReservedAt).value = $event)),
              time: internalReservedAt.value,
              "onUpdate:time": _cache[2] || (_cache[2] = ($event: any) => ((internalReservedAt).value = $event))
            }, null, 8, ["date", "time"]),
            (!isValid.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('mail.sendAtError')), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})
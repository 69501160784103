import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header-item header-title" }
const _hoisted_3 = { class: "header-item number-of-items" }
const _hoisted_4 = { class: "body" }
const _hoisted_5 = ["infinite-scroll-disabled"]

import { PartialReceivedMail } from '@/api/openapi';
import { ReceivedMailApiService } from '@/api/user/resources/received_mail';
import ReceivedMailNotificationItem from '@/components/organisms/user/receivedMailNotification/ReceivedMailNotificationItem.vue';
import { useInfiniteLoading } from '@/composable/infinite-loading';

const KEY_OF_INFINITE_LOADING_WAIT = 'receiveMailInfiniteLoadingWait';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReceivedMailNotification',
  setup(__props) {

const fetchItems = async (page = 1) => {
  const api = new ReceivedMailApiService()
  const res = await api.getReceivedMails({
    request: {
      page,
    },
  });
  return {
    items: res.data.receivedMails,
    paginationMeta: res.data.meta,
  }
}

const {
  items,
  paginationMeta,
  infiniteScrollDisabled,
  loadMore,
  clear,
  handleAfterOpen,
  fetchLoadedPage,
} = useInfiniteLoading<PartialReceivedMail>(fetchItems, KEY_OF_INFINITE_LOADING_WAIT);

return (_ctx: any,_cache: any) => {
  const _component_BCustomIcon = _resolveComponent("BCustomIcon")!
  const _component_BEmptyBox = _resolveComponent("BEmptyBox")!
  const _component_BElementIcon = _resolveComponent("BElementIcon")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BIconWithActionMenu = _resolveComponent("BIconWithActionMenu")!
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")!

  return (_openBlock(), _createBlock(_component_BIconWithActionMenu, {
    class: "received-mail-notification-container",
    "badge-count": _unref(paginationMeta)?.totalCount || 0,
    "auto-fetch-interval": 30000,
    onAutoFetchIntervalPassed: _unref(fetchLoadedPage),
    onAfterOpen: _unref(handleAfterOpen),
    onAfterClose: _unref(clear)
  }, {
    icon: _withCtx(() => [
      _createVNode(_component_BCustomIcon, { "icon-class": "b-mail-return" })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('receivedMailNotification.title')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(_unref(paginationMeta)?.totalCount || 0) + "件", 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives((_openBlock(), _createElementBlock("div", {
          class: "body-inner",
          "infinite-scroll-disabled": _unref(infiniteScrollDisabled),
          "infinite-scroll-distance": "20"
        }, [
          (!_ctx.$wait.is(KEY_OF_INFINITE_LOADING_WAIT) && !_unref(items).length)
            ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                key: 0,
                class: "empty",
                "display-only": ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('receivedMailNotification.empty')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(items), (item) => {
            return (_openBlock(), _createBlock(ReceivedMailNotificationItem, {
              key: item.id,
              item: item,
              onAfterClickEventSave: _unref(fetchLoadedPage)
            }, null, 8, ["item", "onAfterClickEventSave"]))
          }), 128)),
          (_ctx.$wait.is(KEY_OF_INFINITE_LOADING_WAIT))
            ? (_openBlock(), _createBlock(_component_BLayout, {
                key: 1,
                class: "py-200",
                "justify-center": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BElementIcon, { name: "Loading" })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ], 8, _hoisted_5)), [
          [_directive_infinite_scroll, _unref(loadMore)]
        ])
      ])
    ]),
    _: 1
  }, 8, ["badge-count", "onAutoFetchIntervalPassed", "onAfterOpen", "onAfterClose"]))
}
}

})
<template>
  <div class="py-300">
    <BLayout class="profile-block top-content">
      <div class="block-content">
        <VWait
          class="full-width"
          for="none"
          :visible="$wait.is('getCallTargetWait')"
        >
          <div class="fw-bold mb-50">
            {{ companyName }}
          </div>
          <BLayout align-center>
            <span class="mr-100">{{ targetName }}</span>
            <BTooltip
              v-if="isUnsubscribed"
              top
            >
              <BCustomIcon
                icon-class="b-mail-block"
                size="small"
              />
              <template #content>
                <span>{{ unsubscribedMessage }}</span>
              </template>
            </BTooltip>
          </BLayout>
          <template #waiting>
            <div>-</div>
            <div>-</div>
          </template>
        </VWait>
      </div>
      <div class="more-menu">
        <BMoreMenu left>
          <template #default>
            <BTooltip
              v-if="isUnsubscribed"
              top
              :disabled="$can('owner')"
            >
              <div>
                <BBtn
                  flat
                  fit
                  size="small"
                  :disabled="$can('member')"
                  @click="$emit('subscribe', callTargetId)"
                >
                  {{ $t('unsubscribe.subscribe') }}
                </BBtn>
              </div>
              <template #content>
                <span>{{ $t('unsubscribe.subscribeTip') }}</span>
              </template>
            </BTooltip>
            <BBtn
              v-else-if="isUnsubscribable"
              flat
              fit
              size="small"
              @click="$emit('unsubscribe', callTargetId)"
            >
              {{ $t('unsubscribe.unsubscribe') }}
            </BBtn>
            <BBtn
              flat
              fit
              position="left"
              size="small"
              @click="deleteCallTarget(callTargetId)"
            >
              {{ $t('general.delete.to', {item: $t('callTarget.title')}) }}
            </BBtn>
            <BBtn
              flat
              fit
              position="left"
              size="small"
              @click="duplicateCallTarget(callTargetId)"
            >
              {{ $t('general.duplicate.to', {item: $t('callTarget.title')}) }}
            </BBtn>
          </template>
        </BMoreMenu>
      </div>
    </BLayout>
    <BLayout
      v-if="isAvailableSequenceFeature"
      class="profile-block top-content"
    >
      <SequenceContainer
        :active-sequence-instance="activeSequenceInstance"
        @update:sequence-status="handleUpdateSequenceStatus"
        @click:sequence-apply="handleClickSequenceApply"
        @click:sequence-widget-open="handleSequenceWidgetOpen"
      />
    </BLayout>
    <BDivider class="mb-300" />
    <BExpansionPanel
      class="profile-block"
      expand
    >
      <template #header>
        <div class="block-title">
          <div class="title-text mr-100">
            {{ $t('leadStage.title') }}
          </div>
        </div>
      </template>
      <div class="block-content">
        <VWait
          class="full-width"
          for="none"
          :visible="$wait.is('getCallTargetWait')"
        >
          <template #waiting>
            <BContentLoader
              :height="32"
              :width="230"
            >
              <rect
                x="0"
                y="8.5"
                rx="6"
                ry="6"
                width="160"
                height="12"
              />
            </BContentLoader>
          </template>
          <BLayout wrap>
            <LeadStageForm
              :call-target-id="callTargetId"
              :lead-stage="callTargetLeadStage"
              :lead-stages="leadStages"
              :loading="callTargetLoading"
              @change="handleChange"
            />
          </BLayout>
        </VWait>
      </div>
    </BExpansionPanel>
    <BExpansionPanel
      class="profile-block"
      expand
    >
      <template #header>
        <div class="block-title">
          <div class="title-text mr-100">
            {{ $t('operatorAssign.assignedOperator') }}
          </div>
        </div>
      </template>
      <div class="block-content">
        <VWait
          class="full-width"
          for="none"
          :visible="operatorLoading"
        >
          <div
            fat
            :class="isExistOperator(operator) ? 'set-operator-btn' : 'set-operator-round-btn'"
            :fit="true"
            size="default"
          >
            <div
              v-if="isExistOperator(operator)"
              class="operator-info"
              @click.stop="openSetOperatorModal"
            >
              <BAvatar
                :image-url="operator.avatarImage"
                :name="operator.name"
                :user-id="operator.id"
                :size="36"
              />
              <p class="operator-name">
                {{ operator.name }}
              </p>
            </div>
            <BEmptyBox
              v-else
              @click.stop="openSetOperatorModal"
            >
              <BIcon size="small">
                add
              </BIcon>
            </BEmptyBox>
          </div>
          <template #waiting>
            <BContentLoader
              :height="24"
              :width="230"
            >
              <rect
                x="0"
                y="8.5"
                rx="6"
                ry="6"
                width="160"
                height="12"
              />
            </BContentLoader>
          </template>
        </VWait>
      </div>
    </BExpansionPanel>
    <BModal
      modal-name="setOperatorInDetail"
      width="380px"
      height="100px"
    >
      <SetOperator
        :current-operator-id="operator.id"
        :operator-users="activeUsers"
        :needs-withdraw-option="true"
        @assign-operator="assignOperator"
        @withdraw-operator="withdrawOperator"
      />
    </BModal>
    <BExpansionPanel
      class="profile-block"
      expand
    >
      <template #header>
        <div class="block-title">
          <div class="title-text mr-100">
            {{ $t('leadSource.title') }}
          </div>
          <LeadSourceForm
            :candidates="leadSourceCandidates"
            :selected-ids="callTargetLeadSources.map((c) => c.id)"
            :call-target-id="callTargetId"
            :loading="leadSourceLoading"
            @change="handleChange"
          />
        </div>
      </template>
      <div class="block-content">
        <VWait
          class="full-width"
          for="none"
          :visible="leadSourceLoading"
        >
          <template #waiting>
            <BContentLoader
              :height="32"
              :width="230"
            >
              <rect
                x="0"
                y="8.5"
                rx="6"
                ry="6"
                width="160"
                height="12"
              />
            </BContentLoader>
          </template>
          <BLayout wrap>
            <BLabel
              v-for="leadSource in callTargetLeadSources"
              :key="`lead-source-${leadSource.id}`"
              class="mr-100 mb-100"
              round
            >
              <span>{{ leadSource.name }}</span>
            </BLabel>
          </BLayout>
        </VWait>
      </div>
    </BExpansionPanel>
    <BExpansionPanel
      v-if="salesforceCampaigns.length > 0"
      class="profile-block"
      expand
    >
      <template #header>
        <div class="block-title">
          <div class="title-text mr-100">
            {{ $t('salesforceCampaign.title') }}
          </div>
        </div>
      </template>
      <div class="block-content">
        <VWait
          class="full-width"
          for="none"
          :visible="$wait.is('getCallTargetWait')"
        >
          <template #waiting>
            <BContentLoader
              :height="32"
              :width="230"
            >
              <rect
                x="0"
                y="8.5"
                rx="6"
                ry="6"
                width="160"
                height="12"
              />
            </BContentLoader>
          </template>
          <BLayout wrap>
            <BLabel
              v-for="salesforceCampaign in dispSalesforceCampaigns"
              :key="`salesforce-campaign-${salesforceCampaign.id}`"
              class="mr-100 mb-100"
              round
            >
              <span>{{ salesforceCampaign.name }}</span>
            </BLabel>
          </BLayout>
        </VWait>
        <div
          v-if="salesforceCampaigns.length > 10 && !dispAllSalesforceCampaign"
          class="see-more"
          @click="seeMoreCampaign"
        >
          <div class="block-text-color">
            {{ $t('general.seeMore') }}
          </div>
          <BIcon>
            arrow_drop_down
          </BIcon>
        </div>
      </div>
    </BExpansionPanel>
    <BExpansionPanel
      class="profile-block"
      expand
    >
      <template #header>
        <div class="block-title">
          <div class="title-text mr-100">
            {{ $t('freeTag.title') }}
          </div>
          <FreeTagForm
            :candidates="freeTagCandidates"
            :selected-ids="callTargetFreeTags.map((c) => c.id)"
            :call-target-id="callTargetId"
            :loading="freeTagLoading"
            @change="handleChange"
          />
        </div>
      </template>
      <div class="block-content">
        <VWait
          class="full-width"
          for="none"
          :visible="freeTagLoading"
        >
          <template #waiting>
            <BContentLoader
              :height="32"
              :width="230"
            >
              <rect
                x="0"
                y="8.5"
                rx="6"
                ry="6"
                width="160"
                height="12"
              />
            </BContentLoader>
          </template>
          <BFreeTag
            v-for="freeTag in callTargetFreeTags"
            :key="`freeTag-${freeTag.id}`"
            class="mr-100 mb-100"
            :tag="freeTag"
          />
        </VWait>
      </div>
    </BExpansionPanel>
    <div class="profile-area">
      <BExpansionPanel
        class="profile-block"
        expand
      >
        <template #header>
          <div class="block-title">
            <div class="title-text">
              {{ $t('companyInfo.title') }}
            </div>
          </div>
        </template>
        <div class="block-content">
          <LeadDetailListItem
            v-for="(item, index) in companyInfos"
            :key="index"
            v-model:editing-node="editingNode"
            class="mb-200"
            :title="item.title"
            :value="item.value"
            :node="item.node"
            :rules="item.rules"
            :loading="callTargetLoading"
            :items="item.items"
            @save="editSaveCompanyInfo"
          >
            <template
              v-if="item.node === 'companyName'"
              #help
            >
              <BTooltip
                :key="`tooltip1-${item.node}-${item.value}`"
                top
              >
                <a
                  v-if="companyInfo.companyName"
                  :href="searchCompanyName"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BBtn
                    :key="`btn1-${item.node}-${item.value}`"
                    size="mini"
                    fab
                  >
                    <BIcon
                      :key="`icon1-${item.node}-${item.value}`"
                      size="small"
                    >public</BIcon>
                  </BBtn>
                </a>
                <template #content>
                  <span>{{ $t('callTarget.search.companyName') }}</span>
                </template>
              </BTooltip>
            </template>
            <template
              v-else-if="item.node === 'companyUrl' && companyInfo.companyUrl"
              #help
            >
              <BLinkBtn :value="companyInfo.companyUrl" />
            </template>
            <template
              v-else-if="item.node === 'companyTel_1'"
              #help
            >
              <CallButtonContainer
                :phone-number="companyInfo.companyTel1"
              />
            </template>
            <template
              v-else-if="
                item.node === 'companyAddress' && companyAddress != ''
              "
              #help
            >
              <BTooltip top>
                <a
                  :href="searchCompanyAddress"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BBtn
                    size="mini"
                    fab
                  >
                    <BIcon size="small">location_on</BIcon>
                  </BBtn>
                </a>
                <template #content>
                  <span>{{ $t('callTarget.search.address') }}</span>
                </template>
              </BTooltip>
            </template>
          </LeadDetailListItem>
        </div>
      </BExpansionPanel>
      <BExpansionPanel
        class="profile-block"
        expand
      >
        <template #header>
          <div class="block-title">
            <div class="title-text">
              {{ $t('callTargetInfo.title') }}
            </div>
          </div>
        </template>
        <div class="block-content">
          <CallTargetNameItem
            v-for="(item, index) in callTargetNames"
            :key="index"
            v-model:editing-node="editingNode"
            class="mb-200"
            :title="item.title"
            :items="item.items"
            :node="item.node"
            :rules="item.rules"
            :loading="callTargetLoading"
            @save="editSaveCallTargetInfo"
          />
          <LeadDetailListItem
            v-for="(item, index) in callTargetInfos"
            :key="index"
            v-model:editing-node="editingNode"
            class="mb-200"
            :title="item.title"
            :value="item.value"
            :node="item.node"
            :rules="item.rules"
            :loading="callTargetLoading"
            @save="editSaveCallTargetInfo"
          >
            <template #notification>
              <template
                v-if="
                  item.node === 'email' &&
                    !!item.notification
                "
              >
                <BTooltip
                  right
                  :content="$t('duplicatedList.show')"
                >
                  <button
                    class="email-notification"
                    @click="$emit('click-email-notification')"
                  >
                    {{ item.notification }}
                  </button>
                </BTooltip>
              </template>
            </template>
            <template #help>
              <template
                v-if="item.node === 'personalTel' && callTargetInfo.personalTel"
              >
                <CallButtonContainer
                  :phone-number="callTargetInfo.personalTel"
                />
              </template>
              <template
                v-if="
                  isAvailableDirectMailFeature &&
                    item.node === 'email' &&
                    item.value
                "
              >
                <BBtn
                  type="primary"
                  size="mini"
                  fab
                  @click="$emit('click-mail-button')"
                >
                  <BIcon
                    size="small"
                    type="white"
                  >
                    mail
                  </BIcon>
                </BBtn>
              </template>
            </template>
          </LeadDetailListItem>
        </div>
      </BExpansionPanel>
      <BExpansionPanel
        v-if="customFields.length > 0"
        class="profile-block"
        expand
      >
        <template #header>
          <div class="block-title">
            <div class="title-text">
              {{ $t('customField.title') }}
            </div>
          </div>
        </template>
        <div class="block-content">
          <CustomFieldListItem
            v-for="field in customFields"
            :key="field.name"
            v-model:editing-node="editingNode"
            class="mb-200"
            :call-target-id="callTargetId"
            :custom-field-id="field.id"
            :options="field.options"
            :node="field.name"
            :title="field.name"
            :value="customFieldValue(field)"
            :sf-link="customFieldSfLinkValue(field)"
            :loading="customFieldsLoading"
            :type="field.dataType"
            @change="handleChange"
            @save="editCustomContent($event, field)"
          />
        </div>
      </BExpansionPanel>
    </div>
  </div>
</template>

<script>
import lodash from 'lodash'
import { mapWaitingActions } from 'vue-wait'
import { SequenceInstances } from '@/api/user/models/sequence_instances';
import { SfCallTargetReferenceService } from '@/api/user/resources/salesforce/sf_call_target_reference'
import CallButtonContainer from '@/components/organisms/user/lead/detail/CallButtonContainer.vue'
import CallTargetNameItem from '@/components/organisms/user/lead/detail/CallTargetNameItem.vue'
import CustomFieldListItem from '@/components/organisms/user/lead/detail/CustomFieldListItem.vue'
import FreeTagForm from '@/components/organisms/user/lead/detail/FreeTagForm.vue'
import LeadDetailListItem from '@/components/organisms/user/lead/detail/LeadDetailListItem.vue'
import LeadSourceForm from '@/components/organisms/user/lead/detail/LeadSourceForm.vue'
import LeadStageForm from '@/components/organisms/user/lead/detail/LeadStageForm.vue'
import SequenceContainer from '@/components/organisms/user/lead/detail/SequenceContainer.vue';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import inputValidation from '@/mixins/input_validation'

export default {
  components: {
    LeadStageForm,
    LeadSourceForm,
    FreeTagForm,
    LeadDetailListItem,
    CustomFieldListItem,
    CallButtonContainer,
    CallTargetNameItem,
    SequenceContainer,
  },
  mixins: [inputValidation],
  props: {
    leadStages: {
      type: Array,
      default() {
        return []
      },
    },
    callTargetLeadStage: {
      type: Object,
      default() {
        return {
          id: undefined,
          name: undefined,
          colorName: undefined,
          sortOrder: undefined,
          isPreset: undefined,
          createdAt: undefined,
          updatedAt: undefined,
        }
      },
    },
    companyInfo: {
      type: Object,
      default () {
        return {
          companyName: '',
          companyUrl: '',
          companyTel1: '',
          companyCountry: '',
          companyPostalCode: '',
          companyPrefecture: '',
          companyCity: '',
          companyAddressLine: '',
          email: '',
        }
      },
    },
    callTargetInfo: {
      type: Object,
      default () {
        return {
          personalTel: '',
          name: '',
          nameKana: '',
          email: '',
        }
      },
    },
    duplicatedEmailsCount: Number,
    customFields: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: true,
    },
    freeTagCandidates: Array,
    leadSourceCandidates: Array,
    callTargetFreeTags: Array,
    callTargetLeadSources: Array,
    callTargetId: Number,
    callTargetUnsubscribe: Object,
    activeUsers: Array,
    sequenceInstances: SequenceInstances,
    salesforceCampaigns: {
      type: Array,
      default: () => [],
    },
    dispAllSalesforceCampaign: {
      type: Boolean,
      default: true,
    },
    operator: Object,
  },
  emits: [
    'subscribe',
    'unsubscribe',
    'click-email-notification',
    'click-mail-button',
    'update-custom-field-value',
    'update-company-info',
    'update-call-target-info',
    'change',
    'delete-call-target',
    'duplicate-call-target',
    'update-sequence-status',
    'click:sequence-apply',
  ],
  setup() {
    const { isAvailableSequenceFeature, isAvailableDirectMailFeature } = useAvailableFeatureCheck()
    return {
      isAvailableSequenceFeature,
      isAvailableDirectMailFeature,
    }
  },
  data() {
    return {
      editingNode: null,
      loadingOperator: false,
      operatorModalName: 'setOperatorInDetail',
      sfReferenceInfo: null,
    }
  },
  computed: {
    companyInfos() {
      return [
        {
          node: 'companyName',
          title: this.$t('companyInfo.item.companyName'),
          value: this.companyInfo?.companyName,
          rules: [this.requiredRule],
        },
        {
          node: 'companyUrl',
          title: this.$t('companyInfo.item.companyUrl'),
          value: this.companyInfo?.companyUrl,
          rules: [this.urlRule],
        },
        {
          node: 'companyTel_1',
          title: this.$t('companyInfo.item.companyTel'),
          value: this.companyInfo?.companyTel1,
          rules: [this.phoneRule],
        },
        {
          node: 'companyAddress',
          title: this.$t('companyInfo.item.companyAddress'),
          value: this.cmpanyAddress(),
          items: {
            companyCountry: this.companyInfo?.companyCountry,
            companyPostalCode: this.companyInfo?.companyPostalCode,
            companyPrefecture: this.companyInfo?.companyPrefecture,
            companyCity: this.companyInfo?.companyCity,
            companyAddressLine: this.companyInfo?.companyAddressLine,
          },
        },
        {
          node: 'numberOfEmployees',
          title: this.$t('companyInfo.item.numberOfEmployees'),
          value: this.companyInfo?.numberOfEmployees,
        },
        {
          node: 'businessCategory',
          title: this.$t('companyInfo.item.businessCategory'),
          value: this.companyInfo?.businessCategory,
        },
      ]
    },
    callTargetNames() {
      return [
        {
          node: 'fullName',
          title: {
            label: this.$t('callTargetInfo.item.name'),
            value: this.callTargetInfo?.name,
          },
          items: {
            salutation: this.callTargetInfo?.salutation,
            lastName: this.callTargetInfo?.lastName,
            firstName: this.callTargetInfo?.firstName,
          },
        },
        {
          node: 'fullNameKana',
          title: {
            label: this.$t('callTargetInfo.item.nameKana'),
            value: this.callTargetInfo?.nameKana,
          },
          items: {
            lastNameKana: this.callTargetInfo?.lastNameKana,
            firstNameKana: this.callTargetInfo?.firstNameKana,
          },
          rules: [this.kanaRule],
        },
      ]
    },
    callTargetInfos() {
      return [
        {
          node: 'personalTel',
          title: this.$t('callTargetInfo.item.personalTel'),
          value: this.callTargetInfo?.personalTel,
          rules: [this.phoneRule],
        },
        {
          node: 'email',
          title: this.$t('callTargetInfo.item.email'),
          value: this.callTargetInfo?.email,
          rules: [this.emailRule],
          notification: this.duplicatedEmailsCount || null,
        },
        {
          node: 'department',
          title: this.$t('callTargetInfo.item.department'),
          value: this.callTargetInfo?.department,
        },
        {
          node: 'position',
          title: this.$t('callTargetInfo.item.position'),
          value: this.callTargetInfo?.position,
        },
      ]
    },
    searchCompanyName() {
      const target = encodeURIComponent(`${this.companyInfo.companyName}`)
      return `${this.$t('general.googleSearch')}${target}`
    },
    searchCompanyAddress() {
      const target = encodeURIComponent(`${this.cmpanyAddress()}`)
      return `${this.$t('general.googleMapSearch')}${target}`
    },
    freeTagLoading() {
      return this.$wait.is([
        'getCallTargetFreeTagsWait',
        'deleteCallTargetFreeTagWait',
        'appendCallTargetFreeTagWait',
      ])
    },
    operatorLoading() {
      return this.$wait.is([
        'createOperatorWait',
        'getOperatorWait',
        'withdrawOperatorWait',
      ])
    },
    leadSourceLoading() {
      return this.$wait.is([
        'getCallTargetLeadSourcesWait',
        'deleteCallTargetLeadSourceWait',
        'appendCallTargetLeadSourceWait',
      ])
    },
    callTargetLoading() {
      return this.$wait.is('updateCallTargetWait')
    },
    customFieldsLoading() {
      return this.$wait.is('updateCustomFieldValueWait')
    },
    isUnsubscribed() {
      return this.callTargetUnsubscribe.createdAt != null
    },
    unsubscribedMessage() {
      if (this.callTargetUnsubscribe == null) return ''
      return this.callTargetUnsubscribe.message
    },
    isUnsubscribable() {
      return this.callTargetInfo?.email.length > 0
    },
    companyName() {
      if (this.companyInfo == null) return '-'
      return this.companyInfo.companyName
    },
    targetName() {
      if (this.callTargetInfo == null) return '-'
      return this.callTargetInfo.name || this.$t('callTarget.nameless')
    },
    activeSequenceInstance() {
      return this.sequenceInstances?.findActive()
    },
    customFieldValue() {
      return (field) => {
        if (field.dataType != 'sf_reference') return field.value
        if (this.sfReferenceInfo == null) return 'loading'

        const fieldValue = this.sfReferenceInfo[`custom_field_${field.id}`]
        if (fieldValue == null) {
          return '未連携'
        }
        return fieldValue.name
      }
    },
    customFieldSfLinkValue() {
      return (field) => {
        if (field.dataType != 'sf_reference') return null
        if (this.sfReferenceInfo == null) return null
        const fieldValue = this.sfReferenceInfo[`custom_field_${field.id}`]
        if (fieldValue == null) {
          return null
        }
        return fieldValue.sf_link
      }
    },
    dispSalesforceCampaigns() {
      if (this.dispAllSalesforceCampaign) return this.salesforceCampaigns
      return this.salesforceCampaigns.slice(0, 10)
    },
  },
  methods: {
    ...mapWaitingActions('userUi', {
      setTargetHeaderMoveButtonDisabledAction:
        'setTargetHeaderMoveButtonDisabledWait',
      setSelectedSequenceInstanceIdAction:
        'setSelectedSequenceInstanceIdWait',
      setTargetSequenceInstanceFormVisibleAction:
        'setTargetSequenceInstanceFormVisibleWait',
    }),
    ...mapWaitingActions('user', {
      getCallTargetsBackgroundAction: { action: 'getCallTargetsAction', loader: 'getCallTargetsBackgroundWait' },
      createOperatorAction: 'createOperatorWait',

      getOperatorAction: 'getOperatorWait',
      withdrawOperatorAction: 'withdrawOperatorWait',
    }),
    editCustomContent({ event, node }, customField) {
      if (this.$wait.is('updateCustomFieldValuesWait')) {
        alert(this.$t('callTarget.waitingUpdate'))
        return
      }
      this.setTargetHeaderMoveButtonDisabledAction(true)
      const newCustomField = lodash.cloneDeep(customField)
      newCustomField.value = event
      this.$emit('update-custom-field-value', newCustomField)
    },
    editSaveCompanyInfo(callTarget) {
      this.setTargetHeaderMoveButtonDisabledAction(true)
      if (this.$wait.is('updateCallTargetWait')) {
        alert(this.$t('callTarget.waitingUpdate'))
        return
      }
      this.$emit('update-company-info', callTarget)
    },
    openSetOperatorModal () {
      this.$modal.show(this.operatorModalName)
    },
    async assignOperator (selectedOperatorId) {
      try {
        await this.createOperatorAction({
          body: {
            operatorId: selectedOperatorId,
            callTargetId: this.callTargetId,
          },
        })
        await this.getOperatorAction(this.callTargetId)
        this.$toast.show(this.$t('general.done.of', { action: this.$t('operatorAssign.of', { item: this.$t('home.member') }) }))
      } catch (e) {
        this.$toast.show({
          message: this.$t('general.fail.to', { action: this.$t('operatorAssign.assign') }),
          type: 'error',
        })
      } finally {
        this.$modal.hide(this.operatorModalName)
      }
      this.getCallTargetsBackground()
    },
    async withdrawOperator () {
      try {
        await this.withdrawOperatorAction(this.callTargetId)
        this.$toast.show(this.$t('general.done.of', { action: `${this.$t('operatorAssign.assign')}${this.$t('general.withdraw.text')}` }))
      } catch (e) {
        this.$toast.show({
          message: this.$t('general.fail.to', { action: `${this.$t('operatorAssign.assign')}${this.$t('general.withdraw.text')}` }),
          type: 'error',
        })
      } finally {
        this.$modal.hide(this.operatorModalName)
      }
      this.getCallTargetsBackground()
    },
    async getCallTargetsBackground () {
      this.$toast.show({ message: this.$t('callList.update.loading'), type: 'info' })
      await this.getCallTargetsBackgroundAction()
      this.$toast.show({ message: this.$t('callList.update.done'), type: 'success' })
    },
    editSaveCallTargetInfo(callTarget) {
      this.setTargetHeaderMoveButtonDisabledAction(true)
      if (this.$wait.is('updateCallTargetWait')) {
        alert(this.$t('callTarget.waitingUpdate'))
        return
      }
      this.$emit('update-call-target-info', callTarget)
    },
    handleChange() {
      this.$emit('change')
    },
    deleteCallTarget(callTargetId) {
      this.$emit('delete-call-target', callTargetId)
    },
    duplicateCallTarget(callTargetId) {
      this.$emit('duplicate-call-target', callTargetId)
    },
    isExistOperator (operator) {
      return Object.keys(operator).length !== 0
    },
    handleSequenceWidgetOpen() {
      this.setSelectedSequenceInstanceIdAction(this.activeSequenceInstance.id)
      this.setTargetSequenceInstanceFormVisibleAction(true)
    },
    handleUpdateSequenceStatus(action) {
      this.$emit('update-sequence-status', action, this.activeSequenceInstance.id)
    },
    handleClickSequenceApply() {
      this.$emit('click:sequence-apply');
    },
    cmpanyAddress() {
      return this.companyAddress = (`${this.companyInfo?.companyCountry}${this.companyInfo?.companyPostalCode}`
        + ` ${this.companyInfo?.companyPrefecture}${this.companyInfo?.companyCity}`
        + `${this.companyInfo?.companyAddressLine}`).trim()
    },
    seeMoreCampaign() {
      this.$emit('click-disp-sf-campaign-button')
    },
  },
  async mounted() {
    const apiService = new SfCallTargetReferenceService()
    const value = await apiService.getCallTargetSfReferenceInfo({ callTargetId: this.callTargetId })
    this.sfReferenceInfo = value.data
  },
}
</script>

<style lang="scss" scoped>
.see-more  {
  display: flex;
  float: right;
  .block-text-color {
    color: $basecolor-primary;
    font-size: $fontsize-100;
  }
}
.profile-block {
  padding: 0 $basespace-400;

  &:not(:last-child) {
    margin-bottom: $basespace-400;
  }

  .block-title {
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 600;
  }
  .set-operator-btn {
    width: 200px;
    height: 42px;
    &:hover {
      cursor: pointer;
      background: #F6F6F6;
      border-radius: 6px;
    }
    .operator-info {
      display: flex;
      align-items: center;
      padding: 3px;
      .v-avatar {
        border-radius: 50%;
      }
      .operator-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        margin: 7px;
        color: #2B2B2B;
        height: 22px;
        line-height: 160%;
        font-family: Rubik;
        font-size: 14px;
      }
    }
  }

  .set-operator-round-btn {
    border: none ;
    &:hover {
      border-radius: 50%;
    }
    .b-empty-box {
      border-radius: 50%;
      width: 36px;
      height: 36px;
      padding: 9px;
      &:hover {
        background-color: #F6F6F6;
      }
      .b-icon {
        font-size: 16px;
      }
    }
  }
}

.list-title {
  position: relative;
  width: 90px;
  min-width: 90px;
  max-width: 90px;
  background-color: $bgcolor-dark;
  padding: 4px $basespace-200;
}

.list-content {
  position: relative;
  width: 100%;
  word-wrap: break-word;
  word-break: break-all;
}

.full-width {
  width: 100%;
}

.top-content {
  justify-content: space-between;
  align-items: center;
}

.email-notification {
  color: $textcolor-white;
  background-color: $basecolor-error;
  height: 18px;
  border-radius: 20px;
  width: 38px;
}
</style>

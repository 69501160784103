import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "truncate-text" }
const _hoisted_2 = { class: "truncate-text truncate-item type-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BLabel = _resolveComponent("BLabel")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BUserTooltip = _resolveComponent("BUserTooltip")!
  const _component_LeadLink = _resolveComponent("LeadLink")!

  return (_openBlock(), _createBlock(_component_LeadLink, {
    "call-target-id": _ctx.callTargetId,
    tag: "div",
    class: "mail-notification-item"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BTooltip, { top: "" }, {
        content: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('mailNotificationEvent.eventCount', {count: _ctx.item.eventCount})), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_BLabel, {
            type: "primary",
            class: "event ml-400",
            style: _normalizeStyle(_ctx.getColumnStyles(_ctx.columnWidth[0])),
            round: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BIcon, {
                type: "white",
                size: "small"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.icon), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["style"])
        ]),
        _: 1
      }),
      _createElementVNode("span", {
        class: "ml-400",
        style: _normalizeStyle(_ctx.getColumnStyles(_ctx.columnWidth[1]))
      }, _toDisplayString(_ctx.item.event), 5),
      _createElementVNode("span", {
        class: "truncate-text truncate-item ml-400",
        style: _normalizeStyle(_ctx.getColumnStyles(_ctx.columnWidth[2]))
      }, _toDisplayString(_ctx.item.companyName), 5),
      (_ctx.item.isBulkMail)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "ml-400",
            style: _normalizeStyle(_ctx.getColumnStyles(_ctx.columnWidth[3]))
          }, [
            _createVNode(_component_BLabel, {
              class: "type-label bulk",
              round: ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.item.name), 1)
              ]),
              _: 1
            })
          ], 4))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "ml-400",
            style: _normalizeStyle(_ctx.getColumnStyles(_ctx.columnWidth[3]))
          }, [
            _createVNode(_component_BLabel, {
              class: "type-label",
              type: "default",
              round: ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.item.name), 1)
              ]),
              _: 1
            })
          ], 4)),
      _createElementVNode("div", {
        class: "time ml-400",
        style: _normalizeStyle(_ctx.getColumnStyles(_ctx.columnWidth[5]))
      }, _toDisplayString(_ctx.historiedAt), 5),
      (_ctx.isExistUser)
        ? (_openBlock(), _createBlock(_component_BUserTooltip, {
            key: 2,
            "image-url": _ctx.item.sentUserAvatarUrl,
            name: _ctx.item.sentUserName,
            "user-id": _ctx.item.sentUserId,
            class: "user-icon",
            size: 40
          }, null, 8, ["image-url", "name", "user-id"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["call-target-id"]))
}
import ApiService, { ApiArguments } from '@/api/api_service';
import {
  GetSfLeadSyncErrorLogs200Response,
  SfLeadSyncLogsApi,
  SfLeadSyncLogsApiGetSfLeadSyncErrorLogsRequest,
} from '@/api/openapi';



export class SfLeadSyncLogsService extends ApiService {
  api = new SfLeadSyncLogsApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    // openapiで作ったやつはtrue
    super(true)
  }

  async getSfLeadSyncErrorLogs({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SfLeadSyncLogsApiGetSfLeadSyncErrorLogsRequest>) {
    return this.checkResponse<GetSfLeadSyncErrorLogs200Response>(
      this.api.getSfLeadSyncErrorLogs(request, options),
      errorHandlers,
    );

  }
}

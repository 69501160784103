import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "block-title" }
const _hoisted_2 = { class: "mr-100" }
const _hoisted_3 = { class: "block-content" }

import { computed } from 'vue';
import { CustomFieldValueApiService } from '@/api/user/resources/custom_field_value';

type TCustomFieldOption = {
  id: number;
  label: string;
};

type TProps = {
  callTargetId: number;
  customFieldId: number;
  title: string;
  loading: boolean;
  items: TCustomFieldOption[]; // [{ id: xxxx, label: xxxx }]
  dataTest: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomFieldMultiOption',
  props: /*@__PURE__*/_mergeModels({
    callTargetId: {},
    customFieldId: {},
    title: {},
    loading: { type: Boolean },
    items: {},
    dataTest: {}
  }, {
    "modelValue": { default: [] },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const props = __props;
const api = new CustomFieldValueApiService();

const model = _useModel<string[]>(__props, "modelValue")

const selectedItems = computed(() =>
  props.items.filter(item => model.value.includes(item.label)),
);

const attributes = computed(() => {
  return {
    'candidates': props.items,
    'selectedIds': selectedItems.value.map(item => item.id),
    'loading': props.loading,
    'item-text': 'label',
  }
});

const handleClickAppend = async (customFieldOptionId: number) => {
  const apiArguments = getApiArguments(customFieldOptionId);
  await api.postCallTargetCustomFieldValue(apiArguments);

  const item = props.items.find(item => item.id === customFieldOptionId)
  model.value = model.value.concat(item.label)
};

const handleClickDelete = async (customFieldOptionId: number) => {
  const apiArguments = getApiArguments(customFieldOptionId);
  await api.deleteCallTargetCustomFieldValue(apiArguments);

  const item = props.items.find(item => item.id === customFieldOptionId)
  model.value = model.value.filter(v => v !== item.label)
};

const getApiArguments = (customFieldOptionId: number) => {
  return {
    request: {
      callTargetId: props.callTargetId,
      callTargetCustomFieldValueBody: {
        customFieldValue: {
          customFieldId: props.customFieldId,
          customFieldOptionId,
        },
      },
    },
  };
};

return (_ctx: any,_cache: any) => {
  const _component_BSelectMenu = _resolveComponent("BSelectMenu")!
  const _component_BContentLoader = _resolveComponent("BContentLoader")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLabel = _resolveComponent("BLabel")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_VWait = _resolveComponent("VWait")!
  const _component_BListItem = _resolveComponent("BListItem")!

  return (_openBlock(), _createBlock(_component_BListItem, { class: "mb-200" }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createVNode(_component_BSelectMenu, _mergeProps(attributes.value, {
          "data-test": _ctx.dataTest,
          onClickAppend: handleClickAppend,
          onClickDelete: handleClickDelete
        }), null, 16, ["data-test"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_VWait, {
          class: "full-width",
          for: "none",
          visible: _ctx.$wait.is(['getCallTargetWait'])
        }, {
          waiting: _withCtx(() => [
            _createVNode(_component_BContentLoader, {
              height: 32,
              width: 230
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("rect", {
                  x: "0",
                  y: "8.5",
                  rx: "6",
                  ry: "6",
                  width: "160",
                  height: "12"
                }, null, -1)
              ])),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_BLayout, { wrap: "" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedItems.value, (item) => {
                  return (_openBlock(), _createBlock(_component_BLabel, {
                    key: `custom-field-multi-option-${item.id}`,
                    class: "mr-100 mb-100",
                    round: ""
                  }, {
                    right: _withCtx(() => [
                      _createVNode(_component_BBtn, {
                        flat: "",
                        fit: "",
                        size: "mini",
                        class: "close-button",
                        onClick: () => handleClickDelete(item.id)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_BIcon, {
                            type: "gray",
                            size: "mini"
                          }, {
                            default: _withCtx(() => _cache[1] || (_cache[1] = [
                              _createTextVNode(" close ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(item.label), 1)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["visible"])
      ])
    ]),
    _: 1
  }))
}
}

})
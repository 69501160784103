import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "analytics-nav" }
const _hoisted_2 = { class: "analytics-card" }
const _hoisted_3 = { class: "analytics-header" }
const _hoisted_4 = { class: "mt-300" }
const _hoisted_5 = { class: "ml-100 pb-600" }
const _hoisted_6 = { class: "pt-600 pb-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BCustomIcon = _resolveComponent("BCustomIcon")!
  const _component_LeadViewSelectPanel = _resolveComponent("LeadViewSelectPanel")!
  const _component_BCustomIconText = _resolveComponent("BCustomIconText")!
  const _component_BDateRangeForm = _resolveComponent("BDateRangeForm")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_NumLeadInList = _resolveComponent("NumLeadInList")!
  const _component_Graph = _resolveComponent("Graph")!
  const _component_CallResultTable = _resolveComponent("CallResultTable")!
  const _component_MemberTable = _resolveComponent("MemberTable")!
  const _component_BTabs = _resolveComponent("BTabs")!
  const _component_BContent = _resolveComponent("BContent")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_BContent, null, {
    navbar: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BCustomIcon, {
          "icon-class": "b-call-analytics",
          class: "mt-50"
        }),
        _createVNode(_component_LeadViewSelectPanel, {
          class: "mt-100",
          onAfterSetLeadView: _ctx.changeLeadView
        }, null, 8, ["onAfterSetLeadView"]),
        _createVNode(_component_BCustomIconText, {
          hover: "",
          "text-size": "mini",
          "icon-size": "large",
          "icon-class": "b-list",
          class: "mt-100",
          onClick: _ctx.moveLeadList
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('callAnalytics.moveLeadList')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('callAnalytics.dateRange')), 1),
          _createVNode(_component_BListItem, { class: "data-selector period-selector" }, {
            default: _withCtx(() => [
              _createVNode(_component_BDateRangeForm, {
                modelValue: _ctx.date,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
                range: ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _withDirectives(_createVNode(_component_NumLeadInList, { class: "mt-300" }, null, 512), [
            [_directive_loading, _ctx.loading]
          ])
        ]),
        _createVNode(_component_BTabs, {
          class: "pl-100",
          "label-items": _ctx.tabLabelItems
        }, {
          summary: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _withDirectives(_createVNode(_component_Graph, {
                "data-test": "reachCountParCallCount",
                class: "summary-graph",
                title: _ctx.$t('callAnalytics.reachCountParCallCount'),
                "chart-data": _ctx.reachCountParCallChartData.data,
                options: _ctx.reachCountParCallChartData.options
              }, null, 8, ["title", "chart-data", "options"]), [
                [_directive_loading, _ctx.loading]
              ]),
              _withDirectives(_createVNode(_component_Graph, {
                "data-test": "opportunityCountParReachCount",
                class: "summary-graph",
                title: _ctx.$t('callAnalytics.opportunityCountParReachCount'),
                "chart-data": _ctx.opportunityCountParReachCountChartData.data,
                options: _ctx.opportunityCountParReachCountChartData.options
              }, null, 8, ["title", "chart-data", "options"]), [
                [_directive_loading, _ctx.loading]
              ]),
              _withDirectives(_createVNode(_component_Graph, {
                "data-test": "opportunityCountParCallCount",
                class: "summary-graph",
                title: _ctx.$t('callAnalytics.opportunityCountParCallCount'),
                "chart-data": _ctx.opportunityCountParCallCountChartData.data,
                options: _ctx.opportunityCountParCallCountChartData.options
              }, null, 8, ["title", "chart-data", "options"]), [
                [_directive_loading, _ctx.loading]
              ]),
              _withDirectives(_createVNode(_component_CallResultTable, {
                "data-test": "callResultTable",
                class: "summary-table",
                "table-data": _ctx.callResultTableData
              }, null, 8, ["table-data"]), [
                [_directive_loading, _ctx.loading]
              ])
            ])
          ]),
          member: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _withDirectives(_createVNode(_component_MemberTable, {
                class: "summary-content",
                "table-data": _ctx.memberTableData
              }, null, 8, ["table-data"]), [
                [_directive_loading, _ctx.loading]
              ])
            ])
          ]),
          _: 1
        }, 8, ["label-items"])
      ])
    ]),
    _: 1
  }))
}
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withKeys as _withKeys, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "b-input-wrapper" }
const _hoisted_2 = { class: "b-input-inner" }
const _hoisted_3 = {
  key: 0,
  class: "b-input-tools"
}
const _hoisted_4 = {
  key: 0,
  class: "check-icon"
}
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_BCopyButton = _resolveComponent("BCopyButton")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BTooltip = _resolveComponent("BTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ElInput, _mergeProps(_ctx.attributes, {
        id: _ctx.inputId,
        ref: "input",
        modelValue: _ctx.internalValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
        class: ["b-input", _ctx.inputClass],
        onKeydown: _withKeys(_ctx.keyDownEnter, ["enter"]),
        onFocus: _ctx.focus,
        onBlur: _ctx.blur,
        onKeyup: _ctx.keyup
      }), _createSlots({ _: 2 }, [
        (_ctx.$slots.prefix)
          ? {
              name: "prefix",
              fn: _withCtx(() => [
                _renderSlot(_ctx.$slots, "prefix", {}, undefined, true)
              ]),
              key: "0"
            }
          : undefined,
        (_ctx.$slots.prepend)
          ? {
              name: "prepend",
              fn: _withCtx(() => [
                _renderSlot(_ctx.$slots, "prepend", {}, undefined, true)
              ]),
              key: "1"
            }
          : undefined,
        (_ctx.$slots.append)
          ? {
              name: "append",
              fn: _withCtx(() => [
                _renderSlot(_ctx.$slots, "append", {}, undefined, true)
              ]),
              key: "2"
            }
          : undefined
      ]), 1040, ["id", "modelValue", "class", "onKeydown", "onFocus", "onBlur", "onKeyup"]),
      (_ctx.copyable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_BCopyButton, {
              text: _ctx.internalValue,
              title: _ctx.placeholder || _ctx.type
            }, null, 8, ["text", "title"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_Transition, { name: "el-fade-in-linear" }, {
        default: _withCtx(() => [
          (_ctx.isExistValidation && _ctx.statusObject)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_BTooltip, {
                  disabled: _ctx.isValid,
                  top: "",
                  value: _ctx.isError
                }, {
                  content: _withCtx(() => [
                    (_ctx.isRequired && !_ctx.isError)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('validation.required')), 1))
                      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.errorMessages, (message) => {
                          return (_openBlock(), _createElementBlock("div", { key: message }, _toDisplayString(message), 1))
                        }), 128))
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_BIcon, {
                      size: "small",
                      class: _normalizeClass(_ctx.statusObject.class)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.statusObject.icon), 1)
                      ]),
                      _: 1
                    }, 8, ["class"])
                  ]),
                  _: 1
                }, 8, ["disabled", "value"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("input", {
      type: "text",
      style: {"display":"none"}
    }, null, -1))
  ]))
}
import ApiBase from '@/api/base'

export default {
  signinUser () {
    return ApiBase.post(`/user/signin`)
  },
  signoutUser () {
    return ApiBase.post('/user/signout')
  },
}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Bar = _resolveComponent("Bar")!

  return (_openBlock(), _createBlock(_component_Bar, {
    "chart-id": _ctx.chartId,
    "chart-options": _ctx.options,
    "chart-data": _ctx.chartData,
    width: _ctx.width,
    height: _ctx.height
  }, null, 8, ["chart-id", "chart-options", "chart-data", "width", "height"]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "ml-50 fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoForm = _resolveComponent("InfoForm")!
  const _component_ResultForm = _resolveComponent("ResultForm")!
  const _component_BDivider = _resolveComponent("BDivider")!
  const _component_CustomActionForm = _resolveComponent("CustomActionForm")!
  const _component_NextActionForm = _resolveComponent("NextActionForm")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BDrawerMenu = _resolveComponent("BDrawerMenu")!
  const _component_QuickTextSelectModal = _resolveComponent("QuickTextSelectModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BDrawerMenu, {
      modelValue: _ctx.drawer,
      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.drawer) = $event)),
      title: _ctx.$t('callResult.title'),
      "disabled-close": _ctx.disabledClose,
      onCancel: _ctx.handleCancel
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_BBtn, {
            text: "",
            type: "success",
            onClick: _ctx.handleQuickTextButtonClick
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BIcon, {
                type: "success",
                outlined: ""
              }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createTextVNode(" article ")
                ])),
                _: 1
              }),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('quickText.apply')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_BInput, {
            ref: "commentTextarea",
            modelValue: _ctx.callResult.comment,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.callResult.comment) = $event)),
            type: "textarea",
            class: "mb-200",
            placeholder: _ctx.$t('callResult.comment.text'),
            rows: 11,
            autosize: false,
            resize: "none",
            copyable: ""
          }, null, 8, ["modelValue", "placeholder"]),
          _createVNode(_component_BLayout, {
            "align-center": "",
            "justify-center": "",
            class: "mt-400"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BBtn, {
                class: "mr-300",
                text: "",
                disabled: _ctx.waiting,
                onClick: _ctx.handleCancel
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.cancel.text')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]),
              _createVNode(_component_BBtn, {
                class: "ml-300",
                type: "primary",
                loading: _ctx.waiting,
                disabled: _ctx.saveButtonDisabled,
                "data-test": "saveCallResultBtn",
                onClick: _ctx.clickSaveButton
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.save.text')), 1)
                ]),
                _: 1
              }, 8, ["loading", "disabled", "onClick"])
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_InfoForm, {
          "started-at": _ctx.callResult.startedAt,
          "onUpdate:startedAt": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.callResult.startedAt) = $event)),
          "ended-at": _ctx.callResult.endedAt,
          "onUpdate:endedAt": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.callResult.endedAt) = $event)),
          "miitel-result-url": _ctx.callResult.miitelResultUrl,
          "onUpdate:miitelResultUrl": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.callResult.miitelResultUrl) = $event)),
          "time-error": _ctx.timeError,
          "onUpdate:timeError": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.timeError) = $event)),
          "zoom-phone-call-id": _ctx.callResult.zoomPhoneCallId,
          class: "mb-300"
        }, null, 8, ["started-at", "ended-at", "miitel-result-url", "time-error", "zoom-phone-call-id"]),
        _createVNode(_component_ResultForm, {
          connected: _ctx.callResult.connected,
          "onUpdate:connected": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.callResult.connected) = $event)),
          "connected-to": _ctx.callResult.connectedTo,
          "onUpdate:connectedTo": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.callResult.connectedTo) = $event)),
          result: _ctx.callResult.result,
          "onUpdate:result": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.callResult.result) = $event))
        }, null, 8, ["connected", "connected-to", "result"]),
        (_ctx.callResultFormItemCount)
          ? (_openBlock(), _createBlock(_component_BDivider, {
              key: 0,
              class: "my-500"
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_CustomActionForm, {
          modelValue: _ctx.callResult.customActionOptionValues,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.callResult.customActionOptionValues) = $event)),
          onAfterFetch: _cache[8] || (_cache[8] = ($event: any) => (_ctx.callResultFormItemCount = $event))
        }, null, 8, ["modelValue"]),
        (!_ctx.isEdit)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_BDivider, { class: "my-500" }),
              _createVNode(_component_NextActionForm, {
                "next-actions": _ctx.callResult.nextActions,
                "onUpdate:nextActions": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.callResult.nextActions) = $event)),
                "is-valid": _ctx.isNextActionsValid,
                "onUpdate:isValid": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.isNextActionsValid) = $event)),
                "call-target-id": _ctx.callTargetId
              }, null, 8, ["next-actions", "is-valid", "call-target-id"])
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "title", "disabled-close", "onCancel"]),
    _createVNode(_component_QuickTextSelectModal, {
      "modal-name": "quickTextSelectInCallResultForm",
      "call-target-id": _ctx.callTargetId,
      onSelect: _ctx.handleQuickTextSelect
    }, null, 8, ["call-target-id", "onSelect"])
  ]))
}
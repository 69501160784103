import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BInputDateTime = _resolveComponent("BInputDateTime")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_NextActionFormBody = _resolveComponent("NextActionFormBody")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BDrawerMenu = _resolveComponent("BDrawerMenu")!

  return (_openBlock(), _createBlock(_component_BDrawerMenu, {
    modelValue: _ctx.drawer,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.drawer) = $event)),
    title: _ctx.$t('general.nextAction'),
    onCancel: _ctx.handleCancel
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BLayout, {
          "align-center": "",
          "justify-center": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BBtn, {
              class: "mr-300",
              text: "",
              disabled: _ctx.waiting,
              onClick: _ctx.handleCancel
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.cancel.text')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"]),
            _createVNode(_component_BBtn, {
              disabled: !_ctx.isValid,
              class: "ml-300",
              type: "primary",
              loading: _ctx.waiting,
              onClick: _ctx.saveNextAction
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.save.text')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "loading", "onClick"])
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_NextActionFormBody, {
          modelValue: _ctx.internalCommonNextAction,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.internalCommonNextAction) = $event)),
          "is-valid": _ctx.isFormValid,
          "onUpdate:isValid": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isFormValid) = $event)),
          "call-target-id": _ctx.callTargetId
        }, {
          dateTime: _withCtx(() => [
            _createVNode(_component_BListItem, { class: "mb-300" }, {
              header: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('nextAction.reservedAt')), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_BInputDateTime, {
                  date: _ctx.reservedDate,
                  "onUpdate:date": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.reservedDate) = $event)),
                  time: _ctx.reservedTime,
                  "onUpdate:time": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.reservedTime) = $event)),
                  "is-step": "",
                  clearable: ""
                }, null, 8, ["date", "time"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "is-valid", "call-target-id"]),
        (_ctx.nextAction.id)
          ? (_openBlock(), _createBlock(_component_BListItem, { key: 0 }, {
              header: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('nextAction.createdAt')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.firstSetUpAt), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title", "onCancel"]))
}
<template>
  <BTooltip
    top
    :content="tooltipText"
    :disabled="!disabled"
  >
    <BLayout
      class="mt-300 mb-300"
      align-center
      justify-center
    >
      <BCheckbox
        v-model="checkboxValue"
        :disabled="disabled"
        :label="i18n.t('opportunity.associateWithSequence')"
      />
    </BLayout>
  </BTooltip>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

type TProps = {
  modelValue: number | null | undefined;
  tooltipTextWhenReadonly: string;
  readonly: boolean;
  sequenceId: number | undefined;
};
type TEmit = {
  (event: 'update:modelValue', sequenceId: number | null): void;
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const i18n = useI18n();

const checkboxValue = computed<boolean>({
  get() {
    return !!props.modelValue;
  },
  set(value: boolean) {
    const newValue = value ? props.sequenceId : null;
    emit('update:modelValue', newValue);
  },
})

const disabled = computed<boolean>(() => {
  return !!tooltipText.value;
});

const tooltipText = computed<string>(() => {
  if (props.readonly) return props.tooltipTextWhenReadonly;
  if (!props.sequenceId) return i18n.t('opportunity.notAvailableByNoSequence');
  return '';
});
</script>

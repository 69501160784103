<template>
  <BCard>
    <slot name="title">
      <span class="headline">{{ $t(`userManagement.title`) }}</span>
    </slot>
    <div class="container">
      <div class="mb-500">
        <BInput
          v-model="inputName"
          v-model:valid="valid"
          :label="$t(`userManagement.name`)"
          :rules="[requiredRule]"
        />
      </div>
      <div class="mb-500">
        <BInput
          v-model="inputLastName"
          v-model:valid="valid"
          :label="$t(`userManagement.lastName`)"
          :rules="[requiredRule]"
        />
      </div>
      <div class="mb-500">
        <BInput
          v-model="inputFirstName"
          v-model:valid="valid"
          :label="$t(`userManagement.firstName`)"
          :rules="[requiredRule]"
        />
      </div>
      <div class="mb-500">
        <BInput
          v-model="inputEmail"
          v-model:valid="valid"
          :label="$t(`userManagement.email`)"
          :rules="[requiredRule]"
        />
      </div>
      <div class="mb-500">
        <BCheckbox
          v-model="inputRole"
          :label="$t(`userManagement.role`)"
          color="focus"
        />
      </div>
      <div class="mb-500">
        <BCheckbox
          v-model="inputActivated"
          :label="$t(`userManagement.activated`)"
          color="focus"
        />
      </div>
    </div>
    <slot name="actions">
      <BLayout
        align-center
        justify-center
      >
        <BBtn
          class="mr-400"
          text
          @click="cancel"
        >
          {{ $t(`general.cancel.text`) }}
        </BBtn>
        <BBtn
          class="ml-400"
          type="primary"
          :disabled="!valid"
          :loading="loading"
          @click="save"
        >
          {{ $t(`general.save.text`) }}
        </BBtn>
      </BLayout>
    </slot>
  </BCard>
</template>

<script>
import inputValidation from '@/mixins/input_validation'

export default {
  mixins: [inputValidation],
  props: {
    name: String,
    lastName: String,
    firstName: String,
    email: String,
    role: String,
    activated: String,
    loading: Boolean,
  },
  emits: [
    'save',
    'cancel',
  ],
  data () {
    return {
      valid: false,
      inputName: null,
      inputLastName: null,
      inputFirstName: null,
      inputEmail: null,
      inputRole: null,
      inputActivated: null,
    }
  },
  watch: {
    name (newVal) {
      this.inputName = newVal
    },
    lastName (newVal) {
      this.inputLastName = newVal
    },
    firstName (newVal) {
      this.inputFirstName = newVal
    },
    email (newVal) {
      this.inputEmail = newVal
    },
    role (newVal) {
      this.inputRole = this.convertRole(newVal)
    },
    activated (newVal) {
      this.inputActivated = this.convertActivated(newVal)
    },
  },
  created () {
    this.reset()
  },
  methods: {
    async save () {
      let confirmEmailChange = true

      if (!this.valid) return
      if (this.email != this.inputEmail) {
        confirmEmailChange = await this.$bitterAlert.show({
          title: this.$t('general.confirm.text'),
          text: this.$t('auth.changeEmailMessage'),
        })
      }

      if (!confirmEmailChange) {
        this.$emit('cancel')
        return
      }

      const user = {
        name: this.inputName,
        lastName: this.inputLastName,
        firstName: this.inputFirstName,
        email: this.inputEmail,
        role: this.inputRole ? 'owner' : 'member',
        activated: this.inputActivated,
      }
      this.$emit('save', user)
    },
    cancel () {
      this.$emit('cancel')
    },
    reset () {
      this.inputName = this.name
      this.inputLastName = this.lastName
      this.inputFirstName = this.firstName
      this.inputEmail = this.email
      this.inputRole = this.convertRole(this.role)
      this.inputActivated = this.convertActivated(this.activated)
    },
    convertActivated (activated) {
      if (activated === 'disabled') {
        return false
      } else if (activated === 'enabled') {
        return true
      } else {
        return null
      }
    },
    convertRole (role) {
      if (role === 'member') {
        return false
      } else if (role === 'owner') {
        return true
      } else {
        return null
      }
    },
  },
}
</script>

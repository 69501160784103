import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sequence-master-simple-select-container" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { key: 0 }

import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { SequenceMaster } from '@/api/openapi';
import { SequenceMasterApiService } from '@/api/user/resources/sequence_master';
import { useWait } from '@/composable/vue-wait';

type TProps = {
  required: boolean;
  isDisplayOnly: boolean;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceMasterSimpleSelect',
  props: /*@__PURE__*/_mergeModels({
    required: { type: Boolean },
    isDisplayOnly: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
    "isValid": { type: Boolean, ...{ default: false } },
    "isValidModifiers": {},
  }),
  emits: ["update:modelValue", "update:isValid"],
  setup(__props: any) {

const props = __props;

const i18n = useI18n();
const router = useRouter();
const { doActionWithWait } = useWait();

const model = _useModel<number | null>(__props, "modelValue")
const isValid = _useModel<boolean>(__props, 'isValid');

const sequencePath = computed(() => {
  if (!model.value) {
    return '';
  }
  const id = model.value;
  return router.resolve({ name: 'Sequence', params: { id } }).href; 
});

const sequenceMasters = ref<SequenceMaster[]>([]);
const loadSequenceMasters = async () => {
  const api = new SequenceMasterApiService();
  await doActionWithWait('fetchSequenceMasters', async () => {
    const { data: { sequenceMasters: sequenceMastersRes } } = await api.getSequenceMasters({
      request: {
        fields: ['name'],
        noLimit: true,
      },
    });
    sequenceMasters.value = sequenceMastersRes;
  });
};
loadSequenceMasters();

const sequenceBtnTooltipContent = computed(() => {
  const item = i18n.t('sequence.title');
  return model.value ? i18n.t('general.open.andCheck', { item }) : i18n.t('general.open.cannotAndCheckCauseNotSelected', { item });
});

const sequenceMasterName = (sequenceMasterId: number) => {
  return sequenceMasters.value.find((sequenceMaster) => sequenceMaster.id === sequenceMasterId)?.name;
};

return (_ctx: any,_cache: any) => {
  const _component_BCustomIcon = _resolveComponent("BCustomIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BSelect = _resolveComponent("BSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BTooltip, {
      top: "",
      content: sequenceBtnTooltipContent.value
    }, {
      default: _withCtx(() => [
        _createElementVNode("a", {
          href: sequencePath.value,
          target: "_blank",
          class: "sequence-btn",
          rel: "noopener noreferrer"
        }, [
          _createVNode(_component_BBtn, {
            size: "mini",
            fab: "",
            disabled: !model.value
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BCustomIcon, {
                class: "sequence-icon",
                size: "small",
                "icon-class": "b-sequence"
              })
            ]),
            _: 1
          }, 8, ["disabled"])
        ], 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["content"]),
    (_ctx.isDisplayOnly)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(sequenceMasterName(model.value)), 1))
      : (_openBlock(), _createBlock(_component_BSelect, {
          key: 1,
          modelValue: model.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
          valid: isValid.value,
          "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => ((isValid).value = $event)),
          items: sequenceMasters.value,
          required: _ctx.required,
          "item-text": "name",
          "item-value": "id",
          fit: "",
          filterable: ""
        }, null, 8, ["modelValue", "valid", "items", "required"]))
  ]))
}
}

})
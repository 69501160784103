import { LocaleMessages, VueMessageType, createI18n } from 'vue-i18n'

function loadLocaleMessages() {
  // @ts-ignore
  const locales = require.context('./locales', true, /.json$/i)
  const messages: LocaleMessages<VueMessageType> = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

/**
 * `legacy: false` では、this.$tc, this.$teがなぜか存在しない
 * 使用する際は、これを直接importして `i18n.te` のように使用すること
 * @see https://github.com/smartcamp/bales-crm/pull/3825#issuecomment-1590712680
 */
export const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'ja',
  legacy: false,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
})

export const i18nGlobal = i18n.global

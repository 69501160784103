import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "company-name" }
const _hoisted_3 = { class: "full-name" }
const _hoisted_4 = { class: "error-message" }

import LeadLink from '@/components/organisms/user/general/LeadLink.vue'

type TProps = {
  callTargetId: number;
  companyName: string;
  fullName: string;
  errorMessage: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'FailedCallTargetListItem',
  props: {
    callTargetId: {},
    companyName: {},
    fullName: {},
    errorMessage: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(LeadLink, {
      "in-new-tab": true,
      "call-target-id": _ctx.callTargetId,
      class: "lead-link"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.companyName), 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.fullName), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1)
      ]),
      _: 1
    }, 8, ["call-target-id"])
  ]))
}
}

})
import ApiBase from '@/api/base'

export default {
  getClientSendgridApiKey(args) {
    const { clientId } = args
    return ApiBase.get(`/admin/client_sendgrid_api_keys/${clientId}`)
  },
  createClientSendgridApiKey(args) {
    const { clientId } = args
    return ApiBase.post(`/admin/clients/${clientId}/client_sendgrid_api_keys`, args)
  },
}

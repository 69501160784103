<template>
  <BLayout
    class="page-wrapper"
    align-center
    justify-center
    fill-height
  >
    <BCard>
      <BLayout justify-center>
        <span
          v-if="isWrongUnsubToken"
          class="fs-400"
        >{{ $t('unsubscribe.wrongUrl') }}</span><span
          v-else
          class="fs-400"
        >{{ $t('unsubscribe.title') }}</span>
      </BLayout>
      <VWait for="checkUnsubscribeWait">
        <template #waiting>
          <BPlaceholder
            v-loading="true"
            height="20vh"
            element-loading-background="transparent"
          />
        </template>
        <BLayout
          v-if="isWrongUnsubToken"
          class="my-600"
          align-center
          justify-center
          column
        >
          <BIcon
            size="super-extra-large"
            type="error"
          >
            clear
          </BIcon>
          <BListItem class="message mb-600">
            <span>{{ $t('unsubscribe.wrongUrlMessage') }}</span>
          </BListItem>
          <BLayout
            align-center
            justify-center
          >
            <BBtn
              class="footer-btn"
              type="default"
              :loading="$wait.is('unsubscribeWait')"
              @click.prevent="closeWindow"
            >
              <span>{{ $t('general.close.text') }}</span>
            </BBtn>
          </BLayout>
        </BLayout>
        <BLayout
          v-else-if="isUnsubscribed"
          class="my-600"
          align-center
          justify-center
          column
        >
          <BIcon
            size="super-extra-large"
            type="success"
          >
            check
          </BIcon>
          <BListItem class="message mb-600">
            <span>{{ $t('unsubscribe.unsubscribed') }}</span>
          </BListItem>
          <BLayout
            align-center
            justify-center
          >
            <BBtn
              class="footer-btn"
              type="default"
              :loading="$wait.is('unsubscribeWait')"
              @click.prevent="closeWindow"
            >
              <span>{{ $t('general.close.text') }}</span>
            </BBtn>
          </BLayout>
        </BLayout>
        <BLayout
          v-else
          class="my-600"
          align-center
          justify-center
          column
        >
          <BCustomIcon
            icon-class="b-mail-block"
            size="super-extra-large"
          />
          <BListItem class="message mb-600">
            <span>{{ $t('unsubscribe.unsubscribeMessage') }}</span>
          </BListItem>
          <BLayout
            align-center
            justify-center
            wrap
          >
            <BBtn
              class="footer-btn"
              type="default"
              :loading="$wait.is('unsubscribeWait')"
              @click.prevent="closeWindow"
            >
              <span>{{ $t('general.cancel.text') }}</span>
            </BBtn>
            <BBtn
              class="footer-btn"
              type="primary"
              :loading="$wait.is('unsubscribeWait')"
              @click.prevent="unsubscribe"
            >
              <span>{{ $t('general.submit') }}</span>
            </BBtn>
          </BLayout>
        </BLayout>
      </VWait>
    </BCard>
  </BLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapWaitingActions } from 'vue-wait'
import queryParameter from '@/mixins/query_parameter'

export default defineComponent({
  mixins: [queryParameter],
  data() {
    return {
      unsubscribeParams: null,
      isUnsubscribed: false,
      isWrongUnsubToken: false,
    }
  },
  created() {
    this.unsubscribeParams = this.getUnsubscribeQueryParameter()
    this.checkUnsubscribeAction({
      params: {
        unsubToken: this.unsubscribeParams.unsubToken,
      },
      errorHandlers: {
        400: () => {
          this.isWrongUnsubToken = true
        },
        404: () => {
          this.isUnsubscribed = true
        },
      },
    }).then((res) => {
      if (res.data.unsubscribe.createdAt != null) this.isUnsubscribed = true
    })
  },
  methods: {
    ...mapWaitingActions('unsubscribe', {
      unsubscribeAction: 'unsubscribeWait',
      checkUnsubscribeAction: 'checkUnsubscribeWait',
    }),
    async unsubscribe() {
      const check = await this.$bitterAlert.show({
        text: this.$t('unsubscribe.confirm'),
        closeOnClickOutside: false,
      })
      if (!check) {
        return
      }
      let alertText = this.$t('general.success.to', { action: this.$t('unsubscribe.title') })
      try {
        await this.unsubscribeAction({
          body: {
            unsubToken: this.unsubscribeParams.unsubToken,
          },
        })
        this.isUnsubscribed = true
      } catch (e) {
        alertText = this.$t('general.fail.to', { action: this.$t('unsubscribe.title') })
      } finally {
        await this.$bitterAlert.show({
          title: this.$t('unsubscribe.title'),
          text: alertText,
          closeOnClickOutside: true,
          buttonsCancel: false,
        })
      }
    },
    closeWindow() {
      window.open('about:blank', '_self').close()
    },
  },
})
</script>

<style lang="scss" scoped>
body {
  background-color: $concrete-light;
}

.page-wrapper {
  position: absolute;
  width: 100%;
}

.message {
  text-align: center;
  white-space: pre-line;
}

.footer-btn {
  margin: 10px;
  width: 140px;
}
</style>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "biscuet-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UpdateNotification = _resolveComponent("UpdateNotification")!
  const _component_ClientExpiredNotification = _resolveComponent("ClientExpiredNotification")!
  const _component_Header = _resolveComponent("Header")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_FilterControlPanel = _resolveComponent("FilterControlPanel")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "inspire",
      style: _normalizeStyle(_ctx.bAppStyle)
    }, [
      _createVNode(_component_UpdateNotification),
      _createVNode(_component_ClientExpiredNotification),
      _createVNode(_component_BLayout, null, {
        default: _withCtx(() => [
          _createVNode(_component_Header),
          _createVNode(_component_Sidebar),
          (_ctx.$route.name === 'LeadList')
            ? (_openBlock(), _createBlock(_component_FilterControlPanel, { key: 0 }))
            : _createCommentVNode("", true),
          (_openBlock(), _createBlock(_component_RouterView, {
            key: _ctx.$route.fullPath
          }))
        ]),
        _: 1
      })
    ], 4)
  ]))
}
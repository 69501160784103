<template>
  <BContentBox>
    <template #content>
      <SelectObject
        v-if="selectedObject.length <= 0"
        @select-object="selectObject"
      />
      <MappingColumn
        v-else
        :selected-object="selectedObject"
        @back="backAction"
      />
    </template>
  </BContentBox>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { SfObjectMappingService } from '@/api/user/resources/salesforce/sf_object_mapping';
import MappingColumn from '@/components/organisms/user/setting/api/SalesforceApiMap/MappingColumn.vue';
import SelectObject from '@/components/organisms/user/setting/api/SalesforceApiMap/SelectObject.vue';

const apiService = new SfObjectMappingService();
const salesforceSettingLoading = ref(true);
const sfObjectMappingsKeys = ref([]);
const selectedObject = ref('');

const selectObject = (object: string) => {
  selectedObject.value = object;
}

const backAction = () => {
  selectedObject.value = '';
}

onMounted(async () => {
  const result = await apiService.getSfObjectMappingsKeys();
  sfObjectMappingsKeys.value = result.data.sfObjectMappingsKeys;
  salesforceSettingLoading.value = false;
})
</script>

<style lang="scss" scoped>
.content {
  position: absolute;
  top: auto;
  left: 0;
  width: 100%;
}

.header-text {
  color: $textcolor-light;
}

.mapping-key-text {
  font-weight: bold;
}

.select-colum {
  > .el-select {
    width: 100%;
  }
}

.hr {
  border-bottom: 1px solid $bdcolor-base;
  margin-bottom: 8px;
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mr-400" }
const _hoisted_2 = { class: "direct-mail-title" }
const _hoisted_3 = { class: "direct-mail-title-title" }
const _hoisted_4 = { class: "direct-mail-content" }
const _hoisted_5 = { class: "direct-mail-support" }
const _hoisted_6 = { class: "direct-mail-reserved-at" }

import { computed } from 'vue';
import { useStore } from 'vuex'
import { DirectMail, ReserveDirectMail } from '@/api/openapi';
import { useCheckRelationToSequence } from '@/composable/user/directMail/direct-mail';
import { i18nGlobal as i18n } from '@/i18n';
import { bitterAlert } from '@/plugins/BBitterAlert'
import { key } from '@/store/index'
import { altText } from '@/utils/alt';
import { formatShorterDateTime } from '@/utils/date-time';

type TProps = {
  directMail: DirectMail | ReserveDirectMail;
  displayDatetimeColumn?: 'updatedAt' | 'reservedAt';
};


export default /*@__PURE__*/_defineComponent({
  __name: 'DirectMail',
  props: {
    directMail: {},
    displayDatetimeColumn: { default: 'updatedAt' }
  },
  emits: ['click:confirm', 'afterDelete'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit

const { isSequenceDirectMail } = useCheckRelationToSequence(() => props.directMail);

const store = useStore(key);

const displayDatetime = computed(() => altText(formatShorterDateTime(props.directMail[props.displayDatetimeColumn])));
const handleClickConfirm = () => emit('click:confirm');
const handleDelete = async () => {
  if (!props.directMail.id) { return }
  const check = await bitterAlert.show({
    title: i18n.t('sfTaskParams.directMail.subject'),
    text: i18n.t('general.confirm.of', { action: i18n.t('general.delete.text') }),
  })
  if (!check) { return }
  try {
    await store.dispatch('user/deleteDirectMailAction', {
      request: { directMailId: props.directMail.id },
    });
  } finally {
    emit('afterDelete')
  }
};

return (_ctx: any,_cache: any) => {
  const _component_BUserTooltip = _resolveComponent("BUserTooltip")!
  const _component_BCustomIcon = _resolveComponent("BCustomIcon")!
  const _component_BStatus = _resolveComponent("BStatus")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BMoreMenu = _resolveComponent("BMoreMenu")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createBlock(_component_BLayout, {
    "align-center": "",
    class: "direct-mail"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BUserTooltip, {
          "image-url": _ctx.directMail.owner.avatarUrl,
          name: _ctx.directMail.owner.name,
          "user-id": _ctx.directMail.owner.id,
          size: 40
        }, null, 8, ["image-url", "name", "user-id"])
      ]),
      (_unref(isSequenceDirectMail))
        ? (_openBlock(), _createBlock(_component_BCustomIcon, {
            key: 0,
            "icon-class": "b-sequence",
            class: "mr-400",
            size: "medium"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('sendMailDirect.title')), 1),
        _createVNode(_component_BStatus, {
          class: "mt-50",
          status: _ctx.directMail.status,
          "status-name": _ctx.$t(`mail.status.${_ctx.directMail.status}`)
        }, null, 8, ["status", "status-name"])
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('mail.titlePlaceholder')) + "：" + _toDisplayString(_ctx.directMail.subject), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(displayDatetime.value), 1),
        _createElementVNode("div", {
          class: "direct-mail-action-area",
          onClick: _withModifiers(handleClickConfirm, ["stop"])
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.confirm.do')), 1),
          _createVNode(_component_BIcon, { size: "small" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" chevron_right ")
            ])),
            _: 1
          })
        ])
      ]),
      (!_unref(isSequenceDirectMail))
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "px-100",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }, [
            _createVNode(_component_BMoreMenu, { attach: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_BBtn, {
                  flat: "",
                  fit: "",
                  size: "small",
                  onClick: handleDelete
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t(`general.delete.do`)), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "confirm-drawer" }

import { computed } from 'vue';
import { DirectMail } from '@/api/openapi/models/direct-mail';
import MailDrawerTemplate from '@/components/organisms/user/mail/common/drawer/MailDrawerTemplate.vue';
import { formatShorterDateTime } from '@/utils/date-time';
import { SenderData } from '../types';
import ConfirmDrawerBodyCommon from './ConfirmDrawerBodyCommon.vue';

type TProps = {
  directMail: DirectMail;
  isAvailableSalesforceApiFeature: boolean;
  isLoading: boolean;
  senderData: SenderData;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfirmDrawer',
  props: {
    directMail: {},
    isAvailableSalesforceApiFeature: { type: Boolean },
    isLoading: { type: Boolean },
    senderData: {}
  },
  emits: [
  'edit', 'reserve', 'send', 'close', 'open', 'change-sf-owner-id',
],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const isSendNow = computed(() => props.directMail.reservedAt === null);
const enableSendOrReserve = computed(() =>
  props.directMail.sendToAddress !== ''
  && props.directMail.status === 'draft',
);

const displayReservedAt = computed(() => props.directMail.reservedAt ? formatShorterDateTime(props.directMail.reservedAt) : '');
const modalName = `SalesforceMailSend${props.directMail.id}`

const clickSendButton = () => {
  emit('send');
}

const clickReserveButton = () => {
  emit('reserve');
}

const handleBackToEdit = () => emit('edit');
const handleClose = () => emit('close');

return (_ctx: any,_cache: any) => {
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? _withDirectives((_openBlock(), _createBlock(_component_BPlaceholder, {
          key: 0,
          height: "50vh",
          "element-loading-background": "transparent"
        }, null, 512)), [
          [_directive_loading, true]
        ])
      : (_openBlock(), _createBlock(MailDrawerTemplate, {
          key: 1,
          title: _ctx.$t('mail.confirmContent'),
          width: 600
        }, {
          drawerHeaderAction: _withCtx(() => [
            (isSendNow.value)
              ? (_openBlock(), _createBlock(_component_BBtn, {
                  key: 0,
                  size: "small",
                  type: "primary",
                  outline: "",
                  loading: _ctx.$wait.is('sendDirectWait'),
                  disabled: !enableSendOrReserve.value,
                  onClick: clickSendButton
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('mail.send')), 1),
                    _createVNode(_component_BIcon, null, {
                      default: _withCtx(() => _cache[0] || (_cache[0] = [
                        _createTextVNode("keyboard_arrow_right")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["loading", "disabled"]))
              : (_openBlock(), _createBlock(_component_BBtn, {
                  key: 1,
                  size: "small",
                  type: "primary",
                  outline: "",
                  disabled: !enableSendOrReserve.value,
                  onClick: clickReserveButton
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('mail.reserve')), 1),
                    _createVNode(_component_BIcon, null, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode("keyboard_arrow_right")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["disabled"]))
          ]),
          drawerBody: _withCtx(() => [
            _createVNode(ConfirmDrawerBodyCommon, {
              "direct-mail": _ctx.directMail,
              "is-available-salesforce-api-feature": _ctx.isAvailableSalesforceApiFeature,
              "sender-data": _ctx.senderData
            }, {
              mailTo: _withCtx(() => [
                _createVNode(_component_BListItem, { class: "list-item mb-600" }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('directMail.sendTo')), 1)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(`${_ctx.directMail.sendToName} <${_ctx.directMail.sendToAddress}>`), 1)
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_BListItem, { class: "list-item mb-600" }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('mail.sendAt')), 1)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(displayReservedAt.value), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["direct-mail", "is-available-salesforce-api-feature", "sender-data"])
          ]),
          drawerFooter: _withCtx(() => [
            _createVNode(_component_BLayout, { "justify-center": "" }, {
              default: _withCtx(() => [
                _createVNode(_component_BBtn, {
                  class: "mr-100",
                  onClick: handleBackToEdit
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BIcon, null, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("keyboard_arrow_left")
                      ])),
                      _: 1
                    }),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('mail.backToEdit')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_BBtn, {
                  class: "mr-100",
                  flat: "",
                  onClick: handleClose
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('general.close.text')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["title"]))
  ]))
}
}

})
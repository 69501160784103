import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "mt-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BAlert = _resolveComponent("BAlert")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BFlex = _resolveComponent("BFlex")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_BFlex, { class: "oauth-callback" }, {
    default: _withCtx(() => [
      (!_ctx.settingLoading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.success)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_BAlert, {
                    text: _ctx.$t('oauthCallback.linkComplete', {service_name: _ctx.serviceName}),
                    class: "success",
                    type: "success",
                    "show-icon": ""
                  }, null, 8, ["text"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_BAlert, {
                    text: _ctx.$t('oauthCallback.linkFailure', {service_name: _ctx.serviceName}),
                    class: "alert",
                    type: "error",
                    "show-icon": ""
                  }, null, 8, ["text"])
                ])),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_BBtn, {
                disabled: _ctx.settingLoading,
                loading: _ctx.settingLoading,
                onClick: _ctx.toHome
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('oauthCallback.toTopPage')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "loading", "onClick"])
            ])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  })), [
    [_directive_loading, _ctx.settingLoading]
  ])
}
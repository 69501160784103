<template>
  <BCard>
    <BListItem class="mb-400">
      <template #header>
        <span>{{ $t('sendgridSubUserManagement.select') }}</span>
      </template>
      <BSelect
        v-model="inputClientId"
        :items="items"
        :loading="loading"
        filterable
        required
        :teleported="false"
      />
    </BListItem>
    <BListItem class="mb-400">
      <template #header>
        <span>{{ $t('sendgridSubUserManagement.selectDomain') }}</span>
      </template>
      <BSelect
        v-model="selectedSendgridDomainId"
        :items="domainForSelectItems"
        :loading="loading"
        filterable
        required
        :teleported="false"
      />
    </BListItem>
    <BLayout
      align-center
      justify-center
    >
      <BBtn
        class="mr-400"
        text
        @click="cancel"
      >
        {{ $t(`general.close.text`) }}
      </BBtn>
      <BBtn
        class="ml-400"
        type="primary"
        :loading="loading"
        :disabled="!isValid"
        @click="save"
      >
        {{ $t('sendgridSubUserManagement.exec') }}
      </BBtn>
    </BLayout>
  </BCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { useSendgridDomains, DEFAULT_SENDGRID_DOMAIN_ID } from '@/composable/admin/sendgrid_domains';
import inputValidation from '@/mixins/input_validation'

export default defineComponent({
  name: 'SendgridSubUserInviteForm',
  mixins: [inputValidation],
  props: {
    loading: Boolean,
  },
  emits: [
    'save',
    'cancel',
  ],
  setup() {
    const { fetchSendgridDomains, domainForSelectItems } = useSendgridDomains();
    return {
      fetchSendgridDomains,
      domainForSelectItems,
    };
  },
  data () {
    return {
      inputClientId: null,
      selectedSendgridDomainId: DEFAULT_SENDGRID_DOMAIN_ID,
    }
  },
  computed: {
    ...mapGetters('admin', ['clients', 'sendgridSubUsers']),
    usedClientIds () {
      let arr = []
      this.sendgridSubUsers.forEach((user) => {
        arr = [...arr, ...user.clientIds]
      })
      return arr
    },
    items () {
      return this.clients.filter((client) => {
        return !this.usedClientIds.includes(client.id)
      }).map((client) => {
        return {
          value: client.id,
          text: client.name,
        }
      })
    },
    isValid() {
      return this.inputClientId != null && this.selectedSendgridDomainId != null
    },
  },
  async beforeMount() {
    await this.fetchSendgridDomains();
  },
  methods: {
    save () {
      this.$emit('save', { clientId: this.inputClientId, sendgridDomainId: this.selectedSendgridDomainId })
    },
    cancel () {
      this.$emit('cancel')
    },
    reset () {
      this.inputClientId = null
      this.selectedSendgridDomainId = DEFAULT_SENDGRID_DOMAIN_ID
    },
  },
})
</script>

<template>
  <div
    class="status-block"
    @click.stop="openSfModal"
  >
    <BCustomIcon
      :icon-class="iconClass"
      :type="type"
    />
  </div>
</template>

<script>
import SalesforceConst from '@/const/salesforce'

export default {
  props: {
    connectStatus: {
      type: String,
      required: false,
      default: 'no_related',
    },
  },
  emits: [
    'open-sf-modal',
  ],
  computed: {
    iconClass() {
      if (this.connectStatus == SalesforceConst.Synced || this.connectStatus == SalesforceConst.Syncing) {
        return 'b-sf-success'
      } else if (this.connectStatus == SalesforceConst.SyncedFailed) {
        return 'b-sf-failure'
      } else if (this.connectStatus == SalesforceConst.UnSynced) {
        return 'b-sf-pending'
      }

      return ''
    },
    type() {
      if (this.connectStatus == SalesforceConst.Synced) {
        return 'success'
      } else if (this.connectStatus == SalesforceConst.SyncedFailed) {
        return 'error'
      } else if (this.connectStatus == SalesforceConst.Syncing) {
        return 'warning'
      }
      return ''
    },
  },
  methods: {
    openSfModal() {
      if (['synced', 'sync_failed'].includes(this.connectStatus)) {
        this.$emit('open-sf-modal')
      }
      return
    },
  },
}
</script>

<style lang="scss" scoped>
  .status-block {
    line-height: 0;
  }
</style>

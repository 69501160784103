import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import {
  PlaceholderApi,
  GetPlaceholders200Response,
  PlaceholderApiGetPlaceholdersRequest,
} from '@/api/openapi';

export class PlaceholderApiService extends ApiService {
  api = new PlaceholderApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true)
  }

  async getPlaceholders(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<PlaceholderApiGetPlaceholdersRequest>,
  ) {
    return this.checkResponse<GetPlaceholders200Response>(
      this.api.getPlaceholders(request, options),
      errorHandlers,
    );
  }
}

<template>
  <div class="b-switch-btn">
    <BLayout wrap>
      <BFlex :span="12">
        <div
          class="b-switch-btn-item connected"
          :class="{ 'selected': isSelected(connected) }"
          @click="handleStatus(connected)"
        >
          {{ connected.text }}
        </div>
      </BFlex>
      <BFlex :span="12">
        <div
          class="b-switch-btn-item not-connect"
          :class="{ 'selected': isSelected(notConnect) }"
          @click="handleStatus(notConnect)"
        >
          {{ notConnect.text }}
        </div>
      </BFlex>
    </BLayout>
  </div>
</template>

<script>
export default {
  props: {
    disabledValue: {
      type: Array,
      default () {
        return []
      },
    },
    modelValue: [String, Boolean],
  },
  emits: [
    'update:modelValue',
  ],
  data () {
    return {
      connected: { text: this.$t('callResult.connected.enum.true'), value: true },
      notConnect: { text: this.$t('callResult.connected.enum.false'), value: false },
    }
  },
  computed: {
    internalValue: {
      get () {
        return this.modelValue
      },
      set (newVal) {
        if (this.modelValue !== newVal) this.$emit('update:modelValue', newVal)
      },
    },
  },
  methods: {
    isSelected (item) {
      return this.internalValue === item.value
    },
    handleStatus (item) {
      this.internalValue = item.value
    },
  },
}
</script>

<style lang="scss" scoped>
  .b-switch-btn-item {
    width: 100%;
    text-align: center;
    border: 1px solid $bdcolor-base;
    color: $textcolor-base;
    padding: $basespace-100;
    cursor: pointer;
    transition: all 200ms $standard-easing;

    &.connected {
      background-color: rgba($basecolor-hot, .1);
      color: $basecolor-hot;
      border-color: rgba($basecolor-hot, .1);
      border-radius: 8px 0 0 8px;

      &.selected {
        background-color: $basecolor-hot;
        color: $textcolor-white;
        border-color: $basecolor-hot;
      }
    }

    &.not-connect {
      background-color: rgba($basecolor-cold, .1);
      color: $basecolor-cold;
      border-color: rgba($basecolor-cold, .1);
      border-radius: 0 8px 8px 0;

      &.selected {
        background-color: $basecolor-cold;
        color: $textcolor-white;
        border: 1px solid $basecolor-cold;
      }
    }
  }
</style>

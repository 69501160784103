import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import ApiBase from '@/api/base'
import {
  CreateOutgoingMailAttachmentAccessUrl200ResponseAllOf,
  FileStrageAccess,
  FileStrageAccessApi,
  FileStrageAccessApiCreateMailAttachmentPresignedUrlRequest,
  FileStrageAccessApiCreateOutgoingMailAttachmentAccessUrlRequest,
  FileStrageAccessApiCreateZoomPhoneRecordingPresignedUrlRequest,
} from '@/api/openapi';

export class FileStorageAccessApiService extends ApiService {
  api = new FileStrageAccessApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true)
  }

  async createZoomPhoneRecordingPresignedUrl({
    request,
    options,
    errorHandlers,
  }: ApiArguments<FileStrageAccessApiCreateZoomPhoneRecordingPresignedUrlRequest>): Promise<AxiosResponse<FileStrageAccess>> {
    return this.checkResponse<FileStrageAccess>(
      this.api.createZoomPhoneRecordingPresignedUrl(request, options),
      errorHandlers,
    );
  }

  async createMailAttachmentPresignedUrl({
    request,
    options,
    errorHandlers,
  }: ApiArguments<FileStrageAccessApiCreateMailAttachmentPresignedUrlRequest>): Promise<AxiosResponse<FileStrageAccess>> {
    return this.checkResponse<FileStrageAccess>(
      this.api.createMailAttachmentPresignedUrl(request, options),
      errorHandlers,
    );
  }

  async createOutgoingMailAttachmentPresignedUrl({
    request,
    options,
    errorHandlers,
  }: ApiArguments<FileStrageAccessApiCreateOutgoingMailAttachmentAccessUrlRequest>): Promise<AxiosResponse<CreateOutgoingMailAttachmentAccessUrl200ResponseAllOf>> {
    return this.checkResponse<CreateOutgoingMailAttachmentAccessUrl200ResponseAllOf>(
      this.api.createOutgoingMailAttachmentAccessUrl(request, options),
      errorHandlers,
    );
  }
}

export default {
  createImportFileStorageAccessUrl(args) {
    return ApiBase.post(`/user/file_storage_access/create_import_access_url`, args)
  },
  createUpdateFileStorageAccessUrl(args) {
    return ApiBase.post(`/user/file_storage_access/create_update_access_url`, args)
  },
  createExportFileStorageAccessUrl(args) {
    return ApiBase.post(`/user/file_storage_access/create_export_access_url`, args)
  },
  createMailImageFileStorageAccessUrl(args) {
    return ApiBase.post(`/user/file_storage_access/create_mail_image_access_url`, args)
  },
}

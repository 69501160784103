import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "developer-feature" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BDivider = _resolveComponent("BDivider")!
  const _component_BSelect = _resolveComponent("BSelect")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_MailContentEditor = _resolveComponent("MailContentEditor")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BPopover = _resolveComponent("BPopover")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BListItem, { class: "mb-300" }, {
      header: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.apiKey')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_BInput, {
          modelValue: _ctx.apiKey,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.apiKey) = $event)),
          disabled: ""
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_BListItem, { class: "mb-300" }, {
      header: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('sendgridSetting.domain')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_BInput, {
          modelValue: _ctx.domain,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.domain) = $event)),
          disabled: ""
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    (_ctx.isAdminDeveloper)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_BDivider, { class: "my-200" }),
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('sendgridSetting.developerFeature')), 1),
          _createVNode(_component_BSelect, {
            modelValue: _ctx.internalSendGridKey.id,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.internalSendGridKey.id) = $event)),
            items: _ctx.sendgridApiKeyItems,
            "item-text": "text",
            "item-value": "value",
            fit: ""
          }, null, 8, ["modelValue", "items"]),
          _createVNode(_component_BBtn, {
            class: "mt-700",
            type: "primary",
            onClick: _ctx.handleDecisive
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('general.decisive')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_BLayout, { "justify-center": "" }, {
            default: _withCtx(() => [
              _createVNode(_component_BPopover, {
                modelValue: _ctx.testPopoverOpen,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.testPopoverOpen) = $event)),
                placement: "right",
                width: "520",
                trigger: "click"
              }, {
                reference: _withCtx(() => [
                  _createVNode(_component_BBtn, {
                    class: "mr-200",
                    type: "primary",
                    outline: "",
                    disabled: !_ctx.saveValid || _ctx.isSaveLoading || _ctx.testPopoverOpen
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('mail.sendTest')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_BListItem, { class: "list-item mt-300" }, {
                      header: _withCtx(() => [
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t('mail.to_address')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_BInput, {
                          modelValue: _ctx.testAddress,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.testAddress) = $event)),
                          valid: _ctx.testAddressValid,
                          "onUpdate:valid": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.testAddressValid) = $event)),
                          placeholder: _ctx.$t('mail.mailAddress'),
                          rules: [_ctx.requiredRule, _ctx.emailRule],
                          "max-length": 255
                        }, null, 8, ["modelValue", "valid", "placeholder", "rules"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_MailContentEditor, {
                      class: "mb-600",
                      subject: _ctx.testSubject,
                      content: _ctx.testContent,
                      "enable-placeholder": false,
                      onEditSubject: _ctx.editSubject,
                      onEditContent: _ctx.editContent
                    }, null, 8, ["subject", "content", "onEditSubject", "onEditContent"]),
                    _createVNode(_component_BLayout, {
                      class: "mt-500",
                      "justify-center": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BBtn, {
                          class: "mr-100",
                          flat: "",
                          size: "small",
                          loading: _ctx.isSendTestLoading,
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.testPopoverOpen = false))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('general.cancel.text')), 1)
                          ]),
                          _: 1
                        }, 8, ["loading"]),
                        _createVNode(_component_BBtn, {
                          type: "primary",
                          size: "small",
                          loading: _ctx.isSendTestLoading,
                          disabled: !_ctx.testAddressValid,
                          onClick: _ctx.handleSendTest
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('mail.send')), 1)
                          ]),
                          _: 1
                        }, 8, ["loading", "disabled", "onClick"])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"]),
              (_ctx.isEdit)
                ? (_openBlock(), _createBlock(_component_BBtn, {
                    key: 0,
                    type: "primary",
                    onClick: _ctx.handleDelete
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.delete.text')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : (_openBlock(), _createBlock(_component_BBtn, {
                    key: 1,
                    class: "mt-200",
                    type: "primary",
                    disabled: !_ctx.saveValid || _ctx.isSendTestLoading,
                    loading: _ctx.isSaveLoading,
                    onClick: _ctx.handleSave
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.save.text')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "loading", "onClick"]))
            ]),
            _: 1
          })
        ], 64))
      : _createCommentVNode("", true)
  ])), [
    [_directive_loading, _ctx.$wait.is(['getClientSendgridAuthWait', 'deleteClientSendgridAuthWait', 'getClientSendgridApiKeyWait'])]
  ])
}
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, ref } from 'vue';

type TProps = {
  status: boolean;
  beforeChange?: Function;
  color?: string;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'BSwitch',
  props: {
    status: { type: Boolean },
    beforeChange: { type: Function, default: async () => {
    return true;
  } },
    color: { default: 'default' }
  },
  setup(__props: any) {

const props = __props;

const loading = ref(false);

const status = computed({
  get() {
    return props.status;
  },
  set(_v) {
    // beforeActionでactionしているので、更新後にやりたいことがあればここに記述
  },
});

const beforeAction = async () => {
  loading.value = true;
  await props.beforeChange();
  loading.value = false;
  return true;
}

const classes = () => {
  return {
    'b-switch-on-default': props.color === 'default',
    'b-switch-on-accent': props.color === 'accent',
  }
}

return (_ctx: any,_cache: any) => {
  const _component_ElSwitch = _resolveComponent("ElSwitch")!

  return (_openBlock(), _createBlock(_component_ElSwitch, {
    modelValue: status.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((status).value = $event)),
    loading: loading.value,
    "before-change": beforeAction,
    class: _normalizeClass(classes())
  }, null, 8, ["modelValue", "loading", "class"]))
}
}

})
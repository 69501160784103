<template>
  <Transition>
    <div
      v-if="isVisible"
      class="mail-form-transition"
    >
      <slot />
    </div>
  </Transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MailFormTransition',
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.mail-form-transition {
  position: absolute;
  z-index: 8;
  top: 0;
  right: 0;
  height: 100%;
}
.v-enter-to {
  transition: transform 300ms $standard-easing;
  transform: translateX(0px);
}
.v-enter-from {
  transform: translateX(100%);
}
.v-leave-to {
  transition: transform 300ms $standard-easing;
  transform: translateX(100%);
}
.v-leave-from {
  transform: translateX(0px);
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, toHandlers as _toHandlers, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sequence-steps-container" }
const _hoisted_2 = { class: "side-menu-container" }
const _hoisted_3 = { class: "side-menu-container" }
const _hoisted_4 = { class: "side-menu-container" }
const _hoisted_5 = { class: "side-menu-container" }

import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { ApiArguments } from '@/api/api_service';
import {
  CallTarget,
  SequenceStepBody,
  SequenceStepInstance,
  SequenceStepInstanceApiGetSequenceStepInstancesRequest,
  SequenceStepInstanceApiPutSequenceStepInstanceRequest,
} from '@/api/openapi';
import { SequenceStepInstanceApiService } from '@/api/user/resources/sequence_step_instance';
import { makeNextActionType } from '@/composable/user/nextActionType/next-action-types';
import { getActionDetail, isDirectMailActionType } from '@/composable/user/sequence/post-put-body';
import { useSequenceStepActions, useStepDetail, useStepEditing, useStepForm } from '@/composable/user/sequence/step-actions';
import { useSequenceStepInstanceActions } from '@/composable/user/sequence/step-menu-actions';
import { useWait } from '@/composable/vue-wait';
import { bitterAlert } from '@/plugins/BBitterAlert';
import { sleep } from '@/utils/sleep';
import EditableSequenceSteps from '../general/sequence/EditableSequenceSteps.vue';
import SequenceDirectMailForm from '../general/sequence/SequenceDirectMailForm.vue';
import SequenceNextActionDetail from '../general/sequence/SequenceNextActionDetail.vue';
import SequenceNextActionForm from '../general/sequence/SequenceNextActionForm.vue';
import { TFormNextActionSequenceStep, TFormSequenceStep, TPartialFormDirectMailSequenceStep, TPartialFormNextActionSequenceStep, TStepHandlers } from '../general/sequence/types';

type TProps = {
  callTarget: CallTarget | null;
  sequenceInstanceId: number;
  ownerId: number | undefined;
  loading: boolean;
};
type TEmit = {
  'afterUpdate': [];
  'beforeSend': [];
  'stopSending': [];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceSteps',
  props: {
    callTarget: {},
    sequenceInstanceId: {},
    ownerId: {},
    loading: { type: Boolean }
  },
  emits: ["afterUpdate", "beforeSend", "stopSending"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const sequenceStepInstances = ref<SequenceStepInstance[]>([]);
const i18n = useI18n();
const api = new SequenceStepInstanceApiService();
const { doActionWithWait } = useWait();

const { getStepsActions } = useSequenceStepInstanceActions();
const stepHandlers: TStepHandlers = {
  'click:detail': (index: number) => handleDetailClick(index),
  'click:edit': (index: number) => handleEditClick(index),
  'click:skip': (index: number) => handleSkipClick(index),
  'click:sendImmediately': (index: number) => handleSendImmediatelyClick(index),
  'click:startImmediately': (index: number) => handleStartImmediatelyClick(index),
};

const sequenceStepData = computed(() =>
  sequenceStepInstances.value.map(({ actionTypeId, defaultActionTypeId, ...data }): TFormSequenceStep => ({
    ...data,
    actionType: makeNextActionType(defaultActionTypeId, actionTypeId),
  })),
);

const {
  internalSequenceSteps,
  isMenuOpens,
  stepsActionsWithDisabled,
  closeMenu,
} = useSequenceStepActions(
  () => sequenceStepData.value,
  getStepsActions,
);
const {
  isNextActionFormOpen,
  isDirectMailFormOpen,
  directMailSubmitType,
  openStepForm,
  closeStepForm,
  saveStep,
  formSelectedSequenceStep,
} = useStepForm();
const {
  openDetail,
  isNextActionDetailOpen,
  isDirectMailDetailOpen,
  selectedNextActionType,
  closeStepDetail,
  detailSelectedSequenceStep,
} = useStepDetail(internalSequenceSteps);
const { openStepFormToEdit } = useStepEditing(internalSequenceSteps, openStepForm);

const fetchSequenceStepInstances = async (sequenceInstanceId: number) => {
  const apiArguments: ApiArguments<SequenceStepInstanceApiGetSequenceStepInstancesRequest> = {
    request: { sequenceInstanceId },
  };
  await doActionWithWait('fetchSequenceStepInstances', async () => {
    const res = await api.getSequenceStepInstances(apiArguments);
    sequenceStepInstances.value = res.data;
  });
};

watch(() => props.sequenceInstanceId, async (sequenceInstanceId) => {
  await fetchSequenceStepInstances(sequenceInstanceId);
}, { immediate: true })

const handleEditClick = (index: number) => {
  closeStepForm();
  closeMenu(index);
  openStepFormToEdit(index);
};
const handleDetailClick = (index: number) => {
  closeMenu(index);
  openDetail(index);
};
const handleSkipClick = async (index: number) => {
  const ok = await bitterAlert.show({
    text: i18n.t('sequence.message.confirmStepToSkip', { number: index + 1 }),
  });
  closeMenu(index);
  if (!ok) {
    return;
  }
  skipStep(internalSequenceSteps.value[index]);
};
const handleSendImmediatelyClick = async (index: number) => {
  const ok = await bitterAlert.show({
    text: i18n.t('sequence.message.confirmSendImmediately', { number: index + 1 }),
  });
  closeMenu(index);
  if (!ok) {
    return;
  }
  executeImmediately(internalSequenceSteps.value[index]);
};

const handleStartImmediatelyClick = async (index: number) => {
  const ok = await bitterAlert.show({
    text: i18n.t('sequence.message.confirmStartImmediately', { number: index + 1 }),
  });
  closeMenu(index);
  if (!ok) {
    return;
  }
  executeImmediately(internalSequenceSteps.value[index]);
};

const handleCloseStepDetail = () => {
  closeStepDetail();
};

const handleFormCancel = () => {
  closeStepForm();
};
const afterUpdateByForm = (item: TFormSequenceStep) => {
  fetchSequenceStepInstances(props.sequenceInstanceId);
  emit('afterUpdate');
  saveStep(item);
};
const handleFormSave = async (sequenceStep: TFormSequenceStep) => {
  await doActionWithWait('putSequenceStepInstance', async () => {
    await api.putSequenceStepInstance({
      request: makeUpdateRequest(sequenceStep),
    });
  });
  afterUpdateByForm(sequenceStep);
  closeStepForm();
};
const canConfirmDirectMail = async (sequenceStep: TFormSequenceStep) => {
  try {
    // NOTE: 下書き保存成功したら遷移OK
    await draftDirectMailStep(sequenceStep);
    afterUpdateByForm(sequenceStep);
    // NOTE: steps再読み込みに伴ってconfirm画面がちらつくので、confirmへの遷移を一旦待つ
    await sleep(500);
    return true;
  } catch (_e) {
    return false;
  }
};
const handleDirectMailFormSaveAsDraft = async (sequenceStep: TFormSequenceStep) => {
  // NOTE: 「下書き保存」と「下書きとして設定」は同じエンドポイント
  await draftDirectMailStep(sequenceStep);
  afterUpdateByForm(sequenceStep);
  closeStepForm();
};
const handleDirectMailFormDraft = async (sequenceStep: TFormSequenceStep) => {
  // NOTE: 「下書き保存」と「下書きとして設定」は同じエンドポイント
  await draftDirectMailStep(sequenceStep);
  afterUpdateByForm(sequenceStep);
};
const handleDirectMailFormReserve = async (sequenceStep: TFormSequenceStep) => {
  console.log('before:reserveDirectMailSequenceStepInstance')
  // NOTE: 「配信予約」と「配信予約として設定」は同じエンドポイント
  await doActionWithWait('reserveDirectMailSequenceStepInstance', async () => {
    await api.reserveDirectMailSequenceStepInstance({
      request: makeUpdateRequest(sequenceStep),
    });
  });
  afterUpdateByForm(sequenceStep);
  closeStepForm();
};
const handleDirectMailFormSendImmediately = async (sequenceStep: TFormSequenceStep) => {
  await executeImmediately(sequenceStep);
};

const skipStep = async (sequenceStep: TFormSequenceStep) => {
  const apiArguments: ApiArguments<SequenceStepInstanceApiPutSequenceStepInstanceRequest> = {
    request: {
      sequenceInstanceId: props.sequenceInstanceId,
      sequenceStepInstanceId: sequenceStep.id,
    },
  };
  await doActionWithWait('skipSequenceStepInstance', async () => {
    await api.skipSequenceStepInstance(apiArguments);
  });
  fetchSequenceStepInstances(props.sequenceInstanceId);
  emit('afterUpdate');
};
const makeUpdateRequest = (sequenceStep: TFormSequenceStep): SequenceStepInstanceApiPutSequenceStepInstanceRequest => {
  // NOTE: TFormSequenceStepからpost用の型に詰め替える
  const sequenceStepInstanceBody = {
    id: sequenceStep.id,
    actionType: sequenceStep.actionType,
    dateInterval: sequenceStep.dateInterval,
    reservedTime: sequenceStep.reservedTime,
    actionDetail: getActionDetail(sequenceStep),
    priority: sequenceStep.priority,
  } as SequenceStepBody;

  return {
    sequenceInstanceId: props.sequenceInstanceId,
    sequenceStepInstanceId: sequenceStep.id,
    postSequenceStepInstanceBody: {
      sequenceStep: sequenceStepInstanceBody,
    },
  };
}
const draftDirectMailStep = async (sequenceStep: TFormSequenceStep) => {
  await doActionWithWait('draftDirectMailSequenceStepInstance', async () => {
    await api.draftDirectMailSequenceStepInstance({
      request: makeUpdateRequest(sequenceStep),
    });
  });
};
const executeImmediately = async (sequenceStep: TFormSequenceStep) => {
  if (isDirectMailActionType(sequenceStep.actionType)) {
    emit('beforeSend');
  }
  try {
    await doActionWithWait('executeSequenceStepInstance', async () => {
      await api.executeSequenceStepInstance({
        request: {
          sequenceInstanceId: props.sequenceInstanceId,
          sequenceStepInstanceId: sequenceStep.id,
        },
      });
    });
    fetchSequenceStepInstances(props.sequenceInstanceId);
    emit('afterUpdate');
  } catch (_e) {
    emit('stopSending');
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(EditableSequenceSteps, _mergeProps({
        "is-menu-opens": _unref(isMenuOpens),
        "onUpdate:isMenuOpens": _cache[0] || (_cache[0] = ($event: any) => (_isRef(isMenuOpens) ? (isMenuOpens).value = $event : null)),
        "sequence-steps": _unref(internalSequenceSteps),
        "enabled-to-add-steps": false,
        "steps-actions-with-disabled": _unref(stepsActionsWithDisabled)
      }, _toHandlers(stepHandlers)), null, 16, ["is-menu-opens", "sequence-steps", "steps-actions-with-disabled"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(SequenceNextActionDetail, {
        "sequence-step": (_unref(detailSelectedSequenceStep) as TFormNextActionSequenceStep),
        "is-open": _unref(isNextActionDetailOpen),
        "next-action-type": _unref(selectedNextActionType),
        "onClick:cancel": handleCloseStepDetail
      }, null, 8, ["sequence-step", "is-open", "next-action-type"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(SequenceNextActionForm, {
        "sequence-step": (_unref(formSelectedSequenceStep) as TPartialFormNextActionSequenceStep),
        "is-open": _unref(isNextActionFormOpen),
        loading: _ctx.loading,
        "onClick:cancel": handleFormCancel,
        "onClick:save": handleFormSave
      }, null, 8, ["sequence-step", "is-open", "loading"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(SequenceDirectMailForm, {
        mode: "confirm",
        "sequence-step": (_unref(detailSelectedSequenceStep) as TPartialFormDirectMailSequenceStep),
        "sender-id": _ctx.ownerId,
        "is-open": _unref(isDirectMailDetailOpen),
        loading: _ctx.loading,
        "onClick:cancel": handleCloseStepDetail
      }, null, 8, ["sequence-step", "sender-id", "is-open", "loading"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(SequenceDirectMailForm, {
        mode: "form",
        "sequence-step": (_unref(formSelectedSequenceStep) as TPartialFormDirectMailSequenceStep),
        "sender-id": _ctx.ownerId,
        "is-open": _unref(isDirectMailFormOpen),
        loading: _ctx.loading,
        "save-button-text": _ctx.$t('mail.reserve'),
        "can-confirm": _unref(directMailSubmitType) === 'send' ? canConfirmDirectMail : undefined,
        "submit-type": _unref(directMailSubmitType),
        "onClick:cancel": handleFormCancel,
        "onClick:saveAsDraft": handleDirectMailFormSaveAsDraft,
        "onClick:saveAsReserve": handleDirectMailFormReserve,
        "onClick:draft": handleDirectMailFormDraft,
        "onClick:reserve": handleDirectMailFormReserve,
        "onClick:sendImmediately": handleDirectMailFormSendImmediately
      }, null, 8, ["sequence-step", "sender-id", "is-open", "loading", "save-button-text", "can-confirm", "submit-type"])
    ])
  ], 64))
}
}

})
<template>
  <div class="custom-action-options-container">
    <div
      v-for="option in customActionOptions"
      :key="option.id"
    >
      <div class="option-container">
        <BEditableContent
          :value="option.name"
          :rules="[requiredRule]"
          :max-length="175"
          :loading="wait.is(`updateCustomActionOptionWait-${option.id}`) || loading"
          @check="(inputValue) => handleUpdateNameClick(inputValue, option.id)"
        />
        <BBtn text>
          <BPopover
            v-if="!option.isDeletable"
            placement="top"
            width="240"
            trigger="hover"
          >
            <template #reference>
              <div>
                <BIcon
                  class="px-200"
                  size="small"
                  type="gray"
                >
                  close
                </BIcon>
              </div>
            </template>
            <div>{{ $t('general.alreadyUsed') }}</div>
          </BPopover>
          <BIcon
            v-else
            class="px-200"
            size="small"
            type="gray"
            @click="() => handleDeleteClick(option.id)"
          >
            close
          </BIcon>
        </BBtn>
      </div>
    </div>
    <BInputBox
      v-if="isCreatingInputVisible"
      v-model="creatingInputValue"
      class="creating-input"
      horiz
      required
      :loading="wait.is(['createCustomActionOptionWait'])"
      @check="handleCreateingInputCheckClick"
      @close="handleCreateingInputCloseClick"
    />
    <BBtn
      type="primary"
      text
      size="small"
      @click="handleAppendClick"
    >
      <span class="append-option">{{ $t('general.append.of', {item: $t('general.option')}) }}</span>
    </BBtn>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { CustomActionOption } from '@/api/openapi';
import { CustomActionOptionApiService } from '@/api/user/resources/custom_action_option';
import { useInputValidations } from '@/composable/input-validations';
import { useWait } from '@/composable/vue-wait';
import { bitterAlert } from '@/plugins/BBitterAlert';

type TEmit = {
  (event: 'afterSave'): void;
};
type TProps = {
  customActionId: number,
  customActionOptions: CustomActionOption[],
  loading?: boolean, // 名前の更新時、前の値がチラつくのを防止するために使っている。
};

const props = withDefaults(defineProps<TProps>(), {
  loading: false,
});
const emit = defineEmits<TEmit>();

const { wait, doActionWithWait } = useWait();
const { requiredRule } = useInputValidations();
const i18n = useI18n();

const isCreatingInputVisible = ref(false);
const creatingInputValue = ref('');

const handleUpdateNameClick = async (name: string, customActionOptionId: number) => {
  await doActionWithWait(`updateCustomActionOptionWait-${customActionOptionId}`, async () => {
    const api = new CustomActionOptionApiService();
    await api.updateCustomActionOption(
      {
        request: {
          customActionOptionId,
          putCustomActionOptionBody: {
            name,
          },
        },
      },
    );
    emit('afterSave');
  });
};

const handleDeleteClick = async (customActionOptionId: number) => {
  const customAction = props.customActionOptions.find((option) => option.id === customActionOptionId);
  const isAccepted = await bitterAlert.show({
    text: i18n.t('general.confirm.actionWithTarget', { target: customAction.name, action: i18n.t('general.delete.text') }),
  });
  if (!isAccepted) return;
  await doActionWithWait('deleteCustomActionOptionWait', async () => {
    const api = new CustomActionOptionApiService();
    await api.deleteCustomActionOption(
      {
        request: {
          customActionOptionId,
        },
      },
    );
    emit('afterSave');
  });
};
const handleAppendClick = () => {
  isCreatingInputVisible.value = true;
};
const handleCreateingInputCheckClick = async () => {
  await doActionWithWait('createCustomActionOptionWait', async () => {
    const api = new CustomActionOptionApiService();
    await api.createCustomActionOption(
      {
        request: {
          customActionId: props.customActionId,
          postCustomActionOptionBody: {
            name: creatingInputValue.value,
          },
        },
      },
    );
    emit('afterSave');
  });
  clearCreatingInput();
};
const handleCreateingInputCloseClick = () => {
  clearCreatingInput();
};
const clearCreatingInput = () => {
  isCreatingInputVisible.value = false;
  creatingInputValue.value = '';
};
</script>

<style lang="scss" scoped>
.option-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $bdcolor-light;
  line-height: 36px;
  font-size: $fontsize-100;
  width: 420px;
}
.creating-input {
  width: 420px;
}
</style>

import Waiting from '@/store/waiting'

export default class LeadSourceActions {
  constructor (Api, types) {
    const actions = {
      async getLeadSourcesAction ({ commit }, args) {
        await waiting.check('getLeadSourcesAction', async () => {
          try {
            const res = await Api.getLeadSources(args)
            commit(types.SET_LEAD_SOURCES, Object.values(res.data)[0])
          } catch (err) {
            commit(types.CLEAR_LEAD_SOURCES)
            throw err
          }
        })
      },
      async createLeadSourceAction ({ commit }, args) {
        await waiting.check('createLeadSourceAction', async () => {
          await Api.createLeadSource(args)
          try {
            const res = await Api.getLeadSources(args)
            commit(types.SET_LEAD_SOURCES, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
      async updateLeadSourceAction ({ commit }, args) {
        await waiting.check('updateLeadSourceAction', async () => {
          await Api.updateLeadSource(args)
          try {
            const res = await Api.getLeadSources(args)
            commit(types.SET_LEAD_SOURCES, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
      async deleteLeadSourceAction ({ commit }, args) {
        await waiting.check('deleteLeadSourceAction', async () => {
          await Api.deleteLeadSource(args)
          try {
            const res = await Api.getLeadSources(args)
            commit(types.SET_LEAD_SOURCES, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

<template>
  <div class="pt-600">
    <BContainer full>
      <BCard shadow>
        <BLayout
          class="mb-200"
          justify-space-between
          align-center
        >
          <h2>{{ $t('sendgridSubUserManagement.title') }}</h2>
          <BBtn
            v-if="isAdminDeveloper"
            type="primary"
            @click="openInviteDialog"
          >
            {{ $t('sendgridSubUserManagement.create') }}
          </BBtn>
        </BLayout>
        <SendgridSubUsersList
          :is-admin-developer="isAdminDeveloper"
          @exec-delete="execDelete"
        />
      </BCard>
      <FormDialog
        :dialog="inviteDialog"
        width="500"
      >
        <template #form>
          <SendgridSubUserInviteForm
            ref="inviteForm"
            :loading="$wait.is(['createAdminWait', 'postSendgridApiSettingWait'])"
            @save="exec"
            @cancel="closeInviteDialog"
          />
        </template>
      </FormDialog>
    </BContainer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapWaitingActions } from 'vue-wait'
import { SendgridSubUserApiService } from '@/api/admin/resources/sendgrid_sub_user'
import SendgridSubUserInviteForm from '@/components/organisms/admin/SendgridSubUserInviteForm.vue'
import SendgridSubUsersList from '@/components/organisms/admin/SendgridSubUsersList.vue'
import { useCurrentUser } from '@/composable/admin/user/users'
import { useWait } from '@/composable/vue-wait'
import errorHandler from '@/mixins/error_handler'

export default defineComponent({
  components: {
    SendgridSubUserInviteForm,
    SendgridSubUsersList,
  },
  mixins: [errorHandler],
  setup() {
    const { isAdminDeveloper } = useCurrentUser();
    const { doActionWithWait } = useWait();
    return {
      isAdminDeveloper,
      doActionWithWait,
    };
  },
  data() {
    return {
      inviteDialog: false,
    }
  },
  created() {
    this.getSendgridSubUsersAction()
    this.getClientsAction()
    const breadcrumbs = [{ text: this.$t('clientManagement.title'), path: { name: 'AdminClients' } }]
    this.setBreadcrumbsAction(breadcrumbs)
  },
  methods: {
    ...mapWaitingActions('breadcrumbs', {
      setBreadcrumbsAction: 'setBreadcrumbsWait',
    }),
    ...mapWaitingActions('admin', {
      deleteSendgridSubUserAction: 'deleteSendgridSubUserWait',
      getSendgridSubUsersAction: 'getSendgridSubUsersWait',
      getClientsAction: 'getClientsWait',
    }),
    openInviteDialog() {
      this.inviteDialog = true
    },
    closeInviteDialog() {
      this.inviteDialog = false
      this.$refs.inviteForm.reset()
    },
    async exec({ clientId, sendgridDomainId }) {
      await this.doActionWithWait('postSendgridApiSettingWait', async () => {
        const api = new SendgridSubUserApiService();
        await api.postSendgridApiSetting({
          request: {
            postSendgridApiSettingRequest: {
              clientId,
              sendgridDomainId,
            },
          },
          errorHandlers: { 422: this.defaultHandler },
        });
      });

      this.closeInviteDialog()
      this.getSendgridSubUsersAction()
      this.$toast.show(this.$t('general.done.of', { action: this.$t('general.invite') }))
    },
    async execDelete({ id }) {
      await this.deleteSendgridSubUserAction({
        id,
        errorHandlers: { 422: this.defaultHandler },
      })
      this.$toast.show(this.$t('general.done.of', { action: this.$t('general.delete.text') }))
    },
  },
})
</script>

<style lang="scss" scoped>
.slug {
  color: $basecolor-primary;
  font-weight: 600;
  margin: 0 2px;
}
</style>

<template>
  <ElUpload
    class="b-avatar-upload"
    accept="image/*"
    drag
    :show-file-list="false"
    :auto-upload="false"
    :on-change="handleChange"
    :class="{
      'with-image': internalImageUrl,
    }"
  >
    <img
      v-if="displayImageUrl"
      ref="refImage"
      :src="displayImageUrl"
      class="avatar"
    >
    <ElIcon
      v-else
      class="b-avatar-upload-icon"
    >
      <Plus />
    </ElIcon>
  </ElUpload>
</template>

<script lang="ts" setup>
import { Plus } from '@element-plus/icons-vue'
import type { UploadProps } from 'element-plus'
import { computed, ref } from 'vue'
import { convertBase64ToBlob, convertBlobToUrl, convertFileToBlob, cropImageToSquare, loadImage, resizeImage } from '@/utils/image';

const props = withDefaults(defineProps<TProps>(), {
  modelValue: null,
});
const emit = defineEmits(['update:modelValue']);

const FILE_MAX_LENGTH = 320;

type TProps = {
  modelValue?: Blob | null;
  imageUrl: string;
};

const displayImageUrl = computed(() => internalImageUrl.value || props.imageUrl);
const imageBlob = computed<Blob | null>({
  get: () => props.modelValue,
  set: (val) => {
    internalImageUrl.value = val ? convertBlobToUrl(val) : null;
    emit('update:modelValue', val);
  },
});
const refImage = ref<HTMLImageElement | null>(null);
const internalImageUrl = ref<string | null>();

const handleChange: UploadProps['onChange'] = async (uploadFile) => {
  if (!uploadFile.raw) {
    imageBlob.value = null;
  }
  const blob = convertFileToBlob(uploadFile.raw);
  imageBlob.value = await resizeAndCropBlob(blob);
};

const resizeAndCropBlob = async (blob: Blob) => {
  const image = await loadImage(convertBlobToUrl(blob));
  const croppedBase64 = cropImageToSquare(image);

  const croppedImage = await loadImage(croppedBase64);
  const resizedBase64 = resizeImage(croppedImage, FILE_MAX_LENGTH);

  return convertBase64ToBlob(resizedBase64);
};
</script>

<style type="scss" scoped>
.b-avatar-upload {
  &.with-image :deep(.el-upload-dragger) {
    /* NOTE: borderを見えなくする */
    border-color: var(--el-fill-color-blank);
  }
  &.with-image :deep(.el-upload-dragger:hover) {
    border-color: var(--el-color-primary);
  }
  &, .avatar, .b-avatar-upload-icon {
    width: 160px;
    height: 160px;
  }
  .avatar {
    object-fit: cover;
    display: block;
  }
  .b-avatar-upload-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100%;
    height: 160px;
    text-align: center;
  }
}
:deep(.el-upload-dragger), :deep(.el-upload-dragger.is-dragover) {
  padding: 0;
  border-color: var(--el-fill-color-blank);
}
</style>

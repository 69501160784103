import ApiService, { ApiArguments } from '@/api/api_service';
import { GetTriggerTargetActionApprovals200Response, TriggerTargetActionApi, TriggerTargetActionApiGetTriggerTargetActionApprovalsRequest, TriggerTargetActionApiPutApproveTriggerTargetActionRequest } from '@/api/openapi';

export class TriggerTargetActionApiService extends ApiService {
  api = new TriggerTargetActionApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true)
  }

  async getTriggerTargetActionApprovals(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<TriggerTargetActionApiGetTriggerTargetActionApprovalsRequest>,
  ) {
    return this.checkResponse<GetTriggerTargetActionApprovals200Response>(
      this.api.getTriggerTargetActionApprovals(request, options),
      errorHandlers,
    );
  }

  async approveTriggerTargetAction({
    request,
    options,
    errorHandlers,
  }: ApiArguments<TriggerTargetActionApiPutApproveTriggerTargetActionRequest>) {
    return this.checkResponse<void>(
      this.api.putApproveTriggerTargetAction(request, options),
      errorHandlers,
    );
  }

  async rejectTriggerTargetAction({
    request,
    options,
    errorHandlers,
  }: ApiArguments<TriggerTargetActionApiPutApproveTriggerTargetActionRequest>) {
    return this.checkResponse<void>(
      this.api.putRejectTriggerTargetAction(request, options),
      errorHandlers,
    );
  }
}

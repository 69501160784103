import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "trigger-action-form-body-container" }
const _hoisted_2 = { class: "fs-300 fw-bold" }
const _hoisted_3 = { class: "list-group" }
const _hoisted_4 = { class: "list-group" }
const _hoisted_5 = { class: "mb-200" }
const _hoisted_6 = {
  key: 0,
  class: "action-detail-container confirm"
}
const _hoisted_7 = { class: "fs-300 fw-bold" }
const _hoisted_8 = { class: "list-group" }
const _hoisted_9 = { class: "footer" }

import { ref, watch, nextTick, computed } from 'vue';
import { useI18n } from 'vue-i18n'
import { useTriggerAction, useTriggerActionForm } from '@/composable/user/trigger/trigger-action';
import { useUsers } from '@/composable/user/user/users';
import SequenceMasterSimpleSelect from '../SequenceMasterSimpleSelect.vue';
import { TriggerActionBodyExt } from '../types';

type TProps = {
  editable: boolean;
};
type TEmits = {
  'click:cancel': [];
  'click:triggerAction': [triggerAction: TriggerActionBodyExt, menu: string];
};

export default /*@__PURE__*/_defineComponent({
  __name: 'ConfirmDrawer',
  props: /*@__PURE__*/_mergeModels({
    editable: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
    "isOpen": { type: Boolean, ...{ default: false } },
    "isOpenModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["click:cancel", "click:triggerAction"], ["update:modelValue", "update:isOpen"]),
  setup(__props: any, { emit: __emit }) {


const emit = __emit;

const model = _useModel<TriggerActionBodyExt | null>(__props, "modelValue");
const isOpen = _useModel<boolean>(__props, 'isOpen');

const i18n = useI18n();

const { users } = useUsers();
const approveUser = computed(() => {
  return users.value.find((user) => user.id === internalTriggerAction.value.approveUserId);
});

const { makeInitialTriggerAction } = useTriggerAction();
const { forms, fetchForms, loading } = useTriggerActionForm();
const internalTriggerAction = ref<TriggerActionBodyExt>();

const clearInternalValues = () => {
  internalTriggerAction.value = makeInitialTriggerAction(model.value);
  forms.value = [];
};
clearInternalValues();

watch(() => internalTriggerAction.value.actionType, value => {
  if (value) fetchForms(value);
});

watch(() => isOpen.value, () => {
  nextTick(() => {
    clearInternalValues();
  });
});

const handleCancelClick = () => {
  emit('click:cancel');
};

const handleEditClick = () => {
  emit('click:triggerAction', model.value, 'edit');
};

return (_ctx: any,_cache: any) => {
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BDrawerMenu = _resolveComponent("BDrawerMenu")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_BDrawerMenu, {
    modelValue: isOpen.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isOpen).value = $event)),
    class: "trigger-action-form-container",
    width: "600px",
    title: _ctx.$t('trigger.actionSetting.title'),
    onCancel: handleCancelClick
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_BLayout, {
          "align-center": "",
          "justify-center": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BBtn, {
              class: "mr-300 btn",
              text: "",
              onClick: handleCancelClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.cancel.text')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_BBtn, {
              class: "ml-300 btn",
              type: "primary",
              outline: "",
              disabled: !_ctx.editable,
              onClick: handleEditClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.edit.text')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BListItem, { class: "pb-100" }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('trigger.actionSetting.targetSelect')), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BListItem, { class: "list-item" }, {
            header: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('trigger.actionSetting.actionType')), 1)
            ]),
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_unref(i18n).t(`trigger.actionTypes.${internalTriggerAction.value.actionType}`)), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_BListItem, { class: "list-item" }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('trigger.actionSetting.actionDetail')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_BListItem, { class: "list-item" }, {
            default: _withCtx(() => [
              (!!internalTriggerAction.value.actionType)
                ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(forms), (form) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: form.actionDetailKey
                      }, [
                        (form.inputType === 'sequence_select')
                          ? (_openBlock(), _createBlock(_component_BListItem, {
                              key: 0,
                              class: "list-item"
                            }, {
                              header: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(form.label), 1)
                              ]),
                              default: _withCtx(() => [
                                _createVNode(SequenceMasterSimpleSelect, {
                                  modelValue: (internalTriggerAction.value.actionDetail[form.actionDetailKey] as number | null),
                                  "onUpdate:modelValue": ($event: any) => (((internalTriggerAction.value.actionDetail[form.actionDetailKey] as number | null)) = $event),
                                  "is-display-only": ""
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ])), [
                    [_directive_loading, _unref(loading)]
                  ])
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_BListItem, { class: "pt-200 pb-200" }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('trigger.actionSetting.approveUserSelect')), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_BListItem, { class: "list-item" }, {
            header: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('trigger.actionSetting.approveUser')), 1)
            ]),
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(approveUser.value ? approveUser.value.name : ''), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}
}

})
<template>
  <BIcon
    class="mr-100"
    size="small"
    type="gray"
  >
    {{ iconName }}
  </BIcon>  
</template>

<script lang="ts" setup>
import { computed } from 'vue'

export type TDataType = typeof DATA_TYPE[keyof typeof DATA_TYPE];

type TProps = {
  dataType: TDataType
};

const props = defineProps<TProps>();

const iconName = computed(() => {
  switch (props.dataType) {
    case 'text':
      return 'text_fields'
    case 'datetime':
      return 'calendar_today'
    case 'option':
      return 'radio_button_checked'
    case 'number':
      return 'looks_one'
    case 'multi_option':
      return 'checklist'
    case 'sf_reference':
      return 'account_box'
    default:
      return ''
  }
});
</script>

<script lang="ts">
export const DATA_TYPE = {
  Text: 'text',
  DateTime: 'datetime',
  Option: 'option',
  Number: 'number',
  MultiOption: 'multi_option',
  SFReference: 'sf_reference',
} as const;
</script>

import Waiting from '@/store/waiting'
import SfBaseActions from './sf_base'

export default class SfUserActions extends SfBaseActions {
  constructor(Api, types) {
    super()
    const actions = {
      getSfUsersAction: this.SalesForceBaseAction(({ commit }, args) => {
        return waiting.check('getSfUsersAction', async () => {
          try {
            const result = await Api.getSfUsers(args)
            commit(types.SET_SF_USERS, Object.values(result.data)[0])

            return result.data.sfUsers
          } catch (err) {
            throw err
          }
        })
      }),
      getSfUsersByNameAction: this.SalesForceBaseAction(({ commit }, args) => {
        return waiting.check('getSfUsersByNameAction', async () => {
          try {
            const result = await Api.getSfUsersByName(args)
            return result.data.sfUsers
          } catch (err) {
            throw err
          }
        })
      }),
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

<template>
  <div>
    <BCheckbox
      v-if="!noBlankItem"
      v-model="internalCheckedItem"
      class="my-100"
      :label="checkboxBlankItem.label"
      :value="checkboxBlankItem.value"
    />
    <div class="item-filter-area">
      <div
        v-if="isSearchText"
        class="item-filter-area-input"
      >
        <BInput
          v-model="searchText"
          flat
          placeholder="候補を入力"
        />
        <BCheckbox
          class="all-checkbox"
          :model-value="isAllSelected"
          :indeterminate="isPartiallySelected"
          :disabled="noCandidate"
          @change="toggleCheckAll"
        />
      </div>
      <div class="item-filter-area-candidate">
        <div v-if="filteredCandidateItemValues.length > 0">
          <div 
            v-for="group in filteredCandidateGroups"
            :key="group.name"
            class="group"
          >
            <div
              v-for="item in group.items"
              :key="item.value"
              class="my-100"
            >
              <BCheckbox
                v-model="internalCheckedItem"
                :value="item.value"
              >
                <template #label>
                  <Component
                    v-bind="item.labelPrefixAttributes"
                    :is="item.labelPrefixComponent"
                  />
                  {{ item.label }}
                </template>
              </BCheckbox>
            </div>
          </div>
        </div>
        <div v-else>
          <span class="text-annotation">{{ $t('general.noCandidate') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'

type TCandidateItem = {
  label: string;
  value: string;
  labelPrefixComponent?: unknown;
  labelPrefixAttributes?: string;
};

type TCandidateItems = TCandidateItem[];

type TCandidateGroup = {
  name: string;
  items: TCandidateItem[];
};
function isTCandidateGroup(arg: any): arg is TCandidateGroup {
  if (arg.name === undefined) return false
  if (arg.items === undefined) return false
  if (!Array.isArray(arg.items)) return false
  return true
}
type TCandidateGroupingItems = TCandidateGroup[];
function isTCandidateGroupingItems(arg: any): arg is TCandidateGroupingItems {
  if (!Array.isArray(arg)) return false
  if (arg.some(e => !isTCandidateGroup(e))) return false
  return true
}

export default defineComponent({
  props: {
    checkedItem: {
      type: Array,
      default() {
        return []
      },
    },
    candidateItems: {
      type: Array as PropType<TCandidateItems | TCandidateGroupingItems>,
      default() {
        return []
      },
    },
    blankItem: {
      type: Object,
    },
    noBlankItem: Boolean,
    isSearchText: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'update:checked-item',
  ],
  data() {
    return {
      searchText: '',
    }
  },
  computed: {
    internalCheckedItem: {
      get() {
        return this.checkedItem
      },
      set(newVal) {
        this.$emit('update:checked-item', newVal)
      },
    },
    checkboxBlankItem() {
      return {
        label: this.blankItem?.label || this.$t('general.blankInput'),
        value: this.blankItem?.value || this.$t('general.blankInput'),
      }
    },
    candidateGroups(): TCandidateGroupingItems {
      if (isTCandidateGroupingItems(this.candidateItems)) return this.candidateItems
      return [{
        name: 'no group',
        items: this.candidateItems,
      }]
    },
    filteredCandidateGroups(): TCandidateGroupingItems {
      return this.candidateGroups.map(group => {
        return {
          name: group.name,
          items: group.items.filter((item) => {
            return item.label.includes(this.searchText)
          }),
        }
      })
    },
    filteredCandidateItemValues() {
      return this.filteredCandidateGroups.map(group => group.items.map((item) => item.value)).flat()
    },
    noCandidate() {
      return this.filteredCandidateItemValues.length <= 0
    },
    isPartiallySelected() {
      if (this.isAllSelected) return false
      return this.filteredCandidateItemValues.some((value) =>
        this.internalCheckedItem.includes(value),
      )
    },
    isAllSelected() {
      if (this.noCandidate) return false
      return this.filteredCandidateItemValues.every((value) =>
        this.internalCheckedItem.includes(value),
      )
    },
  },
  methods: {
    toggleCheckAll(value) {
      if (this.noCandidate) return

      let newCheckedItem = []
      if (value) {
        // 重複を排除するために Set を使う
        newCheckedItem = Array.from(
          new Set(
            this.internalCheckedItem.concat(this.filteredCandidateItemValues),
          ),
        )
      } else {
        newCheckedItem = this.internalCheckedItem.filter(
          (item) => !this.filteredCandidateItemValues.includes(item),
        )
      }

      this.internalCheckedItem = newCheckedItem
    },
  },
})
</script>

<style lang="scss" scoped>
.item-filter-area {
  border: 1px solid $bdcolor-base;
  border-radius: 4px;

  &-input {
    border-bottom: 1px solid $bdcolor-base;
    position: relative;
  }

  .item-filter-area-candidate {
    .group:nth-child(n+2):before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: $bdcolor-base;
      margin: 18px 0;
    }
  }

  .all-checkbox {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &-candidate {
    padding: $basespace-200;
  }
}
</style>

const altText = (value: unknown, altText?: string): unknown => {
  if (shouldAlternate(value)) {
    return altText || '-'
  } else {
    return value
  }
}

const shouldAlternate = (value: unknown): boolean =>
  value == null || typeof value === 'string' && value.length === 0;

export {
  altText,
  shouldAlternate,
}

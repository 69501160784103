import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "draft-reminder-timeline-container" }

import { computed, ref } from 'vue'
import { MailRemindItem } from '@/api/openapi';

type TProps = {
  items: MailRemindItem[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DraftMailReminderTimeline',
  props: {
    items: {}
  },
  setup(__props: any) {

const props = __props;

const existItems = computed(() => {
  return props.items.length > 0
})

return (_ctx: any,_cache: any) => {
  const _component_BEmptyBox = _resolveComponent("BEmptyBox")!
  const _component_DraftMailReminderItem = _resolveComponent("DraftMailReminderItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!existItems.value)
      ? (_openBlock(), _createBlock(_component_BEmptyBox, {
          key: 0,
          class: "empty",
          "display-only": ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('draftMailReminder.empty')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (timelineItems) => {
      return (_openBlock(), _createBlock(_component_DraftMailReminderItem, {
        key: timelineItems.directMailId,
        item: timelineItems
      }, null, 8, ["item"]))
    }), 128))
  ]))
}
}

})
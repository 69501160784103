<template>
  <div class="sequence-master-simple-select-container">
    <BTooltip
      top
      :content="sequenceBtnTooltipContent"
    >
      <a
        :href="sequencePath"
        target="_blank"
        class="sequence-btn"
        rel="noopener noreferrer"
      >
        <BBtn
          size="mini"
          fab
          :disabled="!model"
        >
          <BCustomIcon
            class="sequence-icon"
            size="small"
            icon-class="b-sequence"
          />
        </BBtn>
      </a>
    </BTooltip>
    <span
      v-if="isDisplayOnly"
    >
      {{ sequenceMasterName(model) }}
    </span>
    <BSelect
      v-else
      v-model="model"
      v-model:valid="isValid"
      :items="sequenceMasters"
      :required="required"
      item-text="name"
      item-value="id"
      fit
      filterable
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { SequenceMaster } from '@/api/openapi';
import { SequenceMasterApiService } from '@/api/user/resources/sequence_master';
import { useWait } from '@/composable/vue-wait';

type TProps = {
  required: boolean;
  isDisplayOnly: boolean;
};
const props = defineProps<TProps>();

const i18n = useI18n();
const router = useRouter();
const { doActionWithWait } = useWait();

const model = defineModel<number | null>()
const isValid = defineModel<boolean>('isValid', { default: false });

const sequencePath = computed(() => {
  if (!model.value) {
    return '';
  }
  const id = model.value;
  return router.resolve({ name: 'Sequence', params: { id } }).href; 
});

const sequenceMasters = ref<SequenceMaster[]>([]);
const loadSequenceMasters = async () => {
  const api = new SequenceMasterApiService();
  await doActionWithWait('fetchSequenceMasters', async () => {
    const { data: { sequenceMasters: sequenceMastersRes } } = await api.getSequenceMasters({
      request: {
        fields: ['name'],
        noLimit: true,
      },
    });
    sequenceMasters.value = sequenceMastersRes;
  });
};
loadSequenceMasters();

const sequenceBtnTooltipContent = computed(() => {
  const item = i18n.t('sequence.title');
  return model.value ? i18n.t('general.open.andCheck', { item }) : i18n.t('general.open.cannotAndCheckCauseNotSelected', { item });
});

const sequenceMasterName = (sequenceMasterId: number) => {
  return sequenceMasters.value.find((sequenceMaster) => sequenceMaster.id === sequenceMasterId)?.name;
};
</script>

<style lang="scss" scoped>
.sequence-master-simple-select-container {
  position: relative;

  .sequence-icon {
    margin-right: 1px; /* アイコンの形に起因してか、実際は中心に来ていそうだが見た目が少し右寄りに見えるため、微調整。 */
  }
  .sequence-btn {
    position: absolute;
    bottom: 46px;
    right: 0;
  }
}
</style>

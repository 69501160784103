<template>
  <div class="biscuet-container">
    <div
      id="inspire"
      :style="bAppStyle"
    >
      <UpdateNotification />
      <ClientExpiredNotification />
      <BLayout>
        <Header />
        <Sidebar />
        <FilterControlPanel v-if="$route.name === 'LeadList'" />
        <RouterView :key="$route.fullPath" />
      </BLayout>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ClientExpiredNotification from '@/components/organisms/ClientExpiredNotification.vue'
import UpdateNotification from '@/components/organisms/UpdateNotification.vue'
import Header from '@/components/organisms/user/Header.vue'
import FilterControlPanel from '@/components/organisms/user/leadList/filter/FilterControlPanel.vue'
import Sidebar from '@/components/organisms/user/Sidebar.vue'

export default defineComponent({
  name: 'User',
  components: {
    FilterControlPanel,
    Sidebar,
    Header,
    UpdateNotification,
    ClientExpiredNotification,
  },
  computed: {
    bAppStyle() {
      return {
        width: '100%',
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.biscuet-container {
  @include m-fixed-height(100vh);
  display: flex;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "fs-400 fw-bold" }

import { inject, nextTick, ref } from 'vue';
import { useRoute } from 'vue-router';
import SequenceApplyModal from '@/components/organisms/user/sequence/modal/SequenceApplyModal.vue'
import BulkActionsFooter from '@/components/organisms/user/sequenceLeads/BulkActionsFooter.vue';
import SequenceMaster from '@/components/organisms/user/sequenceLeads/Sequence.vue';
import SequenceLeads from '@/components/organisms/user/sequenceLeads/SequenceLeads.vue'
import { useQueryParameter } from '@/composable/query-parameter';
import { useUrlLinkBtabsHandlers } from '@/composable/url-link-b-tabs';
import { i18nGlobal as i18n } from '@/i18n';
import { KEY_OF_MODAL } from '@/injection-keys';

const SEQUENCE_APPLY_MODAL_NAME = 'sequenceApplyModal';


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceLeads',
  setup(__props) {

const modal = inject(KEY_OF_MODAL);
const route = useRoute();
const sequenceMasterId = parseInt(route.params.id as string);

const { param: leadListPage } = useQueryParameter<string>('leadListPage');

const handleAfterMoveLeadListPageUpdate = (afterPage: number) => {
  leadListPage.value = `${afterPage || ''}`;
};

const tabLabelItems = [
  { label: i18n.t('sequence.step.title'), slotName: 'step', route: { name: 'Sequence', params: { id: sequenceMasterId } } },
  { label: i18n.t('sequence.lead'), slotName: 'lead' },
];

const { handleTabClick, handleTabBeforeLeave } = useUrlLinkBtabsHandlers(tabLabelItems);

const activeName = ref('lead');
const selectedSequenceInstanceIds = ref<number[]>([]);

const renderLeads = ref(true);
const reRenderLeads = async () => {
  renderLeads.value = false;
  await nextTick(() => renderLeads.value = true);
};

const handleUpdateSelectedSequenceInstanceIds = (sequenceInstanceIds: number[]) => {
  selectedSequenceInstanceIds.value = sequenceInstanceIds;
};
const handleAfterBulkAction = () => {
  reRenderLeads();
};
const handleAfterSequenceApply = () => {
  reRenderLeads();
};
const handleClickApplySequence = () => {
  modal.show(SEQUENCE_APPLY_MODAL_NAME);
};
const handleCloseApplySequenceModal = () => {
  modal.hide(SEQUENCE_APPLY_MODAL_NAME);
};

return (_ctx: any,_cache: any) => {
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_BTabs = _resolveComponent("BTabs")!
  const _component_BContent = _resolveComponent("BContent")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_BContent, null, {
    navbar: _withCtx(() => [
      _createVNode(_component_BLayout, {
        "align-start": "",
        "justify-start": ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('sequence.title')), 1)
        ]),
        _: 1
      })
    ]),
    waiting: _withCtx(() => [
      _withDirectives(_createVNode(_component_BPlaceholder, { "element-loading-background": "transparent" }, null, 512), [
        [_directive_loading, true]
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(BulkActionsFooter, {
        "selected-sequence-instance-ids": selectedSequenceInstanceIds.value,
        onAfterBulkAction: handleAfterBulkAction
      }, null, 8, ["selected-sequence-instance-ids"])
    ]),
    default: _withCtx(() => [
      _createVNode(SequenceApplyModal, {
        "modal-name": SEQUENCE_APPLY_MODAL_NAME,
        "sequence-master-id": _unref(sequenceMasterId),
        onAfterApply: handleAfterSequenceApply,
        onClose: handleCloseApplySequenceModal
      }, null, 8, ["sequence-master-id"]),
      _createVNode(SequenceMaster, {
        "sequence-master-id": _unref(sequenceMasterId),
        editable: false
      }, null, 8, ["sequence-master-id"]),
      _createVNode(_component_BTabs, {
        modelValue: activeName.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((activeName).value = $event)),
        class: _normalizeClass(["tabs", {'with-footer': selectedSequenceInstanceIds.value.length > 0}]),
        "label-items": tabLabelItems,
        "before-leave": _unref(handleTabBeforeLeave),
        "onClick:tab": _unref(handleTabClick)
      }, {
        lead: _withCtx(() => [
          (renderLeads.value)
            ? (_openBlock(), _createBlock(SequenceLeads, {
                key: 0,
                "sequence-master-id": _unref(sequenceMasterId),
                "instance-list-page": parseInt(_unref(leadListPage), 10),
                "onUpdate:selectedSequenceInstanceIds": handleUpdateSelectedSequenceInstanceIds,
                "onAfter:moveInstanceListPage": handleAfterMoveLeadListPageUpdate,
                "onClick:applyToLeadButton": handleClickApplySequence
              }, null, 8, ["sequence-master-id", "instance-list-page"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue", "class", "before-leave", "onClick:tab"])
    ]),
    _: 1
  }))
}
}

})
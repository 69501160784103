import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "select-user" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUsers } from '@/composable/user/user/users';
import { TGroups } from '@/plugins/biscuet-materials/components/atoms/BSelect.vue';

export type TSequenceOwnerValue = number | 'leadOwner' | 'sequenceOwner' | undefined;


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectSequenceOwner',
  props: {
    "modelValue": {
  default: 'sequenceOwner',
  },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

const model = _useModel<TSequenceOwnerValue>(__props, "modelValue", {
  set(value) {
    return value == null ? 'sequenceOwner' : value;
  },
});

const i18n = useI18n();

const { activeUsers } = useUsers();
const userItems = computed<TGroups>(() => {
  return [
    {
      key: 'owners',
      label: i18n.t('sequence.selectOwner.owners'),
      options: [
        { text: i18n.t('sequence.selectOwner.leadOwner'), value: 'leadOwner' },
        { text: i18n.t('sequence.selectOwner.sequenceOwner'), value: 'sequenceOwner' },
      ],
    },
    {
      key: 'users',
      label: i18n.t('sequence.selectOwner.users'),
      options: activeUsers.value.map((candidate) => (
        { text: candidate.name, value: candidate.id }
      )),
    },
  ];
})

return (_ctx: any,_cache: any) => {
  const _component_BSelect = _resolveComponent("BSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BSelect, {
      modelValue: model.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
      items: userItems.value,
      placeholder: _ctx.$t('sequence.selectOwner.placeholder'),
      "item-text": "text",
      "item-value": "value",
      filterable: "",
      class: "type-select"
    }, null, 8, ["modelValue", "items", "placeholder"])
  ]))
}
}

})
<template>
  <div class="header-wrapper">
    <div class="header">
      <div class="logo-and-action-menu">
        <RouterLink
          class="brand-logo"
          :to="{ name: 'Home' }"
        >
          <img
            :src="require('@/assets/brand_logo.svg')"
            height="25.5"
          >
        </RouterLink>
        <ActionMenu class="action-menu" />
      </div>
      <Notification />
      <div class="right-content">
        <Settings />
      </div>
    </div>
  </div>
</template>

<script>
import ActionMenu from '@/components/organisms/user/ActionMenu.vue'
import Notification from '@/components/organisms/user/Notification.vue'
import Settings from '@/components/organisms/user/Settings.vue'

export default {
  components: {
    ActionMenu,
    Settings,
    Notification,
  },
}
</script>

<style lang="scss" scoped>
  .header-wrapper {
    @include m-fixed-width(0px);
    transition: $transition-base;
    position: relative;

    .header {
      @include m-fixed-width(100vw);
      @include m-fixed-height(50px);
      position: fixed;
      background-color: $bgcolor-white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 9;
      box-shadow: 0 2px 6px 0 $shadowcolor-base;

      .action-menu {
        margin-left: 20px;
      }
    }
  }

  .brand-logo {
    margin-top: 6px;
    margin-left: 22px;
  }

  .sidebar-profile {
    position: absolute;
    bottom: 0;
    display: flex;
    height: 70px;
    width: 100%;
    padding: $basespace-200 $basespace-400;
    transition: $transition-base;

    &:hover {
      background-color: $bgcolor-dark;
    }
  }

  .right-content {
    display: flex;
    gap: 0px 40px
  }

  .sidebar-card {
    width: 280px;
  }

  .user-name {
    margin-top: 3px;
    font-size: 13px;
  }

  .user-email {
    color: $textcolor-light;
    margin-top: 3px;
    font-size: 11px;
  }

  .logo-and-action-menu {
    display: flex;
    align-items: center;
  }
</style>

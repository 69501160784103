<template>
  <ElAlert
    :title="text"
    :type="type"
    :effect="effect"
    :closable="closable"
    :close-text="closeText"
    :description="description"
    :show-icon="showIcon"
  />
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'success',
    },
    effect: {
      type: String,
      default: 'dark',
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: false,
    },
    closeText: String,
    description: String,
  },
}
</script>

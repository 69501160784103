import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "duplicated-list-modal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BModalHeader = _resolveComponent("BModalHeader")!
  const _component_DuplicatedLeadList = _resolveComponent("DuplicatedLeadList")!
  const _component_SelectLeadItems = _resolveComponent("SelectLeadItems")!
  const _component_Confirm = _resolveComponent("Confirm")!
  const _component_BHeightAdjuster = _resolveComponent("BHeightAdjuster")!
  const _component_BModal = _resolveComponent("BModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BModal, {
      "modal-name": "duplicatedList",
      delegate: _ctx.bModalDelegate
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BHeightAdjuster, null, {
          default: _withCtx(() => [
            _createVNode(_component_BModalHeader, {
              title: _ctx.$t('duplicatedList.title'),
              center: true,
              onModalClose: _ctx.handleClose
            }, {
              default: _withCtx(() => [
                (_ctx.isDisplayPrevious)
                  ? (_openBlock(), _createBlock(_component_BBtn, {
                      key: 0,
                      class: "previous-button mx-300",
                      size: "small",
                      fab: "",
                      flat: "",
                      onClick: _ctx.handleClickPrevious
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BIcon, {
                          class: "fs-400",
                          size: "small"
                        }, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode(" keyboard_arrow_left ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["title", "onModalClose"]),
            (_ctx.step === 'leadList')
              ? (_openBlock(), _createBlock(_component_DuplicatedLeadList, {
                  key: 0,
                  modelValue: _ctx.selectedCallTargetIds,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCallTargetIds) = $event)),
                  "duplicated-call-targets": _ctx.duplicatedCallTargets,
                  "onUpdate:duplicatedCallTargets": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.duplicatedCallTargets) = $event)),
                  "default-selected-call-target-ids": _ctx.defaultSelectedCallTargetIds,
                  "onUpdate:defaultSelectedCallTargetIds": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.defaultSelectedCallTargetIds) = $event)),
                  onClickNext: _ctx.handleClickNext
                }, null, 8, ["modelValue", "duplicated-call-targets", "default-selected-call-target-ids", "onClickNext"]))
              : _createCommentVNode("", true),
            (_ctx.step === 'selectLeadItems')
              ? (_openBlock(), _createBlock(_component_SelectLeadItems, {
                  key: 1,
                  modelValue: _ctx.leadItemSelectedValues,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.leadItemSelectedValues) = $event)),
                  "selected-leads": _ctx.selectedLeads,
                  "onUpdate:selectedLeads": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedLeads) = $event)),
                  "selected-call-target-ids-with-order": _ctx.selectedCallTargetIdsWithOrder,
                  onClickNext: _ctx.handleClickNext
                }, null, 8, ["modelValue", "selected-leads", "selected-call-target-ids-with-order", "onClickNext"]))
              : _createCommentVNode("", true),
            (_ctx.step === 'confirm')
              ? (_openBlock(), _createBlock(_component_Confirm, {
                  key: 2,
                  "selected-leads": _ctx.selectedLeads,
                  "lead-item-selected-values": _ctx.leadItemSelectedValues,
                  loading: _ctx.$wait.is('mergeCallTargetWait'),
                  onClickMerge: _ctx.handleClickMerge
                }, null, 8, ["selected-leads", "lead-item-selected-values", "loading", "onClickMerge"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["delegate"])
  ]))
}
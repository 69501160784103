<template>
  <BHeightAdjuster>
    <div class="lead-stage-item-container">
      <div
        v-loading="loading"
        class="list-container"
      >
        <Draggable
          v-model="internalLeadStages"
          class="item-row"
          item-key="id"
          handle=".handle"
          :animation="300"
          :move="handleMove"
          @change="handleSortOrderChange"
        >
          <template #item="{ element: leadStage }">
            <BLayout
              class="py-100"
              align-center
              justify-space-between
            >
              <BLayout
                class="ml-100 mr-100"
                align-center
              >
                <span class="handle-wrap">
                  <BIcon
                    v-if="leadStage.isPreset === false"
                    class="handle"
                    size="small"
                    type="gray"
                  >
                    drag_indicator
                  </BIcon>
                </span>
                <EditableLeadStageColorPreview
                  :uneditable="leadStage.isPreset"
                  :model-value="leadStage.colorName"
                  @update:model-value="handleColorChange($event, leadStage.id)"
                />
                <BEditableContent
                  :uneditable="leadStage.isPreset"
                  :value="leadStage.name"
                  :placeholder="$t('leadStage.name')"
                  :rules="[requiredRule]"
                  :max-length="maxNameLength"
                  :loading="$wait.is(['updateLeadStageWait'])"
                  @check="handleNameChange($event, leadStage.id)"
                />
              </BLayout>
              <span class="more-menu-wrap">
                <BMoreMenu v-if="leadStage.isPreset === false">
                  <BBtn
                    flat
                    fit
                    size="small"
                    @click="() => handleDeleteClick(leadStage)"
                  >
                    {{ $t('general.delete.do') }}
                  </BBtn>
                </BMoreMenu>
              </span>
            </BLayout>
          </template>
        </Draggable>
      </div>
      <div class="creating-form-container">
        <LeadStageCreatingForm @after-create="handleLeadStageAfterCreate" />
      </div>
    </div>
  </BHeightAdjuster>
</template>

<script lang="ts">
import lodash from 'lodash'
import { defineComponent } from 'vue'
import { mapWaitingActions } from 'vue-wait'
import Draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import { LeadStage, PutLeadStageBody } from '@/api/openapi'
import errorHandler from '@/mixins/error_handler'
import inputValidation from '@/mixins/input_validation'
import EditableLeadStageColorPreview from './EditableLeadStageColorPreview.vue'
import { MAX_NAME_LENGTH } from './lead_stage'
import LeadStageCreatingForm from './LeadStageCreatingForm.vue'

type TData = {
  internalLeadStages: LeadStage[]
}

export default defineComponent({
  components: {
    Draggable,
    LeadStageCreatingForm,
    EditableLeadStageColorPreview,
  },
  mixins: [inputValidation, errorHandler],
  data (): TData {
    return {
      internalLeadStages: [],
    }
  },
  computed: {
    ...mapGetters('user', [
      'leadStages',
    ]),
    maxNameLength() {
      return MAX_NAME_LENGTH
    },
    loading() {
      return this.$wait.is(['getLeadStagesWait', 'updateLeadStageWait', 'deleteLeadStageWait'])
    },
  },
  watch: {
    leadStages (newVal) {
      this.internalLeadStages = lodash.cloneDeep(newVal)
    },
  },
  async created() {
    await this.getLeadStagesAction()
  },
  methods: {
    ...mapWaitingActions('user', {
      getLeadStagesAction: 'getLeadStagesWait',
      updateLeadStageAction: 'updateLeadStageWait',
      deleteLeadStageAction: 'deleteLeadStageWait',
    }),
    handleMove(event) {
      if (event.relatedContext.element.isPreset === true) return false
    },
    async updateLeadStage(id: number, body: PutLeadStageBody): Promise<void> {
      await this.updateLeadStageAction({
        request: {
          leadStageId: id,
          putLeadStageBody: body,
        },
      })
      this.getLeadStagesAction()
    },
    async handleNameChange(name: string, leadStageId: number): Promise<void> {
      await this.updateLeadStage(leadStageId, {
        name,
      })
      this.getLeadStagesAction()
    },
    async handleColorChange(colorName: string, leadStageId: number): Promise<void> {
      await this.updateLeadStage(leadStageId, {
        colorName,
      })
      this.getLeadStagesAction()
    },
    async handleSortOrderChange(event): Promise<void> {
      if (!event.moved) return
      const prevLeadStage = this.leadStages[event.moved.newIndex]
      await this.updateLeadStage(event.moved.element.id, {
        sortOrder: prevLeadStage.sortOrder,
      })
      this.getLeadStagesAction()
    },
    async handleDeleteClick(leadStage: LeadStage): Promise<void> {
      const accepted = await this.$bitterAlert.show({
        title: this.$t('leadStage.title'),
        text: `${this.$t('general.confirm.actionWithTarget', { target: leadStage.name, action: this.$t('general.delete.text') })}\n\n${this.$t('leadStage.deleteMessage')}`,
      })
      if (!accepted) { return }
      await this.deleteLeadStageAction({
        request: {
          leadStageId: leadStage.id,
        },
      })
      this.getLeadStagesAction()
    },
    handleLeadStageAfterCreate(): void {
      this.getLeadStagesAction()
    },
  },
})
</script>

<style lang="scss" scoped>
.handle {
  cursor: grab;
  flex-shrink: 0;
}
.handle-wrap {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
}
.more-menu-wrap {
  flex-shrink: 0;
  width: 26px;
  height: 26px;
}

.lead-stage-item-container {
  margin: 20px 0;
  padding: 0 22px;
  .list-container {
    overflow-y: auto;
    width: 100%;
    height: calc(100vh - 240px);
    .item-row {
      overflow-wrap: break-word;
      word-break: break-all;
      margin: 0;
      @media only screen and (min-width: 959px) {
        width: 50%;
      }
      :deep(.b-input-box) {
        margin-left: 8px;
      }
      :deep(.editable-lead-stage-color-preview-wrapper) {
        margin: 0 14px;
        flex-shrink: 0;
      }
    }
  }
}

.creating-form-container {
  margin-top: 24px;
  padding: 0px 32px;
}
</style>

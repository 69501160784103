import ApiService from '@/api/api_service';
import {
  SfUserMappingsApi,
} from '@/api/openapi';

export class SfUserMappingService extends ApiService {
  api = new SfUserMappingsApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    // openapiで作ったやつはtrue
    super(true)
  }

  async getSfUserMappings(args) {
    return this.api.getSfUserMappings(args);
  }

  async upsertSfUserMappingBody(args) {
    return this.api.upsertSfUserMappingBody(args);
  }
}

import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "zoom-phone" }
const _hoisted_2 = ["hidden"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", {
      id: "zoom-phone-container",
      class: _normalizeClass(_ctx.classes)
    }, [
      _createVNode(_component_BLayout, {
        class: "zoom-phone-header",
        "align-center": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BTooltip, {
            top: "",
            content: _ctx.$t('general.close.text'),
            disabled: _ctx.isOutboundCalling
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BBtn, {
                disabled: _ctx.isOutboundCalling,
                size: "small",
                fab: "",
                text: "",
                onClick: _ctx.closeWidget
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BIcon, { size: "small" }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode(" close ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ]),
            _: 1
          }, 8, ["content", "disabled"]),
          _createVNode(_component_BBtn, {
            size: "small",
            fab: "",
            text: "",
            onClick: _ctx.minimizeWidget
          }, {
            default: _withCtx(() => [
              (!_ctx.isMinimize)
                ? (_openBlock(), _createBlock(_component_BIcon, {
                    key: 0,
                    size: "small"
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode(" minimize ")
                    ])),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_BIcon, {
                    key: 1,
                    size: "small"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" maximize ")
                    ])),
                    _: 1
                  }))
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createElementVNode("div", {
        class: "zoom-phone-content",
        hidden: _ctx.isMinimize
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("iframe", {
          id: "zoom-embeddable-phone-iframe",
          src: "https://applications.zoom.us/integration/phone/embeddablephone/home",
          allow: "clipboard-read; clipboard-write https://applications.zoom.us"
        }, null, -1)
      ]), 8, _hoisted_2)
    ], 2), [
      [_vShow, _ctx.isVisible]
    ])
  ]))
}
import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "instance-list-container" }
const _hoisted_2 = { class: "instance-list-control" }
const _hoisted_3 = { class: "instance-list ml-400" }
const _hoisted_4 = { class: "instance-items" }

import { ref, watch, onMounted } from 'vue';
import { TriggerTarget, PaginationMeta } from '@/api/openapi';
import LeadLink from '@/components/organisms/user/general/LeadLink.vue';
import InstanceListHeader from '@/components/organisms/user/trigger/InstanceListHeader.vue';
import InstanceListItem from '@/components/organisms/user/trigger/InstanceListItem.vue';
import { TCheck, useCheckAll } from '@/composable/check-all';

type TEmits = {
  'after:movePage': [afterPage: number];
  'update:selectedTriggerTargetIds': [values: number[]];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InstanceList',
  props: {
    "modelValue": {},
    "modelModifiers": {},
    "paginationMeta": {},
    "paginationMetaModifiers": {},
    "page": { default: 1 },
    "pageModifiers": {},
  },
  emits: /*@__PURE__*/_mergeModels(["after:movePage", "update:selectedTriggerTargetIds"], ["update:modelValue", "update:paginationMeta", "update:page"]),
  setup(__props, { emit: __emit }) {

const emit = __emit;
const triggerTargets = _useModel<TriggerTarget[]>(__props, "modelValue");
const paginationMeta = _useModel<PaginationMeta>(__props, 'paginationMeta');
const page = _useModel<number | null>(__props, 'page');

const {
  checkAll: triggerTargetsCheckAll,
  checkedValues: selectedTriggerTargetIds,
  initializeChecks,
  findCheck,
  handleCheckAllUpdate: handleTriggerTargetsCheckAllUpdate,
  toggleCheck: toggleTriggerTargetCheck,
} = useCheckAll();

const makeTriggerTargetIdChecks = (): TCheck[] =>
  triggerTargets.value.map((instance) => ({
    value: instance.id,
    checked: false,
  }));

watch(() => triggerTargets.value, (value) => {
  if (value) {
    initializeChecks(makeTriggerTargetIdChecks());
  }
}, { immediate: true });

watch(() => page.value, (value) => {
  emit('after:movePage', value);
});

watch(selectedTriggerTargetIds, (values) => {
  emit('update:selectedTriggerTargetIds', values);
});

return (_ctx: any,_cache: any) => {
  const _component_BPaginationMetaText = _resolveComponent("BPaginationMetaText")!
  const _component_BPagination = _resolveComponent("BPagination")!
  const _component_BContainer = _resolveComponent("BContainer")!
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_VWait = _resolveComponent("VWait")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BContainer, {
        class: "instance-list-control-inner",
        size: "large"
      }, {
        default: _withCtx(() => [
          (paginationMeta.value)
            ? (_openBlock(), _createBlock(_component_BPaginationMetaText, {
                key: 0,
                "current-page": page.value,
                "paging-meta": paginationMeta.value
              }, null, 8, ["current-page", "paging-meta"]))
            : _createCommentVNode("", true),
          (paginationMeta.value)
            ? (_openBlock(), _createBlock(_component_BPagination, {
                key: 1,
                modelValue: page.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((page).value = $event)),
                class: "ml-a",
                length: paginationMeta.value.totalPages,
                "total-visible": 5,
                circle: ""
              }, null, 8, ["modelValue", "length"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_VWait, { for: "fetchTriggerTargetsWait" }, {
        waiting: _withCtx(() => [
          _withDirectives(_createVNode(_component_BPlaceholder, { "element-loading-background": "transparent" }, null, 512), [
            [_directive_loading, true]
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(InstanceListHeader, {
            "model-value": _unref(triggerTargetsCheckAll),
            class: "instance-list-header",
            "onUpdate:modelValue": _unref(handleTriggerTargetsCheckAllUpdate)
          }, null, 8, ["model-value", "onUpdate:modelValue"]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(triggerTargets.value, (instance) => {
              return (_openBlock(), _createBlock(LeadLink, {
                key: instance.id,
                "call-target-id": instance.callTarget?.id,
                "in-new-tab": "",
                tag: "div"
              }, {
                default: _withCtx(() => [
                  _createVNode(InstanceListItem, {
                    "model-value": _unref(findCheck)(instance.id),
                    "trigger-target": instance,
                    "onUpdate:modelValue": _unref(toggleTriggerTargetCheck)
                  }, null, 8, ["model-value", "trigger-target", "onUpdate:modelValue"])
                ]),
                _: 2
              }, 1032, ["call-target-id"]))
            }), 128))
          ])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})
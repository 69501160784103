<template>
  <div
    class="b-modal-footer"
    :style="{ height: height}"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    full: Boolean,
    height: String,
  },
  computed: {
    classes () {
      return {
        'b-modal-footer-full': this.full,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .b-modal-footer {
    padding: $basespace-100 $basespace-600 $basespace-600;

    &-full {
      padding: 0;
    }
  }
</style>

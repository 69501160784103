<template>
  <ElInput
    v-bind="attributes"
    ref="textarea"
    v-model="internalValue"
    :class="classes"
    @blur="$emit('blur')"
  />
</template>

<script>
export default {
  props: {
    modelValue: String,
    label: String,
    clearable: Boolean,
    disabled: Boolean,
    rows: [Number, String],
    placeholder: String,
    autosize: [Boolean, Object],
    autofocus: Boolean,
    resize: String,
    flat: {
      type: Boolean,
      required: false,
    },
    underline: {
      type: Boolean,
      required: false,
    },
    maxlength: {
      type: Number,
      required: false,
    },
  },
  emits: [
    'blur',
    'update:modelValue',
  ],
  computed: {
    internalValue: {
      get () {
        return this.modelValue
      },
      set (newVal) {
        if (this.modelValue !== newVal) this.$emit('update:modelValue', newVal)
      },
    },
    attributes () {
      return {
        'type': 'textarea',
        'label': this.label,
        'disabled': this.disabled,
        'clearable': this.clearable,
        'rows': this.rows,
        'placeholder': this.placeholder,
        'background-color': 'white',
        'auto-grow': true,
        'autosize': this.autosize,
        'autofocus': this.autofocus,
        'resize': this.resize,
        'maxlength': typeof this.maxlength === 'number' ? this.maxlength : null,
      }
    },
    classes () {
      return {
        'is-flat': this.flat,
        'is-underline': this.underline,
      }
    },
  },
  methods: {
    setFocus () {
      this.$refs.textarea.focus()
    },
  },
}
</script>

<style lang="scss" scoped>
.el-textarea {
  :deep(.el-textarea__inner) {
    padding: $basespace-50 $basespace-100;
  }
}

.is-flat {
  :deep(.el-textarea__inner) {
    border: none;
    box-shadow: none;
  }
}
.is-underline {
  transition: all 0.3s ease;
  border-bottom: 1px solid $bdcolor-base;
  &:focus-within {
    border-bottom: 1px solid $bdcolor-active;
  }
  :deep(.el-textarea__inner) {
    border: none;
    box-shadow: none;
  }
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "item-filter-area" }
const _hoisted_2 = { class: "item-filter-area-candidate" }

import { camelCase, lowerFirst } from 'lodash';
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { DateTimeEnum, DateTimeFilter, LeadFilterDateTimeOr } from '@/api/openapi';
import { formatShorterDateTime } from '@/utils/date-time';
import { getIncExc } from '@/utils/object';
import FilterDetailPopover from '../FilterDetailPopover.vue';

type TFilterParam = { inc: DateTimeFilter } | { exc: DateTimeFilter };
type TType = DateTimeEnum | 'custom';
type TDateTimeRange = [string, string] | [];
type TCommonProps = {
  isCustom?: boolean;
  isDisplayOnly?: boolean;
  keyName: string;
  title: string;
};
type TProps = TCommonProps & {
  filterParam: TFilterParam;
};
type TEmit = {
  hide: [filterParam: TFilterParam];
};

export type {
  TFilterParam,
  TCommonProps,
  TEmit,
}

export default /*@__PURE__*/_defineComponent({
  __name: 'DateTimeFilterCommon',
  props: {
    isCustom: { type: Boolean, default: false },
    isDisplayOnly: { type: Boolean, default: false },
    keyName: {},
    title: {},
    filterParam: {}
  },
  emits: ["hide"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const i18n = useI18n();

const dateTimeOptions = Object.values<TType>(DateTimeEnum).concat(['custom'])
  .map((value) => ({
    label: i18n.t(`leadFilter.dateTime.type.${lowerFirst(camelCase(value))}`),
    value,
  }));

const inputType = ref<TType>(null);
const inputDateTimeRange = ref<TDateTimeRange>([]);
const isInclude = ref(true);

const makeInputValues = (newValue: LeadFilterDateTimeOr) => {
  let type: TType = null;
  let dateTimeRange: TDateTimeRange = [];
  if (!Array.isArray(newValue)) {
    type = newValue;
    dateTimeRange = [];
  } else if (newValue.length === 2) {
    type = 'custom';
    dateTimeRange = [newValue[0], newValue[1]];
  }
  return { type, dateTimeRange };
};
const isBlankInputValues = (type: TType, dateTimeRange: TDateTimeRange) => {
  return type == null
    || type === 'custom' && dateTimeRange.length === 0;
};

const incExc = computed<'inc' | 'exc'>(() => {
  return getIncExc(props.filterParam);
});
const targetDetail = computed<LeadFilterDateTimeOr | undefined>(() => {
  return props.filterParam?.[incExc.value].or;
});

const referenceValue = computed(() => {
  const { type, dateTimeRange } = makeInputValues(targetDetail.value);
  if (isBlankInputValues(type, dateTimeRange)) return '-';
  if (type !== 'custom') {
    return dateTimeOptions.find((item) => item.value === type)?.label;
  }

  const start = formatShorterDateTime(dateTimeRange[0])
  const end = formatShorterDateTime(dateTimeRange[1])
  return `${start} 〜 ${end}`;
});
const referenceGroupingText = computed(() => {
  const { type, dateTimeRange } = makeInputValues(targetDetail.value);
  if (isBlankInputValues(type, dateTimeRange)) return '';
  return i18n.t(`general.filter.${incExc.value}`);
});

const submitInputValues = () => {
  // NOTE: 「絞り込む」or「閉じる」を押さずにclose - 入力値を上位Componentへ渡す→絞り込み実施
  // NOTE: filterの入力がない時の値は `{ inc: { or: [] } }`
  const value = isBlankInputValues(inputType.value, inputDateTimeRange.value) ? []
    : inputType.value === 'custom' ? inputDateTimeRange.value
      : inputType.value;
  const incExc = isInclude.value ? 'inc' : 'exc';
  emit('hide', { [incExc]: { or: value } } as TFilterParam);
};

const resetInputValues = () => {
  // NOTE: 開く（含:「閉じる」→再度開く） - 入力値のリセット
  const { type, dateTimeRange } = makeInputValues(targetDetail.value);
  inputType.value = type;
  inputDateTimeRange.value = dateTimeRange;
  isInclude.value = incExc.value !== 'exc';
};


return (_ctx: any,_cache: any) => {
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BRadio = _resolveComponent("BRadio")!
  const _component_BDateRangeForm = _resolveComponent("BDateRangeForm")!

  return (_openBlock(), _createBlock(FilterDetailPopover, {
    "is-include": isInclude.value,
    "onUpdate:isInclude": _cache[2] || (_cache[2] = ($event: any) => ((isInclude).value = $event)),
    title: _ctx.title,
    width: 420,
    "is-switch": "",
    "is-display-only": _ctx.isDisplayOnly,
    "key-name": _ctx.keyName,
    "is-custom": _ctx.isCustom,
    onHide: submitInputValues,
    onShow: resetInputValues
  }, {
    reference: _withCtx(() => [
      _createVNode(_component_BLayout, { "align-center": "" }, {
        default: _withCtx(() => [
          _createVNode(_component_BListItem, null, {
            header: _withCtx(() => [
              _createVNode(_component_BLayout, { "justify-space-between": "" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, _toDisplayString(_ctx.title), 1),
                  _createElementVNode("div", {
                    class: _normalizeClass(["grouping-text", { 'is-include': (incExc.value === 'inc') }])
                  }, _toDisplayString(referenceGroupingText.value), 3)
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString(referenceValue.value), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dateTimeOptions), (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.value,
                class: "my-100"
              }, [
                _createVNode(_component_BRadio, {
                  modelValue: inputType.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputType).value = $event)),
                  label: item.value
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.label), 1)
                  ]),
                  _: 2
                }, 1032, ["modelValue", "label"])
              ]))
            }), 128)),
            (inputType.value === 'custom')
              ? (_openBlock(), _createBlock(_component_BDateRangeForm, {
                  key: 0,
                  modelValue: inputDateTimeRange.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((inputDateTimeRange).value = $event)),
                  teleported: false,
                  type: "datetimerange"
                }, null, 8, ["modelValue"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["is-include", "title", "is-display-only", "key-name", "is-custom"]))
}
}

})
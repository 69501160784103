import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import { computed, ref, watch, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import MailFormTransition from '@/components/organisms/user/mail/common/drawer/MailFormTransition.vue';
import { getCommonDefault } from '@/components/organisms/user/mail/direct/drawer/state/form-values';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';
import { TSubmitType } from '@/composable/user/sequence/step-actions';
import { useStepModal } from '@/composable/user/sequence/step-form-modal';
import { useUsers, useCurrentUser } from '@/composable/user/user/users';
import { bitterAlert } from '@/plugins/BBitterAlert';
import { TPartialFormDirectMailCommon, SenderData } from '../../mail/direct/drawer/state/types';
import ConfirmDrawer from './directMailForm/ConfirmDrawer.vue';
import FormDrawer from './directMailForm/FormDrawer.vue';
import { TFormDirectMailSequenceStep } from './types';

export type TState = 'form' | 'confirm';
type TProps = {
  sequenceStep: TPartialFormDirectMailCommon | null;
  isOpen?: boolean;
  mode: TState;
  senderId?: number;
  loading?: boolean;
  canConfirm?: (sequenceStep: TFormDirectMailSequenceStep) => Promise<boolean>;
  submitType?: TSubmitType;
  withSender?: boolean;
};
type TEmit = {
  'click:saveAsDraft': [sequenceStep: TFormDirectMailSequenceStep];
  'click:saveAsReserve': [sequenceStep: TFormDirectMailSequenceStep];
  'click:reserve': [sequenceStep: TFormDirectMailSequenceStep];
  'click:sendImmediately': [sequenceStep: TFormDirectMailSequenceStep];
  'click:draft': [sequenceStep: TFormDirectMailSequenceStep];
  'click:cancel': [];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceDirectMailForm',
  props: {
    sequenceStep: {},
    isOpen: { type: Boolean, default: false },
    mode: {},
    senderId: { default: undefined },
    loading: { type: Boolean, default: false },
    canConfirm: { type: Function, default: undefined },
    submitType: { default: 'save' },
    withSender: { type: Boolean, default: true }
  },
  emits: ["click:saveAsDraft", "click:saveAsReserve", "click:reserve", "click:sendImmediately", "click:draft", "click:cancel"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const i18n = useI18n();
const { isAvailableMailTemplateFeature } = useAvailableFeatureCheck();

const { activeUsers, fetchUsers } = useUsers();
const { currentUser, fetchCurrentUser } = useCurrentUser();
const sequenceOwner = computed(() => activeUsers.value.find(u => u.id === props.senderId));
const draftable = computed(() => props.submitType === 'send');

const currentState = ref<TState>(props.mode);
const cancelOpenai = ref<() => void>();
const senderData = ref<SenderData>(null);

onBeforeMount(async () => {
  await fetchCurrentUser();
  await fetchUsers();
  setSenderData();
});

const {
  internalIsOpen,
  internalSequenceStep,
} = useStepModal<TFormDirectMailSequenceStep>(
  () => props.isOpen,
  () => ({
    sortOrder: 1,
    actionType: null,
    priority: 'middle',
    dateInterval: 1,
    reservedTime: null,
    ...getCommonDefault(),
    mailAggregates: (props.sequenceStep as TFormDirectMailSequenceStep)?.mailAggregates || null,
    ...props.sequenceStep,
  }),
);

watch(() => props.mode, (value) => {
  currentState.value = value;
});

watch(() => props.isOpen, (value) => {
  if (value) return;
  closeModal();
});

const toConfirm = () => {
  currentState.value = 'confirm';
};
const toForm = () => {
  currentState.value = 'form';
};
const reset = () => {
  currentState.value = props.mode;
};

const closeModal = () => {
  reset();
  emit('click:cancel');
};

const handleUpdateModelValue = (value: TFormDirectMailSequenceStep) => {
  internalSequenceStep.value = value;
};
const handleChangeToConfirm = async () => {
  if (props.canConfirm != null && !await props.canConfirm(internalSequenceStep.value)) return;

  toConfirm();
};

const handleCancel = async () => {
  const check = await bitterAlert.show({
    title: i18n.t('general.confirm.text'),
    text: i18n.t('general.alert.of', { target: i18n.t('general.unsavedValue'), action: i18n.t('general.clear') }),
  })
  if (!check) { return }
  if (cancelOpenai.value != null) { cancelOpenai.value() }
  closeModal();
};
const handleClose = () => {
  if (props.mode === 'confirm') {
    closeModal();
  } else {
    handleCancel();
  }
};

const edit = () => {
  toForm();
};
const saveAsDraft = () => {
  emit('click:saveAsDraft', internalSequenceStep.value);
};
const saveAsReserve = () => {
  emit('click:saveAsReserve', internalSequenceStep.value);
};
const reserve = () => {
  emit('click:reserve', internalSequenceStep.value);
};
const sendImmediately = () => {
  emit('click:sendImmediately', internalSequenceStep.value);
};
const draft = () => {
  if (!draftable.value) return;
  emit('click:draft', internalSequenceStep.value);
};

const handleCloseOpenAi = (cancel: () => void) => {
  cancelOpenai.value = cancel;
};

const setSenderData = () => {
  if (!props.withSender) return;
  const user = sequenceOwner.value || currentUser.value;
  if (user == null) return { senderName: '', senderEmail: '' };

  senderData.value = {
    senderName: `${user.lastName} ${user.firstName}`,
    senderEmail: user.senderEmail,
  };
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MailFormTransition, {
    "is-visible": _unref(internalIsOpen),
    style: {"height":"100%"}
  }, {
    default: _withCtx(() => [
      (currentState.value === 'form')
        ? (_openBlock(), _createBlock(FormDrawer, {
            key: 0,
            "model-value": _unref(internalSequenceStep),
            users: _unref(activeUsers),
            "is-loading": _ctx.loading,
            "is-available-mail-template-feature": _unref(isAvailableMailTemplateFeature),
            "sender-data": senderData.value,
            draftable: draftable.value,
            "onUpdate:modelValue": handleUpdateModelValue,
            onChangeCurrentStateToConfirm: handleChangeToConfirm,
            onCancel: handleCancel,
            onDraft: draft,
            onCloseOpenai: handleCloseOpenAi
          }, null, 8, ["model-value", "users", "is-loading", "is-available-mail-template-feature", "sender-data", "draftable"]))
        : (currentState.value === 'confirm')
          ? (_openBlock(), _createBlock(ConfirmDrawer, {
              key: 1,
              editable: _ctx.mode === 'form',
              step: _unref(internalSequenceStep),
              "is-loading": _ctx.loading,
              "sender-data": senderData.value,
              "submit-type": _ctx.submitType,
              onEdit: edit,
              onSaveAsDraft: saveAsDraft,
              onSaveAsReserve: saveAsReserve,
              onReserve: reserve,
              onSendImmediately: sendImmediately,
              onClose: handleClose
            }, null, 8, ["editable", "step", "is-loading", "sender-data", "submit-type"]))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["is-visible"]))
}
}

})
<template>
  <div
    class="b-sort-text"
    :class="{
      'is-default': !isDisplayOnly,
      'is-display-only': isDisplayOnly,
      'is-active': isActive,
      'no-hover-arrow': noNeedArrowOnHover
    }"
    @click="changeSort"
  >
    <span>{{ itemText }}</span>
    <BTooltip
      v-if="info"
      top
      :content="info"
    >
      <BIcon
        size="small"
        class="info"
      >
        help
      </BIcon>
    </BTooltip>
    <BIcon
      class="b-sort-icon ml-50"
      size="small"
      :class="classes"
    >
      arrow_downward
    </BIcon>
  </div>
</template>

<script>
export default {
  name: 'BSortText',
  props: {
    itemText: String,
    isActive: Boolean,
    isDisplayOnly: {
      type: Boolean,
      default: false,
    },
    orderBy: {
      type: String,
      default: 'asc',
    },
    noNeedArrowOnHover: {
      type: Boolean,
      default: false,
    },
    info: {
      type: String,
      default: '',
    },
  },
  emits: [
    'order-by',
  ],
  computed: {
    internalOrderBy: {
      get() {
        return this.orderBy
      },
      set(newVal) {
        this.$emit('order-by', newVal)
      },
    },
    classes() {
      return {
        'is-desc': this.internalOrderBy === 'desc',
      }
    },
  },
  methods: {
    changeSort() {
      if (!this.isActive) return this.$emit('order-by', this.orderBy)
      if (this.internalOrderBy === 'asc') {
        this.internalOrderBy = 'desc'
      } else {
        this.internalOrderBy = 'asc'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .b-sort-text {
    &.is-default {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: $transition-base;
    }

    .b-sort-icon {
      display: none;
      transform: rotate(-180deg);
      color: $textcolor-light;

      &.is-desc {
        transform: rotate(0);
      }
    }
    .b-icon.info {
      color: $textcolor-light;
      margin-left: $basespace-50;
      display: inline-block;
      width: $fontsize-300;
    }

    &.is-active {
      color: $textcolor-base;

      .b-sort-icon {
        display: block;
        color: $textcolor-base;
      }
    }

    &.is-display-only {
      display: flex;
      align-items: center;
      transition: $transition-base;
    }

    &:not(.is-active):not(.no-hover-arrow):not(.is-display-only):hover {
      color: $textcolor-base;

      .b-sort-icon {
        display: block;
      }
    }
  }
</style>

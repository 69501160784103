<template>
  <ElCol v-bind="attributes">
    <slot />
  </ElCol>
</template>

<script>
export default {
  props: {
    // 1 ~ 24で指定
    span: Number,
    alignSelfCenter: Boolean,
  },
  computed: {
    attributes () {
      return {
        span: this.span,
        style: this.styles,
      }
    },
    styles () {
      return {
        'align-self': this.alignSelfCenter ? 'center' : undefined,
      }
    },
  },
}
</script>

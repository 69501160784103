import ApiBase from '@/api/base'

export default {
  getSalesforceSetting() {
    return ApiBase.get(`/user/salesforce/salesforce_setting`)
  },
  createSalesforceSetting(args) {
    return ApiBase.post(`/user/salesforce/salesforce_settings`, args)
  },
  deleteSalesforceSetting() {
    return ApiBase.delete(`/user/salesforce/salesforce_settings`)
  },
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "sub-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_DuplicatedLeadList = _resolveComponent("DuplicatedLeadList", true)!
  const _component_VWait = _resolveComponent("VWait")!
  const _component_BModalBody = _resolveComponent("BModalBody")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BModalFooter = _resolveComponent("BModalFooter")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('duplicatedList.leadList.subTitle', { count: _ctx.duplicatedCount })), 1)
    ]),
    _createVNode(_component_BModalBody, { style: { padding: 0 } }, {
      default: _withCtx(() => [
        _createVNode(_component_VWait, { for: "getDuplicatedCallTargetsWait" }, {
          waiting: _withCtx(() => [
            _withDirectives(_createVNode(_component_BPlaceholder, { "element-loading-background": "transparent" }, null, 512), [
              [_directive_loading, true]
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_DuplicatedLeadList, {
              modelValue: _ctx.selectedDuplicatedCallTargetIds,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDuplicatedCallTargetIds) = $event)),
              "default-selected-call-target-ids": _ctx.internalDefaultSelectedCallTargetIds,
              "onUpdate:defaultSelectedCallTargetIds": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.internalDefaultSelectedCallTargetIds) = $event)),
              "call-targets": _ctx.internalDuplicatedCallTargets,
              "is-first-fixed": true,
              "max-selectable-length": 3
            }, null, 8, ["modelValue", "default-selected-call-target-ids", "call-targets"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_BModalFooter, null, {
      default: _withCtx(() => [
        _createVNode(_component_BLayout, {
          class: "mt-200",
          "justify-center": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BBtn, {
              type: "primary",
              disabled: !_ctx.isValidDuplicated,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('click-next')))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.toNext')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
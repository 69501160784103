import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "attachments" }

import { computed, ref, watch } from 'vue';
import { MailAttachment as TMailAttachment } from '@/api/openapi';
import { MailAttachmentApiService } from '@/api/user/resources/mail_attachment';
import MailAttachment from '@/components/organisms/user/general/MailAttachment.vue'


type TProps = {
  mailAttachmentIds: number[];
  showCrossButton?: boolean;
};

type TEmit = (event: 'editMailAttachmentIds', mailAttachmentIds: number[]) => void;


export default /*@__PURE__*/_defineComponent({
  __name: 'MailAttachments',
  props: {
    mailAttachmentIds: {},
    showCrossButton: { type: Boolean, default: true }
  },
  emits: ["editMailAttachmentIds"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;
const emit = __emit;

const mailAttachments = ref<TMailAttachment[]>([]);

const internalMailAttachmentIds = computed({
  get() {
    return props.mailAttachmentIds
  },
  set(newValue) {
    emit('editMailAttachmentIds', newValue)
  },
});

const fetchMailAttachments = async () => {
  if (!internalMailAttachmentIds.value.length) {
    mailAttachments.value = [];
    return;
  }
  const apiService =  new MailAttachmentApiService();
  const { data } = await apiService.getMailAttachments({
    request: {
      ids: internalMailAttachmentIds.value,
    },
  });
  mailAttachments.value = data;
}

watch(() => props.mailAttachmentIds, (newValue) => {
  fetchMailAttachments();
}, { immediate: true });

const handleAttachmentCrossButtonClick = (mailAttachmentId: number) => {
  internalMailAttachmentIds.value = internalMailAttachmentIds.value.filter(id => id !== mailAttachmentId)
}

const totalByteSize = computed(() => {
  return mailAttachments.value.reduce((sum, mailAttachment) => {
    return sum + mailAttachment.fileByteSize
  }, 0)
})

__expose({
  totalByteSize,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mailAttachments.value, (mailAttachment) => {
      return (_openBlock(), _createBlock(MailAttachment, {
        id: mailAttachment.id,
        key: mailAttachment.id,
        "file-name": mailAttachment.fileName,
        "enable-link": false,
        "show-cross-button": _ctx.showCrossButton,
        onClickCrossIcon: handleAttachmentCrossButtonClick
      }, null, 8, ["id", "file-name", "show-cross-button"]))
    }), 128))
  ]))
}
}

})
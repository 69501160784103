import Waiting from '@/store/waiting'

export default class CallAnalyticsActions {
  constructor (Api, types) {
    const actions = {
      async getCallAnalyticsAction ({ commit }, args) {
        await waiting.check('getCallAnalyticsAction', async () => {
          try {
            const res = await Api.getCallAnalytics(args)
            commit(types.SET_CALL_TARGETS_META, res.data.meta)
            commit(types.SET_CALL_ANALYTICS, res.data.callAnalytics)
            commit(types.SET_ALL_USER_CALL_ANALYTICS, res.data.allUserCallAnalytics)
            commit(types.SET_CALL_RESULT_CALL_ANALYTICS, res.data.callResultCallAnalytics)
          } catch (err) {
            commit(types.CLEAR_CALL_ANALYTICS)
            throw err
          }
        })
      },
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "item active" }
const _hoisted_3 = { class: "item invited" }
const _hoisted_4 = { class: "item invalid" }
const _hoisted_5 = { class: "member-list-container" }
const _hoisted_6 = { class: "member-list" }
const _hoisted_7 = { class: "theader" }
const _hoisted_8 = { class: "table-row theader-row" }
const _hoisted_9 = { class: "tbody" }
const _hoisted_10 = { class: "member-list-body" }
const _hoisted_11 = {
  key: 0,
  class: "member-footer"
}
const _hoisted_12 = { class: "footer-title" }

import { kebabCase } from 'lodash'
import { computed, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CreateUserBody, UpdateUserBody, User } from '@/api/openapi'
import { UserApiService } from '@/api/user/resources/user'
import MemberListItem from '@/components/organisms/user/setting/member/MemberListItem.vue'
import { useErrorHandler } from '@/composable/error-handler'
import { useInputValidations } from '@/composable/input-validations'
import { useUsers } from '@/composable/user/user/users'
import { useWait } from '@/composable/vue-wait'
import BLayout from '@/plugins/biscuet-materials/components/atoms/BLayout.vue';
import AuthService from '@/services/auth_service';


export default /*@__PURE__*/_defineComponent({
  __name: 'Member',
  setup(__props) {

_useCssVars(_ctx => ({
  "7b80e6a2": (totalWidth)
}))

const { wait, doActionWithWait } = useWait();
const i18n = useI18n();
const { defaultHandler } = useErrorHandler();
const { requiredRule, emailRule } = useInputValidations();
const { activeUsers, invitingUsers, archivedUsers, fetchUsers } = useUsers(() => false);
const api = new UserApiService();

const columnWidth = [60, 250, 100, 100, 200, 280, 100, 100, 60];
const columnNames = [
  'avatar',
  'name',
  'lastName',
  'firstName',
  'type',
  'email',
  'mfaSetting',
  'userId',
];
const headerList = columnNames.map((column) => ({
  key: kebabCase(column),
  name: i18n.t(`corpSettingApi.tab.member.${column}`),
}));
const totalWidth = `${columnWidth.reduce((accumulator, currentValue) => accumulator + currentValue)}px`;

const emailValid = ref(false);
const nameValid = ref(false);
const lastNameValid = ref(false);
const firstNameValid = ref(false);
const form = ref({
  email: '',
  name: '',
  lastName: '',
  firstName: '',
});

const sortUsers = (users: readonly User[]) => [].concat(users).sort((a, b) => a.role > b.role ? -1 : 1);
const filteredActiveUsers = computed(() => sortUsers(activeUsers.value));
const filteredInvitingUsers = computed(() => sortUsers(invitingUsers.value));
const filteredArchivedUsers = computed(() => sortUsers(archivedUsers.value));

const loading = computed(() => wait.is(['getUsersWait', 'updateUserWait', 'createUserWait', 'deleteUserWait']));
const isValid = computed(() => emailValid.value && nameValid.value && lastNameValid.value && firstNameValid.value);

const getUsers = async () => {
  await doActionWithWait('getUsersWait', fetchUsers);
};
onBeforeMount(async () => {
  await getUsers();
});

const updateUser = async (user: UpdateUserBody) => {
  await doActionWithWait('updateUserWait', async () => {
    await api.updateUser({
      request: {
        userId: user.id,
        updateUserBody: user,
      },
      errorHandlers: {
        422: defaultHandler,
      },
    });
  });
  await getUsers();
};
const handleUpdateUserActivated = (user: User, activated: boolean) => {
  updateUser({ id: user.id, activated });
};

const createUser = async () => {
  if (!isValid.value) return;
  await doActionWithWait('createUserWait', async () => {
    const user: CreateUserBody = {
      email: form.value.email.trim(),
      name: form.value.name,
      lastName: form.value.lastName,
      firstName: form.value.firstName,
    };
    await api.createUser({
      request: {
        createUserBody: user,
      },
      errorHandlers: {
        422: defaultHandler,
      },
    });
    // TODO: AdminSDK(サーバーサイド)で実装
    new AuthService().resetPassword(user.email);
  });
  await getUsers();

  form.value.email = '';
  form.value.name = '';
  form.value.lastName = '';
  form.value.firstName = '';
};

const deleteUser = async (userId: number) => {
  await doActionWithWait('deleteUserWait', async () => {
    await api.deleteUser({ request: { userId } });
  });
  await getUsers();
};

const getHeaderStyles = (width: number, key: string) => {
  let style = `width: ${width}px; min-width: ${width}px; max-width: ${width}px;`
  if (key === 'avatar') style += 'position:sticky;left:0;z-index:3;'
  return style
};

return (_ctx: any,_cache: any) => {
  const _component_BSortText = _resolveComponent("BSortText")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BMoreMenu = _resolveComponent("BMoreMenu")!
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BContentBox = _resolveComponent("BContentBox")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_BContentBox, {
    class: "content-wide",
    "is-footer": _ctx.$can('owner')
  }, {
    content: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(BLayout, { class: "label-header" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('corpSettingApi.tab.member.active')) + " : " + _toDisplayString(filteredActiveUsers.value.length), 1),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('corpSettingApi.tab.member.inviting')) + " : " + _toDisplayString(filteredInvitingUsers.value.length), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('corpSettingApi.tab.member.archive')) + " : " + _toDisplayString(filteredArchivedUsers.value.length), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(headerList), (cell, index) => {
                  return (_openBlock(), _createBlock(_component_BSortText, {
                    key: cell.key,
                    class: _normalizeClass(["header-cell", [cell.key]]),
                    style: _normalizeStyle(getHeaderStyles(columnWidth[index], cell.key)),
                    "item-text": cell.name,
                    "is-display-only": ""
                  }, null, 8, ["class", "style", "item-text"]))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredActiveUsers.value, (user) => {
                  return (_openBlock(), _createBlock(MemberListItem, {
                    key: `user-${user.id}`,
                    user: user,
                    "column-names": columnNames,
                    "column-width": columnWidth,
                    onUpdate: updateUser
                  }, {
                    default: _withCtx(() => [
                      (_ctx.$can('owner'))
                        ? (_openBlock(), _createBlock(_component_BMoreMenu, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_BBtn, {
                                flat: "",
                                fit: "",
                                size: "small",
                                onClick: () => handleUpdateUserActivated(user, false)
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('corpSetting.tab.member.menu.disable')), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["user"]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredInvitingUsers.value, (user) => {
                  return (_openBlock(), _createBlock(MemberListItem, {
                    key: `user-${user.id}`,
                    user: user,
                    "column-names": columnNames,
                    "column-width": columnWidth,
                    onUpdate: updateUser
                  }, {
                    default: _withCtx(() => [
                      (_ctx.$can('owner'))
                        ? (_openBlock(), _createBlock(_component_BMoreMenu, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_BBtn, {
                                flat: "",
                                fit: "",
                                size: "small",
                                onClick: () => deleteUser(user.id)
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('corpSetting.tab.member.menu.cancel')), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["user"]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredArchivedUsers.value, (user) => {
                  return (_openBlock(), _createBlock(MemberListItem, {
                    key: `user-${user.id}`,
                    user: user,
                    "column-names": columnNames,
                    "column-width": columnWidth,
                    onUpdate: updateUser
                  }, {
                    default: _withCtx(() => [
                      (_ctx.$can('owner'))
                        ? (_openBlock(), _createBlock(_component_BMoreMenu, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_BBtn, {
                                flat: "",
                                fit: "",
                                size: "small",
                                onClick: () => handleUpdateUserActivated(user, true)
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('corpSetting.tab.member.menu.activate')), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["user"]))
                }), 128))
              ])
            ])
          ])
        ])
      ])), [
        [_directive_loading, loading.value]
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(BLayout, {
        "align-center": "",
        "justify-center": "",
        class: "footer-content"
      }, {
        default: _withCtx(() => [
          (_ctx.$can('owner'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('corpSetting.tab.member.inputLabel')), 1),
                _createVNode(BLayout, { "align-center": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BInput, {
                      modelValue: form.value.email,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.email) = $event)),
                      valid: emailValid.value,
                      "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => ((emailValid).value = $event)),
                      class: "input input-email",
                      placeholder: _ctx.$t('corpSettingApi.tab.member.email'),
                      rules: [_unref(requiredRule), _unref(emailRule)]
                    }, null, 8, ["modelValue", "valid", "placeholder", "rules"]),
                    _createVNode(_component_BInput, {
                      modelValue: form.value.name,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.name) = $event)),
                      valid: nameValid.value,
                      "onUpdate:valid": _cache[3] || (_cache[3] = ($event: any) => ((nameValid).value = $event)),
                      class: "input input-name",
                      placeholder: _ctx.$t('corpSettingApi.tab.member.name'),
                      rules: [_unref(requiredRule)],
                      onKeypressEnter: createUser
                    }, null, 8, ["modelValue", "valid", "placeholder", "rules"]),
                    _createVNode(_component_BInput, {
                      modelValue: form.value.lastName,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.lastName) = $event)),
                      valid: lastNameValid.value,
                      "onUpdate:valid": _cache[5] || (_cache[5] = ($event: any) => ((lastNameValid).value = $event)),
                      class: "input input-last-name",
                      placeholder: _ctx.$t('corpSettingApi.tab.member.lastName'),
                      rules: [_unref(requiredRule)],
                      onKeypressEnter: createUser
                    }, null, 8, ["modelValue", "valid", "placeholder", "rules"]),
                    _createVNode(_component_BInput, {
                      modelValue: form.value.firstName,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((form.value.firstName) = $event)),
                      valid: firstNameValid.value,
                      "onUpdate:valid": _cache[7] || (_cache[7] = ($event: any) => ((firstNameValid).value = $event)),
                      class: "input input-first-name",
                      placeholder: _ctx.$t('corpSettingApi.tab.member.firstName'),
                      rules: [_unref(requiredRule)],
                      onKeypressEnter: createUser
                    }, null, 8, ["modelValue", "valid", "placeholder", "rules"]),
                    _createVNode(_component_BBtn, {
                      class: "ml-100",
                      type: "primary",
                      disabled: !isValid.value || loading.value,
                      onClick: createUser
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('corpSetting.tab.member.invite')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-footer"]))
}
}

})
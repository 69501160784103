<template>
  <div
    class="b-icon-image"
    :class="[iconClass, size]"
  >
    <img :src="iconImagePath">
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { TSize } from '../../types';

type TIcon = 'negativeReaction' | 'positiveReaction';
type TProps = {
  icon: TIcon;
  iconClass?: string;
  size?: TSize;
};

const props = withDefaults(defineProps<TProps>(), {
  iconClass: '',
  size: 'medium',
});

const ICON_IMAGES: Record<TIcon, string> = {
  negativeReaction: 'negative_reaction.svg',
  positiveReaction: 'positive_reaction.svg',
};

const iconImagePath = computed(() => require(`@/assets/${ICON_IMAGES[props.icon]}`));
</script>

<style lang="scss" scoped>
  .b-icon-image {
    &.small, &.small img {
      width: 16px;
      height: 16px;
    }
    &.medium, &.medium img {
      width: 18px;
      height: 18px;
    }
    &.large, &.large img {
      width: 20px;
      height: 20px;
    }
  }
</style>

<template>
  <BContentBox
    class="content-wide"
    :is-footer="$can('owner')"
  >
    <template #content>
      <div
        v-loading="loading"
        class="container"
      >
        <BLayout class="label-header">
          <div class="item active">
            {{ $t('corpSettingApi.tab.member.active') }} : {{ filteredActiveUsers.length }}
          </div>
          <div class="item invited">
            {{ $t('corpSettingApi.tab.member.inviting') }} : {{ filteredInvitingUsers.length }}
          </div>
          <div class="item invalid">
            {{ $t('corpSettingApi.tab.member.archive') }} : {{ filteredArchivedUsers.length }}
          </div>
        </BLayout>
        <div class="member-list-container">
          <div class="member-list">
            <div class="theader">
              <div class="table-row theader-row">
                <BSortText
                  v-for="(cell, index) in headerList"
                  :key="cell.key"
                  class="header-cell"
                  :class="[cell.key]"
                  :style="getHeaderStyles(columnWidth[index], cell.key)"
                  :item-text="cell.name"
                  is-display-only
                />
              </div>
            </div>
            <div class="tbody">
              <div class="member-list-body">
                <MemberListItem
                  v-for="user in filteredActiveUsers"
                  :key="`user-${user.id}`"
                  :user="user"
                  :column-names="columnNames"
                  :column-width="columnWidth"
                  @update="updateUser"
                >
                  <BMoreMenu v-if="$can('owner')">
                    <BBtn
                      flat
                      fit
                      size="small"
                      @click="() => handleUpdateUserActivated(user, false)"
                    >
                      {{ $t('corpSetting.tab.member.menu.disable') }}
                    </BBtn>
                  </BMoreMenu>
                </MemberListItem>
                <MemberListItem
                  v-for="user in filteredInvitingUsers"
                  :key="`user-${user.id}`"
                  :user="user"
                  :column-names="columnNames"
                  :column-width="columnWidth"
                  @update="updateUser"
                >
                  <BMoreMenu v-if="$can('owner')">
                    <BBtn
                      flat
                      fit
                      size="small"
                      @click="() => deleteUser(user.id)"
                    >
                      {{ $t('corpSetting.tab.member.menu.cancel') }}
                    </BBtn>
                  </BMoreMenu>
                </MemberListItem>
                <MemberListItem
                  v-for="user in filteredArchivedUsers"
                  :key="`user-${user.id}`"
                  :user="user"
                  :column-names="columnNames"
                  :column-width="columnWidth"
                  @update="updateUser"
                >
                  <BMoreMenu v-if="$can('owner')">
                    <BBtn
                      flat
                      fit
                      size="small"
                      @click="() => handleUpdateUserActivated(user, true)"
                    >
                      {{ $t('corpSetting.tab.member.menu.activate') }}
                    </BBtn>
                  </BMoreMenu>
                </MemberListItem>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <BLayout
        align-center
        justify-center
        class="footer-content"
      >
        <div
          v-if="$can('owner')"
          class="member-footer"
        >
          <div class="footer-title">
            {{ $t('corpSetting.tab.member.inputLabel') }}
          </div>
          <BLayout align-center>
            <BInput
              v-model="form.email"
              v-model:valid="emailValid"
              class="input input-email"
              :placeholder="$t('corpSettingApi.tab.member.email')"
              :rules="[requiredRule, emailRule]"
            />
            <BInput
              v-model="form.name"
              v-model:valid="nameValid"
              class="input input-name"
              :placeholder="$t('corpSettingApi.tab.member.name')"
              :rules="[requiredRule]"
              @keypress-enter="createUser"
            />
            <BInput
              v-model="form.lastName"
              v-model:valid="lastNameValid"
              class="input input-last-name"
              :placeholder="$t('corpSettingApi.tab.member.lastName')"
              :rules="[requiredRule]"
              @keypress-enter="createUser"
            />
            <BInput
              v-model="form.firstName"
              v-model:valid="firstNameValid"
              class="input input-first-name"
              :placeholder="$t('corpSettingApi.tab.member.firstName')"
              :rules="[requiredRule]"
              @keypress-enter="createUser"
            />
            <BBtn
              class="ml-100"
              type="primary"
              :disabled="!isValid || loading"
              @click="createUser"
            >
              <span>{{ $t('corpSetting.tab.member.invite') }}</span>
            </BBtn>
          </BLayout>
        </div>
      </BLayout>
    </template>
  </BContentBox>
</template>

<script lang="ts" setup>
import { kebabCase } from 'lodash'
import { computed, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CreateUserBody, UpdateUserBody, User } from '@/api/openapi'
import { UserApiService } from '@/api/user/resources/user'
import MemberListItem from '@/components/organisms/user/setting/member/MemberListItem.vue'
import { useErrorHandler } from '@/composable/error-handler'
import { useInputValidations } from '@/composable/input-validations'
import { useUsers } from '@/composable/user/user/users'
import { useWait } from '@/composable/vue-wait'
import BLayout from '@/plugins/biscuet-materials/components/atoms/BLayout.vue';
import AuthService from '@/services/auth_service';

const { wait, doActionWithWait } = useWait();
const i18n = useI18n();
const { defaultHandler } = useErrorHandler();
const { requiredRule, emailRule } = useInputValidations();
const { activeUsers, invitingUsers, archivedUsers, fetchUsers } = useUsers(() => false);
const api = new UserApiService();

const columnWidth = [60, 250, 100, 100, 200, 280, 100, 100, 60];
const columnNames = [
  'avatar',
  'name',
  'lastName',
  'firstName',
  'type',
  'email',
  'mfaSetting',
  'userId',
];
const headerList = columnNames.map((column) => ({
  key: kebabCase(column),
  name: i18n.t(`corpSettingApi.tab.member.${column}`),
}));
const totalWidth = `${columnWidth.reduce((accumulator, currentValue) => accumulator + currentValue)}px`;

const emailValid = ref(false);
const nameValid = ref(false);
const lastNameValid = ref(false);
const firstNameValid = ref(false);
const form = ref({
  email: '',
  name: '',
  lastName: '',
  firstName: '',
});

const sortUsers = (users: readonly User[]) => [].concat(users).sort((a, b) => a.role > b.role ? -1 : 1);
const filteredActiveUsers = computed(() => sortUsers(activeUsers.value));
const filteredInvitingUsers = computed(() => sortUsers(invitingUsers.value));
const filteredArchivedUsers = computed(() => sortUsers(archivedUsers.value));

const loading = computed(() => wait.is(['getUsersWait', 'updateUserWait', 'createUserWait', 'deleteUserWait']));
const isValid = computed(() => emailValid.value && nameValid.value && lastNameValid.value && firstNameValid.value);

const getUsers = async () => {
  await doActionWithWait('getUsersWait', fetchUsers);
};
onBeforeMount(async () => {
  await getUsers();
});

const updateUser = async (user: UpdateUserBody) => {
  await doActionWithWait('updateUserWait', async () => {
    await api.updateUser({
      request: {
        userId: user.id,
        updateUserBody: user,
      },
      errorHandlers: {
        422: defaultHandler,
      },
    });
  });
  await getUsers();
};
const handleUpdateUserActivated = (user: User, activated: boolean) => {
  updateUser({ id: user.id, activated });
};

const createUser = async () => {
  if (!isValid.value) return;
  await doActionWithWait('createUserWait', async () => {
    const user: CreateUserBody = {
      email: form.value.email.trim(),
      name: form.value.name,
      lastName: form.value.lastName,
      firstName: form.value.firstName,
    };
    await api.createUser({
      request: {
        createUserBody: user,
      },
      errorHandlers: {
        422: defaultHandler,
      },
    });
    // TODO: AdminSDK(サーバーサイド)で実装
    new AuthService().resetPassword(user.email);
  });
  await getUsers();

  form.value.email = '';
  form.value.name = '';
  form.value.lastName = '';
  form.value.firstName = '';
};

const deleteUser = async (userId: number) => {
  await doActionWithWait('deleteUserWait', async () => {
    await api.deleteUser({ request: { userId } });
  });
  await getUsers();
};

const getHeaderStyles = (width: number, key: string) => {
  let style = `width: ${width}px; min-width: ${width}px; max-width: ${width}px;`
  if (key === 'avatar') style += 'position:sticky;left:0;z-index:3;'
  return style
};
</script>

<style lang="scss" scoped>
.content-box.content-wide {
  :deep(.content) {
    padding: 22px 0 0;
    overflow: hidden;
    display: table-row;
    min-height: calc(100vh - 230px);
  }
}

.member-footer {
  padding-top: $basespace-300;

  .footer-title {
    font-size: $fontsize-100;
    color: $textcolor-light;
    margin-bottom: $basespace-100;
  }

  .input {
    margin-right: $basespace-200;
  }

  .input-email {
    @include m-fixed-width(280px);
  }

  .input-name {
    @include m-fixed-width(160px);
  }

  .input-last-name, .input-first-name {
    @include m-fixed-width(100px);
  }
}

//table
.table-row {
  display: flex;
  align-items: center;
  padding-right: $basespace-400;
  padding-left: $basespace-400;
}

.theader {
  height: 35px;
  min-width: v-bind(totalWidth);
  position: sticky;
  top: 0;
  z-index: 2;
  cursor: text;

  &-row {
    height: inherit;
    background-color: white;
    border-bottom: 1px solid $bdcolor-base;
    color: $textcolor-light;
    font-size: $fontsize-100;
  }
}

.member-list-container {
  overflow-x: auto;
}

.tbody {
  &-row {
    border-bottom: 1px solid $bdcolor-base;
    padding-top: $basespace-200;
    padding-bottom: $basespace-200;

    cursor: pointer;
    &:hover {
      background-color: $bgcolor-base;
    }
  }
  .member-list-body {
    height: $setting-member-height;
    min-width: v-bind(totalWidth);
  }
}

.item.item {
  display: flex;
  align-items: center;
  margin-right: 16px;
  font-weight: 600;

  &::before {
    content: '';
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 8px;
    background-color: $basecolor-success;
  }

  &.invited::before {
    background-color: $basecolor-warning;
  }

  &.invalid::before {
    background-color: $basecolor-error;
  }
}
.label-header {
  padding: 0 $basespace-600 $basespace-400;
  border-bottom: 1px solid $bdcolor-base;
}
</style>

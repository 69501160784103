/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface SequenceStepInstanceAllOf
 */
export interface SequenceStepInstanceAllOf {
    /**
     * シーケンスステップインスタンスID
     * @type {number}
     * @memberof SequenceStepInstanceAllOf
     */
    'id': number;
    /**
     * 並び順
     * @type {number}
     * @memberof SequenceStepInstanceAllOf
     */
    'sortOrder': number;
    /**
     * シーケンスインスタンスID
     * @type {number}
     * @memberof SequenceStepInstanceAllOf
     */
    'sequenceInstanceId': number;
    /**
     * 次のアクションID
     * @type {number}
     * @memberof SequenceStepInstanceAllOf
     */
    'nextActionId': number | null;
    /**
     * デフォルトアクションタイプID
     * @type {number}
     * @memberof SequenceStepInstanceAllOf
     */
    'defaultActionTypeId': number | null;
    /**
     * アクションタイプID
     * @type {number}
     * @memberof SequenceStepInstanceAllOf
     */
    'actionTypeId': number | null;
    /**
     * 終了日時
     * @type {string}
     * @memberof SequenceStepInstanceAllOf
     */
    'doneAt': string | null;
    /**
     * 予定日時
     * @type {string}
     * @memberof SequenceStepInstanceAllOf
     */
    'scheduledOrEndedDatetime': string | null;
    /**
     * 次のステップタイプ
     * @type {string}
     * @memberof SequenceStepInstanceAllOf
     */
    'nextStepConditionType': string;
    /**
     * 間隔日数
     * @type {number}
     * @memberof SequenceStepInstanceAllOf
     */
    'dateInterval': number;
    /**
     * 詳細
     * @type {string}
     * @memberof SequenceStepInstanceAllOf
     */
    'nextActionContent': string | null;
    /**
     * 実施時間
     * @type {string}
     * @memberof SequenceStepInstanceAllOf
     */
    'reservedTime': string;
    /**
     * シーケンス名
     * @type {string}
     * @memberof SequenceStepInstanceAllOf
     */
    'name': string;
    /**
     * 実行予定日時
     * @type {string}
     * @memberof SequenceStepInstanceAllOf
     */
    'reservedAt': string | null;
    /**
     * 送信先アドレス
     * @type {string}
     * @memberof SequenceStepInstanceAllOf
     */
    'sendToAddress': string | null;
    /**
     * 送信先名前
     * @type {string}
     * @memberof SequenceStepInstanceAllOf
     */
    'sendToName': string | null;
    /**
     * シーケンス編集可能かどうか
     * @type {boolean}
     * @memberof SequenceStepInstanceAllOf
     */
    'sequenceEditable': boolean;
    /**
     * ステップの状態
     * @type {string}
     * @memberof SequenceStepInstanceAllOf
     */
    'status': SequenceStepInstanceAllOfStatusEnum;
    /**
     * メールの状態
     * @type {string}
     * @memberof SequenceStepInstanceAllOf
     */
    'mailStatus': SequenceStepInstanceAllOfMailStatusEnum;
}

export const SequenceStepInstanceAllOfStatusEnum = {
    Failed: 'failed',
    NotRunning: 'not_running',
    Running: 'running',
    Done: 'done',
    Skipped: 'skipped'
} as const;

export type SequenceStepInstanceAllOfStatusEnum = typeof SequenceStepInstanceAllOfStatusEnum[keyof typeof SequenceStepInstanceAllOfStatusEnum];
export const SequenceStepInstanceAllOfMailStatusEnum = {
    Sent: 'sent',
    Sending: 'sending',
    SendingFailed: 'sending_failed',
    Draft: 'draft',
    Pending: 'pending',
    SendRequest: 'send_request',
    Ready: 'ready'
} as const;

export type SequenceStepInstanceAllOfMailStatusEnum = typeof SequenceStepInstanceAllOfMailStatusEnum[keyof typeof SequenceStepInstanceAllOfMailStatusEnum];



import ApiService, { ApiArguments } from '@/api/api_service';
import ApiBase from '@/api/base'
import { SendgridSubUser, SendgridSubUserApi, SendgridSubUserApiGetSendgridSubUserRequest, SendgridSubUserApiPostSendgridApiSettingRequest } from '@/api/openapi';

export class SendgridSubUserApiService extends ApiService {
  api = new SendgridSubUserApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true)
  }

  async getSendgridSubUser({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SendgridSubUserApiGetSendgridSubUserRequest>) {
    return this.checkResponse<SendgridSubUser>(
      this.api.getSendgridSubUser(request, options),
      errorHandlers,
    );
  }

  async postSendgridApiSetting({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SendgridSubUserApiPostSendgridApiSettingRequest>) {
    return this.checkResponse<void>(
      this.api.postSendgridApiSetting(request, options),
      errorHandlers,
    );
  }
}

export default {
  getSendgridSubUsers() {
    return ApiBase.get(`/admin/sendgrid_sub_users`)
  },
  deleteSendgridSubUser(args) {
    const { id } = args
    return ApiBase.delete(`/admin/sendgrid_sub_users/${id}`, args)
  },
}

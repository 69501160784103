import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "notice success" }
const _hoisted_2 = { class: "notice error" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "icon" }
const _hoisted_5 = { class: "column-content non-border bc-label" }
const _hoisted_6 = { class: "fotter" }

import { onMounted, ref } from 'vue';
import { SfUserMappingService } from '@/api/user/resources/salesforce/sf_user_mapping';
import { i18nGlobal as i18n } from '@/i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'SalesforceApiUser',
  emits: ['back'],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const api = new SfUserMappingService();
const mapping = ref([]);
const bcUsers = ref([]);
const sfUsers = ref([]);
const salesforceSettingLoading = ref(true);
const success = ref('')
const error = ref('')
// v-forで選択したときに、同一のkeyの場合に書き換わらないケースががあるため
const changeBoxCounter = ref(0) 

const backAction = () => {
  emit('back');
}

const reset = (sfId: string) => {
  for (const index in mapping.value) {
    if (mapping.value[index].id === sfId) {
      mapping.value[index].id = ''
    }
  }
  // 変更が反映されないことがあるため、v-forのkeyを更新
  changeBoxCounter.value++
}

const upsertMapping = async () => {
  salesforceSettingLoading.value = true
  api.upsertSfUserMappingBody(
    {
      upsertSfUserMappingBodyRequest: {
        mapping: JSON.stringify(mapping.value),
      },
    },
  ).then(() => {
    success.value = i18n.t('general.success.to', { action: i18n.t('general.update') })
  }).catch(() => {
    error.value = i18n.t('general.fail.to', { action: i18n.t('general.update') })
  })
  salesforceSettingLoading.value = false;
}

onMounted(async () => {
  const result = await api.getSfUserMappings({});
  mapping.value = result.data.sfUserMapping
  bcUsers.value = result.data.bcUsers
  sfUsers.value = result.data.sfUsers
  salesforceSettingLoading.value = false;
})

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BSelect = _resolveComponent("BSelect")!
  const _component_BContentBox = _resolveComponent("BContentBox")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_BContentBox, { class: "pb-800" }, {
    content: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(success.value), 1),
        _createVNode(_component_BBtn, {
          text: "",
          position: "right",
          class: "close"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BIcon, {
              class: "px-200",
              size: "small",
              type: "white",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (success.value = ''))
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" close ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ], 512), [
        [_vShow, success.value != '']
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", null, _toDisplayString(error.value), 1),
        _createVNode(_component_BBtn, {
          text: "",
          position: "right",
          class: "close"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BIcon, {
              class: "px-200",
              size: "small",
              type: "white",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (error.value = ''))
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" close ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ], 512), [
        [_vShow, error.value != '']
      ]),
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode(_component_BLayout, {
          class: "pl-600 pb-200 header",
          "justify-center": "",
          "align-center": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BListItem, { class: "column-content non-border" }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('import.salesforce.salesforce_project')), 1)
              ]),
              _: 1
            }),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "icon" }, null, -1)),
            _createVNode(_component_BListItem, { class: "column-content non-border" }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('import.salesforce.bales_cloud_project')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mapping.value, (value, index) => {
          return (_openBlock(), _createBlock(_component_BLayout, {
            key: `${index}-${changeBoxCounter.value}`,
            class: "pl-600 pb-200",
            "justify-center": "",
            "align-center": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BListItem, null, {
                default: _withCtx(() => [
                  _createVNode(_component_BSelect, {
                    modelValue: mapping.value[index]['id'],
                    "onUpdate:modelValue": ($event: any) => ((mapping.value[index]['id']) = $event),
                    filterable: "",
                    clearable: "",
                    class: "select-column",
                    items: sfUsers.value,
                    "item-text": "name",
                    "item-value": "id",
                    fit: "",
                    onClear: () => reset(value['id'])
                  }, {
                    optionTemplate: _withCtx(({ item }) => [
                      _createElementVNode("span", {
                        class: _normalizeClass(["b-select", {'b-selected': item['id'] == value['id']}])
                      }, _toDisplayString(item['name']), 3)
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue", "items", "onClear"])
                ]),
                _: 2
              }, 1024),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_BIcon, {
                  class: "icon-sync",
                  size: "b-icon-medium"
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" swap_horiz ")
                  ])),
                  _: 1
                })
              ]),
              _createVNode(_component_BListItem, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(value['name']), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ])), [
        [_directive_loading, salesforceSettingLoading.value]
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          class: "btn-back",
          onClick: backAction
        }, _toDisplayString(_ctx.$t("sfObjectMappings.back")), 1),
        _createElementVNode("div", {
          class: "btn-save",
          onClick: upsertMapping
        }, _toDisplayString(_ctx.$t("sfObjectMappings.save")), 1)
      ])
    ]),
    _: 1
  }))
}
}

})
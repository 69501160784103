import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "member-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElTableColumn = _resolveComponent("ElTableColumn")!
  const _component_ElTable = _resolveComponent("ElTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElTable, {
      data: _ctx.tableData,
      "show-summary": "",
      "summary-method": _ctx.getSummaries,
      "row-class-name": "table-row",
      style: {"width":"100%"},
      height: 600
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ElTableColumn, {
          width: "200",
          fixed: "",
          prop: "name",
          label: _ctx.$t('general.username')
        }, null, 8, ["label"]),
        _createVNode(_component_ElTableColumn, {
          width: "200",
          sortable: "",
          prop: "operatingTime",
          label: _ctx.$t('callAnalytics.operatingTime'),
          formatter: _ctx.toTimeFormatter
        }, null, 8, ["label", "formatter"]),
        _createVNode(_component_ElTableColumn, {
          width: "200",
          sortable: "",
          prop: "callTime",
          label: _ctx.$t('callAnalytics.callTime'),
          formatter: _ctx.toTimeFormatter
        }, null, 8, ["label", "formatter"]),
        _createVNode(_component_ElTableColumn, {
          width: "200",
          sortable: "",
          prop: "callCount",
          label: _ctx.$t('callAnalytics.callCount')
        }, null, 8, ["label"]),
        _createVNode(_component_ElTableColumn, {
          width: "200",
          sortable: "",
          prop: "reachCount",
          label: _ctx.$t('callAnalytics.reachCount')
        }, null, 8, ["label"]),
        _createVNode(_component_ElTableColumn, {
          width: "200",
          sortable: "",
          prop: "opportunityCount",
          label: _ctx.$t('callAnalytics.opportunityCount')
        }, null, 8, ["label"]),
        _createVNode(_component_ElTableColumn, {
          width: "200",
          sortable: "",
          prop: "totalOpportunityAmout",
          label: _ctx.$t('callAnalytics.totalOpportunityAmout'),
          formatter: _ctx.toCurrencyFormatter
        }, null, 8, ["label", "formatter"]),
        _createVNode(_component_ElTableColumn, {
          width: "250",
          sortable: "",
          prop: "reachCountParCallCount",
          label: _ctx.$t('callAnalytics.reachCountParCallCount'),
          formatter: _ctx.toPercentFormatter
        }, null, 8, ["label", "formatter"]),
        _createVNode(_component_ElTableColumn, {
          width: "250",
          sortable: "",
          prop: "opportunityCountParReachCount",
          label: _ctx.$t('callAnalytics.opportunityCountParReachCount'),
          formatter: _ctx.toPercentFormatter
        }, null, 8, ["label", "formatter"]),
        _createVNode(_component_ElTableColumn, {
          width: "250",
          sortable: "",
          prop: "opportunityCountParCallCount",
          label: _ctx.$t('callAnalytics.opportunityCountParCallCount'),
          formatter: _ctx.toPercentFormatter
        }, null, 8, ["label", "formatter"]),
        _createVNode(_component_ElTableColumn, {
          width: "200",
          sortable: "",
          prop: "operatingTimeParCall",
          label: _ctx.$t('callAnalytics.operatingTimeParCall'),
          formatter: _ctx.toTimeFormatter
        }, null, 8, ["label", "formatter"]),
        _createVNode(_component_ElTableColumn, {
          width: "200",
          sortable: "",
          prop: "callTimeParCall",
          label: _ctx.$t('callAnalytics.callTimeParCall'),
          formatter: _ctx.toTimeFormatter
        }, null, 8, ["label", "formatter"]),
        _createVNode(_component_ElTableColumn, {
          width: "200",
          sortable: "",
          prop: "callCountParHour",
          label: _ctx.$t('callAnalytics.callCountParHour')
        }, null, 8, ["label"]),
        _createVNode(_component_ElTableColumn, {
          width: "200",
          sortable: "",
          prop: "callTimeParReach",
          label: _ctx.$t('callAnalytics.callTimeParReach'),
          formatter: _ctx.toTimeFormatter
        }, null, 8, ["label", "formatter"])
      ]),
      _: 1
    }, 8, ["data", "summary-method"])
  ]))
}
// Parameterの付与と取得はこのMixinで管理する
// 注意: vue-routerを使うと取得できないパターンもあるので、基本的にこちらを利用する
import qs from 'qs'
import { removePropertyHasEmptyValue } from '@/utils/object'

// FIXME: routerを更新しないので、routerを経由した処理をすると正しく動かないケースがある
// ページA→B→Aと同じページを連続で開こうとする場合など
// routerを仕様するとupdateが発生する問題（↓）があるため、これが解消されるまでママとする
// https://github.com/vuejs/router/issues/1701
const replaceStateWithQueryObject = (queryObject) => {
  // 空のものを除外したObject
  const pureQueryObject = {}
  for (const key in queryObject) {
    pureQueryObject[key] = removePropertyHasEmptyValue(queryObject[key])
  }
  const newQuery = Object.keys(pureQueryObject).length > 0 ? `?${qs.stringify(pureQueryObject)}` : ''
  history.state.current = location.pathname + newQuery
  if (newQuery) {
    history.replaceState(history.state, '', newQuery)
  } else {
    history.replaceState(history.state, '', location.pathname)
  }
}

const mixin = {
  methods: {
    // qs.parse時に使用するdecoder
    // Booleanのparseなどが最適化される
    // 詳細は https://github.com/ljharb/qs/issues/91#issuecomment-437926409
    decoderForQs() {
      return {
        decoder(str, decoder, charset) {
          const strWithoutPlus = str.replace(/\+/g, ' ')
          if (charset === 'iso-8859-1') {
            // unescape never throws, no try...catch needed:
            return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape)
          }

          // MEMO: 数値のみのクエリパラメタをIntegerに変換しているが、
          // パーサに求める範囲を超えているためコメントアウトする
          // クエリパラメタに数値を入れて数値型を取り出したい場合は取り出した後で明示的にparseIntかけることとする
          // 例.) LeadList.vueのcreated内、currentDisplayNumberやcurrentPageに対する部分
          // if (/^(\d+|\d*\.\d+)$/.test(str)) {
          //   return parseFloat(str)
          // }

          const keywords = {
            // true: true,
            // false: false,
            // null: null,
            // undefined
          }
          if (str in keywords) {
            return keywords[str]
          }

          // utf-8
          try {
            return decodeURIComponent(strWithoutPlus)
          } catch (e) {
            return strWithoutPlus
          }
        },
      }
    },
    getPageQueryParameter() {
      const pageParams = {}
      const query = qs.parse(window.location.search.substring(1), this.decoderForQs())

      if (query.currentDisplayNumber) pageParams.currentDisplayNumber = parseInt(query.currentDisplayNumber)
      if (query.currentPage) pageParams.currentPage = parseInt(query.currentPage)
      return pageParams
    },
    getLeadViewQueryParameter() {
      const params = qs.parse(window.location.search.substring(1), this.decoderForQs())
      return parseInt(params.leadViewId)
    },
    getUnsubscribeQueryParameter() {
      const unsubscribeParams = {}
      const query = qs.parse(window.location.search.substring(1), this.decoderForQs())

      unsubscribeParams.unsubToken = query.unsubToken
      return unsubscribeParams
    },
    getCallAnalyticsQueryParameter() {
      const query = qs.parse(window.location.search.substring(1), this.decoderForQs())
      return query.callAnalytics
    },
    getTokenQueryParameter() {
      const query = qs.parse(window.location.search.substring(1), this.decoderForQs())
      return query.token
    },
    getCurrentQueryParameter() {
      return qs.parse(window.location.search.substring(1), this.decoderForQs()) || {}
    },
    setQueryParameter(queryObject) {
      const newQueryObject = {
        ...this.getCurrentQueryParameter(),
        ...queryObject,
      }
      replaceStateWithQueryObject(newQueryObject)
    },
    deleteQueryParameter(key) {
      const currentQueryObject = qs.parse(window.location.search.substring(1), this.decoderForQs()) || {}

      if (currentQueryObject[key]) delete currentQueryObject[key]
      replaceStateWithQueryObject(currentQueryObject)
    },
    deleteAllQueryParameters(initialParam) {
      const params = qs.parse(window.location.search.substring(1), this.decoderForQs())
      if (params.leadViewId !== undefined) {
        const queryObject = { leadViewId: parseInt(params.leadViewId) }
        replaceStateWithQueryObject(queryObject)
      } else {
        replaceStateWithQueryObject(initialParam)
      }
    },
  },
}

export default mixin

type TObject = Record<string | number | symbol, unknown>;
type TSequenceAction = 'stop' | 'unregister' | 'restart';
type TSequenceStatus = 'registered' | 'stopped' | 'done' | 'failed' | 'unregistered';
const CTI_TYPES = ['zoom', 'miitel'] as const;
type TCtiType = typeof CTI_TYPES[number];

export type {
  TSequenceAction,
  TSequenceStatus,
  TObject,
  TCtiType,
}

export {
  CTI_TYPES,
}

<template>
  <div
    class="duplicated-list"
    :style="listStyle"
  >
    <div class="theader">
      <div class="table-row theader-row">
        <BSortText
          v-for="(cell, index) in headerList"
          :key="cell.key"
          class="table-cell header-cell"
          :style="getTableCellStyles(columnWidth[index])"
          :item-text="cell.name"
          is-display-only
        />
      </div>
    </div>
    <div class="tbody">
      <div
        v-for="callTarget in callTargets"
        :key="callTarget.id"
        :class="getTableRowClass(callTarget.id)"
      >
        <div
          v-for="(column, index) in columnNames"
          :key="column"
          class="table-cell"
          :style="getTableCellStyles(columnWidth[index])"
        >
          <BCheckbox
            v-if="column === 'checkbox'"
            v-model="selectedCallTargetIds"
            :value="callTarget.id"
            :disabled="isDisabledCheckbox(callTarget.id)"
          />
          <template v-else-if="column === 'createdAt'">
            {{ formatDateTime(callTarget.createdAt) }}
          </template>
          <BBtn
            v-else-if="column === 'operatorName'"
            fab
            flat
          >
            <BUserTooltip
              v-if="callTarget.operator"
              :image-url="callTarget.operator.avatarUrl"
              :name="callTarget.operator.name"
              :user-id="callTarget.operator.id"
              :size="40"
            />
          </BBtn>
          <BSfStatusIcon
            v-else-if="column === 'sfStatus' && callTarget.sfStatus"
            :connect-status="callTarget.sfStatus"
          />
          <div
            v-else
            class="truncate-text"
          >
            {{ getPropertyFallBackAltText(callTarget, column) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// NOTE: thisで使うとなぜか$teが欠損するため直接import
import { i18nGlobal as i18n } from '@/i18n';
import { altText } from '@/utils/alt';
import { formatShorterDateTime } from '@/utils/date-time';

export default {
  name: 'DuplicatedLeadList',
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    defaultSelectedCallTargetIds: {
      type: Array,
      default: () => [],
    },
    callTargets: {
      type: Array,
      default: () => [],
    },
    isFirstFixed: {
      type: Boolean,
      default: false,
    },
    maxSelectableLength: {
      type: Number,
      default: 3,
    },
  },
  emits: [
    'update:modelValue',
    'update:default-selected-call-target-ids',
  ],
  data() {
    return {
      columnWidth: [40, 120, 200, 160, 200, 140, 120, 140],
      columnNames: [
        'checkbox',
        'id',
        'companyName',
        'name',
        'email',
        'createdAt',
        'operatorName',
        'sfStatus',
      ],
    };
  },
  computed: {
    selectedCallTargetIds: {
      get() {
        return this.modelValue
      },
      set(newVal) {
        this.$emit('update:modelValue', newVal)
      },
    },
    internalDefaultSelectedCallTargetIds: {
      get() {
        return this.defaultSelectedCallTargetIds
      },
      set(newVal) {
        this.$emit('update:default-selected-call-target-ids', newVal)
      },
    },
    headerList() {
      return this.columnNames.map(column => ({
        key: column,
        name: i18n.te(`duplicatedList.leadList.columns.${column}`) ? i18n.t(`duplicatedList.leadList.columns.${column}`) : '',
      }));
    },
    listStyle() {
      const totalWidth = this.columnWidth.reduce((accumulator, currentValue) => accumulator + currentValue)
      return `width: ${totalWidth}px; min-width: 100%; max-width: ${totalWidth}px;`
    },
    fixedCallTargetId() {
      return this.isFirstFixed && this.callTargets[0] ? this.callTargets[0].id : null
    },
  },
  created() {
    if (this.selectedCallTargetIds.length > 0) {
      return
    }
    const defaultValue = this.fixedCallTargetId ? [this.fixedCallTargetId] : []
    this.internalDefaultSelectedCallTargetIds = defaultValue
    this.selectedCallTargetIds = defaultValue
  },
  methods: {
    getTableCellStyles(width) {
      return `width: ${width}px; min-width: ${width}px;`;
    },
    getPropertyFallBackAltText(obj, property) {
      return altText(obj?.[property]);
    },
    formatDateTime(datetime) {
      return altText(formatShorterDateTime(datetime));
    },
    getTableRowClass(id) {
      return {
        'table-row': true,
        'tbody-row': true,
        'fixed': this.fixedCallTargetId === id,
      }
    },
    isDisabledCheckbox(id) {
      return this.selectedCallTargetIds.length >= this.maxSelectableLength && !this.selectedCallTargetIds.includes(id)
    },
  },
};
</script>

<style lang="scss" scoped>
  .duplicated-list {
    box-sizing: content-box;
  }
  
  .tbody-row {
    cursor: pointer;
  }

  .table-row {
    padding-left: 10px;
  }

  .tbody-row:hover .table-cell {
    background-color: $bgcolor-base;
  }

  .header-cell:hover {
    font-weight: bold;
  }

  .tbody-row.fixed{
    position: sticky;
    top: 50px;
    z-index: 3;
    border-bottom: 3px solid $bdcolor-base;
    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border-bottom: 1px solid #ccc;
    }
  }
</style>

<template>
  <div class="item-filter-area">
    <div class="item-filter-area-candidate">
      <div
        v-for="item in radioItems"
        :key="item.value"
        class="my-100"
      >
        <BRadio
          v-model="internalSelectedItem"
          :label="item.label"
        >
          {{ item.displayValue }}
        </BRadio>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType, defineComponent } from 'vue'

type RadioItem = {
  label: string,
  displayValue: string
}

export default defineComponent({
  props: {
    selectedItem: {
      type: String,
      default() {
        return ''
      },
    },
    radioItems: {
      type: Array as PropType<RadioItem[]>,
    },
  },
  emits: [
    'update:selected-item',
  ],
  computed: {
    internalSelectedItem: {
      get() {
        return this.selectedItem
      },
      set(newVal) {
        this.$emit('update:selected-item', newVal)
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.item-filter-area {
  border: 1px solid $bdcolor-base;
  border-radius: 4px;

  :deep(.el-radio__label) {
    vertical-align: middle;
  }

  &-candidate {
    padding: $basespace-200;
  }
}
</style>

import { computed, readonly, ref } from 'vue';
import apis from '@/api/admin';
import { User } from '@/api/openapi';

const useCurrentUser = () => {
  const currentUser = ref<User>(null);
  const isAdminDeveloper = computed(() => ['admin_developer', 'admin_owner'].includes(currentUser.value?.role));
  const fetchCurrentUser = async () => {
    const res = await apis.getCurrentUser();
    currentUser.value = res.data.currentUser;
  };
  const fetchCurrentUserPromise = fetchCurrentUser();

  return {
    currentUser: readonly(currentUser),
    isAdminDeveloper,
    fetchCurrentUser,
    fetchCurrentUserPromise,
  };
};

export {
  useCurrentUser,
}

import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

import { onBeforeMount, onBeforeUnmount, getCurrentInstance, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/store/index';

type TProps = {
  badgeCount?: number;
  width?: number;
  autoFetchInterval?: number;
};

type TEmit = {
  (e: 'autoFetchIntervalPassed'): void;
  (e: 'afterOpen'): void;
  (e: 'afterClose'): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BIconWithActionMenu',
  props: {
    badgeCount: {},
    width: { default: 580 },
    autoFetchInterval: {}
  },
  emits: ["autoFetchIntervalPassed", "afterOpen", "afterClose"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;
const emit = __emit;

const store = useStore(key);

const bIconWithActionMenuContainer = ref<HTMLElement | null>(null);
const isOpen = ref(false);

watch(() => isOpen.value, (newVal) => {
  if (newVal) {
    emit('afterOpen');
    document.removeEventListener('click', handleDocumentClick, true);
    document.addEventListener('click', handleDocumentClick, true);
  } else {
    emit('afterClose');
    document.removeEventListener('click', handleDocumentClick, true);
  }
})

const app = getCurrentInstance();
let timerObj: number;

onBeforeMount(() => {
  if (!props.autoFetchInterval) return
  timerObj = app.appContext.config.globalProperties.$setInterval(() => {
    emit('autoFetchIntervalPassed');
  }, props.autoFetchInterval);
})

onBeforeUnmount(() => {
  app.appContext.config.globalProperties.$clearInterval(timerObj);
})

const handleIconClick = () => {
  isOpen.value = !isOpen.value;
}

const handleDocumentClick = (e: any) => {
  // MEMO: 個別に管理しないと、同時に開いていた場合やタイミングによって閉じたいものが閉じないことがあるかもしれないが、現状はこのケースになることはなさそうなので、個別管理はしないようにした。
  if (store.getters['userUi/isDisabledBIconWithActionMenuClose']) return
  if (bIconWithActionMenuContainer.value?.contains(e.target)
    || !bIconWithActionMenuContainer.value
  ) return
  close();
}

const close = () => {
  isOpen.value = false;
}

__expose({
  close,
});

return (_ctx: any,_cache: any) => {
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BBadge = _resolveComponent("BBadge")!
  const _component_BCard = _resolveComponent("BCard")!

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "bIconWithActionMenuContainer",
    ref: bIconWithActionMenuContainer,
    class: "b-icon-with-action-menu-container"
  }, [
    _createVNode(_component_BBadge, { value: _ctx.badgeCount }, {
      default: _withCtx(() => [
        _createVNode(_component_BBtn, {
          fab: "",
          flat: "",
          size: "small",
          onClick: handleIconClick
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "icon")
          ]),
          _: 3
        })
      ]),
      _: 3
    }, 8, ["value"]),
    _createVNode(_Transition, { name: "fade-in-linear" }, {
      default: _withCtx(() => [
        (isOpen.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "content",
              style: _normalizeStyle({ width: `${_ctx.width}px` })
            }, [
              _createVNode(_component_BCard, { full: "" }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
              })
            ], 4))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 512))
}
}

})
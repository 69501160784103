import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content error-confirm" }
const _hoisted_2 = { class: "error-message mb-300" }
const _hoisted_3 = { class: "error-title-area" }
const _hoisted_4 = { class: "error-title" }
const _hoisted_5 = { class: "break-word-pre-wrap" }
const _hoisted_6 = {
  key: 0,
  class: "pt-200"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "footer" }

import { useI18n } from 'vue-i18n'
import { SfReportImportSettingsService } from '@/api/user/resources/salesforce/sf_report_import_settings';
import { altText } from '@/utils/alt';

type TProps = {
  modelId: number;
  importErrors?: object[];
  importError?: string;
  importMappingErrors?: string[];
  sfLink?: string;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorDetail',
  props: {
    modelId: {},
    importErrors: {},
    importError: {},
    importMappingErrors: {},
    sfLink: {}
  },
  emits: ['back'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const i18n = useI18n();
const sfApi = new SfReportImportSettingsService();

const backAction = () => {
  emit('back');
}

const importErrorTargetHeaders = () => {
  return [
    { label: i18n.t('import.columns.call_target_company_name'), prop: 'call_target_company_name' },
  ]
}

const summaryErrors = (record: object) => {
  return Object.values(record).flatMap((value) => {
    return value.errors.map(e => `${value.header} - ${e}`)
  })
}

const getPropertyFallBackAltText = (obj: object, property: string) => {
  return altText(obj?.[property])
}

const getTargetTitle = (record: object, target: string) => {
  return Object.values(record).find((r) => r.label === target)?.value || '-'
}

const open = (url: string) => {
  window.open(url, '_blank')
}

const enableRetry = async () => {
  await sfApi.sfReportImportSettingsEnable(
    { request: { sfReportImportSettingId: props.modelId } },
  );
  window.location.reload();
}

const title = () => {
  if (props.importMappingErrors && props.importMappingErrors.length) {
    return `${props.importMappingErrors[0]}\n${i18n.t('salesforceScheduleImport.list.import.errorMapping')}`;
  }
  if (props.importErrors && props.importErrors.length) {
    return i18n.t('import.errorMessage');
  }
  return `${props.importError}\n${i18n.t('salesforceScheduleImport.list.import.errorMapping')}`;
}

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_ElTableColumn = _resolveComponent("ElTableColumn")!
  const _component_ElTable = _resolveComponent("ElTable")!
  const _component_BContainer = _resolveComponent("BContainer")!
  const _component_BContentBox = _resolveComponent("BContentBox")!

  return (_openBlock(), _createBlock(_component_BContentBox, {
    class: "pb-800",
    "is-footer": ""
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BContainer, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "error-icon" }, null, -1)),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('salesforceScheduleImport.list.import.error')), 1)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(title()), 1)
              ]),
              _createVNode(_component_BBtn, {
                class: "sf-link",
                size: "medium",
                flat: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (open(_ctx.sfLink)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('salesforceScheduleImport.sfLink')) + " ", 1),
                  _createVNode(_component_BIcon, { type: "white" }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" open_in_new ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            (_ctx.importError != null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('import.errors.systemColon')) + " " + _toDisplayString(_ctx.importError), 1))
              : (_ctx.importMappingErrors && _ctx.importMappingErrors.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.importMappingErrors, (error, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "error-row"
                      }, [
                        _createElementVNode("div", null, _toDisplayString(error.split('：')[0]), 1),
                        _createElementVNode("div", null, _toDisplayString(error.split('：')[1]), 1)
                      ]))
                    }), 128))
                  ]))
                : (_ctx.importErrors != null && _ctx.importErrors.length > 0)
                  ? (_openBlock(), _createBlock(_component_ElTable, {
                      key: 2,
                      data: _ctx.importErrors,
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ElTableColumn, { type: "expand" }, {
                          default: _withCtx((props) => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(props.row.record), ([key, val]) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: key,
                                class: "row-data"
                              }, [
                                (_openBlock(), _createBlock(_component_BLayout, {
                                  key: `layout-${key}`,
                                  "align-center": ""
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", {
                                      class: _normalizeClass(["row-header", { 'error-text': val.errors.length > 0 }])
                                    }, _toDisplayString(val.header), 3),
                                    _createElementVNode("div", {
                                      class: _normalizeClass({ 'error-text': val.errors.length > 0 })
                                    }, _toDisplayString(getPropertyFallBackAltText(val, 'value')), 3)
                                  ]),
                                  _: 2
                                }, 1024))
                              ]))
                            }), 128))
                          ]),
                          _: 1
                        }),
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(importErrorTargetHeaders, (header, index) => {
                          return _createVNode(_component_ElTableColumn, {
                            key: index,
                            label: header.label
                          }, {
                            default: _withCtx((props) => [
                              _createElementVNode("div", null, _toDisplayString(getTargetTitle(props.row.record, header.prop)), 1)
                            ]),
                            _: 2
                          }, 1032, ["label"])
                        }), 64)),
                        _createVNode(_component_ElTableColumn, {
                          label: _ctx.$t('import.errors.content')
                        }, {
                          default: _withCtx((props) => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(summaryErrors(props.row.record), (error) => {
                              return (_openBlock(), _createElementBlock("div", { key: error }, _toDisplayString(error), 1))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }, 8, ["data"]))
                  : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_BBtn, {
          size: "medium",
          onClick: backAction
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BIcon, { size: "medium" }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" keyboard_arrow_left ")
              ])),
              _: 1
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("sfObjectMappings.back")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_BBtn, {
          size: "medium",
          type: "primary",
          onClick: enableRetry
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("salesforceScheduleImport.list.import.retry")), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}
}

})
import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import {
  GetReceivedMails200Response,
  ReceivedMail,
  ReceivedMailApi,
  ReceivedMailApiGetReceivedMailRequest,
  ReceivedMailApiGetReceivedMailsRequest,
  ReceivedMailApiPostReceivedMailClickRequest,
} from '@/api/openapi';

export class ReceivedMailApiService extends ApiService {
  api = new ReceivedMailApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true)
  }

  async getReceivedMails(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<ReceivedMailApiGetReceivedMailsRequest>,
  ) {
    return this.checkResponse<GetReceivedMails200Response>(
      this.api.getReceivedMails(request, options),
      errorHandlers,
    );
  }

  async getReceivedMail(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<ReceivedMailApiGetReceivedMailRequest>): Promise<AxiosResponse<ReceivedMail>>
  {
    return this.checkResponse<ReceivedMail>(
      this.api.getReceivedMail(request, options),
      errorHandlers,
    );
  }

  async postReceivedMailClick(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<ReceivedMailApiPostReceivedMailClickRequest>,
  ) {
    return this.checkResponse<void>(
      this.api.postReceivedMailClick(request, options),
      errorHandlers,
    );
  }
}

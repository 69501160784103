<template>
  <div class="duplicated-list-modal">
    <BModal
      modal-name="duplicatedList"
      :delegate="bModalDelegate"
    >
      <BHeightAdjuster>
        <BModalHeader
          :title="$t('duplicatedList.title')"
          :center="true"
          @modal-close="handleClose"
        >
          <BBtn
            v-if="isDisplayPrevious"
            class="previous-button mx-300"
            size="small"
            fab
            flat
            @click="handleClickPrevious"
          >
            <BIcon
              class="fs-400"
              size="small"
            >
              keyboard_arrow_left 
            </BIcon>
          </BBtn>
        </BModalHeader>
        <DuplicatedLeadList
          v-if="step === 'leadList'"
          v-model="selectedCallTargetIds"
          v-model:duplicated-call-targets="duplicatedCallTargets"
          v-model:default-selected-call-target-ids="defaultSelectedCallTargetIds"
          @click-next="handleClickNext"
        />
        <SelectLeadItems
          v-if="step === 'selectLeadItems'"
          v-model="leadItemSelectedValues"
          v-model:selected-leads="selectedLeads"
          :selected-call-target-ids-with-order="selectedCallTargetIdsWithOrder"
          @click-next="handleClickNext"
        />
        <Confirm
          v-if="step === 'confirm'"
          :selected-leads="selectedLeads"
          :lead-item-selected-values="leadItemSelectedValues"
          :loading="$wait.is('mergeCallTargetWait')"
          @click-merge="handleClickMerge"
        />
      </BHeightAdjuster>
    </BModal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import { CallTargetApiMergeCallTargetsRequest, LeadItemSelectedValues } from '@/api/openapi';
import { CallTargetApiService } from '@/api/user/resources/call_target'
import { TBmodalDelegate } from '@/plugins/BModal/BModal.vue'
import * as arrayUtils from '@/utils/array';
import Confirm from '../duplicatedList/Confirm.vue'
import DuplicatedLeadList from '../duplicatedList/DuplicatedLeadList.vue'
import { TLeadItemSelectedValues, TSelectedLead, getDefaultLeadItemSelectedValues } from '../duplicatedList/selectLeadItem'
import SelectLeadItems from '../duplicatedList/SelectLeadItems.vue'

export type TSelectedCallTargetIdsWithOrder = {
  id: number;
  order: number;
}
export type TDuplicatedCallTarget = {
  id: number;
  companyName: string;
  name: string;
  email: string;
  createdAt: string;
  operator: {
    id?: number;
    name?: string;
    avatarUrl?: string;
  };
  sfStatus?: string;
}

type TData = {
  duplicatedCallTargets: TDuplicatedCallTarget[];
  defaultSelectedCallTargetIds: number[];
  selectedCallTargetIds: number[];
  selectedLeads: TSelectedLead[];
  leadItemSelectedValues: TLeadItemSelectedValues;
  step: TStep;
  bModalDelegate: TBmodalDelegate;
}
type TStep = 'leadList' | 'selectLeadItems' | 'confirm'
const steps: TStep[] = ['leadList', 'selectLeadItems', 'confirm']

export default defineComponent({
  components: {
    DuplicatedLeadList,
    SelectLeadItems,
    Confirm,
  },
  emits: [
    'close',
    'merged',
  ],
  data(): TData {
    return {
      duplicatedCallTargets: [],
      defaultSelectedCallTargetIds: [],
      selectedCallTargetIds: [],
      selectedLeads: [],
      leadItemSelectedValues: getDefaultLeadItemSelectedValues(),
      step: 'leadList',
      bModalDelegate: {
        // NOTE: 「as」がないと、「Type 'Function' is not assignable to type '() => boolean | Promise<boolean>'」と怒られる。他にいい方法があれば変えたい。
        shouldClose: this.confirmToCloseModal as () => Promise<boolean>,
        beforeClose: this.beforeClose as () => void,
      },
    }
  },
  computed: {
    ...mapGetters('user', [
      'callTarget',
    ]),
    isDisplayPrevious(): boolean {
      return this.previousStep !== null
    },
    currentStepIndex(): number {
      return steps.indexOf(this.step)
    },
    previousStep(): TStep | null {
      const previousStepIndex = this.currentStepIndex - 1
      return previousStepIndex < 0
        ? null
        : steps[previousStepIndex]
    },
    nextStep(): TStep | null {
      const nextStepIndex = this.currentStepIndex + 1
      return nextStepIndex >= steps.length
        ? null
        : steps[nextStepIndex]
    },
    isValueChanged(): boolean {
      // NOTE: stepが2つ目以降に進んでいる時はユーザーが既に何か行った後なので常に「変更あり」とする
      if (this.step !== 'leadList') {
        return true
      }

      return !arrayUtils.equals(this.defaultSelectedCallTargetIds, this.selectedCallTargetIds)
    },
    selectedCallTargetIdsWithOrder(): TSelectedCallTargetIdsWithOrder[] {
      return this.duplicatedCallTargets
        .filter(ct => this.selectedCallTargetIds.includes(ct.id))
        .map(({ id }, order) => ({
          id,
          order,
        }))
    },
  },
  methods: {
    ...mapWaitingActions('userUi', {
      setDoReloadLeadMergeStatusAction: 'setDoReloadLeadMergeStatusActionWait',
    }),
    async handleClose(): Promise<void> {
      await this.confirmToCloseModal() && this.closeModal()
    },
    handleClickPrevious(): void {
      this.step = this.previousStep
    },
    handleClickNext(): void {
      this.step = this.nextStep
    },
    async handleClickMerge(): Promise<void> {
      this.$wait.start('mergeCallTargetWait')
      const request: CallTargetApiMergeCallTargetsRequest = {
        mergeCallTargetsBody: {
          callTargetIds: this.selectedCallTargetIds,
          sections: this.leadItemSelectedValues as LeadItemSelectedValues,
        },
      }
      const api = new CallTargetApiService()
      await api.mergeCallTarget({ request })
      this.$wait.end('mergeCallTargetWait')

      this.closeModal()
      this.setDoReloadLeadMergeStatusAction(true)
      this.$emit('merged')
    },
    beforeClose(): void {
      this.$emit('close')
    },
    closeModal(): void {
      this.$modal.hide('duplicatedList')
    },
    async confirmToCloseModal(): Promise<boolean> {
      if (!this.isValueChanged) {
        return true
      }
      return await this.$bitterAlert.show({
        text: this.$t('duplicatedList.confirmToCloseModal'),
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.duplicated-list-modal {
  overflow: auto;
}
.b-modal-header .previous-button {
  position: absolute;
  top: 0;
  left: $basespace-300;
  bottom: 0;
  margin: auto;
}
:deep(.b-modal) {
  .sub-title {
    white-space: pre-line;
    text-align: center;
    line-height: 1.5
  }

  .table-row {
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
  .table-cell {
    padding-right: $basespace-200;
    padding-left: 10px;
    min-width: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .theader {
    height: 50px;
    position: sticky;
    top: 0;
    z-index: 2;
    cursor: text;
    &-row {
      height: inherit;
      background-color: $bgcolor-white;
      border-bottom: 1px solid $bdcolor-base;
      padding-bottom: $basespace-100;
      color: $textcolor-light;
      font-size: $fontsize-100;
    }
  }
  .tbody {
    &-row {
      border-bottom: 1px solid $bdcolor-base;
      padding-top: $basespace-200;
      padding-bottom: $basespace-200;
      background-color: $bgcolor-white;

      &:hover {
        background-color: $bgcolor-base;
      }
    }
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    overflow-y: auto;
  }
}
</style>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "inspire" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BToolbar = _resolveComponent("BToolbar")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BToolbar, {
      modelValue: _ctx.drawer,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.drawer) = $event)),
      name: _ctx.currentUser.name,
      email: _ctx.currentUser.email,
      "avatar-url": _ctx.currentUser.avatarUrl
    }, null, 8, ["modelValue", "name", "email", "avatar-url"]),
    (_openBlock(), _createBlock(_component_RouterView, {
      key: _ctx.$route.fullPath,
      style: {"height":"100%"}
    }))
  ]))
}
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sequence-apply-modal" }

import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { FailedCallTarget } from '@/api/openapi';
import { KEY_OF_TOAST } from '@/injection-keys';
import { bitterAlert } from '@/plugins/BBitterAlert';
import { TBmodalDelegate } from '@/plugins/BModal/BModal.vue'
import SequenceApply from '../../general/sequence/SequenceApply.vue';
import SequenceMasterSelect from './sequenceApply/SequenceMasterSelect.vue';

type TProps = {
  modalName: string;
  callTargetId: number;
};
type TStep = 'select' | 'apply';


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceApplyModal',
  props: {
    modalName: {},
    callTargetId: {}
  },
  emits: ['close', 'afterApply'],
  setup(__props: any, { emit: __emit }) {


const emit = __emit;

const i18n = useI18n();
const toast = inject(KEY_OF_TOAST);

const step = ref<TStep>('select');
const selectedSequenceId = ref<number>();

const beforeClose = () => {
  step.value = 'select';
}
const shouldClose = async (): Promise<boolean> => {
  return step.value == 'select' || await bitterAlert.show({
    text: i18n.t('sequence.leadDetail.confirmToCloseModal'),
  });
}
const bModalDelegate = ref<TBmodalDelegate>({
  shouldClose,
  beforeClose,
});

const handleCloseClick = async () => {
  await shouldClose() && closeModal();
};
const handleSelectSequence = (sequenceId: number) => {
  selectedSequenceId.value = sequenceId;
  step.value = 'apply';
};
const handleBackToSequenceList = () => {
  step.value = 'select';
};
const handleAfterApply = (failedCallTargets: FailedCallTarget[]) => {
  if (failedCallTargets.length) {
    bitterAlert.show({
      title: i18n.t('sequence.message.sequenceRegistrationFailure'),
      text: failedCallTargets.map(e => e.errorMessages).flat().join('\n'),
      buttonsCancel: false,
    })
    return
  }
  toast.show(i18n.t('general.done.of', { action: i18n.t('sequence.applySequenceToLead') }))

  closeModal();
  emit('afterApply');
};

const closeModal = () => {
  emit('close');
};

return (_ctx: any,_cache: any) => {
  const _component_BModalHeader = _resolveComponent("BModalHeader")!
  const _component_BModalBody = _resolveComponent("BModalBody")!
  const _component_BHeightAdjuster = _resolveComponent("BHeightAdjuster")!
  const _component_BModal = _resolveComponent("BModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BModal, {
      "modal-name": _ctx.modalName,
      width: "1480px",
      height: "880px",
      delegate: bModalDelegate.value
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BHeightAdjuster, null, {
          default: _withCtx(() => [
            (step.value === 'select')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_BModalHeader, {
                    title: _ctx.$t('sequence.title'),
                    onModalClose: handleCloseClick
                  }, null, 8, ["title"]),
                  _createVNode(_component_BModalBody, { full: "" }, {
                    default: _withCtx(() => [
                      _createVNode(SequenceMasterSelect, { onSelectSequenceMaster: handleSelectSequence })
                    ]),
                    _: 1
                  })
                ], 64))
              : _createCommentVNode("", true),
            (step.value === 'apply')
              ? (_openBlock(), _createBlock(SequenceApply, {
                  key: 1,
                  "call-target-ids": [_ctx.callTargetId],
                  "sequence-master-id": selectedSequenceId.value,
                  onClickClose: handleCloseClick,
                  onAfterApply: handleAfterApply,
                  onBackToSequenceList: handleBackToSequenceList
                }, null, 8, ["call-target-ids", "sequence-master-id"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modal-name", "delegate"])
  ]))
}
}

})
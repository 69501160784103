import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { PaginationMeta } from '@/api/openapi';
import { TCallTarget, usePrevNext } from '@/composable/user/leadList/paging';

type TProps = {
  callTargetId: number;
  currentCallTargets: TCallTarget[]; // TODO: define type
  callTargetsMeta: PaginationMeta | null;
  pageLoading: boolean;
  disabled: boolean;
};
type TEmit = {
  closeModal: [];
  changeTargetPage: [callTargetId: number, page: number];
  changeTargetCallTarget: [callTargetId: number];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MoveButton',
  props: {
    callTargetId: {},
    currentCallTargets: {},
    callTargetsMeta: {},
    pageLoading: { type: Boolean },
    disabled: { type: Boolean }
  },
  emits: ["closeModal", "changeTargetPage", "changeTargetCallTarget"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const {
  currentPage,
  prevDisabled,
  nextDisabled,
  isPrevMovesAcrossPages,
  isNextMovesAcrossPages,
  prevCallTargetId,
  nextCallTargetId,
} = usePrevNext(
  () => props.pageLoading,
  () => props.disabled,
  () => props.callTargetId,
  () => props.currentCallTargets,
  () => props.callTargetsMeta,
);

const editing = computed(() => !props.disabled);

const moveToPreviousCallTarget = () => {
  if (isPrevMovesAcrossPages.value) { 
    emit('changeTargetPage', prevCallTargetId.value, currentPage.value - 1);
  } else {
    emit('changeTargetCallTarget', prevCallTargetId.value);
  }
};
const moveToNextCallTarget = () => {
  if (isNextMovesAcrossPages.value) {
    emit('changeTargetPage', nextCallTargetId.value, currentPage.value + 1)
  } else {
    emit('changeTargetCallTarget', nextCallTargetId.value);
  }
};

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createBlock(_component_BLayout, { "align-center": "" }, {
    default: _withCtx(() => [
      _createVNode(_component_BBtn, {
        class: "mx-300",
        size: "small",
        fab: "",
        flat: "",
        "data-test": "closeCallTarget",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal')))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BIcon, {
            class: "fs-400",
            size: "small"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" close ")
            ])),
            _: 1
          })
        ]),
        _: 1
      }),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "target-close-button-line" }, null, -1)),
      _createVNode(_component_BTooltip, {
        top: "",
        content: _ctx.$t('callTarget.move.isEditing'),
        disabled: editing.value
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_BBtn, {
              class: "mx-200",
              size: "small",
              fab: "",
              flat: "",
              disabled: _unref(prevDisabled) || !_unref(prevCallTargetId),
              onClick: moveToPreviousCallTarget
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BIcon, {
                  class: "fs-400",
                  size: "small"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" keyboard_arrow_left ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["disabled"])
          ])
        ]),
        _: 1
      }, 8, ["content", "disabled"]),
      _createVNode(_component_BTooltip, {
        top: "",
        content: _ctx.$t('callTarget.move.isEditing'),
        disabled: editing.value
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_BBtn, {
              class: "mx-200",
              size: "small",
              fab: "",
              flat: "",
              disabled: _unref(nextDisabled) || !_unref(nextCallTargetId),
              onClick: moveToNextCallTarget
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BIcon, {
                  class: "fs-400",
                  size: "small"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" keyboard_arrow_right ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["disabled"])
          ])
        ]),
        _: 1
      }, 8, ["content", "disabled"])
    ]),
    _: 1
  }))
}
}

})
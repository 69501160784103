<template>
  <div
    class="b-check-text"
    :class="CheckTextClass"
  >
    <div
      class="inner-icon"
      @click="clickDone"
    >
      <BIcon
        class="b-icon"
        size="mini"
      >
        check
      </BIcon>
    </div>
    <div class="inner-text">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    done: Boolean,
    editable: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'click-done',
  ],
  computed: {
    CheckTextClass () {
      return { 'check-text-done': this.done }
    },
  },
  methods: {
    clickDone () {
      if (this.done || !this.editable) { return }
      this.$emit('click-done')
    },
  },
}
</script>

<style lang="scss" scoped>
  .b-check-text {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-left: 20px;
    }
  }

  .inner-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border:  1px solid $bdcolor-base;
    cursor: pointer;
    margin-right: 8px;
  }

  .check-text-done {
    opacity: .4;

    .inner-icon {
      background-color: $basecolor-success;
      border-color: $basecolor-success;
      cursor: not-allowed;

      .b-icon {
        color: white;
      }
    }
  }
</style>

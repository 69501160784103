
import Api from '@/api/unsubscribe'
import UnsubscribeActions from '@/store/common/resources/unsubscribe'

const unsubscribe = new UnsubscribeActions(Api)

const actions = Object.assign(
  unsubscribe.actions,
)

export default actions

import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "column-content non-border select-column" }
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = { class: "column-content non-border bc-label" }

import { onMounted, ref, computed } from 'vue';
import { SfObjectMappingService } from '@/api/user/resources/salesforce/sf_object_mapping';

type TProps = {
  selectedObject?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SalesforceReportMapping',
  props: {
    selectedObject: { default: '' }
  },
  emits: ['back'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const api = new SfObjectMappingService();
const mapping = ref({});
const sfFields = ref({});
const sfFieldsMap = ref({});
const sfMapInfo = ref({});
const sfColumnDataTypes = ref({});
const customFieldsDataTypes = ref({});
const sharedObjectMapping = ref({});
const salesforceSettingLoading = ref(true);
// v-forで選択したときに、同一のkeyの場合に書き換わらないケースががあるため
const changeBoxCounter = ref(0)

const loadSfFieldsMap = () => {
  const sfMap = []
  for (const sfKey of Object.keys(sfFields.value)) {
    if (sfKey !== 'Id') {
      sfMap[sfKey] = sfFields.value[sfKey].label
    }
  }
  sfFieldsMap.value = sfMap
}

const mappingDisplayValue = computed(() => {
  const sfMap = {}
  for (const key of Object.keys(mapping.value)) {
    if (mapping.value[key].sf_column !== null) {
      sfMap[key] = mapping.value[key]
    }
  }

  return sfMap
})


onMounted(async () => {
  if (props.selectedObject !== 'lead') {
    const contact = await api.getSfObjectMappings({ targetObject: 'contact' });
    const account = await api.getSfObjectMappings({ targetObject: 'account' });

    mapping.value = Object.assign(contact.data.sfObjectMappings.objectMappings, account.data.sfObjectMappings.objectMappings);
    sfFields.value = Object.assign(contact.data.sfObjectMappings.objectFields, account.data.sfObjectMappings.objectFields);
    sfMapInfo.value = Object.assign(contact.data.sfObjectMappings.sfMapInfo, account.data.sfObjectMappings.sfMapInfo);
    sfColumnDataTypes.value = Object.assign(contact.data.sfObjectMappings.sfColumnDataTypes, account.data.sfObjectMappings.sfColumnDataTypes);
    customFieldsDataTypes.value = Object.assign(contact.data.sfObjectMappings.customFieldsDataTypes, account.data.sfObjectMappings.customFieldsDataTypes);
    sharedObjectMapping.value = Object.assign(contact.data.sfObjectMappings.sharedObjectMapping, account.data.sfObjectMappings.sharedObjectMapping);
  } else {
    const lead = await api.getSfObjectMappings({ targetObject: props.selectedObject });
    mapping.value = lead.data.sfObjectMappings.objectMappings;
    sfFields.value = lead.data.sfObjectMappings.objectFields;
    sfMapInfo.value = lead.data.sfObjectMappings.sfMapInfo;
    sfColumnDataTypes.value = lead.data.sfObjectMappings.sfColumnDataTypes;
    customFieldsDataTypes.value = lead.data.sfObjectMappings.customFieldsDataTypes;
    sharedObjectMapping.value = lead.data.sfObjectMappings.sharedObjectMapping;
  }
  loadSfFieldsMap()
  salesforceSettingLoading.value = false;
})

return (_ctx: any,_cache: any) => {
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BContentBox = _resolveComponent("BContentBox")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_BContentBox, null, {
    content: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_BLayout, {
          class: "pl-600 pb-200 header",
          "justify-center": "",
          "align-center": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BListItem, { class: "column-content non-border radio-priority-area" }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("span", null, "SF優先", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_BListItem, { class: "column-content non-border" }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("span", null, "Salesforce項目", -1)
              ])),
              _: 1
            }),
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "icon" }, null, -1)),
            _createVNode(_component_BListItem, { class: "column-content non-border radio-priority-area" }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("span", null, "BC優先", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_BListItem, { class: "column-content non-border" }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("span", null, "BALES CLOUD項目", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_BListItem, { class: "column-content non-border check-area" }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("span", null, "SF→BC", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_BListItem, { class: "column-content non-border check-area" }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createElementVNode("span", null, "SF←BC", -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mappingDisplayValue.value, (value, bcKey) => {
          return (_openBlock(), _createBlock(_component_BLayout, {
            key: `${bcKey}-${changeBoxCounter.value}`,
            class: "pl-600 pb-200",
            "justify-center": "",
            "align-center": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BListItem, { class: "radio-priority-area" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(`column-content non-border radio-box
                radio-box-${value['bc_priority'] != true && value['sf_import'] ? 'active' : 'non-active'}`)
                  }, [
                    _createVNode(_component_BIcon, {
                      class: "radio",
                      size: "b-icon-small"
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode(" done ")
                      ])),
                      _: 1
                    })
                  ], 2)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_BListItem, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, _toDisplayString(sfFieldsMap.value[value['sf_column']]), 1)
                ]),
                _: 2
              }, 1024),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_BIcon, {
                  class: "icon-sync",
                  size: "b-icon-medium"
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode(" swap_horiz ")
                  ])),
                  _: 1
                })
              ]),
              _createVNode(_component_BListItem, { class: "radio-priority-area" }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("div", {
                    class: _normalizeClass(`column-content non-border radio-box
                radio-box-${value['bc_priority'] ? 'active' : 'non-active'}`)
                  }, [
                    _createVNode(_component_BIcon, {
                      class: "radio",
                      size: "b-icon-small"
                    }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode(" done ")
                      ])),
                      _: 1
                    })
                  ], 2), [
                    [_vShow, !('sf_import_only' in value && value['sf_import_only'] == true) && sharedObjectMapping.value[bcKey] == null]
                  ])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_BListItem, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, _toDisplayString(value['label'] ?? _ctx.$t(`import.salesforce.mappings.${bcKey}`)), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_BListItem, { class: "check-area" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(`column-content non-border check-box
                check-box-${value['sf_import'] ? 'active' : 'non-active'}`)
                  }, [
                    _createVNode(_component_BIcon, {
                      class: "check",
                      size: "b-icon-small"
                    }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode(" done ")
                      ])),
                      _: 1
                    })
                  ], 2)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_BListItem, { class: "check-area" }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("div", {
                    class: _normalizeClass(`column-content non-border check-box
                check-box-${value['bc_import'] ? 'active' : 'non-active'}`)
                  }, [
                    _createVNode(_component_BIcon, {
                      class: "check",
                      size: "b-icon-small"
                    }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode(" done ")
                      ])),
                      _: 1
                    })
                  ], 2), [
                    [_vShow, !('sf_import_only' in value && value['sf_import_only'] == true)]
                  ])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ])), [
        [_directive_loading, salesforceSettingLoading.value]
      ])
    ]),
    _: 1
  }))
}
}

})
import ApiBase from '@/api/base'

export default {
  signinAdmin () {
    return ApiBase.post(`/admin/signin`)
  },
  signoutAdmin () {
    return ApiBase.post(`/admin/signout`)
  },
}

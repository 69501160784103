<template>
  <div class="graph">
    <div class="graph-title fw-bold">
      {{ title }}
    </div>
    <BVerticalBarChart
      :chart-data="chartData"
      :options="options"
      :height="400"
    />
  </div>
</template>

<script>
export default {
  name: 'Graph',
  props: {
    title: {
      type: String,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
  .graph {
    &-title {
     font-size: $fontsize-400;
     font-weight: bold;
    }
  }
</style>

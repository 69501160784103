import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import { useInputValidations } from '@/composable/input-validations';
import { useWait } from '@/composable/vue-wait';
import { key } from '@/store/index';

type TEmit = {
  'afterCreate': [];
};

export default /*@__PURE__*/_defineComponent({
  __name: 'CustomFieldCreatingForm',
  emits: ["afterCreate"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const i18n = useI18n();
const store = useStore(key);
const { doActionWithWait } = useWait();
const { requiredRule } = useInputValidations();
const { isAvailableSalesforceLinkFeature } = useAvailableFeatureCheck()

const name = ref('');
const systemReferenceName = ref('');
const type = ref('text');
const nameValid = ref(false);
const systemReferenceNameValid = ref(false);
const isSubmitButtondisabled = computed(() => !nameValid.value || !systemReferenceNameValid.value);
const selectableDataTypes = computed(() => {
  const selectTypes = []

  for (const [k, v] of Object.entries(i18n.tm('customField.dataType.enum')) as [string, string][]) {
    if (k != 'sfReference' || k == 'sfReference' && isAvailableSalesforceLinkFeature) {
      selectTypes.push({ value: k.replace(/([A-Z])/g, x => '_'.concat(x.toLowerCase())), text: v })
    }
  }

  return selectTypes
});

const clear = () => {
  name.value = '';
  systemReferenceName.value = '';
};

const handleSubmit = async () => {
  await createCustomField();
  emit('afterCreate');
  clear();
};

const createCustomField = async () => {
  await doActionWithWait('createCustomFieldWait', async () => {
    const customField = {
      name: name.value,
      systemReferenceName: systemReferenceName.value,
      dataType: type.value,
    };
    const body = {
      customField,
    };
    await store.dispatch('user/createCustomFieldAction', {
      body,
    });
  })
};

return (_ctx: any,_cache: any) => {
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BSelect = _resolveComponent("BSelect")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createBlock(_component_BLayout, {
    "align-center": "",
    "justify-center": "",
    class: "custom-field-creating-form"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BInput, {
        modelValue: name.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((name).value = $event)),
        valid: nameValid.value,
        "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => ((nameValid).value = $event)),
        class: "name-input",
        placeholder: _ctx.$t('customField.name'),
        rules: [_unref(requiredRule)],
        disabled: _ctx.createLoading,
        onKeypressEnter: handleSubmit
      }, null, 8, ["modelValue", "valid", "placeholder", "rules", "disabled"]),
      _createVNode(_component_BInput, {
        modelValue: systemReferenceName.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((systemReferenceName).value = $event)),
        valid: systemReferenceNameValid.value,
        "onUpdate:valid": _cache[3] || (_cache[3] = ($event: any) => ((systemReferenceNameValid).value = $event)),
        class: "system-reference-name-input",
        placeholder: _ctx.$t('customField.systemReferenceName'),
        rules: [_unref(requiredRule)],
        disabled: _ctx.createLoading,
        onKeypressEnter: handleSubmit
      }, null, 8, ["modelValue", "valid", "placeholder", "rules", "disabled"]),
      _createVNode(_component_BSelect, {
        modelValue: type.value,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((type).value = $event)),
        items: selectableDataTypes.value,
        disabled: _ctx.createLoading,
        class: "type-input"
      }, null, 8, ["modelValue", "items", "disabled"]),
      _createVNode(_component_BBtn, {
        type: "primary",
        disabled: isSubmitButtondisabled.value,
        loading: _ctx.createLoading,
        onClick: handleSubmit
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.append.text')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "loading"])
    ]),
    _: 1
  }))
}
}

})
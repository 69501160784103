import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "navbar-left" }
const _hoisted_2 = { class: "page-title fs-400 fw-bold" }
const _hoisted_3 = { class: "navbar-right" }
const _hoisted_4 = { class: "theader" }
const _hoisted_5 = { class: "table-row theader-row" }
const _hoisted_6 = { class: "tbody" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { key: 3 }
const _hoisted_11 = { key: 4 }

import { computed, inject, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n'
import { GetSfLeadSyncErrorLogs200Response, PaginationMeta, SfLeadSyncErrorLog } from '@/api/openapi';
import { SfLeadSyncLogsService } from '@/api/user/resources/salesforce/sf_lead_sync_error_logs';
import { KEY_OF_WAIT } from '@/injection-keys';
import { formatShorterDateTime } from '@/utils/date-time';


export default /*@__PURE__*/_defineComponent({
  __name: 'SalesforceErrorLogs',
  setup(__props) {

const i18n = useI18n();
const apiService = new SfLeadSyncLogsService();
const currentPage = ref(1);
const sfLeadSyncErrorLogs = ref<SfLeadSyncErrorLog[]>([]);
const wait = inject(KEY_OF_WAIT);
const meta = ref<PaginationMeta>({
  totalPages: 1,
  totalCount: 0,
  limitValue: 0,
});

const allPageCount = computed(() => meta.value.totalPages);
const loadSfLeadSyncErrorLogs = async (currentPage: number) => {
  wait.start('getSfLeadSyncErrorLogsWait');
  const data = await apiService.getSfLeadSyncErrorLogs({ request: { currentPage } });
  ({ sfLeadSyncErrorLogs: sfLeadSyncErrorLogs.value, meta: meta.value } = data.data as GetSfLeadSyncErrorLogs200Response);
  wait.end('getSfLeadSyncErrorLogsWait');
};

const open = (url: string) => {
  window.open(url, '_blank')
}

const objectTypeText = (objectType: string) => {
  return i18n.t(`salesforceSyncErrorLog.objectType.${objectType}`)
}


watch(() => currentPage.value, (newValue) => {
  loadSfLeadSyncErrorLogs(newValue);
}, { immediate: true });

const headers = [
  { key: 'createdAt', name: i18n.t('salesforceSyncErrorLog.list.date'), class: [] },
  { key: 'message', name: i18n.t('salesforceSyncErrorLog.list.message'), class: ['long-container'] },
  { key: 'objectType', name: i18n.t('salesforceSyncErrorLog.list.objectType'), class: [] },
  { key: 'callTargetUrl', name: i18n.t('salesforceSyncErrorLog.list.leadUrl'), class: [] },
  { key: 'sfObjcets', name: i18n.t('salesforceSyncErrorLog.list.sfUrls'), class: [] },
];

return (_ctx: any,_cache: any) => {
  const _component_BPagination = _resolveComponent("BPagination")!
  const _component_BSortText = _resolveComponent("BSortText")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BContent = _resolveComponent("BContent")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_BContent, { class: "content" }, {
    navbar: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('salesforceSyncErrorLog.title')), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BPagination, {
          modelValue: currentPage.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentPage).value = $event)),
          length: allPageCount.value,
          "total-visible": 5
        }, null, 8, ["modelValue", "length"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(headers, (cell) => {
            return _createVNode(_component_BSortText, {
              key: cell.key,
              class: _normalizeClass(["table-cell header-cell", cell.class]),
              "item-text": cell.name,
              "is-display-only": ""
            }, null, 8, ["class", "item-text"])
          }), 64)),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "table-cell menu" }, null, -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sfLeadSyncErrorLogs.value, (sfLeadSyncErrorLog) => {
          return (_openBlock(), _createElementBlock("div", {
            key: sfLeadSyncErrorLog.id,
            class: "table-row tbody-row"
          }, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(headers, (column) => {
              return _createElementVNode("div", {
                key: column.key,
                class: _normalizeClass(["table-cell", column.class])
              }, [
                (column.key === 'objectType')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(objectTypeText(sfLeadSyncErrorLog.objectType)), 1))
                  : _createCommentVNode("", true),
                (column.key === 'message')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(sfLeadSyncErrorLog.message), 1))
                  : _createCommentVNode("", true),
                (column.key === 'createdAt')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_unref(formatShorterDateTime)(sfLeadSyncErrorLog.createdAt)), 1))
                  : _createCommentVNode("", true),
                (column.key === 'callTargetUrl')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createTextVNode(_toDisplayString(sfLeadSyncErrorLog.callTarget.name) + " ", 1),
                      _createVNode(_component_BIcon, {
                        class: "pointer",
                        onClick: () => open(sfLeadSyncErrorLog.callTarget.url)
                      }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode(" open_in_new ")
                        ])),
                        _: 2
                      }, 1032, ["onClick"])
                    ]))
                  : _createCommentVNode("", true),
                (column.key === 'sfObjcets')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sfLeadSyncErrorLog.sfObjects, (sfObject) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: sfObject.type
                        }, [
                          _createTextVNode(_toDisplayString(sfObject.type) + " ", 1),
                          _createVNode(_component_BIcon, {
                            class: "pointer",
                            onClick: () => open(sfObject.url)
                          }, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode(" open_in_new ")
                            ])),
                            _: 2
                          }, 1032, ["onClick"])
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ], 2)
            }), 64))
          ]))
        }), 128))
      ])
    ]),
    _: 1
  })), [
    [_directive_loading, _ctx.$wait.is(['getSfLeadSyncErrorLogsWait'])]
  ])
}
}

})
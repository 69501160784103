<template>
  <div class="b-input-date-time">
    <template v-if="isRange">
      <BLayout>
        <BFlex
          class="pr-100"
          :span="10"
        >
          <BDatePicker
            v-bind="dateAttributes"
            v-model="internalDate"
          />
        </BFlex>
        <BFlex :span="14">
          <BTimePicker
            v-bind="timeAttributes"
            v-model="internalTimeRange"
          />
        </BFlex>
      </BLayout>
    </template>
    <template v-else>
      <BLayout>
        <BFlex
          class="pr-100"
          :span="12"
        >
          <BDatePicker
            v-bind="dateAttributes"
            v-model="internalDate"
          />
        </BFlex>
        <BFlex :span="12">
          <BTimeSelect
            v-if="isStep"
            v-bind="timeAttributes"
            v-model="internalTime"
          />
          <BTimePicker
            v-else
            v-bind="timeAttributes"
            v-model="internalTime"
          />
        </BFlex>
      </BLayout>
    </template>
  </div>
</template>

<script>
import { mergeDateAndTime } from '@/utils/date-time';

export default {
  props: {
    isRange: Boolean,
    date: [String, Date, Number],
    time: [String, Date, Number],
    timeRange: {
      type: Array,
      default () {
        return []
      },
    }, // isRange: trueのみ使用
    rules: { // TODO:element-uiだとruleが設定できなそう
      type: Array,
      default () {
        return []
      },
    },
    dateFormat: {
      type: String,
      default: 'MM/DD',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    isStep: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:date',
    'update:time',
    'update:time-range',
  ],
  computed: {
    internalDate: {
      get () {
        return this.date
      },
      set (newVal) {
        this.$emit('update:date', newVal)
      },
    },
    internalTime: {
      get () {
        return this.time
      },
      set (newVal) {
        this.$emit('update:time', newVal)
      },
    },
    internalTimeRange: {
      get () {
        return this.timeRange
      },
      set (newVal) {
        const newTimeRange = [
          mergeDateAndTime(this.timeRange[0], newVal[0]),
          mergeDateAndTime(this.timeRange[1], newVal[1]),
        ]
        this.$emit('update:time-range', newTimeRange)
      },
    },
    dateAttributes () {
      return {
        type: 'date',
        format: this.dateFormat,
        clearable: this.clearable,
        disabled: this.disabled,
      }
    },
    timeAttributes () {
      const attributes = {
        format: 'HH:mm',
        clearable: this.clearable,
        disabled: this.disabled,
      }

      if (this.isRange) {
        attributes['is-range'] = true
        attributes['arrow-control'] = true
        attributes.editable = true
      }

      return attributes
    },
  },
}
</script>

<style lang="scss" scoped>
  .b-input-date-time {
    width: 100%;
  }
</style>

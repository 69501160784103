import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "date" }
const _hoisted_2 = { class: "company-name" }
const _hoisted_3 = { class: "subject" }

import { computed } from 'vue'
import { MailRemindItem } from '@/api/openapi';
import LeadLink from '@/components/organisms/user/general/LeadLink.vue'
import { formatDate } from '@/utils/date-time'

type TProps = {
  item: MailRemindItem;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DraftMailReminderItem',
  props: {
    item: {}
  },
  setup(__props: any) {

const props = __props;

const callTargetId = computed(() => {
  return props.item.callTargetId
})

const callBackUpdatedAt = computed(() => {
  return formatDate(props.item.updatedAt)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(LeadLink, {
    "call-target-id": callTargetId.value,
    tag: "div",
    class: "draft-reminder-item"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(callBackUpdatedAt.value), 1),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.item.companyName), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('draftMailReminder.subject', {subject: _ctx.item.subject})), 1)
    ]),
    _: 1
  }, 8, ["call-target-id"]))
}
}

})
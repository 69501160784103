import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "message" }
const _hoisted_2 = { class: "desc" }
const _hoisted_3 = { class: "dl-btn" }

import { inject, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CustomFieldsApiService } from '@/api/user/resources/custom_fields';
import { KEY_OF_MODAL } from '@/injection-keys'
import { bitterAlert } from '@/plugins/BBitterAlert'
import EncodingService from '@/services/encoding_service'
import Api from '@/api/user'

type TEmit = {
  (event: 'refresh'): void;
};

const acceptDataType = '.xlsx'

export default /*@__PURE__*/_defineComponent({
  __name: 'CustomImportModal',
  emits: ["refresh"],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const i18n = useI18n()
const modal = inject(KEY_OF_MODAL)
const modalName = ref('customImportModal')
const loading = ref(false)
const save = ref(false)
const fileBlob = ref('')

const upload = async (file: string | Blob) => {
  loading.value = true
  const separatedFileName = file.name.split('.')
  const importFileExtension = `.${separatedFileName[separatedFileName.length - 1]}`

  if (importFileExtension !== acceptDataType) {
    await bitterAlert.show({
      text: i18n.t('import.errors.extension'),
      buttonsCancel: false,
    })
    loading.value = false
    return
  }
  const reader = new FileReader()
  reader.onload = async (e) => {
    const blob = new EncodingService().encodeArrayBufferToBlob(e.target.result)
    // 単位はbyte, 100kbを指定
    const MAX_FILE_SIZE = 100000
    if (blob.size > MAX_FILE_SIZE) {
      onFileUploadFailed()
      await bitterAlert.show({
        text: i18n.t('import.errors.maxFileSize', { max_file_size: `${MAX_FILE_SIZE / 1000}KB` }),
        buttonsCancel: false,
      })
      return false
    }
    const formData = new FormData()
    formData.append('file', file)
    fileBlob.value = formData
    save.value = true
  }
  reader.readAsArrayBuffer(file)
  loading.value = false
}

const saveButton = async () => {
  if (!fileBlob.value) {
    await bitterAlert.show({
      text: i18n.t('apiError.message.fileUploadError'),
      buttonsCancel: false,
    })
    loading.value = false
    save.value = false
    return
  }
  const customFieldsApi = new CustomFieldsApiService()
  customFieldsApi.import({
    options: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: fileBlob.value,
    },
  }).then((result) => {
    save.value = false
    loading.value = false
    emit('refresh')
    hide()
  }).catch(async (err) => {
    await bitterAlert.show({
      title: i18n.t('import.excel.errorTitle'),
      text: err.response?.data?.errorMessage,
      buttonsCancel: false,
    })
    loading.value = false
    return
  })
}

const dlButton = async () => {
  const fileName = 'custom_field_import_template.xlsx';
  Api.customFieldsImportTemplate({})
    .then((res: any) => {
      const blob = new Blob([res.data], { type: 'application/octet-stream' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = fileName
      link.click()
    })
}

const onFileUploadFailed = () => {
  save.value = false
  loading.value = false
}

const hide = () => {
  modal.hide(modalName.value)
}

return (_ctx: any,_cache: any) => {
  const _component_BModalHeader = _resolveComponent("BModalHeader")!
  const _component_BFileUploadArea = _resolveComponent("BFileUploadArea")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BModalBody = _resolveComponent("BModalBody")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BModalFooter = _resolveComponent("BModalFooter")!
  const _component_BHeightAdjuster = _resolveComponent("BHeightAdjuster")!
  const _component_BModal = _resolveComponent("BModal")!

  return (_openBlock(), _createBlock(_component_BModal, {
    "modal-name": modalName.value,
    height: "auto"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BHeightAdjuster, null, {
        default: _withCtx(() => [
          _createVNode(_component_BModalHeader, {
            title: _ctx.$t('customField.exportTitle'),
            center: "",
            onModalClose: hide
          }, null, 8, ["title"]),
          _createVNode(_component_BModalBody, null, {
            default: _withCtx(() => [
              _createVNode(_component_BLayout, {
                "justify-center": "",
                "align-center": "",
                "is-fit": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BFileUploadArea, {
                    ref: "fileUploadArea",
                    class: "mt-400 w-full",
                    "accept-data-type": acceptDataType,
                    "is-fit": "",
                    onUpload: upload
                  }, null, 512)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_BModalFooter, null, {
            default: _withCtx(() => [
              _createVNode(_component_BLayout, { "justify-center": "" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t(`customField.importTemplate.desc`)), 1),
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_BBtn, {
                        type: "primary",
                        onClick: dlButton
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t(`customField.importTemplate.download`)), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createVNode(_component_BBtn, {
                      loading: loading.value,
                      disabled: !save.value,
                      type: "primary",
                      onClick: saveButton
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t(`customField.import`)), 1)
                      ]),
                      _: 1
                    }, 8, ["loading", "disabled"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-name"]))
}
}

})
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "b-time-picker" }
const _hoisted_2 = {
  key: 0,
  class: "check-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElTimePicker = _resolveComponent("ElTimePicker")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BTooltip = _resolveComponent("BTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElTimePicker, _mergeProps($options.attributes, {
      modelValue: $options.internalValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.internalValue) = $event))
    }), null, 16, ["modelValue"]),
    _createVNode(_Transition, { name: "el-fade-in-linear" }, {
      default: _withCtx(() => [
        ($props.required)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (!$options.isValid)
                ? (_openBlock(), _createBlock(_component_BTooltip, {
                    key: 0,
                    top: "",
                    value: false
                  }, {
                    content: _withCtx(() => [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('validation.required')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_BIcon, {
                        size: "small",
                        class: "invalid"
                      }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode(" check_circle_outline ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_BIcon, {
                    key: 1,
                    size: "small",
                    class: "valid"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" check_circle ")
                    ])),
                    _: 1
                  }))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/error_page_image.svg'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "error-detail" }
const _hoisted_3 = { class: "status-code" }
const _hoisted_4 = { class: "status-message" }
const _hoisted_5 = { class: "message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", null, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        })
      ], -1)),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.error.statusCode), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.error.message), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.message), 1)
    ])
  ]))
}
<template>
  <div v-loading="$wait.is('confirmLeadDeleteTaskWait')">
    <ImportStatus
      v-for="task in sfLeadDataCheckTasks"
      :key="task.id"
      :target-task="task"
      :progress-message="$t('callTarget.sfCheckLead.taskStatus.progress')"
      :error-message="$t('callTarget.sfCheckLead.taskStatus.error')"
      :can-show-error-modal="false"
      :success-message="$t('callTarget.sfCheckLead.taskStatus.success')"
      @confirm="() => confirmTask(task.id)"
      @success="$emit('success')"
    />
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import ImportStatus from '@/components/organisms/user/leadImport/ImportStatus.vue'

export default {
  components: {
    ImportStatus,
  },
  emits: [
    'success',
  ],
  computed: {
    ...mapGetters('user', ['sfLeadDataCheckTasks']),
  },
  created() {
    this.getSfBulkCheckLeadDataTasksAction()
    this.timeObj = this.$setInterval(() => {
      this.getSfBulkCheckLeadDataTasksAction()
    }, 30000)
  },
  beforeUnmount() {
    this.$clearInterval(this.timeObj)
  },
  methods: {
    ...mapWaitingActions('user', {
      getSfBulkCheckLeadDataTasksAction: 'getSfBulkCheckLeadDataTasksWait',
      confirmSfBulkCheckLeadDataTaskAction: 'confirmSfBulkCheckLeadDataTaskWait',
    }),
    confirmTask(taskId) {
      this.confirmSfBulkCheckLeadDataTaskAction({ id: taskId })
    },
  },
}
</script>

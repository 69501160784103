import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-600" }
const _hoisted_2 = { class: "pt-600" }
const _hoisted_3 = { class: "headline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BFlex = _resolveComponent("BFlex")!
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_ClientsList = _resolveComponent("ClientsList")!
  const _component_BCard = _resolveComponent("BCard")!
  const _component_ClientForm = _resolveComponent("ClientForm")!
  const _component_FormDialog = _resolveComponent("FormDialog")!
  const _component_BContainer = _resolveComponent("BContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BContainer, null, {
      default: _withCtx(() => [
        (_ctx.$can('admin_owner'))
          ? (_openBlock(), _createBlock(_component_RouterLink, {
              key: 0,
              to: { name: 'AdminAdmins' }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(`adminManagement.title`)), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_RouterLink, {
          class: "ml-400",
          to: { name: 'AdminSendgridSubUsers' }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(`sendgridSubUserManagement.title`)), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BCard, { shadow: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_BLayout, null, {
                default: _withCtx(() => [
                  _createVNode(_component_BFlex, { span: 16 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BBtn, {
                        type: "primary",
                        onClick: _ctx.openClientNewDialog
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(`client.title`) + _ctx.$t(`general.register.text`)), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_BFlex, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BInput, {
                        modelValue: _ctx.searchWord,
                        "onUpdate:modelValue": [
                          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchWord) = $event)),
                          _ctx.resetCurrentPage
                        ],
                        class: "pl-600",
                        placeholder: _ctx.$t('clientManagement.search.placeholder')
                      }, null, 8, ["modelValue", "placeholder", "onUpdate:modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ClientsList, {
                "search-word": _ctx.searchWord,
                "current-page": _ctx.currentPage,
                onChangeCurrentPage: _ctx.changeCurrentPage
              }, null, 8, ["search-word", "current-page", "onChangeCurrentPage"])
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_FormDialog, {
          dialog: _ctx.clientNewDialog,
          width: "500"
        }, {
          form: _withCtx(() => [
            _createVNode(_component_ClientForm, {
              ref: "clientNewForm",
              loading: _ctx.$wait.is('createClientWait'),
              onSave: _ctx.createClient,
              onCancel: _ctx.closeClientNewDialog
            }, {
              title: _withCtx(() => [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(`client.title`) + _ctx.$t(`general.register.text`)), 1)
              ]),
              _: 1
            }, 8, ["loading", "onSave", "onCancel"])
          ]),
          _: 1
        }, 8, ["dialog"])
      ]),
      _: 1
    })
  ]))
}
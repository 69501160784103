import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "select-user" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUsers } from '@/composable/user/user/users';
import { TOptions } from '../atoms/BSelect.vue';

// NOTE: 多分このComponentはsrc/components/organisms以下に置く方が正しい
type TProps = {
  modelValue: number | undefined;
  placeholder?: string;
  useAll?: boolean;
  useAllLabel?: string;
  clearable?: boolean;
  onlyActiveUsers?: boolean;
};
type TEmit = {
  'update:modelValue': [modelValue: number | undefined];
};

const VALUE_ALL = 'all';

export default /*@__PURE__*/_defineComponent({
  __name: 'SelectUser',
  props: {
    modelValue: {},
    placeholder: { default: undefined },
    useAll: { type: Boolean, default: true },
    useAllLabel: {},
    clearable: { type: Boolean, default: false },
    onlyActiveUsers: { type: Boolean, default: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit
const i18n = useI18n();

const selectedUserId = computed({
  get() {
    return props.useAll && props.modelValue === undefined ? VALUE_ALL : props.modelValue
  },
  set(value: number | typeof VALUE_ALL) {
    emit('update:modelValue', value === VALUE_ALL ? undefined : value)
  },
})

const { users, activeUsers } = useUsers()
const userItems = computed(() => {
  const userItems: TOptions = props.useAll ? [{ text: props.useAllLabel ?? i18n.t('draftMailReminder.allOwner'), value: VALUE_ALL }] : []
  const targetUsers = props.onlyActiveUsers ? activeUsers : users;
  return userItems.concat(
    targetUsers.value.map((candidate) => {
      return { text: candidate.name, value: candidate.id }
    }),
  )
})

return (_ctx: any,_cache: any) => {
  const _component_BSelect = _resolveComponent("BSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BSelect, {
      modelValue: selectedUserId.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedUserId).value = $event)),
      items: userItems.value,
      clearable: _ctx.clearable,
      placeholder: _ctx.placeholder,
      "item-text": "text",
      "item-value": "value",
      filterable: "",
      class: "type-select"
    }, null, 8, ["modelValue", "items", "clearable", "placeholder"])
  ]))
}
}

})
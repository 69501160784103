import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "filter-box"
}

import NumberCustomFieldItem from '@/components/organisms/user/leadList/filter/detail/customFieldItem/NumberCustomFieldItem.vue';
import OptionCustomFieldItem from '@/components/organisms/user/leadList/filter/detail/customFieldItem/OptionCustomFieldItem.vue';
import TextCustomFieldItem from '@/components/organisms/user/leadList/filter/detail/customFieldItem/TextCustomFieldItem.vue';
import DateTimeFilterCommon, { TFilterParam } from '@/components/organisms/user/leadList/filter/detail/DateTimeFilterCommon.vue';

type TProps = {
  info: { dataType: string, name: string, value: string };
  customFieldItem: TFilterParam;
  fieldKey: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomFieldItem',
  props: {
    info: {},
    customFieldItem: {},
    fieldKey: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_BEmptyBox = _resolveComponent("BEmptyBox")!

  return (_ctx.info != null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.info.dataType === 'text')
          ? (_openBlock(), _createBlock(TextCustomFieldItem, {
              key: 0,
              title: _ctx.info.name,
              value: _ctx.info.value,
              "custom-field-item": _ctx.customFieldItem,
              "field-key": _ctx.fieldKey,
              "is-display-only": ""
            }, null, 8, ["title", "value", "custom-field-item", "field-key"]))
          : (_ctx.info.dataType === 'datetime')
            ? (_openBlock(), _createBlock(DateTimeFilterCommon, {
                key: 1,
                title: _ctx.info.name,
                value: _ctx.info.value,
                "key-name": _ctx.fieldKey,
                "filter-param": _ctx.customFieldItem,
                "is-custom": "",
                "is-display-only": ""
              }, null, 8, ["title", "value", "key-name", "filter-param"]))
            : (['option', 'multi_option'].includes(_ctx.info.dataType))
              ? (_openBlock(), _createBlock(OptionCustomFieldItem, {
                  key: 2,
                  title: _ctx.info.name,
                  value: _ctx.info.value,
                  "custom-field-item": _ctx.customFieldItem,
                  "field-key": _ctx.fieldKey,
                  "is-display-only": ""
                }, null, 8, ["title", "value", "custom-field-item", "field-key"]))
              : (_ctx.info.dataType === 'number')
                ? (_openBlock(), _createBlock(NumberCustomFieldItem, {
                    key: 3,
                    title: _ctx.info.name,
                    value: _ctx.info.value,
                    "custom-field-item": _ctx.customFieldItem,
                    "field-key": _ctx.fieldKey,
                    "is-display-only": ""
                  }, null, 8, ["title", "value", "custom-field-item", "field-key"]))
                : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createBlock(_component_BEmptyBox, {
        key: 1,
        class: "filter-b-empty-box",
        "display-only": ""
      }))
}
}

})
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "truncate-text" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "truncate-text" }
const _hoisted_5 = { class: "truncate-text" }
const _hoisted_6 = { class: "last-called-item" }
const _hoisted_7 = { class: "last-called-item" }
const _hoisted_8 = { class: "result-type" }
const _hoisted_9 = { class: "break-word-pre-wrap" }
const _hoisted_10 = {
  key: 8,
  class: "next-action"
}
const _hoisted_11 = {
  key: 9,
  class: "truncate-text"
}
const _hoisted_12 = {
  key: 10,
  class: "truncate-text"
}
const _hoisted_13 = {
  key: 11,
  class: "truncate-text"
}
const _hoisted_14 = {
  key: 12,
  class: "truncate-text"
}
const _hoisted_15 = {
  key: 13,
  class: "truncate-text"
}
const _hoisted_16 = {
  key: 14,
  class: "company-url truncate-text"
}
const _hoisted_17 = {
  key: 15,
  class: "truncate-text"
}
const _hoisted_18 = {
  key: 16,
  class: "truncate-text"
}
const _hoisted_19 = {
  key: 17,
  class: "truncate-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BCheckbox = _resolveComponent("BCheckbox")!
  const _component_BCustomIcon = _resolveComponent("BCustomIcon")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BPopover = _resolveComponent("BPopover")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BUserTooltip = _resolveComponent("BUserTooltip")!
  const _component_BEmptyBox = _resolveComponent("BEmptyBox")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BSfStatusIcon = _resolveComponent("BSfStatusIcon")!
  const _component_BNextActionLabel = _resolveComponent("BNextActionLabel")!
  const _component_BLeadStageColorPreview = _resolveComponent("BLeadStageColorPreview")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_DuplicateStateButton = _resolveComponent("DuplicateStateButton")!

  return (_openBlock(), _createBlock(_component_BLayout, {
    class: "lead-list-row",
    "align-center": "",
    style: _normalizeStyle($options.getStyles())
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "lead-list-item checkbox",
        style: _normalizeStyle($options.getStyles()),
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
      }, [
        ($props.modelValue)
          ? (_openBlock(), _createBlock(_component_BCheckbox, {
              key: 0,
              class: "mt-0",
              "model-value": $props.modelValue.checked,
              value: $props.modelValue.value,
              onChange: $options.handleCheckboxChange
            }, null, 8, ["model-value", "value", "onChange"]))
          : _createCommentVNode("", true)
      ], 4),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leadListSelectedColumns, (column) => {
        return (_openBlock(), _createElementBlock("div", {
          key: column,
          class: _normalizeClass(["lead-list-item", $options.getClassNameByKey(column)]),
          style: _normalizeStyle($options.getStyles(column))
        }, [
          (column === 'lastOpportunity')
            ? (_openBlock(), _createBlock(_component_BPopover, {
                key: 0,
                placement: "top",
                width: "200",
                trigger: "hover"
              }, {
                reference: _withCtx(() => [
                  _createVNode(_component_BCustomIcon, {
                    "icon-class": "b-fire",
                    type: $options.getIconType($props.callTarget.lastOpportunity)
                  }, null, 8, ["type"])
                ]),
                default: _withCtx(() => [
                  ($props.callTarget.lastOpportunity)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createVNode(_component_BListItem, { class: "mb-200" }, {
                          header: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('opportunity.scheduled')), 1),
                            _createElementVNode("span", null, _toDisplayString($options.getDateAndTimeWithFallBack($props.callTarget.lastOpportunity.startedAt)), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_BListItem, { class: "mb-200" }, {
                          header: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('opportunity.executedAt')), 1),
                            _createElementVNode("span", null, _toDisplayString($options.getDateAndTimeWithFallBack($props.callTarget.lastOpportunity.executedAt)), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_BListItem, { class: "mb-200" }, {
                          header: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('opportunity.amount')), 1),
                            _createElementVNode("span", null, _toDisplayString($options.getTextWithFallBack($props.callTarget.lastOpportunity.amount)), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_BListItem, null, {
                          header: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('opportunity.memo')), 1)
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_2, _toDisplayString($options.getTextWithFallBack($props.callTarget.lastOpportunity.memo)), 1)
                          ]),
                          _: 1
                        })
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('opportunity.blank')), 1)
                      ]))
                ]),
                _: 1
              }))
            : (column === 'lastActivityTypeText')
              ? (_openBlock(), _createBlock(_component_BLayout, {
                  key: 1,
                  "align-center": "",
                  wrap: ""
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString($options.getTextWithFallBack($props.callTarget.lastActivityTypeText)), 1)
                  ]),
                  _: 1
                }))
              : (column === 'lastActivityAt')
                ? (_openBlock(), _createBlock(_component_BLayout, { key: 2 }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, _toDisplayString($options.getDateAndTimeWithFallBack($props.callTarget.lastActivityAt)), 1)
                    ]),
                    _: 1
                  }))
                : (column === 'lastActivityComment')
                  ? (_openBlock(), _createBlock(_component_BLayout, {
                      key: 3,
                      "align-center": "",
                      wrap: ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, _toDisplayString($options.getTextWithFallBack($props.callTarget.lastActivityComment)), 1)
                      ]),
                      _: 1
                    }))
                  : (column === 'lastCalledAt')
                    ? (_openBlock(), _createBlock(_component_BLayout, {
                        key: 4,
                        class: "last-called-at",
                        "align-center": "",
                        wrap: ""
                      }, {
                        default: _withCtx(() => [
                          ($props.callTarget.lastCalledAt == null)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(" - ")
                              ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createElementVNode("div", _hoisted_6, _toDisplayString($options.getDateAndTimeWithFallBack($props.callTarget.lastCalledAt)), 1),
                                _createElementVNode("div", _hoisted_7, [
                                  _createElementVNode("span", _hoisted_8, _toDisplayString($options.getTextWithFallBack($props.callTarget.callResult)), 1),
                                  _createVNode(_component_BPopover, {
                                    placement: "top",
                                    width: "200",
                                    trigger: "hover"
                                  }, {
                                    reference: _withCtx(() => [
                                      _createVNode(_component_BIcon, {
                                        type: $options.getIconType($props.callTarget.lastCalledComment)
                                      }, {
                                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                                          _createTextVNode(" mode_comment ")
                                        ])),
                                        _: 1
                                      }, 8, ["type"])
                                    ]),
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_9, _toDisplayString($props.callTarget.lastCalledComment || _ctx.$t('callResult.comment.blank')), 1)
                                    ]),
                                    _: 1
                                  })
                                ])
                              ], 64))
                        ]),
                        _: 1
                      }))
                    : (column === 'operatorName')
                      ? (_openBlock(), _createBlock(_component_BBtn, {
                          key: 5,
                          fab: "",
                          flat: "",
                          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($options.openSetOperatorModal($props.callTarget.callTargetId, $props.callTarget.operatorId)), ["stop"]))
                        }, {
                          default: _withCtx(() => [
                            ($props.callTarget.operatorName)
                              ? (_openBlock(), _createBlock(_component_BUserTooltip, {
                                  key: 0,
                                  "image-url": $props.callTarget.operatorAvatarUrl,
                                  name: $props.callTarget.operatorName,
                                  "user-id": $props.callTarget.operatorId,
                                  size: 40
                                }, null, 8, ["image-url", "name", "user-id"]))
                              : (_openBlock(), _createBlock(_component_BEmptyBox, {
                                  key: 1,
                                  class: "empty-box"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_BIcon, { size: "small" }, {
                                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                                        _createTextVNode(" add ")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }))
                          ]),
                          _: 1
                        }))
                      : (column === 'connectStatus' && $options.sfConnectedStatus)
                        ? (_openBlock(), _createBlock(_component_BSfStatusIcon, {
                            key: 6,
                            "connect-status": $options.sfConnectedStatus,
                            onOpenSfModal: _cache[2] || (_cache[2] = ($event: any) => ($options.openSfModal($props.callTarget.sfRelatedLinkInfo)))
                          }, null, 8, ["connect-status"]))
                        : (column === 'nextActions' && $props.callTarget.nextActions.length > 0)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 7 }, _renderList($props.callTarget.nextActions, (nextAction) => {
                              return (_openBlock(), _createBlock(_component_BNextActionLabel, {
                                key: `nextAction-${nextAction.id}`,
                                class: "next-action",
                                "next-action": nextAction
                              }, null, 8, ["next-action"]))
                            }), 128))
                          : (column === 'nextActions' && $props.callTarget.nextActions.length === 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, " - "))
                            : (column === 'leadSources')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString($options.leadSourcesText), 1))
                              : (column === 'lastLeadStageName')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                    _createVNode(_component_BLeadStageColorPreview, {
                                      value: $props.callTarget.lastLeadStageColorName,
                                      size: "small",
                                      class: "mr-50"
                                    }, null, 8, ["value"]),
                                    _createTextVNode(" " + _toDisplayString($options.getTextWithFallBack($options.getColumnValue(column))), 1)
                                  ]))
                                : (column === 'lastLeadStageStayDays')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString($props.callTarget.lastLeadStageStayDays), 1))
                                  : (column === 'freeTags')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString($options.freeTagsText), 1))
                                    : (column === 'callTargetCreatedAt')
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString($options.callTargetCreatedAtText), 1))
                                      : (column === 'companyUrl')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                            _createElementVNode("span", null, _toDisplayString($options.getTextWithFallBack($props.callTarget[column])), 1),
                                            _createVNode(_component_BTooltip, {
                                              top: "",
                                              content: _ctx.$t('callTarget.search.url')
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_BIcon, {
                                                  class: "url-icon",
                                                  size: "mini",
                                                  onClick: _withModifiers(() => $options.openUrl($props.callTarget[column]), ["stop"])
                                                }, {
                                                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                                                    _createTextVNode(" open_in_new ")
                                                  ])),
                                                  _: 2
                                                }, 1032, ["onClick"])
                                              ]),
                                              _: 2
                                            }, 1032, ["content"])
                                          ]))
                                        : (column === 'isEmailDuplicated' && $props.callTarget.isEmailDuplicated)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                              _createVNode(_component_DuplicateStateButton, {
                                                "duplicate-key": "duplicateEmail",
                                                "duplicate-value": $props.callTarget.email
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.$t('general.isDuplicated')), 1)
                                                ]),
                                                _: 1
                                              }, 8, ["duplicate-value"])
                                            ]))
                                          : (column === 'isEmailDuplicated' && !$props.callTarget.isEmailDuplicated)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_18, " - "))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString($options.getTextWithFallBack($options.getColumnValue(column))), 1))
        ], 6))
      }), 128)),
      _createElementVNode("div", {
        class: "lead-list-item end-column",
        style: _normalizeStyle($options.getStyles())
      }, null, 4)
    ]),
    _: 1
  }, 8, ["style"]))
}
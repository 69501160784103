import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { TSize, TSymbolColor } from '../../types';
import leadStageColors from './lead_stage_colors';

type TProps = {
  size?: TSize;
  value: TSymbolColor;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BLeadStageColorPreview',
  props: {
    size: {},
    value: {}
  },
  emits: ['click'],
  setup(__props: any) {

const props = __props;


const colorCode = computed(() => leadStageColors.find(c => c.value === props.value)?.colorCode);

return (_ctx: any,_cache: any) => {
  const _component_BColorPreview = _resolveComponent("BColorPreview")!

  return (_openBlock(), _createBlock(_component_BColorPreview, {
    "color-code": colorCode.value,
    size: _ctx.size,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, null, 8, ["color-code", "size"]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "email-header" }
const _hoisted_2 = { class: "ml-100" }

import { computed, ref, watch } from 'vue';
import { Email } from '@/api/openapi/models/email';

type TProps = {
  modelValue: Email[];
  label: string;
};
type TEmit = {
  (event: 'update:modelValue', value: Email[]): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MailAddressList',
  props: {
    modelValue: {},
    label: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const isVisible = ref(false);

watch(() => props.modelValue, (newVal) => {
  isVisible.value = newVal.length > 0;
}, { immediate: true });
watch(isVisible, (newVal) => {
  if (newVal === false) resetEmail();
});

const emails = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v),
});

const addEmail = (email: string) => {
  emails.value = [...emails.value, { email: email.trim() || '' }];
};
const removeEmail = (index: number) => {
  emails.value = emails.value.filter((_, i) => i !== index);
};
const resetEmail = () => {
  emails.value = [];
};

return (_ctx: any,_cache: any) => {
  const _component_BCheckbox = _resolveComponent("BCheckbox")!
  const _component_BMultipleInput = _resolveComponent("BMultipleInput")!
  const _component_BListItem = _resolveComponent("BListItem")!

  return (_openBlock(), _createBlock(_component_BListItem, { class: "mb-600" }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BCheckbox, {
          modelValue: isVisible.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isVisible).value = $event))
        }, null, 8, ["modelValue"]),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_BMultipleInput, {
        visible: isVisible.value,
        placeholder: _ctx.$t('mail.mailAddress'),
        items: emails.value,
        "input-type": "email",
        "unique-key": "email",
        "max-item-count": 10,
        "item-validate-rules": ['email'],
        onAddItem: addEmail,
        onRemoveItem: removeEmail
      }, null, 8, ["visible", "placeholder", "items"])
    ]),
    _: 1
  }))
}
}

})
<template>
  <div class="b-select-menu">
    <BMenu v-bind="attributes">
      <template #activator>
        <div>
          <BLayout>
            <BBtn
              size="small"
              flat
              fab
              :data-test="dataTest"
            >
              <BIcon size="small">
                add
              </BIcon>
            </BBtn>
          </BLayout>
        </div>
      </template>
      <BCard
        v-loading="loading"
        class="b-select-menu-body"
        full
      >
        <BInput
          v-model="searchText"
          class="b-select-search ml-200 mb-300"
          :placeholder="$t('filterSelect.placeholder')"
        />
        <div v-if="fitlerOptions.length > 0">
          <div
            v-for="option in fitlerOptions"
            :key="option[itemValue]"
            class="b-select-menu-item"
            :class="{'selected': option.selected}"
            @click="() => handleClick(option)"
          >
            <div class="menu-item-icon">
              <BIcon
                v-if="option.selected"
                size="mini"
                type="primary"
              >
                check
              </BIcon>
            </div><span>{{ option.text }}</span>
          </div>
        </div>
        <div
          v-else
          class="text-annotation b-select-empty"
        >
          {{ $t('general.noCandidate') }}
        </div>
      </BCard>
    </BMenu>
  </div>
</template>

<script>
export default {
  props: {
    itemValue: {
      type: String,
      default: 'id',
    },
    itemText: {
      type: String,
      default: 'name',
    },
    candidates: {
      type: Array,
      default () {
        return []
      },
    },
    selectedIds: {
      type: Array,
      default () {
        return []
      },
    },
    loading: Boolean,
    dataTest: String,
    placement: {
      type: String,
      default: 'right',
    },
  },
  emits: [
    'click-delete',
    'click-append',
  ],
  data () {
    return {
      searchText: '',
    }
  },
  computed: {
    attributes () {
      return {
        width: '280px',
        placement: this.placement,
      }
    },
    fitlerOptions () {
      return this.options.filter(item => item.text.includes(this.searchText))
    },
    options () {
      if (!this.candidates.length) return [{ text: this.$t('general.noCandidate'), id: 0 }]
      return this.candidates.map(c => {
        return { text: c[this.itemText], id: c.id, selected: this.selectedIds.includes(c.id) }
      })
    },
  },
  methods: {
    handleClick (option) {
      if (option.id === 0) return
      if (option.selected) {
        this.$emit('click-delete', option[this.itemValue])
      } else {
        this.$emit('click-append', option[this.itemValue])
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .b-select-search {
    width: calc(100% - 36px);
    margin-left: 18px;
    margin-right: 18px;
  }

  .b-select-empty {
    text-align: center;
  }

  .b-select-menu-body {
    padding: $basespace-300 0;
    overflow-y: auto;
    max-height: 95vh;
    min-width: 230px;
  }

  .b-select-menu-item {
    display: flex;
    align-content: center;
    padding: $basespace-100 $basespace-300;
    cursor: pointer;
    transition: $transition-base;

    &:hover {
      background-color: $bgcolor-base;
    }

    &.selected {
      color: $basecolor-primary;
    }

    .menu-item-icon {
      display: flex;
      align-content: center;
      @include m-fixed-width(20px);
    }
  }
</style>

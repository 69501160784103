import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-300 mb-200"
}
const _hoisted_2 = { class: "error-text" }
const _hoisted_3 = {
  key: 1,
  class: "miitel-url mt-200"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 2,
  class: "zoom-phone-call-id mt-200"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BDatePicker = _resolveComponent("BDatePicker")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BTimePicker = _resolveComponent("BTimePicker")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BListItem, { class: "mb-100" }, {
      header: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('callResult.callDate')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_BDatePicker, _mergeProps(_ctx.dateAttributes, {
          modelValue: _ctx.internalDate,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalDate) = $event))
        }), null, 16, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_BLayout, {
      "align-center": "",
      "justify-center": "",
      class: "mb-2"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BListItem, { class: "mr-100" }, {
          header: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('callResult.startTime')), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_BTimePicker, _mergeProps(_ctx.timeAttributes, {
              modelValue: _ctx.internalStartedAt,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.internalStartedAt) = $event))
            }), null, 16, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_BListItem, { class: "ml-100" }, {
          header: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('callResult.endTime')), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_BTimePicker, _mergeProps(_ctx.timeAttributes, {
              modelValue: _ctx.internalEndedAt,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.internalEndedAt) = $event))
            }), null, 16, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.timeError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('callResult.timeError')), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.miitelResultUrl)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("img", {
            class: "miitel-logo",
            src: require('@/assets/miitel.png')
          }, null, 8, _hoisted_4),
          _createElementVNode("a", {
            class: "miitel-text",
            href: _ctx.miitelResultUrl,
            target: "_blank",
            rel: "noopener noreferrer"
          }, _toDisplayString(_ctx.miitelResultUrl), 9, _hoisted_5)
        ]))
      : _createCommentVNode("", true),
    (_ctx.zoomPhoneCallId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Zoom Phone Call ID: " + _toDisplayString(_ctx.zoomPhoneCallId), 1))
      : _createCommentVNode("", true)
  ]))
}
import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { LeadStage, PriorityPriorityEnum } from '@/api/openapi';
import BPriorityColorPreview from './BPriorityColorPreview.vue';

export type TDisplayLeadStage = Pick<LeadStage, 'name' | 'colorName'>;
type TProps = {
  priority?: PriorityPriorityEnum;
  truncateText?: boolean;
  size?: 'micro' | 'small';
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BPriorityPreview',
  props: {
    priority: { default: null },
    truncateText: { type: Boolean, default: true },
    size: { default: 'small' }
  },
  setup(__props: any) {

const props = __props;

const i18n = useI18n();

const priorityName = computed(() => i18n.t(`general.priority.${props.priority}`));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["priority-preview", [_ctx.size]])
  }, [
    (_ctx.priority != null)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(BPriorityColorPreview, {
            size: _ctx.size,
            priority: _ctx.priority
          }, null, 8, ["size", "priority"]),
          _createElementVNode("span", {
            class: _normalizeClass(["priority-name", {'truncate-text': _ctx.truncateText}])
          }, _toDisplayString(priorityName.value), 3)
        ], 64))
      : (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(["placeholder", {'truncate-text': _ctx.truncateText}])
        }, "-", 2))
  ], 2))
}
}

})
<template>
  <div
    class="b-divider"
    :class="classes"
  />
</template>

<script>
export default {
  props: {
    light: Boolean,
    direction: {
      type: String,
      default: 'horizontal',
    },
  },
  computed: {
    classes () {
      return {
        'b-divider-light': this.light,
        'is-vertical': this.direction === 'vertical',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .b-divider {
    display: block;
    height: 1px;
    width: 100%;
    background-color: $bdcolor-base;

    &-light {
      background-color: $bdcolor-light;
    }

    &.is-vertical {
      height: 40%;
      width: 1px;
      margin: 0 5px;
    }
  }
</style>

import { Module } from 'vuex'
import { RootState, ZoomPhoneState } from '../types'
import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'

const store: Module<ZoomPhoneState, RootState> = {
  namespaced: true,
  state: {
    zoomSetting: null,
    isCalling: false,
    callInfo: {
      id: null,
      direction: null,
      startedAt: null,
      connectedAt: null,
      endedAt: null,
      result: null,
    },
  },
  mutations,
  getters,
  actions,
}

export default store

<template>
  <ElCheckbox
    v-bind="attributes"
    v-model="isChecked"
    :class="classes"
    :style="styles"
    @change="updateValue"
  >
    <slot
      v-if="$slots.label"
      name="label"
    />
    <template v-else>
      {{ label }}
    </template>
  </ElCheckbox>
</template>

<script>
export default {
  props: {
    value: [String, Number, Boolean],
    label: String,
    modelValue: null,
    indeterminate: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
    'change',
  ],
  data () {
    return {
      internalValue: this.modelValue,
    }
  },
  computed: {
    attributes () {
      return {
        label: this.label,
        indeterminate: this.indeterminate,
        disabled: this.disabled,
        readonly: this.readonly,
      }
    },
    classes () {
      return {
        'is-center': this.center,
      }
    },
    styles () {
      return {
        'pointer-events': this.readonly ? 'none' : undefined,
        'justify-content': this.center ? 'center' : undefined,
      }
    },
    isChecked: {
      get() {
        if (Array.isArray(this.internalValue)) {
          return this.internalValue.includes(this.value)
        }
        return !!this.internalValue
      },
      set(value) {
        let val
        if (Array.isArray(this.internalValue) && value) {
          val = [...this.internalValue, this.value]
        } else if (Array.isArray(this.internalValue) && !value) {
          val = this.internalValue.filter(v => v !== this.value)
        } else {
          val = value
        }
        this.internalValue = val
      },
    },
  },
  watch: {
    modelValue (val) {
      this.internalValue = val
    },
  },
  methods: {
    updateValue () {
      this.$emit('update:modelValue', this.internalValue)
      this.$emit('change', this.internalValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.el-checkbox {
  display: flex;
  align-items: center;
  white-space: normal;
  margin-right: 8px;
  height: auto;
}
:deep(.el-checkbox__input) {
  padding: 4px;
  line-height: 0;
  &.is-focus:not(.is-checked) {
    .el-checkbox__inner{
      border-color: $bdcolor-base;
    }
  }
}
:deep(.el-checkbox__label) {
  font-size: $fontsize-200;
  color: $textcolor-base;
}
</style>

<template>
  <BContentBox>
    <template #content>
      <BLayout
        v-loading="loading"
        column
        class="form"
      >
        <BListItem>
          <span class="title">{{ $t('mfaSetting.title') }}</span>
          <span
            v-if="isConnect"
            class="defined"
          >{{ $t('general.defined') }}</span>
          <span
            v-else
            class="not-defined"
          >{{ $t('general.notDefined') }}</span>
        </BListItem>
        <BListItem class="explain mt-400">
          {{ $t('userSecurity.mfaSetting.explain') }}
        </BListItem>
        <BListItem
          v-if="isConnect"
          class="mt-400"
        >
          <BLayout>
            <BInput
              v-model="factor"
              class="w-input"
              clearable
              :disabled="true"
            />
            <span class="more-menu">
              <BMoreMenu>
                <BBtn
                  flat
                  fit
                  size="small"
                  :disabled="loading"
                  @click="unconnect"
                >
                  <span>{{ $t('userSecurity.mfaSetting.disconnect') }}</span>
                </BBtn>
              </BMoreMenu>
            </span>
          </BLayout>
        </BListItem>
        <BListItem
          v-else
          class="mt-400"
        >
          <BBtn
            type="primary"
            outline="true"
            @click="modal.show(MFA_MODAL_NAME)"
          >
            {{ $t('general.setting.do') }}
          </BBtn>
        </BListItem>
      </BLayout>
      <MfaModal
        :modal-name="MFA_MODAL_NAME"
        @close="modal.hide(MFA_MODAL_NAME)"
        @authenticated="handleAuthenticated"
      />
    </template>
  </BContentBox>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref, computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import MfaModal from '@/components/organisms/user/general/modal/MfaModal.vue'
import { useMfaSetting } from '@/composable/user/mfaSetting/mfa-setting';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { KEY_OF_MODAL, KEY_OF_TOAST } from '@/injection-keys';
import { bitterAlert } from '@/plugins/BBitterAlert';

const MFA_MODAL_NAME = 'mfaModal';

const i18n = useI18n();
const router = useRouter();
const { currentUser, fetchCurrentUser } = useCurrentUser();
const { doActionWithWait, wait } = useWait();
const { mfaSetting, fetchMfaSetting, deleteMfaSetting } = useMfaSetting();
const modal = inject(KEY_OF_MODAL);
const toast = inject(KEY_OF_TOAST);

const loading = computed(() =>
  wait.is(['fetchMfaSettingWait', 'deleteMfaSettingWait']),
);
const isConnect = computed(() =>
  currentUser.value?.mfaAuthenticated,
);
const factor = computed(() =>
  mfaSetting.value?.factor,
);

onBeforeMount(async () => {
  fetch();
});

const fetch = async () => {
  await doActionWithWait('fetchMfaSettingWait', async () => {
    await fetchCurrentUser();
    await fetchMfaSetting();
  });
};

const unconnect = async () => {
  const check = await bitterAlert.show({
    text: i18n.t('userSecurity.mfaSetting.mfaSettingDeleteConfirm'),
  })
  if (!check) { return }

  await doActionWithWait('deleteMfaSettingWait', async () => {
    await deleteMfaSetting();
  });

  router.push({ name: 'UserSignin' });
};

const handleAuthenticated = async () => {
  fetch();
  toast.show(i18n.t('userSecurity.mfaSetting.mfaSettingComplete'));
  modal.hide(MFA_MODAL_NAME);
};
</script>

<style lang="scss" scoped>
.form {
  @media only screen and (min-width: 959px) {
    .w-input {
      width: 40%;
    }
  }
}
.title {
  font-size: $fontsize-400;
  font-weight: bold;
  line-height: $fontsize-400;
  vertical-align: middle;
}
.explain {
  color: $textcolor-light;
  white-space: pre-wrap;
}
.not-defined {
  border-radius: 2px;
  border: 1px solid $concrete-dark;
  padding: 4px 8px;
  background-color: $concrete-light;
  color: $concrete-dark;
  font-size: 12px;
  font-weight: normal;
  line-height: 12px;
  margin-left: $basespace-100;
}
.defined {
  border-radius: 2px;
  padding: 4px 8px;
  background-color: $concrete-dark;
  color: $paper;
  font-size: 12px;
  font-weight: normal;
  line-height: 12px;
  margin-left: $basespace-100;
}
.more-menu {
  display: flex;
  align-items: center;
}
</style>

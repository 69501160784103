import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "signin-card" }
const _hoisted_2 = { class: "signin-card-inner" }
const _hoisted_3 = { class: "signin-symbol-icon" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "signin-logo" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "signin-form" }
const _hoisted_8 = { class: "mb-200" }
const _hoisted_9 = { class: "mb-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_MfaModal = _resolveComponent("MfaModal")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", {
    class: "signin-container",
    style: _normalizeStyle(_ctx.styles)
  }, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.symbolIconSrc,
            height: "103"
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("img", {
            src: _ctx.logoSrc,
            style: {"width":"100%","height":"60px"}
          }, null, 8, _hoisted_6)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("form", {
            onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.signin && _ctx.signin(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_8, [
              _cache[7] || (_cache[7] = _createElementVNode("label", { for: "email" }, null, -1)),
              _createVNode(_component_BInput, {
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                valid: _ctx.emailValid,
                "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.emailValid) = $event)),
                placeholder: _ctx.$t('auth.email'),
                color: "focus",
                "data-test": "email",
                "input-id": "email"
              }, null, 8, ["modelValue", "valid", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[8] || (_cache[8] = _createElementVNode("label", { for: "password" }, null, -1)),
              _createVNode(_component_BInput, {
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event)),
                valid: _ctx.passWordValid,
                "onUpdate:valid": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.passWordValid) = $event)),
                type: _ctx.passwordShow ? 'text' : 'password',
                name: "password",
                placeholder: _ctx.$t('auth.password'),
                color: "focus",
                "data-test": "password",
                "input-id": "password",
                "onClick:append": _cache[4] || (_cache[4] = ($event: any) => (_ctx.passwordShow = !_ctx.passwordShow)),
                onKeypressEnter: _ctx.signin
              }, null, 8, ["modelValue", "valid", "type", "placeholder", "onKeypressEnter"])
            ]),
            _createVNode(_component_BLayout, {
              class: "mb-300",
              "justify-center": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BBtn, {
                  class: "signin-btn",
                  type: "primary",
                  "data-test": "signinBtn",
                  "btn-type": "submit",
                  disabled: !_ctx.emailValid || !_ctx.passWordValid,
                  onClick: _withModifiers(_ctx.signin, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('auth.signin')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ]),
              _: 1
            })
          ], 32),
          _createVNode(_component_BLayout, {
            "align-center": "",
            "justify-center": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BBtn, { text: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_RouterLink, { to: { name: 'UserResetPassword' } }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('auth.resetPassword')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]),
      _createVNode(_component_MfaModal, {
        "modal-name": _ctx.mfaModalName,
        "multi-factor-error": _ctx.multiFactorError,
        onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$modal.hide(_ctx.mfaModalName))),
        onAuthenticated: _ctx.handleAuthenticated
      }, null, 8, ["modal-name", "multi-factor-error", "onAuthenticated"])
    ])), [
      [_directive_loading, _ctx.$wait.is('USER_SIGNIN_WAIT')]
    ])
  ], 4))
}
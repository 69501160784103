import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "b-select-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "check-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElOption = _resolveComponent("ElOption")!
  const _component_ElOptionGroup = _resolveComponent("ElOptionGroup")!
  const _component_ElSelect = _resolveComponent("ElSelect")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BTooltip = _resolveComponent("BTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElSelect, _mergeProps(_ctx.attributes, {
      modelValue: _ctx.internalValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
      class: _ctx.classes,
      onChange: _ctx.changeItem,
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus'))),
      onClear: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('clear')))
    }), {
      prefix: _withCtx(() => [
        _renderSlot(_ctx.$slots, "prefix", {}, undefined, true)
      ]),
      default: _withCtx(() => [
        (_ctx.groups.length >= 1)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.groups, (group) => {
              return (_openBlock(), _createBlock(_component_ElOptionGroup, {
                key: group.key,
                label: group.label
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.options, (item) => {
                    return (_openBlock(), _createBlock(_component_ElOption, _mergeProps({
                      key: _ctx.optionKey(item),
                      ref_for: true
                    }, _ctx.optionAttrs(item)), {
                      default: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "optionTemplate", { item: item }, undefined, true)
                      ]),
                      _: 2
                    }, 1040))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["label"]))
            }), 128))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.items, (item) => {
              return (_openBlock(), _createBlock(_component_ElOption, _mergeProps({
                key: _ctx.optionKey(item),
                ref_for: true
              }, _ctx.optionAttrs(item)), {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "optionTemplate", { item: item }, undefined, true)
                ]),
                _: 2
              }, 1040))
            }), 128))
      ]),
      _: 3
    }, 16, ["modelValue", "class", "onChange"]),
    _createVNode(_Transition, { name: "el-fade-in-linear" }, {
      default: _withCtx(() => [
        (_ctx.required && !_ctx.disabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (!_ctx.internalValid)
                ? (_openBlock(), _createBlock(_component_BTooltip, {
                    key: 0,
                    top: "",
                    value: false
                  }, {
                    content: _withCtx(() => [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('validation.required')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_BIcon, {
                        size: "small",
                        class: "invalid"
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode(" check_circle_outline ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_BIcon, {
                    key: 1,
                    size: "small",
                    class: "valid"
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode(" check_circle ")
                    ])),
                    _: 1
                  }))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "pl-600 pb-100" }
const _hoisted_3 = { class: "header-text" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "mapping-key-text" }

import { onMounted, ref } from 'vue';
import { SfObjectMappingService } from '@/api/user/resources/salesforce/sf_object_mapping';


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectObject',
  emits: ['selectObject'],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const apiService = new SfObjectMappingService();
const salesforceSettingLoading = ref(true);
const sfObjectMappingsKeys = ref([]);
const selectObject = (object: string) => {
  emit('selectObject', object);
}

onMounted(async () => {
  const result = await apiService.getSfObjectMappingsKeys();
  sfObjectMappingsKeys.value = result.data.sfObjectMappingsKeys;
  salesforceSettingLoading.value = false;
})

return (_ctx: any,_cache: any) => {
  const _component_BContentBox = _resolveComponent("BContentBox")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_BContentBox, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("sfObjectMappings.targetObjectLabel")), 1)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "hr" }, null, -1)),
        _withDirectives((_openBlock(), _createElementBlock("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sfObjectMappingsKeys.value, (mappingKey, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "py-300 pl-600 select-object",
              onClick: () => selectObject(mappingKey)
            }, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t(`sfObjectMappings.${mappingKey}`)), 1)
            ], 8, _hoisted_4))
          }), 128))
        ])), [
          [_directive_loading, salesforceSettingLoading.value]
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "hr" }, null, -1))
      ])
    ]),
    _: 1
  }))
}
}

})
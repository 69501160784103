<template>
  <BModal
    :modal-name="modalName"
    full
  >
    <LeadOverwriteModal
      :title="$t('overwrite.csv.title')"
      :modal-name="modalName"
    />
  </BModal>
</template>

<script>
import LeadOverwriteModal from '@/components/organisms/user/leadList/modal/LeadOverwriteModal.vue'

export default {
  components: {
    LeadOverwriteModal,
  },
  computed: {
    modalName() {
      return 'CsvOverwriteModal'
    },
  },
}
</script>

import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "lead-filter-modal-body" }
const _hoisted_2 = {
  key: 1,
  class: "empty flex-3"
}

import FilterControlPanelBody from '@/components/organisms/user/leadList/filter/FilterControlPanelBody.vue';
import FilterSelectCard from '@/components/organisms/user/leadList/filter/FilterSelectCard.vue';

type TProps = {
  filterParams: object;
  modalName: string;
};
type TEmits = {
  'close': [];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'LeadFilterModal',
  props: {
    filterParams: {},
    modalName: {}
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {


const emit = __emit;

const handleClose = () => {
  emit('close');
};

return (_ctx: any,_cache: any) => {
  const _component_BModalHeader = _resolveComponent("BModalHeader")!
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_BModalBody = _resolveComponent("BModalBody")!
  const _component_BHeightAdjuster = _resolveComponent("BHeightAdjuster")!
  const _component_BModal = _resolveComponent("BModal")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BModal, {
      "modal-name": _ctx.modalName,
      width: "70%",
      height: "70%"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BHeightAdjuster, null, {
          default: _withCtx(() => [
            _createVNode(_component_BModalHeader, {
              title: _ctx.$t('trigger.eventSetting.filterParams'),
              onModalClose: handleClose
            }, null, 8, ["title"]),
            _createVNode(_component_BModalBody, { full: "" }, {
              waiting: _withCtx(() => [
                _withDirectives(_createVNode(_component_BPlaceholder, {
                  height: "100%",
                  "element-loading-background": "transparent"
                }, null, 512), [
                  [_directive_loading, true]
                ])
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(FilterSelectCard, { class: "flex-2" }),
                  (Object.keys(_ctx.filterParams).length > 0)
                    ? (_openBlock(), _createBlock(FilterControlPanelBody, {
                        key: 0,
                        "lead-list-filter-params": _ctx.filterParams,
                        "use-store-lead-list": false,
                        class: "flex-3"
                      }, null, 8, ["lead-list-filter-params"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('trigger.eventSetting.emptyFilterParams')), 1))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modal-name"])
  ]))
}
}

})
import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import {
  DirectMail,
  DirectMailApi,
  DirectMailApiCancelReservationDirectMailRequest,
  DirectMailApiDeleteDirectMailRequest,
  DirectMailApiGetDirectMailRequest,
  DirectMailApiGetDraftItemsRequest,
  DirectMailApiGetReserveItemsRequest,
  DirectMailApiPostDirectMailRequest,
  DirectMailApiPutDirectMailRequest,
  DirectMailApiReserveDirectMailRequest,
  GetDraftItems200Response,
  GetReserveItems200Response,
} from '@/api/openapi';

export class DirectMailApiService extends ApiService {
  api = new DirectMailApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true)
  }

  async getDraftItems({
    request,
    options,
    errorHandlers,
  }: ApiArguments<DirectMailApiGetDraftItemsRequest>): Promise<AxiosResponse<GetDraftItems200Response>> {
    return this.checkResponse<GetDraftItems200Response>(
      this.api.getDraftItems(request, options),
      errorHandlers,
    );
  }

  async getReserveItems({
    request,
    options,
    errorHandlers,
  }: ApiArguments<DirectMailApiGetReserveItemsRequest>): Promise<AxiosResponse<GetReserveItems200Response>> {
    return this.checkResponse<GetReserveItems200Response>(
      this.api.getReserveItems(request, options),
      errorHandlers,
    );
  }

  async getDirectMail({
    request,
    options,
    errorHandlers,
  }: ApiArguments<DirectMailApiGetDirectMailRequest>): Promise<AxiosResponse<DirectMail>> {
    return this.checkResponse<DirectMail>(
      this.api.getDirectMail(request, options),
      errorHandlers,
    );
  }

  async createDirectMail({
    request,
    options,
    errorHandlers,
  }: ApiArguments<DirectMailApiPostDirectMailRequest>): Promise<AxiosResponse<DirectMail>> {
    return this.checkResponse<DirectMail>(
      this.api.postDirectMail(request, options),
      errorHandlers,
    );
  }

  async updateDirectMail({
    request,
    options,
    errorHandlers,
  }: ApiArguments<DirectMailApiPutDirectMailRequest>): Promise<AxiosResponse<DirectMail>> {
    return this.checkResponse<DirectMail>(
      this.api.putDirectMail(request, options),
      errorHandlers,
    );
  }

  async reserveDirectMail({
    request,
    options,
    errorHandlers,
  }: ApiArguments<DirectMailApiReserveDirectMailRequest>): Promise<AxiosResponse<DirectMail>> {
    return this.checkResponse<DirectMail>(
      this.api.reserveDirectMail(request, options),
      errorHandlers,
    );
  }

  async cancelReservationDirectMail({
    request,
    options,
    errorHandlers,
  }: ApiArguments<DirectMailApiCancelReservationDirectMailRequest>): Promise<AxiosResponse<DirectMail>> {
    return this.checkResponse<DirectMail>(
      this.api.cancelReservationDirectMail(request, options),
      errorHandlers,
    );
  }

  async deleteDirectMail({
    request,
    options,
    errorHandlers,
  }: ApiArguments<DirectMailApiDeleteDirectMailRequest>): Promise<AxiosResponse<DirectMail>> {
    return this.checkResponse<DirectMail>(
      this.api.deleteDirectMail(request, options),
      errorHandlers,
    );
  }
}

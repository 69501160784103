import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, mergeProps as _mergeProps, withModifiers as _withModifiers, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "align-center"
}
const _hoisted_2 = {
  key: 1,
  class: "align-center"
}
const _hoisted_3 = { class: "salesforceOpportunityModalBodyTopBlock" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "select-sf-data-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BModalHeader = _resolveComponent("BModalHeader")!
  const _component_BRadio = _resolveComponent("BRadio")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_SearchSfModel = _resolveComponent("SearchSfModel")!
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BDatePicker = _resolveComponent("BDatePicker")!
  const _component_BInputDateTime = _resolveComponent("BInputDateTime")!
  const _component_BModalBody = _resolveComponent("BModalBody")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BModalFooter = _resolveComponent("BModalFooter")!
  const _component_BHeightAdjuster = _resolveComponent("BHeightAdjuster")!
  const _component_BModal = _resolveComponent("BModal")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_BModal, {
    "modal-name": _ctx.modalName,
    onOpenModalEvent: _ctx.syncSfData
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BHeightAdjuster, null, {
        default: _withCtx(() => [
          _createVNode(_component_BModalHeader, {
            title: _ctx.$t('salesforceOpportunityModal.modalTitle'),
            onModalClose: _ctx.hideSfModal
          }, null, 8, ["title", "onModalClose"]),
          _createVNode(_component_BModalBody, null, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["select-sf-data-block", { 'lead-select': _ctx.isSyncSfLead, 'ac-select': !_ctx.isSyncSfLead }])
              }, [
                (_ctx.isSyncSfLead)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_BRadio, {
                        modelValue: _ctx.selectCreateType,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectCreateType) = $event)),
                        class: "mr-600",
                        label: "new"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(`salesforceOpportunityModal.createType.new`)), 1)
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.isSyncSfLead)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_BRadio, {
                        modelValue: _ctx.selectCreateType,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectCreateType) = $event)),
                        label: "exist"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(`salesforceOpportunityModal.createType.exist`)), 1)
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_component_BListItem, { class: "list-item" }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('salesforceOpportunityModal.formLabel.account')), 1)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString(_ctx.currentLeadCompanyName), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.isSyncSfLead)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_SearchSfModel, {
                          "selected-id": _ctx.selectedSfAccountId,
                          "onUpdate:selectedId": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedSfAccountId) = $event)),
                          label: _ctx.$t('salesforceOpportunityModal.formLabel.account'),
                          disabled: _ctx.selectCreateType=='new',
                          search: _ctx.searchAccountByName
                        }, null, 8, ["selected-id", "label", "disabled", "search"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createVNode(_component_BListItem, { class: "list-item" }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('salesforceOpportunityModal.formLabel.contact')), 1)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString(_ctx.currentLeadUserName), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_BListItem, { class: "list-item" }),
                _createVNode(_component_SearchSfModel, {
                  "selected-id": _ctx.selectedSfUserId,
                  "onUpdate:selectedId": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedSfUserId) = $event)),
                  label: _ctx.$t('general.select.of', { item: _ctx.$t('salesforceOpportunityModal.formLabel.owner') }),
                  search: _ctx.searchSfUserByName
                }, null, 8, ["selected-id", "label", "search"])
              ], 2),
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "hr" }, null, -1)),
              _withDirectives(_createElementVNode("div", null, _toDisplayString(_ctx.$t('salesforceOpportunityModal.salesforceMustInput')), 513), [
                [_vShow, _ctx.salesforceMustItemList.length]
              ]),
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.salesforceMustItemList, (salesforceMustItem, index) => {
                  return (_openBlock(), _createBlock(_component_BListItem, {
                    key: index,
                    class: "list-item convert"
                  }, {
                    header: _withCtx(() => [
                      _createElementVNode("div", null, _toDisplayString(salesforceMustItem.label), 1)
                    ]),
                    default: _withCtx(() => [
                      (salesforceMustItem.data_type === 'string')
                        ? (_openBlock(), _createBlock(_component_BInput, {
                            key: 0,
                            modelValue: _ctx.inputSalesforceItemList[`${salesforceMustItem.name}`],
                            "onUpdate:modelValue": ($event: any) => ((_ctx.inputSalesforceItemList[`${salesforceMustItem.name}`]) = $event),
                            rules: _ctx.rules
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"]))
                        : (salesforceMustItem.data_type === 'date')
                          ? (_openBlock(), _createBlock(_component_BDatePicker, _mergeProps({
                              key: 1,
                              ref_for: true
                            }, _ctx.dateAttributes, {
                              modelValue: _ctx.inputSalesforceItemList[`${salesforceMustItem.name}`],
                              "onUpdate:modelValue": ($event: any) => ((_ctx.inputSalesforceItemList[`${salesforceMustItem.name}`]) = $event)
                            }), null, 16, ["modelValue", "onUpdate:modelValue"]))
                          : (salesforceMustItem.data_type === 'datetime')
                            ? (_openBlock(), _createBlock(_component_BInputDateTime, {
                                key: 2,
                                date: _ctx.inputSalesforceItemList[`${salesforceMustItem.name}`],
                                "onUpdate:date": ($event: any) => ((_ctx.inputSalesforceItemList[`${salesforceMustItem.name}`]) = $event),
                                time: _ctx.inputSalesforceItemList[`${salesforceMustItem.name}`],
                                "onUpdate:time": ($event: any) => ((_ctx.inputSalesforceItemList[`${salesforceMustItem.name}`]) = $event),
                                "date-format": "YYYY-MM-DD"
                              }, null, 8, ["date", "onUpdate:date", "time", "onUpdate:time"]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_BModalFooter, null, {
            default: _withCtx(() => [
              _createVNode(_component_BLayout, { "justify-center": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_BBtn, {
                    class: "mr-100",
                    flat: "",
                    size: "small",
                    loading: _ctx.isLoading,
                    onClick: _withModifiers(_ctx.hideSfModal, ["stop"])
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.cancel.text')), 1)
                    ]),
                    _: 1
                  }, 8, ["loading", "onClick"]),
                  _createVNode(_component_BBtn, {
                    type: "primary",
                    loading: _ctx.isLoading,
                    disabled: _ctx.isDisable,
                    onClick: _withModifiers(_ctx.handleSubmit, ["stop"])
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t(`general.save.text`)), 1)
                    ]),
                    _: 1
                  }, 8, ["loading", "disabled", "onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-name", "onOpenModalEvent"])), [
    [_directive_loading, _ctx.isLoading]
  ])
}
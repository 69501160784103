import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "theader" }
const _hoisted_2 = { class: "table-row theader-row" }
const _hoisted_3 = {
  key: 0,
  class: "tbody"
}
const _hoisted_4 = { class: "table-row tbody-row" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { Trigger, TriggerTarget, TriggerTargetStatus, TriggerTargetStatusOverview } from '@/api/openapi';
import { useSequenceMaster } from '@/composable/user/sequence/sequence-master';

type TEmits = {
  'update:modelValue': [value: TriggerTargetStatus | null]
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InstancesSummary',
  props: {
    "modelValue": {},
    "modelModifiers": {},
    "statusOverview": {},
    "statusOverviewModifiers": {},
  },
  emits: /*@__PURE__*/_mergeModels(["update:modelValue"], ["update:modelValue", "update:statusOverview"]),
  setup(__props, { emit: __emit }) {

const emit = __emit;
const status = _useModel<TriggerTargetStatus | null>(__props, "modelValue");
const statusOverview = _useModel<TriggerTargetStatusOverview>(__props, 'statusOverview');

const statusOverviewAll = computed(() =>
  Object.values(statusOverview.value).reduce((prev, current) => prev + current, 0),
);

const i18n = useI18n();
const headers = [
  { key: 'all', name: i18n.t('trigger.target.status.all') },
  { key: 'ready', name: i18n.t('trigger.target.status.enum.ready') },
  { key: 'executing', name: i18n.t('trigger.target.status.enum.executing') },
  { key: 'completed', name: i18n.t('trigger.target.status.enum.completed') },
  { key: 'failed', name: i18n.t('trigger.target.status.enum.failed') },
  { key: 'stopped', name: i18n.t('trigger.target.status.enum.stopped') },
];

const handleStatusClick = (value: TriggerTargetStatus | null) => {
  emit('update:modelValue', value);
};

const isFiltered = (value: TriggerTargetStatus | null) => status.value === value;

return (_ctx: any,_cache: any) => {
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_VWait = _resolveComponent("VWait")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(headers, (header) => {
          return _createElementVNode("div", {
            key: header.key,
            class: "table-cell header-cell"
          }, _toDisplayString(header.name), 1)
        }), 64))
      ])
    ]),
    _createVNode(_component_VWait, { for: "fetchSummary" }, {
      waiting: _withCtx(() => [
        _withDirectives(_createVNode(_component_BPlaceholder, { "element-loading-background": "transparent" }, null, 512), [
          [_directive_loading, true]
        ])
      ]),
      default: _withCtx(() => [
        (statusOverview.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: _normalizeClass(["table-cell", { 'filtered': isFiltered(null) }]),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (handleStatusClick(null)))
                }, [
                  _createElementVNode("span", null, _toDisplayString(statusOverviewAll.value), 1)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["table-cell", { 'filtered': isFiltered('ready') }]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (handleStatusClick('ready')))
                }, _toDisplayString(statusOverview.value.numberOfReady), 3),
                _createElementVNode("div", {
                  class: _normalizeClass(["table-cell", { 'filtered': isFiltered('executing') }]),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (handleStatusClick('executing')))
                }, _toDisplayString(statusOverview.value.numberOfExecuting), 3),
                _createElementVNode("div", {
                  class: _normalizeClass(["table-cell", { 'filtered': isFiltered('completed') }]),
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (handleStatusClick('completed')))
                }, _toDisplayString(statusOverview.value.numberOfCompleted), 3),
                _createElementVNode("div", {
                  class: _normalizeClass(["table-cell", { 'filtered': isFiltered('failed') }]),
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (handleStatusClick('failed')))
                }, _toDisplayString(statusOverview.value.numberOfFailed), 3),
                _createElementVNode("div", {
                  class: _normalizeClass(["table-cell", { 'filtered': isFiltered('stopped') }]),
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (handleStatusClick('stopped')))
                }, _toDisplayString(statusOverview.value.numberOfStopped), 3)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx } from "vue"

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import LeadHistoryCard from '@/components/organisms/user/lead/history/LeadHistoryCard.vue';
import { SequenceStepInstanceHistory } from '@/store/types';
import { formatShorterDateTime } from '@/utils/date-time';

type TProps = {
  sequenceStepInstanceHistory: SequenceStepInstanceHistory;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceStepInstanceCard',
  props: {
    sequenceStepInstanceHistory: {}
  },
  setup(__props: any) {

const props = __props;
const i18n = useI18n();

const attributes = computed(() => ({
  text: title(),
  // TODO: user が必要？システムユーザーとして表示？
  // user: props.sequenceInstanceHistory.user,
  isDisplaySystemUser: true,
  iconComponent: 'BCustomIcon',
  customIconClass: 'b-sequence',
  iconType: 'hot',
  toggle: isFail.value,
}));
const historiedAt = computed<string>(() => formatShorterDateTime(props.sequenceStepInstanceHistory.historiedAt));
const title = () => {
  return `${props.sequenceStepInstanceHistory.actionTypeText} - ${props.sequenceStepInstanceHistory?.name} - ${props.sequenceStepInstanceHistory?.sortOrder + 1}`
};
const isFail = computed<boolean>(() => props.sequenceStepInstanceHistory.status === 'fail');

const failedTitle = computed(() => {
  if (!isFail.value) return '';
  if (props.sequenceStepInstanceHistory.failedReason === 'systemError') {
    return i18n.t('sequence.step.failedTitle.error');
  } else {
    return i18n.t('sequence.step.failedTitle.sentError');
  }
});
const failedReason = computed(() => {
  if (!isFail.value) return '';
  return i18n.t(`sequence.step.failedReason.${props.sequenceStepInstanceHistory.failedReason}`);
});

return (_ctx: any,_cache: any) => {
  const _component_BError = _resolveComponent("BError")!

  return (_openBlock(), _createBlock(LeadHistoryCard, _normalizeProps(_guardReactiveProps(attributes.value)), {
    date: _withCtx(() => [
      _createElementVNode("div", null, _toDisplayString(historiedAt.value), 1)
    ]),
    default: _withCtx(() => [
      (isFail.value)
        ? (_openBlock(), _createBlock(_component_BError, {
            key: 0,
            "error-title": failedTitle.value,
            "error-info": failedReason.value
          }, null, 8, ["error-title", "error-info"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16))
}
}

})
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "b-editable-content" }
const _hoisted_2 = {
  key: 0,
  class: "input-box"
}
const _hoisted_3 = { class: "btn-area" }
const _hoisted_4 = ["data-test"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BInputDateTime = _resolveComponent("BInputDateTime")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.internalEditing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["b-input-box", _ctx.classes])
          }, [
            _createVNode(_component_BInputDateTime, {
              date: _ctx.date,
              "onUpdate:date": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
              time: _ctx.time,
              "onUpdate:time": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.time) = $event)),
              class: "input-area mr-100",
              disabled: _ctx.loading,
              clearable: "",
              "data-test": _ctx.dataTest
            }, null, 8, ["date", "time", "disabled", "data-test"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_BLayout, { "align-center": "" }, {
                default: _withCtx(() => [
                  (_ctx.valueChanged)
                    ? (_openBlock(), _createBlock(_component_BBtn, {
                        key: 0,
                        class: "mr-50",
                        size: "small",
                        fab: "",
                        flat: "",
                        loading: _ctx.loading,
                        "data-test": `${_ctx.dataTest}Btn`,
                        onClick: _ctx.check
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_BIcon, {
                            class: "check-icon",
                            size: "small"
                          }, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode(" check ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["loading", "data-test", "onClick"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_BBtn, {
                    size: "small",
                    fab: "",
                    flat: "",
                    disabled: _ctx.loading,
                    onClick: _ctx.close
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BIcon, {
                        class: "close-icon",
                        size: "small"
                      }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode(" close ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ]),
                _: 1
              })
            ])
          ], 2)
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "item input",
          "data-test": _ctx.dataTest,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleClickEdit && _ctx.handleClickEdit(...args)))
        }, [
          (_ctx.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.formattedValue), 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.hyphen ? '-' : ''), 1)
              ], 64))
        ], 8, _hoisted_4))
  ]))
}
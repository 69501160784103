import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { inject, ref } from 'vue';
import SfLinkBox from '@/components/organisms/user/general/modal/salesforce/SfStatusModal/SfLinkBox.vue'
import { KEY_OF_MODAL } from '@/injection-keys';

export type TBmodalDelegate = {
  shouldClose?: () => boolean | Promise<boolean>;
  beforeClose?: () => void;
};
type TProps = {
  modalName: string;
  sfRelatedLinkInfo: {
    sfSyncedAt: Date,
    sfRelatedLinks: [{
      status: string,
      title: string,
      url: string,
      errorMessage: string,
    }],
  },
  sfExecSyncBtn: {
    type: boolean,
    default: true,
  }
};

export default /*@__PURE__*/_defineComponent({
  __name: 'SfStatusModal',
  props: {
    modalName: {},
    sfRelatedLinkInfo: {},
    sfExecSyncBtn: {}
  },
  setup(__props: any) {

const props = __props;
const delegate = {
  shouldClose: () => {
    return !isLoading.value;
  },
}
const isLoading = ref(false);
const updateLoading = (result) => {
  isLoading.value = result;
}

const modal = inject(KEY_OF_MODAL);
const close = () => {
  modal.hide('sfRelatedLinkModalDetail');
}

return (_ctx: any,_cache: any) => {
  const _component_BHeightAdjuster = _resolveComponent("BHeightAdjuster")!
  const _component_BModal = _resolveComponent("BModal")!

  return (_openBlock(), _createBlock(_component_BModal, {
    "modal-name": _ctx.modalName,
    delegate: delegate,
    width: "454px",
    height: "auto"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BHeightAdjuster, null, {
        default: _withCtx(() => [
          _createVNode(SfLinkBox, {
            "sf-related-link-info": _ctx.sfRelatedLinkInfo,
            "sf-exec-sync-btn": _ctx.sfExecSyncBtn,
            onIsLoading: updateLoading,
            "onClick:modalClose": close
          }, null, 8, ["sf-related-link-info", "sf-exec-sync-btn"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-name"]))
}
}

})
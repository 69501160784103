import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "setting-title" }
const _hoisted_2 = { class: "explain" }

import { camelCase } from 'lodash';
import { computed, inject, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { SequenceExecutionSettingSequenceExecutionTypeEnum } from '@/api/openapi';
import { SequenceSettingApiService } from '@/api/user/resources/sequence_setting';
import { useWait } from '@/composable/vue-wait'
import { KEY_OF_TOAST } from '@/injection-keys';


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceSetting',
  setup(__props) {

const i18n = useI18n();
const { doActionWithWait } = useWait();
const toast = inject(KEY_OF_TOAST);
const api = new SequenceSettingApiService();

const executionTypes = Object.values(SequenceExecutionSettingSequenceExecutionTypeEnum).map(label => ({
  label,
  text: i18n.t(`sequenceSetting.executionType.executionTypes.${camelCase(label)}`),
}));

const executionTypeValue = ref<SequenceExecutionSettingSequenceExecutionTypeEnum>('only_on_weekdays');
const isValid = computed(() => executionTypeValue.value != null);

const fetchSequenceExecutionSetting = () => {
  doActionWithWait('fetchSequenceExecutionSetting', async () => {
    const { data: { sequenceExecutionSetting } } = await api.getSequenceExecutionSetting();
    executionTypeValue.value = sequenceExecutionSetting.sequenceExecutionType;
  });
};
onBeforeMount(async () => {
  await fetchSequenceExecutionSetting();
});

const handleSaveExecutionSettingButtonClick = () => {
  const api = new SequenceSettingApiService();
  doActionWithWait('patchSequenceExecutionSetting', async () => {
    await api.patchSequenceExecutionSetting({
      request: {
        sequenceExecutionSetting: {
          sequenceExecutionType: executionTypeValue.value,
        },
      },
    });
  });
  toast.show(
    i18n.t('general.done.withTarget', {
      action: i18n.t('general.save.text'),
      target: i18n.t('sequenceSetting.executionType.title'),
    }),
  );
};

return (_ctx: any,_cache: any) => {
  const _component_BRadio = _resolveComponent("BRadio")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BContentBox = _resolveComponent("BContentBox")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_BContentBox, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('sequenceSetting.executionType.title')), 1),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('sequenceSetting.executionType.explain')), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(executionTypes), (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.label,
          class: "mb-100"
        }, [
          _createVNode(_component_BRadio, {
            modelValue: executionTypeValue.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((executionTypeValue).value = $event)),
            label: item.label
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.text), 1)
            ]),
            _: 2
          }, 1032, ["modelValue", "label"])
        ]))
      }), 128)),
      _createVNode(_component_BListItem, { class: "mt-400" }, {
        default: _withCtx(() => [
          _createVNode(_component_BBtn, {
            disabled: !isValid.value,
            type: "primary",
            loading: _ctx.$wait.is('patchSequenceExecutionSetting'),
            onClick: handleSaveExecutionSettingButtonClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('general.save.text')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "loading"])
        ]),
        _: 1
      })
    ]),
    _: 1
  })), [
    [_directive_loading, _ctx.$wait.is('fetchSequenceExecutionSetting')]
  ])
}
}

})
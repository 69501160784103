<template>
  <BHeightAdjuster>
    <BModalBody>
      <div class="setting-message">
        {{ $t('leadSource.settingMessage') }}
      </div>
      <div class="list-content">
        <div v-loading="$wait.is('deleteLeadSourceWait') || $wait.is('getLeadSourcesWait')">
          <div class="item-content">
            <BLayout
              v-for="leadSource in leadSources"
              :key="leadSource.id"
              class="item-row py-100"
              align-center
              justify-space-between
            >
              <BEditableContent
                class="mr-100"
                :value="leadSource.name"
                :placeholder="$t('leadSource.name')"
                required
                :loading="$wait.is('updateLeadSourceWait')"
                @check="handleUpdate($event, leadSource.id)"
              />
              <BMoreMenu>
                <BBtn
                  flat
                  fit
                  size="small"
                  @click="() => deleteLeadSource(leadSource)"
                >
                  {{ $t('leadSource.delete') }}
                </BBtn>
              </BMoreMenu>
            </BLayout>
            <div v-if="leadSources.length === 0">
              <BEmptyBox class="pt-600 pb-600">
                <slot name="text">
                  {{ $t('leadSource.empty') }}
                </slot>
              </BEmptyBox>
            </div>
          </div>
        </div>
      </div>
    </BModalBody>
    <BModalFooter>
      <BLayout
        class="footer"
        align-center
        justify-center
      >
        <BInput
          v-model="inputName"
          v-model:valid="createValid"
          class="input-name mr-400"
          :placeholder="$t('leadSource.name')"
          :rules="[requiredRule]"
          :disabled="$wait.is('createLeadSourceWait')"
          @keypress-enter="createLeadSource"
        />
        <BBtn
          type="primary"
          :disabled="!createValid"
          :loading="$wait.is('createLeadSourceWait')"
          @click="createLeadSource"
        >
          <span>{{ $t('leadSource.create') }}</span>
        </BBtn>
      </BLayout>
    </BModalFooter>
  </BHeightAdjuster>
</template>

<script>
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import errorHandler from '@/mixins/error_handler'
import inputValidation from '@/mixins/input_validation'

export default {
  mixins: [inputValidation, errorHandler],
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data () {
    return {
      inputName: null,
      createValid: false,
    }
  },
  computed: {
    ...mapGetters('user', [
      'leadSources',
    ]),
  },
  created () {
    this.getLeadSourcesAction()
  },
  methods: {
    ...mapWaitingActions('user', {
      createLeadSourceAction: 'createLeadSourceWait',
      updateLeadSourceAction: 'updateLeadSourceWait',
      deleteLeadSourceAction: 'deleteLeadSourceWait',
      getLeadSourcesAction: 'getLeadSourcesWait',
    }),
    async createLeadSource () {
      const leadSource = { name: this.inputName }
      await this.createLeadSourceAction({
        body: { leadSource },
        errorHandlers: { 422: this.defaultHandler },
      })
      this.inputName = null
    },
    async deleteLeadSource (leadSource) {
      const check = await this.$bitterAlert.show({
        title: this.$t('leadSource.title'),
        text: `${this.$t('general.confirm.actionWithTarget', { target: leadSource.name, action: this.$t('general.delete.text') })}\n\n${this.$t('leadSource.deleteMessage')}`,
      })
      if (!check) { return }
      await this.deleteLeadSourceAction({
        id: leadSource.id,
      })
    },
    async handleUpdate (name, leadSourceId) {
      await this.updateLeadSourceAction({
        id: leadSourceId,
        body: { name },
        errorHandlers: { 422: this.defaultHandler },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
:deep(.b-modal-body) {
  margin-top: 20px;
  padding: 0 22px 20px;
  .list-content {
    overflow-y: auto;
    .item-content {
      height: calc(100vh - 320px);
    }
    .item-row {
      margin: 0;
      @media only screen and (min-width: 959px) {
        width: 50%;
      }
      .item {
        margin: 0;
      }
      .b-input-box {
        margin-left: 8px;
      }
    }
  }
}

.setting-message {
  color: $textcolor-light;
  margin-bottom: $basespace-400;
  white-space: pre-line;
  margin-left: $basespace-100;
}

.footer {
  padding-top: $basespace-300;
  .input-name {
    width: $setting-input-width;
  }
}
</style>

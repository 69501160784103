<template>
  <ElPopover
    v-bind="attributes"
    v-model:visible="internalValue"
  >
    <slot />
    <template #reference>
      <slot name="reference" />
    </template>
  </ElPopover>
</template>

<script>
export default {
  props: {
    trigger: String,
    title: String,
    content: String,
    width: [String, Number],
    placement: String,
    disabled: Boolean,
    transition: String,
    modelValue: Boolean,
    full: Boolean,
    scroll: Boolean,
    quill: Boolean,
    arrow: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'update:modelValue',
    'hide',
    'show',
  ],
  data() {
    return {
      internalValue: this.modelValue,
    }
  },
  computed: {
    attributes () {
      return {
        trigger: this.trigger,
        title: this.title,
        content: this.content,
        width: this.width,
        placement: this.placement,
        disabled: this.disabled,
        transition: this.transition,
        popperClass: this.setPopperClass,
        showArrow: this.arrow,
        quill: this.quill,
      }
    },
    setPopperClass () {
      let popoverClass = this.full ? 'b-popover-full' : ''
      popoverClass += this.scroll ? ' b-popover-scroll' : ''
      popoverClass += this.quill ? ' quill-confirm' : ''
      return popoverClass
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        this.internalValue = val
      },
    },
    internalValue(val) {
      this.$emit('update:modelValue', val)
      // FIXME: Vue3に移行し終えたら、以下の処理はそれぞれ@show, @hideに戻すとキレイ
      // @see https://www.notion.so/9dd5dc6cbb0141bd9da6e02e58dad6a8#09da144ceb83476d9341fb01ef3eb76d
      // NOTE: @vue/compatとElement+の処理の相性の都合で、ElPopoverのshow, hide eventが発火しないため、手動で発火させている
      if (val) {
        this.onShow()
      } else {
        this.onHide()
      }
    },
  },
  methods: {
    onHide() {
      this.$emit('hide')
    },
    onShow() {
      this.$emit('show')
    },
  },
}
</script>

<style lang="scss" scoped>
  .b-popover-full {
    padding: 0 !important;
  }

  .b-popover-scroll {
    overflow-y: scroll;
    max-height: 300px;
    padding: $basespace-400;
  }

  .quill-confirm {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 1.42;
    tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    // Biscuetの標準リンク色を上書き
    :deep(a) {
      color: #06c;
    }
    :deep(p) {
      margin: 0 !important;
      padding: 0 !important;
      counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9 !important;
    }
  }
</style>

import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

import { useI18n } from 'vue-i18n'
import { columns } from './instance-list-header';


export default /*@__PURE__*/_defineComponent({
  __name: 'InstanceListHeader',
  props: {
    "modelValue": { type: Boolean },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

const model = _useModel<boolean>(__props, "modelValue");

const i18n = useI18n();

const toLabel = (key: string) => {
  if (key === 'companyName') return i18n.t('companyInfo.item.companyName');
  if (key === 'fullName') return i18n.t('callTargetInfo.item.name');
  if (key === 'createdAt') return i18n.t('trigger.target.createdAt');
  if (key === 'event') return i18n.t('trigger.target.event');
  if (key === 'statusChangedAt') return i18n.t('trigger.target.statusChangedAt');
  if (key === 'status') return i18n.t('trigger.target.status.title');
  if (key === 'owner') return i18n.t('trigger.target.owner');
  if (key === 'approveUser') return i18n.t('trigger.target.approveUser');
};

const toInfo = (key: string) => {
  if (key === 'event') return i18n.t('trigger.target.eventNote');
};

return (_ctx: any,_cache: any) => {
  const _component_BCheckbox = _resolveComponent("BCheckbox")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createBlock(_component_BLayout, {
    class: "instance-list-row",
    "align-center": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "instance-list-item checkbox",
        onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
      }, [
        _createVNode(_component_BCheckbox, {
          modelValue: model.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
          class: "mt-0",
          indeterminate: model.value
        }, null, 8, ["modelValue", "indeterminate"])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns), (column) => {
        return (_openBlock(), _createElementBlock("div", {
          key: column.key,
          class: "instance-list-item instance-list-header",
          style: _normalizeStyle({
        'width': column.width,
        'min-width': column.width,
        'max-width': column.width
      })
        }, [
          _createElementVNode("span", null, _toDisplayString(toLabel(column.key)), 1),
          (toInfo(column.key))
            ? (_openBlock(), _createBlock(_component_BTooltip, {
                key: 0,
                top: "",
                content: toInfo(column.key)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BIcon, {
                    size: "small",
                    class: "info"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" help ")
                    ])),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["content"]))
            : _createCommentVNode("", true)
        ], 4))
      }), 128)),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "instance-list-item end-column" }, null, -1))
    ]),
    _: 1
  }))
}
}

})
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import qs from 'qs';
import swal from 'sweetalert';
import { i18nGlobal as i18n } from '@/i18n';
import AuthService from '@/services/auth_service';
import { logging } from '@/services/logging_service';
import ERROR_CODE from './error_code';

const defaultTimeoutMilliSec = 60000;

export type ErrorHandlers = {
  // FIXME: any[]
  [key: number]: (...args) => unknown;
  disableDefaultErrorHandler?: boolean
}

export type ApiArguments<T> = { request?: T } & { options?: AxiosRequestConfig } & {
  errorHandlers?: ErrorHandlers;
};

/**
 * NOTE: この変換を入れない場合、array型のparameterがRailsでhashとして解析される
 * 変換なし：hoge[0]=1&hoge[1]=2
 * 変換あり：hoge[]=1&hoge[]=2
 */
const serializeQueryParameter = (params: any) => qs.stringify(params, { arrayFormat: 'brackets' });

export default class ApiService {
  protected cancelSource: CancelTokenSource = null;
  protected baseUrl: string;
  protected httpInstance: AxiosInstance = null;
  private isOpenApi = false;

  constructor(isOpenApi = false) {
    // VUE_APP_API_ENDPOINTに/apiというパスが入っているがOpenAPIの定義にはパスは含めたくないため
    // すべてOpenAPIに置き換わったら削除する
    this.isOpenApi = isOpenApi;
    this.cancelSource = axios.CancelToken.source();
    this.setBaseUrl();
    this.setHttpInstance();
  }

  protected setHttpInstance() {
    this.httpInstance = axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Cache-Control': 'no-cache, no-store',
        'Pragma': 'no-cache',
        'Expires': 0,
        'X-Requested-With': 'XMLHttpRequest',
      },
      cancelToken: this.cancelSource.token,
    });
    this.httpInstance.interceptors.request.use(async request => {
      const contentType = request.headers['Content-Type'] || 'application/json';

      // MEMO: bodyがOpenApiのクライアントによって二重にstringifyされるため
      if (this.isOpenApi && !!request.data && contentType === 'application/json') request.data = JSON.parse(request.data)

      const as = new AuthService();
      const authHeaders = await as.getAuthHeader();
      if (!authHeaders) return request;

      request.headers = { ...request.headers, ...authHeaders };
      return request;
    });
  }

  private setBaseUrl() {
    const requestedSubdomain = this.extractSubdomain(location.hostname);
    const apiURL = new URL(process.env.VUE_APP_API_ENDPOINT);
    apiURL.host = requestedSubdomain
      ? `${requestedSubdomain}.${apiURL.hostname}`
      : apiURL.hostname;
    this.baseUrl = this.isOpenApi ? apiURL.origin : apiURL.toString()
  }

  extractSubdomain = (hostname: string) => {
    const matched = hostname.match(/^([^.]+)\.[^.]+\..+$/);
    return matched ? matched[1] : '';
  };

  cancel(message: string) {
    this.cancelSource.cancel(message);
  }

  async get(
    path: string,
    {
      params = null,
      header = {},
      errorHandlers = {},
      timeout = defaultTimeoutMilliSec,
      responseType = null,
    } = {},
  ) {
    logging('Request.GET', path);

    return this.checkResponse(
      this.httpInstance.get(path, {
        params,
        timeout,
        headers: header,
        responseType,
        paramsSerializer: (params) => {
          return serializeQueryParameter(params);
        },
      }),
      errorHandlers,
    );
  }

  async delete(
    path: string,
    {
      params = null,
      header = {},
      errorHandlers = {},
      timeout = defaultTimeoutMilliSec,
    } = {},
  ) {
    logging('Request.DELETE', path);

    return this.checkResponse(
      this.httpInstance.delete(path, {
        params,
        timeout: timeout || defaultTimeoutMilliSec,
        headers: header,
        paramsSerializer: (params) => {
          return serializeQueryParameter(params);
        },
      }),
      errorHandlers,
    );
  }

  async post(
    path: string,
    {
      body = null,
      header = {},
      errorHandlers = {},
      timeout = defaultTimeoutMilliSec,
    } = {},
  ) {
    logging('Request.POST', path);

    return this.checkResponse(
      this.httpInstance.post(path, body, {
        timeout,
        headers: header,
      }),
      errorHandlers,
    );
  }

  async put(
    path: string,
    {
      body = null,
      header = {},
      errorHandlers = {},
      timeout = defaultTimeoutMilliSec,
    } = {},
  ) {
    logging('Request.PUT', path);

    return this.checkResponse(
      this.httpInstance.put(path, body, {
        timeout,
        headers: header,
      }),
      errorHandlers,
    );
  }

  async checkResponse<T>(req: Promise<AxiosResponse<T>>, errorHandlers: ErrorHandlers) {
    const res = await req.catch((error: AxiosError) => {
      if (axios.isCancel(error)) {
        logging('Response.Error', error.message);
        throw error;
      } else {
        const handler = this.resolveErrorHandler(error, errorHandlers);
        handler(error);
        throw error;
      }
    });
    logging('Response.Status', res.status);
    if (res.status !== 204) {
      logging('Response.Body', res.data);
    }
    return res;
  }

  resolveErrorHandler(error: AxiosError, errorHandlers: ErrorHandlers) {
    if (errorHandlers?.disableDefaultErrorHandler)
      return () => {}; // swalによるエラーダイアログをオフにする
    const { statusCode, statusText } = this.getStates(error);

    let handler: (error?: AxiosError) => void
      = errorHandlers?.[statusCode]
      || this.getErrorHandlers(statusCode, statusText);
    // FIXME: handlerは必ずfunctionになり、このパターンはない気がする
    if (handler == null) {
      handler = () => {
        console.log(`Status Code ${statusCode} could not be handled.`);
      };
    }
    return handler;
  }

  getStates(error: AxiosError) {
    let statusCode: number | string, statusText: string;

    const isTimeout = error.code === 'ECONNABORTED';
    if (isTimeout) {
      console.log('timeout error');
      statusCode = ERROR_CODE.TIMEOUT_ERROR_CODE;
      statusText = '';
    } else if (!error.response) {
      console.log('network error');
      statusCode = ERROR_CODE.NETWORK_ERROR_CODE;
      statusText = '';
    } else {
      console.log(error.response.statusText);
      statusCode = error.response.status;
      statusText = error.response.statusText;
    }
    return { statusCode, statusText };
  }

  getErrorHandlers(statusCode: number | string, statusText: string) {
    if (Object.values(ERROR_CODE).includes(statusCode)) {
      return this.defaultErrorHandlers[statusCode];
    } else {
      return () => {
        swal(`${statusCode} ${statusText}\n${this.getDescription(statusCode)}`);
      };
    }
  }

  getDescription(status) {
    if (typeof status !== 'number' || status > 600) {
      return i18n.t('apiError.message.unknownError');
    }

    if (status >= 300 && status < 400) {
      return i18n.t('apiError.3XX');
    } else if (status >= 400 && status < 500) {
      return i18n.t('apiError.4XX');
    } else if (status >= 500 && status < 600) {
      return i18n.t('apiError.5XX');
    }
  }

  createSwal({ title, text }) {
    return swal({
      title,
      text,
      closeOnClickOutside: false,
      buttons: {
        cancel: {
          text: `${i18n.t('general.close.text')}`,
          value: false,
          visible: true,
        },
      },
    });
  }

  defaultErrorHandlers = {
    [ERROR_CODE.TIMEOUT_ERROR_CODE]: () => {
      this.createSwal({
        title: i18n.t('apiError.timeoutError.title'),
        text: i18n.t('apiError.timeoutError.message'),
      });
    },
    [ERROR_CODE.NETWORK_ERROR_CODE]: () => {
      this.createSwal({
        title: i18n.t('apiError.networkError.title'),
        text: i18n.t('apiError.networkError.message'),
      });
    },
    [ERROR_CODE.BAD_REQUEST]: () => {
      this.createSwal({
        title: '400 Bad Request',
        text: i18n.t('apiError.badRequest'),
      });
    },
    [ERROR_CODE.UNAUTHORIZED]: () => {
      this.createSwal({
        title: '401 Unauthorized',
        text: i18n.t('apiError.unauthorized'),
      });
    },
    [ERROR_CODE.FORBIDDEN]: () => {
      this.createSwal({
        title: '403 Forbidden',
        text: i18n.t('apiError.forbidden'),
      });
    },
    [ERROR_CODE.NOT_FOUND]: () => {
      this.createSwal({
        title: '404 Not Found',
        text: i18n.t('apiError.notFound'),
      });
    },
    [ERROR_CODE.NOT_ACCEPTABLE]: () => {
      this.createSwal({
        title: '406 Not Acceptable',
        text: i18n.t('apiError.notAcceptable'),
      });
    },
    [ERROR_CODE.UNPROCESSABLE_ENTITY]: (error: AxiosError) => {
      const message = error?.response?.data?.errorMessage
      if (message) {
        swal(message)
        return
      }
      this.createSwal({
        title: '422 Unprocessable Entity',
        text: i18n.t('apiError.unprocessableEntity'),
      });
    },
    [ERROR_CODE.INTERNAL_SERVER_ERROR]: () => {
      this.createSwal({
        title: '500 Internal Server Error',
        text: i18n.t('apiError.internalServerError'),
      });
    },
    [ERROR_CODE.SERVICE_UNAVAILABLE]: () => {
      this.createSwal({
        title: '503 Service Unavailable',
        text: i18n.t('apiError.serviceUnavailable'),
      });
    },
  };
}

import ApiBase from '@/api/base'

export default {
  createMailVerification(args) {
    const { clientId } = args
    return ApiBase.post(`/admin/clients/${clientId}/mail_verifications`, args)
  },
  getMailVerification(args) {
    const { clientId } = args
    const { currentPage } = args
    args.params = { currentPage }
    return ApiBase.get(`/admin/clients/${clientId}/mail_verifications`, args)
  },
  getMailVerificationBalance(args) {
    const { clientId } = args
    return ApiBase.get(`/admin/clients/${clientId}/mail_verification_balance`, args)
  },
  reflectMailVerificationToUnsubscribe(args) {
    const { clientId } = args
    return ApiBase.post(`/admin/clients/${clientId}/reflect_to_unsubscribe`, args)
  },
}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BLeadStageColorSelect = _resolveComponent("BLeadStageColorSelect")!
  const _component_BLeadStageColorPreview = _resolveComponent("BLeadStageColorPreview")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["editable-lead-stage-color-preview-wrapper", { uneditable: _ctx.uneditable }])
  }, [
    (_ctx.editing)
      ? (_openBlock(), _createBlock(_component_BLeadStageColorSelect, {
          key: 0,
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
        }, null, 8, ["modelValue"]))
      : (_openBlock(), _createBlock(_component_BLeadStageColorPreview, {
          key: 1,
          value: _ctx.value,
          onClick: _ctx.handlePreviewClick
        }, null, 8, ["value", "onClick"]))
  ], 2))
}
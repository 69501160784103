import Waiting from '@/store/waiting'

export default class ClientLimitSettingActions {
  constructor(Api, types) {
    const actions = {

      async getClientLimitSettingAction({ commit }, args) {
        await waiting.check('getClientLimitSettingAction', async () => {
          try {
            const res = await Api.getClientLimitSetting(args)
            commit(types.SET_CLIENT_LIMIT_SETTING, Object.values(res.data)[0])
          } catch (err) {
            commit(types.CLEAR_CLIENT_LIMIT_SETTING)
            throw err
          }
        })
      },
      async updateClientLimitSettingAction({ commit }, args) {
        await waiting.check('updateClientLimitSettingAction', async () => {
          await Api.updateClientLimitSetting(args)
          try {
            const res = await Api.getClientLimitSetting(args)
            commit(types.SET_CLIENT_LIMIT_SETTING, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}


import camelcaseKeys from 'camelcase-keys'
import { mapGetters } from 'vuex'

const mixin = {
  computed: {
    ...mapGetters('user', ['leadView']),
    ...mapGetters('userUi', [
      'leadListSortKey',
      'leadListSortOrder',
      'leadListSelectedColumns',
      'leadListFilterParams',
    ]),
    isConditionChanged() {
      return this.isFilterParamsChanged || this.isSorterdColumnsChanged || this.isDisplayColumnsChanged
    },
    isFilterParamsChanged() {
      return this.$JSON.stringify(this.filterParams) !== this.$JSON.stringify(this.leadListFilterParams)
    },
    isSorterdColumnsChanged() {
      return this.sortOrder !== this.leadListSortOrder || this.sortKey !== this.leadListSortKey
    },
    isDisplayColumnsChanged() {
      return this.displayColumns.toString() !== this.leadListSelectedColumns.toString()
    },
    filterParams() {
      if (this.leadView?.filterParams) {
        return camelcaseKeys(this.$JSON.parse(this.leadView?.filterParams), { deep: true, exclude: [/^custom_field_.*[^items]/g] })
      } else {
        return {}
      }
    },
    displayColumns() {
      if (this.leadView?.displayColumns) {
        return this.$JSON.parse(this.leadView?.displayColumns)
      } else {
        return [
          'companyName',
          'targetName',
          'lastActivityAt',
          'lastActivityTypeText',
          'lastActivityComment',
          'nextActions',
          'callTargetCreatedAt',
          'operatorName',
        ]
      }
    },
    sortOrder() {
      if (this.leadView?.sortOrder) {
        return this.leadView?.sortOrder
      } else {
        return 'desc'
      }
    },
    sortKey() {
      if (this.leadView?.sortKey) {
        return this.leadView?.sortKey
      } else {
        return 'callTargetCreatedAt'
      }
    },
  },
}

export default mixin

<template>
  <div
    class="content-wrapper"
    :class="classes"
  >
    <BHeightAdjuster>
      <Navbar v-if="!unvisibleNavbar">
        <slot name="navbar" />
      </Navbar>
      <div class="content">
        <slot />
      </div>
    </BHeightAdjuster>
    <div
      v-if="$slots.footer"
      class="footer"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/organisms/user/Navbar.vue'

export default {
  components: {
    Navbar,
  },
  props: {
    unvisibleNavbar: Boolean,
  },
  computed: {
    classes () {
      return {
        'is-navbar-visible': !this.unvisibleNavbar,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .content-wrapper {
    @include m-fixed-width(calc(100% - #{$sidebar-width}));
    @include m-fixed-height($page-height);
    margin-top: $header-height;
    transition: $transition-base;
    background-color: $bgcolor-white;

    &.is-navbar-visible {
      .content {
        @include m-fixed-height($page-height-with-navbar);
      }
    }

    .content {
      @include m-fixed-width(100%);
      overflow: auto;
    }

    .footer {
      width: 100vw;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: $bgcolor-dark;
    }
  }
</style>

<template>
  <BContentBox :is-footer="$can('owner')">
    <template #content>
      <div
        v-loading="loading"
      >
        <BLayout
          justify-center
          column
          class="form"
        >
          <BListItem class="mb-300">
            <template #header>
              <div>{{ $t('client.slug') }}</div>
            </template>
            <BInput
              v-model="internalClient.slug"
              class="w-input"
              :disabled="true"
            />
          </BListItem>
          <BListItem class="mb-300">
            <template #header>
              <div>{{ $t('client.name') }}</div>
            </template>
            <BInput
              v-model="internalClient.name"
              v-model:valid="clientNameValid"
              class="w-input"
              :disabled="!$can('owner')"
              :rules="[requiredRule]"
            />
          </BListItem>
          <BListItem class="mt-50">
            <BBtn
              type="primary"
              :disabled="!isValid || loading || !$can('owner')"
              :loading="loading"
              @click="save"
            >
              <span>{{ $t(`general.save.text`) }}</span>
            </BBtn>
          </BListItem>
        </BLayout>
      </div>
    </template>
  </BContentBox>
</template>

<script>
import lodash from 'lodash'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import errorHandler from '@/mixins/error_handler'
import inputValidation from '@/mixins/input_validation'

export default {
  mixins: [inputValidation, errorHandler],
  data () {
    return {
      internalClient: {},
      clientNameValid: false,
      form: {
        account: this.account,
        companyName: this.companyName,
      },
    }
  },
  computed: {
    ...mapGetters('user', [
      'client',
      'currentUser',
    ]),
    loading () {
      return this.$wait.is('getClientWait') || this.$wait.is('updateClientWait')
    },
    isValid () {
      return this.clientNameValid
    },
  },
  watch: {
    client (newVal) {
      this.internalClient = lodash.cloneDeep(newVal)
    },
  },
  created () {
    Promise.all([
      this.getClientAction({ id: this.currentUser.clientId }),
    ])
    this.internalClient = lodash.cloneDeep(this.client)
  },
  methods: {
    ...mapWaitingActions('user', {
      getClientAction: 'getClientWait',
      updateClientAction: 'updateClientWait',
    }),
    save () {
      if (!this.clientNameValid) return
      this.updateClientAction({
        id: this.client.id,
        body: {
          client: {
            name: this.internalClient.name,
          },
        },
        errorHandlers: {
          422: this.defaultHandler,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  @media only screen and (min-width: 959px) {
    .w-input {
      width: 40%;
    }
  }
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { KEY_OF_TOAST } from '@/injection-keys';

type TProps = {
  text: string;
  title: string;
  isDetailedName?: boolean;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'BCopyButton',
  props: {
    text: {},
    title: {},
    isDetailedName: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;
const toast = inject(KEY_OF_TOAST);
const i18n = useI18n();

const buttonName = computed(() => props.isDetailedName ? i18n.t('general.copyAction.to', { item: props.title }) : i18n.t('general.copyAction.text'));

const handleCopyClick = () => {
  navigator.clipboard.writeText(props.text);
  toast.show({
    message: i18n.t('general.copyAction.done', { item: props.title }),
    type: 'success',
  });
};

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!

  return (_openBlock(), _createBlock(_component_BBtn, {
    type: "default",
    size: "mini",
    class: "copy-button",
    outline: "",
    onClick: handleCopyClick
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BIcon, { size: "mini" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" content_copy ")
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(buttonName.value), 1)
    ]),
    _: 1
  }))
}
}

})
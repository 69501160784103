<template>
  <BCard>
    <BListItem class="mb-400">
      <div>{{ $t('limit.title') }}</div>
    </BListItem>
    <BListItem
      v-for="(value, key) in internalAvailableFeature"
      :key="key"
      class="mb-400"
    >
      <template #header>
        <span>{{ $t(`availableFeatureManagement.feature.${key}`) }}</span>
      </template>
      <div class="b-switch-btn">
        <BLayout wrap>
          <BFlex :span="12">
            <div
              class="b-switch-btn-item available"
              :class="{ 'selected': value }"
              @click="() => handleStatus(key, available.value)"
            >
              {{ available.text }}
            </div>
          </BFlex>
          <BFlex :span="12">
            <div
              class="b-switch-btn-item not-available"
              :class="{ 'selected': !value }"
              @click="() => handleStatus(key, notAvailable.value)"
            >
              {{ notAvailable.text }}
            </div>
          </BFlex>
        </BLayout>
      </div>
    </BListItem>
    <BListItem class="mb-400">
      <div>{{ $t('beta.title') }}</div>
    </BListItem>
    <BListItem
      v-for="(value, key) in internalAvailableBetaFeature"
      :key="key"
      class="mb-400"
    >
      <template #header>
        <span>{{ $t(`availableFeatureManagement.feature.${key}`) }}</span>
      </template>
      <div class="b-switch-btn">
        <BLayout wrap>
          <BFlex :span="12">
            <div
              class="b-switch-btn-item available"
              :class="{ 'selected': value }"
              @click="() => handleBetaStatus(key, available.value)"
            >
              {{ available.text }}
            </div>
          </BFlex>
          <BFlex :span="12">
            <div
              class="b-switch-btn-item not-available"
              :class="{ 'selected': !value }"
              @click="() => handleBetaStatus(key, notAvailable.value)"
            >
              {{ notAvailable.text }}
            </div>
          </BFlex>
        </BLayout>
      </div>
    </BListItem>
    <BLayout
      align-center
      justify-center
    >
      <BBtn
        class="mr-400"
        text
        @click="cancel"
      >
        {{ $t(`general.cancel.text`) }}
      </BBtn>
      <BBtn
        class="ml-400"
        type="primary"
        :loading="loading"
        @click="save"
      >
        {{ $t(`general.save.text`) }}
      </BBtn>
    </BLayout>
  </BCard>
</template>

<script>
export default {
  name: 'SwitchAvailableFeatureForm',
  props: {
    availableFeature: {
      type: Object,
      required: true,
    },
    availableBetaFeature: {
      type: Object,
      required: true,
    },
    loading: Boolean,
  },
  emits: [
    'save',
    'cancel',
  ],
  data () {
    return {
      available: { text: this.$t('availableFeatureManagement.featureStatus.enum.available'), value: true },
      notAvailable: { text: this.$t('availableFeatureManagement.featureStatus.enum.notAvailable'), value: false },
      internalAvailableFeature: this.availableFeature,
      internalAvailableBetaFeature: this.availableBetaFeature,
    }
  },
  methods: {
    handleStatus (key, value) {
      this.internalAvailableFeature[key] = value
    },
    handleBetaStatus (key, value) {
      this.internalAvailableBetaFeature[key] = value
    },
    save () {
      this.$emit('save', this.internalAvailableFeature, this.internalAvailableBetaFeature)
    },
    cancel () {
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="scss" scoped>
  .b-switch-btn-item {
    width: 100%;
    text-align: center;
    border: 1px solid $bdcolor-base;
    color: $textcolor-base;
    padding: $basespace-100;
    cursor: pointer;
    transition: all 200ms $standard-easing;

    &.available {
      background-color: rgba($basecolor-hot, .1);
      color: $basecolor-hot;
      border-color: rgba($basecolor-hot, .1);
      border-radius: 8px 0 0 8px;

      &.selected {
        background-color: $basecolor-hot;
        color: $textcolor-white;
        border-color: $basecolor-hot;
      }
    }

    &.not-available {
      background-color: rgba($basecolor-cold, .1);
      color: $basecolor-cold;
      border-color: rgba($basecolor-cold, .1);
      border-radius: 0 8px 8px 0;

      &.selected {
        background-color: $basecolor-cold;
        color: $textcolor-white;
        border: 1px solid $basecolor-cold;
      }
    }
  }
</style>

<template>
  <BFlex
    v-loading="settingLoading"
    class="user-sender-email-confirm"
  >
    <template v-if="!settingLoading">
      <div v-if="success">
        <BAlert
          :text="i18n.t('general.done.withTarget', { action: i18n.t('general.update'), target: i18n.t('senderEmail.title') })"
          class="success"
          type="success"
          show-icon
        />
      </div>
      <div v-else>
        <BAlert
          :text="i18n.t('general.fail.withTarget', { action: i18n.t('general.update'), target: i18n.t('senderEmail.title') })"
          class="alert"
          type="error"
          show-icon
        />
      </div>
      <div class="mt-600">
        <BBtn
          :disabled="settingLoading"
          :loading="settingLoading"
          @click="toHome"
        >
          {{ $t('senderEmail.toTopPage') }}
        </BBtn>
      </div>
    </template>
  </BFlex>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { UserSenderEmailApiService } from '@/api/user/resources/user_sender_email';
import { useWait } from '@/composable/vue-wait';
import { i18nGlobal as i18n } from '@/i18n';
import { bitterAlert } from '@/plugins/BBitterAlert';

const success = ref<boolean>(true);
const route = useRoute();
const router = useRouter();
const {
  wait,
  doActionWithWait,
} = useWait();

onBeforeMount(async () => {
  const confirmationToken = route.params.token as string;
  confirmUserSenderEmail(confirmationToken);
});

const settingLoading = computed(() => wait.is(['confirmUserSenderEmailWait']));

const confirmUserSenderEmail = async (confirmationToken: string) => {
  const api = new UserSenderEmailApiService();
  doActionWithWait('confirmUserSenderEmailWait', async () => {
    await api.confirmUserSenderEmail({
      request: {
        userSenderEmailConfirmBody: {
          confirmationToken,
        },
      },
      errorHandlers: {
        404: errorHandler,
        422: errorHandler,
      },
    });
  });
};

const errorHandler = () => {
  success.value = false;
  bitterAlert.show({
    title: i18n.t('changeEmail.confirmFailed.title'),
    text: i18n.t('changeEmail.confirmFailed.message', { itemName: i18n.t('senderEmail.title') }),
    buttonsCancel: false,
  });
}

const toHome = () => {
  router.push({ name: 'Home' })
};
</script>

<style lang="scss" scoped>
  .user-sender-email-confirm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // NOTE: 要素の高さの約半分が50px
    margin-top: calc(50vh - 50px);
  }
</style>

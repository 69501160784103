import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, isRef as _isRef, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "sequence-next-action-container" }
const _hoisted_2 = { class: "mt-100 immediately" }
const _hoisted_3 = {
  key: 1,
  class: "time-items"
}
const _hoisted_4 = { class: "footer" }

import { computed, ref } from 'vue';
import { useStepModal } from '@/composable/user/sequence/step-form-modal';
import NextActionFormBody, { TCommonNextAction } from '../NextActionFormBody.vue';
import { TFormSequenceStep, TPartialFormNextActionSequenceStep } from './types';

type TProps = {
  sequenceStep?: TPartialFormNextActionSequenceStep;
  isOpen?: boolean;
  loading?: boolean;
};

type TEmits = {
  'click:save': [sequenceStep: TFormSequenceStep];
  'click:cancel': [];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceNextActionForm',
  props: {
    sequenceStep: { default: null },
    isOpen: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  emits: ["click:save", "click:cancel"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const {
  internalIsOpen,
  internalSequenceStep,
} = useStepModal<TFormSequenceStep>(
  () => props.isOpen,
  () => ({
    sortOrder: 1,
    actionType: null,
    priority: 'middle',
    dateInterval: 1,
    reservedTime: null,
    nextActionContent: '',
    ...props.sequenceStep,
  }),
);

const isDateIntervalValid = ref(false);
const isNextActionValid = ref(false);
const invalidSequenceStep = computed(() => {
  return !internalSequenceStep.value.actionType
    || !isDateIntervalValid.value && !internalSequenceStep.value.immediately
    || !internalSequenceStep.value.reservedTime && !internalSequenceStep.value.immediately
    || !isNextActionValid.value;
});
const nextActionItems = computed<TCommonNextAction>({
  get: () => ({
    actionType: internalSequenceStep.value.actionType,
    priority: internalSequenceStep.value.priority,
    content: (internalSequenceStep.value as TPartialFormNextActionSequenceStep).nextActionContent,
  }),
  set: (val) => {
    internalSequenceStep.value.actionType = val.actionType;
    internalSequenceStep.value.priority = val.priority;
    (internalSequenceStep.value as TPartialFormNextActionSequenceStep).nextActionContent = val.content;
  },
});

const handleCancel = () => {
  emit('click:cancel');
};

const handleSaveClick = () => {
  emit('click:save', internalSequenceStep.value);
};

return (_ctx: any,_cache: any) => {
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BTimePicker = _resolveComponent("BTimePicker")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BDrawerMenu = _resolveComponent("BDrawerMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BDrawerMenu, {
      modelValue: _unref(internalIsOpen),
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(internalIsOpen) ? (internalIsOpen).value = $event : null)),
      title: _ctx.$t('sequence.step.types.nextAction'),
      onCancel: handleCancel
    }, _createSlots({
      default: _withCtx(() => [
        (_unref(internalSequenceStep))
          ? (_openBlock(), _createBlock(NextActionFormBody, {
              key: 0,
              modelValue: nextActionItems.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((nextActionItems).value = $event)),
              "is-valid": isNextActionValid.value,
              "onUpdate:isValid": _cache[4] || (_cache[4] = ($event: any) => ((isNextActionValid).value = $event)),
              "with-owner": false
            }, {
              dateTime: _withCtx(() => [
                (_unref(internalSequenceStep).immediately)
                  ? (_openBlock(), _createBlock(_component_BListItem, {
                      key: 0,
                      class: "mb-300"
                    }, {
                      header: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('sequence.step.dateInterval')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('general.startImmediately')), 1)
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_BListItem, { class: "mb-300" }, {
                        header: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('sequence.step.dateInterval')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_BInput, {
                            modelValue: _unref(internalSequenceStep).dateInterval,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(internalSequenceStep).dateInterval) = $event)),
                            valid: isDateIntervalValid.value,
                            "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => ((isDateIntervalValid).value = $event)),
                            type: "number",
                            max: 30,
                            min: 0,
                            validation: "",
                            required: "",
                            "needs-parse-int": ""
                          }, null, 8, ["modelValue", "valid"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_BListItem, { class: "mb-300" }, {
                        header: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('sequence.step.reservedTime')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_BTimePicker, {
                            modelValue: _unref(internalSequenceStep).reservedTime,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(internalSequenceStep).reservedTime) = $event)),
                            "value-format": "HH:mm",
                            format: "HH:mm",
                            required: ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]))
              ]),
              _: 1
            }, 8, ["modelValue", "is-valid"]))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (_unref(internalSequenceStep))
        ? {
            name: "footer",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_BLayout, {
                  "align-center": "",
                  "justify-center": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BBtn, {
                      class: "mr-300",
                      text: "",
                      loading: _ctx.loading,
                      onClick: handleCancel
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('general.cancel.text')), 1)
                      ]),
                      _: 1
                    }, 8, ["loading"]),
                    _createVNode(_component_BBtn, {
                      disabled: invalidSequenceStep.value,
                      loading: _ctx.loading,
                      class: "ml-300",
                      type: "primary",
                      onClick: handleSaveClick
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('general.save.text')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "loading"])
                  ]),
                  _: 1
                })
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["modelValue", "title"])
  ]))
}
}

})
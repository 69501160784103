<template>
  <ElTooltip
    v-bind="attributes"
    v-model:visible="internalValue"
    :show-arrow="showArrow"
  >
    <template #content>
      <slot name="content" />
    </template>
    <slot />
  </ElTooltip>
</template>

<script>
export default {
  props: {
    top: Boolean,
    bottom: {
      type: Boolean,
      default: false,
    },
    bottomEnd: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    content: String,
    enterable: Boolean,
    showArrow: {
      type: Boolean,
      default: true,
    },
    value: Boolean,
  },
  emits: [
    'input',
  ],
  data() {
    return {
      internalValue: this.value,
    }
  },
  computed: {
    attributes () {
      return {
        placement: this.placement,
        disabled: this.disabled,
        content: this.content,
        enterable: this.enterable,
      }
    },
    placement () {
      if (this.top) {
        return 'top'
      } else if (this.bottom) {
        return 'bottom'
      } else if (this.bottomEnd) {
        return 'bottom-end'
      } else if (this.right) {
        return 'right'
      } else {
        return null
      }
    },
  },
  watch: {
    value(v) {
      this.internalValue = v
    },
    internalValue(v) {
      this.$emit('input', v)
    },
  },
}
</script>

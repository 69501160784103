import { ActionTree } from 'vuex';
import { ApiArguments } from '@/api/api_service';
import { ZoomSettingApiService } from '@/api/user/resources/zoom_setting';
import { TCallResultType, TDirection } from '@/services/zoom_phone_types';
import { RootState, ZoomPhoneState } from '@/store/types';
import Waiting from '@/store/waiting';
import { types } from './mutations'

const actions: ActionTree<ZoomPhoneState, RootState> = {
  setOutboundCallingAction ({ commit }): void {
    commit(types.SET_DIRECTION, 'outbound')
    commit(types.SET_IS_CALLING, true)
  },
  setCallStartedInfoAction ({ commit }, args: { id: string, direction: TDirection, startedAt: string }): void {
    commit(types.RESET_CALL_INFO)
    commit(types.SET_CALL_STARTED_INFO, args)
    commit(types.SET_DIRECTION, args.direction)
    commit(types.SET_IS_CALLING, true)
  },
  setCallConnectedInfoAction ({ state, commit }, args: { id: string, direction: TDirection, connectedAt: string }): void {
    if (args.id !== state.callInfo.id) {
      commit(types.RESET_CALL_INFO)
    }
    commit(types.SET_CALL_CONNECTED_INFO, args)
    commit(types.SET_DIRECTION, args.direction)
    commit(types.SET_IS_CALLING, true)
  },
  setCallEndedInfoAction ({ state, commit }, args: { id: string, direction: TDirection, endedAt: string, result: TCallResultType }): void {
    if (args.id !== state.callInfo.id) {
      commit(types.RESET_CALL_INFO)
    }
    commit(types.SET_CALL_ENDED_INFO, args)
    commit(types.SET_DIRECTION, args.direction)
    commit(types.SET_IS_CALLING, false)
  },
}

const apiActions: ActionTree<ZoomPhoneState, RootState> = {
  getZoomSettingAction: async (
    { commit },
    apiArguments: ApiArguments<undefined>,
  ): Promise<boolean> => {
    await waiting.check('getZoomSettingAction', async () => {
      try {
        const api = new ZoomSettingApiService();
        const { data: { zoomSetting } } = await api.getZoomSetting(apiArguments);
        const isZoomSettingEmpty = !Object.keys(zoomSetting).length;
        commit(types.SET_ZOOM_SETTING, isZoomSettingEmpty ? null : zoomSetting);
      } catch (err) {
        commit(types.CLEAR_ZOOM_SETTING);
        throw err;
      }
    });
    return true;
  },
  deleteZoomSettingAction: async (
    { commit },
    apiArguments: ApiArguments<undefined>,
  ): Promise<boolean> => {
    await waiting.check('deleteZoomSettingAction', async () => {
      try {
        const api = new ZoomSettingApiService();
        const { data } = await api.deleteZoomSetting(apiArguments);
        if (data) {
          commit(types.CLEAR_ZOOM_SETTING);
        } else {
          throw new Error('failed to delete ZoomSetting');
        }
      } catch (err) {
        throw err;
      }
    });
    return true;
  },
};

const waiting = new Waiting(apiActions);

export default {
  ...actions,
  ...apiActions,
}

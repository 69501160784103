import lodash from 'lodash';
import { computed } from 'vue';
import { PartialSequenceStepMaster } from '@/api/openapi';
import {  TFormSequenceStep, TWithDaysPartialSequenceStepMaster } from '@/components/organisms/user/general/sequence/types';

type TStep = PartialSequenceStepMaster | TFormSequenceStep;
type TStepWithDay = TWithDaysPartialSequenceStepMaster | TFormSequenceStep;

const isTFormSequenceStep = (step: TStep): step is TFormSequenceStep => {
  return 'status' in step;
}

const hasDateIntervalStep = (step: TStep) => {
  return !isTFormSequenceStep(step) || step.status !== 'skipped' && !step.immediately;
};

const useWithDaysSequenceSteps = <
  TConvertedStep extends TStep,
  TStep extends TStepWithDay = TStepWithDay,
>(
  getSequenceSteps: () => (PartialSequenceStepMaster | TFormSequenceStep)[],
  cast?: (step: TStep) => TConvertedStep,
) => {
  const withDaysSequenceSteps = computed<TConvertedStep[]>(() => {
    // NOTE: 1日目から開始
    let dayCount = 1;
    return lodash.cloneDeep(getSequenceSteps()).map((step: PartialSequenceStepMaster | TFormSequenceStep) => {
      if (hasDateIntervalStep(step)) {
        dayCount += step.dateInterval;
      }
      const withDay = { ...step, day: dayCount } as TStep;
      return cast != null ? cast(withDay) : (withDay as TConvertedStep);
    });
  });

  return {
    withDaysSequenceSteps,
  };
};

export {
  useWithDaysSequenceSteps,
  hasDateIntervalStep,
}

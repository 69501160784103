<template>
  <BModal
    :modal-name="modalName"
    :delegate="bModalDelegate"
    width="650px"
    height="385px"
    @open-modal-event="handleOpen"
  >
    <BHeightAdjuster>
      <BModalHeader
        center
        class="modal-header"
        :title="$t('callTarget.destroyAll.headerTitle')"
        @modal-close="handleClose"
      />
      <BModalBody
        v-if="!completed"
        v-loading="
          $wait.is('getCallTargetsCountWait') ||
            $wait.is('deleteAllCallTargetsWait')
        "
        class="modal-body"
      >
        <div class="message mt-300 mb-400">
          <span>{{ $t('callTarget.destroyAll.message') }}</span>
        </div>
        <div class="count">
          <p>
            {{ $t('callTarget.destroyAll.count') }}
            <span class="target">{{
              `${target}${$t('callTarget.destroyAll.unit')}`
            }}</span>
            / {{ `${callTargetsCount}${$t('callTarget.destroyAll.unit')}` }}
          </p>
        </div>
        <BCheckbox
          v-if="$can('owner')"
          v-model="agreed"
          class="check mt-500 mb-300"
          :label="$t('callTarget.destroyAll.checkMessage')"
          center
        />
        <BBtn
          type="primary"
          :loading="
            $wait.is('getCallTargetsCountWait') ||
              $wait.is('deleteAllCallTargetsWait')
          "
          :disabled="!deleteBtnEnabled"
          @click="handleDelete"
        >
          {{ $t('general.delete.text') }}
        </BBtn>
        <p
          v-if="$can('owner')"
          class="caution mt-500"
        >
          {{ $t('callTarget.destroyAll.caution') }}
        </p>
        <p
          v-else
          class="caution mt-500"
        >
          {{ $t('callTarget.destroyAll.ownerOnly') }}
        </p>
      </BModalBody>
      <BModalBody
        v-else
        class="modal-body"
      >
        <p class="completed-text">
          {{ $t('callTarget.destroyAll.completed') }}
        </p>
      </BModalBody>
    </BHeightAdjuster>
  </BModal>
</template>

<script>
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'

export default {
  name: 'AllLeadDeleteModal',
  data() {
    return {
      agreed: false,
      completed: false,
      bModalDelegate: {
        beforeClose: this.beforeClose,
      },
    }
  },
  computed: {
    ...mapGetters('user', [
      'callTargets',
      'callTargetsCount',
      'leadView',
      'callTargetsMeta',
    ]),
    ...mapGetters('userUi', ['leadListFilterParams']),
    modalName() {
      return 'AllLeadDeleteModal'
    },
    target() {
      return this.callTargetsMeta.totalCount
    },
    deleteBtnEnabled() {
      return this.$can('owner') && this.agreed && this.target > 0
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      getCallTargetsCountAction: 'getCallTargetsCountWait',
      deleteAllCallTargetsAction: 'deleteAllCallTargetsWait',
      getLeadDeleteTasksAction: 'getLeadDeleteTasksWait',
    }),
    async handleOpen() {
      await this.getCallTargetsCountAction()
    },
    beforeClose() {
      this.agreed = false
      this.completed = false
    },
    handleClose() {
      this.$modal.hide(this.modalName)
    },
    async handleDelete() {
      const isTargetAll = this.target === this.callTargetsCount
      if (isTargetAll) {
        const check = await this.$bitterAlert.show({
          title: this.$t('general.confirm.text'),
          text: this.$t('callTarget.destroyAll.confirm'),
        })
        if (!check) {
          return
        }
      }

      this.deleteAllCallTargetsAction({
        params: {
          lf: this.leadListFilterParams,
        },
      })
        .then((res) => {
          this.getLeadDeleteTasksAction()
          this.completed = true
        })
        .catch((e) => {
          this.$bitterAlert.show({
            title: this.$t('general.error'),
            text: this.$t('apiError.internalServerError'),
            buttonsCancel: false,
          })
          this.agreed = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
  .modal-body {
    text-align: center;
  }

  .count {
    background-color: $concrete-light;
    border-radius: $basespace-100;
    p {
      font-size: larger;
      padding: 14px 0;
    }
  }

  .target {
    font-weight: bolder;
  }

  .caution {
    margin-bottom: 0px;
    color: $charcoal;
  }

  .completed-text {
    margin-top: 35px;
    font-size: larger;
    white-space: pre;
    line-height: 3;
  }
</style>

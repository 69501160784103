<template>
  <BLayout
    align-center
    class="direct-mail"
  >
    <div class="mr-400">
      <BUserTooltip
        :image-url="directMail.owner.avatarUrl"
        :name="directMail.owner.name"
        :user-id="directMail.owner.id"
        :size="40"
      />
    </div>
    <BCustomIcon
      v-if="isSequenceDirectMail"
      icon-class="b-sequence"
      class="mr-400"
      size="medium"
    />
    <div class="direct-mail-title">
      <span class="direct-mail-title-title">{{ $t('sendMailDirect.title') }}</span>
      <BStatus
        class="mt-50"
        :status="directMail.status"
        :status-name="$t(`mail.status.${directMail.status}`)"
      />
    </div>
    <div
      class="direct-mail-content"
    >
      {{ $t('mail.titlePlaceholder') }}：{{ directMail.subject }}
    </div>
    <div class="direct-mail-support">
      <div class="direct-mail-reserved-at">
        {{ displayDatetime }}
      </div>
      <div
        class="direct-mail-action-area"
        @click.stop="handleClickConfirm"
      >
        <span>{{ $t('general.confirm.do') }}</span>
        <BIcon size="small">
          chevron_right
        </BIcon>
      </div>
    </div>
    <div
      v-if="!isSequenceDirectMail"
      class="px-100"
      @click.stop
    >
      <BMoreMenu attach>
        <BBtn
          flat
          fit
          size="small"
          @click="handleDelete"
        >
          {{ $t(`general.delete.do`) }}
        </BBtn>
      </BMoreMenu>
    </div>
  </BLayout>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex'
import { DirectMail, ReserveDirectMail } from '@/api/openapi';
import { useCheckRelationToSequence } from '@/composable/user/directMail/direct-mail';
import { i18nGlobal as i18n } from '@/i18n';
import { bitterAlert } from '@/plugins/BBitterAlert'
import { key } from '@/store/index'
import { altText } from '@/utils/alt';
import { formatShorterDateTime } from '@/utils/date-time';

type TProps = {
  directMail: DirectMail | ReserveDirectMail;
  displayDatetimeColumn?: 'updatedAt' | 'reservedAt';
};

const props = withDefaults(defineProps<TProps>(), {
  displayDatetimeColumn: 'updatedAt',
});

const emit = defineEmits(['click:confirm', 'afterDelete'])

const { isSequenceDirectMail } = useCheckRelationToSequence(() => props.directMail);

const store = useStore(key);

const displayDatetime = computed(() => altText(formatShorterDateTime(props.directMail[props.displayDatetimeColumn])));
const handleClickConfirm = () => emit('click:confirm');
const handleDelete = async () => {
  if (!props.directMail.id) { return }
  const check = await bitterAlert.show({
    title: i18n.t('sfTaskParams.directMail.subject'),
    text: i18n.t('general.confirm.of', { action: i18n.t('general.delete.text') }),
  })
  if (!check) { return }
  try {
    await store.dispatch('user/deleteDirectMailAction', {
      request: { directMailId: props.directMail.id },
    });
  } finally {
    emit('afterDelete')
  }
};
</script>

<style lang="scss" scoped>
.direct-mail-title {
  @include m-fixed-width(240px);
  &-title {
    font-weight: 600;
  }
}

.direct-mail-content {
  width: 100%;
}

.direct-mail-support {
  @include m-fixed-width(120px);
  font-size: $fontsize-100;
  text-align: right;
  margin-left: auto;
}

.direct-mail-reserved-at {
  color: $textcolor-light;
}

.direct-mail {
  .direct-mail-action-area {
    display: none;
  }

  &:hover {
    background: $bgcolor-base;
    cursor: pointer;

    .direct-mail-reserved-at {
      display: none;
    }

    .direct-mail-action-area {
      display: block;
    }
  }
}
</style>

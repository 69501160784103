<template>
  <div
    class="card"
    :class="cardClass"
  >
    <div
      v-if="title"
      class="card-header"
    >
      <div class="header-title">
        <BIcon
          v-if="icon"
          class="title-icon"
        >
          {{ icon }}
        </BIcon>
        <div
          v-if="title"
          class="title-text"
        >
          {{ title }}
        </div>
      </div>
      <slot name="header" />
    </div>
    <div class="card-body test">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    small: Boolean,
    border: Boolean,
    shadow: Boolean,
    background: Boolean,
    full: Boolean,
    transition: Boolean,
    title: String,
    icon: String,
    pointer: Boolean,
  },
  computed: {
    cardClass () {
      return {
        'card-border': this.border,
        'card-small': this.small,
        'card-shadow': this.shadow,
        'card-background': this.background,
        'card-full': this.full,
        'card-transition': this.transition,
        'card-pointer': this.pointer,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .card {
    display: block;
    position: relative;
    border-radius: 6px;
    background-color: $bgcolor-white;
    padding: 32px;
  }

  .card-full {
    padding: 0;
  }

  .card-small {
    padding: 16px;
  }

  .card-shadow {
    box-shadow: $boxshadow-base;
  }

  .card-border {
    border: 1px solid $bdcolor-base;
  }

  .card-background {
    background-color: $bgcolor-dark;
  }

  .card-header {
    display: flex;
    margin-bottom: 20px;

    .header-title {
      display: flex;
      align-items: center;
    }

    .title-icon {
      font-size: 20px;
      margin-right: 6px;
    }

    .title-text {
      font-size: 20px;
    }
  }

  .card-transition {
    transition: 300ms $standard-easing;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 1px 6px 0 $shadowcolor-base;
    }

    // &:active {
    //   transform: scale(0.99);
    //   box-shadow: 0 1px 6px 0 $shadowcolor-base;
    // }
  }

  .card-pointer {
    cursor: pointer;
  }

  .space-b {
    margin-bottom: 20px;
  }
</style>

import Waiting from '@/store/waiting'

export default class ClientSendgridAuthActions {
  constructor (Api, types) {
    const actions = {
      async getClientSendgridAuthAction ({ commit }, args) {
        await waiting.check('getClientSendgridAuthAction', async () => {
          try {
            const res = await Api.getClientSendgrid(args)
            commit(types.SET_CLIENT_SENDGRID_AUTH, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },

      async updateClientSendgridAuthAction ({ dispatch }, args) {
        await waiting.check('updateClientSendgridAuthAction', async () => {
          try {
            await Api.updateClientSendgridAuth(args)
            await dispatch('getClientSendgridAuthAction', args)
          } catch (err) {
            throw err
          }
        })
      },

      async deleteClientSendgridAuthAction ({ dispatch }, args) {
        await waiting.check('deleteClientSendgridAuthAction', async () => {
          try {
            await Api.deleteClientSendgridAuth(args)
          } catch (err) {
            throw err
          }
        })
      },
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

import Waiting from '@/store/waiting'

export default class FreeActionActions {
  constructor (Api, types) {
    const actions = {
      async createFreeActionAction ({ commit, dispatch }, args) {
        await waiting.check('createFreeActionAction', async () => {
          const res =  await Api.createFreeAction(args)

          await dispatch('createFreeActionSfTaskAction', {
            body: {
              callTargetId: args.callTargetId,
              targetId: res.data.id,
            },
          })

          return
        })
      },
      async updateFreeActionAction ({ commit }, args) {
        await waiting.check('updateFreeActionAction', async () => {
          await Api.updateFreeAction(args)
        })
      },
      async deleteFreeActionAction ({ commit }, args) {
        await waiting.check('deleteFreeActionAction', async () => {
          await Api.deleteFreeAction(args)
          try {
            const res = await Api.getLeadHistories(args)
            commit(types.SET_LEAD_HISTORIES, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

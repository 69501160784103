<template>
  <ElBadge
    :value="value"
    :hidden="value == 0"
    type="primary"
  >
    <slot />
  </ElBadge>
</template>

<script>
export default {
  props: {
    value: Number,
  },
}
</script>

<style lang="scss" scoped>
  :deep(.el-badge__content.is-fixed)  {
    top: 5px;
    right: 15px;
  }
</style>

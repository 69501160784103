<template>
  <div
    v-loading="loading"
    class="lead"
  >
    <BHeightAdjuster class="call-target">
      <div class="target-header-area">
        <LeadHeader
          v-bind="callTargetHeaderProps"
          @change-target-page="changeTargetPage"
          @change-target-call-target="changeCallTarget"
          @close-modal="onCloseLeadModal"
          @click-mail-button="switchMailFormVisibility(true)"
        />
      </div>
      <BModalBody full>
        <BLayout class="page-inner">
          <div class="target-detail-area">
            <TargetDetail
              v-bind="callTargetDetailProps"
              :active-users="activeUsers"
              :operator="operator"
              v-on="callTargetDetailFunctions"
              @click-disp-sf-campaign-button="dispAllSalesforceCampaign =true"
              @click-mail-button="switchMailFormVisibility(true)"
              @click-email-notification="$modal.show('duplicatedList')"
              @click:sequence-apply="handleClickApplySequence"
            />
          </div>
          <div class="page-right">
            <div class="lead-history-area">
              <LeadHistory
                v-bind="leadHistoryProps"
                @select-direct-mail="handleSelectDirectMail($event)"
                @reply-to-received-mail="handleReplyToReceivedMail($event)"
                @after-delete-direct-mail="fetchDirectMailItems"
                @delete-lead-history="getCallTargetsBackground"
                @fetch-sequence-items="fetchSequenceItems"
                @fetch-next-action-items="fetchNextActionItems"
              />
            </div>
            <LeadMenu
              v-bind="targetMenuProps"
              ref="menu"
              :call-target="callTarget"
              @fetch-sequence-items="fetchSequenceItems"
            />
            <MailFormTransition
              :is-visible="targetMailFormVisible"
              style="height: 100%"
            >
              <MailForm
                :call-target-id="internalCallTargetId"
                :selected-direct-mail-id="selectedDirectMailId"
                :reply-to-received-mail-id="replyToReceivedMailId"
                @close="switchMailFormVisibility(false)"
                @reset="handleResetMailForm"
                @change="fetchDirectMailItems"
              />
            </MailFormTransition>
          </div>
        </BLayout>
        <DuplicatedListModal
          v-if="renderDuplicatedListModal"
          @close="forceRerenderDuplicatedListModal"
          @merged="onCloseLeadModal"
        />
        <SequenceApplyModal
          v-if="isAvailableSequenceFeature && callTarget?.id"
          modal-name="sequenceApplyModal"
          :call-target-id="callTarget.id"
          @after-apply="fetchSequenceItems"
          @close="handleCloseApplySequenceModal"
        />
      </BModalBody>
    </BHeightAdjuster>
  </div>
</template>

<script lang="ts">
import { AxiosResponse } from 'axios';
import { defineComponent } from 'vue'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import { GetReserveItems200Response } from '@/api/openapi';
import { DirectMailApiService } from '@/api/user/resources/direct_mail';
import { SequenceInstanceApiService } from '@/api/user/resources/sequence_instance';
import LeadHeader from '@/components/organisms/user/lead/LeadHeader.vue'
import LeadHistory from '@/components/organisms/user/lead/LeadHistory.vue'
import LeadMenu from '@/components/organisms/user/lead/LeadMenu.vue'
import TargetDetail from '@/components/organisms/user/lead/TargetDetail.vue'
import MailFormTransition from '@/components/organisms/user/mail/common/drawer/MailFormTransition.vue'
import MailForm from '@/components/organisms/user/mail/MailForm.vue'
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import { useUsers } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import errorHandler from '@/mixins/error_handler'
import queryParameter from '@/mixins/query_parameter'
import DuplicatedListModal from './modal/DuplicatedListModal.vue'
import SequenceApplyModal from './modal/SequenceApplyModal.vue';


const SEQUENCE_APPLY_MODAL_NAME = 'sequenceApplyModal';

export default defineComponent({
  components: {
    LeadHeader,
    TargetDetail,
    LeadHistory,
    LeadMenu,
    MailForm,
    MailFormTransition,
    DuplicatedListModal,
    SequenceApplyModal,
  },
  mixins: [queryParameter, errorHandler],
  beforeRouteUpdate(to, from, next) {
    if (this.isTargetEditing) {
      const answer = window.confirm(
        this.isCtiOutboundCalling
          ? this.$t('callTarget.changeRoute.isCalling')
          : this.$t('callTarget.changeRoute.isEditing'),
      )
      answer ? next() : next(false)
    } else {
      next()
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isTargetEditing) {
      const answer = window.confirm(
        this.isCtiOutboundCalling
          ? this.$t('callTarget.changeRoute.isCalling')
          : this.$t('callTarget.changeRoute.isEditing'),
      )
      answer ? next() : next(false)
    } else {
      next()
    }
  },
  props: {
    callTargetId: Number,
    callTargets: {
      type: Array,
      required: true,
    },
    callTargetsMeta: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'close-modal',
  ],
  setup() {
    const { promiseOfFetchAvailableFeatures, isAvailableSequenceFeature, isAvailableSalesforceApiFeature, isAvailableDirectMailFeature } = useAvailableFeatureCheck()
    const { activeUsers } = useUsers()
    const { doActionWithWait } = useWait();
    return {
      promiseOfFetchAvailableFeatures,
      isAvailableSequenceFeature,
      isAvailableSalesforceApiFeature,
      isAvailableDirectMailFeature,
      activeUsers,
      doActionWithWait,
    }
  },
  data() {
    return {
      internalCallTargetId: this.callTargetId,
      isTargetLoading: false,
      renderDuplicatedListModal: true,
      sequenceInstances: null,
      draftItems: [],
      reserveItems: [],
      selectedDirectMailId: null,
      replyToReceivedMailId: null,
      sfUserId: null,
      dispAllSalesforceCampaign: false,
      keyOfGetReserveItemsWait: 'getReserveItemsWait',
      isPageLoading: false,
    }
  },
  computed: {
    ...mapGetters('user', [
      'currentUser',
      'callTarget',
      'leadStages',
      'freeTags',
      'callTargetFreeTags',
      'miitelCompanyId',
      'miitelAccessKey',
      'leadSources',
      'callTargetLeadSources',
      'nextActionTypes',
      'defaultNextActionTypes',
      'callTargetUnsubscribe',
      'users',
      'operator',
    ]),
    ...mapGetters('userUi', [
      'isTargetEditing',
      'leadListPageParams',
      'targetMailFormVisible',
      'isCtiOutboundCalling',
    ]),
    callTargetHeaderProps() {
      return {
        callTargetId: this.internalCallTargetId,
        pageLoading: this.isTargetLoading,
        sfSyncSettingInfo: this.callTarget.sfSyncSettingInfo,
        callTargets: this.callTargets,
        callTargetsMeta: this.callTargetsMeta,
      }
    },
    callTargetDetailProps() {
      return {
        leadStages: this.leadStages,
        callTargetLeadStage: this.callTarget.leadStage,
        companyInfo: this.callTarget.companyInfo,
        callTargetInfo: this.callTarget.callTargetInfo,
        customFields: this.callTarget.customFields,
        freeTagCandidates: this.freeTags,
        callTargetFreeTags: this.callTargetFreeTags,
        leadSourceCandidates: this.leadSources,
        callTargetLeadSources: this.callTargetLeadSources,
        callTargetId: this.internalCallTargetId,
        callTargetUnsubscribe: this.callTargetUnsubscribe,
        duplicatedEmailsCount: this.callTarget.duplicatedEmailsCount,
        sequenceInstances: this.sequenceInstances,
        salesforceCampaigns: this.callTarget.salesforceCampaigns,
        dispAllSalesforceCampaign: this.dispAllSalesforceCampaign,
      }
    },
    callTargetDetailFunctions() {
      return {
        'update-call-target-info': this.updateCallTargetInfo,
        'update-company-info': this.updateCompanyInfo,
        'update-custom-field-value': this.updateCustomFieldValue,
        'delete-call-target': this.deleteCallTarget,
        'duplicate-call-target': this.duplicateCallTarget,
        'update-sequence-status': this.updateSequenceStatus,
        'change': this.getCallTargetsBackground,
        'subscribe': this.subscribe,
        'unsubscribe': this.unsubscribe,
      }
    },
    leadHistoryProps() {
      return {
        callTarget: this.callTarget,
        callTargetId: this.internalCallTargetId,
        draftItems: this.draftItems,
        reserveItems: this.reserveItems,
        pageLoading: this.isTargetLoading || this.isPageLoading,
        keyOfGetReserveItemsWait: this.keyOfGetReserveItemsWait,
      }
    },
    targetMenuProps() {
      return {
        callTargetId: this.internalCallTargetId,
        activeSequenceId: this.sequenceInstances?.findActive()?.id,
        miitelCompanyId: this.miitelCompanyId,
        miitelAccessKey: this.miitelAccessKey,
      }
    },
    targetContentLoading() {
      return this.$wait.is(['getNextActionsWait'])
    },
    loading() {
      return this.isTargetLoading || this.$wait.is([
        'deleteCallTargetActionWait',
        'duplicateCallTargetActionWait',
        'updateSequenceStatus',
      ])
    },
  },
  watch: {
    callTargetId(newVal) {
      this.internalCallTargetId = newVal
      this.setupCallTarget()
    },
  },
  created() {
    this.setupCallTarget()
    this.setIsDisabledBIconWithActionMenuCloseAction(true)
  },
  beforeUnmount() {
    this.clearCallTargetAction()
  },
  unmounted() {
    setTimeout(() => {
      this.setIsDisabledBIconWithActionMenuCloseAction(false)
    }, 100);
  },
  methods: {
    ...mapWaitingActions('user', {
      getCallTargetAction: 'getCallTargetWait',
      getCallTargetsAction: 'getCallTargetsWait',
      getCallTargetsBackgroundAction: {
        action: 'getCallTargetsAction',
        loader: 'getCallTargetsBackgroundWait',
      },
      getCallTargetsMetaAction: 'getCallTargetsMetaWait',
      updateCallTargetAction: 'updateCallTargetWait',
      deleteCallTargetAction: 'deleteCallTargetActionWait',
      duplicateCallTargetAction: 'duplicateCallTargetActionWait',
      updateCustomFieldValueAction: 'updateCustomFieldValueWait',
      getCallTargetFreeTagsAction: 'getCallTargetFreeTagsWait',
      getMiitelAuthAction: 'getMiitelAuthWait',
      getNextActionsAction: 'getNextActionsWait',
      getSequenceInstancesAction: 'getSequenceInstancesWait',
      getCallTargetLeadSourcesAction: 'getCallTargetLeadSourcesWait',
      getLeadHistoriesAction: 'getLeadHistoriesWait',
      getLeadSourcesAction: 'getLeadSourcesWait',
      getFreeTagsAction: 'getFreeTagsWait',
      getNextActionTypesAction: 'getNextActionTypesWait',
      getCallResultTypesAction: 'getCallResultTypesWait',
      getCallTargetUnsubscribeAction: 'getCallTargetUnsubscribeWait',
      createCallTargetUnsubscribeAction: 'createCallTargetUnsubscribeWait',
      deleteCallTargetUnsubscribeAction: 'deleteCallTargetUnsubscribeWait',
      getOperatorAction: 'getOperatorWait',
      getUsersAction: 'getUsersAction',
      clearCallTargetAction: 'clearCallTargetAction',
      getDraftItemsAction: 'getDraftItemsWait',
      getLeadStagesAction: 'getLeadStagesWait',
    }),
    ...mapWaitingActions('zoomPhone', {
      getZoomSettingAction: 'getZoomSettingWait',
    }),
    ...mapWaitingActions('userUi', {
      setTargetMenuVisibleResetAction: 'setTargetMenuVisibleResetWait',
      setTargetHeaderMoveButtonDisabledAction: 'setTargetHeaderMoveButtonDisabledWait',
      setTargetMailFormVisibleAction: 'setTargetMailFormVisibleActionWait',
      setLeadListPageParamsAction: 'setLeadListPageParamsWait',
      setIsDisabledBIconWithActionMenuCloseAction: 'setIsDisabledBIconWithActionMenuCloseActionWait',
      setTargetSequenceInstanceFormVisibleAction:
        'setTargetSequenceInstanceFormVisibleWait',
    }),
    async clearCallTarget() {
      this.draftItems = []
      this.reserveItems = []
      this.sequenceInstances = null
      await this.clearCallTargetAction()
    },
    async setupCallTarget() {
      this.isTargetLoading = true
      await Promise.all([
        this.clearCallTarget(),
        this.getLeadStagesAction(),
        this.getCallTargetFreeTagsAction({ id: this.internalCallTargetId }),
        this.getCallTargetLeadSourcesAction({ id: this.internalCallTargetId }),
        this.getCallTargetAction({ request: { callTargetId: this.internalCallTargetId } }),
        this.getNextActionsAction({ callTargetId: this.internalCallTargetId }),
        this.fetchDraftItems(),
        this.fetchReserveItemsIfNeeded(),
        this.fetchSequenceInstancesIfNeeded(),
        this.getZoomSettingAction(),
        this.getMiitelAuthAction(),
        this.getLeadHistoriesAction({
          callTargetId: this.internalCallTargetId,
        }),
        this.getCallTargetUnsubscribeAction({ id: this.internalCallTargetId }),
        this.getOperatorAction(this.internalCallTargetId),
        this.getLeadSourcesAction(),
        this.getFreeTagsAction(),
        this.getNextActionTypesAction(),
        this.getCallResultTypesAction(),
        this.getUsersAction(),
      ])
      this.dispAllSalesforceCampaign = false
      this.isTargetLoading = false
    },
    async fetchDraftItems() {
      const { data: { draftItems } } = await this.getDraftItemsAction({
        request: { callTargetId: this.internalCallTargetId },
      })
      this.draftItems = draftItems || []
    },
    async updateCustomFieldValue(customFieldValue) {
      try {
        await this.updateCustomFieldValueAction({
          id: this.internalCallTargetId,
          body: { customFieldValue },
          errorHandlers: {
            422: this.defaultHandler,
          },
        })
        this.setTargetHeaderMoveButtonDisabledAction(false)
        this.$toast.show(
          this.$t('general.done.of', { action: this.$t('general.save.text') }),
        )
        this.getCallTargetsBackground()
      } catch (e) {
        this.$toast.show({
          message: this.$t('general.fail.withTarget', {
            target: this.$t('customField.title'),
            action: this.$t('general.update'),
          }),
          type: 'error',
        })
      }
    },
    async updateCompanyInfo(companyInfo) {
      try {
        await this.updateCallTargetAction({
          id: this.internalCallTargetId,
          body: {
            callTargetDetail: companyInfo,
          },
          errorHandlers: {
            422: this.defaultHandler,
          },
        })
        this.setTargetHeaderMoveButtonDisabledAction(false)
        this.$toast.show(
          this.$t('general.done.of', { action: this.$t('general.save.text') }),
        )
        this.getCallTargetsBackground()
      } catch (e) {
        this.$toast.show({
          message: this.$t('general.fail.withTarget', {
            target: this.$t('companyInfo.title'),
            action: this.$t('general.update'),
          }),
          type: 'error',
        })
      }
    },
    async updateCallTargetInfo(callTargetInfo) {
      try {
        await this.updateCallTargetAction({
          id: this.internalCallTargetId,
          body: {
            callTargetDetail: callTargetInfo,
          },
          errorHandlers: {
            422: this.defaultHandler,
          },
        })

        this.setTargetHeaderMoveButtonDisabledAction(false)
        this.$toast.show(
          this.$t('general.done.of', { action: this.$t('general.save.text') }),
        )
        this.getCallTargetsBackground()
        if (callTargetInfo.hasOwnProperty('email'))
          await this.getCallTargetUnsubscribeAction({
            id: this.internalCallTargetId,
          })
      } catch (e) {
        this.$toast.show({
          message: this.$t('general.fail.withTarget', {
            target: this.$t('callTargetInfo.title'),
            action: this.$t('general.update'),
          }),
          type: 'error',
        })
      }
    },
    async deleteCallTarget(callTargetId) {
      const check = await this.$bitterAlert.show({
        title: this.$t('general.confirm.text'),
        text: this.$t('general.delete.message'),
      })
      if (!check) return
      await this.deleteCallTargetAction({ callTargetId })
      this.$toast.show(
        this.$t('general.done.of', { action: this.$t('general.delete.text') }),
      )
      this.getCallTargetsBackground()
      this.onCloseLeadModal()
    },
    async duplicateCallTarget(callTargetId) {
      const check = await this.$bitterAlert.show({
        title: this.$t('general.duplicate.title', { item: this.$t('callTarget.title') }),
        text: this.$t('general.duplicate.lead.message'),
      })
      if (!check) return
      this.duplicateCallTargetAction({ callTargetId, errorHandlers: { 422: this.defaultHandler } }).then(res => {
        this.$toast.show(
          this.$t('general.done.of', { action: this.$t('general.duplicate.text') }),
        )
        this.getCallTargetsBackground()
        this.changeCallTarget(res.data.duplicated_id)
      })
    },
    async updateSequenceStatus(action, sequenceInstanceId) {
      if (!['stop', 'restart', 'unregister'].includes(action)) return

      const check = await this.$bitterAlert.show({
        title: this.$t('general.confirm.text'),
        text: this.$t('general.confirm.of', { action: this.$t(`general.${action}.text`) }),
      })
      if (!check) return

      this.$wait.start('updateSequenceStatus')
      try {
        const params = {
          request: {
            bulkUpdateSequenceInstancesStatusBody: { sequenceInstanceIds: [sequenceInstanceId] },
          },
        }
        switch (action) {
          case 'stop':
            await new SequenceInstanceApiService().stopSequenceInstances(params)
            break;
          case 'restart':
            await new SequenceInstanceApiService().restartSequenceInstances(params)
            break;
          case 'unregister':
            await new SequenceInstanceApiService().unregisterSequenceInstances(params)
            break;
        }

        this.$toast.show(
          this.$t('general.done.of', { action: this.$t(`general.${action}.text`) }),
        )
        this.fetchSequenceItems();

        this.setTargetSequenceInstanceFormVisibleAction(false);
      } finally {
        this.$wait.end('updateSequenceStatus')
      }
    },
    async getCallTargetsBackground() {
      await this.getCallTargetsBackgroundAction()
    },
    async changeCallTarget(id) {
      if (this.isTargetEditing) {
        const check = await this.$bitterAlert.show({
          title: this.$t('general.confirm.text'),
          text: this.$t('general.alert.of', {
            target: this.$t('general.unsavedValue'),
            action: this.$t('general.clear'),
          }),
        })
        if (!check) {
          return
        }
      }
      this.setTargetMenuVisibleResetAction()
      this.internalCallTargetId = id
      this.setQueryParameter({ callTargetId: id })
      await this.setupCallTarget()
    },
    async changeTargetPage(callTargetId: number, page: number) {
      this.isPageLoading = true;
      this.changeCallTarget(callTargetId);

      // FIXME: mixinに依存しているため、ここに処理をコピペして記述
      // @see src/components/organisms/user/leadList/ListPageControl.vue
      const queryParams = {
        currentPage: page,
        // NOTE: フロント側のパラメタ（leadListPageParams）ではなくmetaから取得。呼び出し元の処理がそちらを信頼しているため
        currentDisplayNumber: this.callTargetsMeta?.limitValue || 100,
      }
      this.setLeadListPageParamsAction(queryParams)
      this.setQueryParameter(queryParams)
      // リスト・ページングデータ再取得
      await this.getCallTargetsBackgroundAction()
      await this.getCallTargetsMetaAction()
      this.isPageLoading = false;
    },
    closeWidget() {
      this.$refs.menu.closeWidget()
    },
    async subscribe(callTargetId) {
      await this.deleteCallTargetUnsubscribeAction({ id: callTargetId })
      this.$toast.show(
        this.$t('general.withdraw.of', { item: this.$t('unsubscribe.title') }),
      )
    },
    async unsubscribe(callTargetId) {
      await this.createCallTargetUnsubscribeAction({ id: callTargetId })
      this.$toast.show(
        this.$t('general.done.of', { action: this.$t('unsubscribe.title') }),
      )
    },
    switchMailFormVisibility(boolean) {
      if (this.callTarget.sfSyncSettingInfo?.sfUserId == null && this.callTarget.sfSyncSettingInfo?.directMailSfSync && this.isAvailableSalesforceApiFeature) {
        this.$bitterAlert.show({
          title: this.$t('callTarget.errors.salesforceUnableToLinkUser.title'),
          text: this.$t('callTarget.errors.salesforceUnableToLinkUser.text'),
        })
      } else {
        this.setTargetHeaderMoveButtonDisabledAction(boolean)
        this.setTargetMailFormVisibleAction(boolean)
      }
    },
    handleResetMailForm() {
      this.selectedDirectMailId = null
      this.replyToReceivedMailId = null
    },
    handleReplyToReceivedMail(receivedMailId) {
      this.replyToReceivedMailId = receivedMailId
      this.switchMailFormVisibility(true)
    },
    handleSelectDirectMail(directMail) {
      this.selectedDirectMailId = directMail.id
      this.switchMailFormVisibility(true)
    },
    forceRerenderDuplicatedListModal() {
      // remove the component
      this.renderDuplicatedListModal = false
      this.$nextTick(() => {
        // add the component back in
        this.renderDuplicatedListModal = true
      })
    },
    onCloseLeadModal(): void {
      this.$emit('close-modal')
    },
    async fetchSequenceInstances() {
      this.sequenceInstances = await this.getSequenceInstancesAction({ request: { callTargetId: this.internalCallTargetId } })
    },
    async fetchSequenceInstancesIfNeeded() {
      await this.promiseOfFetchAvailableFeatures
      if (!this.isAvailableSequenceFeature) return
      this.fetchSequenceInstances();
    },
    async fetchReserveItems() {
      const directMailApi = new DirectMailApiService()
      const { data: { reserveItems } } = await this.doActionWithWait<AxiosResponse<GetReserveItems200Response>>(this.keyOfGetReserveItemsWait, async () => {
        return await directMailApi.getReserveItems({
          request: { callTargetId: this.internalCallTargetId },
        });
      });
      this.reserveItems = reserveItems || []
    },
    async fetchReserveItemsIfNeeded() {
      await this.promiseOfFetchAvailableFeatures
      if (!this.isAvailableDirectMailFeature) return
      this.fetchReserveItems();
    },
    handleClickApplySequence() {
      this.$modal.show(SEQUENCE_APPLY_MODAL_NAME);
    },
    handleCloseApplySequenceModal() {
      this.$modal.hide(SEQUENCE_APPLY_MODAL_NAME);
    },
    fetchNextActions() {
      this.getNextActionsAction({ callTargetId: this.internalCallTargetId });
    },
    fetchHistoryItems() {
      this.getCallTargetsBackgroundAction();
      this.getLeadHistoriesAction({ callTargetId: this.internalCallTargetId });
    },
    fetchNextActionItems() {
      this.fetchNextActions();
      this.fetchHistoryItems();
    },
    fetchDirectMailItems() {
      this.fetchDraftItems();
      this.fetchReserveItems();
      this.fetchHistoryItems();
    },
    fetchSequenceItems() {
      this.fetchDraftItems();
      this.fetchNextActions();
      this.fetchReserveItems();
      this.fetchHistoryItems();
      this.fetchSequenceInstances();
    },
  },
})
</script>

<style lang="scss" scoped>
.lead {
  height: 100%;
}
.call-target {
  overflow: hidden;
}
.page-inner {
  height: 100%;
  position: relative;
}
.target-header-area {
  @include m-fixed-height(50px);
  display: flex;
  align-items: center;
  border-bottom: 1px solid $bdcolor-light;
}
.target-detail-area {
  @include m-fixed-width(440px);
  @include m-fixed-height(100%);
  // background-color: $bgcolor-base;
  border-right: 1px solid $bdcolor-light;
  overflow-y: auto;
}
.page-right {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.lead-history-area {
  height: 100%;
  background-color: $bgcolor-base;
  overflow-y: auto;
}
</style>

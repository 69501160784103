<template>
  <div class="sequence">
    <div class="sequence-title">
      <BEditableContent
        v-model:editing="isNameEditing"
        :uneditable="!props.editable"
        :value="internalSequenceBody.name"
        :placeholder="$t('sequence.name')"
        :max-length="80"
        size="small"
        settle-type="blur"
        required
        is-display-value-or-place-holder
        @check="handleUpdateName"
      />
    </div>
    <SequenceOwner
      :owner-name="ownerName"
      :for-sequence-instance="forSequenceInstance"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { SequenceBody } from '@/api/openapi';
import SequenceOwner from './SequenceOwner.vue'

type TProps = {
  editable?: boolean;
  sequenceBody?: SequenceBody;
  ownerName?: string;
  forSequenceInstance?: boolean;
};
type TEmit = {
  'update': [sequenceBody: SequenceBody];
};

// NOTE: 編集の場合、取得したデータをsequenceBodyに詰めて渡す
const props = withDefaults(defineProps<TProps>(), {
  editable: true,
  sequenceBody: (): SequenceBody => ({
    name: '',
    ownerId: null,
  }),
  ownerName: undefined,
  forSequenceInstance: false,
});
const emit = defineEmits<TEmit>();

const isNameEditing = ref(false);
const internalSequenceBody = ref<SequenceBody>({
  name: '',
  ownerId: null,
});

watch(() => props.sequenceBody, (newValue) => {
  internalSequenceBody.value = { ...newValue };
}, { immediate: true });
watch(internalSequenceBody, (newValue) => {
  emit('update', newValue);
}, { immediate: true });

const handleUpdateName = (name: string) => {
  isNameEditing.value = false;
  internalSequenceBody.value.name = name;
  emit('update', internalSequenceBody.value);
};
</script>

<style lang="scss" scoped>
.sequence {
  @include m-fixed-width(100%);
  padding: 10px $basespace-600 10px;
  display: flex;

  &-title {
    flex-grow: 1;

    :deep(.input-box) {
      width: 650px;
    }
  }
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "trigger-require-approval-notification-item" }
const _hoisted_2 = { class: "date" }
const _hoisted_3 = { class: "actions" }

import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { TriggerTargetAction } from '@/api/openapi';
import { TriggerTargetActionApiService } from '@/api/user/resources/trigger_target_action';
import { useWait } from '@/composable/vue-wait';
import { formatShorterDateTime } from '@/utils/date-time';
import LeadLink from '../general/LeadLink.vue';

type TProps = {
  item: TriggerTargetAction;
  loading: boolean;
};
type TEmit = {
  'after:update': [];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'TriggerRequireApprovalNotificationItem',
  props: {
    item: {},
    loading: { type: Boolean }
  },
  emits: ["after:update"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const { doActionWithWait } = useWait();
const router = useRouter();

const handleTriggerClick = () => {
  const { href } = router.resolve({
    name: 'Trigger',
    params: { id: props.item.trigger.id },
  });
  window.open(href, '_blank');
};

const api = new TriggerTargetActionApiService();
const handleApproveClick = async () => {
  await doActionWithWait('triggerApproveWait', async () => {
    await api.approveTriggerTargetAction({
      request: {
        triggerTargetActionId: props.item.id,
      },
    });
    emit('after:update');
  });
};
const handleRejectClick = async () => {
  await doActionWithWait('triggerRejectWait', async () => {
    await api.rejectTriggerTargetAction({
      request: {
        triggerTargetActionId: props.item.id,
      },
    });
    emit('after:update');
  });
};

return (_ctx: any,_cache: any) => {
  const _component_BBtn = _resolveComponent("BBtn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(LeadLink, {
      "call-target-id": props.item.callTarget.id,
      tag: "div",
      class: "company-name"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.item.callTarget.companyName), 1)
      ]),
      _: 1
    }, 8, ["call-target-id"]),
    _createElementVNode("div", {
      class: "trigger-name",
      onClick: handleTriggerClick
    }, _toDisplayString(_ctx.item.trigger.name), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(formatShorterDateTime)(_ctx.item.createdAt)), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_BBtn, {
        class: "mr-100",
        flat: "",
        type: "primary",
        loading: _ctx.loading,
        onClick: handleApproveClick
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('general.approve.text')), 1)
        ]),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_component_BBtn, {
        flat: "",
        type: "primary",
        outline: "",
        loading: _ctx.loading,
        onClick: handleRejectClick
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('general.reject.text')), 1)
        ]),
        _: 1
      }, 8, ["loading"])
    ])
  ]))
}
}

})
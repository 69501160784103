import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "multi-input-container" }
const _hoisted_2 = { class: "ml-50" }

import { ref, watch, computed, onMounted } from 'vue';

type TProps = {
  modelValue: string[] | null | undefined,
  rules: string[],
  placeholder: string,
  minInputSize: number,
  maxInputSize: number,
};
type TEmits = {
  'update:modelValue': [values: string[]];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BMultiInputBox',
  props: /*@__PURE__*/_mergeModels({
    modelValue: {},
    rules: {},
    placeholder: {},
    minInputSize: {},
    maxInputSize: {}
  }, {
    "valid": { type: Boolean, ...{ default: true } },
    "validModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["update:modelValue"], ["update:valid"]),
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const valid = _useModel<boolean>(__props, 'valid');

const values = ref<string[]>([]);
const valids = ref<boolean[]>([]);

onMounted(() => {
  if (props.modelValue) {
    values.value = props.modelValue;
  } else {
    values.value = Array(props.minInputSize).fill('');
  }
  valids.value = Array(values.value.length).fill(true);
})

const additionable = computed(() => valids.value.length < props.maxInputSize);

watch(values,
  value => {
    if (valid.value) {
      emit('update:modelValue', values.value);
    }
  },
  { deep: true }
);

watch(valids,
  value => {
    valid.value = valids.value.every((v) => v);
  },
  { deep: true },
);

const handleAppendClick = () => {
  if (additionable.value) {
    values.value.push('');
    valids.value.push(false);
  }
};

const handleInputCloseClick = (index: number) => {
  values.value.splice(index, 1);
  valids.value.splice(index, 1);
};

const handleUpdateValid = (index: number, value: boolean) => {
  valids.value[index] = value;
};

return (_ctx: any,_cache: any) => {
  const _component_BInputBox = _resolveComponent("BInputBox")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(values.value, (value, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createVNode(_component_BInputBox, {
          modelValue: values.value[index],
          "onUpdate:modelValue": ($event: any) => ((values.value[index]) = $event),
          rules: _ctx.rules,
          placeholder: _ctx.placeholder,
          closeable: index !== 0,
          class: "mb-50",
          "settle-type": "blur",
          horiz: "",
          required: "",
          "onUpdate:valid": (value) => handleUpdateValid(index, value),
          onClose: () => handleInputCloseClick(index)
        }, null, 8, ["modelValue", "onUpdate:modelValue", "rules", "placeholder", "closeable", "onUpdate:valid", "onClose"])
      ]))
    }), 128)),
    (additionable.value)
      ? (_openBlock(), _createBlock(_component_BBtn, {
          key: 0,
          type: "primary",
          text: "",
          size: "small",
          onClick: handleAppendClick
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BIcon, {
              type: "primary",
              size: "small"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" add ")
              ])),
              _: 1
            }),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('general.append.of', {item: _ctx.$t('general.target')})), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})
import Waiting from '@/store/waiting'

export default class CallListActions {
  constructor(Api, types) {
    const actions = {
      async getLeadCsvImportTasksAction({ commit }, args) {
        await waiting.check('getLeadCsvImportTasksAction', async () => {
          try {
            const res = await Api.getLeadCsvImportTasks(args)
            commit(types.SET_LEAD_CSV_IMPORT_TASKS, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
      async confirmLeadCsvImportTaskAction({ dispatch }, args) {
        await waiting.check('confirmLeadCsvImportTaskAction', async () => {
          try {
            await Api.confirmLeadCsvImportTask(args)
            await dispatch('getLeadCsvImportTasksAction', args)
          } catch (err) {
            throw err
          }
        })
      },
      async getLeadExcelImportTasksAction({ commit }, args) {
        await waiting.check('getLeadExcelImportTasksAction', async () => {
          try {
            const res = await Api.getLeadExcelImportTasks(args)
            commit(types.SET_LEAD_EXCEL_IMPORT_TASKS, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
      async confirmLeadExcelImportTaskAction({ dispatch }, args) {
        await waiting.check('confirmLeadExcelImportTaskAction', async () => {
          try {
            await Api.confirmLeadExcelImportTask(args)
            await dispatch('getLeadExcelImportTasksAction', args)
          } catch (err) {
            throw err
          }
        })
      },
      async getLeadCsvOverwriteTasksAction({ commit }, args) {
        await waiting.check('getLeadCsvOverwriteTasksAction', async () => {
          try {
            const res = await Api.getLeadCsvOverwriteTasks(args)
            commit(types.SET_LEAD_CSV_OVERWRITE_TASKS, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
      async confirmLeadCsvOverwriteTaskAction({ dispatch }, args) {
        await waiting.check('confirmLeadCsvOverwriteTaskAction', async () => {
          try {
            await Api.confirmLeadCsvOverwriteTask(args)
            await dispatch('getLeadCsvOverwriteTasksAction', args)
          } catch (err) {
            throw err
          }
        })
      },
      async getLeadExcelOverwriteTasksAction({ commit }, args) {
        await waiting.check('getLeadExcelOverwriteTasksAction', async () => {
          try {
            const res = await Api.getLeadExcelOverwriteTasks(args)
            commit(types.SET_LEAD_EXCEL_OVERWRITE_TASKS, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
      async confirmLeadExcelOverwriteTaskAction({ dispatch }, args) {
        await waiting.check('confirmLeadExcelOverwriteTaskAction', async () => {
          try {
            await Api.confirmLeadExcelOverwriteTask(args)
            await dispatch('getLeadExcelOverwriteTasksAction', args)
          } catch (err) {
            throw err
          }
        })
      },
      async createLeadExportTaskAction({ dispatch }, args) {
        await waiting.check('createLeadExportTaskAction', async () => {
          try {
            await Api.createLeadExportTask(args)
            await dispatch('getLeadExportTasksAction', args)
          } catch (err) {
            throw err
          }
        })
      },
      async getLeadExportTasksAction({ commit }, args) {
        await waiting.check('getLeadExportTasksAction', async () => {
          try {
            const res = await Api.getLeadExportTasks(args)
            commit(types.SET_LEAD_EXPORT_TASKS, Object.values(res.data)[0])
          } catch (err) {
            commit(types.CLEAR_LEAD_EXPORT_TASKS)
            throw err
          }
        })
      },
      async getLeadDeleteTasksAction({ commit }, args) {
        await waiting.check('getLeadDeleteTasksAction', async () => {
          try {
            const res = await Api.getLeadDeleteTasks(args)
            commit(types.SET_LEAD_DELETE_TASKS, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
      async confirmLeadDeleteTaskAction({ dispatch }, args) {
        await waiting.check('confirmLeadDeleteTaskAction', async () => {
          try {
            await Api.confirmLeadDeleteTask(args)
            await dispatch('getLeadDeleteTasksAction', args)
          } catch (err) {
            throw err
          }
        })
      },
      async getLeadsCsvAction({ commit }, args) {
        const res = await waiting.check('getLeadsCsvAction', async () => {
          try {
            return await Api.getLeadsCsv(args)
          } catch (err) {
            throw err
          }
        })
        return res.data
      },
      async getSfReportImportTasksAction({ commit }, args) {
        await waiting.check('getSfReportImportTasksAction', async () => {
          try {
            const res = await Api.getSfReportImportTasks(args)
            commit(types.SET_LEAD_SF_IMPORT_TASKS, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
      async confirmSfReportImportTaskAction({ dispatch }, args) {
        await waiting.check('confirmSfReportImportTaskAction', async () => {
          try {
            await Api.confirmSfReportImportTask(args)
            await dispatch('getSfReportImportTasksAction', args)
          } catch (err) {
            throw err
          }
        })
      },
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

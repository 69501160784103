import Waiting from '@/store/waiting'
import SfBaseActions from './sf_base'

export default class SfLeadActions extends SfBaseActions {
  constructor(Api, types) {
    super()
    const actions = {
      getSfLeadAction: this.SalesForceBaseAction(({ commit }, args) => {
        return waiting.check('getSfLeadAction', async () => {
          try {
            const result = await Api.getSfLead(args)
            return result.data.sfLead
          } catch (err) {
            throw err
          }
        })
      }),
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mail-body-editor-conteiner" }

import Quill from 'quill';
import { ref, computed } from 'vue';
import PlaceholderMenu from '@/components/organisms/user/general/PlaceholderMenu.vue';
import QuillContentEditor from '@/components/organisms/user/mail/common/drawer/state/form/QuillContentEditor.vue';

type TProps = {
  content: string;
  showAttachmentIcon?: boolean;
  isMail?: boolean;
  unsubscribeVisible?: boolean;
  enablePlaceholder?: boolean;
};
type TEmit = {
  (e: 'update:content', content: string): void;
  (e: 'click-attach-file'): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MailBodyEditor',
  props: {
    content: {},
    showAttachmentIcon: { type: Boolean, default: false },
    isMail: { type: Boolean, default: true },
    unsubscribeVisible: { type: Boolean, default: false },
    enablePlaceholder: { type: Boolean, default: true }
  },
  emits: ["update:content", "click-attach-file"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const internalContent = computed({
  get () {
    return props.content
  },
  set (newValue) {
    emit('update:content', newValue)
  },
});

const isPlaceholderMenuOpen = ref(false);

const quillInstance = ref<Quill>(null);

const editContent = (content: string) => {
  internalContent.value = content
  isPlaceholderMenuOpen.value = false
};

const insertPlaceholder = (placeHolder: string) => {
  // https://quilljs.com/docs/api/#getselection
  const range = quillInstance.value.getSelection()
  if (range) {
    // https://quilljs.com/docs/api/#inserttext
    quillInstance.value.insertText(range.index, placeHolder)
  } else {
    // User cursor is not in editor
    internalContent.value += placeHolder
  }
  isPlaceholderMenuOpen.value = false
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(QuillContentEditor, {
      class: "quill-content-editor",
      content: internalContent.value,
      "enable-attachment": _ctx.showAttachmentIcon,
      onEdit: editContent,
      onSetQuill: _cache[0] || (_cache[0] = ($event: any) => (quillInstance.value = $event)),
      onClickAttachFile: _cache[1] || (_cache[1] = ($event: any) => (emit('click-attach-file')))
    }, null, 8, ["content", "enable-attachment"]),
    (_ctx.enablePlaceholder)
      ? (_openBlock(), _createBlock(PlaceholderMenu, {
          key: 0,
          modelValue: isPlaceholderMenuOpen.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((isPlaceholderMenuOpen).value = $event)),
          class: "insert-place-holder-menu",
          content: internalContent.value,
          "is-mail": _ctx.isMail,
          "unsubscribe-visible": _ctx.unsubscribeVisible,
          onInsertItem: insertPlaceholder
        }, null, 8, ["modelValue", "content", "is-mail", "unsubscribe-visible"]))
      : _createCommentVNode("", true)
  ]))
}
}

})
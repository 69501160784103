import ApiBase from '@/api/base'

export default {
  getMailNotifications(args = {}) {
    args.timeout = 300000
    return ApiBase.get(`/user/mail_notifications`, args)
  },
  postMailNotificationConfirmed(args) {
    return ApiBase.post(`/user/mail_notifications/confirmed`, args)
  },
  getMailNotificationCount(args) {
    return ApiBase.pollingGet(`/user/mail_notifications/count`, args)
  },
}

<template>
  <div>
    <BDrawerMenu
      v-model="isOpen"
      class="trigger-event-form-container"
      width="600px"
      :title="$t('trigger.eventSetting.title')"
      @cancel="handleCancelClick"
    >
      <div class="trigger-event-form-body-container">
        <BListItem class="pb-100">
          <span class="fs-300 fw-bold">{{ $t('trigger.eventSetting.leadSelect') }}</span>
        </BListItem>
        <BListItem class="list-item">
          <ElDescriptions
            class="margin-top"
            :column="1"
            border
          >
            <ElDescriptionsItem>
              <template #label>
                <span>{{ $t('trigger.eventSetting.leadFilterSelect.title') }}</span>
              </template>
              <div class="radio-group">
                <BRadio
                  v-for="leadSelectType in leadSelectTypes"
                  :key="leadSelectType"
                  v-model="selectedLeadSelectType"
                  :label="leadSelectType"
                  class="radio-item"
                >
                  {{ $t(`trigger.eventSetting.leadFilterSelect.${leadSelectType}`) }}
                </BRadio>
              </div>
            </ElDescriptionsItem>
            <ElDescriptionsItem
              v-if="selectedLeadSelectType === 'leadList'"
            >
              <template #label>
                <span>{{ $t('trigger.eventSetting.leadListSelect') }}</span>
              </template>
              <LeadViewSelectPanel
                v-model="internalTriggerEvent.leadViewId"
                class="lead-view-select-panel"
                full-width
                list-height="60vh"
                use-model
              />
            </ElDescriptionsItem>
            <ElDescriptionsItem
              v-if="selectedLeadSelectType === 'leadCustom'"
            >
              <template #label>
                <span>
                  {{ $t('trigger.eventSetting.leadCustomSelect') }}
                  <BBtn
                    size="mini"
                    class="ml-200"
                    @click="handleClickLeadFilter"
                  >
                    {{ $t('trigger.eventSetting.leadFilterChange') }}
                  </BBtn>
                </span>
              </template>
              <FilterControlPanelBody
                :lead-list-filter-params="leadListFilterParams"
                :is-display-only="true"
                :filter-category-visible="false"
              />
            </ElDescriptionsItem>
          </ElDescriptions>
        </BListItem>
        <BListItem class="pt-200 pb-200">
          <span class="fs-300 fw-bold">{{ $t('trigger.eventSetting.eventSelect') }}</span>
        </BListItem>
        <BListItem class="list-item">
          <template #header>
            <span>{{ $t('trigger.eventSetting.eventType') }}</span>
          </template>
          <BSelect
            v-model="internalTriggerEvent.eventType"
            :items="groupedEventTypes"
            item-text="name"
            item-value="value"
            fit
            required
          />
        </BListItem>
        <div
          v-if="!!internalTriggerEvent.eventType"
          v-loading="loading"
          class="event-detail-container form"
        >
          <template
            v-for="form in forms"
            :key="form.eventDetailKey"
          >
            <template v-if="form.inputType === 'sequence_select'">
              <BListItem class="list-item">
                <template #header>
                  <span>{{ form.label }}</span>
                </template>
                <SequenceMasterSimpleSelect
                  v-model="(internalTriggerEvent.eventDetail[form.eventDetailKey] as number | null)"
                  v-model:is-valid="isSequenceMasterSelectValid"
                  :required="form.validations.includes('required')"
                />
              </BListItem>
            </template>
            <template v-if="form.inputType === 'mail_type_sequence_step_select'">
              <BListItem class="list-item">
                <template #header>
                  <span>{{ form.label }}</span>
                </template>
                <SequenceStepMasterSimpleSelect
                  v-model="(internalTriggerEvent.eventDetail[form.eventDetailKey] as number | null)"
                  v-model:is-valid="isSequenceStepMasterSelectValid"
                  :sequence-master-id="(internalTriggerEvent.eventDetail[form.eventDetailBelongsToKey] as number | null)"
                  :condition="{ stepType: 'direct_mail' }"
                  :required="form.validations.includes('required')"
                />
              </BListItem>
            </template>
            <template v-if="form.inputType === 'multi_text_input'">
              <BListItem class="list-item">
                <template #header>
                  <span>{{ form.label }}</span>
                </template>
                <BMultiInputBox
                  v-model="internalTriggerEvent.eventDetail[form.eventDetailKey]"
                  v-model:valid="isUrlInputValid"
                  :rules="validationRules(form.validations)"
                  :placeholder="$t('general.placeholder.url')"
                  :min-input-size="1"
                  :max-input-size="3"
                />
              </BListItem>
            </template>
          </template>
        </div>
      </div>
      <template #footer>
        <div
          class="footer"
        >
          <BLayout
            align-center
            justify-center
          >
            <BBtn
              class="mr-300 btn"
              text
              @click="handleCancelClick"
            >
              {{ $t('general.cancel.text') }}
            </BBtn>
            <BBtn
              class="ml-300 btn"
              type="primary"
              outline
              :disabled="!isFormValid"
              @click="handleSetClick"
            >
              {{ $t('general.setting.text') }}
            </BBtn>
          </BLayout>
        </div>
      </template>
    </BDrawerMenu>
    <LeadFilterModal
      :filter-params="leadListFilterParams"
      :modal-name="LEAD_FILTER_MODAL_NAME"
      @close="handleCloseLeadFilter"
    />
  </div>
</template>

<script lang="ts" setup>
import camelcaseKeys from 'camelcase-keys'
import lodash from 'lodash'
import { ref, watch, nextTick, computed, toRaw, inject, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { TriggerEventBody, TriggerEventFromValidationType } from '@/api/openapi';
import FilterControlPanelBody from '@/components/organisms/user/leadList/filter/FilterControlPanelBody.vue';
import LeadViewSelectPanel from '@/components/organisms/user/LeadViewSelectPanel.vue'
import LeadFilterModal from '@/components/organisms/user/trigger/LeadFilterModal.vue'
import { useInputValidations } from '@/composable/input-validations';
import { useTriggerEvent, useTriggerEventForm } from '@/composable/user/trigger/trigger-event';
import { KEY_OF_MODAL } from '@/injection-keys';
import { key } from '@/store/index'
import SequenceMasterSimpleSelect from '../SequenceMasterSimpleSelect.vue'
import SequenceStepMasterSimpleSelect from '../SequenceStepMasterSimpleSelect.vue'

const emit = defineEmits<TEmits>();

const LEAD_FILTER_MODAL_NAME = 'leadFilterModal';

type TEmits = {
  'click:cancel': [];
};
const model = defineModel<TriggerEventBody | null>();
const isOpen = defineModel<boolean>('isOpen', { default: false });

const modal = inject(KEY_OF_MODAL);
const store = useStore(key);

const { groupedEventTypes, makeInitialTriggerEvent } = useTriggerEvent();
const { forms, fetchForms, loading } = useTriggerEventForm();

const leadSelectTypes = ['leadList', 'leadCustom'];
const selectedLeadSelectType = ref<string>('leadList');
const internalTriggerEvent = ref<TriggerEventBody>();

const clearInternalValues = () => {
  internalTriggerEvent.value = makeInitialTriggerEvent(model.value);
  forms.value = [];
};
clearInternalValues();

const leadListFilterParams = computed(() => store.getters['userUi/leadListFilterParams']);

watch(() => isOpen.value, () => {
  nextTick(() => {
    clearInternalValues();
    storeLeadListFilterParams();
  });
});

watch(() => leadListFilterParams.value, value => {
  internalTriggerEvent.value.filterParams = value;
});

watch(() => internalTriggerEvent.value.eventType, value => {
  if (value) fetchForms(value);
});

watch(() => internalTriggerEvent.value.leadViewId, value => {
  if (value) selectedLeadSelectType.value = 'leadList';
});

watch(() => internalTriggerEvent.value.filterParams, value => {
  if (value && Object.keys(value).length > 0) selectedLeadSelectType.value = 'leadCustom';
});

const storeLeadListFilterParams = () => {
  const value = internalTriggerEvent.value.filterParams;
  if (value && Object.keys(value).length > 0) {
    store.dispatch('userUi/setLeadListFilterParamsAction',
      camelcaseKeys(value, {
        deep: true,
        exclude: [/^custom_field_.*[^items]/g],
      }),
    );
  }
};

onUnmounted(() => {
  store.dispatch('userUi/clearLeadListFilterParamsAction');
})

// NOTE: 本当は初期値falseにしたいが、レンダリングされていないフォームのバリデーションを拾わせないために、初期値trueにしている。
const isSequenceStepMasterSelectValid = ref(true);
const isSequenceMasterSelectValid = ref(true);
const isUrlInputValid = ref(true);
const isFormValid = computed(() => {
  if (!forms.value.length) return false;
  return isSequenceMasterSelectValid.value && isSequenceStepMasterSelectValid.value && isUrlInputValid.value;
});

const { requiredRule, urlRule, urlWithoutQueryRule } = useInputValidations();
const validationRules = (validationTypes: TriggerEventFromValidationType[]) => {
  return validationTypes.map(validationType => {
    switch (validationType) {
      case 'required':
        return requiredRule;
      case 'url_rule':
        return urlRule;
      case 'url_without_query_rule':
        return urlWithoutQueryRule;
      default:
        return null;
    }
  }).filter(rule => !!rule);
};

const handleClickLeadFilter = () => {
  modal.show(LEAD_FILTER_MODAL_NAME);
};

const handleCloseLeadFilter = () => {
  modal.hide(LEAD_FILTER_MODAL_NAME);
};

const handleCancelClick = () => {
  emit('click:cancel');
};

const handleSetClick = () => {
  switch (selectedLeadSelectType.value){
    case 'leadList':
      internalTriggerEvent.value.filterParams = {};
      break;
    case 'leadCustom':
      internalTriggerEvent.value.leadViewId = null;
      break;
  }
  model.value = lodash.cloneDeep(internalTriggerEvent.value);
};
</script>

<template>
  <ZoomPhoneCallButton
    v-if="activeCti === 'zoom'"
    :phone-number="phoneNumber"
  />
  <MiitelCallButton
    v-if="activeCti === 'miitel'"
    :phone-number="phoneNumber"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useActiveCti } from '@/composable/active-cti'
import MiitelCallButton from './MiitelCallButton.vue'
import ZoomPhoneCallButton from './ZoomPhoneCallButton.vue'

export default defineComponent({
  components: {
    ZoomPhoneCallButton,
    MiitelCallButton,
  },
  props: {
    phoneNumber: String,
  },
  setup() {
    const { activeCti } = useActiveCti(true)
    return {
      activeCti,
    }
  },
})
</script>

<template>
  <BLayout
    align-center
    justify-center
    class="custom-field-creating-form"
  >
    <BInput
      v-model="name"
      v-model:valid="nameValid"
      class="name-input"
      :placeholder="$t('customField.name')"
      :rules="[requiredRule]"
      :disabled="createLoading"
      @keypress-enter="handleSubmit"
    />
    <BInput
      v-model="systemReferenceName"
      v-model:valid="systemReferenceNameValid"
      class="system-reference-name-input"
      :placeholder="$t('customField.systemReferenceName')"
      :rules="[requiredRule]"
      :disabled="createLoading"
      @keypress-enter="handleSubmit"
    />
    <BSelect
      v-model="type"
      :items="selectableDataTypes"
      :disabled="createLoading"
      class="type-input"
    />
    <BBtn
      type="primary"
      :disabled="isSubmitButtondisabled"
      :loading="createLoading"
      @click="handleSubmit"
    >
      <span>{{ $t('general.append.text') }}</span>
    </BBtn>
  </BLayout>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import { useInputValidations } from '@/composable/input-validations';
import { useWait } from '@/composable/vue-wait';
import { key } from '@/store/index';

type TEmit = {
  'afterCreate': [];
};
const emit = defineEmits<TEmit>();

const i18n = useI18n();
const store = useStore(key);
const { doActionWithWait } = useWait();
const { requiredRule } = useInputValidations();
const { isAvailableSalesforceLinkFeature } = useAvailableFeatureCheck()

const name = ref('');
const systemReferenceName = ref('');
const type = ref('text');
const nameValid = ref(false);
const systemReferenceNameValid = ref(false);
const isSubmitButtondisabled = computed(() => !nameValid.value || !systemReferenceNameValid.value);
const selectableDataTypes = computed(() => {
  const selectTypes = []

  for (const [k, v] of Object.entries(i18n.tm('customField.dataType.enum')) as [string, string][]) {
    if (k != 'sfReference' || k == 'sfReference' && isAvailableSalesforceLinkFeature) {
      selectTypes.push({ value: k.replace(/([A-Z])/g, x => '_'.concat(x.toLowerCase())), text: v })
    }
  }

  return selectTypes
});

const clear = () => {
  name.value = '';
  systemReferenceName.value = '';
};

const handleSubmit = async () => {
  await createCustomField();
  emit('afterCreate');
  clear();
};

const createCustomField = async () => {
  await doActionWithWait('createCustomFieldWait', async () => {
    const customField = {
      name: name.value,
      systemReferenceName: systemReferenceName.value,
      dataType: type.value,
    };
    const body = {
      customField,
    };
    await store.dispatch('user/createCustomFieldAction', {
      body,
    });
  })
};
</script>

<style lang="scss" scoped>
.custom-field-creating-form {
  .name-input {
    width: 280px;
    margin-right: 10px;
  }
  .system-reference-name-input {
    width: 180px;
    margin-right: 10px;
  }
  .type-input {
    width: 120px;
    margin-right: $basespace-400;
  }
}
</style>

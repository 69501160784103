<template>
  <BListItem class="mb-200">
    <template #header>
      <span>{{ title.label }}</span>
      <span
        v-if="editing"
        class="btn-area ml-200"
      >
        <BBtn
          v-if="valueChanged"
          class="mr-50"
          size="small"
          fab
          flat
          :disabled="!valid"
          :loading="loading"
          :data-test="`${node}Btn`"
          @click="handleClickCheck"
        >
          <BIcon
            class="check-icon"
            size="small"
          >
            check
          </BIcon>
        </BBtn>
        <BBtn
          size="small"
          fab
          flat
          :disabled="loading"
          @click="handleClickClose"
        >
          <BIcon
            class="close-icon"
            size="small"
          >
            close
          </BIcon>
        </BBtn>
      </span>
    </template>
    <VWait
      class="full-width"
      for="none"
      :visible="$wait.is('getCallTargetWait')"
    >
      <template #waiting>
        <BContentLoader
          :height="24"
          :width="230"
        >
          <rect
            x="0"
            y="8.5"
            rx="6"
            ry="6"
            width="160"
            height="12"
          />
        </BContentLoader>
      </template>
      <BLayout
        class="list-content"
        row
      >
        <div
          v-if="editing"
          class="editable-content"
        >
          <div
            v-for="(key, index) in Object.keys(newItems)"
            :key="index"
            class="input-box"
          >
            <BSelect
              v-if="key === 'salutation'"
              ref="bSelect"
              v-model="newItems[key]"
              class="input-select mr-200"
              :items="salutationOptions"
              :placeholder="$t('callTargetInfo.item.salutation')"
              data-test="salutation"
              fit
              clearable
            />
            <BInput
              v-else
              v-model="newItems[key]"
              v-model:valid="valid"
              class="input-area"
              :placeholder="newItems[key] || $t(`callTargetInfo.item.${key}`)"
              :rules="rules"
              :disabled="loading"
              :max-length="maxLength"
              :data-test="key"
              type="text"
            />
            <div class="spacer" />
          </div>
        </div>
        <template v-else>
          <div
            class="item input"
            :class="{ uneditable: uneditable }"
            :data-test="node"
            @click="handleClickEdit"
          >
            {{ displayValue }}
          </div>
        </template>
      </BLayout>
    </VWait>
  </BListItem>
</template>

<script>
import lodash from 'lodash'
import { altText } from '@/utils/alt'

export default {
  props: {
    node: String,
    editingNode: String,
    title: {
      type: Object,
      default () {
        return {}
      },
    },
    items: {
      type: Object,
      default () {
        return {}
      },
    },
    rules: {
      type: Array,
      default () {
        return []
      },
    },
    maxLength: {
      type: Number,
      default: 255,
    },
    hyphen: {
      type: Boolean,
      default: true,
    },
    uneditable: {
      type: Boolean,
      default: false,
    },
    loading: Boolean,
  },
  emits: [
    'update:editing-node',
    'save',
    'cancel',
  ],
  data () {
    return {
      editing: false,
      valid: false,
      salutationOptions: [
        { text: '様', value: '様' },
      ],
      newItems: lodash.cloneDeep(this.items),
    }
  },
  computed: {
    displayValue () {
      return this.hyphen ? altText(this.title.value) : this.title.value
    },
    valueChanged () {
      return Object.keys(this.items).some((key) => this.items[key] !== this.newItems[key])
    },
  },
  watch: {
    loading (newVal) {
      // loadingを渡さないと、save時にフォームが終了しない
      if (!newVal) { this.editing = false }
    },
    editingNode (newVal) {
      if (newVal !== this.node) this.editing = false
    },
    editing (newVal) {
      if (newVal) this.$emit('update:editing-node', this.node)
    },
  },
  methods: {
    handleClickEdit () {
      if (this.uneditable) return
      this.newItems = lodash.cloneDeep(this.items)
      this.editing = true
    },
    handleClickCheck () {
      this.$emit('save', this.newItems)
    },
    handleClickClose () {
      this.$emit('cancel')
      this.newItems = lodash.cloneDeep(this.items)
      this.editing = false
    },
  },
}
</script>

<style lang="scss" scoped>
  .list-content {
    position: relative;
    width: 100%;
    word-wrap: break-word;
    word-break: break-all;

    .editable-content {
      width: 100%;

      .input-box {
        display: flex;
        align-items: center;
        margin-left: -$basespace-100;
        margin-bottom: $basespace-100;

        .input-area {
          margin-right: $basespace-200;
        }
        .spacer {
          margin-right: $basespace-600;
        }
      }
    }

    .item {
      width: 100%;
      border: 1px solid transparent;
      padding: $basespace-50 $basespace-100;
      margin-left: -$basespace-100;

      &:hover {
        border-color: $bdcolor-light;
        cursor: pointer;
      }

      &.uneditable:hover {
        border-color: transparent;
        cursor: auto;
      }

      &.input:hover {
        border-radius: 4px;
      }
    }
  }

  .btn-area {
    vertical-align: middle;
    line-height: 30px;

    .check-icon {
    color: $basecolor-primary;
    }
    .close-icon {
      color: $textcolor-light;
    }
  }
</style>

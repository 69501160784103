import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_BTooltip, {
    bottom: "",
    content: _ctx.tooltipContent,
    disabled: !_ctx.tooltipContent
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["audio-wrap", {'in-progress': _ctx.zoomPhoneRecording?.inProgress}])
      }, [
        _withDirectives(_createVNode(_component_BPlaceholder, {
          height: "0",
          width: "0",
          "element-loading-background": "transparent"
        }, null, 512), [
          [_directive_loading, _ctx.zoomPhoneRecording?.inProgress]
        ]),
        _createElementVNode("audio", {
          style: _normalizeStyle(_ctx.styles),
          controls: "",
          src: _ctx.zoomPhoneRecordingUrl
        }, null, 12, _hoisted_1)
      ], 2)
    ]),
    _: 1
  }, 8, ["content", "disabled"]))
}
import { StoreDataFetchManager } from '@/store/store-data-fetch-manager';
import Waiting from '@/store/waiting'

export default class CallTargetUnsubscribeActions {
  constructor (Api, types) {
    const actions = {
      async getCallTargetUnsubscribeAction ({ commit }, args) {
        await waiting.checkWithArg('getCallTargetUnsubscribeAction', args, async () => {
          try {
            const res = await storeDataFetchManager.fetch('getCallTargetUnsubscribeAction', async () => await Api.getCallTargetUnsubscribe(args));
            commit(types.SET_CALL_TARGET_UNSUBSCRIBE, Object.values(res.data)[0])
          } catch (err) {
            commit(types.CLEAR_CALL_TARGET_UNSUBSCRIBE)
            throw err
          }
        })
      },
      async createCallTargetUnsubscribeAction ({ commit }, args) {
        await waiting.check('createCallTargetUnsubscribeAction', async () => {
          await Api.createCallTargetUnsubscribe(args)
          try {
            const res = await Api.getCallTargetUnsubscribe(args)
            commit(types.SET_CALL_TARGET_UNSUBSCRIBE, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
      async deleteCallTargetUnsubscribeAction ({ commit }, args) {
        await waiting.check('deleteCallTargetUnsubscribeAction', async () => {
          await Api.deleteCallTargetUnsubscribe(args)
          try {
            const res = await Api.getCallTargetUnsubscribe(args)
            commit(types.SET_CALL_TARGET_UNSUBSCRIBE, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
    }
    const waiting = new Waiting(actions)
    const storeDataFetchManager = new StoreDataFetchManager();
    this.actions = actions
  }
}

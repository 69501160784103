import { ActionTree } from 'vuex';
import { ApiArguments } from '@/api/api_service';
import {
  MailTemplateApiDeleteMailTemplateRequest,
  MailTemplateApiGetMailTemplateRequest,
  MailTemplateApiPostMailTemplateRequest,
  MailTemplateApiPutMailTemplateRequest,
} from '@/api/openapi/apis/mail-template-api';
import { MailTemplatesApiService } from '@/api/user/resources/mail_template';
import { RootState, UserState } from '@/store/types';
import { types } from '@/store/user/mutations';
import Waiting from '@/store/waiting';

const actions: ActionTree<UserState, RootState> = {
  getMailTemplateAction: async (
    { commit },
    apiArguments: ApiArguments<MailTemplateApiGetMailTemplateRequest>,
  ) => {
    await waiting.check('getMailTemplateAction', async () => {
      try {
        const api = new MailTemplatesApiService();
        const res = await api.getMailTemplate(apiArguments);
        commit(types.SET_MAIL_TEMPLATE, res.data);
      } catch (err) {
        commit(types.CLEAR_MAIL_TEMPLATE);
        throw err;
      }
    });
    return true;
  },
  getMailTemplatesAction: async (
    { commit },
    apiArguments?: ApiArguments<undefined>,
  ) => {
    await waiting.check('getMailTemplatesAction', async () => {
      try {
        const api = new MailTemplatesApiService();
        const res = await api.getMailTemplates(apiArguments);
        commit(types.SET_MAIL_TEMPLATES, res.data);
      } catch (err) {
        commit(types.CLEAR_MAIL_TEMPLATES);
        throw err;
      }
    });
    return true;
  },
  createMailTemplateAction: async (
    { commit },
    apiArguments: ApiArguments<MailTemplateApiPostMailTemplateRequest>,
  ) => {
    const res = await waiting.check('createMailTemplateAction', async () => {
      try {
        const api = new MailTemplatesApiService();
        return await api.postMailTemplate(apiArguments);
      } catch (err) {
        throw err;
      }
    });
    return res.data
  },
  updateMailTemplateAction: async (
    { commit },
    apiArguments: ApiArguments<MailTemplateApiPutMailTemplateRequest>,
  ) => {
    await waiting.check('updateMailTemplateAction', async () => {
      try {
        const api = new MailTemplatesApiService();
        await api.putMailTemplate(apiArguments);
      } catch (err) {
        throw err;
      }
    });
    return true;
  },
  deleteMailTemplateAction: async (
    { commit },
    apiArguments: ApiArguments<MailTemplateApiDeleteMailTemplateRequest>,
  ) => {
    await waiting.check('deleteMailTemplateAction', async () => {
      try {
        const api = new MailTemplatesApiService();
        await api.deleteMailTemplate(apiArguments);
      } catch (err) {
        throw err;
      }
    });
    return true;
  },
};

const waiting = new Waiting(actions);

export default actions;

import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "signin-card" }
const _hoisted_2 = { class: "signin-card-inner" }
const _hoisted_3 = { class: "signin-symbol-icon" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "signin-logo" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "signin-message" }
const _hoisted_8 = { class: "signin-form" }
const _hoisted_9 = { class: "mb-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createElementBlock("div", {
    class: "signin-container",
    style: _normalizeStyle(_ctx.styles)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.symbolIconSrc,
            height: "103"
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("img", {
            src: _ctx.logoSrc,
            style: {"width":"100%","height":"60px"}
          }, null, 8, _hoisted_6)
        ]),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('auth.resetPageMessage')), 1),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_BInput, {
              modelValue: _ctx.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
              placeholder: _ctx.$t('auth.email'),
              color: "focus"
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _createVNode(_component_BBtn, {
            class: "mb-400",
            loading: _ctx.$wait.is('USER_RESET_PASSWORD_WAIT'),
            disabled: !_ctx.isValidEmail,
            type: "primary",
            onClick: _withModifiers(_ctx.resetPassword, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('auth.submit')), 1)
            ]),
            _: 1
          }, 8, ["loading", "disabled", "onClick"]),
          _createVNode(_component_BLayout, {
            "align-center": "",
            "justify-center": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_RouterLink, { to: { name: 'UserSignin' } }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('auth.returnSignin')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ])
    ])
  ], 4))
}
<template><div /></template>

<script lang="ts">
import { defineComponent } from 'vue'
import Api from '@/api/user'

export default defineComponent({
  name: 'RlsCheck',
  created() {
    Api.rlsCheck()
  },
})
</script>

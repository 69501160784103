import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

import DraftMailReminder from '@/components/organisms/DraftMailReminder.vue'
import MailNotification from '@/components/organisms/MailNotification.vue'
import ReceivedMailNotification from '@/components/organisms/ReceivedMailNotification.vue'
import Reminder from '@/components/organisms/Reminder.vue'
import ReservedMailReminder from '@/components/organisms/ReservedMailReminder.vue'
import Search from '@/components/organisms/user/general/Search.vue'
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import TriggerRequireApprovalNotification from './TriggerRequireApprovalNotification.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ActionMenu',
  setup(__props) {

const { isAvailableTriggerFeature } = useAvailableFeatureCheck();

return (_ctx: any,_cache: any) => {
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createBlock(_component_BLayout, { "align-center": "" }, {
    default: _withCtx(() => [
      _createVNode(Search),
      _createVNode(Reminder, { class: "mr-50" }),
      _createVNode(ReservedMailReminder, { class: "mr-50" }),
      _createVNode(MailNotification, { class: "mr-50" }),
      _createVNode(DraftMailReminder, { class: "mr-50" }),
      _createVNode(ReceivedMailNotification),
      (_unref(isAvailableTriggerFeature))
        ? (_openBlock(), _createBlock(TriggerRequireApprovalNotification, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})
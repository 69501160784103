import ApiBase from '@/api/base'

export default {
  getClientSendgrid (args) {
    const { clientId } = args
    return ApiBase.get(`/admin/clients/${clientId}/sendgrid_auths`, args)
  },
  updateClientSendgridAuth (args) {
    const { clientId } = args
    return ApiBase.put(`/admin/clients/${clientId}/sendgrid_auths`, args)
  },
  deleteClientSendgridAuth (args) {
    const { clientId } = args
    return ApiBase.delete(`/admin/clients/${clientId}/sendgrid_auths`, args)
  },
}

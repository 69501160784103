<template>
  <BListItem class="mb-600">
    <template #header>
      <div class="email-header">
        <BCheckbox
          v-model="isVisible"
        />
        <span class="ml-100">{{ label }}</span>
      </div>
    </template>
    <BMultipleInput
      :visible="isVisible"
      :placeholder="$t('mail.mailAddress')"
      :items="emails"
      input-type="email"
      unique-key="email"
      :max-item-count="10"
      :item-validate-rules="['email']"
      @add-item="addEmail"
      @remove-item="removeEmail"
    />
  </BListItem>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { Email } from '@/api/openapi/models/email';

type TProps = {
  modelValue: Email[];
  label: string;
};
type TEmit = {
  (event: 'update:modelValue', value: Email[]): void;
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const isVisible = ref(false);

watch(() => props.modelValue, (newVal) => {
  isVisible.value = newVal.length > 0;
}, { immediate: true });
watch(isVisible, (newVal) => {
  if (newVal === false) resetEmail();
});

const emails = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v),
});

const addEmail = (email: string) => {
  emails.value = [...emails.value, { email: email.trim() || '' }];
};
const removeEmail = (index: number) => {
  emails.value = emails.value.filter((_, i) => i !== index);
};
const resetEmail = () => {
  emails.value = [];
};
</script>

<style lang="scss" scoped>
.email-header {
  display: flex;
  align-items: center;
}
</style>

<template>
  <BLayout
    column
    align-center
    justify-center
  >
    <div class="message mb-400">
      {{ $t(`import.excel.templateDownload.message`) }}
    </div>
    <BBtn
      type="primary"
      :loading="$wait.is(waitName)"
      @click="handleDownload"
    >
      {{ $t(`import.excel.templateDownload.title`) }}
    </BBtn>
  </BLayout>
</template>

<script>
import Api from '@/api/user'

export default {
  computed: {
    waitName() {
      return 'waiting'
    },
  },
  methods: {
    handleDownload(fileName, taskId) {
      this.$wait.start(this.waitName)
      Api.downloadExcelTemplate({})
        .then((res) => {
          this.downloadExcel(res.data, 'excel-import-template.xlsx')
        })
        .finally(() => {
          this.$wait.end(this.waitName)
        })
    },
    downloadExcel(excelString, fileName) {
      const blob = new Blob([excelString], { type: 'application/octet-stream' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = fileName
      link.click()
    },
  },
}
</script>

<style lang="scss" scoped>
  .message {
    color: $concrete-dark;
  }
</style>

import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "b-unchanged-itm" }
const _hoisted_2 = { class: "b-unchanged-itm-content" }

type TProps = {
  content: string;
  helpContent?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BUnchangedItem',
  props: {
    content: {},
    helpContent: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BTooltip = _resolveComponent("BTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.content), 1),
    (_ctx.helpContent)
      ? (_openBlock(), _createBlock(_component_BTooltip, {
          key: 0,
          bottom: ""
        }, {
          content: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.helpContent), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_BIcon, {
              size: "small",
              class: "b-unchanged-itm-icon"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" help ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})
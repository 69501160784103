<template>
  <div>
    <BDrawerMenu
      v-model="drawer"
      :title="$t('opportunity.title')"
      @cancel="handleCancelCreateOpportunity"
    >
      <div>
        <BListItem class="mb-300">
          <template #header>
            <div>{{ $t('opportunity.startedAt') }}</div>
          </template>
          <BInputDateTime
            v-model:date="startedAtDate"
            v-model:time="startedAtTime"
            is-step
          />
        </BListItem>
        <BListItem class="mb-300">
          <template #header>
            <div>{{ $t('opportunity.amount') }}</div>
          </template>
          <BInput
            v-model="amount"
            v-model:valid="amountValid"
            type="number"
            :max-digits="18"
            validation
            data-test="opportunityAmount"
          />
        </BListItem>
        <BListItem>
          <BBtn
            text
            type="success"
            @click="handleQuickTextButtonClick"
          >
            <BIcon
              type="success"
              outlined
            >
              article
            </BIcon>
            <span class="ml-50 fw-bold">{{ $t('quickText.apply') }}</span>
          </BBtn>
          <BInput
            ref="memoTextarea"
            v-model="memo"
            :autosize="{ minRows: 10, maxRows: 4 }"
            resize="none"
            data-test="opportunityMemo"
            :placeholder="$t('opportunity.memo')"
            type="textarea"
            copyable
          />
        </BListItem>
      </div>
      <template #footer>
        <SequenceAssociationCheckBox
          v-if="isAvailableSequenceFeature"
          v-model="sequenceInstanceId"
          :tooltip-text-when-readonly="$t('opportunity.notEditSequenceAssociation')"
          :readonly="isEdit"
          :sequence-id="activeSequenceId"
        />
        <SyncCheckbox
          v-if="isAvailableSalesforceApiFeature"
          :is-edit="isEdit"
          :checked-is-sf-sync="checkedIsSfSync"
          :on-click="checkIsSfSync"
        />
        <div class="footer">
          <BLayout
            align-center
            justify-center
          >
            <BBtn
              class="mr-300"
              text
              :disabled="waiting"
              @click="handleCancelCreateOpportunity"
            >
              {{ $t('general.cancel.text') }}
            </BBtn>
            <BBtn
              class="ml-300"
              type="primary"
              :loading="waiting"
              data-test="saveOpportunity"
              @click="saveOpportunity"
            >
              {{ $t('general.save.text') }}
            </BBtn>
          </BLayout>
        </div>
      </template>
    </BDrawerMenu>
    <div v-if="isSfModalAvailable">
      <SalesforceOpportunityModal
        :call-target="callTarget"
        :handle-save="createOpportunity"
        @close-modal="handleClose"
      />
    </div>
    <QuickTextSelectModal
      modal-name="quickTextSelectInOpportunityForm"
      :call-target-id="callTargetId"
      @select="handleQuickTextSelect"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import SyncCheckbox from '@/components/organisms/user/general/form/SyncCheckbox.vue'
import SequenceAssociationCheckBox from '@/components/organisms/user/lead/menu/SequenceAssociationCheckBox.vue';
import QuickTextSelectModal from '@/components/organisms/user/lead/modal/QuickTextSelectModal.vue';
import SalesforceOpportunityModal from '@/components/organisms/user/lead/modal/SalesforceOpportunityModal.vue'
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import { useInsertText } from '@/composable/input'
import errorHandler from '@/mixins/error_handler'
import SfTaskSyncService from '@/mixins/salesforce/sf_task_sync_service';
import BInput from '@/plugins/biscuet-materials/components/atoms/BInput.vue';
import { mergeDateAndTime, newDateByTime } from '@/utils/date-time';

export default defineComponent({
  components: {
    SyncCheckbox,
    SalesforceOpportunityModal,
    SequenceAssociationCheckBox,
    QuickTextSelectModal,
  },
  mixins: [errorHandler, SfTaskSyncService],
  props: {
    callTargetId: Number,
    activeSequenceId: Number,
  },
  emits: ['after:createWithSequenceAssociation'],
  setup(props) {
    const INITIAL_REF_VALES = {
      isEdit: false,
      checkedIsSfSync: true,
      editingOpportunityId: null,
      startedAt: newDateByTime(8, 0, 0),
      amount: null,
      memo: null,
      amountValid: true,
    };
    const isEdit = ref<boolean>(INITIAL_REF_VALES.isEdit);
    const checkedIsSfSync = ref<boolean>(INITIAL_REF_VALES.checkedIsSfSync);
    const editingOpportunityId = ref<number | null>(INITIAL_REF_VALES.editingOpportunityId);
    const startedAt = ref<Date>(INITIAL_REF_VALES.startedAt);
    const amount = ref<number | null>(INITIAL_REF_VALES.amount);
    const memo = ref<string | null>(INITIAL_REF_VALES.memo);
    const sequenceInstanceId = ref<number | null>(props.activeSequenceId);
    const amountValid = ref<boolean>(INITIAL_REF_VALES.amountValid);
    
    const clear = () => {
      isEdit.value = INITIAL_REF_VALES.isEdit
      checkedIsSfSync.value = INITIAL_REF_VALES.checkedIsSfSync
      editingOpportunityId.value = INITIAL_REF_VALES.editingOpportunityId
      startedAt.value = INITIAL_REF_VALES.startedAt
      amount.value = INITIAL_REF_VALES.amount
      memo.value = INITIAL_REF_VALES.memo
      sequenceInstanceId.value = props.activeSequenceId
      amountValid.value = INITIAL_REF_VALES.amountValid
    }
    const memoTextarea = ref<InstanceType<typeof BInput>>(null);
    const { insertText } = useInsertText(memoTextarea, memo)
    const { isAvailableSequenceFeature } = useAvailableFeatureCheck()
    return {
      isEdit,
      checkedIsSfSync,
      editingOpportunityId,
      startedAt,
      amount,
      memo,
      sequenceInstanceId,
      amountValid,
      clear,
      isAvailableSequenceFeature,
      ...SfTaskSyncService.setup(props),
      memoTextarea,
      insertText,
    }
  },
  computed: {
    ...mapGetters('user', ['callTarget']),
    ...mapGetters('userUi', [
      'targetOpportunityFormVisible',
      'selectedOpportunity',
    ]),
    drawer: {
      get() {
        return this.targetOpportunityFormVisible
      },
      set(newVal) {
        if (this.targetOpportunityFormVisible !== newVal) this.setTargetOpportunityFormVisibleAction(newVal)
      },
    },
    startedAtDate: {
      get() {
        return new Date(this.startedAt)
      },
      set(newVal) {
        const startedAt = mergeDateAndTime(newVal, this.startedAtTime)
        this.startedAt = startedAt
      },
    },
    startedAtTime: {
      get() {
        return new Date(this.startedAt)
      },
      set(newVal) {
        const startedAt = mergeDateAndTime(this.startedAtDate, newVal)
        this.startedAt = startedAt
      },
    },
    waiting() {
      return this.$wait.is(['createOpportunityWait', 'updateOpportunityWait'])
    },
    isSfModalAvailable() {
      return this.isAvailableSalesforceApiFeature && this.drawer && this.isSfTaskSync
    },
  },
  watch: {
    targetOpportunityFormVisible(visible) {
      if (!visible) return
      // selectedOpportunityがある場合は編集中と判定
      if (Object.keys(this.selectedOpportunity).length > 0) {
        this.isEdit = true
        this.editingOpportunityId = this.selectedOpportunity.id

        this.startedAt = this.selectedOpportunity.startedAt
        this.amount = this.selectedOpportunity.amount
        this.memo = this.selectedOpportunity.memo
        this.sequenceInstanceId = this.selectedOpportunity.sequenceInstanceId
      }
    },
    activeSequenceId(newVal) {
      this.sequenceInstanceId = newVal
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      createOpportunityAction: 'createOpportunityWait',
      updateOpportunityAction: 'updateOpportunityWait',
      getCallTargetsBackgroundAction: { action: 'getCallTargetsAction', loader: 'getCallTargetsBackgroundWait' },
      getLeadHistoriesBackgroundAction: {
        action: 'getLeadHistoriesAction',
        loader: 'getLeadHistoriesBackgroundWait',
      },
    }),
    ...mapWaitingActions('userUi', {
      setTargetOpportunityFormVisibleAction:
          'setTargetOpportunityFormVisibleWait',
      setTargetHeaderMoveButtonDisabledAction:
          'setTargetHeaderMoveButtonDisabledWait',
      setSelectedOpportunityAction: 'setSelectedOpportunityWait',
    }),
    async saveOpportunity() {
      const check = await this.permitAssociateSequenceIfNeeded();
      if (!check) return
      if (this.isSfTaskSync) {
        this.handleOpen()
      } else if (this.isEdit) {
        await this.updateOpportunity()
        this.closeDrawer()
        this.getLeadHistoriesBackgroundAction({
          callTargetId: this.callTargetId,
        })
        this.getCallTargetsBackgroundAction()
      } else {
        await this.createOpportunity()
        this.getLeadHistoriesBackgroundAction({
          callTargetId: this.callTargetId,
        })
        this.closeDrawer()
        this.getCallTargetsBackgroundAction()
      }
    },
    async createOpportunity() {
      const opportunity = {
        startedAt: this.startedAt,
        amount: this.amount,
        memo: this.memo,
        sequenceInstanceId: this.sequenceInstanceId,
      }
      const result = await this.createOpportunityAction({
        callTargetId: this.callTargetId,
        body: { opportunity },
        errorHandlers: {
          422: this.defaultHandler,
        },
      })
      if (this.sequenceInstanceId) this.$emit('after:createWithSequenceAssociation')

      return result
    },
    async updateOpportunity() {
      const opportunity = {
        startedAt: this.startedAt,
        amount: this.amount,
        memo: this.memo,
        sequenceInstanceId: this.sequenceInstanceId,
      }
      const result = await this.updateOpportunityAction({
        callTargetId: this.callTargetId,
        opportunityId: this.editingOpportunityId,
        body: { opportunity },
        errorHandlers: {
          422: this.defaultHandler,
        },
      })

      return result
    },
    async handleCancelCreateOpportunity() {
      const check = await this.$bitterAlert.show({
        title: this.$t('general.confirm.text'),
        text: this.$t('general.alert.of', {
          target: this.$t('general.unsavedValue'),
          action: this.$t('general.clear'),
        }),
      })
      if (!check) {
        return
      }

      this.closeDrawer()
    },
    closeDrawer() {
      this.drawer = false
      this.setSelectedOpportunityAction({})
      this.clear()
      this.setTargetHeaderMoveButtonDisabledAction(false)
    },
    handleOpen() {
      this.$modal.show(`SalesforceOpportunityModal${this.callTargetId}`)
    },
    handleClose() {
      this.$modal.hide(`SalesforceOpportunityModal${this.callTargetId}`)
      this.closeDrawer()
    },
    checkIsSfSync() {
      this.checkedIsSfSync = !this.checkedIsSfSync
    },
    async permitAssociateSequenceIfNeeded() {
      if (this.sequenceInstanceId == null) return true
      return await this.$bitterAlert.show({
        title: this.$t('opportunity.confirmSequenceAssociation.title'),
        text: this.$t('opportunity.confirmSequenceAssociation.content'),
      })
    },
    handleQuickTextButtonClick() {
      this.$modal.show('quickTextSelectInOpportunityForm')
    },
    handleQuickTextSelect(content: string) {
      this.insertText(content)
    },
  },
});
</script>

<style lang="scss" scoped>
  .footer {
    background-color: $bgcolor-base;
    padding: $basespace-200 $basespace-400;
    border-top: 1px solid $bdcolor-light;
  }
</style>

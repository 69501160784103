import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "signin-container" }
const _hoisted_2 = { class: "signin-card" }
const _hoisted_3 = { class: "signin-card-inner" }
const _hoisted_4 = { class: "signin-message" }
const _hoisted_5 = { class: "signin-form" }
const _hoisted_6 = { class: "custom-loader" }
const _hoisted_7 = {
  key: 0,
  class: "signin-form",
  "data-test": "dummy-form"
}
const _hoisted_8 = { class: "mb-200" }
const _hoisted_9 = { class: "mb-200" }
const _hoisted_10 = { class: "pb-200" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BInput = _resolveComponent("BInput")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('auth.adminPageMessage')), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_BBtn, {
            class: "signin-btn",
            type: "primary",
            loading: _ctx.loading,
            onClick: _withModifiers(_ctx.signin, ["prevent"])
          }, {
            loader: _withCtx(() => [
              _createElementVNode("span", _hoisted_6, [
                _createVNode(_component_BIcon, { type: "white" }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("cached")
                  ])),
                  _: 1
                })
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('auth.signin')), 1)
            ]),
            _: 1
          }, 8, ["loading", "onClick"])
        ]),
        (!_ctx.isProduction)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_BInput, {
                  modelValue: _ctx.email,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                  rules: [_ctx.requiredRule, _ctx.emailRule],
                  placeholder: _ctx.$t('auth.email'),
                  "validate-on-blur": "",
                  color: "focus",
                  "data-test": "email"
                }, null, 8, ["modelValue", "rules", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_BInput, {
                  modelValue: _ctx.password,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                  rules: [_ctx.requiredRule],
                  type: "password",
                  name: "password",
                  placeholder: _ctx.$t('auth.password'),
                  "validate-on-blur": "",
                  color: "focus",
                  "data-test": "password",
                  "onClick:append": _cache[2] || (_cache[2] = ($event: any) => (_ctx.passwordShow = !_ctx.passwordShow)),
                  onKeypressEnter: _ctx.dummySignin
                }, null, 8, ["modelValue", "rules", "placeholder", "onKeypressEnter"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_BBtn, {
                  type: "primary",
                  "data-test": "signin-btn",
                  loading: _ctx.dummyLoading,
                  onClick: _withModifiers(_ctx.dummySignin, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('auth.signin')), 1)
                  ]),
                  _: 1
                }, 8, ["loading", "onClick"])
              ])
            ], 512)), [
              [_vShow, false]
            ])
          : _createCommentVNode("", true)
      ])
    ])), [
      [_directive_loading, _ctx.$wait.is('USER_SIGNIN_WAIT')]
    ])
  ]))
}
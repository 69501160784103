<template>
  <div
    v-if="showConnectErrorMessage"
    class="notification-message"
    @click="showSettingGoogleApi"
  >
    <BIcon
      class="mr-100"
      size="small"
      type="error"
    >
      error
    </BIcon>
    <div class="message">
      {{ connectErrorMessage }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useGoogleSetting } from '@/composable/user/googleSetting/google-setting';

type TEmit = {
  'click:setting': [];
  'update:setting-error': [error: boolean];
};

const emit = defineEmits<TEmit>();

const i18n = useI18n();
const router = useRouter();
const { googleSetting, fetchGoogleSetting } = useGoogleSetting();

onMounted(async () => {
  await fetchGoogleSetting();
  handleVisibility();
})

const showConnectErrorMessage = computed(() =>
  googleSetting.value?.isGmailImport === false,
);
const connectErrorMessage = computed(() =>
  i18n.t('googleNotification.connectErrorMessage') + i18n.t('general.goSetting'),
);

watch(showConnectErrorMessage, (newValue) => {
  emit('update:setting-error', newValue);
}, { immediate: true });

const showSettingGoogleApi = async () => {
  emit('click:setting')
  router.replace({
    name: 'Setting',
    params: { tab: 'user-setting', subTab: 'user-email' },
  })
};

const handleVisibility = () => {
  document.addEventListener('visibilitychange', async () => {
    if (document.visibilityState === 'visible') {
      await fetchGoogleSetting();
    }
  });
};
</script>

<style lang="scss" scoped>
  .notification-message {
    display: flex;
    transition: $transition-base;
    margin: 10px 18px;
    padding: 10px;
    cursor: pointer;
    color: $basecolor-error;
    background: rgba($basecolor-error, 0.1);
    border-radius: 4px;
  }

  .message {
    font-size: $fontsize-100;
  }
</style>

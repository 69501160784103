<template>
  <div
    v-if="showTokenExpiredMessage"
    class="notification-message"
    @click="showSettingSalesforce"
  >
    <BIcon
      class="mr-100"
      size="small"
      type="error"
    >
      error
    </BIcon>
    <div class="message">
      {{ tokenExpiredMessage }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SalesforceConst from '@/const/salesforce'

export default {
  name: 'SfNotificationMessage',
  emits: [
    'click:setting',
    'update:setting-error',
  ],
  computed: {
    ...mapGetters('user', [
      'currentUser',
      'salesforceSetting',
    ]),
    isOwner() {
      return this.currentUser.role === 'owner'
    },
    showTokenExpiredMessage() {
      return this.salesforceSetting?.connectStatus == SalesforceConst.TokenExpired
    },
    tokenExpiredMessage() {
      let message = this.$t('salesforceNotification.tokenExpiredMessage')
      if (this.isOwner) {
        message += this.$t('general.goSetting')
      }
      return message
    },
  },
  watch: {
    showTokenExpiredMessage: {
      immediate: true,
      handler (value) {
        this.$emit('update:setting-error', value)
      },
    },
  },
  methods: {
    async showSettingSalesforce () {
      if (this.isOwner) {
        this.$emit('click:setting')
        this.$router.replace({
          name: 'Setting',
          params: { tab: 'salesforce', subTab: 'salesforce-relation-info' },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .notification-message {
    display: flex;
    transition: $transition-base;
    margin: 10px 18px;
    padding: 10px;
    cursor: pointer;
    color: $basecolor-error;
    background: rgba($basecolor-error, 0.1);
    border-radius: 4px;
  }

  .message {
    font-size: $fontsize-100;
  }
</style>

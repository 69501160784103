import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sequence-notice" }

import { computed } from 'vue';
import { SequenceBody } from '@/api/openapi';
import EditableSequence from '@/components/organisms/user/general/sequence/EditableSequence.vue';
import { useSequenceMaster } from '@/composable/user/sequence/sequence-master';

type TProps = {
  sequenceMasterId: number;
  ownerName: string | undefined;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceMaster',
  props: {
    sequenceMasterId: {},
    ownerName: {}
  },
  setup(__props: any) {

const props = __props;

const {
  sequenceMaster,
  fetchSequenceMaster,
} = useSequenceMaster(props.sequenceMasterId);

fetchSequenceMaster('fetchSequenceMaster');

const sequenceBody = computed<SequenceBody>(() => ({
  name: sequenceMaster.value?.name ?? '',
  ownerId: sequenceMaster.value?.owner?.id ?? null,
}));

return (_ctx: any,_cache: any) => {
  const _component_VWait = _resolveComponent("VWait")!

  return (_openBlock(), _createBlock(_component_VWait, { for: "fetchDuplicateSequenceMaster" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('sequence.leadDetail.applyCaution')), 1),
      _createVNode(EditableSequence, {
        "sequence-body": sequenceBody.value,
        "owner-name": _ctx.ownerName,
        editable: false,
        "for-sequence-instance": ""
      }, null, 8, ["sequence-body", "owner-name"])
    ]),
    _: 1
  }))
}
}

})
<template>
  <CallButton
    :tooltip-message="tooltipMessage"
    :disabled="disabled"
    @click="handleCall"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { makeCall } from '@/services/zoom_phone_service'
import CallButton from './CallButton.vue'

export default defineComponent({
  components: {
    CallButton,
  },
  props: {
    phoneNumber: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('zoomPhone', [
      'isCallable',
      'isCalling',
    ]),
    ...mapGetters('userUi', [
      'zoomPhoneWidgetVisible',
    ]),
    tooltipMessage () {
      if (this.isCalling) {
        return this.$t('zoomPhone.busy')
      } else if (this.disabled) {
        return this.$t('zoomPhone.notStarted')
      } else {
        return this.$t('zoomPhone.call')
      }
    },
    isValidPhoneNumber(): boolean {
      return typeof this.phoneNumber === 'string' && this.phoneNumber.length > 0
    },
    disabled(): boolean {
      return !this.isValidPhoneNumber || !this.zoomPhoneWidgetVisible || !this.isCallable
    },
  },
  methods: {
    handleCall(): void {
      if (this.disabled) return

      makeCall(this.phoneNumber)
    },
  },
})
</script>

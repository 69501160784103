import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "miitel" }
const _hoisted_2 = {
  key: 0,
  class: "call-status"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _directive_else44 = _resolveDirective("else,")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", {
      id: "miitelWindow",
      class: _normalizeClass(_ctx.classes)
    }, [
      _createVNode(_component_BLayout, {
        class: "miitel-header",
        "align-center": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BTooltip, {
            top: "",
            content: _ctx.$t('general.close.text'),
            disabled: _ctx.isCalling
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BBtn, {
                disabled: _ctx.isCalling,
                size: "small",
                fab: "",
                text: "",
                onClick: _ctx.closeWidget
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BIcon, { size: "small" }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode(" close ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ]),
            _: 1
          }, 8, ["content", "disabled"]),
          _createVNode(_component_BBtn, {
            size: "small",
            fab: "",
            text: "",
            onClick: _ctx.minimizeWidget
          }, {
            default: _withCtx(() => [
              (!_ctx.isMinimize)
                ? (_openBlock(), _createBlock(_component_BIcon, {
                    key: 0,
                    size: "small"
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode(" minimize ")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _withDirectives((_openBlock(), _createBlock(_component_BIcon, { size: "small" }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" maximize ")
                ])),
                _: 1
              })), [
                [_directive_else44]
              ])
            ]),
            _: 1
          }, 8, ["onClick"]),
          (_ctx.callingTime)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.callStatusText) + " : " + _toDisplayString(_ctx.callingTime), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "miitel-content" }, null, -1))
    ], 2), [
      [_vShow, _ctx.internalMiitelWidgetVisible]
    ])
  ]))
}
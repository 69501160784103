<template>
  <div v-if="triggerEvent == null">
    <BCard
      border
    >
      <div class="title">
        {{ $t('trigger.eventSetting.title') }}
      </div>
      <div class="desctiption">
        {{ $t('trigger.eventSetting.description') }}
      </div>
      <div>
        <BBtn
          class="setting-btn"
          type="primary"
          outline
          @click="() => $emit('click:setting', 'form')"
        >
          {{ $t('general.setting.text') }}
        </BBtn>
      </div>
    </BCard>
  </div>
  <div v-else>
    <div class="trigger-event-card-container">
      <BCard
        border
      >
        <div class="header">
          <div>
            {{ $t('trigger.event') }}
          </div>
          <div
            class="more-menu"
          >
            <BMoreMenu
              v-model="isMenuVisible"
              left
            >
              <template #default>
                <BBtn
                  flat
                  fit
                  position="center"
                  size="small"
                  :disabled="!editable"
                  @click="() => clickMenuButton('form')"
                >
                  {{ $t('general.edit.text') }}
                </BBtn>
                <BBtn
                  flat
                  fit
                  position="center"
                  size="small"
                  @click="() => clickMenuButton('confirm')"
                >
                  {{ $t('trigger.actionSetting.detail') }}
                </BBtn>
              </template>
            </BMoreMenu>
          </div>
        </div>
        <div class="body">
          <BListItem class="list-item">
            <template #header>
              <span>{{ $t('trigger.eventSetting.eventType') }}</span>
            </template>
            <div class="mt-100">
              {{ actionDescription }}
            </div>
          </BListItem>
        </div>
      </BCard>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n'
import { TriggerEventBody } from '@/api/openapi';
import { TTriggerEventFormMode } from './types';

type TProps = {
  triggerEvent: TriggerEventBody | null;
  editable: boolean;
};
type TEmits = {
  'click:setting': [mode: TTriggerEventFormMode];
};
const props = defineProps<TProps>();
const emit = defineEmits<TEmits>();

const i18n = useI18n();
const isMenuVisible = ref<boolean>();

const clickMenuButton = (mode: TTriggerEventFormMode) => {
  isMenuVisible.value = false;
  emit('click:setting', mode);
};

const actionDescription = computed(() => {
  let description = '';
  switch (props.triggerEvent?.eventType) {
    case 'sequence_mail_url_click':
      description = i18n.t(`trigger.eventTypes.${props.triggerEvent?.eventType}`);
      break;
  }
  return description;
});
</script>

<style lang="scss" scoped>
.title {
  font-size: $fontsize-300;
  font-weight: bold;
  margin-bottom: 4px;
}

.desctiption {
  margin-bottom: 14px;
}

.setting-btn {
  width: 100%;
}

.trigger-event-card-container {
  :deep(.card) {
    padding: 0;

    .card-body {
      .header {
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $bgcolor-dark;
        color: $textcolor-base;
        padding: 8px 20px;
        border-radius: 6px 6px 0 0;

        .more-menu {
          .b-icon {
            color: $textcolor-base;
          }
        }
      }

      .body {
        padding: 20px;
      }
    }
  }
}
</style>

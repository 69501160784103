import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BTooltip = _resolveComponent("BTooltip")!

  return (_openBlock(), _createBlock(_component_BTooltip, {
    top: "",
    content: _ctx.tooltipMessage
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BBtn, {
        type: "primary",
        size: "mini",
        fab: "",
        disabled: _ctx.disabled,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BIcon, {
            size: "small",
            type: "white"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" phone_in_talk ")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }, 8, ["content"]))
}
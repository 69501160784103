import { AxiosResponse } from 'axios';
import { readonly, ref } from 'vue';
import { GetSequenceMaster200Response, SequenceMaster } from '@/api/openapi';
import { SequenceMasterApiService } from '@/api/user/resources/sequence_master';
import { useErrorHandler } from '@/composable/error-handler';
import { useWait } from '@/composable/vue-wait';

const useSequenceMaster = (sequenceMasterId?: number) => {
  const sequenceMaster = ref<SequenceMaster>();
  const fetchSequenceMaster = async (keyOfWait: string) => {
    if (!sequenceMasterId) return;

    const {
      doActionWithWait,
    } = useWait();
    const {
      redirectToNotFoundPage,
    } = useErrorHandler();

    const api = new SequenceMasterApiService();
    const { data: { sequenceMaster: fetchedSequenceMaster } } = await doActionWithWait<AxiosResponse<GetSequenceMaster200Response>>(keyOfWait, async () => {
      return await api.getSequenceMaster({
        request: {
          sequenceMasterId,
        },
        errorHandlers: {
          404: redirectToNotFoundPage,
        },
      });
    });

    sequenceMaster.value = fetchedSequenceMaster;
  };

  return {
    sequenceMaster: readonly(sequenceMaster),
    fetchSequenceMaster,
  };
};

export {
  useSequenceMaster,
}

import { InjectionKey } from 'vue'
import { Store, createStore } from 'vuex'
import admin from '@/store/admin'
import breadcrumbs from '@/store/breadcrumbs'
import miitel from '@/store/miitel'
import unsubscribe from '@/store/unsubscribe'
import user from '@/store/user'
import userUi from '@/store/user-ui'
import zoomPhone from '@/store/zoom-phone'
import { RootState } from './types'

const debug = process.env.NODE_ENV !== 'production'

export const key: InjectionKey<Store<RootState>> = Symbol()

export default createStore<RootState>({
  modules: {
    breadcrumbs,
    admin,
    user,
    miitel,
    zoomPhone,
    userUi,
    unsubscribe,
  },
  strict: debug,
})

import lodash from 'lodash'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import { TFilterType, filterKeyTypes, getInitialValue, multipleFilterKey } from '@/mixins/filter_service/constants'
import queryParameter from '@/mixins/query_parameter'
import { getIncExc } from '@/utils/object'

const mixin = {
  mixins: [queryParameter],
  data() {
    return {
      multipleKeys: [],
      isEmptyFilterData: true,
    }
  },
  computed: {
    ...mapGetters('user', ['customFields']),
    ...mapGetters('userUi', [
      'leadListFilterParams',
      'filterControlPanelVisible',
      'leadListPageParams',
    ]),
  },
  methods: {
    ...mapWaitingActions('user', {
      getCallTargetsAction: 'getCallTargetsWait',
      getCallTargetsMetaAction: 'getCallTargetsMetaWait',
    }),
    ...mapWaitingActions('userUi', {
      setLeadListFilterParamsAction: 'setLeadListFilterParamsWait',
      setLeadListPageParamsAction: 'setLeadListPageParamsWait',
      setFilterControlPanelVisibleAction: 'setFilterControlPanelVisibleWait',
      setSelectedFilterDetailKeyNameAction: 'setSelectedFilterDetailKeyNameWait',
    }),
    isCustomFieldKey(fieldKey) {
      return fieldKey.includes('custom_field_')
    },
    getFilterType(key) {
      return this.isCustomFieldKey(key)
        ? this.findCustomFilterType(key)
        : this.findNormalFilterType(key)
    },
    findNormalFilterType(key) {
      if (filterKeyTypes[key]) return filterKeyTypes[key]
      console.error(`couldn't not found type. key =`, key)
      return 'typeString'
    },
    findCustomFilterType(key): TFilterType {
      const customField = this.customFields.find((cf) => cf.fieldKey === key)
      if (customField == null) {
        console.error(`invalid fieldKey. fieldKey = `, key)
        return 'typeString'
      }
      switch (customField.dataType) {
        case 'text':
          return 'typeString'
        case 'datetime':
          return 'typeArray'
        case 'option':
          return 'typeArray'
        case 'number':
          return 'typeNumber'
        case 'multi_option':
          return 'typeArray'
        default:
          console.error(`invalid dataType. dataType = `, customField.dataType)
          return 'typeString'
      }
    },
    isEmptyFilter(filter) {
      const incExcType = getIncExc(filter)
      const filterValue = filter[incExcType].or
      return (
        (filterValue == null || filterValue.length === 0)
        && filterValue !== false
      )
    },
    addFilterItem(shouldAddKey) {
      const newLeadListFilterParams = lodash.cloneDeep(
        this.leadListFilterParams,
      )
      const keyType = this.getFilterType(shouldAddKey)
      const param = {
        inc: { or: getInitialValue(keyType) },
      };
      const isCustomFieldKey = this.isCustomFieldKey(shouldAddKey)
      if (isCustomFieldKey) {
        if (newLeadListFilterParams.hasOwnProperty('customFieldItems')) {
          // 既に customFieldItems 自体は存在する場合
          newLeadListFilterParams.customFieldItems[shouldAddKey] = param
        } else {
          // customFieldItems 自体が存在しない場合は初期化して定義する
          newLeadListFilterParams.customFieldItems = {
            [shouldAddKey]: param,
          }
        }
      } else {
        newLeadListFilterParams[shouldAddKey] = param
      }

      this.setLeadListFilterParamsAction(newLeadListFilterParams)
    },
    addMultipleFilterItem(shouldAddKeys) {
      shouldAddKeys.forEach((key) => {
        this.addFilterItem(key)
      })
    },
    removeFilterItem(shouldRemoveKey) {
      const newLeadListFilterParams = lodash.cloneDeep(
        this.leadListFilterParams,
      )
      let willCustomKeyRemove = false
      const isCustomFieldKey = this.isCustomFieldKey(shouldRemoveKey)
      if (isCustomFieldKey) {
        this.isEmptyFilterData = this.isEmptyFilter(
          newLeadListFilterParams.customFieldItems[shouldRemoveKey],
        )
        delete newLeadListFilterParams.customFieldItems[shouldRemoveKey]
        if (Object.keys(newLeadListFilterParams.customFieldItems).length === 0)
          delete newLeadListFilterParams.customFieldItems
        willCustomKeyRemove = true
      } else {
        if (this.multipleKeys.length) {
          if (this.isEmptyFilterData) this.isEmptyFilterData = this.isEmptyFilter(newLeadListFilterParams[shouldRemoveKey])
        } else this.isEmptyFilterData = this.isEmptyFilter(newLeadListFilterParams[shouldRemoveKey])
        delete newLeadListFilterParams[shouldRemoveKey]
      }

      this.setLeadListFilterParamsAction(newLeadListFilterParams)
      this.setSelectedFilterDetailKeyNameAction(null)

      // 空のフィルタでなかった場合のみリードを再取得する
      if (!this.multipleKeys.length) {
        if (!this.isEmptyFilterData) this.getCallTargetsAction() && this.getCallTargetsMetaAction()
        this.isEmptyFilterData = true
      } else {
        if (this.isLastKey(shouldRemoveKey) && !this.isEmptyFilterData) {
          this.getCallTargetsAction() && this.getCallTargetsMetaAction()
          this.isEmptyFilterData = true
        }
      }
    },
    setFilterAndMoveLeadList(filterParams = {}) {
      if (this.$route.name !== 'LeadList') {
        this.$router.push({ name: 'LeadList' })
      }
      this.setLeadListFilterParamsAction(filterParams)
      // 現在の表示数設定で1ページ目を表示する
      this.setLeadListPageParamsAction(Object.assign(lodash.cloneDeep(this.leadListPageParams), { currentPage: 1 }))
      this.setFilterControlPanelVisibleAction(true)
      this.getCallTargetsAction()
    },
    removeMultipleFilterItem(shouldRemoveKeys) {
      this.multipleKeys = lodash.cloneDeep(shouldRemoveKeys);
      shouldRemoveKeys.forEach((key) => {
        this.removeFilterItem(key)
      })
    },
    getMultipleFilterKey(key) {
      return key ? multipleFilterKey[key] : multipleFilterKey
    },
    isLastKey(key) {
      return this.multipleKeys[this.multipleKeys.length - 1] === key;
    },
  },
}

export default mixin

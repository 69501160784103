<template>
  <BContent
    v-if="isAvailableTriggerFeature"
    v-loading="loading"
  >
    <template #navbar>
      <BLayout
        align-start
        justify-start
      >
        <span class="fs-400 fw-bold">{{ $t('trigger.title') }}</span>
      </BLayout>
    </template>
    <template #waiting>
      <BPlaceholder
        v-loading="true"
        element-loading-background="transparent"
      />
    </template>
    <TriggerForm
      v-model="triggerBody"
      wait-for="getTriggerWait"
      is-display-only="true"
    />
    <div class="select-wrapper mt-100 mb-300 pl-600">
      <SelectUser
        v-model="selectedUserId"
        :use-all-label="$t('trigger.target.allApproveUser')"
      />
    </div>
    <BTabs
      v-model="activeName"
      class="tabs"
      :label-items="tabLabelItems"
      :before-leave="handleTabBeforeLeave"
      @click:tab="handleTabClick"
    >
      <template #lead>
        <TriggerLeads
          v-model="trigger"
          :owner-id="selectedUserId"
        />
      </template>
    </BTabs>
    <template #footer />
  </BContent>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { Trigger, TriggerBody } from '@/api/openapi';
import { TriggerApiService } from '@/api/user/resources/trigger';
import TriggerForm from '@/components/organisms/user/trigger/TriggerForm.vue';
import TriggerLeads from '@/components/organisms/user/trigger/TriggerLeads.vue';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import { useErrorHandler } from '@/composable/error-handler';
import { useUrlLinkBtabsHandlers } from '@/composable/url-link-b-tabs';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { i18nGlobal as i18n } from '@/i18n';

const { isAvailableTriggerFeature } = useAvailableFeatureCheck()
const route = useRoute();
const {
  wait,
  doActionWithWait,
} = useWait();
const {
  redirectToNotFoundPage,
} = useErrorHandler();
const { currentUser, fetchCurrentUser } = useCurrentUser();

const api = new TriggerApiService();

const triggerId = parseInt(route.params.id as string);
const trigger = ref<Trigger>();
const triggerBody = ref<TriggerBody>({
  name: '',
  ownerId: null,
});
const selectedUserId = ref<number | undefined>();

watch(trigger, (newValue) => {
  triggerBody.value.name = newValue?.name || '';
  triggerBody.value.ownerId = newValue?.owner?.id || null;
});

const activeName = ref('lead');
const tabLabelItems = [
  { label: i18n.t('trigger.action'), slotName: 'action', route: { name: 'Trigger', params: { id: triggerId } } },
  { label: i18n.t('trigger.lead'), slotName: 'lead' },
];
const { handleTabClick, handleTabBeforeLeave } = useUrlLinkBtabsHandlers(tabLabelItems);

const loading = computed(() => wait.is(['getTriggerWait']));

onMounted(async () => {
  await fetchCurrentUser();
  selectedUserId.value = currentUser.value.id;
  await fetchTrigger();
});

const fetchTrigger = async () => {
  if (!triggerId) return;

  await doActionWithWait('getTriggerWait', async () => {
    const { data } = await api.getTrigger({
      request: {
        triggerId,
      },
      errorHandlers: {
        404: redirectToNotFoundPage,
      },
    });
    trigger.value = data.trigger;
  });
};
</script>

<style lang="scss" scoped>
.tabs {
  height: calc(100% - 50px - 62px);
}

:deep(.el-tabs__header) {
  margin-bottom: 0;

  .el-tabs__nav-scroll {
    padding: 0 $basespace-600;
  }
}

:deep(.el-tabs__content) {
  height: calc(100% - 40px);

  .el-tab-pane {
    height: 100%;
  }
}
</style>

import Waiting from '@/store/waiting'

export default class CallResultTypeActions {
  constructor (Api, types) {
    const actions = {
      async getCallResultTypesAction ({ commit }, args) {
        await waiting.check('getCallResultTypesAction', async () => {
          try {
            const res = await Api.getCallResultTypes(args)
            const resData = res.data.callResultTypes
            commit(types.SET_CALL_RESULT_TYPES, resData.callResultTypes)
            commit(types.SET_DEFAULT_CALL_RESULT_TYPES, resData.defaultCallResultTypes)
          } catch (err) {
            throw err
          }
        })
      },
      async createCallResultTypeAction ({ commit }, args) {
        await waiting.check('createCallResultTypeAction', async () => {
          await Api.createCallResultType(args)
          try {
            const res = await Api.getCallResultTypes(args)
            const resData = res.data.callResultTypes
            commit(types.SET_CALL_RESULT_TYPES, resData.callResultTypes)
            commit(types.SET_DEFAULT_CALL_RESULT_TYPES, resData.defaultCallResultTypes)
          } catch (err) {
            throw err
          }
        })
      },
      async updateCallResultTypeAction ({ commit }, args) {
        const { id, name } = args
        await waiting.check('updateCallResultTypeAction', async () => {
          await Api.updateCallResultType({ id, body: { name } })
          try {
            const res = await Api.getCallResultTypes(args)
            const resData = res.data.callResultTypes
            commit(types.SET_CALL_RESULT_TYPES, resData.callResultTypes)
            commit(types.SET_DEFAULT_CALL_RESULT_TYPES, resData.defaultCallResultTypes)
          } catch (err) {
            throw err
          }
        })
      },
      async deleteCallResultTypeAction ({ commit }, args) {
        await waiting.check('deleteCallResultTypeAction', async () => {
          await Api.deleteCallResultType(args)
          try {
            const res = await Api.getCallResultTypes(args)
            const resData = res.data.callResultTypes
            commit(types.SET_CALL_RESULT_TYPES, resData.callResultTypes)
            commit(types.SET_DEFAULT_CALL_RESULT_TYPES, resData.defaultCallResultTypes)
          } catch (err) {
            throw err
          }
        })
      },
      async updateCallResultTypesAction ({ dispatch }, args) {
        await waiting.check('updateCallResultTypesAction', async () => {
          await Api.updateCallResultTypes(args)
          try {
            await dispatch('getCallResultTypesAction', args)
          } catch (err) {
            throw err
          }
        })
      },
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "color-select" }
const _hoisted_2 = {
  key: 1,
  class: "placeholder truncate-text"
}
const _hoisted_3 = { class: "icons" }
const _hoisted_4 = { class: "color-container" }

import { computed, ref } from 'vue';
import { TColor } from '../../types';

type TProps = {
  modelValue: string | null;
  colors: TColor[];
  clearable?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BColorSelect',
  props: {
    modelValue: {},
    colors: {},
    clearable: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit

const selectedColor = computed(() => props.modelValue ? props.colors.find(c => props.modelValue === c.value) : undefined);

const isOpen = ref(false);
const toggleSelector = () => isOpen.value = !isOpen.value;

const handleSelectColor = (color: TColor) => {
  emit('update:modelValue', color.value);
  toggleSelector();
};

// NOTE: BElementIconにclickがうまくhookできないので一枚span着せてます
const handleClear = () => emit('update:modelValue', null);

return (_ctx: any,_cache: any) => {
  const _component_BColorPreview = _resolveComponent("BColorPreview")!
  const _component_BElementIcon = _resolveComponent("BElementIcon")!
  const _component_BMenu = _resolveComponent("BMenu")!

  return (_openBlock(), _createBlock(_component_BMenu, {
    modelValue: isOpen.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isOpen).value = $event))
  }, {
    activator: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!!selectedColor.value)
          ? (_openBlock(), _createBlock(_component_BColorPreview, {
              key: 0,
              "color-code": selectedColor.value.colorCode
            }, null, 8, ["color-code"]))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('general.select.please')), 1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BElementIcon, {
            name: "ArrowDown",
            type: "gray",
            size: "medium"
          }),
          _createElementVNode("span", {
            onClick: _withModifiers(handleClear, ["stop"])
          }, [
            (_ctx.clearable && !!selectedColor.value)
              ? (_openBlock(), _createBlock(_component_BElementIcon, {
                  key: 0,
                  name: "Close",
                  type: "gray",
                  size: "medium"
                }))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors, (color) => {
          return (_openBlock(), _createBlock(_component_BColorPreview, {
            key: color.value,
            "color-code": color.colorCode,
            onClick: () => handleSelectColor(color)
          }, null, 8, ["color-code", "onClick"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "instance-list-container" }
const _hoisted_2 = { class: "instance-list-control" }
const _hoisted_3 = { class: "instance-list ml-400" }
const _hoisted_4 = { class: "instance-items" }

import { ref, watch } from 'vue';
import { PaginationMeta, SequenceInstanceOverview } from '@/api/openapi';
import { SequenceMasterApiService } from '@/api/user/resources/sequence_master';
import LeadLink from '@/components/organisms/user/general/LeadLink.vue';
import InstanceListHeader from '@/components/organisms/user/sequenceLeads/InstanceListHeader.vue';
import InstanceListItem from '@/components/organisms/user/sequenceLeads/InstanceListItem.vue';
import { TCheck, useCheckAll } from '@/composable/check-all';
import { useWait } from '@/composable/vue-wait';

type TProps = {
  sequenceMasterId: number;
  page?: number;
};
type TEmits = {
  (event: 'after:movePage', afterPage: number): void;
  (event: 'update:selectedSequenceInstanceIds', values: number[]): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InstanceList',
  props: {
    sequenceMasterId: {},
    page: { default: 1 }
  },
  emits: ["after:movePage", "update:selectedSequenceInstanceIds"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const internalPage = ref(props.page || 1);
watch(() => props.page, (value) => {
  internalPage.value = value || 1;
});
watch(() => internalPage.value, (value) => {
  fetchSequenceInstances();
  emit('after:movePage', value);
});

const sequenceInstances = ref<SequenceInstanceOverview[]>([]);
const paginationMeta = ref<PaginationMeta>(null);

const {
  checkAll: sequenceInstancesCheckAll,
  checkedValues: selectedSequenceInstanceIds,
  initializeChecks,
  findCheck,
  handleCheckAllUpdate: handleSequenceInstancesCheckAllUpdate,
  toggleCheck: toggleSequenceInstanceCheck,
} = useCheckAll();

watch(selectedSequenceInstanceIds, (values) => {
  emit('update:selectedSequenceInstanceIds', values);
});

const makeSequenceInstanceIdChecks = (): TCheck[] =>
  sequenceInstances.value.map((instance) => ({
    value: instance.id,
    checked: false,
  }));

const api = new SequenceMasterApiService();
const {
  doActionWithWait,
} = useWait();

const fetchSequenceInstances = async () => {
  await doActionWithWait('fetchSequenceInstancesWait', async () => {
    const { data: { sequenceInstances: sequenceInstancesRes, meta } } = await api.getSequenceInstanceOverviews({
      request: {
        sequenceMasterId: props.sequenceMasterId,
        page: internalPage.value,
      },
    });
    sequenceInstances.value = sequenceInstancesRes;
    paginationMeta.value = meta;

    initializeChecks(makeSequenceInstanceIdChecks());
  });
};
fetchSequenceInstances();

return (_ctx: any,_cache: any) => {
  const _component_BPaginationMetaText = _resolveComponent("BPaginationMetaText")!
  const _component_BPagination = _resolveComponent("BPagination")!
  const _component_BContainer = _resolveComponent("BContainer")!
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_VWait = _resolveComponent("VWait")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BContainer, {
        class: "instance-list-control-inner",
        size: "large"
      }, {
        default: _withCtx(() => [
          (paginationMeta.value)
            ? (_openBlock(), _createBlock(_component_BPaginationMetaText, {
                key: 0,
                "current-page": internalPage.value,
                "paging-meta": paginationMeta.value
              }, null, 8, ["current-page", "paging-meta"]))
            : _createCommentVNode("", true),
          (paginationMeta.value)
            ? (_openBlock(), _createBlock(_component_BPagination, {
                key: 1,
                modelValue: internalPage.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((internalPage).value = $event)),
                class: "ml-a",
                length: paginationMeta.value.totalPages,
                "total-visible": 5,
                circle: ""
              }, null, 8, ["modelValue", "length"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_VWait, { for: "fetchSequenceInstancesWait" }, {
        waiting: _withCtx(() => [
          _withDirectives(_createVNode(_component_BPlaceholder, { "element-loading-background": "transparent" }, null, 512), [
            [_directive_loading, true]
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(InstanceListHeader, {
            "model-value": _unref(sequenceInstancesCheckAll),
            class: "instance-list-header",
            "onUpdate:modelValue": _unref(handleSequenceInstancesCheckAllUpdate)
          }, null, 8, ["model-value", "onUpdate:modelValue"]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sequenceInstances.value, (instance) => {
              return (_openBlock(), _createBlock(LeadLink, {
                key: instance.id,
                "call-target-id": instance.callTarget.id,
                "in-new-tab": "",
                tag: "div"
              }, {
                default: _withCtx(() => [
                  _createVNode(InstanceListItem, {
                    "model-value": _unref(findCheck)(instance.id),
                    "sequence-instance": instance,
                    "onUpdate:modelValue": _unref(toggleSequenceInstanceCheck)
                  }, null, 8, ["model-value", "sequence-instance", "onUpdate:modelValue"])
                ]),
                _: 2
              }, 1032, ["call-target-id"]))
            }), 128))
          ])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})
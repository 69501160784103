<template>
  <div
    v-loading="$wait.is('confirmLeadExcelOverwriteTaskWait')"
    class="lead-excel-import-status"
  >
    <ImportStatus
      v-for="task in leadExcelOverwriteTasks"
      :key="task.id"
      :target-task="task"
      :progress-message="$t('overwrite.excel.status.progress')"
      :error-message="$t('overwrite.excel.status.error')"
      :error-modal-title="$t('overwrite.excel.errorTitle')"
      :success-message="$t('overwrite.excel.status.success')"
      @confirm="() => confirmTask(task.id)"
      @success="$emit('success')"
    />
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import ImportStatus from '@/components/organisms/user/leadImport/ImportStatus.vue'

export default {
  components: {
    ImportStatus,
  },
  emits: [
    'success',
  ],
  computed: {
    ...mapGetters('user', ['leadExcelOverwriteTasks']),
  },
  created() {
    this.getLeadExcelOverwriteTasksAction()
    this.timeObj = this.$setInterval(() => {
      this.getLeadExcelOverwriteTasksAction()
    }, 90000)
  },
  beforeUnmount() {
    this.$clearInterval(this.timeObj)
  },
  methods: {
    ...mapWaitingActions('user', {
      getLeadExcelOverwriteTasksAction: 'getLeadExcelOverwriteTasksWait',
      confirmLeadExcelOverwriteTaskAction: 'confirmLeadExcelOverwriteTaskWait',
    }),
    confirmTask(taskId) {
      this.confirmLeadExcelOverwriteTaskAction({ leadExcelOverwriteTaskId: taskId })
    },
  },
}
</script>

<style scoped lang="scss">
  .import-status:nth-last-child(n + 2) {
    border-bottom: 1px solid $concrete-light;
  }
</style>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "ml-400" }

import { computed, watch } from 'vue';
import LeadLink from '@/components/organisms/user/general/LeadLink.vue';
import LeadListHeader from '@/components/organisms/user/leadList/LeadListHeader.vue';
import LeadListItem from '@/components/organisms/user/leadList/LeadListItem.vue';
import { TCheck, useCheckAll } from '@/composable/check-all';

type TProps = {
  // FIXME: CallTarget型, CallTargetMeta型がないので定義が必要。適当にobjectにしている
  callTargets: {
    callTargetId: number;
    [sKey: string]: unknown;
  }[];
  callTargetsMeta: {
    isEmpty?: boolean;
  };
  enabledToDisplayLead?: boolean;
  leadOpenByLeadList?: boolean;
};
type TEmit = {
  (event: 'openSetOperatorModal', callTargetId: number, operatorId: number): void;
  (event: 'openSfModal', sfRelatedLinkInfo: string): void;
  (event: 'update:selectedCallTargetIds', selectedCallTargetIds: number[]): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'LeadTable',
  props: {
    callTargets: {},
    callTargetsMeta: {},
    enabledToDisplayLead: { type: Boolean, default: false },
    leadOpenByLeadList: { type: Boolean, default: false }
  },
  emits: ["openSetOperatorModal", "openSfModal", "update:selectedCallTargetIds"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const {
  checkAll: callTargetIdsCheckAll,
  checkedValues: selectedCallTargetIds,
  initializeChecks,
  findCheck,
  handleCheckAllUpdate: handleCallTargetsCheckAllUpdate,
  toggleCheck: toggleCallTargetCheck,
} = useCheckAll();

const handleOpenSetOperatorModal = (callTargetId: number, operatorId: number) => {
  emit('openSetOperatorModal', callTargetId, operatorId);
};
const handleOpenSfModal = (sfRelatedLinkInfo: string) => {
  emit('openSfModal', sfRelatedLinkInfo);
};

watch(selectedCallTargetIds, (values) => {
  emit('update:selectedCallTargetIds', values);
});
watch(() => props.callTargets, () => {
  const checks: TCheck[] = props.callTargets.map(callTarget => ({
    value: callTarget.callTargetId,
    checked: false,
  }));
  initializeChecks(checks);
}, { immediate: true });

const leadComponent = computed(() => props.enabledToDisplayLead ? LeadLink : 'div');
const getLeadComponentAttrs = (callTargetId: number) => props.enabledToDisplayLead
  ? {
    callTargetId,
    openByLeadList: props.leadOpenByLeadList,
    tag: 'div',
  }
  : undefined;

return (_ctx: any,_cache: any) => {
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_VWait = _resolveComponent("VWait")!
  const _component_BIconText = _resolveComponent("BIconText")!
  const _component_BEmptyBox = _resolveComponent("BEmptyBox")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(LeadListHeader, {
      "model-value": _unref(callTargetIdsCheckAll),
      class: "instance-list-header",
      "onUpdate:modelValue": _unref(handleCallTargetsCheckAllUpdate)
    }, null, 8, ["model-value", "onUpdate:modelValue"]),
    (_ctx.callTargets.length > 0)
      ? (_openBlock(), _createBlock(_component_VWait, {
          key: 0,
          for: "getCallTargetsWait"
        }, {
          waiting: _withCtx(() => [
            _withDirectives(_createVNode(_component_BPlaceholder, {
              height: "50vh",
              "element-loading-background": "transparent"
            }, null, 512), [
              [_directive_loading, true]
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.callTargets, (callTarget, index) => {
                return (_openBlock(), _createBlock(_resolveDynamicComponent(leadComponent.value), _mergeProps({ ref_for: true }, getLeadComponentAttrs(callTarget.callTargetId), {
                  key: callTarget.callTargetId
                }), {
                  default: _withCtx(() => [
                    _createVNode(LeadListItem, {
                      "model-value": _unref(findCheck)(callTarget.callTargetId),
                      "call-target": callTarget,
                      "data-test": `leadListItem-${index}`,
                      onOpenSetOperatorModal: handleOpenSetOperatorModal,
                      onOpenSfModal: handleOpenSfModal,
                      "onUpdate:modelValue": _unref(toggleCallTargetCheck)
                    }, null, 8, ["model-value", "call-target", "data-test", "onUpdate:modelValue"])
                  ]),
                  _: 2
                }, 1040))
              }), 128))
            ])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_VWait, {
          key: 1,
          for: "none",
          visible: _ctx.$wait.is(['initLeadListPage', 'getCallTargetsWait'])
        }, {
          waiting: _withCtx(() => [
            _withDirectives(_createVNode(_component_BPlaceholder, {
              height: "50vh",
              "element-loading-background": "transparent"
            }, null, 512), [
              [_directive_loading, true]
            ])
          ]),
          default: _withCtx(() => [
            (_ctx.callTargetsMeta?.isEmpty)
              ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                  key: 0,
                  class: "mt-500 mb-600 pt-500 pb-500",
                  "display-only": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BIconText, { icon: "add" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('callTarget.empty')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_BEmptyBox, {
                  key: 1,
                  class: "mt-500 mb-600 pt-500 pb-500",
                  "display-only": ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('callTarget.notMatched')), 1)
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        }, 8, ["visible"]))
  ], 64))
}
}

})
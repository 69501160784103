import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { computed, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ApplySequenceMasterBodySequenceStepsInner, FailedCallTarget, SequenceStepMaster } from '@/api/openapi';
import { SequenceMasterApiService } from '@/api/user/resources/sequence_master';
import { TFormSequenceStep } from '@/components/organisms/user/general/sequence/types';
import { getActionDetail } from '@/composable/user/sequence/post-put-body';
import { useSequenceMaster } from '@/composable/user/sequence/sequence-master';
import { useSequenceStepMasterData, useSequenceStepMasters } from '@/composable/user/sequence/sequence-step-masters';
import { useUsers } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { KEY_OF_TOAST } from '@/injection-keys';
import { bitterAlert } from '@/plugins/BBitterAlert';
import { addMinutesToCurrentDateTime, isDateAfter, newDateByTime } from '@/utils/date-time';
import SequenceMaster from '../../lead/modal/sequenceApply/SequenceMaster.vue';
import SequenceSteps from '../../lead/modal/sequenceApply/SequenceSteps.vue';
import { TSequenceOwnerValue } from './SelectSequenceOwner.vue';

type TProps = {
  callTargetIds: number[];
  sequenceMasterId: number;
  ownerId?: TSequenceOwnerValue;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceApply',
  props: {
    callTargetIds: {},
    sequenceMasterId: {},
    ownerId: {}
  },
  emits: ['clickClose', 'afterApply', 'backToSequenceList'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const {
  doActionWithWait,
} = useWait();

const i18n = useI18n();
const { wait } = useWait();
const toast = inject(KEY_OF_TOAST);
const tabLabelItems = [
  { label: i18n.t('sequence.step.title'), slotName: 'step' },
];

const sequenceStepsBodyData = ref<ApplySequenceMasterBodySequenceStepsInner[]>([]);
const loading = computed(() => wait.is(['fetchSequenceMaster', 'fetchSequenceStepMasters', 'applySequenceMaster']));

const selectedOwner = computed(() => {
  if (props.ownerId == null || props.ownerId === 'sequenceOwner') return sequenceMaster.value?.owner;
  if (props.ownerId === 'leadOwner') return null;

  return users.value.find(user => user.id === props.ownerId);
});
const selectedOwnerId = computed(() => selectedOwner.value?.id);
const displayOwnerName = computed(() => props.ownerId === 'leadOwner' ? i18n.t('sequence.selectOwner.leadOwnerDisplayName') : selectedOwner.value?.name);

const settingLeadOwnerAsOwner = computed(() => props.ownerId === 'leadOwner');

const {
  sequenceMaster,
  fetchSequenceMaster,
} = useSequenceMaster(props.sequenceMasterId);
const {
  sequenceStepMasters,
  fetchSequenceStepMasters,
} = useSequenceStepMasters(props.sequenceMasterId);
fetchSequenceMaster('fetchSequenceMaster');
fetchSequenceStepMasters('fetchSequenceStepMasters');
const { users } = useUsers();

const { sequenceStepData: sequenceStepMasterData } = useSequenceStepMasterData(() => sequenceStepMasters.value as SequenceStepMaster[]);

const handleClose = async () => {
  emit('clickClose');
};
const handleBackToSequenceList = () => {
  emit('backToSequenceList');
};

const handleUpdateSequenceSteps = (sequenceSteps: TFormSequenceStep[]) => {
  sequenceStepsBodyData.value = sequenceSteps.map((step) => {
    const { id, sortOrder, actionType, dateInterval, reservedTime, priority, immediately } = step;
    return {
      id,
      sortOrder,
      actionType,
      dateInterval,
      reservedTime,
      priority,
      immediately,
      actionDetail: getActionDetail(step),
    } as ApplySequenceMasterBodySequenceStepsInner;
  });
};

const handleApply = async () => {
  if (!await confirmPastReserveTime(sequenceStepsBodyData.value)) return;

  const api = new SequenceMasterApiService();
  try {
    let failedCallTargets: FailedCallTarget[] = [];
    await doActionWithWait<void>('applySequenceMaster', async () => {
      const { data } = await api.applySequenceMaster({
        request: {
          applySequenceMasterBody: {
            sequenceMasterId: props.sequenceMasterId,
            callTargetIds: props.callTargetIds,
            ownerId: selectedOwnerId.value,
            settingLeadOwnerAsOwner: settingLeadOwnerAsOwner.value,
            sequenceSteps: sequenceStepsBodyData.value,
          },
        },
      })
      failedCallTargets = data.failedCallTargets;
    });
    emit('afterApply', failedCallTargets);
  } catch (e) {
    toast.show({
      message: i18n.t('general.fail.to', { action: i18n.t('sequence.apply') }),
      type: 'error',
    })
  }
}

const shouldShowPastReserveTimeAlert = (sequenceStepData: ApplySequenceMasterBodySequenceStepsInner[]) => {
  const reservedTime = sequenceStepData[0].reservedTime.split(':') as unknown as [number, number];
  return sequenceStepData.length > 0
    && !sequenceStepData[0].immediately
    && sequenceStepData[0].dateInterval === 0
    && !isDateAfter(newDateByTime(reservedTime[0] || 0, reservedTime[1] || 0, 0), addMinutesToCurrentDateTime(5));
};

const confirmPastReserveTime = async (sequenceStepData: ApplySequenceMasterBodySequenceStepsInner[]) => {
  if (shouldShowPastReserveTimeAlert(sequenceStepData)) {
    return await bitterAlert.show({
      title: i18n.t('general.confirm.text'),
      text: i18n.t('sequence.pastSequenceReserveTime'),
    });
  }
  return true;
};

return (_ctx: any,_cache: any) => {
  const _component_BModalHeader = _resolveComponent("BModalHeader")!
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_BTabs = _resolveComponent("BTabs")!
  const _component_BModalBody = _resolveComponent("BModalBody")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BModalFooter = _resolveComponent("BModalFooter")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BModalHeader, {
      title: _ctx.$t('sequence.title'),
      onModalClose: handleClose
    }, null, 8, ["title"]),
    _withDirectives((_openBlock(), _createBlock(_component_BModalBody, { full: "" }, {
      waiting: _withCtx(() => [
        _withDirectives(_createVNode(_component_BPlaceholder, {
          height: "100%",
          "element-loading-background": "transparent"
        }, null, 512), [
          [_directive_loading, true]
        ])
      ]),
      default: _withCtx(() => [
        _withDirectives(_createVNode(SequenceMaster, {
          "sequence-master-id": _ctx.sequenceMasterId,
          "owner-name": displayOwnerName.value
        }, null, 8, ["sequence-master-id", "owner-name"]), [
          [_directive_loading, loading.value]
        ]),
        _createVNode(_component_BTabs, {
          class: "tabs",
          "label-items": tabLabelItems
        }),
        _createVNode(SequenceSteps, {
          "sequence-step-master-data": _unref(sequenceStepMasterData),
          onUpdate: handleUpdateSequenceSteps
        }, null, 8, ["sequence-step-master-data"])
      ]),
      _: 1
    })), [
      [_directive_loading, loading.value]
    ]),
    _createVNode(_component_BModalFooter, null, {
      default: _withCtx(() => [
        _createVNode(_component_BLayout, {
          class: "mt-200",
          "justify-center": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BBtn, {
              class: "mr-100",
              flat: "",
              onClick: handleBackToSequenceList
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.back.text')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_BBtn, {
              type: "primary",
              loading: loading.value,
              onClick: handleApply
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('sequence.leadDetail.apply')), 1)
              ]),
              _: 1
            }, 8, ["loading"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "modal-body" }
const _hoisted_2 = { class: "description" }
const _hoisted_3 = { class: "error-list-container" }

import { ref } from 'vue';
import { FailedCallTarget } from '@/api/openapi'
import FailedCallTargetListItem from '@/components/organisms/user/general/modal/FailedCallTargetListItem.vue';
import BHeightAdjuster from '@/plugins/biscuet-materials/components/atoms/BHeightAdjuster.vue';

type TProps = {
  title: string;
  failedCallTargets: FailedCallTarget[];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'FailedCallTargetsModal',
  props: {
    title: {},
    failedCallTargets: {}
  },
  setup(__props: any) {



const modalName = ref('failedCallTargetsModal');

return (_ctx: any,_cache: any) => {
  const _component_BModalHeader = _resolveComponent("BModalHeader")!
  const _component_BModalBody = _resolveComponent("BModalBody")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BModalFooter = _resolveComponent("BModalFooter")!
  const _component_BModal = _resolveComponent("BModal")!

  return (_openBlock(), _createBlock(_component_BModal, {
    "modal-name": modalName.value,
    width: "680px",
    height: "400px"
  }, {
    default: _withCtx(() => [
      _createVNode(BHeightAdjuster, null, {
        default: _withCtx(() => [
          _createVNode(_component_BModalHeader, {
            title: _ctx.title,
            "needs-close-btn": false,
            center: ""
          }, null, 8, ["title"]),
          _createVNode(_component_BModalBody, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('general.followingItemsCouldNotRegistered', { itemName: _ctx.$t('callTarget.title') })), 1),
                _createElementVNode("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.failedCallTargets, (failedCallTarget) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(failedCallTarget.errorMessages, (errorMessage) => {
                        return (_openBlock(), _createBlock(FailedCallTargetListItem, {
                          key: failedCallTarget.callTarget.id + errorMessage,
                          class: "error-list-item",
                          "call-target-id": failedCallTarget.callTarget.id,
                          "company-name": failedCallTarget.callTarget.companyName,
                          "full-name": failedCallTarget.callTarget.fullName,
                          "error-message": errorMessage
                        }, null, 8, ["call-target-id", "company-name", "full-name", "error-message"]))
                      }), 128))
                    ], 64))
                  }), 256))
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_BModalFooter, { class: "modal-footer" }, {
            default: _withCtx(() => [
              _createVNode(_component_BBtn, {
                type: "primary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$modal.hide(modalName.value)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.ok')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-name"]))
}
}

})
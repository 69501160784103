<template>
  <div
    class="editable-lead-stage-color-preview-wrapper"
    :class="{ uneditable: uneditable }"
  >
    <template v-if="editing">
      <BLeadStageColorSelect
        v-model="value"
      />
    </template>
    <template v-else>
      <BLeadStageColorPreview
        :value="value"
        @click="handlePreviewClick"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

type TData = {
  editing: boolean;
}

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    uneditable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data (): TData {
    return {
      editing: false,
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
        this.editing = false
      },
    },
  },
  methods: {
    handlePreviewClick(): void {
      if (this.uneditable) return
      this.editing = true
    },
  },
  
})
</script>

<style lang="scss" scoped>
.editable-lead-stage-color-preview-wrapper {
  cursor: pointer;
  &.uneditable {
    cursor: default;
  }
  :deep(.color-preview) {
    vertical-align: middle;
  }
}
</style>

import type { Ref } from 'vue';
import { useRouter } from 'vue-router';
import type { RouteLocationRaw } from 'vue-router';
import { i18nGlobal as i18n } from '@/i18n';
import { bitterAlert } from '@/plugins/BBitterAlert';
import { TLableItem } from '@/plugins/biscuet-materials/components/atoms/BTabs.vue';

type TBTabItem = TLableItem & { route?: RouteLocationRaw };

const useUrlLinkBtabsHandlers = (items: TBTabItem[], needsConfirmationToLeave?: Ref<boolean>) => {
  const router = useRouter();

  const handleTabClick = (tabName: string) => {
    const route = items.find(e => e.slotName === tabName)?.route;
    if (route) movePage(route);
  };
  
  const handleTabBeforeLeave = () => {
    return false;
  };

  const movePage = async (to: RouteLocationRaw) => {
    if (needsConfirmationToLeave?.value) {
      const ok = await bitterAlert.show({
        title: i18n.t('general.confirm.text'),
        text: i18n.t('general.alert.of', { target: i18n.t('general.unsavedValue'), action: i18n.t('general.clear') }),
      });
      if (!ok) return;
    }
    router.push(to);
  };

  return {
    handleTabClick,
    handleTabBeforeLeave,
  }
};

export {
  useUrlLinkBtabsHandlers,
}

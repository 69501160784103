import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "toast-container" }

import { Ref, inject } from 'vue';
import ToastItem from './ToastItem.vue'
import { TToastOptions } from './types';


export default /*@__PURE__*/_defineComponent({
  __name: 'BToast',
  setup(__props) {

const toasts = inject<Readonly<Ref<TToastOptions[]>>>('toasts');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, {
      name: "toast",
      tag: "div"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(toasts), (toast) => {
          return (_openBlock(), _createBlock(ToastItem, {
            key: toast.id,
            message: toast.message,
            title: toast.title,
            type: toast.type
          }, null, 8, ["message", "title", "type"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "page-title fs-400 fw-bold mr-400" }
const _hoisted_2 = { class: "tbody" }
const _hoisted_3 = { class: "row" }

import { onMounted, ref } from 'vue'
import { SfTodoSyncSettingsService } from '@/api/user/resources/salesforce/sf_todo_sync_settings';


export default /*@__PURE__*/_defineComponent({
  __name: 'TodoSync',
  setup(__props) {

const todoSyncSettingMap = ref({
  'bulk_mail': true,
  'call_result': true,
  'direct_mail': true,
  'receive_mail': true,
  'free_action': true,
});

const sfApi = new SfTodoSyncSettingsService();
const beforeChange = async (todoType, active) => {
  await sfApi.upsertSfTodoSyncSetting({
    sfTodoSyncSettingBody: {
      todoType,
      active: !active,
    },
  });
  await load();
}
const load = async () => {
  const results = await sfApi.getSfTodoSyncSettings();
  if (results.data == null) {
    return;
  }
  for (const data of results.data) {
    for (const key in todoSyncSettingMap.value) {
      const todoSyncSetting = data;
      if (key === todoSyncSetting.todoType) {
        todoSyncSettingMap.value[key] = todoSyncSetting.active;
      }
    }
  }
}
onMounted(async () => {
  await load();
});

return (_ctx: any,_cache: any) => {
  const _component_BSwitch = _resolveComponent("BSwitch")!
  const _component_BContent = _resolveComponent("BContent")!

  return (_openBlock(), _createBlock(_component_BContent, { class: "content" }, {
    navbar: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("todoSync.title")), 1),
        _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "notice" }, "設定を有効にすると、BALES CLOUD上のTODOデータをSalesforceに同期することができます。", -1))
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(todoSyncSettingMap.value, (use, todoSync) => {
          return (_openBlock(), _createElementBlock("div", { key: todoSync }, [
            _createElementVNode("div", _hoisted_3, [
              _createTextVNode(_toDisplayString(_ctx.$t(`todoSync.mapping.${todoSync}`)) + " ", 1),
              _createVNode(_component_BSwitch, {
                status: use,
                "before-change": async () => await beforeChange(todoSync, use),
                color: "accent"
              }, null, 8, ["status", "before-change"])
            ])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "sequence-step-with-connector-container" }
const _hoisted_2 = {
  key: 0,
  class: "step-stats"
}
const _hoisted_3 = { class: "step-stat-name" }
const _hoisted_4 = { class: "step-stat-value" }
const _hoisted_5 = {
  key: 0,
  class: "step-stat-percent"
}

import { computed } from 'vue'
import { useI18n } from 'vue-i18n';
import { NextActionTypeOrDefaultType } from '@/api/user/models/next_action_types';
import { getDisplayContent, getDisplaySubContent, getStepTypeByActionType, isDirectMailActionType, isDirectMailStepExecuted } from '@/composable/user/sequence/post-put-body';
import { useSequenceStepComputedProperty } from '@/composable/user/sequence/step-computed-property';
import { TMenu } from '@/plugins/biscuet-materials/components/molecules/connector';
import { TActionWithDisabled, TFormSequenceStep, TMailAggregates, TStepEventType, TStepType, TStepStat } from './types';

type TProps = {
  sequenceStep: TFormSequenceStep;
  needsAddButton: boolean;
  isMenuOpen: boolean;
  disabledAddButton: boolean;
  nextActionType?: NextActionTypeOrDefaultType;
  actions: TActionWithDisabled[];
};

type TEmits = {
  (event: 'click:insertNextAction'): void;
  (event: 'click:insertDirectMail'): void;
  (event: TStepEventType): void;
  (event: 'update:isMenuOpen', value: boolean): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceStepWithConnector',
  props: {
    sequenceStep: {},
    needsAddButton: { type: Boolean },
    isMenuOpen: { type: Boolean },
    disabledAddButton: { type: Boolean },
    nextActionType: { default: undefined },
    actions: {}
  },
  emits: ["click:insertNextAction", "click:insertDirectMail", "update:isMenuOpen"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const i18n = useI18n();
const menus: TMenu<TStepType> = [
  { key: 'next_action', label: i18n.t('sequence.step.types.nextAction') },
  { key: 'direct_mail', label: `${i18n.t('sequence.step.types.directMail')}` },
];

const addButtonPosition = computed(() => props.needsAddButton ? 'middle' : 'none');

const stepType = computed(() => getStepTypeByActionType(props.sequenceStep.actionType));

const expandableHtmlContent = computed(() => getDisplaySubContent(props.sequenceStep))

const { title, isSkipped } = useSequenceStepComputedProperty(() => props.sequenceStep);
const content = computed(() => getDisplayContent(props.sequenceStep));
const icon = computed(() => isDirectMailActionType(props.sequenceStep.actionType) ? 'b-mail' : 'b-next-action');

const internalIsMenuOpen = computed({
  get() {
    return props.isMenuOpen;
  },
  set(newValue) {
    emit('update:isMenuOpen', newValue);
  },
});
const isRunning = computed(() => props.sequenceStep.status === 'running');
const isFailed = computed(() => props.sequenceStep.status === 'failed');
const isSending = computed(() => isRunning.value && isDirectMailStepExecuted(props.sequenceStep));
const isDone = computed(() => props.sequenceStep.status === 'done');

const stepStats = computed<TStepStat[]>(() => {
  if (stepType.value !== 'direct_mail') return [];
  const aggregates = (props.sequenceStep as TMailAggregates).mailAggregates;
  if (aggregates == null) return [];
  return [
    { key: 'mailAggregates.all', name: i18n.t('sequence.mailAggregates.all'), value: aggregates.numberOfAll, percent: null },
    { key: 'mailAggregates.sent', name: i18n.t('sequence.mailAggregates.sent'), value: aggregates.numberOfSent, percent: aggregates.sentPerAll },
    { key: 'mailAggregates.opened', name: i18n.t('sequence.mailAggregates.opened'), value: aggregates.numberOfOpened, percent: aggregates.openedPerSent },
    { key: 'mailAggregates.clicked', name: i18n.t('sequence.mailAggregates.clicked'), value: aggregates.numberOfClicked, percent: aggregates.clickedPerSent },
    { key: 'mailAggregates.replied', name: i18n.t('sequence.mailAggregates.replied'), value: aggregates.numberOfReplied, percent: aggregates.repliedPerSent },
    { key: 'mailAggregates.failed', name: i18n.t('sequence.mailAggregates.failed'), value: aggregates.numberOfFailed, percent: aggregates.failedPerAll },
  ];
});

// NOTE: 任意のイベントをhandle＆発火
const cardHandlers = computed(() =>
  Object.fromEntries(
    props.actions
      .filter(({ disabled }) => !disabled)
      .map(({ event }) => [event, () => emit(event)]),
  ),
);

const handleAddClick = (stepType: TStepType) => {
  if (props.disabledAddButton) return;
  stepType === 'direct_mail' ? emit('click:insertDirectMail') : emit('click:insertNextAction');
}

return (_ctx: any,_cache: any) => {
  const _component_BTodoCard = _resolveComponent("BTodoCard")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BConnector = _resolveComponent("BConnector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BTooltip, {
      "bottom-end": "",
      "show-arrow": false,
      content: _ctx.$t('sequence.step.skippedStep'),
      disabled: !_unref(isSkipped)
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BTodoCard, _mergeProps({
          "is-menu-open": internalIsMenuOpen.value,
          "onUpdate:isMenuOpen": _cache[0] || (_cache[0] = ($event: any) => ((internalIsMenuOpen).value = $event)),
          class: ["step-card", { 'disabled': _unref(isSkipped), 'current-step': isRunning.value, 'failed': isFailed.value, 'sending': isSending.value }],
          "is-done": isDone.value,
          title: _unref(title),
          content: content.value,
          actions: _ctx.actions,
          "expandable-html-content": expandableHtmlContent.value,
          icon: icon.value
        }, _toHandlers(cardHandlers.value)), {
          "sub-content": _withCtx(() => [
            (stepStats.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stepStats.value, (stat) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: stat.key,
                      class: "pl-50"
                    }, [
                      _createElementVNode("div", _hoisted_3, _toDisplayString(stat.name), 1),
                      _createElementVNode("div", _hoisted_4, [
                        _createTextVNode(_toDisplayString(stat.value) + " ", 1),
                        (stat.percent)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(`(${stat.percent}%)`), 1))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 16, ["is-menu-open", "class", "is-done", "title", "content", "actions", "expandable-html-content", "icon"])
      ]),
      _: 1
    }, 8, ["content", "disabled"]),
    _createVNode(_component_BConnector, {
      menus: menus,
      "add-button-position": addButtonPosition.value,
      "disabled-add-button": _ctx.disabledAddButton,
      "add-button-disabled-tooltip": _ctx.$t('sequence.step.limitMessage'),
      "onClick:addButton": handleAddClick
    }, null, 8, ["add-button-position", "disabled-add-button", "add-button-disabled-tooltip"])
  ]))
}
}

})
<template>
  <BTooltip
    top
    :content="disableConnectToolChipText"
    :disabled="!disableConnectCheckbox"
  >
    <div>
      <BLayout
        class="mt-300 mb-300"
        align-center
        justify-center
      >
        <BCheckbox
          class="all-checkbox"
          :model-value="isSfTaskSync"
          :disabled="disableConnectCheckbox"
          :label="disableConnectCheckbox ? '' : $t('sfActionTask.connectSalesforce')"
          @change="onClick"
        />
        <label
          v-if="disableConnectCheckbox"
          class="disabledLabel"
        >{{ $t("sfActionTask.connectSalesforce") }}</label>
      </BLayout>
    </div>
  </BTooltip>
</template>

<script>
import SfTaskSyncService from '@/mixins/salesforce/sf_task_sync_service';
import BTooltip from '@/plugins/biscuet-materials/components/atoms/BTooltip.vue';

export default {
  components: {
    BTooltip,
  },
  mixins: [SfTaskSyncService],
  props: {
    isEdit: Boolean,
    checkedIsSfSync: Boolean,
    onClick: {
      type: Function,
      default: () => {
        console.log('dummy onClick');
      },
    },
  },
  setup(props) {
    return { ...SfTaskSyncService.setup(props) }
  },
}
</script>

<style lang="scss" scoped>
.disabledLabel{
  color: $textcolor-light;
  line-height: 0;
}
</style>

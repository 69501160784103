import { ref } from 'vue';
import { CustomAction } from '@/api/openapi';
import { CustomActionApiService } from '@/api/user/resources/custom_action';
import { useWait } from '@/composable/vue-wait';

const useCustomActions = () => {
  const { doActionWithWait } = useWait();

  const customActions = ref<CustomAction[]>([]);
  const fetchCustomActions = async () => {
    await doActionWithWait('listCustomActionsWait', async () => {
      const api = new CustomActionApiService();
      const { data } = await api.getCustomActions();
      customActions.value = data;
    });
  };
  fetchCustomActions();

  return {
    customActions,
    fetchCustomActions,
  };
};

export {
  useCustomActions,
}

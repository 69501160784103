<template>
  <BSelect
    ref="refSelect"
    v-model="value"
    :items="priorities"
    :loading="loading"
    :required="required"
    :fit="fit"
    :automatic-dropdown="automaticDropdown"
    :teleported="teleported"
    item-text="name"
    item-value="priority"
  >
    <template #prefix>
      <BPriorityColorPreview
        v-if="value !== VALUE_ALL"
        :priority="value"
        size="small"
      />
    </template>
    <template #optionTemplate="{ item }">
      <span v-if="item.priority == VALUE_ALL">{{ item.name }}</span>
      <BPriorityPreview
        v-else
        :priority="item.priority"
        size="small"
      />
    </template>
  </BSelect>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { PriorityPriorityEnum } from '@/api/openapi';
import BPriorityPreview from './BPriorityPreview.vue';
import priorityWithoutNotSet from './priority';

type TProps = {
  modelValue: PriorityPriorityEnum | null;
  // for BSelect
  loading?: boolean;
  required?: boolean;
  fit?: boolean;
  needsAllOption?: boolean;
  teleported?: boolean;
};
type TEmit = {
  'update:modelValue': [modelValue: PriorityPriorityEnum | null];
}

const props = withDefaults(defineProps<TProps>(), {
  loading: false,
  required: false,
  fit: false,
  needsAllOption: false,
  teleported: true,
});

const emit = defineEmits<TEmit>();

const VALUE_ALL = 'all';
const i18n = useI18n();
const prependedPriorities = [];
if (props.needsAllOption) {
  prependedPriorities.push({
    priority: VALUE_ALL,
    name: i18n.t('general.priority.all'),
  });
}
const priorities = prependedPriorities.concat(priorityWithoutNotSet.map((priority) => ({
  priority,
  name: i18n.t(`general.priority.${priority}`),
})));

const automaticDropdown = ref(false);
const refSelect = ref<HTMLElement>(null);
const value = computed<PriorityPriorityEnum | typeof VALUE_ALL | null>({
  get() {
    return props.modelValue === null ? VALUE_ALL : props.modelValue
  },
  set(v) {
    emit('update:modelValue', v === VALUE_ALL ? null : v);
  },
});

const focus = async () => {
  // NOTE: automaticDropdown=trueにしないとfocus時にdropdownが開かないが、ONのままにしておくとclick時にdropdownが開かなくなってしまう（Element Plusのバグかも）
  automaticDropdown.value = true;
  refSelect.value.focus();
  await nextTick();
  automaticDropdown.value = false;
};

defineExpose({
  focus,
});
</script>

<style lang="scss" scoped>
.priority-preview {
  display: flex;
  align-items: center;

  .color-preview {
    margin-right: 10px;
  }
  .placeholder {
    color: $concrete-dark;
  }
}
.el-select-dropdown__item {
  .priority-preview {
    // NOTE: 標準のoptionと同様に40文字でtruncateされるようにする
    max-width: 600px;
  }
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "reserve-card" }
const _hoisted_2 = ["onClick"]

import { ReserveDirectMail } from '@/api/openapi';
import { isRelatedToSequence } from '@/composable/user/directMail/direct-mail';
import DirectMailItem from './general/DirectMail.vue';

type TProps = {
  reserveItems: ReserveDirectMail[];
  keyOfGetReserveItemsWait: string;
};
type TEmits = {
  (event: 'click:directMailConfirm', directMail: ReserveDirectMail): void,
  (event: 'afterDeleteDirectMail'): void,
  (event: 'click:sequenceAction', sequenceInstanceId: number): void
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ReserveItemArea',
  props: {
    reserveItems: {},
    keyOfGetReserveItemsWait: {}
  },
  emits: ["click:directMailConfirm", "afterDeleteDirectMail", "click:sequenceAction"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit

const handleDirectMailConfirm = (directMail: ReserveDirectMail) => {
  if (isRelatedToSequence(directMail)) {
    emit('click:sequenceAction', directMail.sequenceInstanceId)
  } else {
    emit('click:directMailConfirm', directMail)
  }
};
const handleDirectMailReload = () => { emit('afterDeleteDirectMail') };

return (_ctx: any,_cache: any) => {
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reserveItems, (directMail) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `reserve-${directMail.id}`,
        class: "reserve-list",
        onClick: () => handleDirectMailConfirm(directMail)
      }, [
        _createVNode(DirectMailItem, {
          "direct-mail": directMail,
          "display-datetime-column": "reservedAt",
          "onClick:confirm": () => handleDirectMailConfirm(directMail),
          onAfterDelete: handleDirectMailReload
        }, null, 8, ["direct-mail", "onClick:confirm"])
      ], 8, _hoisted_2))
    }), 128))
  ])), [
    [_directive_loading, _ctx.$wait.is(_ctx.keyOfGetReserveItemsWait)]
  ])
}
}

})
<template>
  <BContent
    v-loading="$wait.is(['getSfLeadSyncErrorLogsWait'])"
    class="content"
  >
    <template #navbar>
      <div class="navbar-left">
        <span class="page-title fs-400 fw-bold">{{ $t('salesforceSyncErrorLog.title') }}</span>
      </div>
      <div class="navbar-right">
        <BPagination
          v-model="currentPage"
          :length="allPageCount"
          :total-visible="5"
        />
      </div>
    </template>
    <div class="theader">
      <div class="table-row theader-row">
        <BSortText
          v-for="cell in headers"
          :key="cell.key"
          class="table-cell header-cell"
          :class="cell.class"
          :item-text="cell.name"
          is-display-only
        />
        <div class="table-cell menu" />
      </div>
    </div>
    <div class="tbody">
      <div
        v-for="sfLeadSyncErrorLog in sfLeadSyncErrorLogs"
        :key="sfLeadSyncErrorLog.id"
        class="table-row tbody-row"
      >
        <div
          v-for="column in headers"
          :key="column.key"
          class="table-cell"
          :class="column.class"
        >
          <template v-if="column.key === 'objectType'">
            <div>
              {{ objectTypeText(sfLeadSyncErrorLog.objectType) }}
            </div>
          </template>
          <template v-if="column.key === 'message'">
            <div>
              {{ sfLeadSyncErrorLog.message }}
            </div>
          </template>
          <template v-if="column.key === 'createdAt'">
            <div>
              {{ formatShorterDateTime(sfLeadSyncErrorLog.createdAt) }}
            </div>
          </template>
          <template v-if="column.key === 'callTargetUrl'">
            <div>
              {{ sfLeadSyncErrorLog.callTarget.name }}
              <BIcon
                class="pointer"
                @click="() => open(sfLeadSyncErrorLog.callTarget.url)"
              >
                open_in_new
              </BIcon>
            </div>
          </template>
          <template v-if="column.key === 'sfObjcets'">
            <div>
              <div
                v-for="sfObject in sfLeadSyncErrorLog.sfObjects"
                :key="sfObject.type"
              >
                {{ sfObject.type }}
                <BIcon
                  class="pointer"
                  @click="() => open(sfObject.url)"
                >
                  open_in_new
                </BIcon>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </BContent>
</template>

<script lang="ts" setup>
import { computed, inject, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n'
import { GetSfLeadSyncErrorLogs200Response, PaginationMeta, SfLeadSyncErrorLog } from '@/api/openapi';
import { SfLeadSyncLogsService } from '@/api/user/resources/salesforce/sf_lead_sync_error_logs';
import { KEY_OF_WAIT } from '@/injection-keys';
import { formatShorterDateTime } from '@/utils/date-time';

const i18n = useI18n();
const apiService = new SfLeadSyncLogsService();
const currentPage = ref(1);
const sfLeadSyncErrorLogs = ref<SfLeadSyncErrorLog[]>([]);
const wait = inject(KEY_OF_WAIT);
const meta = ref<PaginationMeta>({
  totalPages: 1,
  totalCount: 0,
  limitValue: 0,
});

const allPageCount = computed(() => meta.value.totalPages);
const loadSfLeadSyncErrorLogs = async (currentPage: number) => {
  wait.start('getSfLeadSyncErrorLogsWait');
  const data = await apiService.getSfLeadSyncErrorLogs({ request: { currentPage } });
  ({ sfLeadSyncErrorLogs: sfLeadSyncErrorLogs.value, meta: meta.value } = data.data as GetSfLeadSyncErrorLogs200Response);
  wait.end('getSfLeadSyncErrorLogsWait');
};

const open = (url: string) => {
  window.open(url, '_blank')
}

const objectTypeText = (objectType: string) => {
  return i18n.t(`salesforceSyncErrorLog.objectType.${objectType}`)
}


watch(() => currentPage.value, (newValue) => {
  loadSfLeadSyncErrorLogs(newValue);
}, { immediate: true });

const headers = [
  { key: 'createdAt', name: i18n.t('salesforceSyncErrorLog.list.date'), class: [] },
  { key: 'message', name: i18n.t('salesforceSyncErrorLog.list.message'), class: ['long-container'] },
  { key: 'objectType', name: i18n.t('salesforceSyncErrorLog.list.objectType'), class: [] },
  { key: 'callTargetUrl', name: i18n.t('salesforceSyncErrorLog.list.leadUrl'), class: [] },
  { key: 'sfObjcets', name: i18n.t('salesforceSyncErrorLog.list.sfUrls'), class: [] },
];
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
.table-row {
  align-items: center;
  padding-right: $basespace-400;
  display: inline-flex;
  min-width: 100%;
}

.table-cell {
  @include m-fixed-width(180px);
  padding-right: $basespace-200;
  padding-left: 10px;
  background-color: white;
  text-overflow: ellipsis;
  overflow: hidden;
  &:first-child {
    padding-left: 30px;
  }

  &.long-container {
    @include m-fixed-width(500px);
    left: 0;
    z-index: 1;
    display: flex;
    // NOTE: user列と高さを揃えている
    min-height: 40px;
    align-items: center;
    .name {
      flex: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.theader {
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 2;
  cursor: text;
  &-row {
    height: inherit;
    background-color: white;
    border-bottom: 1px solid $bdcolor-base;
    padding-bottom: $basespace-100;
    color: $textcolor-light;
    font-size: $fontsize-100;
  }
}

.tbody-row {
  border-bottom: 1px solid $bdcolor-base;
  padding-top: $basespace-200;
  padding-bottom: $basespace-200;
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "setting-title" }
const _hoisted_2 = { class: "explain" }

import { ref, computed, inject, watch } from 'vue';
import { User } from '@/api/openapi';
import { CurrentUserApiService } from '@/api/user/resources/current_user';
import { UserSenderEmailApiService } from '@/api/user/resources/user_sender_email';
import { useInputValidations } from '@/composable/input-validations';
import { useWait } from '@/composable/vue-wait';
import { i18nGlobal as i18n } from '@/i18n';
import { KEY_OF_TOAST } from '@/injection-keys';
import { bitterAlert } from '@/plugins/BBitterAlert';

type TProps = {
  user: User | null;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'SenderEmail',
  props: {
    user: {}
  },
  emits: ['update:user'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const { doActionWithWait } = useWait();
const { requiredRule, emailRule } = useInputValidations();
const toast = inject(KEY_OF_TOAST);

const inputSenderEmail = ref<string | null>(null);
const savedSenderEmail = ref<string | null>(null);
const senderEmailValid = ref<boolean>(false);

const inputIsReceiveMailAddressAsCc = ref<boolean>(true);
const hasMailApiConnection = ref<boolean>(false);

const isSenderEmailChanged = computed(() => savedSenderEmail.value !== inputSenderEmail.value);
const isSaveEnabled = computed(() => !hasMailApiConnection.value && (!isSenderEmailChanged.value || senderEmailValid.value));

const setData = (user: User | null) => {
  if (user == null) return;

  const { senderEmail, isReceiveMailAddressAsCc, mailProvider } = user;
  const existsMailApiConnection = mailProvider !== 'sendgrid';
  // NOTE: senderEmailは「保存」時点ではDBの値が更新されない。入力値を保持するため、初回とメール連携状態変更時のみDBの値を設定する
  if (inputSenderEmail.value == null || hasMailApiConnection.value !== existsMailApiConnection) {
    savedSenderEmail.value = senderEmail;
    inputSenderEmail.value = senderEmail;
  }
  inputIsReceiveMailAddressAsCc.value = isReceiveMailAddressAsCc;
  hasMailApiConnection.value = existsMailApiConnection;
};

watch(() => props.user, (newVal) => {
  setData(newVal);
}, { immediate: true });

const save = async () => {
  await updateSenderEmail();
  await updateReceiveMailAddressAsCc();

  emit('update:user');
};

const userSenderEmailApi = new UserSenderEmailApiService();
const updateSenderEmail = async () => {
  if (!isSenderEmailChanged.value || !senderEmailValid.value) return;

  const ok = await bitterAlert.show({
    title: i18n.t('changeEmail.confirmModal.title'),
    text: i18n.t('changeEmail.confirmModal.explain', { itemName: i18n.t('senderEmail.title') }),
    buttonsCancel: i18n.t('general.cancel.text'),
    className: 'swal-modal-large',
  });
  if (!ok) return;

  await doActionWithWait('updateSenderEmail', async () => {
    await userSenderEmailApi.prepareUserSenderEmail({
      request: {
        userSenderEmailPrepareBody: {
          senderEmail: inputSenderEmail.value,
        },
      },
    });

    toast.show(
      i18n.t('general.done.withTarget', {
        action: i18n.t('general.send.text'),
        target: i18n.t('senderEmail.confirmationMail'),
      }),
    );

    savedSenderEmail.value = inputSenderEmail.value;
  });
};

const currentUserApi = new CurrentUserApiService();
const updateReceiveMailAddressAsCc = async () => {
  await doActionWithWait('updateReceiveMailAddressAsCc', async () => {
    await currentUserApi.updateCurrentUser({
      request: {
        putCurrentUserRequest: {
          isReceiveMailAddressAsCc: inputIsReceiveMailAddressAsCc.value,
        },
      },
    });
  });
};

return (_ctx: any,_cache: any) => {
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BCheckbox = _resolveComponent("BCheckbox")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('senderEmail.title')), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('senderEmail.explain')), 1),
    _withDirectives((_openBlock(), _createBlock(_component_BLayout, {
      column: "",
      class: "form"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BListItem, null, {
          header: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('user.senderEmail')), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_BInput, {
              modelValue: inputSenderEmail.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputSenderEmail).value = $event)),
              valid: senderEmailValid.value,
              "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => ((senderEmailValid).value = $event)),
              class: "w-input",
              rules: [_unref(requiredRule), _unref(emailRule)],
              clearable: "",
              disabled: hasMailApiConnection.value
            }, null, 8, ["modelValue", "valid", "rules", "disabled"])
          ]),
          _: 1
        }),
        _createVNode(_component_BListItem, { class: "mt-200" }, {
          default: _withCtx(() => [
            _createVNode(_component_BCheckbox, {
              modelValue: inputIsReceiveMailAddressAsCc.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((inputIsReceiveMailAddressAsCc).value = $event)),
              label: _ctx.$t('user.isReceiveMailAddressAsCc'),
              disabled: hasMailApiConnection.value
            }, null, 8, ["modelValue", "label", "disabled"])
          ]),
          _: 1
        }),
        _createVNode(_component_BListItem, { class: "mt-300" }, {
          default: _withCtx(() => [
            _createVNode(_component_BBtn, {
              type: "primary",
              disabled: !isSaveEnabled.value,
              onClick: save
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.save.text')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })), [
      [_directive_loading, _ctx.$wait.is(['updateSenderEmail', 'updateReceiveMailAddressAsCc'])]
    ])
  ], 64))
}
}

})
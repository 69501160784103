<template>
  <div class="trigger-action-card-container">
    <BCard
      border
    >
      <div class="header">
        <div>
          {{ $t('trigger.action') }}
        </div>
        <div
          class="more-menu"
        >
          <BMoreMenu
            v-model="isMenuVisible"
            left
          >
            <template #default>
              <BBtn
                flat
                fit
                position="center"
                size="small"
                :disabled="!editable"
                @click="() => clickMenuButton('form')"
              >
                {{ $t('general.edit.text') }}
              </BBtn>
              <BBtn
                flat
                fit
                position="center"
                size="small"
                @click="() => clickMenuButton('confirm')"
              >
                {{ $t('trigger.actionSetting.detail') }}
              </BBtn>
            </template>
          </BMoreMenu>
        </div>
      </div>
      <div class="body">
        <BListItem class="list-item">
          <template #header>
            <span>{{ $t('trigger.actionSetting.actionType') }}</span>
          </template>
          <div class="mt-100">
            {{ actionDescription }}
          </div>
        </BListItem>
      </div>
      <div
        v-if="approveUser"
        class="footer"
      >
        <BUserTooltip
          :image-url="approveUser.avatarUrl"
          :name="approveUser.name"
          :user-id="approveUser.id"
          :size="30"
        />
        <div class="label ml-100">
          {{ $t('trigger.actionSetting.approveUser') }}
        </div>
        <div class="approve-user ml-100">
          {{ approveUser?.name || '' }}
        </div>
      </div>
    </BCard>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n'
import { TriggerActionBody } from '@/api/openapi';
import { useUsers } from '@/composable/user/user/users';
import { TTriggerActionFormMode } from './types';

type TProps = {
  triggerAction: TriggerActionBody | null;
  editable: boolean;
};
type TEmits = {
  'click:setting': [mode: TTriggerActionFormMode];
};
const props = defineProps<TProps>();
const emit = defineEmits<TEmits>();

const i18n = useI18n();
const isMenuVisible = ref<boolean>();

const clickMenuButton = (mode: TTriggerActionFormMode) => {
  isMenuVisible.value = false;
  emit('click:setting', mode);
};

const actionDescription = computed(() => {
  let description = '';
  switch (props.triggerAction?.actionType) {
    case 'sequence_register':
      description = i18n.t(`trigger.actionTypes.${props.triggerAction?.actionType}`);
      break;
  }
  return description;
});

const { users } = useUsers()
const approveUser = computed(() => {
  return users.value.find((user) => user.id === props.triggerAction?.approveUserId);
})
</script>

<style lang="scss" scoped>
.trigger-action-card-container {
  :deep(.card) {
    padding: 0;

    .card-body {
      .header {
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $charcoal;
        color: $textcolor-white;
        padding: 8px 20px;
        border-radius: 6px 6px 0 0;

        .more-menu {
          .b-icon {
            color: $textcolor-white;
          }
        }
      }

      .body {
        padding: 20px;
      }

      .footer {
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 12px 20px;
        border-top: 1px solid $bdcolor-light;

        .label {
          min-width: 42px;
          color: $textcolor-light;
        }

        .approve-user {

        }
      }
    }
  }
}
</style>

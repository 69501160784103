import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toHandlers as _toHandlers, mergeProps as _mergeProps, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "sequence-step-container" }
const _hoisted_2 = { class: "summary-container" }
const _hoisted_3 = { class: "total-step item" }
const _hoisted_4 = { class: "value" }
const _hoisted_5 = { class: "unit" }
const _hoisted_6 = { class: "total-day item" }
const _hoisted_7 = { class: "value" }
const _hoisted_8 = { class: "unit" }
const _hoisted_9 = { class: "automation-rate item" }
const _hoisted_10 = { class: "value" }
const _hoisted_11 = { class: "unit" }
const _hoisted_12 = { class: "steps-container" }
const _hoisted_13 = { class: "scrollable-container" }
const _hoisted_14 = { class: "scrollable-container--inner" }
const _hoisted_15 = { class: "b-connector" }
const _hoisted_16 = { class: "step-end" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import SequenceStepEnd from '@/components/organisms/user/general/sequence/SequenceStepEnd.vue'
import SequenceStepWithConnector from '@/components/organisms/user/general/sequence/SequenceStepWithConnector.vue';
import { useNextActionTypes } from '@/composable/user/nextActionType/next-action-types';
import { isAutomatic } from '@/composable/user/sequence/post-put-body';
import { useWithDaysSequenceSteps } from '@/composable/user/sequence/steps-computed-property';
import { TMenu } from '@/plugins/biscuet-materials/components/molecules/connector';
import { TActionWithDisabled, TFormSequenceStep, TStepEventType, TStepType } from './types';

type TProps = {
  isMenuOpens: boolean[];
  sequenceSteps?: TFormSequenceStep[];
  enabledToAddSteps: boolean;
  stepsActionsWithDisabled: TActionWithDisabled[][];
};

const MAX_NUMBER_OF_STEPS = 20;


export default /*@__PURE__*/_defineComponent({
  __name: 'EditableSequenceSteps',
  props: {
    isMenuOpens: {},
    sequenceSteps: { default: () => [] },
    enabledToAddSteps: { type: Boolean },
    stepsActionsWithDisabled: {}
  },
  emits: ["update", "update:isMenuOpens", "click:addFirstStepDirectMail", "click:addFirstStepNextAction", "click:insertNextAction", "click:insertDirectMail"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const i18n = useI18n();
const menus: TMenu<TStepType> = [
  { key: 'next_action', label: i18n.t('sequence.step.types.nextAction') },
  { key: 'direct_mail', label: `${i18n.t('sequence.step.types.directMail')} - ${i18n.t('mail.reserve')}` },
];

const { findNextActionType } = useNextActionTypes();

const totalSteps = computed(() => {
  return props.sequenceSteps.filter((step) => hasDateIntervalStep(step)).length;
});
const totalDays = computed(() => {
  const totalSequenceStepDays = props.sequenceSteps
    .filter((step) => hasDateIntervalStep(step))
    .reduce((sum, e) => sum + e.dateInterval, 0);
  // NOTE: 1日目から開始
  return totalSequenceStepDays ? totalSequenceStepDays + 1 : 0;
});
const internalIsMenuOpens = computed({
  get() {
    return props.isMenuOpens;
  },
  set(newValue) {
    emit('update:isMenuOpens', newValue);
  },
});

const automationRate = computed(() => {
  const aggregated = props.sequenceSteps.reduce((aggregated, e) => {
    if (e.status === 'skipped') return aggregated
    aggregated.total += 1
    if (isAutomatic(e)) aggregated.automatic += 1
    return aggregated
  }, { total: 0, automatic: 0 })
  return Math.floor((aggregated.automatic / aggregated.total || 0) * 100);
});

const { withDaysSequenceSteps } = useWithDaysSequenceSteps<TFormSequenceStep>(() => props.sequenceSteps);

const stepHandlers = computed(() =>
  props.stepsActionsWithDisabled.map((stepActions, index) => getStepHandlers(stepActions, index)),
);
/**
 * NOTE: 任意のイベントをhandle＆発火
 * @examples {'click:detail': () => emit('click:detail', 0), ...}
 */
const getStepHandlers = (stepActions: TActionWithDisabled[], index: number): Partial<Record<TStepEventType, () => void>> => {
  // stepカードのmenuで発火するイベント
  const stepCardEvents: TStepEventType[] = stepActions
    .filter(({ disabled }) => !disabled)
    .map(({ event }) => event);
  // connectorの追加ボタンで発火するイベント
  const stepAddEvents: TStepEventType[] = props.enabledToAddSteps ? ['click:insertNextAction', 'click:insertDirectMail'] : [];

  const events: TStepEventType[] = [
    ...stepCardEvents,
    ...stepAddEvents,
  ];
  return Object.fromEntries(
    events
      .map((stepEvent) => 
        [stepEvent, () => emit(stepEvent, index)],
      ),
  );
};

const disabledAddSequenceStep = computed(() => !props.enabledToAddSteps || props.sequenceSteps.length >= MAX_NUMBER_OF_STEPS);
const enabledAppending = computed(() => withDaysSequenceSteps.value.length <= 0 && props.enabledToAddSteps);

const handleAddFirstStepClick = (stepType: TStepType) => {
  if (!enabledAppending.value) return;

  stepType === 'direct_mail' ? emit('click:addFirstStepDirectMail') : emit('click:addFirstStepNextAction');
};

const hasDateIntervalStep = (step: TFormSequenceStep) => {
  return step.status !== 'skipped' && !step.immediately;
};

return (_ctx: any,_cache: any) => {
  const _component_BConnector = _resolveComponent("BConnector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(totalSteps.value), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('sequence.step.title')), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, _toDisplayString(totalDays.value), 1),
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('sequence.daysToComplete')), 1)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, _toDisplayString(automationRate.value + '%'), 1),
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('sequence.automationRate')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(withDaysSequenceSteps), (sequenceStep, index) => {
            return (_openBlock(), _createBlock(SequenceStepWithConnector, _mergeProps({
              key: sequenceStep.sortOrder,
              "is-menu-open": internalIsMenuOpens.value[index],
              "onUpdate:isMenuOpen": ($event: any) => ((internalIsMenuOpens.value[index]) = $event),
              "sequence-step": sequenceStep,
              "next-action-type": _unref(findNextActionType)(sequenceStep.actionType),
              actions: _ctx.stepsActionsWithDisabled[index],
              "needs-add-button": _ctx.enabledToAddSteps,
              "disabled-add-button": disabledAddSequenceStep.value
            }, _toHandlers(stepHandlers.value[index])), null, 16, ["is-menu-open", "onUpdate:isMenuOpen", "sequence-step", "next-action-type", "actions", "needs-add-button", "disabled-add-button"]))
          }), 128)),
          _createElementVNode("div", _hoisted_15, [
            (enabledAppending.value)
              ? (_openBlock(), _createBlock(_component_BConnector, {
                  key: 0,
                  size: "small",
                  "add-button-position": "top",
                  menus: menus,
                  "onClick:addButton": handleAddFirstStepClick
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createVNode(SequenceStepEnd)
        ])
      ])
    ])
  ]))
}
}

})
import Waiting from '@/store/waiting'

export default class UserActions {
  constructor (Api, types) {
    const actions = {
      async getUsersAction ({ commit }, args) {
        await waiting.check('getUsersAction', async () => {
          try {
            const res = await Api.getUsers(args)
            commit(types.SET_USERS, Object.values(res.data)[0])
          } catch (err) {
            commit(types.CLEAR_USERS)
            throw err
          }
        })
      },
      async getUserAction ({ commit }, args) {
        await waiting.check('getUserAction', async () => {
          try {
            const res = await Api.getUser(args)
            commit(types.SET_USER, Object.values(res.data)[0])
          } catch (err) {
            commit(types.CLEAR_USER)
            throw err
          }
        })
      },
      async createUserAction ({ commit }, args) {
        await waiting.check('createUserAction', async () => {
          await Api.createUser(args)
          try {
            const res = await Api.getUsers(args)
            commit(types.SET_USERS, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
      async updateUserAction ({ commit }, args) {
        await waiting.check('updateUserAction', async () => {
          await Api.updateUser(args)
          try {
            const res = await Api.getUsers(args)
            commit(types.SET_USERS, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import LeadHistoryCard from '@/components/organisms/user/lead/history/LeadHistoryCard.vue';
import { SequenceInstanceHistory } from '@/store/types';
import { formatShorterDateTime } from '@/utils/date-time';

type TProps = {
  sequenceInstanceHistory: SequenceInstanceHistory;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceInstanceCard',
  props: {
    sequenceInstanceHistory: {}
  },
  setup(__props: any) {

const props = __props;

const attributes = computed(() => ({
  text: title(),
  // TODO: user が必要？システムユーザーとして表示？
  // user: props.sequenceInstanceHistory.user,
  isDisplaySystemUser: true,
  iconComponent: 'BCustomIcon',
  customIconClass: 'b-sequence',
  iconType: 'hot',
  toggle: false,
}));

const historiedAt = computed<string>(() => formatShorterDateTime(props.sequenceInstanceHistory.historiedAt));
const title = () => {
  return `${props.sequenceInstanceHistory.actionTypeText} - ${props.sequenceInstanceHistory?.name}`
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(LeadHistoryCard, _normalizeProps(_guardReactiveProps(attributes.value)), {
    date: _withCtx(() => [
      _createElementVNode("div", null, _toDisplayString(historiedAt.value), 1)
    ]),
    _: 1
  }, 16))
}
}

})
<template>
  <div class="pt-600">
    <BContainer full>
      <BCard shadow>
        <BLayout
          class="mb-200"
          justify-space-between
          align-center
        >
          <h2>{{ $t(`adminManagement.title`) }}</h2>
          <BBtn
            type="primary"
            @click="openInviteDialog"
          >
            {{ $t(`general.invite`) }}
          </BBtn>
        </BLayout>
        <AdminsList />
      </BCard>
      <FormDialog
        :dialog="inviteDialog"
        width="500"
      >
        <template #form>
          <InviteForm
            ref="inviteForm"
            :loading="$wait.is('createAdminWait')"
            @save="inviteAdmin"
            @cancel="closeInviteDialog"
          />
        </template>
      </FormDialog>
    </BContainer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import AdminsList from '@/components/organisms/admin/AdminsList.vue'
import InviteForm from '@/components/organisms/admin/InviteForm.vue'
import errorHandler from '@/mixins/error_handler'

export default defineComponent({
  components: {
    InviteForm,
    AdminsList,
  },
  mixins: [errorHandler],
  data() {
    return {
      inviteDialog: false,
    }
  },
  computed: {
    ...mapGetters('admin', []),
  },
  created() {
    const setupClientManagementDetail = async () => {
      this.getAdminsAction()
      const breadcrumbs = [{ text: this.$t('clientManagement.title'), path: { name: 'AdminClients' } }]
      this.setBreadcrumbsAction(breadcrumbs)
    }
    setupClientManagementDetail()
  },
  methods: {
    ...mapWaitingActions('breadcrumbs', {
      setBreadcrumbsAction: 'setBreadcrumbsWait',
    }),
    ...mapWaitingActions('admin', {
      createAdminAction: 'createAdminWait',
      getAdminsAction: 'getAdminsWait',
    }),
    openInviteDialog() {
      this.inviteDialog = true
    },
    closeInviteDialog() {
      this.inviteDialog = false
      this.$refs.inviteForm.reset()
    },
    async inviteAdmin({ name, email }) {
      const admin = { name, email }
      await this.createAdminAction({
        body: { admin },
        errorHandlers: { 422: this.defaultHandler },
      })
      this.closeInviteDialog()
      this.$toast.show(this.$t('general.done.of', { action: this.$t('general.invite') }))
    },
  },
})
</script>

<style lang="scss" scoped>
.slug {
  color: $basecolor-primary;
  font-weight: 600;
  margin: 0 2px;
}
</style>

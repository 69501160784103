<template>
  <div>
    <div
      v-if="isDisplayOnly"
    >
      {{ nameByModelValue || $t('leadList.allTitle') }}
    </div>
    <BMenu
      v-else
      v-model="isOpen"
      width="670"
    >
      <template #activator>
        <div
          class="lead-view-select truncate-text"
          :class="{ 'full-width': fullWidth }"
        >
          <span v-if="useModel">
            {{ nameByModelValue || $t('leadList.allTitle') }}
          </span>
          <span
            v-else
            class="truncate-text"
          >{{
            leadView.id ? leadView.name : $t('leadList.allTitle')
          }}</span>
          <BElementIcon
            name="ArrowDown"
            type="gray"
            size="medium"
          />
        </div>
      </template>
      <template #default>
        <BCard full>
          <div class="search">
            <BIcon
              class="search-input-icon"
              hover
            >
              search
            </BIcon>
            <BInput
              v-model="searchText"
              :placeholder="$t('leadView.searchText')"
              flat
            />
          </div>
          <div
            class="lead-list"
            :style="listHeightStyle"
          >
            <div class="section mb-300 mt-500">
              <div class="section-body">
                <BCard
                  class="lead-view-card"
                  border
                  transition
                  @click="setLeadViewSettings"
                >
                  <BLayout>
                    <div class="lead-view-icon">
                      <img :src="require('@/assets/icon_lead_list.svg')">
                    </div>
                    <div class="lead-view-name">
                      <div class="fw-bold mb-50">
                        {{ $t('leadList.allTitle') }}
                      </div>
                      <div class="text-annotation">
                        {{ $t('leadList.allDescription') }}
                      </div>
                    </div>
                  </BLayout>
                </BCard>
              </div>
            </div>
            <div class="section">
              <div class="section-body">
                <div class="column-headers">
                  <BLayout>
                    <div class="lead-view-icon" />
                    <div class="lead-view-name">
                      <BBtn
                        text
                        size="small"
                      >
                        <span>リスト名</span>
                      </BBtn>
                    </div>
                    <BLayout
                      class="ml-a"
                      justify-end
                      align-center
                    >
                      <div class="lead-view-date">
                        {{ $t('leadView.updatedAt') }}
                      </div>
                      <div class="lead-view-user">
                        {{ $t('leadView.updatedUser') }}
                      </div>
                    </BLayout>
                  </BLayout>
                </div>
                <VWait for="getLeadViewsWait">
                  <template #waiting>
                    <BPlaceholder
                      v-loading="true"
                      height="20vh"
                      element-loading-background="transparent"
                    />
                  </template>
                  <div
                    v-if="leadViews.length > 0"
                    v-infinite-scroll="load"
                    class="column layout"
                    infinite-scroll-disabled="scrollDisabled"
                    infinite-scroll-distance="20"
                  >
                    <BCard
                      v-for="leadView in filterLeadViews"
                      :key="'leadView' + leadView.id"
                      class="lead-view-card mb-100"
                      border
                      transition
                      @click="() => setLeadViewSettings(leadView)"
                    >
                      <BLayout align-center>
                        <div class="lead-view-icon">
                          <img :src="require('@/assets/icon_lead_view.svg')">
                        </div>
                        <div class="lead-view-name">
                          <div class="fw-bold mb-50">
                            {{ leadView.name }}
                          </div>
                          <div class="text-annotation break-word-pre-wrap">
                            {{ leadView.description }}
                          </div>
                        </div>
                        <BLayout
                          class="ml-a"
                          justify-end
                          align-center
                        >
                          <div class="lead-view-date">
                            {{ getDateAndTimeWithFallBack(leadView.updatedAt) }}
                          </div>
                          <div class="lead-view-user">
                            <BUserTooltip
                              :image-url="leadView.updatedUserAvatarUrl"
                              :name="leadView.updatedUserName"
                              :user-id="leadView.updatedUserId"
                              :size="36"
                            />
                          </div>
                        </BLayout>
                      </BLayout>
                    </BCard>
                  </div>
                  <BLayout
                    v-if="scrollDisabled && $wait.is('infiniteLoadingWait')"
                    class="py-200"
                    justify-center
                  >
                    <BElementIcon name="loading" />
                  </BLayout>
                  <BEmptyBox
                    v-else-if="leadViews.length == 0"
                    class="px-600 py-600"
                    display-only
                  >
                    <div class="break-word-pre-wrap">
                      {{ $t('leadView.empty') }}
                    </div>
                  </BEmptyBox>
                </VWait>
              </div>
            </div>
          </div>
        </BCard>
      </template>
    </BMenu>
  </div>
</template>

<script>
import camelcaseKeys from 'camelcase-keys'
import lodash from 'lodash'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import queryParameter from '@/mixins/query_parameter'
import { altText } from '@/utils/alt'
import { formatShorterDateTime } from '@/utils/date-time'
import { isObject } from '@/utils/object'

export default {
  mixins: [queryParameter],
  props: {
    listHeight: {
      type: String,
      default: '80vh',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    useModel: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Number,
      default: null,
    },
    isDisplayOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['after-set-lead-view', 'update:modelValue'],
  data() {
    return {
      isOpen: false,
      scrollDisabled: false,
      displayCount: 20,
      increaseNumber: 20,
      searchText: '',
      leadViewSortKey: {
        key: 'name', // leadView.name
        order: 'asc',
      },
    }
  },
  computed: {
    ...mapGetters('user', ['leadViews', 'leadView']),
    nameByModelValue() {
      return this.leadViews.find((leadView) => leadView.id === this.modelValue)?.name
    },
    listHeightStyle() {
      return {
        '--list-height': this.listHeight,
      }
    },
    isLoading() {
      return this.$wait.is('getLeadViewsWait')
    },
    filterLeadViews() {
      return this.sortedLeadViews.filter((item) => {
        if (this.matchSearchText(item?.name)
            || this.matchSearchText(item?.description))
          return item
      }).slice(0, this.displayCount)
    },
    sortedLeadViews() {
      return lodash.orderBy(
        this.leadViews,
        (leadView) => {
          return leadView[this.leadViewSortKey.key]
        },
        this.leadViewSortKey.order,
      )
    },
  },
  watch: {
    isOpen(newVal) {
      if (newVal === false) {
        this.displayCount = 20
        this.searchText = ''
        this.scrollDisabled = false
      }
    },
  },
  created() {
    this.getLeadViewsAction()
  },
  unmounted() {
    this.displayCount = 20
    this.searchText = ''
    this.scrollDisabled = false
  },
  methods: {
    ...mapWaitingActions('user', {
      getLeadViewsAction: {
        action: 'getLeadViewsAction',
        loader: 'getLeadViewsWait',
      },
      clearLeadViewAction: 'clearLeadViewAction',
      setLeadViewAction: 'setLeadViewAction',
    }),
    ...mapWaitingActions('userUi', {
      setLeadListFilterParamsAction: 'setLeadListFilterParamsWait',
      clearLeadListFilterParamsAction: 'clearLeadListFilterParamsAction',
      setFilterControlPanelVisibleAction: 'setFilterControlPanelVisibleWait',
      setLeadListSelectedColumnsAction: 'setLeadListSelectedColumnsWait',
      setLeadListSortKeyAction: 'setLeadListSortKeyWait',
      setLeadListSortOrderAction: 'setLeadListSortOrderWait',
      clearLeadListSortKeyAction: 'clearLeadListSortKeyAction',
      clearLeadListSortOrderAction: 'clearLeadListSortOrderAction',
      resetLeadListSelectedColumnsAction: 'resetLeadListSelectedColumnsAction',
    }),
    setLeadViewSettings(leadView) {
      this.isOpen = false
      if (this.useModel) {
        this.$emit('update:modelValue', leadView.id)
        return
      }

      if (!isObject(leadView) || Object.keys(leadView).length === 0) {
        this.clearCondition()
        this.$emit('after-set-lead-view')
        return false
      }

      const columns = this.$JSON.parse(leadView.displayColumns)
      this.setLeadListSelectedColumnsAction(columns)
      this.setLeadListSortKeyAction(leadView.sortKey)
      this.setLeadListSortOrderAction(leadView.sortOrder)
      this.setFilterParams(leadView.filterParams)
      this.setLeadViewAction(leadView)
      this.setQueryParameter({ leadViewId: this.leadView.id })
      this.$emit('after-set-lead-view')
    },
    setFilterParams(filterParams) {
      filterParams = this.$JSON.parse(filterParams)

      if (Object.keys(filterParams).length === 0) {
        this.clearLeadListFilterParamsAction()
        this.setFilterControlPanelVisibleAction(true)
        return false
      }
      filterParams = camelcaseKeys(filterParams, {
        deep: true,
        exclude: [/^custom_field_.*[^items]/g],
      })
      if (Object.keys(filterParams).length > 0) {
        this.setLeadListFilterParamsAction(filterParams)
        this.setFilterControlPanelVisibleAction(true)
      }
    },
    clearCondition() {
      this.resetLeadListSelectedColumnsAction()
      this.clearLeadListFilterParamsAction()
      this.clearLeadListSortKeyAction()
      this.clearLeadListSortOrderAction()
      this.clearLeadViewAction()
    },
    matchSearchText(text) {
      if (this.searchText.length === 0) return true
      if (text.indexOf(this.searchText) !== -1) return true
    },
    getDateAndTimeWithFallBack(date) {
      return altText(formatShorterDateTime(date))
    },
    load() {
      if (this.leadViews.length <= this.displayCount) return
      this.$wait.start('infiniteLoadingWait')
      this.scrollDisabled = true
      setTimeout(() => {
        this.displayCount += this.increaseNumber
        this.$wait.end('infiniteLoadingWait')
        this.scrollDisabled = false
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.lead-view-card {
  padding: 28px !important;
  cursor: pointer;
}

.search {
  display: flex;
  border: 1px solid $bdcolor-base;
  padding-left: 10px;
  transition: all 0.3s ease;
  &:focus-within {
    border: 1px solid $bdcolor-active;
  }
  margin: 32px 32px 20px 32px;
  .search-input-icon {
    position: relative;
    cursor: pointer;
  }
}

.lead-list {
  max-height: var(--list-height);
  overflow: auto;
  padding: 0 $basespace-600 $basespace-600 $basespace-600;
  @include m-fixed-width(670px);
}

.lead-view-select {
  cursor: pointer;
  -webkit-appearance: none;
  background-color: white;
  background-image: none;
  border-radius: 4px;
  border: 1px solid $concrete;
  box-sizing: border-box;
  color: $charcoal;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 15px;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  @include m-fixed-width(400px);

  &.full-width {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}

.card-wrapper {
  display: block;
  height: 100%;
  padding: $basespace-100;
  text-decoration: none;
  color: $textcolor-base;
}

.column-headers {
  font-size: $fontsize-100;
  padding: $basespace-100 $basespace-600;
  color: $textcolor-light;
}

.lead-view {
  &-icon {
    @include m-fixed-width(70px);
  }

  &-name {
    @include m-fixed-width(260px);
  }

  &-date {
    @include m-fixed-width(140px);
  }

  &-user {
    @include m-fixed-width(40px);
  }
}
</style>

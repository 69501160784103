import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'

const store = {
  namespaced: true,
  state: {
    selectedFreeTagInfo: {
      freeTagId: null,
      freeTagText: null,
    },
    isUpdateCallList: false,
    selectedCallResult: {},
    selectedOpportunity: {},
    selectedNextAction: {},
    selectedSequenceInstanceId: null,
    zoomPhoneWidgetVisible: false,
    miitelWidgetVisible: false,
    targetCallResultFormVisible: false,
    targetOpportunityFormVisible: false,
    targetFreeActionFromVisible: false,
    targetSequenceInstanceFormVisible: false,
    targetSingleNextActionFormVisible: false,
    targetMailFormVisible: false,
    targetHeaderMoveButtonDisabled: false,
    leadListFilterParams: {},
    leadListPageParams: {
      currentPage: 1,
      currentDisplayNumber: 100,
    },
    isLeadOpenByLeadList: false,
    leadListSelectedColumns: [
      'companyName',
      'targetName',
      'lastActivityAt',
      'lastActivityTypeText',
      'lastActivityComment',
      'nextActions',
      'callTargetCreatedAt',
      'operatorName',
    ],
    leadListSortableColumns: [
      'callTargetCreatedAt',
      'lastCalledAt',
      'companyName',
      'companyUrl',
      'companyTel1',
      'companyAddress',
      'numberOfEmployees',
      'businessCategory',
      'targetName',
      'targetNameKana',
      'personalTel',
      'email',
      'department',
      'position',
      'operatorName',
      'lastActivityAt',
      'lastActivityTypeText',
      'lastActivityComment',
      'callResultsCount',
    ],
    leadListColumnWidths: {
      // MEMO: ja.jsonと同じ値にしているので、値を追加する場合は両方対応が必要
      // companyInfo
      companyName: 240,
      companyUrl: 160,
      companyTel1: 160,
      companyAddress: 160,
      numberOfEmployees: 160,
      businessCategory: 160,
      // callTargetInfo
      targetName: 160,
      targetNameKana: 160,
      personalTel: 160,
      email: 240,
      department: 160,
      position: 160,
      // historyInfo
      lastCalledAt: 240,
      nextActions: 240,
      callResultsCount: 120,
      lastOpportunity: 120,
      lastLeadStageStayDays: 240,
      // leadAdditionalInfo
      lastLeadStageName: 160,
      operatorName: 120,
      leadSources: 160,
      freeTags: 160,
      // lastActivityInfo
      lastActivityAt: 160,
      lastActivityTypeText: 160,
      lastActivityComment: 160,
      // systemAdditionalInfo
      callTargetCreatedAt: 160,
      // salesforceInfo
      connectStatus: 160,
      // duplicateInfo
      isEmailDuplicated: 160,
    },
    filterControlPanelVisible: false,
    selectedFilterDetailKeyName: null,
    leadListSortKey: 'callTargetCreatedAt',
    leadListSortOrder: 'desc',
    pendingCsvImportTaskIds: [],
    pendingCsvOverwriteTaskIds: [],
    pendingExcelImportTaskIds: [],
    pendingExcelOverwriteTaskIds: [],
    pendingSfImportTaskIds: [],
    doReloadLeadMergeStatus: false,
    currentCallTargetId: null,
    isDisabledBIconWithActionMenuClose: false,
  },
  mutations,
  getters,
  actions,
}

export default store

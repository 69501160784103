import ApiService, { ApiArguments } from '@/api/api_service';
import {
  TriggerActionFormApiGetTriggerActionFormsRequest,
  GetTriggerActionForms200Response,
  TriggerActionFormApi,
} from '@/api/openapi';

export class TriggerActionFormApiService extends ApiService {
  api = new TriggerActionFormApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true)
  }

  async getTriggerActionForms(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<TriggerActionFormApiGetTriggerActionFormsRequest>,
  ) {
    return this.checkResponse<GetTriggerActionForms200Response>(
      this.api.getTriggerActionForms(request, options),
      errorHandlers,
    );
  }
}

import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "desctiption" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "trigger-event-card-container" }
const _hoisted_6 = { class: "header" }
const _hoisted_7 = { class: "more-menu" }
const _hoisted_8 = { class: "body" }
const _hoisted_9 = { class: "mt-100" }

import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n'
import { TriggerEventBody } from '@/api/openapi';
import { TTriggerEventFormMode } from './types';

type TProps = {
  triggerEvent: TriggerEventBody | null;
  editable: boolean;
};
type TEmits = {
  'click:setting': [mode: TTriggerEventFormMode];
};

export default /*@__PURE__*/_defineComponent({
  __name: 'TriggerEventCard',
  props: {
    triggerEvent: {},
    editable: { type: Boolean }
  },
  emits: ["click:setting"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const i18n = useI18n();
const isMenuVisible = ref<boolean>();

const clickMenuButton = (mode: TTriggerEventFormMode) => {
  isMenuVisible.value = false;
  emit('click:setting', mode);
};

const actionDescription = computed(() => {
  let description = '';
  switch (props.triggerEvent?.eventType) {
    case 'sequence_mail_url_click':
      description = i18n.t(`trigger.eventTypes.${props.triggerEvent?.eventType}`);
      break;
  }
  return description;
});

return (_ctx: any,_cache: any) => {
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BCard = _resolveComponent("BCard")!
  const _component_BMoreMenu = _resolveComponent("BMoreMenu")!
  const _component_BListItem = _resolveComponent("BListItem")!

  return (_ctx.triggerEvent == null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_BCard, { border: "" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('trigger.eventSetting.title')), 1),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('trigger.eventSetting.description')), 1),
            _createElementVNode("div", null, [
              _createVNode(_component_BBtn, {
                class: "setting-btn",
                type: "primary",
                outline: "",
                onClick: _cache[0] || (_cache[0] = () => _ctx.$emit('click:setting', 'form'))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.setting.text')), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_BCard, { border: "" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", null, _toDisplayString(_ctx.$t('trigger.event')), 1),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_BMoreMenu, {
                    modelValue: isMenuVisible.value,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((isMenuVisible).value = $event)),
                    left: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BBtn, {
                        flat: "",
                        fit: "",
                        position: "center",
                        size: "small",
                        disabled: !_ctx.editable,
                        onClick: _cache[1] || (_cache[1] = () => clickMenuButton('form'))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.edit.text')), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled"]),
                      _createVNode(_component_BBtn, {
                        flat: "",
                        fit: "",
                        position: "center",
                        size: "small",
                        onClick: _cache[2] || (_cache[2] = () => clickMenuButton('confirm'))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('trigger.actionSetting.detail')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_BListItem, { class: "list-item" }, {
                  header: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('trigger.eventSetting.eventType')), 1)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(actionDescription.value), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          })
        ])
      ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "quick-texts" }
const _hoisted_2 = { class: "navbar" }
const _hoisted_3 = { class: "description" }
const _hoisted_4 = { class: "navbar-left" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "content mt-50" }

import { ref, onBeforeMount, computed, nextTick } from 'vue';
import { PaginationMeta, QuickText } from '@/api/openapi';
import { QuickTextApiService } from '@/api/user/resources/quick_text';
import { useAlert, useUnsavedConfirm } from '@/composable/message-dialog';
import { useWait } from '@/composable/vue-wait';
import { i18nGlobal as i18n } from '@/i18n';
import QuickTextFormDrawer from './quickTexts/QuickTextFormDrawer.vue';
import QuickTextList from './quickTexts/QuickTextList.vue';

// NOTE: 現状固定
const NUMBER_OF_QUICK_TEXT_LIMIT = 10;

export default /*@__PURE__*/_defineComponent({
  __name: 'QuickTexts',
  setup(__props) {

const { doActionWithWait } = useWait()
const { alert } = useAlert();
const { unsavedConfirm } = useUnsavedConfirm();
const api = new QuickTextApiService();

const quickTexts = ref<QuickText[]>([]);
const currentPage = ref(1);
const meta = ref<PaginationMeta>({
  totalPages: 1,
  totalCount: 0,
  limitValue: 0,
  currentPage: 0,
  isEmpty: false,
});
const isFormShow = ref<boolean>(false);
const selectedId = ref<number>(undefined);
const isFormValueChanged = ref<boolean>(false);

const canCreate = computed(() => {
  return meta.value.totalCount < NUMBER_OF_QUICK_TEXT_LIMIT;
});

onBeforeMount(() => {
  loadQuickTexts(currentPage.value);
});

const loadQuickTexts = async (currentPage: number) => {
  await doActionWithWait('getQuickTextsWait', async () => {
    const { data } = await api.getQuickTexts({ request: { currentPage } });
    quickTexts.value = data.quickTexts;
    meta.value = data.meta;
  });
};

// NOTE: 現状上限数10固定なのでページング不要。ページング機能を除いておく
// watch(() => currentPage.value, (newValue) => {
//   loadQuickTexts(newValue);
// });

const handleClickNew = async () => {
  if (!canCreate.value) {
    await alert({
      title: i18n.t('general.error'),
      message: i18n.t('quickText.exceededQuickTextLimit'),
    });
    
    return;
  }

  showForm();
};
const handleClickEdit = (id: number) => {
  showForm(id);
};
const showForm = async (id?: number) => {
  if (isFormShow.value && isFormValueChanged.value) {
    const ok = await unsavedConfirm();
    if (!ok) return;
  }
  isFormShow.value = false;
  await nextTick();

  selectedId.value = id;
  isFormShow.value = true;
};

const handleChangeQuickTextList = () => {
  loadQuickTexts(currentPage.value);
};
const handleIsChangedUpdate = (isChanged: boolean) => {
  isFormValueChanged.value = isChanged;
};

return (_ctx: any,_cache: any) => {
  const _component_BPopover = _resolveComponent("BPopover")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('quickText.description')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_BPopover, {
          disabled: canCreate.value,
          content: _ctx.$t('quickText.exceededQuickTextLimit')
        }, {
          reference: _withCtx(() => [
            _createElementVNode("button", {
              type: "button",
              class: "create-button",
              disabled: !canCreate.value,
              onClick: handleClickNew
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.create.to', { item: _ctx.$t('quickText.title') })), 1)
            ], 8, _hoisted_5)
          ]),
          _: 1
        }, 8, ["disabled", "content"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(QuickTextList, {
        "quick-texts": quickTexts.value,
        "onClick:row": handleClickEdit,
        onAfterDelete: handleChangeQuickTextList
      }, null, 8, ["quick-texts"])
    ]),
    _createVNode(QuickTextFormDrawer, {
      "is-show": isFormShow.value,
      "onUpdate:isShow": _cache[0] || (_cache[0] = ($event: any) => ((isFormShow).value = $event)),
      "quick-text-id": selectedId.value,
      onAfterSave: handleChangeQuickTextList,
      "onUpdate:isChanged": handleIsChangedUpdate
    }, null, 8, ["is-show", "quick-text-id"])
  ])), [
    [_directive_loading, _ctx.$wait.is(['getQuickTextsWait', 'deleteQuickTextWait'])]
  ])
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "pr-100 pl-50 more-menu" }
const _hoisted_4 = { class: "body-inner" }
const _hoisted_5 = { class: "content" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "body-inner" }

import { computed, ref } from 'vue';
import { UserForDisplay } from '@/api/openapi';
import { TActionWithDisabled, TStepCardEventType } from '@/components/organisms/user/general/sequence/types';

type TProps = {
  isDone: boolean;
  title: string;
  owner?: UserForDisplay;
  content: string;
  icon?: 'b-next-action' | 'b-mail';
  actions?: TActionWithDisabled[];
  isMenuOpen: boolean;
  expandableHtmlContent: string | null;
};

type TEmits = {
  (event: TStepCardEventType): void;
  (event: 'update:isMenuOpen', value: boolean): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BTodoCard',
  props: {
    isDone: { type: Boolean },
    title: {},
    owner: { default: undefined },
    content: {},
    icon: { default: 'b-next-action' },
    actions: { default: () => [] },
    isMenuOpen: { type: Boolean },
    expandableHtmlContent: {}
  },
  emits: ["click:edit", "click:moveUp", "click:moveDown", "click:delete", "click:skip", "click:sendImmediately", "click:startImmediately", "click:cancelSendImmediately", "click:cancelStartImmediately", "click:detail", "update:isMenuOpen"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const isHtmlContentVisible = ref(false);
const isAllContentVisible = ref(false);

const internalIsMenuOpen = computed({
  get() {
    return props.isMenuOpen;
  },
  set(newValue) {
    emit('update:isMenuOpen', newValue);
  },
});

const handleActionButtonClick = ({ event, disabled }: TActionWithDisabled) => {
  if (disabled) return;

  emit(event);
};

return (_ctx: any,_cache: any) => {
  const _component_BCustomIcon = _resolveComponent("BCustomIcon")!
  const _component_BUserTooltip = _resolveComponent("BUserTooltip")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BMoreMenu = _resolveComponent("BMoreMenu")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BCard = _resolveComponent("BCard")!

  return (_openBlock(), _createBlock(_component_BCard, {
    shadow: "",
    border: "",
    full: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BLayout, {
          "align-center": "",
          class: "row"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BCustomIcon, {
              class: _normalizeClass(["mr-100 custom-icon todo-icon", {
            'done': _ctx.isDone,
          }]),
              "icon-class": _ctx.icon
            }, null, 8, ["class", "icon-class"]),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
            _renderSlot(_ctx.$slots, "sub-content"),
            (_ctx.owner)
              ? (_openBlock(), _createBlock(_component_BUserTooltip, {
                  key: 0,
                  "image-url": _ctx.owner.avatarUrl,
                  name: _ctx.owner.name,
                  "user-id": _ctx.owner.id,
                  size: 30
                }, null, 8, ["image-url", "name", "user-id"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.actions.length)
                ? (_openBlock(), _createBlock(_component_BMoreMenu, {
                    key: 0,
                    modelValue: internalIsMenuOpen.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((internalIsMenuOpen).value = $event))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action) => {
                        return (_openBlock(), _createBlock(_component_BTooltip, {
                          key: action.event,
                          right: "",
                          disabled: !action.disabled || !action.disabledContent,
                          content: action.disabledContent
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BBtn, {
                              disabled: action.disabled,
                              flat: "",
                              fit: "",
                              size: "small",
                              onClick: () => handleActionButtonClick(action)
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(action.label), 1)
                              ]),
                              _: 2
                            }, 1032, ["disabled", "onClick"])
                          ]),
                          _: 2
                        }, 1032, ["disabled", "content"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 3
        })
      ]),
      (_ctx.expandableHtmlContent)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "body",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (isHtmlContentVisible.value = !isHtmlContentVisible.value))
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.content), 1),
              _createVNode(_component_BIcon, {
                class: _normalizeClass(["mr-110", { 'is-open': isHtmlContentVisible.value }])
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" arrow_drop_up ")
                ])),
                _: 1
              }, 8, ["class"])
            ]),
            _withDirectives(_createElementVNode("div", {
              class: "html-content",
              innerHTML: _ctx.expandableHtmlContent
            }, null, 8, _hoisted_6), [
              [_vShow, isHtmlContentVisible.value]
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "body",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (isAllContentVisible.value = !isAllContentVisible.value))
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", {
                class: _normalizeClass(["content expandable", { 'overview': !isAllContentVisible.value }])
              }, _toDisplayString(_ctx.content), 3),
              _createVNode(_component_BIcon, {
                class: _normalizeClass(["mr-110", { 'is-open': isAllContentVisible.value }])
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" arrow_drop_up ")
                ])),
                _: 1
              }, 8, ["class"])
            ])
          ]))
    ]),
    _: 3
  }))
}
}

})
<template>
  <div class="tab-wrapper">
    <div class="tab-title">
      {{ $t('general.displayNumber') }}
    </div>
    <div
      v-for="item in items"
      :key="item"
      class="tab"
      @click="() => selectItem(item)"
    >
      <BBtn
        v-if="isActive(item)"
        size="small"
        fab
        type="primary"
      >
        {{ item }}
      </BBtn>
      <BBtn
        v-else
        size="small"
        fab
        flat
      >
        {{ item }}
      </BBtn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    value: Number,
    title: String,
  },
  emits: [
    'click-item',
  ],
  data () {
    return {
      selectedItem: this.value,
    }
  },
  methods: {
    isActive (item) {
      return this.selectedItem === item
    },
    selectItem (item) {
      this.selectedItem = item
      this.$emit('click-item', item)
    },
  },
}
</script>

<style lang='scss' scoped>
  .tab-wrapper {
    display: flex;
    align-items: center;
  }

  .tab {
    transition: all .3s;
    border-radius: 4px;
    padding: 0;
    margin: 0 2px;
    cursor: pointer;
  }

  .tab-title {
    margin-right: 8px;
  }
</style>

import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "lead-stage-preview" }

import { LeadStage } from '@/api/openapi';

export type TDisplayLeadStage = Pick<LeadStage, 'name' | 'colorName'>;
type TProps = {
  leadStage?: TDisplayLeadStage | null;
  truncateText?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BLeadStagePreview',
  props: {
    leadStage: { default: null },
    truncateText: { type: Boolean, default: true }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_BLeadStageColorPreview = _resolveComponent("BLeadStageColorPreview")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!!_ctx.leadStage)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_BLeadStageColorPreview, {
            size: "small",
            value: _ctx.leadStage.colorName
          }, null, 8, ["value"]),
          _createElementVNode("span", {
            class: _normalizeClass(["lead-name", {'truncate-text': _ctx.truncateText}])
          }, _toDisplayString(_ctx.leadStage.name), 3)
        ], 64))
      : (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(["placeholder", {'truncate-text': _ctx.truncateText}])
        }, "-", 2))
  ]))
}
}

})
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, toHandlers as _toHandlers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "lead" }
const _hoisted_2 = { class: "target-header-area" }
const _hoisted_3 = { class: "target-detail-area" }
const _hoisted_4 = { class: "page-right" }
const _hoisted_5 = { class: "lead-history-area" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeadHeader = _resolveComponent("LeadHeader")!
  const _component_TargetDetail = _resolveComponent("TargetDetail")!
  const _component_LeadHistory = _resolveComponent("LeadHistory")!
  const _component_LeadMenu = _resolveComponent("LeadMenu")!
  const _component_MailForm = _resolveComponent("MailForm")!
  const _component_MailFormTransition = _resolveComponent("MailFormTransition")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_DuplicatedListModal = _resolveComponent("DuplicatedListModal")!
  const _component_SequenceApplyModal = _resolveComponent("SequenceApplyModal")!
  const _component_BModalBody = _resolveComponent("BModalBody")!
  const _component_BHeightAdjuster = _resolveComponent("BHeightAdjuster")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BHeightAdjuster, { class: "call-target" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_LeadHeader, _mergeProps(_ctx.callTargetHeaderProps, {
            onChangeTargetPage: _ctx.changeTargetPage,
            onChangeTargetCallTarget: _ctx.changeCallTarget,
            onCloseModal: _ctx.onCloseLeadModal,
            onClickMailButton: _cache[0] || (_cache[0] = ($event: any) => (_ctx.switchMailFormVisibility(true)))
          }), null, 16, ["onChangeTargetPage", "onChangeTargetCallTarget", "onCloseModal"])
        ]),
        _createVNode(_component_BModalBody, { full: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_BLayout, { class: "page-inner" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_TargetDetail, _mergeProps(_ctx.callTargetDetailProps, {
                    "active-users": _ctx.activeUsers,
                    operator: _ctx.operator
                  }, _toHandlers(_ctx.callTargetDetailFunctions), {
                    onClickDispSfCampaignButton: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dispAllSalesforceCampaign =true)),
                    onClickMailButton: _cache[2] || (_cache[2] = ($event: any) => (_ctx.switchMailFormVisibility(true))),
                    onClickEmailNotification: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$modal.show('duplicatedList'))),
                    "onClick:sequenceApply": _ctx.handleClickApplySequence
                  }), null, 16, ["active-users", "operator", "onClick:sequenceApply"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_LeadHistory, _mergeProps(_ctx.leadHistoryProps, {
                      onSelectDirectMail: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleSelectDirectMail($event))),
                      onReplyToReceivedMail: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleReplyToReceivedMail($event))),
                      onAfterDeleteDirectMail: _ctx.fetchDirectMailItems,
                      onDeleteLeadHistory: _ctx.getCallTargetsBackground,
                      onFetchSequenceItems: _ctx.fetchSequenceItems,
                      onFetchNextActionItems: _ctx.fetchNextActionItems
                    }), null, 16, ["onAfterDeleteDirectMail", "onDeleteLeadHistory", "onFetchSequenceItems", "onFetchNextActionItems"])
                  ]),
                  _createVNode(_component_LeadMenu, _mergeProps(_ctx.targetMenuProps, {
                    ref: "menu",
                    "call-target": _ctx.callTarget,
                    onFetchSequenceItems: _ctx.fetchSequenceItems
                  }), null, 16, ["call-target", "onFetchSequenceItems"]),
                  _createVNode(_component_MailFormTransition, {
                    "is-visible": _ctx.targetMailFormVisible,
                    style: {"height":"100%"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_MailForm, {
                        "call-target-id": _ctx.internalCallTargetId,
                        "selected-direct-mail-id": _ctx.selectedDirectMailId,
                        "reply-to-received-mail-id": _ctx.replyToReceivedMailId,
                        onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.switchMailFormVisibility(false))),
                        onReset: _ctx.handleResetMailForm,
                        onChange: _ctx.fetchDirectMailItems
                      }, null, 8, ["call-target-id", "selected-direct-mail-id", "reply-to-received-mail-id", "onReset", "onChange"])
                    ]),
                    _: 1
                  }, 8, ["is-visible"])
                ])
              ]),
              _: 1
            }),
            (_ctx.renderDuplicatedListModal)
              ? (_openBlock(), _createBlock(_component_DuplicatedListModal, {
                  key: 0,
                  onClose: _ctx.forceRerenderDuplicatedListModal,
                  onMerged: _ctx.onCloseLeadModal
                }, null, 8, ["onClose", "onMerged"]))
              : _createCommentVNode("", true),
            (_ctx.isAvailableSequenceFeature && _ctx.callTarget?.id)
              ? (_openBlock(), _createBlock(_component_SequenceApplyModal, {
                  key: 1,
                  "modal-name": "sequenceApplyModal",
                  "call-target-id": _ctx.callTarget.id,
                  onAfterApply: _ctx.fetchSequenceItems,
                  onClose: _ctx.handleCloseApplySequenceModal
                }, null, 8, ["call-target-id", "onAfterApply", "onClose"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ])), [
    [_directive_loading, _ctx.loading]
  ])
}
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mr-300" }
const _hoisted_2 = { class: "fs-500 fw-bold mr-100 ml-100" }

import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { SequenceInstanceApiService } from '@/api/user/resources/sequence_instance';
import { useWait } from '@/composable/vue-wait';
import { bitterAlert } from '@/plugins/BBitterAlert';
import { TSequenceAction } from '@/types';

type TProps = {
  selectedSequenceInstanceIds: number[];
};
type TEmit = (event: 'afterBulkAction') => void;


export default /*@__PURE__*/_defineComponent({
  __name: 'BulkActionsFooter',
  props: {
    selectedSequenceInstanceIds: {}
  },
  emits: ["afterBulkAction"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const i18n = useI18n();
const {
  wait,
  doActionWithWait,
} = useWait();
const api = new SequenceInstanceApiService();

const ACTIONS: { key: string, options: { id: TSequenceAction, name: string }[] }[] = [
  { key: 'section1', options: [
    { id: 'restart', name: i18n.t('sequence.instance.restart') },
    { id: 'stop', name: i18n.t('sequence.instance.stop') },
  ] },
  { key: 'section2', options: [
    { id: 'unregister', name: i18n.t('sequence.instance.unregister') },
  ] },
];

const selectedActionId = ref<TSequenceAction | null>(null);

const loading = computed<boolean>(() =>
  wait.is(['restartSequenceInstances', 'unregisterSequenceInstances', 'stopSequenceInstances']),
);
const bulkDisabled = computed<boolean>(() =>
  !selectedActionId.value || props.selectedSequenceInstanceIds.length === 0,
);

const handleDoBulkClick = async () => {
  const ok = await bitterAlert.show({
    title: i18n.t(`sequence.instance.${selectedActionId.value}`),
    text: i18n.t(`sequence.instance.bulkConfirmMessage.${selectedActionId.value}`),
  });
  if (!ok) return;

  if (selectedActionId.value === 'restart') {
    await restartSequenceInstances();
  } else if (selectedActionId.value === 'unregister') {
    await unregisterSequenceInstances();
  } else if (selectedActionId.value === 'stop') {
    await stopSequenceInstances();
  }

  emit('afterBulkAction');
};

const restartSequenceInstances = async () => {
  const { selectedSequenceInstanceIds: sequenceInstanceIds } = props;
  
  await doActionWithWait('restartSequenceInstances', async () => {
    await api.restartSequenceInstances({
      request: {
        bulkUpdateSequenceInstancesStatusBody: { sequenceInstanceIds },
      },
    });
  });
};
const unregisterSequenceInstances = async () => {
  const { selectedSequenceInstanceIds: sequenceInstanceIds } = props;
  
  await doActionWithWait('unregisterSequenceInstances', async () => {
    await api.unregisterSequenceInstances({
      request: {
        bulkUpdateSequenceInstancesStatusBody: { sequenceInstanceIds },
      },
    });
  });
};
const stopSequenceInstances = async () => {
  const { selectedSequenceInstanceIds: sequenceInstanceIds } = props;
  
  await doActionWithWait('stopSequenceInstances', async () => {
    await api.stopSequenceInstances({
      request: {
        bulkUpdateSequenceInstancesStatusBody: { sequenceInstanceIds },
      },
    });
  });
};

return (_ctx: any,_cache: any) => {
  const _component_BSelect = _resolveComponent("BSelect")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_ctx.selectedSequenceInstanceIds.length > 0)
    ? (_openBlock(), _createBlock(_component_BLayout, {
        key: 0,
        "align-center": "",
        "justify-center": "",
        class: "bulk-actions-footer"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(_ctx.$t('general.bulk.selected')), 1),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.selectedSequenceInstanceIds.length), 1),
            _createTextVNode(_toDisplayString(_ctx.$t('general.bulk.numberOf')), 1)
          ]),
          _createVNode(_component_BSelect, {
            modelValue: selectedActionId.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedActionId).value = $event)),
            class: "mr-400 bulk-action-select",
            items: ACTIONS,
            "item-text": "name",
            "item-value": "id",
            placeholder: _ctx.$t('general.select.please'),
            filterable: ""
          }, null, 8, ["modelValue", "placeholder"]),
          _createVNode(_component_BBtn, {
            type: "primary",
            loading: loading.value,
            disabled: bulkDisabled.value || loading.value,
            onClick: handleDoBulkClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('general.bulk.do')), 1)
            ]),
            _: 1
          }, 8, ["loading", "disabled"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})
import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "trigger-event-form-body-container" }
const _hoisted_2 = { class: "fs-300 fw-bold" }
const _hoisted_3 = { class: "radio-group" }
const _hoisted_4 = { class: "fs-300 fw-bold" }
const _hoisted_5 = {
  key: 0,
  class: "event-detail-container form"
}
const _hoisted_6 = { class: "footer" }

import camelcaseKeys from 'camelcase-keys'
import lodash from 'lodash'
import { ref, watch, nextTick, computed, toRaw, inject, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { TriggerEventBody, TriggerEventFromValidationType } from '@/api/openapi';
import FilterControlPanelBody from '@/components/organisms/user/leadList/filter/FilterControlPanelBody.vue';
import LeadViewSelectPanel from '@/components/organisms/user/LeadViewSelectPanel.vue'
import LeadFilterModal from '@/components/organisms/user/trigger/LeadFilterModal.vue'
import { useInputValidations } from '@/composable/input-validations';
import { useTriggerEvent, useTriggerEventForm } from '@/composable/user/trigger/trigger-event';
import { KEY_OF_MODAL } from '@/injection-keys';
import { key } from '@/store/index'
import SequenceMasterSimpleSelect from '../SequenceMasterSimpleSelect.vue'
import SequenceStepMasterSimpleSelect from '../SequenceStepMasterSimpleSelect.vue'

const LEAD_FILTER_MODAL_NAME = 'leadFilterModal';

type TEmits = {
  'click:cancel': [];
};

export default /*@__PURE__*/_defineComponent({
  __name: 'FormDrawer',
  props: {
    "modelValue": {},
    "modelModifiers": {},
    "isOpen": { type: Boolean, ...{ default: false } },
    "isOpenModifiers": {},
  },
  emits: /*@__PURE__*/_mergeModels(["click:cancel"], ["update:modelValue", "update:isOpen"]),
  setup(__props, { emit: __emit }) {

const emit = __emit;

const model = _useModel<TriggerEventBody | null>(__props, "modelValue");
const isOpen = _useModel<boolean>(__props, 'isOpen');

const modal = inject(KEY_OF_MODAL);
const store = useStore(key);

const { groupedEventTypes, makeInitialTriggerEvent } = useTriggerEvent();
const { forms, fetchForms, loading } = useTriggerEventForm();

const leadSelectTypes = ['leadList', 'leadCustom'];
const selectedLeadSelectType = ref<string>('leadList');
const internalTriggerEvent = ref<TriggerEventBody>();

const clearInternalValues = () => {
  internalTriggerEvent.value = makeInitialTriggerEvent(model.value);
  forms.value = [];
};
clearInternalValues();

const leadListFilterParams = computed(() => store.getters['userUi/leadListFilterParams']);

watch(() => isOpen.value, () => {
  nextTick(() => {
    clearInternalValues();
    storeLeadListFilterParams();
  });
});

watch(() => leadListFilterParams.value, value => {
  internalTriggerEvent.value.filterParams = value;
});

watch(() => internalTriggerEvent.value.eventType, value => {
  if (value) fetchForms(value);
});

watch(() => internalTriggerEvent.value.leadViewId, value => {
  if (value) selectedLeadSelectType.value = 'leadList';
});

watch(() => internalTriggerEvent.value.filterParams, value => {
  if (value && Object.keys(value).length > 0) selectedLeadSelectType.value = 'leadCustom';
});

const storeLeadListFilterParams = () => {
  const value = internalTriggerEvent.value.filterParams;
  if (value && Object.keys(value).length > 0) {
    store.dispatch('userUi/setLeadListFilterParamsAction',
      camelcaseKeys(value, {
        deep: true,
        exclude: [/^custom_field_.*[^items]/g],
      }),
    );
  }
};

onUnmounted(() => {
  store.dispatch('userUi/clearLeadListFilterParamsAction');
})

// NOTE: 本当は初期値falseにしたいが、レンダリングされていないフォームのバリデーションを拾わせないために、初期値trueにしている。
const isSequenceStepMasterSelectValid = ref(true);
const isSequenceMasterSelectValid = ref(true);
const isUrlInputValid = ref(true);
const isFormValid = computed(() => {
  if (!forms.value.length) return false;
  return isSequenceMasterSelectValid.value && isSequenceStepMasterSelectValid.value && isUrlInputValid.value;
});

const { requiredRule, urlRule, urlWithoutQueryRule } = useInputValidations();
const validationRules = (validationTypes: TriggerEventFromValidationType[]) => {
  return validationTypes.map(validationType => {
    switch (validationType) {
      case 'required':
        return requiredRule;
      case 'url_rule':
        return urlRule;
      case 'url_without_query_rule':
        return urlWithoutQueryRule;
      default:
        return null;
    }
  }).filter(rule => !!rule);
};

const handleClickLeadFilter = () => {
  modal.show(LEAD_FILTER_MODAL_NAME);
};

const handleCloseLeadFilter = () => {
  modal.hide(LEAD_FILTER_MODAL_NAME);
};

const handleCancelClick = () => {
  emit('click:cancel');
};

const handleSetClick = () => {
  switch (selectedLeadSelectType.value){
    case 'leadList':
      internalTriggerEvent.value.filterParams = {};
      break;
    case 'leadCustom':
      internalTriggerEvent.value.leadViewId = null;
      break;
  }
  model.value = lodash.cloneDeep(internalTriggerEvent.value);
};

return (_ctx: any,_cache: any) => {
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BRadio = _resolveComponent("BRadio")!
  const _component_ElDescriptionsItem = _resolveComponent("ElDescriptionsItem")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_ElDescriptions = _resolveComponent("ElDescriptions")!
  const _component_BSelect = _resolveComponent("BSelect")!
  const _component_BMultiInputBox = _resolveComponent("BMultiInputBox")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BDrawerMenu = _resolveComponent("BDrawerMenu")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BDrawerMenu, {
      modelValue: isOpen.value,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((isOpen).value = $event)),
      class: "trigger-event-form-container",
      width: "600px",
      title: _ctx.$t('trigger.eventSetting.title'),
      onCancel: handleCancelClick
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_BLayout, {
            "align-center": "",
            "justify-center": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BBtn, {
                class: "mr-300 btn",
                text: "",
                onClick: handleCancelClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.cancel.text')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_BBtn, {
                class: "ml-300 btn",
                type: "primary",
                outline: "",
                disabled: !isFormValid.value,
                onClick: handleSetClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.setting.text')), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_BListItem, { class: "pb-100" }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('trigger.eventSetting.leadSelect')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_BListItem, { class: "list-item" }, {
            default: _withCtx(() => [
              _createVNode(_component_ElDescriptions, {
                class: "margin-top",
                column: 1,
                border: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElDescriptionsItem, null, {
                    label: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('trigger.eventSetting.leadFilterSelect.title')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(leadSelectTypes, (leadSelectType) => {
                          return _createVNode(_component_BRadio, {
                            key: leadSelectType,
                            modelValue: selectedLeadSelectType.value,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedLeadSelectType).value = $event)),
                            label: leadSelectType,
                            class: "radio-item"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t(`trigger.eventSetting.leadFilterSelect.${leadSelectType}`)), 1)
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "label"])
                        }), 64))
                      ])
                    ]),
                    _: 1
                  }),
                  (selectedLeadSelectType.value === 'leadList')
                    ? (_openBlock(), _createBlock(_component_ElDescriptionsItem, { key: 0 }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('trigger.eventSetting.leadListSelect')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(LeadViewSelectPanel, {
                            modelValue: internalTriggerEvent.value.leadViewId,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((internalTriggerEvent.value.leadViewId) = $event)),
                            class: "lead-view-select-panel",
                            "full-width": "",
                            "list-height": "60vh",
                            "use-model": ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (selectedLeadSelectType.value === 'leadCustom')
                    ? (_openBlock(), _createBlock(_component_ElDescriptionsItem, { key: 1 }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", null, [
                            _createTextVNode(_toDisplayString(_ctx.$t('trigger.eventSetting.leadCustomSelect')) + " ", 1),
                            _createVNode(_component_BBtn, {
                              size: "mini",
                              class: "ml-200",
                              onClick: handleClickLeadFilter
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('trigger.eventSetting.leadFilterChange')), 1)
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(FilterControlPanelBody, {
                            "lead-list-filter-params": leadListFilterParams.value,
                            "is-display-only": true,
                            "filter-category-visible": false
                          }, null, 8, ["lead-list-filter-params"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_BListItem, { class: "pt-200 pb-200" }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('trigger.eventSetting.eventSelect')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_BListItem, { class: "list-item" }, {
            header: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('trigger.eventSetting.eventType')), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_BSelect, {
                modelValue: internalTriggerEvent.value.eventType,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((internalTriggerEvent.value.eventType) = $event)),
                items: _unref(groupedEventTypes),
                "item-text": "name",
                "item-value": "value",
                fit: "",
                required: ""
              }, null, 8, ["modelValue", "items"])
            ]),
            _: 1
          }),
          (!!internalTriggerEvent.value.eventType)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(forms), (form) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: form.eventDetailKey
                  }, [
                    (form.inputType === 'sequence_select')
                      ? (_openBlock(), _createBlock(_component_BListItem, {
                          key: 0,
                          class: "list-item"
                        }, {
                          header: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(form.label), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(SequenceMasterSimpleSelect, {
                              modelValue: (internalTriggerEvent.value.eventDetail[form.eventDetailKey] as number | null),
                              "onUpdate:modelValue": ($event: any) => (((internalTriggerEvent.value.eventDetail[form.eventDetailKey] as number | null)) = $event),
                              "is-valid": isSequenceMasterSelectValid.value,
                              "onUpdate:isValid": _cache[3] || (_cache[3] = ($event: any) => ((isSequenceMasterSelectValid).value = $event)),
                              required: form.validations.includes('required')
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "is-valid", "required"])
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true),
                    (form.inputType === 'mail_type_sequence_step_select')
                      ? (_openBlock(), _createBlock(_component_BListItem, {
                          key: 1,
                          class: "list-item"
                        }, {
                          header: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(form.label), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(SequenceStepMasterSimpleSelect, {
                              modelValue: (internalTriggerEvent.value.eventDetail[form.eventDetailKey] as number | null),
                              "onUpdate:modelValue": ($event: any) => (((internalTriggerEvent.value.eventDetail[form.eventDetailKey] as number | null)) = $event),
                              "is-valid": isSequenceStepMasterSelectValid.value,
                              "onUpdate:isValid": _cache[4] || (_cache[4] = ($event: any) => ((isSequenceStepMasterSelectValid).value = $event)),
                              "sequence-master-id": (internalTriggerEvent.value.eventDetail[form.eventDetailBelongsToKey] as number | null),
                              condition: { stepType: 'direct_mail' },
                              required: form.validations.includes('required')
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "is-valid", "sequence-master-id", "required"])
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true),
                    (form.inputType === 'multi_text_input')
                      ? (_openBlock(), _createBlock(_component_BListItem, {
                          key: 2,
                          class: "list-item"
                        }, {
                          header: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(form.label), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_BMultiInputBox, {
                              modelValue: internalTriggerEvent.value.eventDetail[form.eventDetailKey],
                              "onUpdate:modelValue": ($event: any) => ((internalTriggerEvent.value.eventDetail[form.eventDetailKey]) = $event),
                              valid: isUrlInputValid.value,
                              "onUpdate:valid": _cache[5] || (_cache[5] = ($event: any) => ((isUrlInputValid).value = $event)),
                              rules: validationRules(form.validations),
                              placeholder: _ctx.$t('general.placeholder.url'),
                              "min-input-size": 1,
                              "max-input-size": 3
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "valid", "rules", "placeholder"])
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              ])), [
                [_directive_loading, _unref(loading)]
              ])
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(LeadFilterModal, {
      "filter-params": leadListFilterParams.value,
      "modal-name": LEAD_FILTER_MODAL_NAME,
      onClose: handleCloseLeadFilter
    }, null, 8, ["filter-params"])
  ]))
}
}

})
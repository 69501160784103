<template>
  <LeadHistoryCard
    :text="actionTypeText"
    toggle
    :expand="expand"
    is-boxil-event
    icon="assignment"
    icon-type="boxil"
  >
    <template #date>
      <div>{{ historiedAt }}</div>
    </template>
    <BListItem>
      <template #header>
        <div>{{ $t('boxilLeadSurvey.history.problems') }}</div>
      </template>
      <span>{{ boxilLeadSurvey.problems }}</span>
    </BListItem>
    <BListItem class="mt-400">
      <template #header>
        <div>{{ $t('boxilLeadSurvey.history.introductionTime') }}</div>
      </template>
      <span>{{ boxilLeadSurvey.introductionTime }}</span>
    </BListItem>
    <BListItem class="mt-400">
      <template #header>
        <div>{{ $t('boxilLeadSurvey.history.tools') }}</div>
      </template>
      <span>{{ boxilLeadSurvey.tools }}</span>
    </BListItem>
  </LeadHistoryCard>
</template>

<script>
import LeadHistoryCard from '@/components/organisms/user/lead/history/LeadHistoryCard.vue'
import errorHandler from '@/mixins/error_handler'
import { formatShorterDateTime } from '@/utils/date-time'

export default {
  name: 'BoxilLeadSurveyCard',
  components: {
    LeadHistoryCard,
  },
  mixins: [errorHandler],
  props: {
    boxilLeadSurvey: {
      type: Object,
      required: true,
    },
    expand: Boolean,
  },
  computed: {
    historiedAt () {
      return formatShorterDateTime(this.boxilLeadSurvey.historiedAt)
    },
    actionTypeText () {
      return this.boxilLeadSurvey.actionTypeText
    },
  },
}
</script>

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "sidebar" }
const _hoisted_2 = { class: "sidebar-menu" }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BCustomIcon = _resolveComponent("BCustomIcon")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_BHeightAdjuster = _resolveComponent("BHeightAdjuster")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BHeightAdjuster, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item) => {
            return (_openBlock(), _createBlock(_component_RouterLink, {
              key: item.routeTo,
              class: "list-item",
              to: { name: item.routeTo },
              "data-test": item.dataAttr.test
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BTooltip, {
                  right: "",
                  content: item.label
                }, {
                  default: _withCtx(() => [
                    (_ctx.isMenuItemWithIcon(item))
                      ? (_openBlock(), _createBlock(_component_BIcon, {
                          key: 0,
                          class: "mr-400",
                          type: _ctx.setCurrentRouteIconClass(item.routeTo)
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", {
                              class: _normalizeClass(item.iconClass)
                            }, _toDisplayString(item.icon), 3)
                          ]),
                          _: 2
                        }, 1032, ["type"]))
                      : _createCommentVNode("", true),
                    (_ctx.isMenuItemWithCustomIcon(item))
                      ? (_openBlock(), _createBlock(_component_BCustomIcon, {
                          key: 1,
                          class: "mr-400",
                          "icon-class": item.customIcon,
                          type: _ctx.setCurrentRouteIconClass(item.routeTo)
                        }, null, 8, ["icon-class", "type"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["content"])
              ]),
              _: 2
            }, 1032, ["to", "data-test"]))
          }), 128)),
          _createElementVNode("a", {
            href: _ctx.helpPageUrl,
            target: "_blank",
            rel: "noopener noreferrer",
            class: "list-item"
          }, [
            _createVNode(_component_BTooltip, {
              right: "",
              content: _ctx.$t('helpPage.link')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BIcon, {
                  type: "default",
                  class: "mr-400"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode(" help_outline ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["content"])
          ], 8, _hoisted_3)
        ])
      ]),
      _: 1
    })
  ]))
}
<template>
  <BContent>
    <template #navbar>
      <div class="analytics-nav">
        <BCustomIcon
          icon-class="b-call-analytics"
          class="mt-50"
        />
        <LeadViewSelectPanel
          class="mt-100"
          @after-set-lead-view="changeLeadView"
        />
        <BCustomIconText
          hover
          text-size="mini"
          icon-size="large"
          icon-class="b-list"
          class="mt-100"
          @click="moveLeadList"
        >
          {{ $t('callAnalytics.moveLeadList') }}
        </BCustomIconText>
      </div>
    </template>
    <div class="analytics-card">
      <div class="analytics-header">
        <span class="mt-300">{{ $t('callAnalytics.dateRange') }}</span>
        <BListItem class="data-selector period-selector">
          <BDateRangeForm
            v-model="date"
            range
          />
        </BListItem>
        <NumLeadInList
          v-loading="loading"
          class="mt-300"
        />
      </div>
      <BTabs
        class="pl-100"
        :label-items="tabLabelItems"
      >
        <template #summary>
          <div class="ml-100 pb-600">
            <Graph
              v-loading="loading"
              data-test="reachCountParCallCount"
              class="summary-graph"
              :title="$t('callAnalytics.reachCountParCallCount')"
              :chart-data="reachCountParCallChartData.data"
              :options="reachCountParCallChartData.options"
            />
            <Graph
              v-loading="loading"
              data-test="opportunityCountParReachCount"
              class="summary-graph"
              :title="$t('callAnalytics.opportunityCountParReachCount')"
              :chart-data="opportunityCountParReachCountChartData.data"
              :options="opportunityCountParReachCountChartData.options"
            />
            <Graph
              v-loading="loading"
              data-test="opportunityCountParCallCount"
              class="summary-graph"
              :title="$t('callAnalytics.opportunityCountParCallCount')"
              :chart-data="opportunityCountParCallCountChartData.data"
              :options="opportunityCountParCallCountChartData.options"
            />
            <CallResultTable
              v-loading="loading"
              data-test="callResultTable"
              class="summary-table"
              :table-data="callResultTableData"
            />
          </div>
        </template>
        <template #member>
          <div class="pt-600 pb-600">
            <MemberTable
              v-loading="loading"
              class="summary-content"
              :table-data="memberTableData"
            />
          </div>
        </template>
      </BTabs>
    </div>
  </BContent>
</template>

<script lang="ts">
import swal from 'sweetalert'
import { defineComponent } from 'vue'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import CallResultTable from '@/components/organisms/user/callAnalytics/CallResultTable.vue'
import Graph from '@/components/organisms/user/callAnalytics/Graph.vue'
import MemberTable from '@/components/organisms/user/callAnalytics/MemberTable.vue'
import LeadViewSelectPanel from '@/components/organisms/user/LeadViewSelectPanel.vue'
import Intercom from '@/mixins/intercom'
import queryParameter from '@/mixins/query_parameter'
import { add, format, newDate } from '@/utils/date-time'

export default defineComponent({
  name: 'CallAnalytics',
  components: {
    Graph,
    MemberTable,
    CallResultTable,
    LeadViewSelectPanel,
  },
  mixins: [queryParameter, Intercom],
  setup(props) {
    return {
      ...Intercom.setup(props),
    }
  },
  data() {
    return {
      date: [add(newDate(), -14, 'days'), newDate()],
      reachCountParCallChartData: {
        data: {},
        options: {},
      },
      opportunityCountParReachCountChartData: {
        data: {},
        options: {},
      },
      opportunityCountParCallCountChartData: {
        data: {},
        options: {},
      },
      selectedLeadViewId: null,
    }
  },
  computed: {
    ...mapGetters('user', ['leadView', 'callAnalytics', 'allUserCallAnalytics', 'callResultCallAnalytics']),
    tabLabelItems() {
      return [
        { label: this.$t('callAnalytics.summary'), slotName: 'summary' },
        { label: this.$t('callAnalytics.member'), slotName: 'member' },
      ]
    },
    memberTableData() {
      if (this.callAnalytics?.length === 0) return []
      return this.callAnalytics.map((user) => {
        return {
          name: user.name,
          operatingTime: user.operatingTime,
          callTime: user.callTime,
          callCount: user.callCount,
          reachCount: user.reachCount,
          opportunityCount: user.opportunityCount,
          totalOpportunityAmout: user.totalOpportunityAmout,
          opportunityCountParReachCount: user.opportunityCountParReachCount,
          opportunityCountParCallCount: user.opportunityCountParCallCount,
          reachCountParCallCount: user.reachCountParCallCount,
          operatingTimeParCall: user.operatingTimeParCall,
          callTimeParCall: user.callTimeParCall,
          callCountParHour: user.callCountParHour,
          callTimeParReach: user.callTimeParReach,
        }
      })
    },
    callResultTableData() {
      if (this.callResultCallAnalytics?.length === 0) return []
      return this.callResultCallAnalytics.map((callResult) => {
        return {
          name: callResult.name,
          count: callResult.count,
          defaultCallResultTypeId: callResult.defaultCallResultTypeId,
          callResultTypeId: callResult.callResultTypeId,
        }
      })
    },
    parameters() {
      return {
        reachCountParCallCount: {
          label: this.$t('callAnalytics.reachCountParCallCount'),
          key: 'reachCountParCallCount',
        },
        opportunityCountParReachCount: {
          label: this.$t('callAnalytics.opportunityCountParReachCount'),
          key: 'opportunityCountParReachCount',
        },
        opportunityCountParCallCount: {
          label: this.$t('callAnalytics.opportunityCountParCallCount'),
          key: 'opportunityCountParCallCount',
        },
        opportunityCount: {
          label: this.$t('callAnalytics.opportunityCount'),
          key: 'opportunityCount',
          color: 'rgba(86, 180, 189, 1)',
        },
        reachCount: {
          label: this.$t('callAnalytics.reachCount'),
          key: 'reachCount',
          color: 'rgba(134, 231, 229, 1)',
        },
        callCount: {
          label: this.$t('callAnalytics.callCount'),
          key: 'callCount',
          color: `rgba(1, 132, 132, 1)`,
        },
      }
    },
    loading() {
      return this.$wait.is('getCallAnalyticsWait')
    },
  },
  watch: {
    date() {
      this.setupAnalytics()
    },
    selectedLeadViewId() {
      this.setupAnalytics()
    },
  },
  async created() {
    const params = this.getCallAnalyticsQueryParameter()
    if (params) {
      this.date = [params.startAt, params.endAt]
    }
    const breadcrumbs = [{ text: this.$t('callAnalytics.title'), path: '/app/call-analytics' }]
    this.setBreadcrumbsAction(breadcrumbs)
    if (this.leadView?.id) {
      this.selectedLeadViewId = this.leadView.id
    } else {
      await this.setupAnalytics()
    }
  },
  methods: {
    ...mapWaitingActions('user', {
      getCallAnalyticsAction: 'getCallAnalyticsWait',
    }),
    ...mapWaitingActions('breadcrumbs', {
      setBreadcrumbsAction: 'setBreadcrumbsWait',
    }),
    moveLeadList() {
      this.$router.push({
        name: 'LeadList',
        query: { leadViewId: this.leadView.id, notClearFilter: true },
      })
    },
    changeLeadView() {
      this.selectedLeadViewId = this.leadView.id
    },
    async setupAnalytics() {
      this.setQueryParameter({
        callAnalytics: {
          startAt: format(this.date[0], 'YYYY-MM-DD'),
          endAt: format(this.date[1], 'YYYY-MM-DD'),
        },
      })
      await this.getCallAnalyticsAction({
        params: {
          start: format(this.date[0], 'YYYY-MM-DD'),
          end: format(this.date[1], 'YYYY-MM-DD'),
          leadViewId: this.selectedLeadViewId,
        },
        errorHandlers: {
          400: this.invalidRangeErrorHandler,
        },
      })
      this.reachCountParCallChartData = this.buildChartData({
        percentage: this.parameters.reachCountParCallCount,
        numerator: this.parameters.reachCount,
        denominator: this.parameters.callCount,
        maxValue: Math.max(this.allUserCallAnalytics?.graphMaxValue?.maxCallCount, this.allUserCallAnalytics?.graphMaxValue?.maxReachCount) || 0,
      })
      this.opportunityCountParReachCountChartData = this.buildChartData({
        percentage: this.parameters.opportunityCountParReachCount,
        numerator: this.parameters.opportunityCount,
        denominator: this.parameters.reachCount,
        maxValue: Math.max(this.allUserCallAnalytics?.graphMaxValue?.maxOpportunityCount, this.allUserCallAnalytics?.graphMaxValue?.maxReachCount) || 0,
      })
      this.opportunityCountParCallCountChartData = this.buildChartData({
        percentage: this.parameters.opportunityCountParCallCount,
        numerator: this.parameters.opportunityCount,
        denominator: this.parameters.callCount,
        maxValue: Math.max(this.allUserCallAnalytics?.graphMaxValue?.maxCallCount, this.allUserCallAnalytics?.graphMaxValue?.maxOpportunityCount) || 0,
      })
    },
    invalidRangeErrorHandler(e) {
      swal({
        title: this.$t('general.error'),
        text: this.$t('callAnalytics.invalidRange'),
      })
    },
    buildChartData({ percentage, numerator, denominator, maxValue }) {
      return {
        data: {
          labels: this.allUserCallAnalytics.graph.map((x) => x.date),
          datasets: [
            {
              label: percentage.label,
              data: this.allUserCallAnalytics.graph.map((x) => x[percentage.key]),
              backgroundColor: 'rgba(44,191,183, 1)',
              borderColor: 'rgba(44,191,183, 0.5)',
              fill: false,
              type: 'line',
              lineTension: 0.3,
              yAxisID: 'y2',
            },
            {
              label: denominator.label,
              data: this.allUserCallAnalytics.graph.map((x) => x[denominator.key]),
              backgroundColor: denominator.color || 'rgb(166,223,222)',
              yAxisID: 'y1',
            },
            {
              label: numerator.label,
              data: this.allUserCallAnalytics.graph.map((x) => x[numerator.key]),
              backgroundColor: numerator.color || 'rgb(204,178,255)',
              yAxisID: 'y1',
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              labels: {
                generateLabels: this.generateLabels(),
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y1: {
              type: 'linear',
              position: 'left',
              beginAtZero: true,
              min: 0,
              max: maxValue * 1.5 || 1,
              ticks: {
                callback: (label, index, labels) => {
                  if (Math.floor(label) === label) {
                    return `${label}件`
                  }
                },
              },
            },
            y2: {
              type: 'linear',
              position: 'right',
              beginAtZero: true,
              suggestedMin: 0,
              suggestedMax: 100,
              ticks: {
                callback (value, index, values) {
                  return `${value}%`
                },
              },
              grid: {
                drawOnChartArea: false,
              },
            },
          },
        },
      }
    },
    generateLabels() {
      return (chart) => {
        return chart.data.datasets.map((dataset, i) => {
          return {
            text: dataset.label,
            fillStyle: dataset.backgroundColor,
            hidden: !chart.isDatasetVisible(i),
            lineCap: dataset.borderCapStyle,
            lineDash: [],
            lineDashOffset: 0,
            lineJoin: dataset.borderJoinStyle,
            lineWidth: 0,
            strokeStyle: dataset.borderColor,
            pointStyle: dataset.pointStyle,
            datasetIndex: i,
          };
        })
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.content-wrapper {
  :deep(.b-height-adjuster) {
    .navbar {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      height: 63px;
      min-height: 63px;
      max-height: 63px;
      display: flex;
      align-items: center;
      padding: 9px 32px 11px;
      border-bottom: 1px solid $bdcolor-light;
    }
  }
}

.analytics-nav {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 21px 397px 72px;
  column-gap: 15px;
}

.analytics-card {
  width: 100%;
}

.data-selector {
  display: inline-block;
  width: auto !important; // b-list-itemコンポーネントの `width:100%;` が適用されてしまうため
}

.period-selector {
  margin-right: 8px;
  margin-left: 8px;
}

.summary-graph {
  @include m-fixed-width(calc(100% - 10px));
  box-shadow: $boxshadow-base;
  padding: 20px;
  padding-bottom: 10px;
}

.summary-table {
  @include m-fixed-width(370px);
  box-shadow: $boxshadow-base;
  padding: 20px;
}

$analytics-header-height: 79px;
.analytics-header {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 30px 380px 150px;
  padding-left: 30px;
  height: $analytics-header-height;
  position: sticky;
  top: 0px;
  background-color: white;
  width: 100%;
  z-index: 3;
  padding-top: 5px;
}
:deep(.el-tabs--top) {
  .el-tabs__header {
    .el-tabs__nav-scroll {
      padding: 0 20px;
    }
    &.is-top {
      position: sticky;
      top: $analytics-header-height;
      height: 50px;
      width: 100%;
      background-color: white;
      z-index: 3;
    }
  }
  .el-tabs__item {
    // NOTE: 既存レイアウトを踏襲するため、余白詰め気味にする
    padding: 0 10px;
  }
  .el-tabs__active-bar {
    // NOTE: 既存レイアウトを踏襲するため、active時の下線の色を消す
    background-color: var(--el-border-color-light);
  }
}
</style>

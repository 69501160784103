import Waiting from '@/store/waiting'
import SfBaseActions from './sf_base'

export default class SfTaskActions extends SfBaseActions {
  constructor(Api) {
    super()
    const actions = {
      createNextActionSfTaskAction: this.SalesForceBaseAction(({ commit }, args) => {
        return waiting.check('createNextActionSfTaskAction', async () => {
          try {
            args.body.type = 'next_action'
            const result = await Api.createSfTask({ ...args })
            return result
          } catch (err) {
            throw err
          }
        })
      }),
      createCallResultSfTaskAction: this.SalesForceBaseAction(({ commit }, args) => {
        return waiting.check('createCallResultSfTaskAction', async () => {
          try {
            args.body.type = 'call_result'
            const result = await Api.createSfTask({ ...args })
            return result
          } catch (err) {
            throw err
          }
        })
      }),
      createFreeActionSfTaskAction: this.SalesForceBaseAction(({ commit }, args) => {
        return waiting.check('createFreeActionSfTaskAction', async () => {
          try {
            args.body.type = 'free_action'
            const result = await Api.createSfTask({ ...args })
            return result
          } catch (err) {
            throw err
          }
        })
      }),
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

<template>
  <LeadHistoryCard v-bind="attributes">
    <template #date>
      <div>{{ historiedAt }}</div>
    </template>
    <template #action>
      <div>
        <BLayout align-center>
          <BBtn
            v-if="isAvailableSalesforceApiFeature"
            class="mt-2 mr-2"
            size="small"
            fab
            flat
          >
            <BSfStatusIcon
              :connect-status="sfConnectedStatus"
              @open-sf-modal="$modal.show(sfOpportunityName);"
            />
          </BBtn>
          <SfStatusModal
            v-if="isAvailableSalesforceApiFeature"
            :modal-name="sfOpportunityName"
            :sf-related-link-info="opportunity.sfRelatedLinkInfo"
            :sf-exec-sync-btn="false"
          />
          <BBtn
            size="small"
            fab
            flat
            data-test="edit"
            @click.stop="$emit('click-edit', opportunity)"
          >
            <BIcon size="small">
              edit
            </BIcon>
          </BBtn>
          <BTooltip
            top
            :content="$t('opportunity.cannotDeleteCauseAssociatedSequence')"
            :disabled="!isUndeletable"
          >
            <span> <!-- これがないと、ボタンが非活性の時にツールチップが出ない -->
              <BBtn
                size="small"
                fab
                flat
                data-test="delete"
                :disabled="isUndeletable"
                @click.stop="$emit('click-delete', opportunity.id)"
              >
                <BIcon size="small">
                  delete_forever
                </BIcon>
              </BBtn>
            </span>
          </BTooltip>
        </BLayout>
      </div>
    </template>
    <BLayout align-center>
      <div class="started-at pr-600">
        <BListItem>
          <template #header>
            <span>{{ $t('opportunity.startedAt') }}</span>
          </template>
          <div>{{ startedAt }}</div>
        </BListItem>
      </div>
      <div class="pr-600">
        <BListItem>
          <template #header>
            <span>{{ $t('opportunity.amount') }}</span>
          </template>
          <div>{{ amount }}</div>
        </BListItem>
      </div>
      <div v-if="isAvailableSequenceFeature">
        <BListItem>
          <template #header>
            <span>{{ $t('opportunity.sequence') }}</span>
          </template>
          <div>{{ opportunity.sequenceInstanceName || '-' }}</div>
        </BListItem>
      </div>
    </BLayout>
    <div
      v-if="opportunity.memo"
      class="memo pt-400"
    >
      <BListItem>
        <div class="break-word-pre-wrap">
          {{ opportunity.memo }}
        </div>
      </BListItem>
    </div>
    <BLayout
      v-if="opportunity.memo"
      justify-end
      class="mt-50"
    >
      <BCopyButton
        :text="opportunity.memo"
        :title="$t('opportunity.memo')"
        is-detailed-name
      />
    </BLayout>
  </LeadHistoryCard>
</template>

<script>
import SfStatusModal from '@/components/organisms/user/general/modal/salesforce/SfStatusModal.vue';
import LeadHistoryCard from '@/components/organisms/user/lead/history/LeadHistoryCard.vue'
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import BSfStatusIcon from '@/plugins/biscuet-materials/components/molecules/BSfStatusIcon.vue';
import { formatShorterDateTime } from '@/utils/date-time';

export default {
  components: {
    LeadHistoryCard,
    SfStatusModal,
    BSfStatusIcon,
  },
  props: {
    callTarget: Object,
    opportunity: Object,
    expand: Boolean,
  },
  emits: [
    'click-edit',
    'click-delete',
  ],
  setup() {
    const { isAvailableSalesforceApiFeature, isAvailableSequenceFeature } = useAvailableFeatureCheck()
    return {
      isAvailableSalesforceApiFeature,
      isAvailableSequenceFeature,
    }
  },
  data: () => {
    return {
      isLoadingSf: false,
      isOpen: false,
    }
  },
  computed: {
    attributes() {
      return {
        'text': this.$t('opportunity.title'), 'user': this.opportunity.createdUser,
        'icon-type': 'emphasis',
        'toggle': true,
        'expand': this.expand,
        'icon-component': 'bCustomIcon',
        'custom-icon-class': 'b-fire',
        'icon-size': 'large',
      }
    },
    amount() {
      if (this.opportunity.amount) {
        return `${this.opportunity.amount.toLocaleString()}${this.$t('general.currency')}`;
      } else {
        return '-'
      }
    },
    historiedAt() {
      return formatShorterDateTime(this.opportunity.historiedAt)
    },
    startedAt() {
      return formatShorterDateTime(this.opportunity.startedAt)
    },
    sfOpportunityName() {
      return `sfOpportunityModal${this.opportunity.id}`
    },
    sfConnectedStatus() {
      return this.opportunity.sfRelatedLinkInfo?.sfRelatedLinks[0]?.status || ''
    },
    isUndeletable() {
      return !!this.opportunity.sequenceInstanceId
    },
  },
}
</script>

<style lang="scss" scoped>
  .opportunity-card {
    padding: $basespace-500 16px $basespace-500 80px;
    white-space: pre-wrap;
    word-wrap: break-word;

    .header {
      color: $textcolor-light;
      font-size: $fontsize-100;
    }

    .content {
      font-size: $fontsize-200;
    }
  }

  .user-content {
    display: flex;
  }
</style>

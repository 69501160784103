import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import colors from './lead_stage_colors';

type TProps = {
  modelValue: string | null;
  clearable?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BLeadStageColorSelect',
  props: {
    modelValue: {},
    clearable: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const value = computed<string>({
  get() {
    return props.modelValue;
  },
  set(v) {
    emit('update:modelValue', v);
  },
})

return (_ctx: any,_cache: any) => {
  const _component_BColorSelect = _resolveComponent("BColorSelect")!

  return (_openBlock(), _createBlock(_component_BColorSelect, {
    modelValue: value.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
    colors: _unref(colors),
    clearable: _ctx.clearable
  }, null, 8, ["modelValue", "colors", "clearable"]))
}
}

})
import { App, readonly, ref } from 'vue';
import { KEY_OF_MODAL } from '@/injection-keys';
import BModal from './BModal.vue';

export type TModalAction = {
  modalName: string;
  action: 'show' | 'hide';
};
const modalAction = ref<TModalAction>();

const doModalAction = (modalName: string, action: TModalAction['action']) => {
  modalAction.value = {
    modalName,
    action,
  };
};

const modal = {
  show(modalName: string) {
    doModalAction(modalName, 'show');
  },
  hide(modalName: string) {
    doModalAction(modalName, 'hide');
  },
};

export type TModal = typeof modal;

export default {
  install(app: App) {
    app.provide('modalAction', readonly(modalAction));
    app.component('BModal', BModal);

    // Optional API用（前方互換）
    /**
     * @duplicated inject(KEY_OF_MODAL)を使用してください
     */
    app.config.globalProperties.$modal = modal;
    // Composition API用
    app.provide(KEY_OF_MODAL, app.config.globalProperties.$modal);
  },
}

import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'

const store = {
  namespaced: true,
  state: {
    items: [],
  },
  mutations,
  actions,
  getters,
}

export default store

import ApiBase from '@/api/base'

export default {
  getFreeTags (args) {
    return ApiBase.get(`/user/free_tags`)
  },
  createFreeTag (args) {
    return ApiBase.post(`/user/free_tags`, args)
  },
  updateFreeTag (args) {
    const { id } = args
    return ApiBase.put(`/user/free_tags/${id}`, args)
  },
  deleteFreeTag (args) {
    const { id } = args
    return ApiBase.delete(`/user/free_tags/${id}`, args)
  },
}

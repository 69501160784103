import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { SequenceBody, SequenceMaster } from '@/api/openapi';
import { isSequenceChanged } from '@/composable/user/sequence/changed';
import EditableSequence from '../general/sequence/EditableSequence.vue';

type TProps = {
  sequenceMaster: SequenceMaster;
  waitFor: string;
};
type TEmit = {
  (e: 'update', sequenceBody: SequenceBody): void;
  (e: 'update:changed', changed: boolean): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'Sequence',
  props: {
    sequenceMaster: {},
    waitFor: {}
  },
  emits: ["update", "update:changed"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const sequenceBody = computed<SequenceBody>(() => ({
  name: props.sequenceMaster?.name ?? '',
  ownerId: props.sequenceMaster?.owner?.id ?? null,
}));

const handleUpdate = async (updatedValue: SequenceBody) => {
  emit('update:changed', await isSequenceChanged(sequenceBody.value, updatedValue));
  emit('update', updatedValue);
};

return (_ctx: any,_cache: any) => {
  const _component_VWait = _resolveComponent("VWait")!

  return (_openBlock(), _createBlock(_component_VWait, { for: _ctx.waitFor }, {
    default: _withCtx(() => [
      _createVNode(EditableSequence, {
        "sequence-body": sequenceBody.value,
        "owner-name": _ctx.sequenceMaster?.owner?.name,
        onUpdate: handleUpdate
      }, null, 8, ["sequence-body", "owner-name"])
    ]),
    _: 1
  }, 8, ["for"]))
}
}

})
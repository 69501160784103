import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]

import { Plus } from '@element-plus/icons-vue'
import type { UploadProps } from 'element-plus'
import { computed, ref } from 'vue'
import { convertBase64ToBlob, convertBlobToUrl, convertFileToBlob, cropImageToSquare, loadImage, resizeImage } from '@/utils/image';

const FILE_MAX_LENGTH = 320;

type TProps = {
  modelValue?: Blob | null;
  imageUrl: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BAvatarUpload',
  props: {
    modelValue: { default: null },
    imageUrl: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const displayImageUrl = computed(() => internalImageUrl.value || props.imageUrl);
const imageBlob = computed<Blob | null>({
  get: () => props.modelValue,
  set: (val) => {
    internalImageUrl.value = val ? convertBlobToUrl(val) : null;
    emit('update:modelValue', val);
  },
});
const refImage = ref<HTMLImageElement | null>(null);
const internalImageUrl = ref<string | null>();

const handleChange: UploadProps['onChange'] = async (uploadFile) => {
  if (!uploadFile.raw) {
    imageBlob.value = null;
  }
  const blob = convertFileToBlob(uploadFile.raw);
  imageBlob.value = await resizeAndCropBlob(blob);
};

const resizeAndCropBlob = async (blob: Blob) => {
  const image = await loadImage(convertBlobToUrl(blob));
  const croppedBase64 = cropImageToSquare(image);

  const croppedImage = await loadImage(croppedBase64);
  const resizedBase64 = resizeImage(croppedImage, FILE_MAX_LENGTH);

  return convertBase64ToBlob(resizedBase64);
};

return (_ctx: any,_cache: any) => {
  const _component_ElIcon = _resolveComponent("ElIcon")!
  const _component_ElUpload = _resolveComponent("ElUpload")!

  return (_openBlock(), _createBlock(_component_ElUpload, {
    class: _normalizeClass(["b-avatar-upload", {
      'with-image': internalImageUrl.value,
    }]),
    accept: "image/*",
    drag: "",
    "show-file-list": false,
    "auto-upload": false,
    "on-change": handleChange
  }, {
    default: _withCtx(() => [
      (displayImageUrl.value)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            ref_key: "refImage",
            ref: refImage,
            src: displayImageUrl.value,
            class: "avatar"
          }, null, 8, _hoisted_1))
        : (_openBlock(), _createBlock(_component_ElIcon, {
            key: 1,
            class: "b-avatar-upload-icon"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(Plus))
            ]),
            _: 1
          }))
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})
<template>
  <div>
    <BListItem class="mb-300">
      <template #header>
        <span>{{ $t('callResult.connected.title') }}</span>
      </template>
      <SwitchBtn
        v-model="internalConnected"
        class="mb-300"
      />
    </BListItem>
    <div class="result-form-content">
      <template v-if="internalConnected">
        <BListItem class="mb-300">
          <template #header>
            <span>{{ $t('callResult.connectedTo.title') }}</span>
          </template>
          <BSelectBtn
            ref="connectedTo"
            v-model="internalConnectedTo"
            :items="connectedToItems"
          />
        </BListItem>
        <BListItem v-if="internalConnectedTo !== 'unknown'">
          <template #header>
            <span>{{ $t('callResult.title') }}</span>
          </template>
          <BSelect
            v-model="internalResult"
            :items="connectedCallResultItems"
            item-text="text"
            item-value="value"
            fit
          />
        </BListItem>
      </template>
      <template v-else>
        <BListItem>
          <template #header>
            <span>{{ $t('callResult.title') }}</span>
          </template>
          <BSelect
            v-model="internalResult"
            :items="notConnectedCallResultItems"
            item-text="text"
            item-value="value"
            fit
          />
        </BListItem>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import SwitchBtn from '@/components/organisms/user/call_target/menu/call_result/SwitchBtn.vue'
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';

export default {
  components: {
    SwitchBtn,
  },
  props: {
    connected: Boolean,
    connectedTo: String,
    result: String,
  },
  emits: [
    'update:connected',
    'update:connected-to',
    'update:result',
  ],
  setup() {
    const { isJinjer } = useAvailableFeatureCheck();
    return {
      isJinjer,
    }
  },
  computed: {
    ...mapGetters('user', [
      'callResultTypes',
      'defaultCallResultTypes',
      'notConnectedCallResultTypes',
    ]),
    internalConnected: {
      get () {
        return this.connected
      },
      set (newVal) {
        if (this.connected === newVal) return
        this.$emit('update:connected', newVal)
        this.internalConnectedTo = ''
        this.internalResult = ''
        if (this.$refs.result) this.$refs.result.resetSelectResults()
      },
    },
    internalConnectedTo: {
      get () {
        return this.connectedTo
      },
      set (newVal) {
        if (this.connectedTo !== newVal) this.$emit('update:connected-to', newVal)
        if (this.$refs.result) this.$refs.result.resetSelectResults()
      },
    },
    internalResult: {
      get () {
        return this.result
      },
      set (newVal) {
        if (this.result !== newVal) this.$emit('update:result', newVal)
      },
    },
    connectedToItems () {
      return [
        { text: this.$t('callResult.connectedTo.enum.stakeholder'), value: 'stakeholder' },
        { text: this.$t('callResult.connectedTo.enum.not_stakeholder'), value: 'not_stakeholder' },
      ]
    },
    notConnectedCallResultItems () {
      const notConnectedCallResultTypes = this.notConnectedCallResultTypes
        .map(candidate => ({ text: candidate.name, value: `default${candidate.id}` }))

      return notConnectedCallResultTypes.concat(this.callResultTypes
        .filter(candidate => !candidate.connected)
        .map(candidate => ({ text: candidate.name, value: String(candidate.id) })),
      )
    },
    connectedCallResultItems () {
      // NOTE: jinjer様の場合、ヒアリング成功・不可を選択させない
      const defaultCallResultTypes = this.isJinjer ? [] : this.defaultCallResultTypes
        .map(candidate => ({ text: candidate.name, value: `default${candidate.id}` }))

      return defaultCallResultTypes.concat(this.callResultTypes
        .filter(candidate => candidate.connected)
        .map(candidate => ({ text: candidate.name, value: String(candidate.id) })),
      )
    },
  },
  watch: {
    connected (newVal) {
      if (newVal) {
        this.internalConnectedTo = ''
      } else {
        // 未着電だった場合は、接続先は「unknown」に指定
        this.internalConnectedTo = 'unknown'
      }
    },
  },
}
</script>

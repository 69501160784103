import Api from '@/api/user'
import Waiting from '@/store/waiting'
import { types } from '../mutations'

const actions = {
  async getClientAction ({ commit }, args) {
    await waiting.check('getClientAction', async () => {
      try {
        const { id } = args
        if (!id) {
          const user = await Api.getCurrentUser()
          args = Object.assign(args, { id: user.data.currentUser.clientId })
        }
        const res = await Api.getClient(args)
        commit(types.SET_CLIENT, Object.values(res.data)[0])
      } catch (err) {
        commit(types.CLEAR_CLIENT)
        throw err
      }
    })
  },
  async updateClientAction ({ commit }, args) {
    await waiting.check('updateClientAction', async () => {
      await Api.updateClient(args)
      // 単体 reload
      try {
        const res = await Api.getClient(args)
        commit(types.SET_CLIENT, Object.values(res.data)[0])
      } catch (err) {
        throw err
      }
    })
  },
}

const waiting = new Waiting(actions)

export default actions

import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "sisense-iframe",
  class: "content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["frame", { loading: _ctx.loading }])
  }, [
    _withDirectives(_createElementVNode("iframe", _hoisted_1, null, 512), [
      [_vShow, !_ctx.loading]
    ])
  ], 2)), [
    [_directive_loading, _ctx.loading]
  ])
}
import { ref, Ref, computed, readonly } from 'vue';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { useI18n } from 'vue-i18n';
import { useWait } from '@/composable/vue-wait';
import { useMfaSetting } from '@/composable/user/mfaSetting/mfa-setting';
import {
  getAuth,
  getMultiFactorResolver,
  multiFactor,
  reauthenticateWithCredential,
  EmailAuthProvider,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  MultiFactorError,
  MultiFactorResolver,
} from 'firebase/auth';

const useFirebaseAuth = (
  signin: Ref<boolean>,
  multiFactorError: Ref<MultiFactorError>,
  setRecaptcha: () => Promise<RecaptchaVerifier>,
) => {
  const i18n = useI18n();
  const { doActionWithWait } = useWait();
  const { createMfaSetting } = useMfaSetting();

  const reAuthed = ref<boolean>(false);
  const verificationId = ref<string>();
  const resolver = ref<MultiFactorResolver | null>(null);
  const errorMessage = ref<string | null>(null);
  const sentVerificationCode = computed<boolean>(() =>
    !!verificationId.value,
  );

  const resetErrorMessage = () => {
    errorMessage.value = null;
  };

  const resetVerification = () => {
    verificationId.value = null;
  };

  const reAuth = async (password: string) => {
    try {
      await doActionWithWait('reAuthWait', async () => {
        const auth = getAuth();
        const user = auth.currentUser;

        // NOTE: 多要素認証をアクティブにするには直近のログインが必要
        const credential = EmailAuthProvider.credential(user.email, password);
        await reauthenticateWithCredential(user, credential).then(() => {
          reAuthed.value = true;
        });
      });
    } catch (error) {
      let alertText = error.message
      switch (error.code) {
        case 'auth/invalid-credential':
        case 'auth/missing-password':
          alertText = i18n.t('auth.errorMessage.wrongPassword')
          break
        case 'auth/too-many-requests':
          alertText = i18n.t('auth.errorMessage.tooManyRequests')
          break
      }
      errorMessage.value = alertText;
    };
  };

  const sendVerificationCode = async (phoneNumber: string) => {
    const recaptchaVerifier = await setRecaptcha();

    try {
      await doActionWithWait('sendVerificationCodeWait', async () => {
        const auth = getAuth();
        let phoneInfoOptions;

        if (signin.value) {
          resolver.value = getMultiFactorResolver(auth, multiFactorError.value);
          const selectedIndex = 0;
          if (resolver.value.hints[selectedIndex].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
            phoneInfoOptions = {
              multiFactorHint: resolver.value.hints[selectedIndex],
              session: resolver.value.session,
            };
          }
        } else {
          const multiFactorSession = await multiFactor(auth.currentUser).getSession();
          phoneInfoOptions = {
            phoneNumber: convertE164PhoneNumber(phoneNumber),
            session: multiFactorSession,
          };
        }

        // NOTE: 認証コードを送信する
        const phoneAuthProvider = new PhoneAuthProvider(auth);
        await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier).then((id) => {
          verificationId.value = id;
        });
      });
    } catch (error) {
      errorMessage.value = i18n.t('userSecurity.mfaSetting.failedVerificationCode');
    }
  };

  const convertE164PhoneNumber = (phoneNumber: string) => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const phoneNumberJp = phoneUtil.parseAndKeepRawInput(phoneNumber, 'JP');
    return phoneUtil.format(phoneNumberJp, PhoneNumberFormat.E164);
  };

  const resolveVerificationCode = async (phoneNumber: string, verificationCode: string) => {
    try {
      await doActionWithWait('resolveVerificationCodeWait', async () => {
        const auth = getAuth();
        const cred = PhoneAuthProvider.credential(verificationId.value, verificationCode);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

        // NOTE: 認証コードを確認する
        if (signin.value) {
          await resolver.value.resolveSignIn(multiFactorAssertion);
        } else {
          await multiFactor(auth.currentUser).enroll(multiFactorAssertion, 'phone');
          await createMfaSetting({
            mfaSettingBody: {
              factorType: 'phone',
              factor: phoneNumber,
            },
          });
        }
      });
    } catch (error) {
      let alertText = error.message
      switch (error.code) {
        case 'auth/invalid-verification-code':
        case 'auth/missing-code':
          alertText = i18n.t('auth.errorMessage.wrongVerificationCode')
          break
      }
      errorMessage.value = alertText;
    }
  };

  return {
    reAuth,
    reAuthed: readonly(reAuthed),
    sendVerificationCode,
    sentVerificationCode: readonly(sentVerificationCode),
    resolveVerificationCode,
    errorMessage: readonly(errorMessage),
    resetErrorMessage,
    resetVerification,
    convertE164PhoneNumber,
  };
};

export {
  useFirebaseAuth,
}

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "navbar-left" }
const _hoisted_2 = { class: "page-title fs-400 fw-bold" }
const _hoisted_3 = { class: "ml-100" }
const _hoisted_4 = { class: "navbar-right" }
const _hoisted_5 = { class: "select-wrapper mt-300 mb-200 pl-600" }
const _hoisted_6 = { class: "mt-50" }

import { ref, onBeforeMount, computed, watch, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { PaginationMeta, Trigger, TriggerApiGetTriggersRequest } from '@/api/openapi';
import { TriggerApiService } from '@/api/user/resources/trigger';
import TriggerList from '@/components/organisms/user/triggers/TriggerList.vue';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import { useAlert } from '@/composable/message-dialog';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { i18nGlobal as i18n } from '@/i18n';

// NOTE: 現状固定
const NUMBER_OF_QUICK_TEXT_LIMIT = 10;

export default /*@__PURE__*/_defineComponent({
  __name: 'Triggers',
  setup(__props) {

const { isAvailableTriggerFeature } = useAvailableFeatureCheck()
const { doActionWithWait } = useWait()
const { alert } = useAlert();
const api = new TriggerApiService();
const router = useRouter();
const { currentUser, fetchCurrentUser } = useCurrentUser();

const triggers = ref<Trigger[]>([]);
const currentPage = ref(1);
const meta = ref<PaginationMeta>({
  totalPages: 1,
  totalCount: 0,
  limitValue: 0,
  currentPage: 0,
  isEmpty: false,
});
const selectedUserId = ref<number | undefined>();

const canCreate = computed(() => {
  return meta.value.totalCount < NUMBER_OF_QUICK_TEXT_LIMIT;
});

onBeforeMount(async () => {
  await fetchCurrentUser();
  selectedUserId.value = currentUser.value.id;
  await fetchTriggers(currentPage.value);
});

watch(() => selectedUserId.value, (newValue) => {
  fetchTriggers(currentPage.value);
});

watch(() => currentPage.value, (newValue) => {
  fetchTriggers(newValue);
});

const allPageCount = computed(() => meta.value.totalPages);

const fetchTriggers = async (currentPage: number) => {
  const ownerId = selectedUserId.value
  const request: TriggerApiGetTriggersRequest = {
    currentPage,
    ownerId,
  }
  await doActionWithWait('getTriggersWait', async () => {
    const { data } = await api.getTriggers({ request });
    triggers.value = data.triggers;
    meta.value = data.meta;
  });
};

const handleClickNew = async () => {
  if (!canCreate.value) {
    await alert({
      title: i18n.t('general.error'),
      message: i18n.t('trigger.exceededTriggerLimit'),
    });
    return;
  }
  router.push({ name: 'TriggerNew' });
};

const handleClickEdit = (id: number) => {
  router.push({ name: 'Trigger', params: { id } });
};

const handleChangeTriggerList = () => {
  fetchTriggers(currentPage.value);
};

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BPagination = _resolveComponent("BPagination")!
  const _component_SelectUser = _resolveComponent("SelectUser")!
  const _component_BContent = _resolveComponent("BContent")!
  const _directive_loading = _resolveDirective("loading")!

  return (_unref(isAvailableTriggerFeature))
    ? _withDirectives((_openBlock(), _createBlock(_component_BContent, {
        key: 0,
        class: "content"
      }, {
        navbar: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('trigger.title')), 1),
            (_ctx.$can('owner'))
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "create-btn",
                  onClick: handleClickNew
                }, [
                  _createVNode(_component_BIcon, {
                    type: "white",
                    size: "medium"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createElementVNode("span", null, "bolt", -1)
                    ])),
                    _: 1
                  }),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('general.create.to', { item: _ctx.$t('trigger.title') })), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_BPagination, {
              modelValue: currentPage.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentPage).value = $event)),
              length: allPageCount.value,
              "total-visible": 5
            }, null, 8, ["modelValue", "length"])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_SelectUser, {
              modelValue: selectedUserId.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedUserId).value = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(TriggerList, {
              triggers: triggers.value,
              "onClick:row": handleClickEdit,
              onAfterDelete: handleChangeTriggerList
            }, null, 8, ["triggers"])
          ])
        ]),
        _: 1
      })), [
        [_directive_loading, _ctx.$wait.is(['getTriggersWait', 'deleteTriggerWait'])]
      ])
    : _createCommentVNode("", true)
}
}

})
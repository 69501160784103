<template>
  <div
    v-infinite-scroll="load"
    infinite-scroll-disabled="scrollDisabled"
    infinite-scroll-distance="20"
  >
    <BEmptyBox
      v-if="!existActions"
      class="empty"
      display-only
    >
      {{ $t('reminder.empty') }}
    </BEmptyBox>
    <template v-else>
      <div
        v-for="(timelineActions, index) in orderedActions"
        :key="timelineActions.key"
      >
        <div
          v-if="timelineActions.status === 'isCurrent'"
          class="current-time-line"
        />
        <ReminderItem
          v-for="item in timelineActions.value"
          :key="item.nextActionId"
          :item="item"
        />
        <div
          v-if="index === orderedActions.length - 1 && timelineActions.status === 'isPast'"
          class="current-time-line"
        />
      </div>
    </template>
    <BLayout
      v-if="scrollDisabled && $wait.is('infiniteLoadingWait')"
      class="py-200"
      justify-center
    >
      <BElementIcon name="Loading" />
    </BLayout>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { formatDateTime, isDateAfter, newDate } from '@/utils/date-time';

export default defineComponent({
  props: {
    actions: {
      type: Array,
      default () {
        return []
      },
    },
  },
  data () {
    return {
      scrollDisabled: false,
      displayCount: 0,
      initialDisplayCount: 20,
      increaseNumber: 10,
    }
  },
  computed: {
    existActions () {
      return this.actions.length > 0
    },
    path () {
      return this.$route.path.split('/')[1]
    },
    filterActions () {
      return this.actions.slice(0, this.displayCount)
    },
    resultLength () {
      return this.actions.length
    },
    orderedActions () {
      const timelineObj = {}
      const actions = this.filterActions
      for (let i = 0, l = actions.length; i < l; i++) {
        const action = actions[i]
        const key = formatDateTime(action.callBackReservedAt)
        if (!timelineObj[key]) {
          timelineObj[key] = []
        }
        timelineObj[key].push(action)
      }

      const timelineArray = Object.entries(timelineObj).map(([key, value]) => ({ key, value }))
      const sortedTimelineArray = timelineArray.sort((a, b) => {
        if (a.key < b.key) return -1
        if (a.key > b.key) return 1
        return 0
      })

      const now = newDate();
      let isPast = true
      return sortedTimelineArray.map(timeObj => {
        if (isPast) {
          if (isDateAfter(timeObj.key, now)) {
            isPast = false
            timeObj.status = 'isCurrent'
          } else {
            timeObj.status = 'isPast'
          }
        } else {
          timeObj.status = 'isFuture'
        }
        return timeObj
      })
    },
  },
  methods: {
    load () {
      if (this.actions.length <= this.displayCount) return
      this.$wait.start('infiniteLoadingWait')
      this.scrollDisabled = true
      setTimeout(() => {
        if (this.displayCount + this.increaseNumber >= this.resultLength) {
          const remainingResultLength = this.resultLength - this.displayCount
          this.displayCount += remainingResultLength
        } else {
          this.displayCount += this.increaseNumber
        }
        this.$wait.end('infiniteLoadingWait')
        this.scrollDisabled = false
      }, 1000)
    },
  },
});
</script>

<style lang="scss" scoped>
  .empty {
    color: $textcolor-light;
    padding: $basespace-600;
    margin: $basespace-600;
    height: 90px;
    font-size: 12px;
  }

  .current-time-line {
    position: relative;
    width: 100%;
    height: 2px;
    background-color: $basecolor-primary;

    &:before {
      position: absolute;
      height: 12px;
      width: 12px;
      margin-top: -5px;
      content: "";
      background: $basecolor-primary;
      border-radius: 50%;
    }
  }
</style>

import ApiService, { ApiArguments } from '@/api/api_service';
import {
  SequenceStepInstance,
  SequenceStepInstanceApi,
  SequenceStepInstanceApiExecuteSequenceStepInstanceRequest,
  SequenceStepInstanceApiGetCurrentSequenceStepInstanceRequest,
  SequenceStepInstanceApiGetSequenceStepInstancesRequest,
  SequenceStepInstanceApiPutSequenceStepInstanceRequest,
  SequenceStepInstanceApiSkipSequenceStepInstanceRequest,
} from '@/api/openapi';

export class SequenceStepInstanceApiService extends ApiService {
  api = new SequenceStepInstanceApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true)
  }

  async getSequenceStepInstances({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceStepInstanceApiGetSequenceStepInstancesRequest>) {
    return this.checkResponse<SequenceStepInstance[]>(
      this.api.getSequenceStepInstances(request, options),
      errorHandlers,
    );
  }

  async putSequenceStepInstance({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceStepInstanceApiPutSequenceStepInstanceRequest>) {
    return this.checkResponse<void>(
      this.api.putSequenceStepInstance(request, options),
      errorHandlers,
    );
  }

  async draftDirectMailSequenceStepInstance({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceStepInstanceApiPutSequenceStepInstanceRequest>) {
    return this.checkResponse<void>(
      this.api.draftDirectMailSequenceStepInstance(request, options),
      errorHandlers,
    );
  }

  async reserveDirectMailSequenceStepInstance({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceStepInstanceApiPutSequenceStepInstanceRequest>) {
    return this.checkResponse<void>(
      this.api.reserveDirectMailSequenceStepInstance(request, options),
      errorHandlers,
    );
  }

  async skipSequenceStepInstance({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceStepInstanceApiSkipSequenceStepInstanceRequest>) {
    return this.checkResponse<void>(
      this.api.skipSequenceStepInstance(request, options),
      errorHandlers,
    );
  }

  async executeSequenceStepInstance({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceStepInstanceApiExecuteSequenceStepInstanceRequest>) {
    return this.checkResponse<void>(
      this.api.executeSequenceStepInstance(request, options),
      errorHandlers,
    );
  }

  async getCurrentSequenceStepInstance({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceStepInstanceApiGetCurrentSequenceStepInstanceRequest>) {
    return this.checkResponse<SequenceStepInstance>(
      this.api.getCurrentSequenceStepInstance(request, options),
      errorHandlers,
    );
  }
}

<template>
  <div class="theader">
    <div class="table-row theader-row">
      <BSortText
        v-for="cell in headers"
        :key="cell.key"
        class="table-cell header-cell"
        :class="cell.class"
        :item-text="cell.name"
        :info="cell.info"
        is-display-only
      />
      <div class="table-cell menu" />
    </div>
  </div>
  <div class="tbody">
    <div
      v-for="sequenceMaster in sequenceMasters"
      :key="sequenceMaster.id"
      class="table-row tbody-row"
      @click="() => moveToEdit(sequenceMaster.id)"
    >
      <div
        v-for="column in headers"
        :key="column.key"
        class="table-cell"
        :class="column.class"
      >
        <template v-if="column.key === 'name'">
          <div class="name">
            {{ sequenceMaster.name }}
          </div>
          <BTooltip
            top
            :content="$t('general.duplicate.do')"
          >
            <BCustomIcon
              size="small"
              icon-class="b-clone"
              hover
              @click.stop="() => moveToDuplicate(sequenceMaster.id)"
            />
          </BTooltip>
        </template>
        <template v-else-if="column.key === 'summary.all'">
          {{ sequenceMaster.numberOfInstances }}
        </template>
        <template v-else-if="column.key === 'summary.registered'">
          {{ sequenceMaster.numberOfRegistered }}
        </template>
        <template v-else-if="column.key === 'summary.stopped'">
          {{ sequenceMaster.numberOfStopped }}
        </template>
        <template v-else-if="column.key === 'summary.done'">
          {{ sequenceMaster.numberOfDone }}
        </template>
        <template v-else-if="column.key === 'summary.associatedOpportunity'">
          {{ sequenceMaster.numberOfOpportunities }}
        </template>
        <template v-else-if="column.key === 'summary.unregistered'">
          {{ sequenceMaster.numberOfUnregistered }}
        </template>
        <template v-else-if="column.key === 'mailAggregates.all'">
          {{ sequenceMaster.mailAggregates.numberOfAll }}
        </template>
        <template v-else-if="column.key === 'mailAggregates.sent'">
          {{ sequenceMaster.mailAggregates.numberOfSent }}
          <div class="rate">
            {{ displayRate(sequenceMaster.mailAggregates.sentPerAll) }}
          </div>
        </template>
        <template v-else-if="column.key === 'mailAggregates.opened'">
          {{ sequenceMaster.mailAggregates.numberOfOpened }}
          <div class="rate">
            {{ displayRate(sequenceMaster.mailAggregates.openedPerSent) }}
          </div>
        </template>
        <template v-else-if="column.key === 'mailAggregates.clicked'">
          {{ sequenceMaster.mailAggregates.numberOfClicked }}
          <div class="rate">
            {{ displayRate(sequenceMaster.mailAggregates.clickedPerSent) }}
          </div>
        </template>
        <template v-else-if="column.key === 'mailAggregates.replied'">
          {{ sequenceMaster.mailAggregates.numberOfReplied }}
          <div class="rate">
            {{ displayRate(sequenceMaster.mailAggregates.repliedPerSent) }}
          </div>                                         
        </template>
        <template v-else-if="column.key === 'mailAggregates.failed'">
          {{ sequenceMaster.mailAggregates.numberOfFailed }}
          <div class="rate">
            {{ displayRate(sequenceMaster.mailAggregates.failedPerAll) }}
          </div>
        </template>
        <template v-else-if="column.key === 'createdUser'">
          <BUserTooltip
            v-if="sequenceMaster.createdUser"
            :image-url="sequenceMaster.createdUser.avatarUrl"
            :name="sequenceMaster.createdUser.name"
            :user-id="sequenceMaster.createdUser.id"
            :size="40"
          />
        </template>
        <template v-else-if="column.key === 'updatedUser'">
          <BUserTooltip
            v-if="sequenceMaster.updatedUser"
            :image-url="sequenceMaster.updatedUser.avatarUrl"
            :name="sequenceMaster.updatedUser.name"
            :user-id="sequenceMaster.updatedUser.id"
            :size="40"
          />
        </template>
        <template v-else-if="column.key === 'owner'">
          <BUserTooltip
            v-if="sequenceMaster.owner"
            :image-url="sequenceMaster.owner.avatarUrl"
            :name="sequenceMaster.owner.name"
            :user-id="sequenceMaster.owner.id"
            :size="40"
          />
        </template>
      </div>
      <div
        class="table-cell menu"
        @click.stop
      >
        <BMoreMenu
          :z-index100="true"
        >
          <BPopover
            placement="top"
            width="240"
            trigger="hover"
            :disabled="sequenceMaster.deletable"
            :content="$t('general.alreadyUsed')"
          >
            <template #reference>
              <div>
                <BBtn
                  flat
                  fit
                  size="small"
                  :disabled="!sequenceMaster.deletable"
                  @click="() => handleDeleteButtonClick(sequenceMaster)"
                >
                  {{ $t('general.delete.text') }}
                </BBtn>
              </div>
            </template>
          </BPopover>
          <div>
            <BBtn
              flat
              fit
              size="small"
              @click="() => handleSetOperatorModal(sequenceMaster.id)"
            >
              {{ $t('sequence.owner') }}
            </BBtn>
          </div>
        </BMoreMenu>
        <BModal
          modal-name="setOperatorInSequenceInMasterList"
          width="380px"
          height="100px"
        >
          <SetOperator
            :current-operator-id="sequenceMaster.owner?.id"
            :operator-users="activeUsers"
            :needs-withdraw-option="false"
            @assign-operator="assignOperator"
          />
        </BModal>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router';
import { SequenceMaster } from '@/api/openapi';
import { SequenceMasterApiService } from '@/api/user/resources/sequence_master';
import { useUsers } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { KEY_OF_MODAL } from '@/injection-keys';
import { bitterAlert } from '@/plugins/BBitterAlert';

type TProps = {
  sequenceMasters: SequenceMaster[];
};
type TEmits = {
  (e: 'afterDelete'): void;
  (e: 'afterChangeOwner'): void;
};

defineProps<TProps>();
const emit = defineEmits<TEmits>();

const router = useRouter();

const modal = inject(KEY_OF_MODAL);
const i18n = useI18n();
const api = new SequenceMasterApiService();
const selectedSequenceMasterId = ref<number>();
const {
  doActionWithWait,
} = useWait();

const headers = [
  { key: 'name', name: i18n.t('sequence.name'), class: ['name-container'] },
  { key: 'summary.all', name: i18n.t('sequence.summary.all'), class: ['summary'] },
  { key: 'summary.registered', name: i18n.t('sequence.summary.registered'), class: ['summary'] },
  { key: 'summary.stopped', name: i18n.t('sequence.summary.stopped'), class: ['summary'] },
  { key: 'summary.done', name: i18n.t('sequence.summary.done'), class: ['summary'] },
  { key: 'summary.associatedOpportunity', name: i18n.t('sequence.summary.associatedOpportunity'), class: ['summary'] },
  { key: 'summary.unregistered', name: i18n.t('sequence.summary.unregistered'), class: ['summary'] },
  { key: 'divider1', name: '', class: ['column-divider'] },
  { key: 'mailAggregates.all', name: i18n.t('sequence.mailAggregates.all'), class: ['summary', 'mail-aggregate'], info: i18n.t('sequence.mailAggregates.allInfo') },
  { key: 'mailAggregates.sent', name: i18n.t('sequence.mailAggregates.sent'), class: ['summary', 'mail-aggregate'], info: i18n.t('sequence.mailAggregates.sentInfo') },
  { key: 'mailAggregates.opened', name: i18n.t('sequence.mailAggregates.opened'), class: ['summary', 'mail-aggregate'], info: i18n.t('sequence.mailAggregates.openedInfo') },
  { key: 'mailAggregates.clicked', name: i18n.t('sequence.mailAggregates.clicked'), class: ['summary', 'mail-aggregate'], info: i18n.t('sequence.mailAggregates.clickedInfo') },
  { key: 'mailAggregates.replied', name: i18n.t('sequence.mailAggregates.replied'), class: ['summary', 'mail-aggregate'], info: i18n.t('sequence.mailAggregates.repliedInfo') },
  { key: 'mailAggregates.failed', name: i18n.t('sequence.mailAggregates.failed'), class: ['summary', 'mail-aggregate'], info: i18n.t('sequence.mailAggregates.failedInfo') },
  { key: 'divider2', name: '', class: ['column-divider'] },
  { key: 'createdUser', name: i18n.t('sequence.createdUser'), class: ['user'] },
  { key: 'updatedUser', name: i18n.t('sequence.updatedUser'), class: ['user'] },
  { key: 'owner', name: i18n.t('sequence.owner'), class: ['user'] },
];

const displayRate = (rate: number) => `(${rate.toFixed(1)}%)`;
const moveToEdit = (id: number) => router.push({ name: 'Sequence', params: { id } });
const moveToDuplicate = (duplicateId: number) => router.push({ name: 'SequenceNew', query: { duplicateId } });
const { activeUsers } = useUsers();
const handleDeleteButtonClick = async (sequenceMaster: SequenceMaster) => {
  const ok = await bitterAlert.show({
    text: i18n.t('sequence.message.confirmWhetherToDelete', { target: sequenceMaster.name }),
  });
  if (!ok) return;
  await deleteSequenceMaster(sequenceMaster.id);
  emit('afterDelete');
};

const deleteSequenceMaster = async (sequenceMasterId: number) => {
  await doActionWithWait(KEY_OF_DELETE_SEQUENCE_MASTER_WAIT, async () => {
    await api.deleteSequenceMaster({
      request: { sequenceMasterId },
    });
  });
};

const handleSetOperatorModal = async (sequenceMasterId: number) => {
  selectedSequenceMasterId.value = sequenceMasterId;
  modal.show('setOperatorInSequenceInMasterList')
}

const assignOperator = async (ownerId: number) => {
  const ok = await bitterAlert.show({
    text: i18n.t('sequence.message.confirmChangeSequenceOwner'),
  });
  if (!ok) {
    modal.hide('setOperatorInSequenceInMasterList');
    return;
  }
  
  await doActionWithWait(KEY_OF_CHANGE_SEQUENCE_MASTER_OWNER_WAIT, async () => {
    await api.changeSequenceMasterOwner({
      request: {
        changeSequenceMasterOwnerBody: {
          sequenceMasterId: selectedSequenceMasterId.value,
          ownerId,
        },
      },
    })
  });
  modal.hide('setOperatorInSequenceInMasterList');
  emit('afterChangeOwner');
}
</script>

<script lang="ts">
export const KEY_OF_DELETE_SEQUENCE_MASTER_WAIT = 'deleteSequenceMasterWait';
export const KEY_OF_CHANGE_SEQUENCE_MASTER_OWNER_WAIT = 'changeSequenceMasterOwnerWait';
</script>

<style lang="scss" scoped>
.table-row {
  align-items: center;
  padding-right: $basespace-400;
  display: inline-flex;
  min-width: 100%;
}

.table-cell {
  @include m-fixed-width(50px);
  padding-right: $basespace-200;
  padding-left: 10px;
  background-color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:first-child {
    padding-left: 30px;
  }

  &.name-container {
    @include m-fixed-width(400px);
    position: sticky;
    left: 0;
    z-index: 1;
    display: flex;
    // NOTE: user列と高さを揃えている
    min-height: 40px;
    align-items: center;
    .name {
      flex: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &.column-divider {
    @include m-fixed-width(0px);
  }
  &.summary {
    justify-content: center;
    @include m-fixed-width(4.5rem);
  }
  &.mail-aggregate {
    @include m-fixed-width(6rem);
  }

  &.user {
    justify-content: center;
    @include m-fixed-width(80px);
    :deep(.b-avatar) {
      justify-content: center;
    }
  }

  &.menu {
    width: 100%;
  }
}

.theader {
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 2;
  cursor: text;
  &-row {
    height: inherit;
    background-color: white;
    border-bottom: 1px solid $bdcolor-base;
    padding-bottom: $basespace-100;
    color: $textcolor-light;
    font-size: $fontsize-100;
  }
}

.tbody-row {
  border-bottom: 1px solid $bdcolor-base;
  padding-top: $basespace-200;
  padding-bottom: $basespace-200;

  cursor: pointer;
  &:hover {
    background-color: $bgcolor-base;

    .table-cell {
      background-color: $bgcolor-base;
    }
  }

  .table-cell.summary {
    font-weight: bold;
    font-size: $fontsize-300;
    text-align: center;

    .rate {
      font-weight: normal;
      color: $textcolor-light;
      font-size: $fontsize-100;
    }
  }
}
:deep(.b-modal) {
  background: rgba(0, 0, 0, 0.1) !important;
}
</style>

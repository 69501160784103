<template>
  <BContent
    v-if="isAvailableTriggerFeature"
    v-loading="$wait.is(['getTriggersWait', 'deleteTriggerWait'])"
    class="content"
  >
    <template #navbar>
      <div class="navbar-left">
        <span class="page-title fs-400 fw-bold">{{ $t('trigger.title') }}</span>
        <button
          v-if="$can('owner')"
          type="button"
          class="create-btn"
          @click="handleClickNew"
        >
          <BIcon
            type="white"
            size="medium"
          >
            <span>bolt</span>
          </BIcon>
          <span class="ml-100">{{ $t('general.create.to', { item: $t('trigger.title') }) }}</span>
        </button>
      </div>
      <div class="navbar-right">
        <BPagination
          v-model="currentPage"
          :length="allPageCount"
          :total-visible="5"
        />
      </div>
    </template>
    <div class="select-wrapper mt-300 mb-200 pl-600">
      <SelectUser
        v-model="selectedUserId"
      />
    </div>
    <div class="mt-50">
      <TriggerList
        :triggers="triggers"
        @click:row="handleClickEdit"
        @after-delete="handleChangeTriggerList"
      />
    </div>
  </BContent>
</template>

<script lang="ts" setup>
import { ref, onBeforeMount, computed, watch, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { PaginationMeta, Trigger, TriggerApiGetTriggersRequest } from '@/api/openapi';
import { TriggerApiService } from '@/api/user/resources/trigger';
import TriggerList from '@/components/organisms/user/triggers/TriggerList.vue';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import { useAlert } from '@/composable/message-dialog';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { i18nGlobal as i18n } from '@/i18n';

// NOTE: 現状固定
const NUMBER_OF_QUICK_TEXT_LIMIT = 10;
const { isAvailableTriggerFeature } = useAvailableFeatureCheck()
const { doActionWithWait } = useWait()
const { alert } = useAlert();
const api = new TriggerApiService();
const router = useRouter();
const { currentUser, fetchCurrentUser } = useCurrentUser();

const triggers = ref<Trigger[]>([]);
const currentPage = ref(1);
const meta = ref<PaginationMeta>({
  totalPages: 1,
  totalCount: 0,
  limitValue: 0,
  currentPage: 0,
  isEmpty: false,
});
const selectedUserId = ref<number | undefined>();

const canCreate = computed(() => {
  return meta.value.totalCount < NUMBER_OF_QUICK_TEXT_LIMIT;
});

onBeforeMount(async () => {
  await fetchCurrentUser();
  selectedUserId.value = currentUser.value.id;
  await fetchTriggers(currentPage.value);
});

watch(() => selectedUserId.value, (newValue) => {
  fetchTriggers(currentPage.value);
});

watch(() => currentPage.value, (newValue) => {
  fetchTriggers(newValue);
});

const allPageCount = computed(() => meta.value.totalPages);

const fetchTriggers = async (currentPage: number) => {
  const ownerId = selectedUserId.value
  const request: TriggerApiGetTriggersRequest = {
    currentPage,
    ownerId,
  }
  await doActionWithWait('getTriggersWait', async () => {
    const { data } = await api.getTriggers({ request });
    triggers.value = data.triggers;
    meta.value = data.meta;
  });
};

const handleClickNew = async () => {
  if (!canCreate.value) {
    await alert({
      title: i18n.t('general.error'),
      message: i18n.t('trigger.exceededTriggerLimit'),
    });
    return;
  }
  router.push({ name: 'TriggerNew' });
};

const handleClickEdit = (id: number) => {
  router.push({ name: 'Trigger', params: { id } });
};

const handleChangeTriggerList = () => {
  fetchTriggers(currentPage.value);
};
</script>

<style lang="scss" scoped>
.navbar-left {
  display: flex;
  height: 100%;
  margin-top: 3px;
  .create-btn {
    position: relative;
    width: 150px;
    height: 37px;
    margin-top: 5px;
    margin-left: 30px;
    color: $textcolor-white;
    background-color: $basecolor-primary;
    border-radius: 20px;
    outline: none;
    .b-icon {
      position: absolute;
      top: 10px;
      left: 15px;
    }
  }
  .page-title {
    display: block;
    margin-top: 12px;
  }
}
.navbar-right {
  max-width: 300px;
  margin-left: auto;
  margin-top: 3px;
}
.select-wrapper {
  position: sticky;
  left: 0;
}
.content {
  position: relative;
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "navbar-left" }
const _hoisted_2 = { class: "page-title fs-400 fw-bold" }
const _hoisted_3 = { class: "ml-300" }
const _hoisted_4 = { class: "navbar-right" }
const _hoisted_5 = { class: "select-wrapper mt-300 mb-200 pl-600" }
const _hoisted_6 = { class: "mt-50" }

import { computed, inject, ref, watch, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import { GetSequenceMasters200Response, PaginationMeta, SequenceMaster, SequenceMasterApiGetSequenceMastersRequest } from '@/api/openapi';
import SequenceMasterList, { KEY_OF_CHANGE_SEQUENCE_MASTER_OWNER_WAIT, KEY_OF_DELETE_SEQUENCE_MASTER_WAIT } from '@/components/organisms/user/sequences/SequenceMasterList.vue';
import { useCurrentUser } from '@/composable/user/user/users';
import { i18nGlobal as i18n } from '@/i18n';
import { KEY_OF_WAIT } from '@/injection-keys';
import { bitterAlert } from '@/plugins/BBitterAlert'
import SelectUser from '@/plugins/biscuet-materials/components/molecules/SelectUser.vue';
import { key } from '@/store/index'


export default /*@__PURE__*/_defineComponent({
  __name: 'Sequences',
  setup(__props) {

const store = useStore(key);
const wait = inject(KEY_OF_WAIT);
const router = useRouter();

const sequenceMasters = ref<SequenceMaster[]>([]);
const currentPage = ref(1);
const meta = ref<PaginationMeta>({
  totalPages: 1,
  totalCount: 0,
  limitValue: 0,
});

const { currentUser, fetchCurrentUser } = useCurrentUser();
const selectedUserId = ref<number | undefined>();

onBeforeMount(async () => {
  await fetchCurrentUser();
  selectedUserId.value = currentUser.value.id;
  await loadSequenceMasters(currentPage.value);
})

const loadSequenceMasters = async (currentPage: number) => {
  const ownerId = selectedUserId.value
  const request: SequenceMasterApiGetSequenceMastersRequest = {
    currentPage,
    ownerId,
  }

  wait.start('getSequenceMastersWait');
  const { data } = await store.dispatch('user/getSequenceMastersAction', { request });
  const { sequenceMasters: dataSequenceMasters, meta: dataMeta } = data as GetSequenceMasters200Response;
  sequenceMasters.value = dataSequenceMasters;
  meta.value = dataMeta;
  wait.end('getSequenceMastersWait');
};

const allPageCount = computed(() => meta.value.totalPages);

watch(() => currentPage.value, (newValue) => {
  loadSequenceMasters(newValue);
});

watch(() => selectedUserId.value, (newValue) => {
  loadSequenceMasters(currentPage.value);
});

const moveToSequenceNew = async () => {
  wait.start('getClientLimitSettingWait');
  const { sequenceLimit } = await store.dispatch('user/getClientLimitSettingAction');
  if (meta.value.totalCount >= sequenceLimit) {
    await bitterAlert.show({
      title: i18n.t('general.error'),
      text: i18n.t('sequence.exceededSequenceLimit'),
    })
    
    return;
  }
  wait.end('getClientLimitSettingWait');
  router.push({ name: 'SequenceNew' });
};

const handleAfterSequenceMasterAction = () => {
  loadSequenceMasters(currentPage.value);
};

return (_ctx: any,_cache: any) => {
  const _component_BCustomIcon = _resolveComponent("BCustomIcon")!
  const _component_BPagination = _resolveComponent("BPagination")!
  const _component_BContent = _resolveComponent("BContent")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_BContent, { class: "content" }, {
    navbar: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('sequence.title')), 1),
        _createElementVNode("button", {
          type: "button",
          class: "create-btn",
          onClick: moveToSequenceNew
        }, [
          _createVNode(_component_BCustomIcon, {
            "icon-class": "b-sequence",
            type: "white",
            size: "medium"
          }),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('sequence.create')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_BPagination, {
          modelValue: currentPage.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentPage).value = $event)),
          length: allPageCount.value,
          "total-visible": 5
        }, null, 8, ["modelValue", "length"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(SelectUser, {
          modelValue: selectedUserId.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedUserId).value = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(SequenceMasterList, {
          "sequence-masters": sequenceMasters.value,
          onAfterDelete: handleAfterSequenceMasterAction,
          onAfterChangeOwner: handleAfterSequenceMasterAction
        }, null, 8, ["sequence-masters"])
      ])
    ]),
    _: 1
  })), [
    [_directive_loading, _ctx.$wait.is(['getSequenceMastersWait', _unref(KEY_OF_DELETE_SEQUENCE_MASTER_WAIT), 'getClientLimitSettingWait', _unref(KEY_OF_CHANGE_SEQUENCE_MASTER_OWNER_WAIT)])]
  ])
}
}

})
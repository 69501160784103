import ApiService, { ApiArguments } from '@/api/api_service';
import {
  SfTodoSyncSettingsApi,
} from '@/api/openapi';

export class SfTodoSyncSettingsService extends ApiService {
  api = new SfTodoSyncSettingsApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    // openapiで作ったやつはtrue
    super(true)
  }

  async getSfTodoSyncSettings() {
    return this.api.getSfTodoSyncSettings()
  }

  async upsertSfTodoSyncSetting(args) {
    return this.api.putSfTodoSyncSettings(args);
  }
}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "item-filter-area" }
const _hoisted_2 = {
  key: 0,
  class: "item-filter-area-input"
}
const _hoisted_3 = { class: "item-filter-area-candidate" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "text-annotation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BCheckbox = _resolveComponent("BCheckbox")!
  const _component_BInput = _resolveComponent("BInput")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.noBlankItem)
      ? (_openBlock(), _createBlock(_component_BCheckbox, {
          key: 0,
          modelValue: _ctx.internalCheckedItem,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalCheckedItem) = $event)),
          class: "my-100",
          label: _ctx.checkboxBlankItem.label,
          value: _ctx.checkboxBlankItem.value
        }, null, 8, ["modelValue", "label", "value"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isSearchText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_BInput, {
              modelValue: _ctx.searchText,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchText) = $event)),
              flat: "",
              placeholder: "候補を入力"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_BCheckbox, {
              class: "all-checkbox",
              "model-value": _ctx.isAllSelected,
              indeterminate: _ctx.isPartiallySelected,
              disabled: _ctx.noCandidate,
              onChange: _ctx.toggleCheckAll
            }, null, 8, ["model-value", "indeterminate", "disabled", "onChange"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.filteredCandidateItemValues.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredCandidateGroups, (group) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: group.name,
                  class: "group"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.items, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: item.value,
                      class: "my-100"
                    }, [
                      _createVNode(_component_BCheckbox, {
                        modelValue: _ctx.internalCheckedItem,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.internalCheckedItem) = $event)),
                        value: item.value
                      }, {
                        label: _withCtx(() => [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(item.labelPrefixComponent), _mergeProps({ ref_for: true }, item.labelPrefixAttributes), null, 16)),
                          _createTextVNode(" " + _toDisplayString(item.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "value"])
                    ]))
                  }), 128))
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('general.noCandidate')), 1)
            ]))
      ])
    ])
  ]))
}
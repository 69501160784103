<template>
  <div class="filter-select">
    <BMenu placement="bottom-start">
      <template #activator>
        <BBtn
          flat
          size="small"
        >
          <BCustomIcon
            class="mr-50"
            icon-class="b-column"
            size="small"
          /><span>{{ $t('leadList.column.title') }}</span>
        </BBtn>
      </template>
      <BCard
        class="column-select-card"
        small
      >
        <div
          v-for="listColumn in listColumns"
          :key="listColumn"
          class="pb-200"
        >
          <div class="sub-title">
            {{ $t(`filterSelect.${listColumn}.title`) }}
          </div>
          <BDivider class="mb-100" />
          <template v-if="listColumn !== 'customField'">
            <div
              v-for="[key, value] in Object.entries($tm(`filterSelect.${listColumn}.column`))"
              :key="key"
              class="select-item"
            >
              <BCheckbox
                v-if="!isExceptColumn(key)"
                v-model="columnChecks[key]"
                class="mb-100"
                :label="value"
                @change="() => onCheckChanged(key)"
              />
            </div>
          </template>
          <template v-else>
            <div
              v-for="customField in customFields"
              :key="customField.fieldKey"
              class="select-item"
            >
              <BCheckbox
                v-model="columnChecks[customField.fieldKey]"
                class="mb-100"
                :label="customField.name"
                @change="() => onCheckChanged(customField.fieldKey)"
              />
            </div>
          </template>
        </div>
        <div class="panel-footer my-200">
          <BLayout
            align-center
            justify-center
          >
            <BFlex xs6>
              <BBtn
                text
                @click="resetDisplayColumn"
              >
                <span class="footer-text reset">{{ $t('general.reset.text') }}</span>
              </BBtn>
            </BFlex>
          </BLayout>
        </div>
      </BCard>
    </BMenu>
  </div>
</template>

<script>
import lodash from 'lodash'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import queryParameter from '@/mixins/query_parameter'

export default {
  name: 'ListColumnSelect',
  mixins: [queryParameter],
  props: {
    exceptInfo: {
      type: Array,
      required: false,
      default: () => [],
    },
    exceptColumns: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup() {
    const { isAvailableSalesforceLinkFeature } = useAvailableFeatureCheck()
    return {
      isAvailableSalesforceLinkFeature,
    }
  },
  computed: {
    ...mapGetters('user', [
      'salesforceSetting',
      'customFields',
    ]),
    ...mapGetters('userUi', [
      'leadListSelectedColumns',
    ]),
    listColumns () {
      const listColumns =  ['companyInfo', 'callTargetInfo', 'customField', 'historyInfo', 'leadAdditionalInfo', 'lastActivityInfo', 'systemAdditionalInfo', 'duplicateInfo']
      if (this.salesforceSetting && this.isAvailableSalesforceLinkFeature) {
        return listColumns.concat(['salesforceInfo'])
      }
      if (this.exceptInfo.length > 0) {
        return listColumns.filter(i => this.exceptInfo.indexOf(i) == -1)
      }
      return listColumns
    },
    columnChecks () {
      // storeにすでに存在する表示項目は true にしておく
      const columnChecks = {}
      this.leadListSelectedColumns.forEach((key) => {
        columnChecks[key] = true
      })
      return columnChecks
    },
  },
  methods: {
    ...mapWaitingActions('userUi', {
      setLeadListSelectedColumnsAction: 'setLeadListSelectedColumnsWait',
      resetLeadListSelectedColumnsAction: 'resetLeadListSelectedColumnsWait',
    }),
    onCheckChanged (key) {
      const leadListSelectedColumns = lodash.cloneDeep(this.leadListSelectedColumns)
      const index = leadListSelectedColumns.indexOf(key)
      const shouldAdding = index === -1
      // leadListSelectedColumns に存在しない場合は追加、既に存在する場合は削除する
      if (shouldAdding) {
        leadListSelectedColumns.push(key)
      } else {
        leadListSelectedColumns.splice(index, 1)
      }
      this.setLeadListSelectedColumnsAction(leadListSelectedColumns)
    },
    resetDisplayColumn () {
      this.resetLeadListSelectedColumnsAction()
    },
    isExceptColumn(key) {
      return this.exceptColumns.indexOf(key) != -1
    },
  },
}
</script>

<style lang="scss" scoped>
  .column-select-card {
    max-height: 550px;
    @include m-fixed-width(320px);
    overflow: auto;
  }

  .sub-title {
    font-size: 12px;
    color: $textcolor-light;
    padding-bottom: $basespace-100;
  }

  .panel-footer {
    @include m-fixed-height(10px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 $basespace-300;
    text-align: center;
    background-color: $bgcolor-white;

    .reset {
      color: $textcolor-light;
    }
  }
</style>

<template>
  <div class="modal-content">
    <div class="sub-title">
      <p>{{ $t('duplicatedList.leadList.subTitle', { count: duplicatedCount }) }}</p>
    </div>
    <BModalBody :style="{ padding: 0 }">
      <VWait
        for="getDuplicatedCallTargetsWait"
      >
        <template #waiting>
          <BPlaceholder
            v-loading="true"
            element-loading-background="transparent"
          />
        </template>
        <DuplicatedLeadList
          v-model="selectedDuplicatedCallTargetIds"
          v-model:default-selected-call-target-ids="internalDefaultSelectedCallTargetIds"
          :call-targets="internalDuplicatedCallTargets"
          :is-first-fixed="true"
          :max-selectable-length="3"
        />
      </VWait>
    </BModalBody>
    <BModalFooter>
      <BLayout
        class="mt-200"
        justify-center
      >
        <BBtn
          type="primary"
          :disabled="!isValidDuplicated"
          @click="$emit('click-next')"
        >
          <span>{{ $t('general.toNext') }}</span>
        </BBtn>
      </BLayout>
    </BModalFooter>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Api from '@/api/user'
import { CallTargetApiService } from '@/api/user/resources/call_target'
import DuplicatedLeadList from '@/components/organisms/user/DuplicatedLeadList.vue'
import { TDuplicatedCallTarget } from '../modal/DuplicatedListModal.vue'

export default defineComponent({
  components: {
    DuplicatedLeadList,
  },
  props: {
    modelValue: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    defaultSelectedCallTargetIds: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    duplicatedCallTargets: {
      type: Array as PropType<TDuplicatedCallTarget[]>,
      default: () => [],
    },
  },
  emits: [
    'update:modelValue',
    'update:default-selected-call-target-ids',
    'update:duplicated-call-targets',
    'click-next',
  ],
  computed: {
    ...mapGetters('user', [
      'callTarget',
    ]),
    selectedDuplicatedCallTargetIds: {
      get(): number[] {
        return this.modelValue
      },
      set(newVal: number[]) {
        this.$emit('update:modelValue', newVal)
      },
    },
    internalDefaultSelectedCallTargetIds: {
      get(): number[] {
        return this.defaultSelectedCallTargetIds
      },
      set(newVal: number[]) {
        this.$emit('update:default-selected-call-target-ids', newVal)
      },
    },
    internalDuplicatedCallTargets: {
      get(): TDuplicatedCallTarget[] {
        return this.duplicatedCallTargets
      },
      set(newVal: TDuplicatedCallTarget[]) {
        this.$emit('update:duplicated-call-targets', newVal)
      },
    },
    isValidDuplicated() {
      return this.selectedDuplicatedCallTargetIds.length >= 2
    },
    duplicatedCount() {
      if (this.selectedDuplicatedCallTargetIds.length >= 3) {
        return `${this.selectedDuplicatedCallTargetIds.length}個（最大数）`
      }
      return `${this.selectedDuplicatedCallTargetIds.length}個`
    },
  },
  async created() {
    if (this.internalDuplicatedCallTargets.length > 0) {
      return
    }

    this.$wait.start('getDuplicatedCallTargetsWait')
    this.internalDuplicatedCallTargets = (
      await Promise.all([
        this.getCallTarget(this.callTarget.id),
        this.getDuplicatedCallTargets(this.callTarget.callTargetInfo.email,
        )])
    ).flat()
    this.$wait.end('getDuplicatedCallTargetsWait')
  },
  methods: {
    async getCallTarget(callTargetId: number): Promise<TDuplicatedCallTarget> {
      const api = new CallTargetApiService();
      const res = await api.getCallTarget({ request: { callTargetId } });
      const ct = res.data.callTarget
      return {
        id: ct.id,
        companyName: ct.companyInfo.companyName,
        name: ct.callTargetInfo.name,
        email: ct.callTargetInfo.email,
        createdAt: ct.createdAt,
        operator: {
          id: ct.user?.id,
          name: ct.user?.name,
          avatarUrl: ct.user?.avatarUrl,
        },
        sfStatus: ct.sfRelatedLinkInfo?.sfRelatedLinks?.[0]?.status,
      }
    },
    async getDuplicatedCallTargets(email: string): Promise<TDuplicatedCallTarget[]> {
      const params = {
        lf: {
          duplicate_email: {
            inc: {
              or: email,
            },
          },
        },
        sortKey: 'callTargetCreatedAt',
        sortOrder: 'asc',
        currentDisplayNumber: 21,
      };
      const res = await Api.getCallTargets({ params });
      const cts = res.data.callTargets
      return cts.filter(ct => ct.callTargetId !== this.callTarget.id).map((ct) => ({
        id: ct.callTargetId,
        companyName: ct.companyName,
        name: ct.targetName,
        email: ct.email,
        createdAt: ct.createdAt,
        operator: {
          name: ct.operatorName,
          id: ct.operatorId,
          avatarUrl: ct.operatorAvatarUrl,
        },
        sfStatus: ct.sfRelatedLinkInfo?.sfRelatedLinks[0].status,
      })).slice(0, 20)
    },
  },
})
</script>

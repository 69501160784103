<template>
  <div class="lead-list">
    <LeadTable
      :call-targets="callTargets"
      :call-targets-meta="callTargetsMeta"
      enabled-to-display-lead
      lead-open-by-lead-list
      @open-set-operator-modal="openSetOperatorModal"
      @open-sf-modal="openSfModal"
      @update:selected-call-target-ids="handleSelectedCallTargetIdsUpdate"
    />
    <SfStatusModal
      v-if="isAvailableSalesforceApiFeature"
      :modal-name="sfModalName(sfRelatedLinkInfo)"
      :sf-related-link-info="sfRelatedLinkInfo"
      :sf-exec-sync-btn="false"
    />
    <BModal
      modal-name="callTargetSetting"
      full
    >
      <CallTargetSetting :custom-fields="customFields" />
    </BModal>
    <BModal
      modal-name="setOperator"
      width="380px"
      height="100px"
    >
      <SetOperator
        :current-operator-id="currentOperatorId"
        :operator-users="activeUsers"
        :needs-withdraw-option="true"
        @assign-operator="assignOperator"
        @withdraw-operator="withdrawOperator"
      />
    </BModal>
    <BModal
      modal-name="target"
      class="lead-modal"
      :delegate="leadModalDelegate"
      full
    >
      <Lead
        ref="callTarget"
        :call-target-id="currentCallTargetId"
        :call-targets="callTargets"
        :call-targets-meta="callTargetsMeta"
        @close-modal="handleCloseLeadModalAction"
      />
    </BModal>
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import CallTargetSetting from '@/components/organisms/user/CallTargetSetting.vue'
import SfStatusModal from '@/components/organisms/user/general/modal/salesforce/SfStatusModal.vue';
import Lead from '@/components/organisms/user/lead/Lead.vue'
import LeadTable from '@/components/organisms/user/leadList/LeadTable.vue'
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import { useUsers } from '@/composable/user/user/users';
import queryParameter from '@/mixins/query_parameter'

export default {
  components: {
    SfStatusModal,
    Lead,
    CallTargetSetting,
    LeadTable,
  },
  mixins: [queryParameter],
  props: {
    callTargets: {
      type: Array,
      required: true,
    },
    callTargetsMeta: {
      type: Object,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'update:selectedCallTargetIds',
  ],
  setup() {
    const { isAvailableSalesforceApiFeature } = useAvailableFeatureCheck()
    const { activeUsers } = useUsers()
    return {
      isAvailableSalesforceApiFeature,
      activeUsers,
    }
  },
  data () {
    return {
      selectedOperatorCallTargetId: null,
      currentOperatorId: null,
      sfRelatedLinkInfo: {},
      leadModalDelegate: {
        shouldClose: this.shouldLeadModalClose,
        beforeClose: this.beforeLeadModalClose,
      },
    }
  },
  computed: {
    ...mapGetters('userUi', [
      'isTargetEditing',
      'currentCallTargetId',
      'isClosingAndMovingLeadEnabled',
    ]),
    ...mapGetters('user', [
      'currentUser',
    ]),
  },
  mounted () {
    const query = this.$route.query
    Object.entries(query).forEach(([key, value]) => {
      if (key === 'callTargetId') {
        this.setCurrentCallTargetIdAction(Number(value))
        this.currentCallTarget = value
        this.$modal.show('target')
      }
    })
    // NOTE: ブラウザバックでモーダルがstate上開いたままとなるので、不要なときは明確に閉じる
    if (!Object.keys(query).includes('callTargetId')) {
      this.$modal.hide('target')
    }
  },
  methods: {
    ...mapWaitingActions('userUi', {
      setTargetMenuVisibleResetAction: 'setTargetMenuVisibleResetWait',
      setTargetHeaderMoveButtonDisabledAction: 'setTargetHeaderMoveButtonDisabledWait',
      setSelectedCallResultAction: 'setSelectedCallResultWait',
      setSelectedOpportunityAction: 'setSelectedOpportunityWait',
      setSelectedNextActionAction: 'setSelectedNextActionWait',
      setSelectedSequenceInstanceIdAction: 'setSelectedSequenceInstanceIdWait',
      setCurrentCallTargetIdAction: 'setCurrentCallTargetIdActionWait',
      clearCurrentCallTargetIdAction: 'clearCurrentCallTargetIdActionWait',
    }),
    ...mapWaitingActions('user', {
      createOperatorAction: 'createOperatorWait',
      withdrawOperatorAction: 'withdrawOperatorWait',
      getCallTargetsBackgroundAction: { action: 'getCallTargetsAction', loader: 'getCallTargetsBackgroundWait' },
    }),
    async handleCloseLeadModalAction() {
      await this.shouldLeadModalClose() && this.closeLeadModal()
    },
    async closeLeadModal () {
      this.$modal.hide('target')
    },
    async shouldLeadModalClose() {
      if (!this.isClosingAndMovingLeadEnabled) {
        return await this.$bitterAlert.show({
          title: this.$t('general.confirm.text'),
          text: this.$t('callTarget.close.isCalling'),
          buttonsConfirm: false,
        })
      }
      if (this.isTargetEditing) {
        return await this.$bitterAlert.show({
          title: this.$t('general.confirm.text'),
          text: this.$t('general.alert.of', { target: this.$t('general.unsavedValue'), action: this.$t('general.clear') }),
        })
      }
      return true
    },
    beforeLeadModalClose() {
      this.setTargetMenuVisibleResetAction()
      this.deleteQueryParameter('callTargetId')
      this.setSelectedCallResultAction({})
      this.setSelectedOpportunityAction({})
      this.setSelectedNextActionAction({})
      this.setSelectedSequenceInstanceIdAction(null)
      this.$refs.callTarget?.closeWidget()
      this.setTargetHeaderMoveButtonDisabledAction(false)
      // FIXME: コレ以降のタイミングでcallTargetIdが使われているらしく、稀にエラーになるのでいったん処理を消す
      // this.clearCurrentCallTargetIdAction()
    },
    openSetOperatorModal (callTargetId, operatorId) {
      this.selectedOperatorCallTargetId = callTargetId
      this.currentOperatorId = operatorId
      this.$modal.show('setOperator')
    },
    sfModalName(sfRelatedLinkInfo) {
      return `sfStatusListModal${sfRelatedLinkInfo.id}`
    },
    openSfModal(sfRelatedLinkInfo) {
      this.sfRelatedLinkInfo = sfRelatedLinkInfo
      this.$modal.show(this.sfModalName(sfRelatedLinkInfo))
    },
    async assignOperator (selectedOperatorId) {
      try {
        await this.createOperatorAction({
          body: {
            operatorId: selectedOperatorId,
            callTargetId: this.selectedOperatorCallTargetId,
          },
        })
        this.$toast.show(this.$t('general.done.of', { action: this.$t('operatorAssign.of', { item: this.$t('home.member') }) }))
      } catch (e) {
        this.$toast.show({
          message: this.$t('general.fail.to', { action: this.$t('operatorAssign.assign') }),
          type: 'error',
        })
      }
      this.getCallList()
    },
    async withdrawOperator () {
      try {
        await this.withdrawOperatorAction(this.selectedOperatorCallTargetId)
        this.$toast.show(this.$t('general.done.of', { action: `${this.$t('operatorAssign.assign')}${this.$t('general.withdraw.text')}` }))
      } catch (e) {
        this.$toast.show({
          message: this.$t('general.fail.to', { action: `${this.$t('operatorAssign.assign')}${this.$t('general.withdraw.text')}` }),
          type: 'error',
        })
      }
      this.getCallList()
    },
    async getCallList () {
      // UX改善のため、データ再読込時に全体ローディングしないようになっている
      // organismsでの処理は、必ず初期データがある前提なので、backgroundのみにしている
      // TODO: データ更新のタイミングなど戦略を決める
      this.$toast.show({ message: this.$t('callList.update.loading'), type: 'info' })
      await this.getCallTargetsBackgroundAction()
      this.$toast.show({ message: this.$t('callList.update.done'), type: 'success' })
    },
    handleSelectedCallTargetIdsUpdate(selectedCallTargetIds) {
      this.$emit('update:selectedCallTargetIds', selectedCallTargetIds);
    },
  },
}
</script>

<style lang="scss" scoped>
  .lead-modal {
    z-index: 11 !important; // Modalコンポーネントの1つ上を指定
  }
</style>

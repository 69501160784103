<template>
  <BCard>
    <BListItem class="mb-400">
      <template #header>
        <span>{{ $t(`client.name`) }}</span>
      </template>
      <BInput
        v-model="internalClient.name"
        v-model:valid="nameValid"
        :rules="[requiredRule]"
        :max-length="255"
        :placeholder="$t('client.placeholder.name')"
      />
    </BListItem>
    <BListItem class="mb-600">
      <template #header>
        <span>{{ $t(`client.slug`) }}</span>
      </template>
      <BInput
        v-model="internalClient.slug"
        v-model:valid="slugValid"
        :rules="[requiredRule, slugRule]"
        :max-length="32"
        :min-length="4"
        :placeholder="$t('client.placeholder.slug')"
        @keypress-enter="save"
      />
    </BListItem>
    <BListItem class="mb-600">
      <template #header>
        <span>{{ $t(`client.expiredAt`) }}</span>
      </template>
      <BInputDateTime
        v-model:date="internalClient.expiredAt"
        v-model:time="internalClient.expiredAt"
        date-format="YYYY/MM/DD"
      />
    </BListItem>
    <BListItem class="mb-600">
      <template #header>
        <span>{{ $t(`client.status.title`) }}</span>
      </template>
      <BSelect
        v-model="internalClient.status"
        :items="clientStatuses"
        item-text="text"
        item-value="value"
        fit
      />
    </BListItem>
    <BLayout
      align-center
      justify-center
    >
      <BBtn
        class="mr-400"
        text
        @click="cancel"
      >
        {{ $t(`general.cancel.text`) }}
      </BBtn>
      <BBtn
        class="ml-400"
        type="primary"
        :loading="loading"
        :disabled="!isValid"
        @click="save"
      >
        {{ $t(`general.save.text`) }}
      </BBtn>
    </BLayout>
  </BCard>
</template>

<script>
import inputValidation from '@/mixins/input_validation'

export default {
  mixins: [inputValidation],
  props: {
    client: {
      type: Object,
      default () {
        return {
          name: '',
          slug: '',
          expiredAt: '',
        }
      },
    },
    loading: Boolean,
  },
  emits: [
    'save',
    'cancel',
  ],
  data () {
    return {
      nameValid: false,
      slugValid: false,
      internalClient: this.client,
    }
  },
  computed: {
    isValid () {
      return this.nameValid && this.slugValid && this.expiredAtValid && this.statusValid
    },
    expiredAtValid () {
      return !!this.internalClient.expiredAt
    },
    statusValid () {
      return !!this.internalClient.status
    },
    clientStatuses () {
      return Object.entries(this.$tm('client.status.enum')).map(([k, v]) => {
        return { value: k, text: v }
      })
    },
  },
  methods: {
    save () {
      if (!this.isValid) return
      const client = {
        name: this.internalClient.name,
        slug: this.internalClient.slug,
        expiredAt: this.internalClient.expiredAt,
        status: this.internalClient.status,
      }
      this.$emit('save', client)
    },
    reset () {
      this.internalClient.name = '',
      this.internalClient.slug = '',
      this.internalClient.expiredAt = ''
      this.internalClient.status = ''
    },
    cancel () {
      this.$emit('cancel')
    },
  },
}
</script>

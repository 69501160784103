import ApiService, { ApiArguments } from '@/api/api_service';
import ApiBase from '@/api/base'

import {
  GetNextActionTypes200Response,
  NextActionTypeApi,
} from '@/api/openapi';
import { NextActionTypesAndDefaultTypes } from '@/api/user/models/next_action_types';

export class NextActionTypeApiService extends ApiService {
  api = new NextActionTypeApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true)
  }

  async getNextActionTypes(
    apiArguments?: ApiArguments<undefined>,
  ): Promise<NextActionTypesAndDefaultTypes> {
    const res = await this.checkResponse<GetNextActionTypes200Response>(
      this.api.getNextActionTypes(apiArguments?.options),
      apiArguments?.errorHandlers,
    );
    return new NextActionTypesAndDefaultTypes(res.data.nextActionTypes.nextActionTypes, res.data.nextActionTypes.defaultNextActionTypes);
  }
}


export default {
  getNextActionTypes() {
    return ApiBase.pollingGet(`/user/next_action_types`)
  },
  createNextActionType(args) {
    return ApiBase.post(`/user/next_action_types`, args)
  },
  deleteNextActionType(args) {
    return ApiBase.delete(`/user/next_action_types/${args.id}`, args)
  },
  updateNextActionType(args) {
    return ApiBase.put(`/user/next_action_types/${args.id}`, args)
  },
}

import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, ref } from 'vue';
import { TMenu } from './connector';

type TProps = {
  menus: TMenu,
  addButtonPosition?: TAddButtonPosition;
  disabledAddButton?: boolean;
  addButtonDisabledTooltip?: string;
  size?: TConnectorSize;
};
type TEmits = {
  'click:addButton': [menuKey: string];
};

type TConnectorSize = 'small' | 'medium';
type TAddButtonPosition = 'top' | 'middle' | 'none';


export default /*@__PURE__*/_defineComponent({
  __name: 'BConnector',
  props: {
    menus: { default: [] },
    addButtonPosition: { default: 'none' },
    disabledAddButton: { type: Boolean, default: false },
    addButtonDisabledTooltip: { default: '' },
    size: { default: 'medium' }
  },
  emits: ["click:addButton"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const isMenuOpen = ref(false);

const needsAddButton = computed(() => props.addButtonPosition !== 'none');
const iconType = computed(() => props.disabledAddButton ? 'gray' : 'default');

const handleAddButtonClick = (menuKey: string) => {
  if (props.disabledAddButton) return;
  emit('click:addButton', menuKey);
  isMenuOpen.value = false;
};

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BEmptyBox = _resolveComponent("BEmptyBox")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BMoreMenu = _resolveComponent("BMoreMenu")!
  const _component_BPopover = _resolveComponent("BPopover")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["b-connector-wrap", _ctx.size])
  }, [
    _createVNode(_component_BPopover, {
      placement: "top",
      width: "240",
      trigger: "hover",
      disabled: !_ctx.disabledAddButton,
      content: _ctx.addButtonDisabledTooltip
    }, {
      reference: _withCtx(() => [
        (needsAddButton.value)
          ? (_openBlock(), _createBlock(_component_BMoreMenu, {
              key: 0,
              modelValue: isMenuOpen.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isMenuOpen).value = $event)),
              placement: "right-start"
            }, {
              activator: _withCtx(() => [
                _createVNode(_component_BEmptyBox, {
                  class: _normalizeClass(["add-button-container", _ctx.addButtonPosition]),
                  "display-only": _ctx.disabledAddButton
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BIcon, {
                      size: "large",
                      class: "add",
                      type: iconType.value
                    }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode(" add ")
                      ])),
                      _: 1
                    }, 8, ["type"])
                  ]),
                  _: 1
                }, 8, ["class", "display-only"])
              ]),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu) => {
                  return (_openBlock(), _createBlock(_component_BBtn, {
                    key: menu.key,
                    flat: "",
                    fit: "",
                    size: "small",
                    onClick: () => handleAddButtonClick(menu.key)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(menu.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["disabled", "content"])
  ], 2))
}
}

})
import { types } from './mutations'

export default {
  setCurrentStateAction ({ commit }, args) {
    const { currentWidgetStatus } = args
    commit(types.SET_CURRENT_STATE, { currentWidgetStatus })
  },
  setAuthInfoAction ({ commit }, args) {
    const { companyId, accessKey } = args
    commit(types.SET_AUTH_INFO, { companyId, accessKey })
  },
  setIsCallingAction ({ commit }, args) {
    const e = args
    commit(types.SET_IS_CALLING, e)
  },
  setIsInboundAction ({ commit }, args) {
    const isInbound = args
    commit(types.SET_IS_INBOUND, isInbound)
  },
  setStartedAtAction ({ commit }, args) {
    const startedAt = args
    commit(types.SET_STARTED_AT, startedAt)
  },
  setEndedAtAction ({ commit }, args) {
    const endedAt = args
    commit(types.SET_ENDED_AT, endedAt)
  },
  setUrlAction ({ commit }, args) {
    const url = args
    commit(types.SET_URL, url)
  },
  setConnectedAction ({ commit }, args) {
    const connected = args
    commit(types.SET_CONNECTED, connected)
  },
  setCallInfoResetAction ({ commit }) {
    commit(types.SET_CALL_INFO_RESET)
  },
}

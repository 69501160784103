import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "draft-reminder-header" }
const _hoisted_2 = { class: "draft-reminder-header-item header-title" }
const _hoisted_3 = { class: "draft-reminder-header-item number-of-items" }
const _hoisted_4 = { class: "draft-reminder-header-item" }
const _hoisted_5 = { class: "draft-reminder-body" }

import { computed, onBeforeMount, ref } from 'vue'
import { CallTargetApiGetMailRemindItemsRequest, MailRemindItem, User } from '@/api/openapi';
import apis from '@/api/user';
import { CallTargetApiService } from '@/api/user/resources/call_target';
import { useUsers } from '@/composable/user/user/users';
import { i18nGlobal as i18n } from '@/i18n';
import { TOptions } from '@/plugins/biscuet-materials/components/atoms/BSelect.vue';

const VALUE_ALL = 'all';


export default /*@__PURE__*/_defineComponent({
  __name: 'DraftMailReminder',
  setup(__props) {

const mailRemindItems = ref<MailRemindItem[]>([]);
const selectedUserId = ref<number | string>();

const { activeUsers, fetchUsers } = useUsers()

const handleAfterOpen = () => {
  fetchMasterData();
}

onBeforeMount(async () => {
  await fetchCurrentUser();
  await fetchMasterData();
})

const userItems = computed(() => {
  const userItems: TOptions = [{ text: i18n.t('draftMailReminder.allOwner'), value: VALUE_ALL }]
  return userItems.concat(
    activeUsers.value.map((candidate) => {
      return { text: candidate.name, value: candidate.id }
    }),
  )
})

const handleSelectedUserChange = () => {
  fetchMailReminder()
}

const fetchMailReminder = async () => {
  const userId = selectedUserId.value === VALUE_ALL ? undefined : selectedUserId.value as undefined | number;
  const draft = true;
  const request: CallTargetApiGetMailRemindItemsRequest = {
    userId,
    draft,
  }
  const callTargetApi = new CallTargetApiService()
  mailRemindItems.value = (await callTargetApi.getMailRemindItems({ request })).data.mailRemindItems
}

const fetchCurrentUser = async () => {
  const res = await apis.getCurrentUser();
  selectedUserId.value = res.data.currentUser.id;
};

const fetchMasterData = async () => {
  await fetchUsers();
  await fetchMailReminder();
}

return (_ctx: any,_cache: any) => {
  const _component_BCustomIcon = _resolveComponent("BCustomIcon")!
  const _component_BSelect = _resolveComponent("BSelect")!
  const _component_DraftMailReminderTimeline = _resolveComponent("DraftMailReminderTimeline")!
  const _component_BIconWithActionMenu = _resolveComponent("BIconWithActionMenu")!

  return (_openBlock(), _createBlock(_component_BIconWithActionMenu, {
    "badge-count": mailRemindItems.value.length,
    "auto-fetch-interval": 30000,
    onAutoFetchIntervalPassed: fetchMailReminder,
    onAfterOpen: handleAfterOpen
  }, {
    icon: _withCtx(() => [
      _createVNode(_component_BCustomIcon, { "icon-class": "b-mail-draft" })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('draftMailReminder.title')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(mailRemindItems.value.length) + "件", 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_BSelect, {
            modelValue: selectedUserId.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedUserId).value = $event)),
            class: "type-select",
            items: userItems.value,
            "item-text": "text",
            "item-value": "value",
            filterable: "",
            teleported: false,
            onChange: handleSelectedUserChange
          }, null, 8, ["modelValue", "items"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_DraftMailReminderTimeline, { items: mailRemindItems.value }, null, 8, ["items"])
      ])
    ]),
    _: 1
  }, 8, ["badge-count"]))
}
}

})
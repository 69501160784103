import ApiBase from '@/api/base'

export default {
  getCustomFields (args) {
    return ApiBase.get(`/user/custom_fields`)
  },
  createCustomField (args) {
    return ApiBase.post(`/user/custom_fields`, args)
  },
  deleteCustomField (args) {
    const { customFieldId } = args
    return ApiBase.delete(`/user/custom_fields/${customFieldId}`, args)
  },
  // 単体での更新
  updateCustomField (args) {
    const { customFieldId } = args
    return ApiBase.put(`/user/custom_fields/${customFieldId}`, args)
  },
  // 一括更新
  updateCustomFields (args) {
    return ApiBase.put(`/user/custom_fields`, args)
  },
  deleteCustomFieldOption (args) {
    const { customFieldOptionId } = args
    return ApiBase.delete(`/user/custom_field_options/${customFieldOptionId}`, args)
  },
}

<template>
  <BEmptyBox
    class="import-area"
    :class="{ dragged: isDragged }"
    @dragover.prevent="isDragged = true"
    @dragleave.prevent="isDragged = false"
  >
    <template v-if="disabled">
      <div class="input-file" />
      <div class="message">
        <span>{{ $t(`callList.upload.fileAreaMessage`) }}</span>
      </div>
    </template>
    <template v-else>
      <input
        id="input-file"
        class="input-file"
        type="file"
        :accept="acceptDataType"
        @change="handleInputFile"
      >
      <div class="message">
        <span v-if="!fileName">{{ $t(`callList.upload.fileAreaMessage`) }}</span>
        <div
          v-if="acceptDataType === '.xlsx'"
          class="caution"
        >
          <div>{{ fileName }}</div>
          <div>
            {{ $t(`import.excel.fileAreaCautionMessage`) }}
          </div>
        </div>
        <span v-else>{{ fileName }}</span>
      </div>
    </template>
  </BEmptyBox>
</template>

<script>
export default {
  props: {
    acceptDataType: {
      type: String,
      default: '.csv',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'upload',
  ],
  data () {
    return {
      isDragged: false,
      fileName: null,
    }
  },
  methods: {
    // MEMO: input要素にdragdropするとfile選択状態になる(@changeが発火)
    handleInputFile (event) {
      const file = event.target.files[0]
      this.fileName = file.name
      this.$emit('upload', file)
    },
    clear () {
      this.fileName = null
      document.getElementById('input-file').value = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.import-area {
  padding: 60px 0;
  position: relative;

  &.dragged {
    background-color: $bgcolor-dark;
  }

  .input-file {
    width: 100%;
    height: 100%;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    opacity: 0;
  }

  .message {
    padding: $basespace-600 0;
    text-align: center;

    .caution {
      display: block;
      color: $basecolor-primary;
    }
  }
}
</style>

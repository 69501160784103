<template>
  <div class="sequence-step-container">
    <div class="summary-container">
      <div class="total-step item">
        <div class="value">
          {{ totalSteps }}
        </div>
        <div class="unit">
          {{ $t('sequence.step.title') }}
        </div>
      </div>
      <div class="total-day item">
        <div class="value">
          {{ totalDays }}
        </div>
        <div class="unit">
          {{ $t('sequence.daysToComplete') }}
        </div>
      </div>
      <div class="automation-rate item">
        <div class="value">
          {{ automationRate + '%' }}
        </div>
        <div class="unit">
          {{ $t('sequence.automationRate') }}
        </div>
      </div>
    </div>
    <div class="steps-container">
      <div class="scrollable-container">
        <div class="scrollable-container--inner">
          <SequenceStepWithConnector
            v-for="(sequenceStep, index) in withDaysSequenceSteps"
            :key="sequenceStep.sortOrder"
            v-model:is-menu-open="internalIsMenuOpens[index]"
            :sequence-step="sequenceStep"
            :next-action-type="findNextActionType(sequenceStep.actionType)"
            :actions="stepsActionsWithDisabled[index]"
            :needs-add-button="enabledToAddSteps"
            :disabled-add-button="disabledAddSequenceStep"
            v-on="stepHandlers[index]"
          />
          <div class="b-connector">
            <BConnector
              v-if="enabledAppending"
              size="small"
              add-button-position="top"
              :menus="menus"
              @click:add-button="handleAddFirstStepClick"
            />
          </div>
        </div>
        <div class="step-end">
          <SequenceStepEnd />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import SequenceStepEnd from '@/components/organisms/user/general/sequence/SequenceStepEnd.vue'
import SequenceStepWithConnector from '@/components/organisms/user/general/sequence/SequenceStepWithConnector.vue';
import { useNextActionTypes } from '@/composable/user/nextActionType/next-action-types';
import { isAutomatic } from '@/composable/user/sequence/post-put-body';
import { useWithDaysSequenceSteps } from '@/composable/user/sequence/steps-computed-property';
import { TMenu } from '@/plugins/biscuet-materials/components/molecules/connector';
import { TActionWithDisabled, TFormSequenceStep, TStepEventType, TStepType } from './types';

type TProps = {
  isMenuOpens: boolean[];
  sequenceSteps?: TFormSequenceStep[];
  enabledToAddSteps: boolean;
  stepsActionsWithDisabled: TActionWithDisabled[][];
};

const props = withDefaults(defineProps<TProps>(), {
  sequenceSteps: () => [],
});
const emit = defineEmits<{
  (e: 'update', sequenceSteps: TFormSequenceStep[]): void;
  (e: 'update:isMenuOpens', isMenuOpens: boolean[]): void;
  (e: 'click:addFirstStepDirectMail'): void;
  (e: 'click:addFirstStepNextAction'): void;
  (e: TStepEventType, index: number): void;
}>();

const i18n = useI18n();
const menus: TMenu<TStepType> = [
  { key: 'next_action', label: i18n.t('sequence.step.types.nextAction') },
  { key: 'direct_mail', label: `${i18n.t('sequence.step.types.directMail')} - ${i18n.t('mail.reserve')}` },
];

const MAX_NUMBER_OF_STEPS = 20;

const { findNextActionType } = useNextActionTypes();

const totalSteps = computed(() => {
  return props.sequenceSteps.filter((step) => hasDateIntervalStep(step)).length;
});
const totalDays = computed(() => {
  const totalSequenceStepDays = props.sequenceSteps
    .filter((step) => hasDateIntervalStep(step))
    .reduce((sum, e) => sum + e.dateInterval, 0);
  // NOTE: 1日目から開始
  return totalSequenceStepDays ? totalSequenceStepDays + 1 : 0;
});
const internalIsMenuOpens = computed({
  get() {
    return props.isMenuOpens;
  },
  set(newValue) {
    emit('update:isMenuOpens', newValue);
  },
});

const automationRate = computed(() => {
  const aggregated = props.sequenceSteps.reduce((aggregated, e) => {
    if (e.status === 'skipped') return aggregated
    aggregated.total += 1
    if (isAutomatic(e)) aggregated.automatic += 1
    return aggregated
  }, { total: 0, automatic: 0 })
  return Math.floor((aggregated.automatic / aggregated.total || 0) * 100);
});

const { withDaysSequenceSteps } = useWithDaysSequenceSteps<TFormSequenceStep>(() => props.sequenceSteps);

const stepHandlers = computed(() =>
  props.stepsActionsWithDisabled.map((stepActions, index) => getStepHandlers(stepActions, index)),
);
/**
 * NOTE: 任意のイベントをhandle＆発火
 * @examples {'click:detail': () => emit('click:detail', 0), ...}
 */
const getStepHandlers = (stepActions: TActionWithDisabled[], index: number): Partial<Record<TStepEventType, () => void>> => {
  // stepカードのmenuで発火するイベント
  const stepCardEvents: TStepEventType[] = stepActions
    .filter(({ disabled }) => !disabled)
    .map(({ event }) => event);
  // connectorの追加ボタンで発火するイベント
  const stepAddEvents: TStepEventType[] = props.enabledToAddSteps ? ['click:insertNextAction', 'click:insertDirectMail'] : [];

  const events: TStepEventType[] = [
    ...stepCardEvents,
    ...stepAddEvents,
  ];
  return Object.fromEntries(
    events
      .map((stepEvent) => 
        [stepEvent, () => emit(stepEvent, index)],
      ),
  );
};

const disabledAddSequenceStep = computed(() => !props.enabledToAddSteps || props.sequenceSteps.length >= MAX_NUMBER_OF_STEPS);
const enabledAppending = computed(() => withDaysSequenceSteps.value.length <= 0 && props.enabledToAddSteps);

const handleAddFirstStepClick = (stepType: TStepType) => {
  if (!enabledAppending.value) return;

  stepType === 'direct_mail' ? emit('click:addFirstStepDirectMail') : emit('click:addFirstStepNextAction');
};

const hasDateIntervalStep = (step: TFormSequenceStep) => {
  return step.status !== 'skipped' && !step.immediately;
};
</script>

<style lang="scss" scoped>
.sequence-step-container {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  .summary-container {
    display: flex;
    flex-direction: row;
    padding: 14px 7px;
    border-bottom: 1px solid $bdcolor-base;

    .item {
      padding: 0 24px;
      text-align: center;
      font-weight: bold;

      .value {
        font-size: $fontsize-500;
      }

      .unit {
        margin-top: 4px;
        font-size: $fontsize-100;
      }

      &:not(:last-child) {
        border-right: 1px solid $bdcolor-base;
      }
    }
  }
  .steps-container {
    position: relative;
    flex: 1;
    height: calc(100% - 74px);
    .scrollable-container {
      padding: 40px 0;
      height: 100%;
      overflow-y: auto;

      &--inner {
        max-width: 80%;
        @media only screen and (min-width: 959px) {
          width: 60%;
        }
        margin-left: auto;
        margin-right: auto;
      }
    }
    .step-end {
      text-align: center;
    }
    .b-connector {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>

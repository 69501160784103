<template>
  <BCard>
    <BListItem class="mb-400">
      <template #header>
        <span>{{ $t(`userManagement.name`) }}</span>
      </template>
      <BInput
        v-model="inputName"
        v-model:valid="nameValid"
        :rules="[requiredRule]"
        :max-length="255"
        :placeholder="$t('userManagement.name')"
      />
    </BListItem>
    <BListItem class="mb-400">
      <template #header>
        <span>{{ $t(`userManagement.lastName`) }}</span>
      </template>
      <BInput
        v-model="inputLastName"
        v-model:valid="lastNameValid"
        :rules="[requiredRule]"
        :max-length="255"
        :placeholder="$t('userManagement.lastName')"
      />
    </BListItem>
    <BListItem class="mb-400">
      <template #header>
        <span>{{ $t(`userManagement.firstName`) }}</span>
      </template>
      <BInput
        v-model="inputFirstName"
        v-model:valid="firstNameValid"
        :rules="[requiredRule]"
        :max-length="255"
        :placeholder="$t('userManagement.firstName')"
      />
    </BListItem>
    <BListItem class="mb-600">
      <template #header>
        <span>{{ $t(`userManagement.email`) }}</span>
      </template>
      <BInput
        v-model="inputEmail"
        v-model:valid="emailValid"
        :rules="[requiredRule, emailRule]"
        :max-length="255"
        :placeholder="$t('userManagement.email')"
        @keypress-enter="save"
      />
    </BListItem>
    <BLayout
      align-center
      justify-center
    >
      <BBtn
        class="mr-400"
        text
        @click="cancel"
      >
        {{ $t(`general.close.text`) }}
      </BBtn>
      <BBtn
        class="ml-400"
        type="primary"
        :loading="loading"
        :disabled="!isValid"
        @click="save"
      >
        {{ $t(`general.invite`) }}
      </BBtn>
    </BLayout>
  </BCard>
</template>

<script>
import inputValidation from '@/mixins/input_validation'

export default {
  name: 'InviteForm',
  mixins: [inputValidation],
  props: {
    name: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
    firstName: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    loading: Boolean,
  },
  emits: [
    'save',
    'cancel',
  ],
  data () {
    return {
      nameValid: false,
      lastNameValid: false,
      firstNameValid: false,
      emailValid: false,
      inputName: '',
      inputLastName: '',
      inputFirstName: '',
      inputEmail: '',
    }
  },
  computed: {
    isValid () {
      return this.nameValid && this.lastNameValid && this.firstNameValid && this.emailValid
    },
  },
  watch: {
    name: {
      immediate: true,
      handler (newVal) {
        this.inputName = newVal
      },
    },
    lastName: {
      immediate: true,
      handler (newVal) {
        this.inputLastName = newVal
      },
    },
    firstName: {
      immediate: true,
      handler (newVal) {
        this.inputFirstName = newVal
      },
    },
    email: {
      immediate: true,
      handler (newVal) {
        this.inputEmail = newVal
      },
    },
  },
  methods: {
    save () {
      if (!this.isValid) return

      const staff = {
        name: this.inputName,
        lastName: this.inputLastName,
        firstName: this.inputFirstName,
        email: this.inputEmail,
      }
      this.$emit('save', staff)
    },
    cancel () {
      this.$emit('cancel')
    },
    reset () {
      this.inputName = ''
      this.inputLastName = ''
      this.inputFirstName = ''
      this.inputEmail = ''
    },
  },
}
</script>

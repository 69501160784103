import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "table-row" }
const _hoisted_3 = { class: "body" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "mb-50" }

import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n'
import { QuickText, QuickTextStatus } from '@/api/openapi';
import { QuickTextApiService } from '@/api/user/resources/quick_text';
import { useWait } from '@/composable/vue-wait';
import { formatShorterDateTime } from '@/utils/date-time';

type TProps = {
  callTargetId: number;
};
type TEmits = {
  select: [content: string];
};

export default /*@__PURE__*/_defineComponent({
  __name: 'QuickTextSelectTable',
  props: {
    callTargetId: {}
  },
  emits: ["select"],
  setup(__props: any, { emit: __emit }) {

// NOTE: 現状クイックテキストの上限数が１０個固定であるため、ページングは実装していない。上限が増えるようなら、ページングや無限スクロールなどの実装が必要になる。
const props = __props;
const emit = __emit;

const i18n = useI18n()

const quickTexts = ref<QuickText[]>([]);

const api = new QuickTextApiService();
const { doActionWithWait, wait } = useWait();
const loadQuickTexts = async () => {
  const currentPage = 1;
  const statuses: QuickTextStatus[] = ['published'];
  const sourceCallTargetId = props.callTargetId;
  doActionWithWait('getQuickTextsWait', async () => {
    const { data } = await api.getQuickTexts({ request: { currentPage, statuses, sourceCallTargetId } });
    quickTexts.value = data.quickTexts;
  });
};
loadQuickTexts();

const columns = [
  { key: 'name', label: i18n.t('quickText.name') },
  { key: 'createdAt', label: i18n.t('general.createdAt') },
  { key: 'updatedAt', label: i18n.t('general.updatedAt') },
  { key: 'createdUser', label: i18n.t('general.createdUser') },
  { key: 'updatedUser', label: i18n.t('general.updatedUser') },
];

const loading = computed(() => {
  return wait.is('getQuickTextsWait');
});

return (_ctx: any,_cache: any) => {
  const _component_BEmptyBox = _resolveComponent("BEmptyBox")!
  const _component_BStatus = _resolveComponent("BStatus")!
  const _component_BUserTooltip = _resolveComponent("BUserTooltip")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(columns, (column) => {
          return _createElementVNode("div", {
            key: column.key,
            class: _normalizeClass(["table-cell", column.key])
          }, _toDisplayString(column.label), 3)
        }), 64))
      ])
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
      (!quickTexts.value.length)
        ? (_openBlock(), _createBlock(_component_BEmptyBox, {
            key: 0,
            class: "py-400 mt-400 mx-400",
            "display-only": ""
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "text", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.$t('apiError.notFound')), 1)
              ])
            ]),
            _: 3
          }))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(quickTexts.value, (quickText) => {
            return (_openBlock(), _createElementBlock("div", {
              key: quickText.id,
              class: "table-row",
              onClick: () => emit('select', quickText.content)
            }, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(columns, (column) => {
                return _createElementVNode("div", {
                  key: column.key,
                  class: _normalizeClass(["table-cell", column.key])
                }, [
                  (column.key === 'name')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("div", _hoisted_5, _toDisplayString(quickText.name), 1),
                        _createVNode(_component_BStatus, {
                          status: quickText.status,
                          "status-name": _ctx.$t(`general.status.${quickText.status}`)
                        }, null, 8, ["status", "status-name"])
                      ], 64))
                    : (['createdAt', 'updatedAt'].includes(column.key))
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(_unref(formatShorterDateTime)(quickText[column.key])), 1)
                        ], 64))
                      : (['createdUser', 'updatedUser'].includes(column.key))
                        ? (_openBlock(), _createBlock(_component_BUserTooltip, {
                            key: 2,
                            "image-url": quickText[column.key].avatarUrl,
                            name: quickText[column.key].name,
                            "user-id": quickText[column.key].id,
                            size: 40
                          }, null, 8, ["image-url", "name", "user-id"]))
                        : _createCommentVNode("", true)
                ], 2)
              }), 64))
            ], 8, _hoisted_4))
          }), 128))
    ])), [
      [_directive_loading, loading.value]
    ])
  ], 64))
}
}

})
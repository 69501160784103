import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fs-400"
}
const _hoisted_2 = {
  key: 1,
  class: "fs-400"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BCustomIcon = _resolveComponent("BCustomIcon")!
  const _component_VWait = _resolveComponent("VWait")!
  const _component_BCard = _resolveComponent("BCard")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_BLayout, {
    class: "page-wrapper",
    "align-center": "",
    "justify-center": "",
    "fill-height": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BCard, null, {
        default: _withCtx(() => [
          _createVNode(_component_BLayout, { "justify-center": "" }, {
            default: _withCtx(() => [
              (_ctx.isWrongUnsubToken)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('unsubscribe.wrongUrl')), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('unsubscribe.title')), 1))
            ]),
            _: 1
          }),
          _createVNode(_component_VWait, { for: "checkUnsubscribeWait" }, {
            waiting: _withCtx(() => [
              _withDirectives(_createVNode(_component_BPlaceholder, {
                height: "20vh",
                "element-loading-background": "transparent"
              }, null, 512), [
                [_directive_loading, true]
              ])
            ]),
            default: _withCtx(() => [
              (_ctx.isWrongUnsubToken)
                ? (_openBlock(), _createBlock(_component_BLayout, {
                    key: 0,
                    class: "my-600",
                    "align-center": "",
                    "justify-center": "",
                    column: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BIcon, {
                        size: "super-extra-large",
                        type: "error"
                      }, {
                        default: _withCtx(() => _cache[0] || (_cache[0] = [
                          _createTextVNode(" clear ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_BListItem, { class: "message mb-600" }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('unsubscribe.wrongUrlMessage')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_BLayout, {
                        "align-center": "",
                        "justify-center": ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_BBtn, {
                            class: "footer-btn",
                            type: "default",
                            loading: _ctx.$wait.is('unsubscribeWait'),
                            onClick: _withModifiers(_ctx.closeWindow, ["prevent"])
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.close.text')), 1)
                            ]),
                            _: 1
                          }, 8, ["loading", "onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : (_ctx.isUnsubscribed)
                  ? (_openBlock(), _createBlock(_component_BLayout, {
                      key: 1,
                      class: "my-600",
                      "align-center": "",
                      "justify-center": "",
                      column: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BIcon, {
                          size: "super-extra-large",
                          type: "success"
                        }, {
                          default: _withCtx(() => _cache[1] || (_cache[1] = [
                            _createTextVNode(" check ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_BListItem, { class: "message mb-600" }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('unsubscribe.unsubscribed')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_BLayout, {
                          "align-center": "",
                          "justify-center": ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BBtn, {
                              class: "footer-btn",
                              type: "default",
                              loading: _ctx.$wait.is('unsubscribeWait'),
                              onClick: _withModifiers(_ctx.closeWindow, ["prevent"])
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.close.text')), 1)
                              ]),
                              _: 1
                            }, 8, ["loading", "onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_BLayout, {
                      key: 2,
                      class: "my-600",
                      "align-center": "",
                      "justify-center": "",
                      column: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BCustomIcon, {
                          "icon-class": "b-mail-block",
                          size: "super-extra-large"
                        }),
                        _createVNode(_component_BListItem, { class: "message mb-600" }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('unsubscribe.unsubscribeMessage')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_BLayout, {
                          "align-center": "",
                          "justify-center": "",
                          wrap: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BBtn, {
                              class: "footer-btn",
                              type: "default",
                              loading: _ctx.$wait.is('unsubscribeWait'),
                              onClick: _withModifiers(_ctx.closeWindow, ["prevent"])
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.cancel.text')), 1)
                              ]),
                              _: 1
                            }, 8, ["loading", "onClick"]),
                            _createVNode(_component_BBtn, {
                              class: "footer-btn",
                              type: "primary",
                              loading: _ctx.$wait.is('unsubscribeWait'),
                              onClick: _withModifiers(_ctx.unsubscribe, ["prevent"])
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.submit')), 1)
                              ]),
                              _: 1
                            }, 8, ["loading", "onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
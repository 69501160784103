import Waiting from '@/store/waiting'

export default class NextActionTypeActions {
  constructor (Api, types) {
    const actions = {
      async getNextActionTypesAction ({ commit }, args) {
        await waiting.check('getNextActionTypesAction', async () => {
          try {
            const res = await Api.getNextActionTypes(args)
            const resData = res.data.nextActionTypes
            commit(types.SET_NEXT_ACTION_TYPES, resData.nextActionTypes)
            commit(types.SET_DEFAULT_NEXT_ACTION_TYPES, resData.defaultNextActionTypes)
          } catch (err) {
            throw err
          }
        })
      },
      async createNextActionTypeAction ({ commit }, args) {
        await waiting.check('createNextActionTypeAction', async () => {
          await Api.createNextActionType(args)
          try {
            const res = await Api.getNextActionTypes(args)
            const resData = res.data.nextActionTypes
            commit(types.SET_NEXT_ACTION_TYPES, resData.nextActionTypes)
            commit(types.SET_DEFAULT_NEXT_ACTION_TYPES, resData.defaultNextActionTypes)
          } catch (err) {
            throw err
          }
        })
      },
      async updateNextActionTypeAction ({ commit }, args) {
        const { id, name } = args
        await waiting.check('updateNextActionTypeAction', async () => {
          await Api.updateNextActionType({ id, body: { name } })
          try {
            const res = await Api.getNextActionTypes(args)
            const resData = res.data.nextActionTypes
            commit(types.SET_NEXT_ACTION_TYPES, resData.nextActionTypes)
            commit(types.SET_DEFAULT_NEXT_ACTION_TYPES, resData.defaultNextActionTypes)
          } catch (err) {
            throw err
          }
        })
      },
      async deleteNextActionTypeAction ({ commit }, args) {
        await waiting.check('deleteNextActionTypeAction', async () => {
          await Api.deleteNextActionType(args)
          try {
            const res = await Api.getNextActionTypes(args)
            const resData = res.data.nextActionTypes
            commit(types.SET_NEXT_ACTION_TYPES, resData.nextActionTypes)
            commit(types.SET_DEFAULT_NEXT_ACTION_TYPES, resData.defaultNextActionTypes)
          } catch (err) {
            throw err
          }
        })
      },
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

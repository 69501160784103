<template>
  <div class="lead-import-comfirm">
    <div class="import-message mb-400">
      <p>{{ $t('import.confirm.importMessage') }}</p>
    </div>
    <BPopover
      v-if="invalidHeaders.length !== 0"
      placement="bottom"
      width="600"
      trigger="hover"
    >
      <template #reference>
        <div class="comfirm-invalid-message mb-300">
          <BIcon
            class="mr-50"
            size="small"
            type="error"
          >
            warning
          </BIcon><span>{{ `${$t('import.confirm.invalid')}（${invalidHeaders.length}）` }}</span>
        </div>
      </template>
      <div>
        <div class="invalid-list-header">
          <div class="invalid-list-item-column">
            {{ $t('import.confirm.rowName') }}
          </div>
          <div>{{ $t('import.confirm.itemName') }}</div>
        </div>
        <div class="invalid-list-body">
          <div
            v-for="(header, i) in invalidHeaders.slice(0, 99)"
            :key="`invalidHeader-${i}`"
            class="invalid-list-item"
          >
            <div class="invalid-list-item-column">
              {{ `${header.column_number + 1}/${toExcelLikeColumnName(header.column_number + 1)}` }}
            </div>
            <div>{{ `「${header.text}」` }}</div>
          </div>
          <div
            v-if="invalidHeaders.length >= 100"
            class="invalid-list-item-more"
            size="small"
          >
            <BIcon size="small">
              more_vert
            </BIcon>
          </div>
        </div>
      </div>
    </BPopover>
    <div class="lead-import-comfirm-header">
      <span>{{ `${$t('import.confirm.targetCount')}${importCount}` }}</span>
    </div>
    <div class="lead-import-comfirm-body">
      <ElTable
        :data="newCandidateTargetsObjects"
        size="small"
        :row-class-name="unImportRowClass"
      >
        <ElTableColumn type="expand">
          <template #default="props">
            <p
              v-for="[key, val] in getEntries(props.row)"
              :key="`newCandidateInfo-${key}`"
            >
              <span class="strong">{{ findNewTargetsHeaderLabel(key) }}：</span>{{ val }}
            </p>
          </template>
        </ElTableColumn>
        <ElTableColumn
          v-for="(header, i) in newTargetHeaders"
          :key="`newTargetHeader-${i}`"
          :label="header.label"
          :prop="header.prop"
        />
        <ElTableColumn :label="$t('import.confirm.importCheckItem')">
          <template #default="props">
            <BIcon
              v-if="checkUnImportRow(props.row)"
              size="small"
            >
              close
            </BIcon>
            <BIcon
              v-else
              size="small"
            >
              check
            </BIcon>
          </template>
        </ElTableColumn>
      </ElTable>
    </div>
    <BLayout
      class="my-300"
      justify-start
    >
      <span>{{ $t('import.confirm.message') }}</span>
    </BLayout>
  </div>
</template>

<script>
export default {
  name: 'LeadImportConfirm',
  props: {
    invalidHeaders: Array, // e.g. [{text: "システム付与情報  全体ID", column_number: 1}]
    newCandidateTargets: Object,
    importCount: Number,
  },
  computed: {
    newTargetHeaders() {
      return [
        { label: this.$t('import.columns.call_target_company_name'), prop: 'call_target_company_name' },
        { label: this.$t('import.columns.call_target_company_tel_1'), prop: 'call_target_company_tel_1' },
        { label: this.$t('import.columns.call_target_email'), prop: 'call_target_email' },
        { label: this.$t('import.columns.call_target_last_name'), prop: 'call_target_last_name' },
        { label: this.$t('import.columns.call_target_first_name'), prop: 'call_target_first_name' },
      ]
    },
    newCandidateTargetsObjects() {
      return this.newCandidateTargets.records
        .map((record) => {
          return this.candidateTargetObjectToHash(record)
        })
        .filter((item) => Object.keys(item).length > 0)
    },
  },
  methods: {
    checkUnImportRow(row){
      return row.call_target_company_name == null
             || row.call_target_lead_stage_lead_stage_id == null
    },
    unImportRowClass({ row, _row_index }) {
      if (this.checkUnImportRow(row)) return 'disable';
      return ''
    },
    findNewTargetsHeaderLabel(value) {
      const h = this.newCandidateTargets.headers.find((header) => {
        return header.value === 'custom_field'
          ? `${header.value}_${header.column_number}` === value
          : header.value === value
      })
      return h ? h.text : null
    },
    candidateTargetObjectToHash(record) {
      return this.newCandidateTargets.headers.reduce((acc, header) => {
        if (header.value === 'custom_field') {
          acc[`${header.value}_${header.column_number}`] = record[header.column_number]
        } else {
          acc[header.value] = record[header.column_number]
        }
        return acc
      }, {})
    },
    toExcelLikeColumnName(num) {
      let ret = ''
      for (let a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
        ret = String.fromCharCode(parseInt(num % b / a) + 65) + ret
      }
      return ret
    },
    getEntries(row) {
      const result = []
      for (const [key, value] of Object.entries(row)) {
        if (value) result.push([key, value])
      }
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
.import-message {
  text-align: center;
  color: $textcolor-light;
  background-color: $bgcolor-base;
  padding: $basespace-200 $basespace-200 1px $basespace-200;
  border-radius: 4px;
}

.lead-import-comfirm {
  height: 100%;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: $basespace-300;
  }

  .search-table-input {
    width: 200px;
  }
}

.comfirm-invalid-message {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $basecolor-error;
  font-size: $fontsize-200;
  padding: $basespace-200 $basespace-600;
  background-color: rgba($basecolor-error, 0.1);
  border-radius: 4px;
}

.invalid-list-header {
  display: flex;
  align-items: center;
  font-size: $fontsize-100;
  color: $textcolor-light;
  border-bottom: 1px solid $bdcolor-light;
  padding-bottom: $basespace-100;
}

.invalid-list-body {
  max-height: 400px;
  overflow-y: auto;
  padding: $basespace-200 0;
}

.invalid-list-item {
  display: flex;
  align-items: center;
  padding: $basespace-50 0;

  &-column {
    @include m-fixed-width(70px);
  }
}

.invalid-list-item-more {
  width: 100%;
  text-align: center;
  padding: $basespace-200 0;
}

.header-column-number {
  @include m-fixed-width(100px);
  display: inline-block;
}
</style>

import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import {
  MailSignatureApi,
  MailSignature,
  MailSignatureApiPutMailSignatureMeRequest,
} from '@/api/openapi';

export class MailSignatureApiService extends ApiService {
  api = new MailSignatureApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true)
  }

  async getMailSignatureMe(
    apiArguments?: ApiArguments<undefined>,
  ): Promise<AxiosResponse<MailSignature>> {
    return this.checkResponse<MailSignature>(
      this.api.getMailSignatureMe(apiArguments?.options),
      apiArguments?.errorHandlers,
    );
  }

  async putMailSignatureMe({
    request,
    options,
    errorHandlers,
  }: ApiArguments<MailSignatureApiPutMailSignatureMeRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.putMailSignatureMe(request, options),
      errorHandlers,
    );
  }
}

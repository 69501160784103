import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { computed, inject, onUnmounted, ref, watch } from 'vue';
import { KEY_OF_MODAL } from '@/injection-keys';
import { TModalAction } from './index';

export type TBmodalDelegate = {
  shouldClose?: () => boolean | Promise<boolean>;
  beforeClose?: () => void; // NOTE: 時間差がない方がいいことが多いと思われるので、EventでなくDelegateにしている
};

type TProps = {
  modalName: string;
  width?: string
  height?: string
  full?: boolean
  delegate?: TBmodalDelegate
  scroll?: boolean
};

export default /*@__PURE__*/_defineComponent({
  __name: 'BModal',
  props: {
    modalName: {},
    width: { default: '100%' },
    height: { default: '100%' },
    full: { type: Boolean, default: false },
    delegate: { default: null },
    scroll: { type: Boolean }
  },
  emits: ['openModalEvent'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const fullStyles = {
  'width': '100%',
  'height': '100%',
  'max-width': '100%',
  'max-height': '100%',
  'border-radius': '0px',
};

const modalAction = inject<TModalAction>('modalAction');
const modal = inject(KEY_OF_MODAL);

const innerIsOpen = ref(false);

const isOpen = computed({
  get: () => innerIsOpen.value,
  set: (newValue) => {
    // モーダル表示時は背景のスクロール禁止
    const isHidden = newValue ? 'hidden' : 'visible';
    document.body.style.overflow = isHidden;
    document.documentElement.style.overflow = isHidden;

    innerIsOpen.value = newValue;
  },
});

const setStyles = computed(() =>
  props.full ? fullStyles : { width: props.width, height: props.height, overflow: props.scroll ? 'scroll' : 'hidden' },
);
const transitionName = computed(() => props.full ? 'fade' : 'modal');

const handleOpenEvent = () => {
  isOpen.value = true;
  emit('openModalEvent');
};
const handleCloseEvent = (targetModalName: string, oldValue: TModalAction) => {
  // NOTE: 判定の意図がよくわからないがとりあえずそのままにする
  if (props.modalName.split('.')[0] === targetModalName.split('.')[0]) {
    oldValue != null && props.delegate?.beforeClose?.(); // NOTE: これがないと、「モーダルを閉じるとき強制に再レンダリングした場合」に無限ループになる。
    isOpen.value = false;
  }
};
const handleOverlayClick = () => {
  if (props.delegate?.shouldClose == null) {
    closeModal();
    return;
  }
  const shouldClose = props.delegate.shouldClose();
  Promise.resolve(shouldClose).then((result) => {
    result && closeModal();
  });
};

const closeModal = () => {
  if (isOpen.value) modal.hide(props.modalName);
};

watch(modalAction, (newValue, oldValue) => {
  if (newValue == null) return;
  if (props.modalName !== newValue.modalName) return;
  // NOTE: 複数回変更が発火するので、変更がない場合は処理をスキップする
  if (newValue.modalName === oldValue?.modalName && newValue.action === oldValue?.action) return;

  if (newValue.action === 'show') handleOpenEvent();
  if (newValue.action === 'hide') handleCloseEvent(newValue.modalName, oldValue);
}, { immediate: true });

onUnmounted(() => {
  document.body.style.overflow = 'visible';
  document.documentElement.style.overflow = 'visible';
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: transitionName.value }, {
    default: _withCtx(() => [
      (isOpen.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "b-modal b-modal-overlay",
            onMousedown: _withModifiers(handleOverlayClick, ["self"])
          }, [
            _createElementVNode("div", {
              class: "b-modal-content",
              style: _normalizeStyle(setStyles.value)
            }, [
              _renderSlot(_ctx.$slots, "default")
            ], 4)
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["name"]))
}
}

})
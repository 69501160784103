import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { PriorityPriorityEnum } from '@/api/openapi';
import { TSize } from '../../types';
import priorityColors from './priority-colors';

type TProps = {
  size?: TSize;
  priority: PriorityPriorityEnum;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BPriorityColorPreview',
  props: {
    size: {},
    priority: {}
  },
  emits: ['click'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const colorCode = computed(() => priorityColors.find(c => c.value === props.priority)?.colorCode);
const handleClick = () => {
  emit('click');
};

return (_ctx: any,_cache: any) => {
  const _component_BColorPreview = _resolveComponent("BColorPreview")!

  return (_openBlock(), _createBlock(_component_BColorPreview, {
    "color-code": colorCode.value,
    size: _ctx.size,
    onClick: handleClick
  }, null, 8, ["color-code", "size"]))
}
}

})
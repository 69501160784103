<template>
  <LeadHistoryCard v-bind="attributes">
    <template #date>
      <div>{{ historiedAt }}</div>
    </template>
    <template #action>
      <BLayout
        v-if="isAvailableSalesforceApiFeature && sfConnectedStatus.length > 0"
        align-center
      >
        <BBtn
          class="mt-2 mr-2"
          size="small"
          fab
          flat
        >
          <BSfStatusIcon
            :connect-status="sfConnectedStatus"
            @open-sf-modal="openSfModal(sfMailTaskName)"
          />
        </BBtn>
        <SfStatusModal
          v-if="isAvailableSalesforceApiFeature"
          :modal-name="sfMailTaskName"
          :sf-related-link-info="mail.sfRelatedLinkInfo"
          :sf-exec-sync-btn="false"
        />
      </BLayout>
    </template>
    <div class="mail-card">
      <BListItem
        v-if="mailSendingFailed"
        class="mb-400"
      >
        <BError
          :error-title="$t('mailEvent.failedInfoTitle')"
          :error-info="mail.failedEvent ? $t(`mailEvent.${mail.failedEvent}Info`) : $t('mailEvent.systemError')"
        />
      </BListItem>
      <BListItem
        v-if="mail.campaignName"
        class="mb-400"
      >
        <template #header>
          <div>{{ $t('mail.campaignName') }}</div>
        </template>
        <div>{{ mail.campaignName }}</div>
      </BListItem>
      <BListItem class="mb-400">
        <template #header>
          <div>{{ $t('mail.sender') }}</div>
        </template>
        <div class="user-name">
          {{ historyMailUser(fromName, fromAddress) }}
        </div>
      </BListItem>
      <BListItem class="mb-400">
        <template #header>
          <div>{{ $t('mail.to_address') }}</div>
        </template>
        <div class="user-name">
          {{ historyMailUser(toName, toAddress) }}
        </div>
      </BListItem>
      <BListItem
        v-if="ccs.length > 0"
        class="mb-400"
      >
        <template #header>
          <div>{{ $t('mail.cc') }}</div>
        </template>
        <div
          v-for="cc in ccs"
          :key="cc.email"
          class="emails"
        >
          {{ cc.email }}
        </div>
      </BListItem>
      <BListItem
        v-if="bccs.length > 0"
        class="mb-400"
      >
        <template #header>
          <div>{{ $t('mail.bcc') }}</div>
        </template>
        <div
          v-for="bcc in bccs"
          :key="bcc.email"
          class="emails"
        >
          {{ bcc.email }}
        </div>
      </BListItem>
      <BListItem class="mb-400">
        <template #header>
          <div>{{ $t('mail.contentPlaceholder') }}</div>
        </template>
        <div
          class="quill-confirm break-word-pre-wrap"
          v-html="mail.content"
        />
      </BListItem>
      <BListItem class="mb-400">
        <div class="mail-attachments">
          <MailAttachment
            v-for="attachment in mail.attachments"
            :id="attachment.id"
            :key="attachment.id"
            :file-name="attachment.fileName"
          />
        </div>
      </BListItem>
      <BBtn
        v-if="mail.canReply"
        size="small"
        type="primary"
        outline
        @click="handleClickReply"
      >
        <BIcon class="icon">
          <span class="material-symbols-outlined">reply</span>
        </BIcon>
        <span class="reply-text">{{ $t('general.reply') }}</span>
      </BBtn>
    </div>
  </LeadHistoryCard>
</template>

<script>
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import MailAttachment from '@/components/organisms/user/general/MailAttachment.vue'
import SfStatusModal from '@/components/organisms/user/general/modal/salesforce/SfStatusModal.vue';
import LeadHistoryCard from '@/components/organisms/user/lead/history/LeadHistoryCard.vue'
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import MAIL_STATUSES from '@/const/mail_statuses'
import errorHandler from '@/mixins/error_handler';
import BSfStatusIcon from '@/plugins/biscuet-materials/components/molecules/BSfStatusIcon.vue';
import { formatShorterDateTime } from '@/utils/date-time'

export default {
  name: 'MailCard',
  components: {
    LeadHistoryCard,
    SfStatusModal,
    BSfStatusIcon,
    MailAttachment,
  },
  mixins: [errorHandler],
  props: {
    mail: Object,
    expand: Boolean,
  },
  emits: [
    'replyToReceivedMail',
  ],
  setup() {
    const { isAvailableSalesforceApiFeature } = useAvailableFeatureCheck()
    return {
      isAvailableSalesforceApiFeature,
    }
  },
  computed: {
    ...mapGetters('userUi', []),
    attributes() {
      return {
        'text': this.$t('mail.historyTitle', { actionTypeText: this.mail.actionTypeText, subject: this.mail.subject }),
        'user': this.mail.sentUser,
        'isDisplaySystemUser': this.mail.sentUser == null,
        'icon': 'mail',
        'icon-type': 'success',
        'toggle': true,
        'expand': this.expand,
        'status': this.status == null ? {} : this.status,
      }
    },
    status() {
      if (this.mail == null) return null
      switch (this.mail.status) {
        case MAIL_STATUSES.SENT:
          return {
            type: 'success',
            icon: 'mail',
            name: this.$t('mail.status.sent'),
          }
        case MAIL_STATUSES.SENDING:
          return {
            type: 'warning',
            icon: 'mail',
            name: this.$t('mail.status.sending'),
          }
        case MAIL_STATUSES.READY:
          return {
            type: 'warning',
            icon: 'mail',
            name: this.$t('mail.status.ready'),
          }
        case MAIL_STATUSES.SENDING_FAILED:
          return {
            type: 'error',
            icon: 'mail',
            name: this.$t('mail.status.sending_failed'),
          }
        default:
          return null
      }
    },
    historiedAt() {
      return formatShorterDateTime(this.mail.historiedAt)
    },
    isReceiveMail() {
      return this.mail.type === 'receiveMail'
    },
    fromName() {
      return this.isReceiveMail ? this.mail.fromName : this.mail.sendFromName
    },
    fromAddress() {
      return this.isReceiveMail ? this.mail.fromAddress : this.mail.sendFromAddress
    },
    toName() {
      return this.isReceiveMail ? this.mail.toName : this.mail.sendToName
    },
    toAddress() {
      return this.isReceiveMail ? this.mail.toAddress : this.mail.sendToAddress
    },
    ccs() {
      let cc = []
      if (this.isReceiveMail) {
        cc = this.mail.cc
      } else if (this.mail.sendCc != null && this.mail.sendCc.length > 0) {
        cc = JSON.parse(this.mail.sendCc)
      }
      return cc
    },
    bccs() {
      let bcc = []
      if (this.isReceiveMail) {
        // NOTE: メール返信はbccなし
      } else if (this.mail.sendBcc != null && this.mail.sendBcc.length > 0) {
        bcc = JSON.parse(this.mail.sendBcc)
      }
      return bcc
    },
    sfConnectedStatus() {
      return this.mail.sfRelatedLinkInfo?.sfRelatedLinks?.[0]?.status || ''
    },
    sfMailTaskName() {
      if (this.mail.type === 'sendMailDirect') {
        return `sfTaskStatusDirectMailModal${this.mail.id}`
      } else if (this.mail.type === 'sendMailBulk') {
        return `sfTaskStatusSendBulkMailModal${this.mail.id}`
      } else {
        return ''
      }
    },
    mailSendingFailed() {
      return this.mail.status === MAIL_STATUSES.SENDING_FAILED
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      getLeadHistoriesBackgroundAction: { action: 'getLeadHistoriesAction', loader: 'getLeadHistoriesBackgroundWait' },
    }),
    historyMailUser(name, mail) {
      return this.$t('historyMail.user', { name, mail })
    },
    openSfModal(modalName) {
      this.$modal.show(modalName);
    },
    async syncDirectMailToSfIfNeeded() {
      if (this.mail.type !== 'sendMailDirect' || this.mail.status !== MAIL_STATUSES.SENT) return
      if ((this.mail.sfRelatedLinkInfo?.sfRelatedLinks?.length || 0) === 0) return
      await this.updateDirectMailSfTaskAction({
        body: {
          callTargetId: this.mail.callTargetId,
          targetId: this.mail.id,
        },
      })
      this.getLeadHistoriesBackgroundAction({ callTargetId: this.mail.callTargetId })
    },
    handleClickReply() {
      if (this.mail.type === 'receiveMail') {
        this.$emit('replyToReceivedMail', this.mail.id)
      } else if (this.mail.type === 'sendMailDirect') {
        this.$emit('replyToReceivedMail', this.mail.receiveMailId)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .mail-card {
    &:not(:last-child) {
      margin-bottom: $basespace-300;
    }
    display: flex;
    flex-wrap: wrap;
    .user-name {
      word-break: break-all;
    }

    .mail-attachments {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  .status {
    display: flex;
    align-items: center;
    font-size: $fontsize-100;
    color: $textcolor-light;

    .badge {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 4px;

      &.draft {
        background: $basecolor-warning;
      }

      &.sending {
        background: $basecolor-warning;
      }

      &.sending_failed {
        background: $basecolor-error;
      }

      &.sent {
        background: $basecolor-success;
      }
    }
  }

  .quill-confirm {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 1.42;
    tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    // Biscuetの標準リンク色を上書き
    :deep(a) {
      color: #0066cc !important;
    }
    :deep(p) {
      margin: 0 !important;
      padding: 0 !important;
      counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9 !important;
    }
  }

  .emails {
    margin: 3px 0;
  }

  .material-symbols-outlined {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 1px;
  }

  .reply-text {
    margin-left: 5px;
  }
</style>

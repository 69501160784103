import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import LeadHistoryCard from '@/components/organisms/user/lead/history/LeadHistoryCard.vue';
import { i18nGlobal as i18n } from '@/i18n';
import BLeadStagePreview, { TDisplayLeadStage } from '@/plugins/biscuet-materials/components/molecules/BLeadStagePreview.vue';
import { LeadStageHistory } from '@/store/types';
import { formatShorterDateTime } from '@/utils/date-time';

type TProps = {
  leadStageHistory: LeadStageHistory;
  expand?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'LeadStageCard',
  props: {
    leadStageHistory: {},
    expand: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props;

const attributes = computed(() => ({
  text: i18n.t('leadStage.title') + i18n.t('leadStage.update'),
  user: props.leadStageHistory.user,
  iconComponent: 'BCustomIcon',
  customIconClass: 'b-stage-cycle',
  iconType: 'hot',
  toggle: true,
  expand: props.expand,
  isDisplaySystemUser: true,
}))
const historiedAt = computed<string>(() => formatShorterDateTime(props.leadStageHistory.historiedAt))
const displayLeadStage = computed<TDisplayLeadStage>(() => ({
  name: props.leadStageHistory.name,
  colorName: props.leadStageHistory.colorName,
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(LeadHistoryCard, _normalizeProps(_guardReactiveProps(attributes.value)), {
    date: _withCtx(() => [
      _createElementVNode("div", null, _toDisplayString(historiedAt.value), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(BLeadStagePreview, {
        "lead-stage": displayLeadStage.value,
        "truncate-text": false
      }, null, 8, ["lead-stage"])
    ]),
    _: 1
  }, 16))
}
}

})
import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "trigger-action-form-body-container" }
const _hoisted_2 = { class: "fs-300 fw-bold" }
const _hoisted_3 = {
  key: 0,
  class: "action-detail-container form"
}
const _hoisted_4 = { class: "fs-300 fw-bold" }
const _hoisted_5 = { class: "footer" }

import { ref, watch, nextTick, computed } from 'vue';
import { useTriggerAction, useTriggerActionForm } from '@/composable/user/trigger/trigger-action';
import SequenceMasterSimpleSelect from '../SequenceMasterSimpleSelect.vue';
import { TriggerActionBodyExt } from '../types';

type TEmits = {
  'click:cancel': [];
};

export default /*@__PURE__*/_defineComponent({
  __name: 'FormDrawer',
  props: {
    "modelValue": {},
    "modelModifiers": {},
    "isOpen": { type: Boolean, ...{ default: false } },
    "isOpenModifiers": {},
  },
  emits: /*@__PURE__*/_mergeModels(["click:cancel"], ["update:modelValue", "update:isOpen"]),
  setup(__props, { emit: __emit }) {

const emit = __emit;

const model = _useModel<TriggerActionBodyExt | null>(__props, "modelValue");
const isOpen = _useModel<boolean>(__props, 'isOpen');

const { groupedActionTypes, makeInitialTriggerAction } = useTriggerAction();
const { forms, fetchForms, loading } = useTriggerActionForm();
const internalTriggerAction = ref<TriggerActionBodyExt>();

const clearInternalValues = () => {
  internalTriggerAction.value = makeInitialTriggerAction(model.value);
  forms.value = [];
};
clearInternalValues();

watch(() => internalTriggerAction.value.actionType, value => {
  if (value) fetchForms(value);
});

watch(() => isOpen.value, () => {
  nextTick(() => {
    clearInternalValues();
  });
});

const isFormValid = computed(() => {
  return !!internalTriggerAction.value.actionType
    && forms.value.length >= 1
    && forms.value.every(form => {
      return !!internalTriggerAction.value.actionDetail[form.actionDetailKey];
    });
});

const handleCancelClick = () => {
  emit('click:cancel');
};

const handleSetClick = () => {
  model.value = internalTriggerAction.value;
};

return (_ctx: any,_cache: any) => {
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BSelect = _resolveComponent("BSelect")!
  const _component_SelectUser = _resolveComponent("SelectUser")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BDrawerMenu = _resolveComponent("BDrawerMenu")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_BDrawerMenu, {
    modelValue: isOpen.value,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((isOpen).value = $event)),
    class: "trigger-action-form-container",
    width: "600px",
    title: _ctx.$t('trigger.actionSetting.title'),
    onCancel: handleCancelClick
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_BLayout, {
          "align-center": "",
          "justify-center": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BBtn, {
              class: "mr-300 btn",
              text: "",
              onClick: handleCancelClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.cancel.text')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_BBtn, {
              class: "ml-300 btn",
              type: "primary",
              outline: "",
              disabled: !isFormValid.value,
              onClick: handleSetClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.setting.text')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BListItem, { class: "pb-100" }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('trigger.actionSetting.targetSelect')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_BListItem, { class: "list-item" }, {
          header: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('trigger.actionSetting.actionSelect')), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_BSelect, {
              modelValue: internalTriggerAction.value.actionType,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((internalTriggerAction.value.actionType) = $event)),
              items: _unref(groupedActionTypes),
              "item-text": "name",
              "item-value": "value",
              fit: "",
              required: ""
            }, null, 8, ["modelValue", "items"])
          ]),
          _: 1
        }),
        _createVNode(_component_BListItem, { class: "list-item" }, {
          default: _withCtx(() => [
            (!!internalTriggerAction.value.actionType)
              ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(forms), (form) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: form.actionDetailKey
                    }, [
                      (form.inputType === 'sequence_select')
                        ? (_openBlock(), _createBlock(_component_BListItem, {
                            key: 0,
                            class: "list-item"
                          }, {
                            header: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(form.label), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(SequenceMasterSimpleSelect, {
                                modelValue: (internalTriggerAction.value.actionDetail[form.actionDetailKey] as number | null),
                                "onUpdate:modelValue": ($event: any) => (((internalTriggerAction.value.actionDetail[form.actionDetailKey] as number | null)) = $event)
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ])), [
                  [_directive_loading, _unref(loading)]
                ])
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_BListItem, { class: "pt-200 pb-200" }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('trigger.actionSetting.approveUserSelect')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_BListItem, { class: "list-item" }, {
          header: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('trigger.actionSetting.approveUser')), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_SelectUser, {
              modelValue: internalTriggerAction.value.approveUserId,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((internalTriggerAction.value.approveUserId) = $event)),
              "use-all": false,
              clearable: true
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}
}

})
<template>
  <div
    v-loading="$wait.is(['updateNextActionWait', 'getNextActionsWait', 'doneNextActionWait'])"
    class="nextaction-card"
  >
    <template
      v-for="nextAction in nextActions"
      :key="`nextaction-${nextAction.id}`"
    >
      <div
        class="nextaction-list"
        @click="() => handleNextActionEdit(nextAction)"
      >
        <NextAction
          :next-action="nextAction"
          @click-done="handleNextActionDone($event)"
          @click-edit="handleNextActionEdit($event)"
          @click-delete="handleNextActionDelete($event)"
          @update="handleNextActionUpdate($event)"
          @click-editor="handleDrawerClose"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { isItSequenceAction } from '@/composable/user/nextAction/next-action'
import NextAction from './nextActionArea/NextAction.vue'

export default {
  components: {
    NextAction,
  },
  props: {
    nextActions: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'click-done',
    'click-edit',
    'click-delete',
    'update',
    'click-drawer-close',
    'click:sequenceAction',
  ],
  methods: {
    handleNextActionDone (nextAction) {
      this.$emit('click-done', nextAction)
    },
    handleNextActionEdit (nextAction) {
      if (isItSequenceAction(nextAction)) {
        this.$emit('click:sequenceAction', nextAction.sequenceStepInstance.sequenceInstanceId)
      } else {
        this.$emit('click-edit', nextAction)
      }
    },
    handleNextActionDelete (nextAction) {
      this.$emit('click-delete', nextAction.id)
    },
    handleNextActionUpdate (nextAction) {
      this.$emit('update', nextAction)
    },
    handleDrawerClose () {
      this.$emit('click-drawer-close')
    },
  },
}
</script>

<style lang="scss" scoped>
  .nextaction-card {
    background-color: $bgcolor-white;
    padding: $basespace-100 0;
  }

  .nextaction-list {
    transition: all 300ms $standard-easing;
    padding: $basespace-100 $basespace-600;

    :deep(.nextaction-support) {
      @include m-fixed-width(120px);
      font-size: $fontsize-100;
      text-align: right;
      margin-left: auto;
    }

    :deep(.nextaction-reserved-at) {
      color: $textcolor-light;
    }

    :deep(.nextaction-action-area) {
      display: none;
    }

    &:hover {
      background: $bgcolor-base;
      cursor: pointer;

      :deep(.nextaction-reserved-at) {
        display: none;
      }

      :deep(.nextaction-action-area) {
        display: block;
      }
    }
  }
</style>

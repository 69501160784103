<template>
  <div class="sequence-apply-modal">
    <BModal
      :modal-name="modalName"
      width="1380px"
      height="880px"
      :delegate="bModalDelegate"
    >
      <BHeightAdjuster>
        <template v-if="step === 'select'">
          <BModalHeader
            :title="$t('sequence.selectLead')"
            @modal-close="handleCloseClick"
          />
          <LeadSelect
            :sequence-master-id="sequenceMasterId"
            @select-call-target-ids="handleSelectCallTargetIds"
          />
        </template>
        <template v-if="step === 'apply'">
          <SequenceApply
            :call-target-ids="selectedCallTargetIds"
            :owner-id="selectedOwnerId"
            :sequence-master-id="sequenceMasterId"
            @click-close="handleCloseClick"
            @after-apply="handleAfterApply"
            @back-to-sequence-list="handleBackToSelect"
          />
        </template>
      </BHeightAdjuster>
      <FailedCallTargetsModal
        :title="$t('sequence.message.sequenceRegistrationFailure')"
        :failed-call-targets="internalFailedCallTargets"
      />
    </BModal>
  </div>
</template>

<script lang="ts" setup>
import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { FailedCallTarget } from '@/api/openapi';
import FailedCallTargetsModal from '@/components/organisms/user/general/modal/FailedCallTargetsModal.vue'
import { TSequenceOwnerValue } from '@/components/organisms/user/general/sequence/SelectSequenceOwner.vue';
import SequenceApply from '@/components/organisms/user/general/sequence/SequenceApply.vue';
import { KEY_OF_MODAL, KEY_OF_TOAST } from '@/injection-keys';
import { bitterAlert } from '@/plugins/BBitterAlert';
import { TBmodalDelegate } from '@/plugins/BModal/BModal.vue'
import LeadSelect from './LeadSelect.vue';

type TProps = {
  modalName: string;
  sequenceMasterId: number;
};
type TStep = 'select' | 'apply';

defineProps<TProps>();
const emit = defineEmits(['close', 'afterApply']);

const i18n = useI18n();
const toast = inject(KEY_OF_TOAST);
const modal = inject(KEY_OF_MODAL);

const step = ref<TStep>('select');
const selectedCallTargetIds = ref<number[]>([]);
const selectedOwnerId = ref<TSequenceOwnerValue>(undefined);
const internalFailedCallTargets = ref<FailedCallTarget[]>([]);

const beforeClose = () => {
  step.value = 'select';
}
const shouldClose = async (): Promise<boolean> => {
  return step.value === 'select' || await bitterAlert.show({
    text: i18n.t('sequence.leadDetail.confirmToCloseModal'),
  });
}
const bModalDelegate = ref<TBmodalDelegate>({
  shouldClose,
  beforeClose,
});

const handleCloseClick = async () => {
  await shouldClose() && closeModal();
};
const handleSelectCallTargetIds = (callTargetIds: number[], ownerId: TSequenceOwnerValue) => {
  selectedCallTargetIds.value = callTargetIds;
  selectedOwnerId.value = ownerId;
  step.value = 'apply';
};
const handleBackToSelect = () => {
  step.value = 'select';
};
const handleAfterApply = (failedCallTargets: FailedCallTarget[]) => {
  emit('afterApply');

  internalFailedCallTargets.value = failedCallTargets;
  if (failedCallTargets.length) {
    modal.show('failedCallTargetsModal')
    return
  }

  toast.show(i18n.t('general.done.of', { action: i18n.t('sequence.of', { item: i18n.t('sequence.lead') }) }))
  closeModal();
};

const closeModal = () => {
  emit('close');
};
</script>

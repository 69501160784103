import Waiting from '@/store/waiting'

export default class BoxilLeadImportSettingActions {
  constructor (Api, types) {
    const actions = {
      async getBoxilLeadImportSettingAction ({ commit }, args) {
        await waiting.check('getBoxilLeadImportSettingAction', async () => {
          try {
            const res = await Api.getBoxilLeadImportSetting(args)
            commit(types.SET_BOXIL_LEAD_IMPORT_SETTING, Object.values(res.data)[0])
          } catch (err) {
            commit(types.CLEAR_BOXIL_LEAD_IMPORT_SETTING)
            throw err
          }
        })
      },
      async createBoxilLeadImportSettingAction ({ commit }, args) {
        await waiting.check('createBoxilLeadImportSettingAction', async () => {
          await Api.createBoxilLeadImportSetting(args)
          try {
            const res = await Api.getBoxilLeadImportSetting(args)
            commit(types.SET_BOXIL_LEAD_IMPORT_SETTING, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
      async deleteBoxilLeadImportSettingAction ({ commit }, args) {
        await waiting.check('deleteBoxilLeadImportSettingAction', async () => {
          await Api.deleteBoxilLeadImportSetting(args)
          commit(types.CLEAR_BOXIL_LEAD_IMPORT_SETTING)
        })
      },
      async testBoxilLeadImportSettingAction ({ commit }, args) {
        let result = null
        await waiting.check('testBoxilLeadImportSettingAction', async () => {
          result = await Api.testBoxilLeadImportSetting(args)
        })
        return result
      },
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

import admin from './resources/admin'
import auth from './resources/auth'
import client from './resources/client'
import clientAvailableFeature from './resources/client_available_feature'
import clientLimitSetting from './resources/client_limit_setting'
import clientSendgridApikey from './resources/client_sendgrid_api_key'
import clientSendgridAuth from './resources/client_sendgrid_auth'
import currentUser from './resources/current_user'
import mail from './resources/mail'
import mailVerification from './resources/mail_verification'
import sendgridApikey from './resources/sendgrid_api_key'
import sendgridSubUser from './resources/sendgrid_sub_user'
import user from './resources/user'

const apis = Object.assign({},
  currentUser,
  auth,
  admin,
  user,
  client,
  clientLimitSetting,
  clientAvailableFeature,
  clientSendgridAuth,
  mail,
  mailVerification,
  sendgridSubUser,
  sendgridApikey,
  clientSendgridApikey,
)

export default apis

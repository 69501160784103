import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "trigger-event-form-body-container" }
const _hoisted_2 = { class: "fs-300 fw-bold" }
const _hoisted_3 = { class: "fs-300 fw-bold" }
const _hoisted_4 = { class: "list-group" }
const _hoisted_5 = { class: "list-group" }
const _hoisted_6 = { class: "mb-200" }
const _hoisted_7 = {
  key: 0,
  class: "event-detail-container confirm"
}
const _hoisted_8 = { class: "mb-100" }
const _hoisted_9 = { class: "footer" }

import camelcaseKeys from 'camelcase-keys'
import { ref, watch, nextTick, computed, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex';
import { TriggerEventBody } from '@/api/openapi';
import FilterControlPanelBody from '@/components/organisms/user/leadList/filter/FilterControlPanelBody.vue';
import LeadViewSelectPanel from '@/components/organisms/user/LeadViewSelectPanel.vue'
import { useTriggerEvent, useTriggerEventForm } from '@/composable/user/trigger/trigger-event';
import { key } from '@/store/index'
import SequenceMasterSimpleSelect from '../SequenceMasterSimpleSelect.vue'
import SequenceStepMasterSimpleSelect from '../SequenceStepMasterSimpleSelect.vue'

type TProps = {
  editable: boolean;
};
type TEmits = {
  'click:cancel': [];
  'click:triggerEvent': [triggerEvent: TriggerEventBody, menu: string];
};

export default /*@__PURE__*/_defineComponent({
  __name: 'ConfirmDrawer',
  props: /*@__PURE__*/_mergeModels({
    editable: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
    "isOpen": { type: Boolean, ...{ default: false } },
    "isOpenModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["click:cancel", "click:triggerEvent"], ["update:modelValue", "update:isOpen"]),
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const model = _useModel<TriggerEventBody | null>(__props, "modelValue");
const isOpen = _useModel<boolean>(__props, 'isOpen');

const i18n = useI18n();
const store = useStore(key);

const { makeInitialTriggerEvent } = useTriggerEvent();
const { forms, fetchForms, loading } = useTriggerEventForm();

const selectedLeadSelectType = ref<string>('leadList');
const internalTriggerEvent = ref<TriggerEventBody>();

const clearInternalValues = () => {
  internalTriggerEvent.value = makeInitialTriggerEvent(model.value);
  forms.value = [];
};
clearInternalValues();

const leadListFilterParams = computed(() => store.getters['userUi/leadListFilterParams']);

watch(() => isOpen.value, () => {
  nextTick(() => {
    clearInternalValues();
    storeLeadListFilterParams();
  });
});

watch(() => leadListFilterParams.value, value => {
  internalTriggerEvent.value.filterParams = value;
});

watch(() => internalTriggerEvent.value.eventType, value => {
  if (value) fetchForms(value);
});

watch(() => internalTriggerEvent.value.leadViewId, value => {
  if (value) selectedLeadSelectType.value = 'leadList';
});

watch(() => internalTriggerEvent.value.filterParams, value => {
  if (value && Object.keys(value).length > 0) selectedLeadSelectType.value = 'leadCustom';
});

const storeLeadListFilterParams = () => {
  const value = internalTriggerEvent.value.filterParams;
  if (value && Object.keys(value).length > 0) {
    store.dispatch('userUi/setLeadListFilterParamsAction',
      camelcaseKeys(value, {
        deep: true,
        exclude: [/^custom_field_.*[^items]/g],
      }),
    );
  }
};

onUnmounted(() => {
  store.dispatch('userUi/clearLeadListFilterParamsAction');
})

const handleCancelClick = () => {
  emit('click:cancel');
};

const handleEditClick = () => {
  emit('click:triggerEvent', model.value, 'edit');
};

return (_ctx: any,_cache: any) => {
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_ElDescriptionsItem = _resolveComponent("ElDescriptionsItem")!
  const _component_ElDescriptions = _resolveComponent("ElDescriptions")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BDrawerMenu = _resolveComponent("BDrawerMenu")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_BDrawerMenu, {
    modelValue: isOpen.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((isOpen).value = $event)),
    class: "trigger-event-form-container",
    width: "600px",
    title: _ctx.$t('trigger.eventSetting.title'),
    onCancel: handleCancelClick
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_BLayout, {
          "align-center": "",
          "justify-center": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BBtn, {
              class: "mr-300 btn",
              text: "",
              onClick: handleCancelClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.cancel.text')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_BBtn, {
              class: "ml-300 btn",
              type: "primary",
              outline: "",
              disabled: !_ctx.editable,
              onClick: handleEditClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.edit.text')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BListItem, { class: "pb-100" }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('trigger.eventSetting.leadSelect')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_BListItem, { class: "list-item" }, {
          default: _withCtx(() => [
            _createVNode(_component_ElDescriptions, {
              class: "margin-top",
              column: 1,
              border: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ElDescriptionsItem, null, {
                  label: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('trigger.eventSetting.leadFilterSelect.title')), 1)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t(`trigger.eventSetting.leadFilterSelect.${selectedLeadSelectType.value}`)), 1)
                  ]),
                  _: 1
                }),
                (selectedLeadSelectType.value === 'leadList')
                  ? (_openBlock(), _createBlock(_component_ElDescriptionsItem, { key: 0 }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('trigger.eventSetting.leadListSelect')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(LeadViewSelectPanel, {
                          modelValue: internalTriggerEvent.value.leadViewId,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((internalTriggerEvent.value.leadViewId) = $event)),
                          class: "lead-view-select-panel",
                          "full-width": "",
                          "list-height": "60vh",
                          "use-model": "",
                          "is-display-only": ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (selectedLeadSelectType.value === 'leadCustom')
                  ? (_openBlock(), _createBlock(_component_ElDescriptionsItem, { key: 1 }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('trigger.eventSetting.leadCustomSelect')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(FilterControlPanelBody, {
                          "lead-list-filter-params": leadListFilterParams.value,
                          "filter-category-visible": false,
                          "is-display-only": ""
                        }, null, 8, ["lead-list-filter-params"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_BListItem, { class: "pt-200 pb-200" }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('trigger.eventSetting.eventSelect')), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_BListItem, { class: "list-item" }, {
            header: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('trigger.eventSetting.eventType')), 1)
            ]),
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_unref(i18n).t(`trigger.eventTypes.${internalTriggerEvent.value.eventType}`)), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_BListItem, { class: "list-item" }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('trigger.eventSetting.eventDetail')), 1)
            ]),
            _: 1
          }),
          (!!internalTriggerEvent.value.eventType)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(forms), (form) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: form.eventDetailKey
                  }, [
                    (form.inputType === 'sequence_select')
                      ? (_openBlock(), _createBlock(_component_BListItem, {
                          key: 0,
                          class: "list-item"
                        }, {
                          header: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(form.label), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(SequenceMasterSimpleSelect, {
                              modelValue: (internalTriggerEvent.value.eventDetail[form.eventDetailKey] as number | null),
                              "onUpdate:modelValue": ($event: any) => (((internalTriggerEvent.value.eventDetail[form.eventDetailKey] as number | null)) = $event),
                              "is-display-only": true
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true),
                    (form.inputType === 'mail_type_sequence_step_select')
                      ? (_openBlock(), _createBlock(_component_BListItem, {
                          key: 1,
                          class: "list-item"
                        }, {
                          header: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(form.label), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(SequenceStepMasterSimpleSelect, {
                              modelValue: (internalTriggerEvent.value.eventDetail[form.eventDetailKey] as number | null),
                              "onUpdate:modelValue": ($event: any) => (((internalTriggerEvent.value.eventDetail[form.eventDetailKey] as number | null)) = $event),
                              "sequence-master-id": (internalTriggerEvent.value.eventDetail[form.eventDetailBelongsToKey] as number | null),
                              condition: { stepType: 'direct_mail' },
                              "is-display-only": true
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "sequence-master-id"])
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true),
                    (form.inputType === 'multi_text_input')
                      ? (_openBlock(), _createBlock(_component_BListItem, {
                          key: 2,
                          class: "list-item"
                        }, {
                          header: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(form.label), 1)
                          ]),
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(internalTriggerEvent.value.eventDetail[form.eventDetailKey], (url, index) => {
                              return (_openBlock(), _createElementBlock("div", { key: index }, [
                                _createElementVNode("div", _hoisted_8, _toDisplayString(url), 1)
                              ]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              ])), [
                [_directive_loading, _unref(loading)]
              ])
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}
}

})
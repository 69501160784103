import Api from '@/api/admin'
import AuthService from './auth_service'

export default class AdminService {
  constructor () {
    this.authService = new AuthService()
  }
  async signinAdmin () {
    try {
      await this.authService.signinWithGoogle()
      const res = await Api.signinAdmin()
      this.authService.changeRole(res.data.role)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async dummySigninAdmin (email, password) {
    try {
      await this.authService.signinWithEmailAndPassword(email, password)
      const res = await Api.signinAdmin()
      this.authService.changeRole(res.data.role)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async signout () {
    try {
      await Api.signoutAdmin()
      await this.authService.signout()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

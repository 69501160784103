<template>
  <FilterDetailPopover
    :title="title"
    :is-display-only="isDisplayOnly"
    :key-name="keyName"
    @hide="$emit('hide', switchValue)"
    @show="popoverShow"
  >
    <template #reference>
      <BLayout
        align-center
      >
        <BListItem>
          <template #header>
            {{ title }}
          </template>
          <div>{{ value }}</div>
        </BListItem>
      </BLayout>
    </template>
    <div>
      <FilterSwitchBtn
        v-model="switchValue.inc.or"
        :true-text="trueText || $t('general.filter.present')"
        :false-text="falseText || $t('general.filter.notPresent')"
      />
    </div>
  </FilterDetailPopover>
</template>

<script>
import lodash from 'lodash'
import FilterSwitchBtn from '@/components/organisms/user/FilterSwitchBtn.vue'
import FilterDetailPopover from '@/components/organisms/user/leadList/filter/FilterDetailPopover.vue'

export default {
  components: {
    FilterDetailPopover,
    FilterSwitchBtn,
  },
  props: {
    leadListFilterParams: {
      type: Object,
      default () {
        return {}
      },
    },
    isDisplayOnly: {
      type: Boolean,
    },
    keyName: {
      type: String,
      require: true,
    },
    title: {
      type: String,
      require: true,
    },
    trueText: String,
    falseText: String,
  },
  emits: [
    'hide',
  ],
  data () {
    return {
      switchValue: { inc: { or: null } },
    }
  },
  computed: {
    value () {
      const target = this.leadListFilterParams[this.keyName]
      if (target == null || target.inc.or == null) return '-'

      let string = ''

      if (target.inc.or === 'true' || target.inc.or === true) {
        string = this.trueText || this.$t('general.filter.present')
      } else if (target.inc.or === 'false' || target.inc.or === false) {
        string = this.falseText || this.$t('general.filter.notPresent')
      }
      return string
    },
  },
  methods: {
    popoverShow () {
      this.switchValue = this.leadListFilterParams[this.keyName].inc.or === null ? { inc: { or: true } } : lodash.cloneDeep(this.leadListFilterParams[this.keyName])
    },
  },
}
</script>

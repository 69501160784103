import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "custom-action-item-container" }
const _hoisted_2 = { class: "list-container" }
const _hoisted_3 = { class: "handle-wrap" }
const _hoisted_4 = { class: "more-menu-wrap" }
const _hoisted_5 = { class: "creating-form-container" }

import lodash from 'lodash';
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Draggable from 'vuedraggable';
import { CustomAction } from '@/api/openapi';
import { CustomActionApiService } from '@/api/user/resources/custom_action';
import { useInputValidations } from '@/composable/input-validations';
import { useCustomActions } from '@/composable/user/customAction/custom-actions';
import { useWait } from '@/composable/vue-wait';
import { bitterAlert } from '@/plugins/BBitterAlert';
import CustomActionCreatingForm from './CustomActionCreatingForm.vue';
import CustomActionItemOptions from './CustomActionItemOptions.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomActionItem',
  setup(__props) {

const { requiredRule } = useInputValidations();
const { wait, doActionWithWait } = useWait();
const i18n = useI18n();

const { customActions, fetchCustomActions } = useCustomActions();

const draggableItems = ref<CustomAction[]>([]);
watch(customActions, (newVal) => {
  draggableItems.value = lodash.cloneDeep(newVal);
}, { immediate: true });

const handleUpdateNameClick = async (name: string, customActionId: number) => {
  await doActionWithWait(`updatingCustomActionWait-${customActionId}`, async () => {
    const api = new CustomActionApiService();
    await api.updateCustomAction(
      {
        request: {
          customActionId,
          putCustomActionBody: {
            name,
          },
        },
      },
    );
    await fetchCustomActions(); // 前の値がチラつくのを防止するために、「doActionWithWait」の中に入れている。
  });
};

const handleSortOrderChange = async (event) => {
  if (!event.moved) return;
  const sortOrder = customActions.value[event.moved.newIndex].sortOrder
  const dragingItem = event.moved.element;
  const customActionId = dragingItem.id;
  await doActionWithWait('updatingCustomActionsWait', async () => {
    const api = new CustomActionApiService();
    await api.updateCustomAction(
      {
        request: {
          customActionId,
          putCustomActionBody: {
            sortOrder,
          },
        },
      },
    );
    await fetchCustomActions();
  });
};

const handleDeleteClick = async (customActionId: number) => {
  const customAction = customActions.value.find((action) => action.id === customActionId);
  const isAccepted = await bitterAlert.show({
    text: i18n.t('general.confirm.actionWithTarget', { target: customAction.name, action: i18n.t('general.delete.text') }),
  });
  if (!isAccepted) return;
  await doActionWithWait('deletingCustomActionWait', async () => {
    const api = new CustomActionApiService();
    await api.deleteCustomAction({ request: { customActionId } });
    await fetchCustomActions();
  });
};

const loading = computed(() => {
  return wait.is(['listCustomActionsWait', 'deletingCustomActionWait', 'updatingCustomActionsWait'])
});

return (_ctx: any,_cache: any) => {
  const _component_BEmptyBox = _resolveComponent("BEmptyBox")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BDataTypeIcon = _resolveComponent("BDataTypeIcon")!
  const _component_BEditableContent = _resolveComponent("BEditableContent")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BPopover = _resolveComponent("BPopover")!
  const _component_BMoreMenu = _resolveComponent("BMoreMenu")!
  const _component_BHeightAdjuster = _resolveComponent("BHeightAdjuster")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_BHeightAdjuster, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!draggableItems.value.length)
            ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                key: 0,
                class: "py-400",
                "display-only": ""
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "text", {}, () => [
                    _createTextVNode(_toDisplayString(_ctx.$t('customAction.notFound')), 1)
                  ])
                ]),
                _: 3
              }))
            : (_openBlock(), _createBlock(_unref(Draggable), {
                key: 1,
                modelValue: draggableItems.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((draggableItems).value = $event)),
                class: "item-row",
                "item-key": "id",
                handle: ".handle",
                animation: 300,
                onChange: handleSortOrderChange
              }, {
                item: _withCtx(({ element: customAction }) => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_BLayout, {
                      class: "py-100",
                      "align-center": "",
                      "justify-space-between": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BLayout, {
                          class: "ml-100 mr-100",
                          "align-center": ""
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_3, [
                              _createVNode(_component_BIcon, {
                                class: "handle",
                                size: "small",
                                type: "gray"
                              }, {
                                default: _withCtx(() => _cache[1] || (_cache[1] = [
                                  _createTextVNode(" drag_indicator ")
                                ])),
                                _: 1
                              })
                            ]),
                            _createVNode(_component_BDataTypeIcon, { "data-type": "option" }),
                            _createVNode(_component_BEditableContent, {
                              value: customAction.name,
                              placeholder: _ctx.$t('customAction.name'),
                              rules: [_unref(requiredRule)],
                              "max-length": 255,
                              loading: _unref(wait).is(`updatingCustomActionWait-${customAction.id}`),
                              onCheck: (inputValue) => handleUpdateNameClick(inputValue, customAction.id)
                            }, null, 8, ["value", "placeholder", "rules", "loading", "onCheck"])
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("span", _hoisted_4, [
                          _createVNode(_component_BMoreMenu, null, {
                            default: _withCtx(() => [
                              (!customAction.isDeletable)
                                ? (_openBlock(), _createBlock(_component_BPopover, {
                                    key: 0,
                                    placement: "top",
                                    width: "240",
                                    trigger: "hover"
                                  }, {
                                    reference: _withCtx(() => [
                                      _createElementVNode("div", null, [
                                        _createVNode(_component_BBtn, {
                                          flat: "",
                                          fit: "",
                                          size: "small",
                                          disabled: ""
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t('general.delete.do')), 1)
                                          ]),
                                          _: 1
                                        })
                                      ])
                                    ]),
                                    default: _withCtx(() => [
                                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('general.alreadyUsed')), 1)
                                    ]),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_BBtn, {
                                    key: 1,
                                    flat: "",
                                    fit: "",
                                    size: "small",
                                    onClick: () => handleDeleteClick(customAction.id)
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('general.delete.do')), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(CustomActionItemOptions, {
                      class: "custom-action-item-options-container",
                      "custom-action-id": customAction.id,
                      "custom-action-options": customAction.customActionOptions,
                      loading: loading.value,
                      onAfterSave: _unref(fetchCustomActions)
                    }, null, 8, ["custom-action-id", "custom-action-options", "loading", "onAfterSave"])
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"]))
        ])), [
          [_directive_loading, loading.value]
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(CustomActionCreatingForm, { onAfterCreate: _unref(fetchCustomActions) }, null, 8, ["onAfterCreate"])
        ])
      ])
    ]),
    _: 3
  }))
}
}

})
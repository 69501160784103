import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "truncate-text"
}
const _hoisted_2 = {
  key: 1,
  class: "truncate-text"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { key: 4 }
const _hoisted_6 = { key: 5 }
const _hoisted_7 = { key: 6 }
const _hoisted_8 = { key: 7 }
const _hoisted_9 = { key: 8 }
const _hoisted_10 = { key: 1 }

import { computed } from 'vue';
import { SequenceInstanceOverview } from '@/api/openapi';
import SequenceInstanceStatusPreview from '@/components/organisms/user/general/sequence/SequenceInstanceStatusPreview.vue';
import { TCheck } from '@/composable/check-all';
import { toYYYYMMDD } from '@/services/date-format-service';
import { TSequenceStatus } from '@/types';
import { altText } from '@/utils/alt';
import { columns } from './instance-list-header';

type TProps = {
  modelValue: TCheck;
  sequenceInstance: SequenceInstanceOverview;
};
type TEmit = (event: 'update:modelValue', check: TCheck) => void;


export default /*@__PURE__*/_defineComponent({
  __name: 'InstanceListItem',
  props: {
    modelValue: {},
    sequenceInstance: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const handleCheckboxChange = () => emit('update:modelValue', props.modelValue);

const numberOfDoneStepsText = computed(() => {
  return `${props.sequenceInstance.numberOfDoneSteps} / ${props.sequenceInstance.numberOfSteps}`;
});

return (_ctx: any,_cache: any) => {
  const _component_BCheckbox = _resolveComponent("BCheckbox")!
  const _component_BCustomIcon = _resolveComponent("BCustomIcon")!
  const _component_BLeadStageColorPreview = _resolveComponent("BLeadStageColorPreview")!
  const _component_BUserTooltip = _resolveComponent("BUserTooltip")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createBlock(_component_BLayout, {
    class: "instance-list-row",
    "align-center": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "instance-list-item checkbox",
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
      }, [
        _createVNode(_component_BCheckbox, {
          class: "mt-0",
          "model-value": _ctx.modelValue.checked,
          value: _ctx.modelValue.value,
          onChange: handleCheckboxChange
        }, null, 8, ["model-value", "value"])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns), (column) => {
        return (_openBlock(), _createElementBlock("div", {
          key: column.key,
          class: "instance-list-item",
          style: _normalizeStyle({
        'width': column.width,
        'min-width': column.width,
        'max-width': column.width,
      })
        }, [
          (column.key == 'companyName')
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.sequenceInstance.callTarget.companyName), 1))
            : (column.key == 'fullName')
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(altText)(_ctx.sequenceInstance.callTarget.fullName)), 1))
              : (column.key == 'registrationDate')
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(toYYYYMMDD)(_ctx.sequenceInstance.createdAt)), 1))
                : (column.key == 'status')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(SequenceInstanceStatusPreview, {
                        value: (_ctx.sequenceInstance.status as TSequenceStatus),
                        size: "small",
                        class: "mr-50"
                      }, null, 8, ["value"])
                    ]))
                  : (column.key == 'isOpportunityAssociated')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_BCustomIcon, {
                          "icon-class": "b-fire",
                          type: _ctx.sequenceInstance.isOpportunityAssociated ? 'primary' : 'gray'
                        }, null, 8, ["type"])
                      ]))
                    : (column.key == 'lastLeadStage')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(_component_BLeadStageColorPreview, {
                            value: _ctx.sequenceInstance.callTarget.leadStage.colorName,
                            size: "small",
                            class: "mr-50"
                          }, null, 8, ["value"]),
                          _createTextVNode(" " + _toDisplayString(_ctx.sequenceInstance.callTarget.leadStage.name), 1)
                        ]))
                      : (column.key == 'numberOfDoneSteps')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(numberOfDoneStepsText.value), 1))
                        : (column.key == 'numberOfCurrentStepStays')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.sequenceInstance.numberOfCurrentStepStays), 1))
                          : (column.key == 'owner')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                (_ctx.sequenceInstance.callTarget.user)
                                  ? (_openBlock(), _createBlock(_component_BUserTooltip, {
                                      key: 0,
                                      "image-url": _ctx.sequenceInstance.callTarget.user.avatarUrl,
                                      name: _ctx.sequenceInstance.callTarget.user.name,
                                      "user-id": _ctx.sequenceInstance.callTarget.user.id,
                                      size: 40
                                    }, null, 8, ["image-url", "name", "user-id"]))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_unref(altText)('')), 1))
                              ]))
                            : _createCommentVNode("", true)
        ], 4))
      }), 128)),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "instance-list-item end-column" }, null, -1))
    ]),
    _: 1
  }))
}
}

})
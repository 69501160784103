<template>
  <div class="form-drawer">
    <MailDrawerTemplate
      :title="$t('mail.mailCreate')"
      :width="600"
    >
      <template #drawerHeaderAction>
        <BTooltip
          top
          :content="confirmInfo"
          :disabled="!confirmInfo"
        >
          <BBtn
            size="small"
            type="primary"
            outline
            :loading="isLoading"
            :disabled="!isValid"
            @click="handleClickConfirm"
          >
            <span>{{ $t('mail.confirmContent') }}</span>
            <BIcon>keyboard_arrow_right</BIcon>
          </BBtn>
        </BTooltip>
      </template>
      <template #drawerBody>
        <FormDrawerBodyCommon
          :model-value="modelValue"
          :is-available-mail-template-feature="isAvailableMailTemplateFeature"
          :sender-data="senderData"
          @update:model-value="handleUpdateFormValuesCommon"
          @update:is-valid="handleUpdateIsValidFormValuesCommon"
          @close-openai="handleOpenAiCloseModal"
        >
          <BListItem
            v-if="modelValue.immediately"
            class="mb-300"
          >
            <template #header>
              <span>{{ $t('sequence.step.dateInterval') }}</span>
            </template>
            <div class="mt-100 immediately">
              {{ $t('general.sendImmediately') }}
            </div>
          </BListItem>
          <div
            v-else
            class="time-items mb-600"
          >
            <BListItem class="date-interval-container">
              <template #header>
                <span>{{ $t('sequence.step.dateInterval') }}</span>
              </template>
              <BInput
                v-model="dateInterval"
                v-model:valid="isDateIntervalValid"
                type="number"
                :max="30"
                :min="0"
                validation
                required
                needs-parse-int
              />
            </BListItem>
            <BListItem class="reserved-time-container">
              <template #header>
                <span>{{ $t('sequence.step.reservedTime') }}</span>
              </template>
              <BTimePicker
                v-model="reservedTime"
                value-format="HH:mm"
                format="HH:mm"
                required
              />
            </BListItem>
          </div>
        </FormDrawerBodyCommon>
      </template>
      <template #drawerFooter>
        <BLayout justify-center>
          <BBtn
            v-if="draftable"
            type="primary"
            class="mr-100"
            :loading="isLoading"
            :disabled="!isValid"
            flat
            @click="handleDraftClick"
          >
            {{ $t('general.draftSave.text') }}
          </BBtn>
          <BBtn
            flat
            @click="handleCancelClick"
          >
            {{ $t('general.close.text') }}
          </BBtn>
        </BLayout>
      </template>
    </MailDrawerTemplate>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { User } from '@/api/openapi';
import MailDrawerTemplate from '@/components/organisms/user/mail/common/drawer/MailDrawerTemplate.vue'
import FormDrawerBodyCommon from '@/components/organisms/user/mail/direct/drawer/state/form/FormDrawerBodyCommon.vue';
import { SenderData } from '../../../mail/direct/drawer/state/types';
import { TFormDirectMailSequenceStep } from '../types';

type TProps = {
  modelValue: TFormDirectMailSequenceStep;
  users: readonly User[];
  isLoading: boolean;
  isAvailableMailTemplateFeature: boolean;
  senderData: SenderData | null;
  draftable: boolean;
  confirmInfo?: string;
};
type TEmit = {
  'update:modelValue': [value: TFormDirectMailSequenceStep];
  'cancel': [];
  'draft': [];
  'changeCurrentStateToConfirm': [];
  'closeOpenai': [cancel: () => void];
};

const props =  defineProps<TProps>();
const emit = defineEmits<TEmit>();

const isValidFormValuesCommon = ref(false);

const isDateIntervalValid = ref(false);
const dateInterval = computed({
  get: () => props.modelValue.dateInterval,
  set: (v) => updateFormValues('dateInterval', v),
});
const reservedTime = computed({
  get: () => props.modelValue.reservedTime,
  set: (v) => updateFormValues('reservedTime', v),
});

const isValid = computed(() =>
  isValidFormValuesCommon.value
  && (isDateIntervalValid.value || props.modelValue.immediately)
  && !!reservedTime.value,
);

const handleUpdateFormValuesCommon = (newValue: TFormDirectMailSequenceStep) => {
  emit('update:modelValue', newValue)
};
const updateFormValues = (key: keyof TFormDirectMailSequenceStep, value: unknown) => {
  emit('update:modelValue', {
    ...props.modelValue,
    [key]: value,
  })
};
const handleUpdateIsValidFormValuesCommon = (value: boolean) => {
  isValidFormValuesCommon.value = value;
};
const handleCancelClick = () => {
  emit('cancel')
};
const handleDraftClick = () => {
  emit('draft')
};
const handleClickConfirm = () => {
  emit('changeCurrentStateToConfirm')
};
const handleOpenAiCloseModal = (cancel: () => void) => {
  emit('closeOpenai', cancel);
};
</script>

<style lang="scss" scoped>
.form-drawer {
  height: 100%
}

.time-items {
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
}
.date-interval-container, .reserved-time-container {
  max-width: 10rem;
}

.immediately {
  margin: 10px 0 20px;
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

import { onMounted, ref } from 'vue';
import { SfObjectMappingService } from '@/api/user/resources/salesforce/sf_object_mapping';
import MappingColumn from '@/components/organisms/user/setting/api/SalesforceApiMap/MappingColumn.vue';
import SelectObject from '@/components/organisms/user/setting/api/SalesforceApiMap/SelectObject.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SalesforceApiMap',
  setup(__props) {

const apiService = new SfObjectMappingService();
const salesforceSettingLoading = ref(true);
const sfObjectMappingsKeys = ref([]);
const selectedObject = ref('');

const selectObject = (object: string) => {
  selectedObject.value = object;
}

const backAction = () => {
  selectedObject.value = '';
}

onMounted(async () => {
  const result = await apiService.getSfObjectMappingsKeys();
  sfObjectMappingsKeys.value = result.data.sfObjectMappingsKeys;
  salesforceSettingLoading.value = false;
})

return (_ctx: any,_cache: any) => {
  const _component_BContentBox = _resolveComponent("BContentBox")!

  return (_openBlock(), _createBlock(_component_BContentBox, null, {
    content: _withCtx(() => [
      (selectedObject.value.length <= 0)
        ? (_openBlock(), _createBlock(SelectObject, {
            key: 0,
            onSelectObject: selectObject
          }))
        : (_openBlock(), _createBlock(MappingColumn, {
            key: 1,
            "selected-object": selectedObject.value,
            onBack: backAction
          }, null, 8, ["selected-object"]))
    ]),
    _: 1
  }))
}
}

})
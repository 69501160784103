<template>
  <div
    v-for="customAction in customActions"
    :key="customAction.id"
  >
    <BListItem class="mb-300">
      <template #header>
        <span>{{ customAction.name }}</span>
      </template>
      <BSelect
        :model-value="customActionOptionId(customAction.id)"
        :items="customAction.customActionOptions"
        item-text="name"
        item-value="id"
        fit
        @update:model-value="(customActionOptionId) => updateModelValue(customAction.id, customActionOptionId)"
      />
    </BListItem>
  </div>
</template>

<script lang="ts" setup>
import { watch } from 'vue';
import { useCustomActions } from '@/composable/user/customAction/custom-actions';

export type CustomActionOptionValue = {
  customActionId: number;
  customActionOptionId: number;
};

type TEmit = {
  'update:modelValue': [value: CustomActionOptionValue[]];
  'afterFetch': [itemCount: number];
};
type TProps = {
  modelValue: CustomActionOptionValue[];
};
const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const { customActions } = useCustomActions();
watch(customActions, () => {
  emit('afterFetch', customActions.value.length);
});

const customActionOptionId = (customActionId: number) => {
  return props.modelValue.find((v) => v.customActionId === customActionId)?.customActionOptionId || null;
};

const updateModelValue = (customActionId: number, customActionOptionId: number) => {
  const modelValue = props.modelValue.filter((e) => e.customActionId !== customActionId).concat([{ customActionId, customActionOptionId }]);
  emit('update:modelValue', modelValue)
};
</script>

<template>
  <BHeightAdjuster>
    <BModalHeader
      :title="$t('general.append.to', {item: $t('callTarget.title')})"
      @modal-close="hide"
    />
    <BModalBody>
      <div class="grid">
        <div class="grid-custom-item px-200 pb-500 lead-grid">
          <div class="block-title">
            {{ $t('callTarget.info') }}
          </div>
          <BDivider class="mt-50 mb-300" />
          <BLayout
            class="px-200"
            justify-center
            column
          >
            <BListItem class="list-item">
              <template #header>
                <div>{{ $t('leadStage.title') }}</div>
              </template>
              <BLeadStageSelect
                v-model="editingLeadStageId"
                :lead-stages="leadStages"
                :loading="loading"
                :required="true"
                fit
              />
            </BListItem>
            <BListItem class="list-item">
              <template #header>
                <div>{{ $t('leadSource.title') }}</div>
              </template>
              <BSelect
                v-model="editingLeadSourceIds"
                label="lead_source"
                :items="selectableLeadSources"
                item-text="label"
                item-value="id"
                multiple
                fit
              />
            </BListItem>
            <BListItem class="list-item">
              <template #header>
                <div>{{ $t('freeTag.title') }}</div>
              </template>
              <BSelect
                v-model="editingFreeTagIds"
                label="free_tag"
                :items="selectableFreeTags"
                item-text="label"
                item-value="id"
                multiple
                fit
              />
            </BListItem>
          </BLayout>
        </div>
        <div class="grid-custom-item px-200 pb-500 company-info-grid">
          <div class="block-title">
            {{ $t('companyInfo.title') }}
          </div>
          <BDivider class="mt-50 mb-300" />
          <BLayout
            class="px-200"
            justify-center
            column
          >
            <BListItem class="list-item">
              <template #header>
                <div>{{ $t('companyInfo.item.companyName') }}</div>
              </template>
              <BInput
                v-model="editingCompanyInfo.companyName"
                v-model:valid="valid.companyName"
                label="company_name"
                :max-length="255"
                :rules="[requiredRule]"
              />
            </BListItem>
            <BListItem class="list-item">
              <template #header>
                <div>{{ $t('companyInfo.item.companyTel') }}</div>
              </template>
              <BInput
                v-model="editingCompanyInfo.companyTel_1"
                v-model:valid="valid.companyTel_1"
                label="company_tel_1"
                :max-length="255"
                :rules="[phoneRule]"
              />
            </BListItem>
            <BListItem class="list-item">
              <template #header>
                <div>{{ $t('companyInfo.item.companyUrl') }}</div>
              </template>
              <BInput
                v-model="editingCompanyInfo.companyUrl"
                v-model:valid="valid.companyUrl"
                label="company_url"
                :max-length="1000"
                :rules="[urlRule]"
              />
            </BListItem>
            <BListItem class="list-item">
              <template #header>
                <div>{{ $t('companyInfo.item.companyAddress') }}</div>
              </template>
              <BLayout>
                <BFlex
                  class="pr-100"
                  :span="7"
                >
                  <BInput
                    v-model="editingCompanyInfo.companyCountry"
                    v-model:valid="valid.companyCountry"
                    label="company_country"
                    :placeholder="$t('companyInfo.item.companyCountry')"
                    :max-length="255"
                    :validation="true"
                  />
                </BFlex>
                <BFlex
                  :span="8"
                  class="pr-100"
                >
                  <BInput
                    v-model="editingCompanyInfo.companyPostalCode"
                    v-model:valid="valid.companyPostalCode"
                    label="company_postal_code"
                    :placeholder="$t('companyInfo.item.companyPostalCode')"
                    :max-length="255"
                    :validation="true"
                  />
                </BFlex>
                <BFlex
                  class="pr-100"
                  :span="9"
                >
                  <BInput
                    v-model="editingCompanyInfo.companyPrefecture"
                    v-model:valid="valid.companyPrefecture"
                    label="company_prefecture"
                    :placeholder="$t('companyInfo.item.companyPrefecture')"
                    :max-length="255"
                    :validation="true"
                  />
                </BFlex>
              </BLayout>
            </BListItem>
            <BListItem class="list-item pb-200">
              <BLayout>
                <BFlex
                  :span="12"
                  class="pr-100"
                >
                  <BInput
                    v-model="editingCompanyInfo.companyCity"
                    v-model:valid="valid.companyCity"
                    label="company_city"
                    :placeholder="$t('companyInfo.item.companyCity')"
                    :max-length="255"
                    :validation="true"
                  />
                </BFlex>
                <BFlex :span="12">
                  <BInput
                    v-model="editingCompanyInfo.companyAddressLine"
                    v-model:valid="valid.companyAddressLine"
                    label="compan_address_line"
                    :placeholder="$t('companyInfo.item.companyAddressLine')"
                    :max-length="255"
                    :validation="true"
                  />
                </BFlex>
              </BLayout>
            </BListItem>
            <BListItem class="list-item">
              <template #header>
                <div>{{ $t('companyInfo.item.businessCategory') }}</div>
              </template>
              <BInput
                v-model="editingCompanyInfo.businessCategory"
                v-model:valid="valid.businessCategory"
                label="business_category"
                :max-length="255"
                :validation="true"
              />
            </BListItem>
            <BListItem class="list-item">
              <template #header>
                <div>{{ $t('companyInfo.item.numberOfEmployees') }}</div>
              </template>
              <BInput
                v-model="editingCompanyInfo.numberOfEmployees"
                v-model:valid="valid.numberOfEmployees"
                label="number_of_employees"
                :max-length="255"
                :validation="true"
              />
            </BListItem>
          </BLayout>
        </div>
        <div class="grid-custom-item px-200 pb-500 target-info-grid">
          <div class="block-title">
            {{ $t('callTargetInfo.title') }}
          </div>
          <BDivider class="mt-50 mb-300" />
          <BLayout
            class="px-200"
            justify-center
            column
          >
            <BListItem class="list-item">
              <template #header>
                <div>{{ $t('callTargetInfo.item.name') }}</div>
              </template>
              <BLayout>
                <BFlex
                  class="pr-100"
                  :span="12"
                >
                  <BInput
                    v-model="editingCompanyInfo.lastName"
                    v-model:valid="valid.lastName"
                    label="last_name"
                    :placeholder="$t('callTargetInfo.item.lastName')"
                    :max-length="255"
                    :validation="true"
                  />
                </BFlex>
                <BFlex :span="12">
                  <BInput
                    v-model="editingCompanyInfo.firstName"
                    v-model:valid="valid.firstName"
                    label="first_name"
                    :placeholder="$t('callTargetInfo.item.firstName')"
                    :max-length="255"
                    :validation="true"
                  />
                </BFlex>
              </BLayout>
            </BListItem>
            <BListItem class="list-item">
              <template #header>
                <div>{{ $t('callTargetInfo.item.nameKana') }}</div>
              </template>
              <BLayout>
                <BFlex
                  class="pr-100"
                  :span="12"
                >
                  <BInput
                    v-model="editingCompanyInfo.lastNameKana"
                    v-model:valid="valid.lastNameKana"
                    label="last_name_kana"
                    :placeholder="$t('callTargetInfo.item.lastNameKana')"
                    :max-length="255"
                    :validation="true"
                    :rules="[kanaRule]"
                  />
                </BFlex>
                <BFlex :span="12">
                  <BInput
                    v-model="editingCompanyInfo.firstNameKana"
                    v-model:valid="valid.firstNameKana"
                    label="last_name_kana"
                    :placeholder="$t('callTargetInfo.item.firstNameKana')"
                    :max-length="255"
                    :validation="true"
                    :rules="[kanaRule]"
                  />
                </BFlex>
              </BLayout>
            </BListItem>
            <BListItem class="list-item">
              <template #header>
                <div>{{ $t('callTargetInfo.item.salutation') }}</div>
              </template>
              <BSelect
                v-model="editingCompanyInfo.salutation"
                label="salutation"
                :items="editingSalutationOptions"
                fit
                clearable
              />
            </BListItem>
            <BListItem class="list-item">
              <template #header>
                <div>{{ $t('callTargetInfo.item.personalTel') }}</div>
              </template>
              <BInput
                v-model="editingCompanyInfo.personalTel"
                v-model:valid="valid.personalTel"
                label="personal_tel"
                :max-length="255"
                :validation="true"
                :rules="[phoneRule]"
              />
            </BListItem>
            <BListItem class="list-item">
              <template #header>
                <div>{{ $t('callTargetInfo.item.email') }}</div>
              </template>
              <BInput
                v-model="editingCompanyInfo.email"
                v-model:valid="valid.targetEmail"
                label="target_email"
                :max-length="255"
                :validation="true"
                :rules="[emailRule]"
              />
            </BListItem>
            <BListItem class="list-item">
              <template #header>
                <div>{{ $t('callTargetInfo.item.department') }}</div>
              </template>
              <BInput
                v-model="editingCompanyInfo.department"
                v-model:valid="valid.departments"
                label="department"
                :max-length="255"
                :validation="true"
              />
            </BListItem>
            <BListItem class="list-item">
              <template #header>
                <div>{{ $t('callTargetInfo.item.position') }}</div>
              </template>
              <BInput
                v-model="editingCompanyInfo.position"
                v-model:valid="valid.position"
                label="position"
                :max-length="255"
                :validation="true"
              />
            </BListItem>
          </BLayout>
        </div>
        <div class="grid-custom-item px-200 pb-500 comment-grid">
          <div class="block-title">
            {{ $t('general.comment') }}
          </div>
          <BDivider class="mt-50 mb-300" />
          <BLayout
            class="px-200"
            justify-center
            column
          >
            <BListItem class="list-item">
              <template #header>
                <div>{{ $t('general.comment') }}</div>
              </template>
              <BTextarea
                v-model="editingComment"
                label="comment"
                resize="none"
                :autosize="{ minRows: 4, maxRows: 10 }"
              />
            </BListItem>
          </BLayout>
        </div>
        <div
          v-if="editingCustomFieldValues.length !== 0"
          class="grid-custom-item px-200 pb-500 custom-grid"
        >
          <div class="block-title">
            {{ $t('customField.title') }}
          </div>
          <BDivider class="mt-50 mb-300" />
          <BLayout
            class="px-200"
            justify-center
            column
          >
            <BListItem
              v-for="field in editingCustomFieldValues"
              :key="field.customFieldId"
              class="list-item"
            >
              <template #header>
                <div>{{ field.name }}</div>
              </template>
              <BInput
                v-if="field.dataType === dataType.text"
                v-model="field.customFieldText"
                v-model:valid="valid.customFieldTexts[field.customFieldId]"
                label="custom_field_text"
                :max-length="1000"
                :validation="true"
              />
              <BInputDateTime
                v-else-if="field.dataType === dataType.datetime"
                v-model:date="field.customFieldDate"
                v-model:time="field.customFieldTime"
                label="custom_field_data_time"
              />
              <BSelect
                v-else-if="field.dataType === dataType.option"
                v-model="field.customFieldOption"
                label="custom_field_option"
                :items="field.options"
                item-text="label"
                item-value="id"
                filterable
                fit
                clearable
              />
              <BInput
                v-else-if="field.dataType === dataType.number"
                v-model="field.customFieldNumber"
                v-model:valid="valid.customFieldNumbers[field.customFieldId]"
                label="custom_field_num"
                :max-length="1000"
                :validation="true"
                type="number"
              />
              <BSelect
                v-else-if="field.dataType === dataType.multiOption"
                v-model="field.customFieldOption"
                label="custom_field_multi_option"
                :items="field.options"
                item-text="label"
                item-value="id"
                multiple
                fit
              />
            </BListItem>
          </BLayout>
        </div>
      </div>
    </BModalBody>
    <BModalFooter>
      <BLayout justify-center>
        <BBtn
          :loading="loading"
          :disabled="!isValid"
          type="primary"
          @click="createCallTarget"
        >
          <span>{{ $t(`general.save.text`) }}</span>
        </BBtn>
      </BLayout>
    </BModalFooter>
  </BHeightAdjuster>
</template>

<script>
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex';
import errorHandler from '@/mixins/error_handler'
import inputValidation from '@/mixins/input_validation'
import { mergeDateAndTime } from '@/utils/date-time';

export default {
  name: 'CallTargetSetting',
  mixins: [inputValidation, errorHandler],
  props: {
    customFields: Array,
  },
  data () {
    return {
      loading: false,
      valid: {
        companyName: false,
        companyTel_1: false,
        companyUrl: false,
        companyCountry: false,
        companyPostalCode: false,
        companyPrefecture: false,
        companyCity: false,
        companyAddressLine: false,
        businessCategory: false,
        numberOfEmployees: false,
        lastName: false,
        lastNameKana: false,
        firstName: false,
        firstNameKana: false,
        personalTel: false,
        targetEmail: false,
        departments: false,
        position: false,
        customFieldTexts: {},
        customFieldNumbers: {},
      },
      editingCompanyInfo: {},
      editingCustomFieldValues: [],
      editingFreeTagIds: [],
      editingLeadStageId: null,
      editingLeadSourceIds: [],
      editingSalutationOptions: [
        { text: '様', value: '様' },
      ],
      editingComment: null,
      dataType: {
        text: 'text',
        datetime: 'datetime',
        option: 'option',
        number: 'number',
        multiOption: 'multi_option',
      },
    }
  },
  computed: {
    ...mapGetters('user', [
      'freeTags',
      'leadStages',
      'leadSources',
    ]),
    selectableLeadSources () {
      if (this.leadSources == null) return []
      return this.leadSources.map((leadSource) => {
        return {
          id: leadSource.id,
          label: leadSource.name,
        }
      })
    },
    selectableFreeTags () {
      if (this.freeTags == null) return []
      return this.freeTags.map((freeTag) => {
        return {
          id: freeTag.id,
          label: freeTag.text,
        }
      })
    },
    modalName () {
      return 'callTargetSetting'
    },
    isValid () {
      return !Object.values(this.valid).includes(false) && !Object.values(this.valid.customFieldTexts).includes(false)
    },
  },
  created () {
    this.initializeInputFieldValues()
  },
  methods: {
    ...mapWaitingActions('user', {
      createCallTargetAction: 'createCallTargetWait',
      getCustomFieldsAction: 'getCustomFieldsWait',
      getFreeTagsAction: 'getFreeTagsWait',
      getLeadSourcesAction: 'getLeadSourcesWait',
      getLeadStagesAction: 'getLeadStagesWait',
    }),
    hide () {
      this.$modal.hide(this.modalName)
    },
    initializeInputFieldValues() {
      this.initializeCustomFieldValues()
      this.initializeLeadStageSelectionValues()
      if (this.freeTags.length === 0) this.getFreeTagsAction()
      if (this.leadSources.length === 0) this.getLeadSourcesAction()
    },
    async initializeLeadStageSelectionValues () {
      if (this.leadStages.length === 0) await this.getLeadStagesAction()
      this.editingLeadStageId = this.leadStages.find(e => e.isPreset).id
    },
    async initializeCustomFieldValues () {
      if (this.customFields.length === 0) await this.getCustomFieldsAction()
      this.editingCustomFieldValues = this.customFields.map(customField => {
        return {
          dataType: customField.dataType,
          name: customField.name,
          customFieldId: customField.id,
          customFieldValue: null,
          customFieldText: null,
          customFieldDate: null,
          customFieldTime: null,
          customFieldOption: null,
          customFieldNumber: null,
          options: customField.options,
        }
      })
    },
    async createCallTarget () {
      try {
        this.loading = true
        await this.createCallTargetAction({
          body: {
            callTargetDetail: this.editingCompanyInfo,
            customFieldValues: this.editingCustomFieldValues.map(customFieldValue => {
              return this.customFieldValueForCreate(customFieldValue)
            }),
            freeTagIds: this.editingFreeTagIds,
            leadSourceIds: this.editingLeadSourceIds,
            leadStageId: this.editingLeadStageId,
            comment: this.editingComment,
          },
          errorHandlers: { 422: this.defaultHandler },
        })
        this.editingCompanyInfo = {}
        this.initializeCustomFieldValues()
        this.$toast.show(this.$t('general.done.of', { action: this.$t('general.save.text') }))
        this.loading = false
        this.hide()
      } catch (e) {
        this.loading = false
        this.$toast.show({
          message: this.$t('general.fail.withTarget', { target: this.$t('companyInfo.title'), action: this.$t('general.append.text') }),
          type: 'error',
        })
      }
    },
    customFieldValueForCreate (customFieldValue) {
      return this.absorbDataTypeDifference(customFieldValue)
    },
    absorbDataTypeDifference (customFieldValue) {
      if (customFieldValue.dataType === this.dataType.datetime && customFieldValue.customFieldTime) {
        customFieldValue.customFieldTime = customFieldValue.customFieldTime.setSeconds(0)
        customFieldValue.customFieldValue = mergeDateAndTime(customFieldValue.customFieldDate, customFieldValue.customFieldTime)
      } else if (customFieldValue.dataType === this.dataType.text && customFieldValue.customFieldText) {
        customFieldValue.customFieldValue = customFieldValue.customFieldText
      } else if (customFieldValue.dataType === this.dataType.option && customFieldValue.customFieldOption) {
        customFieldValue.customFieldValue = customFieldValue.customFieldOption
      } else if (customFieldValue.dataType === this.dataType.number && customFieldValue.customFieldNumber) {
        customFieldValue.customFieldValue = customFieldValue.customFieldNumber
      } else if (customFieldValue.dataType === this.dataType.multiOption && customFieldValue.customFieldOption) {
        customFieldValue.customFieldValue = customFieldValue.customFieldOption
      }
      return customFieldValue
    },
  },
}
</script>

<style lang="scss" scoped>
  .grid {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr;
    @media only screen and (max-width: 959px) {
      grid-template-columns: 1fr;
    }
  }

  .grid-item:nth-child(odd){
    grid-column: 1;
  }

  .grid-item:nth-child(even){
    grid-column: 2;
    @media only screen and (max-width: 959px) {
      grid-column: 1;
    }
  }

  .lead-grid {
    grid-row: span 2;
  }

  .company-info-grid {
    grid-row: span 4;
  }

  .target-info-grid {
    grid-row: span 4;
  }

  .comment-grid {
    grid-row: span 1;
  }

  .custom-grid {
    grid-column: 1;
  }

  .custom-filed-list-item {
    padding-right: 40px;
    padding-left: 24px;
    margin-bottom: $basespace-300;
  }

  .block-title {
    font-weight: bold;
    font-size: $fontsize-300;
  }

  .list-item {
    padding-right: $basespace-300;
    margin-bottom: $basespace-300;
  }
</style>

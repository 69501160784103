<template>
  <BHeightAdjuster>
    <BModalBody>
      <div class="setting-message">
        {{ $t('callResult.message') }}
      </div>
      <div class="list-content">
        <div v-loading="loading">
          <div class="item-content">
            <BEmptyBox
              v-if="!$wait.is('getCallResultTypesInModalWait') && !isExistCallResultTypes"
              class="py-400"
              display-only
            >
              <slot name="text">
                {{ $t('callResult.emptyCustomCallResult') }}
              </slot>
            </BEmptyBox>
            <template v-else>
              <div
                v-for="type in defaultCallResultTypes"
                :key="`default${type.id}`"
                class="call-result"
              >
                <BLayout
                  class="ml-100 py-100"
                  align-cente
                >
                  <BTooltip
                    top
                    :content="$t('callResult.defaultConfirm')"
                  >
                    <BIcon
                      class="mr-50"
                      size="small"
                      type="primary"
                    >
                      star
                    </BIcon>
                  </BTooltip>
                  <span>{{ type.name }}</span>
                </BLayout>
              </div>
              <template v-if="callResultTypes.length > 0">
                <Draggable
                  v-model="internalCallResultTypes"
                  class="item-row"
                  item-key="id"
                  handle=".handle"
                  :animation="300"
                  @end="updateCallResultTypes"
                >
                  <template #item="{ element: type }">
                    <BLayout
                      class="py-100"
                      align-center
                      justify-space-between
                    >
                      <BLayout
                        class="ml-100 mr-100"
                        align-center
                      >
                        <BIcon
                          class="handle"
                          size="small"
                          type="gray"
                        >
                          drag_indicator
                        </BIcon>
                        <BEditableContent
                          :value="type.name"
                          :placeholder="$t('callResult.name')"
                          :loading="updateLoading"
                          :rules="[requiredRule]"
                          :max-length="30"
                          @check="handleUpdate($event, type.id)"
                        />
                        <div
                          class="connected mr-100"
                        >
                          {{ $tm(`callResult.connected.enum.${type.connected}`) }}
                        </div>
                      </BLayout>
                      <BMoreMenu>
                        <BPopover
                          v-if="type.isUsed"
                          placement="top"
                          width="240"
                          trigger="hover"
                        >
                          <template #reference>
                            <div>
                              <BBtn
                                flat
                                fit
                                size="small"
                                disabled
                              >
                                {{ $t('general.delete.do') }}
                              </BBtn>
                            </div>
                          </template>
                          <div>{{ $t('general.alreadyUsed') }}</div>
                        </BPopover>
                        <BBtn
                          v-else
                          flat
                          fit
                          size="small"
                          @click="() => deleteCallResultType(type)"
                        >
                          {{ $t('general.delete.do') }}
                        </BBtn>
                      </BMoreMenu>
                    </BLayout>
                  </template>
                </Draggable>
              </template>
              <BEmptyBox
                v-else
                class="mt-200 py-600"
                display-only
              >
                <slot name="text">
                  {{ $t('callResult.emptyCustomCallResult') }}
                </slot>
              </BEmptyBox>
            </template>
          </div>
        </div>
      </div>
    </BModalBody>
    <BModalFooter>
      <BLayout
        class="footer"
        align-center
        justify-center
      >
        <BInput
          v-model="inputName"
          v-model:valid="createValid"
          class="input-name mr-400"
          :placeholder="$t('callResult.name')"
          :rules="[requiredRule]"
          :disabled="createLoading"
          :max-length="30"
          @keypress-enter="handleCreate"
        />
        <BSelect
          v-model="connected"
          :items="connectedTypes"
          :disabled="createLoading"
          class="input-type mr-400"
        />
        <BBtn
          type="primary"
          :disabled="!createValid"
          :loading="$wait.is('createCallResultTypeWait')"
          @click="handleCreate"
        >
          <span>{{ $t('general.append.text') }}</span>
        </BBtn>
      </BLayout>
    </BModalFooter>
  </BHeightAdjuster>
</template>

<script>
import lodash from 'lodash'
import { mapWaitingActions } from 'vue-wait'
import Draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import errorHandler from '@/mixins/error_handler'
import inputValidation from '@/mixins/input_validation'

export default {
  components: {
    Draggable,
  },
  mixins: [inputValidation, errorHandler],
  data () {
    return {
      inputName: null,
      createValid: false,
      waitNames: ['getCallResultTypesWait', 'updateCallResultTypeWait', 'deleteCallResultTypeWait', 'updateCallResultTypesWait'],
      internalCallResultTypes: [],
      connected: 'true',
    }
  },
  computed: {
    ...mapGetters('user', [
      'callResultTypes',
      'defaultCallResultTypes',
    ]),
    isExistCallResultTypes () {
      return this.callResultTypes.length > 0 || this.defaultCallResultTypes.length > 0
    },
    loading () {
      return this.$wait.is(['getCallResultTypesInModalWait', 'createCallResultTypeInModalWait', 'updateCallResultTypeInModalWait', 'deleteCallResultTypeInModalWait', 'updateCallResultTypesInModalWait'])
    },
    createLoading () {
      return this.$wait.is(['createCallResultTypeInModalWait', 'getCallResultTypesInModalWait'])
    },
    updateLoading () {
      return this.$wait.is(['updateCallResultTypeInModalWait', 'getCallResultTypesInModalWait'])
    },
    deleteLoading () {
      return this.$wait.is(['deleteCallResultTypeInModalWait', 'deleteCallResultTypeInModalWait'])
    },
    connectedTypes () {
      return Object.entries(this.$tm('callResult.connected.enum')).map(([value, text]) => ({ value, text }))
    },
  },
  watch: {
    callResultTypes (newVal) {
      this.internalCallResultTypes = lodash.cloneDeep(newVal)
    },
  },
  async created () {
    await this.getCallResultTypesAction()
    this.internalCallResultTypes = lodash.cloneDeep(this.callResultTypes)
  },
  methods: {
    ...mapWaitingActions('user', {
      getCallResultTypesAction: 'getCallResultTypesInModalWait',
      createCallResultTypeAction: 'createCallResultTypeInModalWait',
      updateCallResultTypeAction: 'updateCallResultTypeInModalWait',
      deleteCallResultTypeAction: 'deleteCallResultTypeInModalWait',
      updateCallResultTypesAction: 'updateCallResultTypesInModalWait',
    }),
    async handleCreate () {
      await this.createCallResultTypeAction({
        body: { callResultType: { name: this.inputName, connected: this.connected } },
        errorHandlers: {
          422: this.defaultHandler,
        },
      })
      this.inputName = null
    },
    async handleUpdate (newTypeName, callResultTypeId) {
      await this.updateCallResultTypeAction({
        id: callResultTypeId,
        name: newTypeName,
        errorHandlers: {
          422: this.defaultHandler,
        },
      })
    },
    async deleteCallResultType (callResultType) {
      const check = await this.$bitterAlert.show({
        title: this.$t('callResult.title'),
        text: this.$t('general.confirm.actionWithTarget', { target: callResultType.name, action: this.$t('general.delete.text') }),
        closeOnClickOutside: false,
        buttons: { cancel: true, confirm: true },
      })
      if (!check) { return }
      await this.deleteCallResultTypeAction({
        id: callResultType.id,
      })
    },
    async updateCallResultTypes () {
      const types = this.internalCallResultTypes.map((type, index) => {
        type.order = index
        return type
      })
      await this.updateCallResultTypesAction({
        body: { callResultTypes: types },
        errorHandlers: {
          422: this.defaultHandler,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
:deep(.b-modal-body) {
  margin-top: 20px;
  padding: 0 22px 20px;
  .list-content {
    overflow-y: auto;
    .item-content {
      height: calc(100vh - 320px);
    }
    .item-row {
      margin: 0;
      @media only screen and (min-width: 959px) {
        width: 70%;
      }
      .b-input-box {
        margin-left: 8px;
      }
      .connected {
        width: 200px;
      }
    }
  }
}

.setting-message {
  color: $textcolor-light;
  margin-bottom: $basespace-400;
  white-space: pre-line;
  margin-left: $basespace-100;
}

.handle {
  cursor: grab;
  margin-right: $basespace-50;
}

.form-box {
  background: $bgcolor-base;
  padding: $basespace-200;
}

.footer {
  padding-top: $basespace-300;
  .input-name {
    width: $setting-input-width;
  }
  .input-type {
    width: 120px;
  }
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

import { onBeforeMount, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex';
import { CurrentUserApiService } from '@/api/user/resources/current_user';
import { UserApiService } from '@/api/user/resources/user';
import { useErrorHandler } from '@/composable/error-handler';
import { useInputValidations } from '@/composable/input-validations';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { bitterAlert } from '@/plugins/BBitterAlert';
import { key } from '@/store/index'


export default /*@__PURE__*/_defineComponent({
  __name: 'UserProfile',
  setup(__props) {

const { currentUser, fetchCurrentUser } = useCurrentUser();
const { doActionWithWait } = useWait();
const { requiredRule, emailRule } = useInputValidations();
const { defaultHandler } = useErrorHandler();
const store = useStore(key);
const i18n = useI18n();

const inputName = ref<string | null>(null);
const inputLastName = ref<string | null>(null);
const inputFirstName = ref<string | null>(null);
const nameValid = ref<boolean>(false);
const lastNameValid = ref<boolean>(false);
const firstNameValid = ref<boolean>(false);
const avatarImage = ref<Blob | null>(null);
const inputEmail = ref<string | null>(null);
const emailValid = ref<boolean>(false);

const isUserInfoValid = computed(() => nameValid.value && lastNameValid.value && firstNameValid.value);
const isEmailValid = computed(() => emailValid.value);

onBeforeMount(async () => {
  await fetchCurrentUser();

  const { name, lastName, firstName, email } = currentUser.value;
  inputName.value = name;
  inputLastName.value = lastName;
  inputFirstName.value = firstName;
  inputEmail.value = email;
});

const updateProfile = async () => {
  const api = new CurrentUserApiService();
  doActionWithWait('updateProfile', async () => {
    if (avatarImage.value != null) {
      const fd = new FormData()
      fd.append('image', avatarImage.value, 'avatar.png')
      await api.updateCurrentUserAvatarImage({
        request: {
          image: fd.get('image'),
        },
        errorHandlers: {
          422: defaultHandler,
        },
      });
    }
    await api.updateCurrentUser({
      request: {
        putCurrentUserRequest: {
          name: inputName.value,
          lastName: inputLastName.value,
          firstName: inputFirstName.value,
        },
      },
    });
    // NOTE: Vuexで持っているユーザ情報を取り直す（この処理は後々消したい）
    store.dispatch('user/getCurrentUserAction');
  });
};

const handleUpdateEmailClick = async () => {
  const ok = await bitterAlert.show({
    title: i18n.t('changeEmail.confirmModal.title'),
    text: i18n.t('changeEmail.confirmModal.explain', { itemName: i18n.t('user.loginEmail') }),
    buttonsCancel: i18n.t('general.cancel.text'),
    className: 'swal-modal-large',
  });
  if (!ok) return;

  await doActionWithWait('prepareLoginEmailUpdate', async () => {
    const api = new UserApiService();
    await api.prepareLoginEmailUpdate({
      request: {
        prepareLoginEmailUpdateBody: {
          email: inputEmail.value,
        },
      },
    });
  });
};

return (_ctx: any,_cache: any) => {
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BAvatarUpload = _resolveComponent("BAvatarUpload")!
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BContentBox = _resolveComponent("BContentBox")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_BContentBox, null, {
    content: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock(_component_BLayout, {
        column: "",
        class: "form"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BListItem, { class: "title" }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('user.userInfo')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_BListItem, { class: "mt-400" }, {
            header: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('user.avatarImage')), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_BAvatarUpload, {
                modelValue: avatarImage.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((avatarImage).value = $event)),
                "image-url": _unref(currentUser)?.avatarUrl
              }, null, 8, ["modelValue", "image-url"])
            ]),
            _: 1
          }),
          _createVNode(_component_BListItem, { class: "mt-400" }, {
            header: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('user.name')), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_BInput, {
                modelValue: inputName.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((inputName).value = $event)),
                valid: nameValid.value,
                "onUpdate:valid": _cache[2] || (_cache[2] = ($event: any) => ((nameValid).value = $event)),
                class: "w-input",
                rules: [_unref(requiredRule)],
                clearable: ""
              }, null, 8, ["modelValue", "valid", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_BListItem, { class: "mt-400" }, {
            header: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('user.lastName')), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_BInput, {
                modelValue: inputLastName.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((inputLastName).value = $event)),
                valid: lastNameValid.value,
                "onUpdate:valid": _cache[4] || (_cache[4] = ($event: any) => ((lastNameValid).value = $event)),
                class: "w-input",
                rules: [_unref(requiredRule)],
                clearable: ""
              }, null, 8, ["modelValue", "valid", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_BListItem, { class: "mt-200" }, {
            header: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('user.firstName')), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_BInput, {
                modelValue: inputFirstName.value,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((inputFirstName).value = $event)),
                valid: firstNameValid.value,
                "onUpdate:valid": _cache[6] || (_cache[6] = ($event: any) => ((firstNameValid).value = $event)),
                class: "w-input",
                rules: [_unref(requiredRule)],
                clearable: ""
              }, null, 8, ["modelValue", "valid", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_BListItem, { class: "mt-400" }, {
            default: _withCtx(() => [
              _createVNode(_component_BBtn, {
                disabled: !isUserInfoValid.value,
                type: "primary",
                onClick: updateProfile
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.save.text')), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          }),
          _createVNode(_component_BListItem, { class: "title mt-900" }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('user.loginEmail')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_BListItem, { class: "mt-400" }, {
            header: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('user.email')), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_BInput, {
                modelValue: inputEmail.value,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((inputEmail).value = $event)),
                valid: emailValid.value,
                "onUpdate:valid": _cache[8] || (_cache[8] = ($event: any) => ((emailValid).value = $event)),
                rules: [_unref(requiredRule), _unref(emailRule)],
                class: "w-input"
              }, null, 8, ["modelValue", "valid", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_BListItem, { class: "title mt-400" }, {
            default: _withCtx(() => [
              _createVNode(_component_BBtn, {
                disabled: !isEmailValid.value,
                type: "primary",
                onClick: handleUpdateEmailClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.save.text')), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })), [
        [_directive_loading, _ctx.$wait.is(['updateProfile', 'prepareLoginEmailUpdate'])]
      ])
    ]),
    _: 1
  }))
}
}

})
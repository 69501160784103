<template>
  <BListItem :class="itemClass">
    <template #header>
      <span>{{ $t('nextAction.actionType.title') }}</span>
    </template>
    <BSelect
      v-model="actionType"
      :items="nextActionTypes"
      item-text="name"
      item-value="id"
      fit
      required
    />
  </BListItem>
  <BListItem :class="itemClass">
    <template #header>
      <span>{{ $t('general.priority.title') }}</span>
    </template>
    <BPrioritySelect
      v-model="priority"
      fit
      required
    />
  </BListItem>
  <BListItem
    v-if="withOwner"
    :class="itemClass"
  >
    <template #header>
      <span>{{ $t('nextAction.owner') }}</span>
    </template>
    <BSelect
      v-model="ownerId"
      :items="activeUsers"
      item-text="name"
      item-value="id"
      fit
      required
    />
  </BListItem>
  <slot name="dateTime" />
  <BListItem :class="itemClass">
    <template #header>
      <span v-if="callTargetId == null">{{ $t('nextAction.content') }}</span>
    </template>
    <BBtn
      v-if="callTargetId != null"
      text
      type="success"
      @click="handleQuickTextButtonClick"
    >
      <BIcon
        type="success"
        outlined
      >
        article
      </BIcon>
      <span class="ml-50 fw-bold">{{ $t('quickText.apply') }}</span>
    </BBtn>
    <BInput
      ref="contentTextarea"
      v-model="content"
      v-model:valid="isNextActionContentValid"
      type="textarea"
      :autosize="{ minRows: 12, maxRows: 12 }"
      :max-length="1000"
      :placeholder="$t('nextAction.content')"
      resize="none"
      validation
      copyable
    />
  </BListItem>
  <QuickTextSelectModal
    :modal-name="quickTextSelectModalName"
    :call-target-id="callTargetId"
    @select="handleQuickTextSelect"
  />
</template>

<script lang="ts" setup>
import { computed, ref, watch, inject } from 'vue';
import { PriorityPriorityEnum } from '@/api/openapi';
import QuickTextSelectModal from '@/components/organisms/user/lead/modal/QuickTextSelectModal.vue';
import { useInsertText } from '@/composable/input';
import { useNextActionTypes } from '@/composable/user/nextActionType/next-action-types';
import { useUsers } from '@/composable/user/user/users';
import { KEY_OF_MODAL } from '@/injection-keys';
import BInput from '@/plugins/biscuet-materials/components/atoms/BInput.vue';

type TProps = {
  modelValue: TCommonNextAction;
  isValid: boolean;
  isShort?: boolean;
  callTargetId?: number | null;
  withOwner?: boolean;
};
type TEmit = {
  'update:modelValue': [modelValue: TCommonNextAction];
  'update:isValid': [isValid: boolean];
}
const props = withDefaults(defineProps<TProps>(), {
  isShort: false,
  callTargetId: null,
  withOwner: true,
});
const emit = defineEmits<TEmit>();

export type TCommonNextAction = TCommonNextActionWithoutOwner | TCommonNextActionWithOwner;
type TKeyOfCommonNextAction = keyof TCommonNextAction | keyof TCommonNextActionWithOwner;
type TCommonNextActionWithoutOwner = {
  actionType: string;
  priority: PriorityPriorityEnum;
  content: string;
};
type TCommonNextActionWithOwner = TCommonNextActionWithoutOwner & {
  ownerId: number;
};
const isModelWithOwner = (model: TCommonNextAction): model is TCommonNextActionWithOwner => {
  return 'ownerId' in model;
};

const { nextActionTypes } = useNextActionTypes();
const { activeUsers } = useUsers();

const isNextActionContentValid = ref(true);

const actionType = computed({
  get: () => props.modelValue.actionType,
  set: (v) => updateFormValues('actionType', v),
});
const priority = computed({
  get: () => props.modelValue.priority,
  set: (v) => updateFormValues('priority', v),
});
const ownerId = computed({
  get: () => isModelWithOwner(props.modelValue) ? props.modelValue.ownerId : null,
  set: (v) => updateFormValues('ownerId', v),
});
const content = computed({
  get: () => props.modelValue.content,
  set: (v) => updateFormValues('content', v),
});

const itemClass = computed(() => ({
  'mb-200': props.isShort,
  'mb-300': !props.isShort,
}));

const contentTextarea = ref<InstanceType<typeof BInput>>(null);
const { insertText: insertTextToContent } = useInsertText(contentTextarea, content)

const handleQuickTextSelect = (content: string) => {
  insertTextToContent(content)
}

const quickTextSelectModalName = `quickTextSelectInNextActionFormBody-${crypto.randomUUID()}`

const modal = inject(KEY_OF_MODAL);
const handleQuickTextButtonClick = () => {
  modal.show(quickTextSelectModalName)
};

watch(
  () => props.modelValue,
  (val) => {
    const isValid = val.actionType != null
      && isNextActionContentValid.value
      && isModelWithOwner(val) ? val.ownerId != null : true;
    emit('update:isValid', isValid);
  },
  { immediate: true },
);

const updateFormValues = (key: TKeyOfCommonNextAction, value: unknown) => {
  const newModelValue = {
    ...props.modelValue,
    [key]: value,
  };

  emit('update:modelValue', newModelValue);
};
</script>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "fs-400 fw-bold" }
const _hoisted_2 = { class: "select-wrapper mt-100 mb-300 pl-600" }

import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { Trigger, TriggerBody } from '@/api/openapi';
import { TriggerApiService } from '@/api/user/resources/trigger';
import TriggerForm from '@/components/organisms/user/trigger/TriggerForm.vue';
import TriggerLeads from '@/components/organisms/user/trigger/TriggerLeads.vue';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import { useErrorHandler } from '@/composable/error-handler';
import { useUrlLinkBtabsHandlers } from '@/composable/url-link-b-tabs';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { i18nGlobal as i18n } from '@/i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'TriggerLeads',
  setup(__props) {

const { isAvailableTriggerFeature } = useAvailableFeatureCheck()
const route = useRoute();
const {
  wait,
  doActionWithWait,
} = useWait();
const {
  redirectToNotFoundPage,
} = useErrorHandler();
const { currentUser, fetchCurrentUser } = useCurrentUser();

const api = new TriggerApiService();

const triggerId = parseInt(route.params.id as string);
const trigger = ref<Trigger>();
const triggerBody = ref<TriggerBody>({
  name: '',
  ownerId: null,
});
const selectedUserId = ref<number | undefined>();

watch(trigger, (newValue) => {
  triggerBody.value.name = newValue?.name || '';
  triggerBody.value.ownerId = newValue?.owner?.id || null;
});

const activeName = ref('lead');
const tabLabelItems = [
  { label: i18n.t('trigger.action'), slotName: 'action', route: { name: 'Trigger', params: { id: triggerId } } },
  { label: i18n.t('trigger.lead'), slotName: 'lead' },
];
const { handleTabClick, handleTabBeforeLeave } = useUrlLinkBtabsHandlers(tabLabelItems);

const loading = computed(() => wait.is(['getTriggerWait']));

onMounted(async () => {
  await fetchCurrentUser();
  selectedUserId.value = currentUser.value.id;
  await fetchTrigger();
});

const fetchTrigger = async () => {
  if (!triggerId) return;

  await doActionWithWait('getTriggerWait', async () => {
    const { data } = await api.getTrigger({
      request: {
        triggerId,
      },
      errorHandlers: {
        404: redirectToNotFoundPage,
      },
    });
    trigger.value = data.trigger;
  });
};

return (_ctx: any,_cache: any) => {
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_SelectUser = _resolveComponent("SelectUser")!
  const _component_BTabs = _resolveComponent("BTabs")!
  const _component_BContent = _resolveComponent("BContent")!
  const _directive_loading = _resolveDirective("loading")!

  return (_unref(isAvailableTriggerFeature))
    ? _withDirectives((_openBlock(), _createBlock(_component_BContent, { key: 0 }, {
        navbar: _withCtx(() => [
          _createVNode(_component_BLayout, {
            "align-start": "",
            "justify-start": ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('trigger.title')), 1)
            ]),
            _: 1
          })
        ]),
        waiting: _withCtx(() => [
          _withDirectives(_createVNode(_component_BPlaceholder, { "element-loading-background": "transparent" }, null, 512), [
            [_directive_loading, true]
          ])
        ]),
        footer: _withCtx(() => _cache[4] || (_cache[4] = [])),
        default: _withCtx(() => [
          _createVNode(TriggerForm, {
            modelValue: triggerBody.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((triggerBody).value = $event)),
            "wait-for": "getTriggerWait",
            "is-display-only": "true"
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_SelectUser, {
              modelValue: selectedUserId.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedUserId).value = $event)),
              "use-all-label": _ctx.$t('trigger.target.allApproveUser')
            }, null, 8, ["modelValue", "use-all-label"])
          ]),
          _createVNode(_component_BTabs, {
            modelValue: activeName.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((activeName).value = $event)),
            class: "tabs",
            "label-items": tabLabelItems,
            "before-leave": _unref(handleTabBeforeLeave),
            "onClick:tab": _unref(handleTabClick)
          }, {
            lead: _withCtx(() => [
              _createVNode(TriggerLeads, {
                modelValue: trigger.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((trigger).value = $event)),
                "owner-id": selectedUserId.value
              }, null, 8, ["modelValue", "owner-id"])
            ]),
            _: 1
          }, 8, ["modelValue", "before-leave", "onClick:tab"])
        ]),
        _: 1
      })), [
        [_directive_loading, loading.value]
      ])
    : _createCommentVNode("", true)
}
}

})
<template>
  <BLayout
    column
    class="my-600"
  >
    <h3 class="mb-300">
      {{ $t('import.salesforce.idSearchTitle') }}
    </h3>
    <BLayout
      align-center
    >
      <div>
        {{ $t('import.salesforce.nameTargetObject') }}
      </div>
      <BInput
        v-model="targetObject"
        class="mx-200"
        :placeholder="$t('general.search.placeholder')"
        :disabled="loading"
      />
      <div>
        {{ $t('import.salesforce.idObjectKey') }}
      </div>
      <BInput
        v-model="objectId"
        class="mx-200"
        :placeholder="$t('general.search.placeholder')"
        :disabled="loading"
      />
      <BBtn
        size="small"
        type="primary"
        :disabled="loading"
        @click="search"
      >
        <span>{{ $t('general.search.exec') }}</span>
      </BBtn>
    </BLayout>
    <div
      v-if="Object.keys(object).length > 0"
      class="import-confirm-list my-200"
    >
      <div
        v-if="Object.keys(object).length > 0"
      >
        <pre>{{ object }}</pre>
      </div>
    </div>
  </BLayout>
</template>
  
<script setup lang="ts">
import { ref } from 'vue';
import api from '@/api/admin/resources/salesforce';
  
  type TProps = {
    id: number;
  };
const props = defineProps<TProps>();
  
const loading = ref<boolean>(false)
const targetObject = ref<string>('')
const objectId = ref<string>('')
const object = ref<object>({})
  
const errorHandler = (error) => {
  object.value = {}
  loading.value = false
}
  
const search = async () => {
  loading.value = true
  object.value = {}
  const result = await api.searchSalesforceObject({
    objectId: objectId.value,
    targetObject: targetObject.value,
    clientId: props.id,
  }).catch(errorHandler)
  if (result == undefined) return;
  
  object.value = JSON.stringify(result.data.salesforce.object, null, 2)
  loading.value = false
}
</script>
  

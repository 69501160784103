<template>
  <BContentBox>
    <template #content>
      <div
        v-loading="boxilLeadImportSettingLoading"
      >
        <BLayout
          justify-center
          column
          class="form"
        >
          <div class="setting-title">
            {{ $t(`corpSettingApi.boxil.title`) }}
          </div>
          <BListItem>
            <template #header>
              <div>{{ $t('corpSettingApi.boxil.apiKey') }}</div>
            </template>
            <BInput
              v-model="internalBoxilLeadImportSettingApiKey"
              class="w-input"
              :disabled="!$can('owner')"
            />
          </BListItem>
          <BListItem class="mt-100 mb-300">
            <BBtn
              class="mr-300 mb-300"
              :disabled="boxilLeadImportSettingLoading || !$can('owner')"
              @click="testBoxilLeadImportSetting"
            >
              <span>{{ $t(`corpSettingApi.boxil.test`) }}</span>
            </BBtn>
            <BBtn
              type="primary"
              :disabled="boxilLeadImportSettingLoading || !$can('owner')"
              @click="saveBoxilLeadImportSetting"
            >
              <span>{{ $t(`corpSettingApi.boxil.btnText`) }}</span>
            </BBtn>
          </BListItem>
        </BLayout>
      </div>
    </template>
  </BContentBox>
</template>

<script>
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import errorHandler from '@/mixins/error_handler'
import inputValidation from '@/mixins/input_validation'

export default {
  name: 'BoxilApi',
  mixins: [inputValidation, errorHandler],
  data () {
    return {
      internalBoxilLeadImportSettingApiKey: '',
    }
  },
  computed: {
    ...mapGetters('user', [
      'currentUser',
      'boxilLeadImportSetting',
    ]),
    boxilLeadImportSettingLoading() {
      return this.$wait.is([
        'getBoxilLeadImportSettingWait',
        'createBoxilLeadImportSettingWait',
        'deleteBoxilLeadImportSettingWait',
        'testBoxilLeadImportSettingWait',
      ])
    },
  },
  async created () {
    await Promise.all([
      this.getBoxilLeadImportSettingAction(),
    ])

    if (this.boxilLeadImportSetting?.apiKey != null) {
      this.internalBoxilLeadImportSettingApiKey = this.boxilLeadImportSetting.apiKey
    }
  },
  methods: {
    ...mapWaitingActions('user', {
      getBoxilLeadImportSettingAction: 'getBoxilLeadImportSettingWait',
      createBoxilLeadImportSettingAction: 'createBoxilLeadImportSettingWait',
      deleteBoxilLeadImportSettingAction: 'deleteBoxilLeadImportSettingWait',
      testBoxilLeadImportSettingAction: 'testBoxilLeadImportSettingWait',
    }),
    saveBoxilLeadImportSetting(){
      this.createBoxilLeadImportSettingAction({
        body: {
          boxilLeadImportSetting: {
            apiKey: this.internalBoxilLeadImportSettingApiKey,
          },
        },
      }).then((_res) => {
        this.$toast.show({
          message: this.$t('corpSettingApi.boxil.saveSuccess'),
          type: 'success',
        })
      }).catch((err) => {
        console.error(err)
        this.$toast.show({
          message: this.$t('corpSettingApi.boxil.saveError'),
          type: 'error',
        })
      })
    },
    testBoxilLeadImportSetting(){
      this.testBoxilLeadImportSettingAction({
        body: {
          boxilLeadImportSetting: {
            apiKey: this.internalBoxilLeadImportSettingApiKey,
          },
        },
      }).then((_res) => {
        this.$toast.show({
          message: this.$t('corpSettingApi.boxil.testSuccess'),
          type: 'success',
        })
      }).catch((err) => {
        console.error(err)
        this.$toast.show({
          message: this.$t('corpSettingApi.boxil.testError'),
          type: 'error',
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.setting-title {
  font-weight: bold;
  margin-bottom: 10px;
}
.form {
  @media only screen and (min-width: 959px) {
    .w-input {
      width: 40%;
    }
  }
}
</style>

import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import ApiBase from '@/api/base'
import {
  UserSenderEmailApi,
  UserSenderEmailApiPostUserSenderEmailPrepareRequest,
  UserSenderEmailApiPostUserSenderEmailConfirmRequest,
} from '@/api/openapi';

export class UserSenderEmailApiService extends ApiService {
  api = new UserSenderEmailApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true)
  }

  async prepareUserSenderEmail({
    request,
    options,
    errorHandlers,
  }: ApiArguments<UserSenderEmailApiPostUserSenderEmailPrepareRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.postUserSenderEmailPrepare(request, options),
      errorHandlers,
    );
  }

  async confirmUserSenderEmail({
    request,
    options,
    errorHandlers,
  }: ApiArguments<UserSenderEmailApiPostUserSenderEmailConfirmRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.postUserSenderEmailConfirm(request, options),
      errorHandlers,
    );
  }
}

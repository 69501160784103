<template>
  <div>
    <BCard class="pa-4">
      <div v-if="infosLoading">
        <BPlaceholder
          v-loading="true"
          element-loading-background="transparent"
        />
      </div>
      <div
        v-else
        class="infos-area"
      >
        <BContainer>
          <BLayout class="mb-200">
            <div class="mr-600">
              <div>{{ $t(`client.name`) }}</div>
              <div class="mt-100">
                {{ client.name }}
              </div>
            </div>
            <div>
              <div class="ml-400 mr-600">
                {{ $t(`client.slug`) }}
              </div>
              <BBtn
                flat
                @click="moveToLoginPage"
              >
                <span>{{ urlProtocol }}</span><span class="slug">{{ client.slug
                }}</span><span>{{ urlDomain }}</span>
              </BBtn>
            </div>
            <div class="ml-400">
              <div>{{ $t(`client.expiredAt`) }}</div>
              <div class="mt-100">
                {{ clientExpiredAt }}
              </div>
            </div>
            <div class="ml-400">
              <div>{{ $t(`client.status.title`) }}</div>
              <div class="mt-100">
                {{ $t(`client.status.enum.${client.status}`) }}
              </div>
            </div>
            <BBtn
              class="ml-a setting-btn"
              type="primary"
              @click="openClientEditDialog"
            >
              {{
                $t(`client.title`) + $t(`general.edit.text`)
              }}
            </BBtn>
          </BLayout>
          <BLayout class="mb-200">
            <div class="mr-300">
              <div>{{ $t(`clientLimitSetting.activeUserLimit`) }}</div>
              <div>{{ clientLimitSetting.activeUserLimit }}</div>
            </div>
            <div class="mr-300">
              <div>{{ $t(`clientLimitSetting.freeTagLimit`) }}</div>
              <div>{{ clientLimitSetting.freeTagLimit }}</div>
            </div>
            <div class="mr-300">
              <div>{{ $t(`clientLimitSetting.customFieldLimit`) }}</div>
              <div>{{ clientLimitSetting.customFieldLimit }}</div>
            </div>
            <div class="mr-300">
              <div>{{ $t(`clientLimitSetting.leadRegistrationLimit`) }}</div>
              <div>{{ clientLimitSetting.leadRegistrationLimit }}</div>
            </div>
            <div class="mr-300">
              <div>{{ $t(`clientLimitSetting.monthlyEmailsLimit`) }}</div>
              <div>{{ clientLimitSetting.monthlyEmailsLimit }}</div>
            </div>
            <div class="mr-300">
              <div>{{ $t(`clientLimitSetting.mailTemplateRegistrationLimit`) }}</div>
              <div>{{ clientLimitSetting.mailTemplateRegistrationLimit }}</div>
            </div>
            <div class="mr-300">
              <div>{{ $t('clientLimitSetting.leadStageLimit') }}</div>
              <div>{{ clientLimitSetting.leadStageLimit }}</div>
            </div>
            <div class="mr-300">
              <div>{{ $t('clientLimitSetting.sequenceLimit') }}</div>
              <div>{{ clientLimitSetting.sequenceLimit }}</div>
            </div>
            <BBtn
              class="ml-a setting-btn"
              type="primary"
              @click="openChangeLimitDialog"
            >
              {{
                $t(`clientLimitSetting.changeLimit`)
              }}
            </BBtn>
          </BLayout>
          <BLayout>
            <BBtn
              class="ml-a setting-btn"
              type="primary"
              @click="toggleVisibleDialog"
            >
              {{
                $t(`availableFeatureManagement.title`)
              }}
            </BBtn>
          </BLayout>
        </BContainer>
      </div>
    </BCard>
    <BContainer full>
      <BTabs :label-items="tabLabelItems">
        <template #user>
          <BCard
            shadow
            class="pa-4"
          >
            <BLayout
              class="mb-200"
              justify-space-between
              align-center
            >
              <BBtn
                type="primary"
                @click="openInviteDialog"
              >
                {{ $t(`client.account`) + $t(`general.invite`) }}
              </BBtn>
            </BLayout>
            <UsersList />
          </BCard>
        </template>
        <template #sendgrid>
          <BCard
            shadow
            class="pa-4"
          >
            <SendgridForm
              :is-admin-developer="isAdminDeveloper"
              :sendgrid-auth="clientSendgridAuth"
              :sendgrid-api-keys="sendgridApiKeys"
              :client-sendgrid-api-key="clientSendgridApiKey"
              @save="updateClientSendgridAuth"
              @decisive="createClientSendgridApiKey"
              @delete="deleteClientSendgridAuth"
            />
          </BCard>
        </template>
        <template
          v-if="clientAvailableFeatures.feature.salesforce_link"
          #salesforce
        >
          <BCard
            shadow
            class="pa-4"
          >
            <SalesforceForm
              :id="id"
            />
            <SalesforceObjectForm
              :id="id"
            />
            <SalesforceDescribe
              :id="id"
            />
          </BCard>
        </template>
        <template
          v-if="isAdminDeveloper"
          #sisense
        >
          <BCard
            shadow
            class="pa-4"
          >
            <SisenseUserForm
              :client-id="id"
            />
          </BCard>
        </template>
        <!--
          <template #mailVerification>
          <MailVerification
          :client="client"
          :mail-verification-info="mailVerificationInfo"
          :mail-verification-balance="mailVerificationBalance"
          @save="createMailVerification"
          @reflect="reflectMailVerificationToUnsubscribe"
          @change-page="getMailVerification"
          />
          </template> 
        -->
      </BTabs>
    </BContainer>
    <FormDialog
      :dialog="clientEditDialog"
      width="500"
    >
      <template #form>
        <ClientForm
          ref="clientEditForm"
          :client="clonedClient"
          :loading="$wait.is('updateClientWait')"
          @save="updateClient"
          @cancel="closeClientEditDialog"
        />
      </template>
    </FormDialog>
    <FormDialog
      :dialog="inviteDialog"
      width="500"
    >
      <template #form>
        <InviteForm
          ref="inviteForm"
          :loading="$wait.is('createUserWait')"
          @save="inviteUser"
          @cancel="closeInviteDialog"
        />
      </template>
    </FormDialog>
    <FormDialog
      :dialog="changeLimitDialog"
      width="500"
    >
      <template #form>
        <LimitForm
          v-bind="clientLimitSetting"
          ref="limitForm"
          :loading="$wait.is('updateClientLimitSettingWait')"
          @save="changeLimit"
          @cancel="closeChangeLimitDialog"
        />
      </template>
    </FormDialog>
    <FormDialog
      :dialog="visibleFeatureDialog"
      width="500"
    >
      <template #form>
        <SwitchAvailableFeatureForm
          :available-feature="clonedClientAvailableFeatures.feature"
          :available-beta-feature="clonedClientAvailableFeatures.betaFeature"
          :loading="$wait.is('updateClientAvailableFeatureWait')"
          @save="changeAvailableFeature"
          @cancel="toggleVisibleDialog"
        />
      </template>
    </FormDialog>
  </div>
</template>

<script lang="ts">
import lodash from 'lodash'
import { defineComponent } from 'vue'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import SisenseUserForm from '@/components/organisms/admin/client/SisenseUserForm.vue'
import ClientForm from '@/components/organisms/admin/ClientForm.vue'
import InviteForm from '@/components/organisms/admin/InviteForm.vue'
import LimitForm from '@/components/organisms/admin/LimitForm.vue'
// import MailVerification from '@/components/organisms/admin/MailVerification.vue'
import SalesforceDescribe from '@/components/organisms/admin/SalesforceDescribe.vue'
import SalesforceForm from '@/components/organisms/admin/SalesforceForm.vue'
import SalesforceObjectForm from '@/components/organisms/admin/SalesforceObjectForm.vue'
import SendgridForm from '@/components/organisms/admin/SendgridForm.vue'
import SwitchAvailableFeatureForm from '@/components/organisms/admin/SwitchAvailableFeatureForm.vue'
import UsersList from '@/components/organisms/admin/UsersList.vue'
import { useCurrentUser } from '@/composable/admin/user/users'
import errorHandler from '@/mixins/error_handler'
import AuthService from '@/services/auth_service'
import { formatDateTime } from '@/utils/date-time'

export default defineComponent({
  components: {
    ClientForm,
    InviteForm,
    LimitForm,
    UsersList,
    SendgridForm,
    SwitchAvailableFeatureForm,
    SalesforceForm,
    SalesforceObjectForm,
    SalesforceDescribe,
    // MailVerification,
    SisenseUserForm,
  },
  mixins: [errorHandler],
  props: {
    id: Number,
  },
  setup() {
    const { isAdminDeveloper } = useCurrentUser();
    return {
      isAdminDeveloper,
    };
  },
  data() {
    return {
      inviteDialog: false,
      clientEditDialog: false,
      changeLimitDialog: false,
      visibleFeatureDialog: false,
    }
  },
  computed: {
    ...mapGetters('admin', [
      'client',
      'clientLimitSetting',
      'clientAvailableFeatures',
      'clientSendgridAuth',
      'sendgridApiKeys',
      'clientSendgridApiKey',
      'mailVerificationInfo',
      'mailVerificationBalance',
    ]),
    tabLabelItems() {
      const items = [
        { label: this.$t('client.account'), slotName: 'user' },
        { label: this.$t('client.sendgridSetting'), slotName: 'sendgrid' },
        // { label: this.$t('client.mailVerification'), slotName: 'mailVerification' },
      ];
      if (this.clientAvailableFeatures.feature.salesforce_link) {
        items.push({ label: this.$t('client.salesforce'), slotName: 'salesforce' });
      }
      if (this.isAdminDeveloper) {
        items.push({ label: this.$t('sisense.title'), slotName: 'sisense' });
      }

      return items;
    },
    infosLoading() {
      return this.$wait.is([
        'getClientWait',
        'updateClientWait',
        'createUserWait',
        'getUsersWait',
        'getClientLimitSettingWait',
        'updateClientLimitSettingWait',
      ])
    },
    urlProtocol() {
      return `${location.protocol}//`
    },
    urlDomain() {
      const splittedDomain = location.hostname.split('.')
      return `.${splittedDomain.slice(1).join('.')}`
    },
    clientExpiredAt() {
      return formatDateTime(this.client.expiredAt)
    },
    clonedClient() {
      return lodash.cloneDeep(this.client)
    },
    clonedClientAvailableFeatures() {
      return lodash.cloneDeep(this.clientAvailableFeatures)
    },
  },
  created() {
    const setupClientManagementDetail = async () => {
      await this.getClientAction({ id: this.id })
      this.getUsersAction({ clientId: this.id })
      this.getClientLimitSettingAction({ clientId: this.id })
      await this.getClientSendgridAuthAction({ clientId: this.id })
      await this.getSendgridApiKeysAction()
      await this.getClientSendgridApiKeyAction({ clientId: this.id })
      await this.getClientAvailableFeatureAction({ clientId: this.id })
      const breadcrumbs = [
        { text: this.$t('clientManagement.title'), path: { name: 'AdminClients' } },
        { text: this.client.name, path: '' },
      ]
      // await this.getMailVerification()
      await this.getMailVerificationBalanceAction({ clientId: this.id })
      this.setBreadcrumbsAction(breadcrumbs)
    }
    setupClientManagementDetail()

    this.authService = new AuthService()
  },
  methods: {
    ...mapWaitingActions('breadcrumbs', {
      setBreadcrumbsAction: 'setBreadcrumbsWait',
    }),
    ...mapWaitingActions('admin', {
      getClientAction: 'getClientWait',
      updateClientAction: 'updateClientWait',
      createUserAction: 'createUserWait',
      getUsersAction: 'getUsersWait',
      getClientLimitSettingAction: 'getClientLimitSettingWait',
      updateClientLimitSettingAction: 'updateClientLimitSettingWait',
      getClientAvailableFeatureAction: 'getClientAvailableFeaturegWait',
      updateClientAvailableFeatureAction: 'updateClientAvailableFeatureWait',
      getClientSendgridAuthAction: 'getClientSendgridAuthWait',
      updateClientSendgridAuthAction: 'updateClientSendgridAuthWait',
      deleteClientSendgridAuthAction: 'deleteClientSendgridAuthWait',
      getSendgridApiKeysAction: 'getSendgridApiKeysWait',
      createClientSendgridApiKeyAction: 'createClientSendgridApiKeyWait',
      getClientSendgridApiKeyAction: 'getClientSendgridApiKeyWait',
      createMailVerificationAction: 'createMailVerificationWait',
      getMailVerificationAction: 'getMailVerificationWait',
      getMailVerificationBalanceAction: 'getMailVerificationBalanceWait',
      reflectMailVerificationToUnsubscribeAction: 'reflectMailVerificationToUnsubscribeWait',
    }),
    moveToLoginPage() {
      const loginPageUrl = `${this.urlProtocol + this.client.slug + this.urlDomain}/app/login`
      window.open(loginPageUrl, '_blank')
    },
    openClientEditDialog() {
      this.clientEditDialog = true
    },
    async closeClientEditDialog() {
      this.clientEditDialog = false
      await this.getClientAction({ id: this.id })
    },
    async updateClient(client) {
      await this.updateClientAction({ id: this.id, body: { client } })
      this.clientEditDialog = false
      this.$toast.show(this.$t('general.done.of', { action: this.$t('general.save.text') }))
    },
    openInviteDialog() {
      this.inviteDialog = true
    },
    closeInviteDialog() {
      this.inviteDialog = false
      this.$refs.inviteForm.reset()
    },
    async inviteUser({ name, lastName, firstName, email }) {
      const user = { name, lastName, firstName, email }
      await this.createUserAction({
        clientId: this.id,
        body: { user },
        errorHandlers: {
          422: this.defaultHandler,
        },
      })
      // TODO: AdminSDK(サーバーサイド)で実装
      await this.authService.resetPassword(user.email)
      this.closeInviteDialog()
      this.$toast.show(this.$t('general.done.of', { action: this.$t('general.invite') }))
    },
    openChangeLimitDialog() {
      this.changeLimitDialog = true
    },
    closeChangeLimitDialog() {
      this.changeLimitDialog = false
      this.$refs.limitForm.reset()
    },
    async changeLimit(clientLimitSetting) {
      await this.updateClientLimitSettingAction({
        clientId: this.id,
        body: { clientLimitSetting },
      })
      this.closeChangeLimitDialog()
      this.$toast.show(this.$t('general.done.of', { action: this.$t('general.save.text') }))
    },
    toggleVisibleDialog() {
      this.visibleFeatureDialog = !this.visibleFeatureDialog
    },
    async changeAvailableFeature(availableFeature, availableBetaFeature) {
      await this.updateClientAvailableFeatureAction({
        clientId: this.id,
        body: { AvailableFeature: availableFeature, AvailableBetaFeature: availableBetaFeature },
        errorHandlers: {
          422: this.defaultHandler,
        },
      })
      this.toggleVisibleDialog()
      this.$toast.show(this.$t('general.done.of', { action: this.$t('general.save.text') }))
    },
    async updateClientSendgridAuth(sendgridAuth) {
      await this.updateClientSendgridAuthAction({
        clientId: this.id,
        body: { sendgridAuth },
      })
      this.$toast.show(this.$t('general.done.of', { action: this.$t('general.save.text') }))
    },
    async deleteClientSendgridAuth() {
      await this.deleteClientSendgridAuthAction({
        clientId: this.id,
      })
      await this.$bitterAlert.show({
        text: this.$t('general.done.withTarget', {
          target: this.$t('client.sendgridSetting'),
          action: this.$t('general.delete.text'),
        }),
        buttonsCancel: false,
        closeOnClickOutside: false,
      })
      window.location.reload(true) // 安全のためスーパーリロードさせる
    },
    async getMailVerification(currentPage?: number) {
      // await this.getMailVerificationAction({
      //   clientId: this.id,
      //   currentPage,
      // })
    },
    async createMailVerification() {
      await this.createMailVerificationAction({
        clientId: this.id,
      })
      this.$toast.show(this.$t('general.done.of', { action: this.$t('general.send.text') }))
    },
    async reflectMailVerificationToUnsubscribe() {
      await this.reflectMailVerificationToUnsubscribeAction({
        clientId: this.id,
      })
      this.$toast.show(this.$t('general.done.of', { action: this.$t('general.update') }))
    },
    async createClientSendgridApiKey(sendgridApiKeyId) {
      await this.createClientSendgridApiKeyAction({
        clientId: this.id,
        body: { id: sendgridApiKeyId },
      })
      this.$toast.show(this.$t('general.done.of', { action: this.$t('general.create.text') }))
    },
  },
})
</script>

<style lang="scss" scoped>
.slug {
  color: $basecolor-primary;
  font-weight: 600;
  margin: 0 2px;
}

.setting-btn {
  width: 140px;
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "table-cell-content user-status"
}
const _hoisted_2 = {
  key: 0,
  class: "item active"
}
const _hoisted_3 = {
  key: 1,
  class: "item invited"
}
const _hoisted_4 = {
  key: 2,
  class: "item invalid"
}
const _hoisted_5 = { class: "table-cell-content" }
const _hoisted_6 = {
  key: 5,
  class: "table-cell-content user-id-cell"
}

import { cloneDeep } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { User } from '@/api/openapi'

type TProps = {
  user: User,
  columnNames: string[],
  columnWidth: number[],
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MemberListItem',
  props: {
    user: {},
    columnNames: {},
    columnWidth: {}
  },
  emits: [
  'update',
],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const i18n = useI18n();
const roleItems = [
  { text: i18n.t('user.enum.owner'), value: 'owner' },
  { text: i18n.t('user.enum.member'), value: 'member' },
];

const internalUser = ref<User | null>(null);
const isEditing = ref(false);

const mfaSetting = computed(() => {
  const status = internalUser.value?.mfaAuthenticated ? 'authenticated' : 'notAuthenticated';

  return {
    status,
    name: i18n.t(`mfaSetting.${status}`),
  };
});

watch(() => props.user, (newVal) => {
  internalUser.value = cloneDeep(newVal);
}, { immediate: true });

const handleEditing = () => {
  isEditing.value = true;
};
const handleEditingCancel = () => {
  isEditing.value = false;
};
const handleUpdateUserRole = () => {
  const updateUser = {
    id: internalUser.value.id,
    role: internalUser.value.role,
  };
  emit('update', updateUser);
  isEditing.value = false;
};
const getBodyStyles = (width: number, index: number) => {
  let style = `width: ${width}px; min-width: ${width}px; max-width: ${width}px;`;
  if (index === 0) style += 'position:sticky;left:0;z-index:1;';
  return style;
};

return (_ctx: any,_cache: any) => {
  const _component_BAvatar = _resolveComponent("BAvatar")!
  const _component_BSelect = _resolveComponent("BSelect")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BBtnSet = _resolveComponent("BBtnSet")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_ElTag = _resolveComponent("ElTag")!

  return (_openBlock(), _createBlock(_component_BLayout, {
    class: "member-list-item",
    "align-center": "",
    "justify-space-between": ""
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnNames, (column, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: column,
          class: "table-cell",
          style: _normalizeStyle(getBodyStyles(_ctx.columnWidth[index], index))
        }, [
          (column === 'avatar')
            ? (_openBlock(), _createBlock(_component_BAvatar, {
                key: 0,
                class: "mr-100",
                size: 36,
                "image-url": _ctx.user.avatarUrl,
                name: _ctx.user.name,
                "user-id": _ctx.user.id
              }, null, 8, ["image-url", "name", "user-id"]))
            : (column === 'name')
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  (_ctx.user.isActive)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2))
                    : (_ctx.user.isInviting)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
                      : (_openBlock(), _createElementBlock("div", _hoisted_4)),
                  _createElementVNode("div", {
                    class: "truncate",
                    style: _normalizeStyle({width: `${_ctx.columnWidth[index] - 25}px`})
                  }, _toDisplayString(_ctx.user.name), 5)
                ]))
              : (column === 'type')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    (isEditing.value)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createVNode(_component_BLayout, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_BSelect, {
                                modelValue: internalUser.value.role,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((internalUser.value.role) = $event)),
                                class: "select-user-role",
                                items: roleItems,
                                "item-text": "text",
                                "item-value": "value",
                                required: ""
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_BLayout, {
                            "align-center": "",
                            "justify-end": ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_BBtnSet, {
                                class: "ml-300",
                                onClose: handleEditingCancel,
                                onCheck: handleUpdateUserRole
                              })
                            ]),
                            _: 1
                          })
                        ], 64))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t(`user.enum.${_ctx.user.role}`)), 1),
                          (_ctx.$can('owner'))
                            ? (_openBlock(), _createBlock(_component_BBtn, {
                                key: 0,
                                size: "small",
                                fab: "",
                                flat: "",
                                onClick: handleEditing
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_BIcon, { size: "small" }, {
                                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                                      _createTextVNode(" edit ")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ], 64))
                  ], 64))
                : (['email', 'lastName', 'firstName'].includes(column))
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 3,
                      class: "table-cell-content truncate",
                      style: _normalizeStyle({width: `${_ctx.columnWidth[index]}px`})
                    }, _toDisplayString(_ctx.user[column]), 5))
                  : (column === 'mfaSetting')
                    ? (_openBlock(), _createBlock(_component_ElTag, {
                        key: 4,
                        class: "mfa-setting-status",
                        type: "info",
                        effect: mfaSetting.value.status === 'authenticated' ? 'dark' : 'plain'
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(mfaSetting.value.name), 1)
                        ]),
                        _: 1
                      }, 8, ["effect"]))
                    : (column === 'userId')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.user.id), 1))
                      : _createCommentVNode("", true)
        ], 4))
      }), 128)),
      _createVNode(_component_BLayout, { "align-center": "" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}
}

})
import ApiBase from '@/api/base'

export default {
  requestSfLeadDataBulkCheckTask(args) {
    return ApiBase.post(`/user/salesforce/sf_lead_data_bulk_check_tasks/execute`, args)
  },
  getSfBulkCheckLeadDataTasks() {
    return ApiBase.pollingGet(`/user/salesforce/sf_lead_data_bulk_check_tasks`)
  },
  confirmSfBulkCheckLeadDataTask(args) {
    const { id } = args
    return ApiBase.put(`/user/salesforce/sf_lead_data_bulk_check_tasks/${id}`)
  },
  getSfStatuses() {
    return ApiBase.get(`/user/salesforce/salesforce_statuses`)
  },
}

import ApiService, { ApiArguments } from '@/api/api_service';
import {
  AllShowSfReportImportTasks200Response,
  SfReportImportTaskApi, SfReportImportTaskApiAllShowSfReportImportTasksRequest,
} from '@/api/openapi';

export class SfReportImportTaskService extends ApiService {
  api = new SfReportImportTaskApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    // openapiで作ったやつはtrue
    super(true)
  }

  async allShowSfReportImportTasks({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SfReportImportTaskApiAllShowSfReportImportTasksRequest>) {
    return this.checkResponse<AllShowSfReportImportTasks200Response>(
      this.api.allShowSfReportImportTasks(request, options),
      errorHandlers,
    );

  }
}

import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { computed } from 'vue';
import { TSize } from '../../types';

type TIcon = 'negativeReaction' | 'positiveReaction';
type TProps = {
  icon: TIcon;
  iconClass?: string;
  size?: TSize;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BImageIcon',
  props: {
    icon: {},
    iconClass: { default: '' },
    size: { default: 'medium' }
  },
  setup(__props: any) {

const props = __props;

const ICON_IMAGES: Record<TIcon, string> = {
  negativeReaction: 'negative_reaction.svg',
  positiveReaction: 'positive_reaction.svg',
};

const iconImagePath = computed(() => require(`@/assets/${ICON_IMAGES[props.icon]}`));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["b-icon-image", [_ctx.iconClass, _ctx.size]])
  }, [
    _createElementVNode("img", { src: iconImagePath.value }, null, 8, _hoisted_1)
  ], 2))
}
}

})
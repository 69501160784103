import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, nextTick, ref } from 'vue';
import { LeadStage } from '@/api/openapi';
import { TSymbolColor } from '../../types';

type TProps = {
  // NOTE: leadStage.id
  modelValue: number | null;
  leadStages: LeadStage[];
  // for BSelect
  loading?: boolean;
  required?: boolean;
  fit?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BLeadStageSelect',
  props: {
    modelValue: {},
    leadStages: {},
    loading: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    fit: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const automaticDropdown = ref(false);
const refSelect = ref<HTMLElement>(null);
const value = computed<number | null>({
  get() {
    return props.modelValue;
  },
  set(v) {
    emit('update:modelValue', v);
  },
});

const selectedLeadStageColorName = computed<TSymbolColor | undefined>(() => 
  props.leadStages.find(ls => ls.id === value.value)?.colorName as TSymbolColor,
);
const focus = async () => {
  // NOTE: automaticDropdown=trueにしないとfocus時にdropdownが開かないが、ONのままにしておくとclick時にdropdownが開かなくなってしまう（Element Plusのバグかも）
  automaticDropdown.value = true;
  refSelect.value.focus();
  await nextTick();
  automaticDropdown.value = false;
};

__expose({
  focus,
});

return (_ctx: any,_cache: any) => {
  const _component_BLeadStageColorPreview = _resolveComponent("BLeadStageColorPreview")!
  const _component_BLeadStagePreview = _resolveComponent("BLeadStagePreview")!
  const _component_BSelect = _resolveComponent("BSelect")!

  return (_openBlock(), _createBlock(_component_BSelect, {
    ref_key: "refSelect",
    ref: refSelect,
    modelValue: value.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
    items: _ctx.leadStages,
    loading: _ctx.loading,
    required: _ctx.required,
    fit: _ctx.fit,
    "automatic-dropdown": automaticDropdown.value,
    "item-text": "name",
    "item-value": "id"
  }, {
    prefix: _withCtx(() => [
      _createVNode(_component_BLeadStageColorPreview, {
        value: selectedLeadStageColorName.value,
        size: "small"
      }, null, 8, ["value"])
    ]),
    optionTemplate: _withCtx(({ item }) => [
      _createVNode(_component_BLeadStagePreview, { "lead-stage": item }, null, 8, ["lead-stage"])
    ]),
    _: 1
  }, 8, ["modelValue", "items", "loading", "required", "fit", "automatic-dropdown"]))
}
}

})
import Waiting from '@/store/waiting'

export default class CallTargetNoteActions {
  constructor (Api, types) {
    const actions = {
      async getCallTargetNotesAction ({ commit }, args) {
        await waiting.check('getCallTargetNotesAction', async () => {
          try {
            const res = await Api.getCallTargetNotes(args)
            commit(types.SET_CALL_TARGET_NOTES, Object.values(res.data)[0])
          } catch (err) {
            commit(types.CLEAR_CALL_TARGET_NOTES)
            throw err
          }
        })
      },
      async createCallTargetNoteAction ({ commit }, args) {
        await waiting.check('createCallTargetNoteAction', async () => {
          await Api.createCallTargetNote(args)
          try {
            const res = await Api.getCallTargetNotes(args)
            commit(types.SET_CALL_TARGET_NOTES, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
      async updateCallTargetNoteAction ({ commit }, args) {
        await waiting.check('updateCallTargetNoteAction', async () => {
          await Api.updateCallTargetNote(args)
          try {
            const res = await Api.getCallTargetNotes(args)
            commit(types.SET_CALL_TARGET_NOTES, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
      async deleteCallTargetNoteAction ({ commit }, args) {
        await waiting.check('deleteCallTargetNoteAction', async () => {
          await Api.deleteCallTargetNote(args)
          try {
            const res = await Api.getCallTargetNotes(args)
            commit(types.SET_CALL_TARGET_NOTES, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

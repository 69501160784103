import Waiting from '@/store/waiting'

export default class RevisionActions {
  constructor (Api, types) {
    const actions = {
      async getRevisionIdAction ({ commit }) {
        await waiting.check('getRevisionIdAction', async () => {
          try {
            const res = await Api.getRevisionId()
            commit(types.SET_REVISION_ID, res.data.revisionId)
          } catch (err) {
            commit(types.CLEAR_REVISION_ID)
            throw err
          }
        })
      },
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

import store from '@/store/index'
import { TCtiType, CTI_TYPES } from '@/types';

const AUTH_DELETE_ACTIONS: Record<TCtiType, string> = {
  zoom: 'zoomPhone/deleteZoomSettingAction',
  miitel: 'user/deleteMiitelAuthAction',
};

/**
 * 接続確認
 */
const connected: Record<TCtiType, () => Promise<boolean>> = {
  zoom: async (): Promise<boolean> => {
    await store.dispatch('zoomPhone/getZoomSettingAction');
    return store.getters['zoomPhone/zoomPhoneConnected'];
  },
  miitel: async (): Promise<boolean> => {
    await store.dispatch('user/getMiitelAuthAction');
    const miitelAuth = store.state.user.miitelAuth
    return !!miitelAuth?.companyId || !!miitelAuth?.accessKey
  },
};

/**
 * 指定したCTI以外を接続解除
 */
const unconnectAllExcept = async (target: TCtiType): Promise<void> => {
  for (const currentTarget of CTI_TYPES) {
    if (currentTarget === target) {
      continue;
    }

    const isConnected = await connected[currentTarget]();
    if (!isConnected) {
      continue;
    }
    const action = AUTH_DELETE_ACTIONS[currentTarget];
    await store.dispatch(action);
  }
};

export {
  unconnectAllExcept,
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lead-list-control" }
const _hoisted_2 = { class: "display-range mr-200" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BPagination = _resolveComponent("BPagination")!
  const _component_BContainer = _resolveComponent("BContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (Object.keys(_ctx.callTargetsMeta || {}).length > 0)
      ? (_openBlock(), _createBlock(_component_BContainer, {
          key: 0,
          class: "lead-list-control-inner",
          size: "large"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", null, _toDisplayString(_ctx.displayRange), 1)
            ]),
            _renderSlot(_ctx.$slots, "default", {}, undefined, true),
            _createVNode(_component_BPagination, {
              modelValue: _ctx.currentPage,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentPage) = $event)),
              class: "ml-a",
              length: _ctx.allPageCount,
              "total-visible": 5,
              circle: ""
            }, null, 8, ["modelValue", "length"])
          ]),
          _: 3
        }))
      : _createCommentVNode("", true)
  ]))
}
<template>
  <BModal
    v-loading="isLoading"
    :modal-name="modalName"
    @open-modal-event="syncSfData"
  >
    <BHeightAdjuster>
      <BModalHeader
        :title="$t('salesforceOpportunityModal.modalTitle')"
        @modal-close="hideSfModal"
      />

      <BModalBody>
        <div
          class="select-sf-data-block"
          :class="{ 'lead-select': isSyncSfLead, 'ac-select': !isSyncSfLead }"
        >
          <div
            v-if="isSyncSfLead"
            class="align-center"
          >
            <BRadio
              v-model="selectCreateType"
              class="mr-600"
              label="new"
            >
              {{ $t(`salesforceOpportunityModal.createType.new`) }}
            </BRadio>
          </div>
          <div
            v-if="isSyncSfLead"
            class="align-center"
          >
            <BRadio
              v-model="selectCreateType"
              label="exist"
            >
              {{ $t(`salesforceOpportunityModal.createType.exist`) }}
            </BRadio>
          </div>
          <BListItem class="list-item">
            <template #header>
              <div>{{ $t('salesforceOpportunityModal.formLabel.account') }}</div>
            </template>
            <div>{{ currentLeadCompanyName }}</div>
          </BListItem>
          <div class="salesforceOpportunityModalBodyTopBlock">
            <div v-if="isSyncSfLead">
              <SearchSfModel
                v-model:selected-id="selectedSfAccountId"
                :label="$t('salesforceOpportunityModal.formLabel.account')"
                :disabled="selectCreateType=='new'"
                :search="searchAccountByName"
              />
            </div>
          </div>
          <BListItem class="list-item">
            <template #header>
              <div>{{ $t('salesforceOpportunityModal.formLabel.contact') }}</div>
            </template>
            <div>{{ currentLeadUserName }}</div>
          </BListItem>
          <BListItem class="list-item" />
          <SearchSfModel
            v-model:selected-id="selectedSfUserId"
            :label="$t('general.select.of', { item: $t('salesforceOpportunityModal.formLabel.owner') })"
            :search="searchSfUserByName"
          />
        </div>
        <div class="hr" />
        <div v-show="salesforceMustItemList.length">
          {{ $t('salesforceOpportunityModal.salesforceMustInput') }}
        </div>
        <div
          class="select-sf-data-block"
        >
          <BListItem
            v-for="(salesforceMustItem, index) in salesforceMustItemList"
            :key="index"
            class="list-item convert"
          >
            <template #header>
              <div>{{ salesforceMustItem.label }}</div>
            </template>
            <BInput
              v-if="salesforceMustItem.data_type === 'string'"
              v-model="inputSalesforceItemList[`${salesforceMustItem.name}`]"
              :rules="rules"
            />
            <BDatePicker
              v-else-if="salesforceMustItem.data_type === 'date'"
              v-bind="dateAttributes"
              v-model="inputSalesforceItemList[`${salesforceMustItem.name}`]"
            />
            <BInputDateTime
              v-else-if="salesforceMustItem.data_type === 'datetime'"
              v-model:date="inputSalesforceItemList[`${salesforceMustItem.name}`]"
              v-model:time="inputSalesforceItemList[`${salesforceMustItem.name}`]"
              date-format="YYYY-MM-DD"
            />
          </BListItem>
        </div>
      </BModalBody>
      <BModalFooter>
        <BLayout justify-center>
          <BBtn
            class="mr-100"
            flat
            size="small"
            :loading="isLoading"
            @click.stop="hideSfModal"
          >
            {{ $t('general.cancel.text') }}
          </BBtn>
          <BBtn
            type="primary"
            :loading="isLoading"
            :disabled="isDisable"
            @click.stop="handleSubmit"
          >
            <span>{{ $t(`general.save.text`) }}</span>
          </BBtn>
        </BLayout>
      </BModalFooter>
    </BHeightAdjuster>
  </BModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import { SfCampaignService } from '@/api/user/resources/salesforce/sf_campaign';
import SearchSfModel from '@/components/organisms/user/lead/modal/SearchSfModel.vue';
import inputValidation from '@/mixins/input_validation'
import BModalBody from '@/plugins/biscuet-materials/components/molecules/BModalBody.vue';

export default defineComponent({
  components: {
    BModalBody,
    SearchSfModel,
  },
  mixins: [inputValidation],
  props: {
    callTarget: {
      type: Object,
      required: true,
    },
    handleSave: {
      type: Function,
      required: true,
    },
  },
  emits: [
    'close-modal',
  ],
  data () {
    return {
      selectCreateType: 'new',
      selectedSfAccountId: '',
      selectedSfContactId: '',
      selectedSfUserId: '',
      opportunityId: null,
      currentAccount: {},
      currentContact: {},
      currentLead: {},
      isDisable: false,
      salesforceMustItemList: [],
      inputSalesforceItemList: {},
    }
  },
  computed: {
    ...mapGetters('user', [
      'salesforceSetting',
      'sfUsers',
    ]),
    modalName () {
      return `SalesforceOpportunityModal${this.callTarget.id}`
    },
    isLoading () {
      return this.$wait.is(['createSfOpportunityWait', 'createOpportunityWait'])
    },
    isSyncSfLead () {
      return this.callTarget?.sfRelatedLead?.sfLeadId?.length > 0
    },
    currentLeadCompanyName () {
      return Object.keys(this.currentAccount).length === 0 ? this.currentLead.company : this.currentAccount.name
    },
    currentLeadUserName () {
      return Object.keys(this.currentContact).length === 0 ? this.currentLead.name : this.currentContact.name
    },
    rules () {
      return [this.requiredRule]
    },
    dateAttributes() {
      return {
        type: 'date',
        format: 'YYYY-MM-DD',
        clearable: false,
      }
    },
    datetimeAttributes() {
      return {
        type: 'datetime',
        format: 'YYYY-MM-DD ',
        clearable: false,
      }
    },
  },
  watch: {
    selectCreateType(newVal) {
      if (newVal == 'new') this.selectedSfAccountId = ''
    },
  },
  async created() {
    this.selectedSfContactId = this.callTarget?.sfRelatedLead?.sfContactId
    if (this.selectedSfContactId) {
      const api = new SfCampaignService();
      const res = await api.getSfOpportunityRequiredObjectFields();
      if (res.data) {
        this.salesforceMustItemList = res.data;
        this.salesforceMustItemList = this.salesforceMustItemList.filter((item) => {
          return ['string', 'date', 'datetime'].includes(item.data_type);
        });
        this.inputSalesforceItemList = {}
        this.salesforceMustItemList.forEach((item) => {
          this.inputSalesforceItemList[item.name] = null;
        })
      }
    }
  },
  methods: {
    ...mapWaitingActions('user', {
      getCallTargetAction: 'getCallTargetWait',
      searchSfAccountByNameAction: 'searchSfAccountByNameWait',
      getSfLeadAction: 'getSfLeadWait',
      getSfAccountAction: 'getSfAccountWait',
      getSfContactAction: 'getSfContactWait',
      getSfUsersAction: 'getSfUsersWait',
      createSfOpportunityAction: 'createSfOpportunityWait',
      clearSfOpportunityItemAction: 'clearSfOpportunityItemWait',
      getSfUsersByNameAction: 'getSfUsersByNameWait',
      getLeadHistoriesBackgroundAction: {
        action: 'getLeadHistoriesAction',
        loader: 'getLeadHistoriesBackgroundWait',
      },
    }),
    async searchSfUserByName(name){
      return await this.getSfUsersByNameAction(name)
    },
    async syncSfData() {
      await this.getSfUsersAction()

      if (this.isSyncSfLead) {
        try {
          this.currentLead = await this.getSfLeadAction({ id: this.callTarget?.sfRelatedLead?.sfLeadId })
        } catch (e) {
          this.$bitterAlert.show({
            title: this.$t('salesforceOpportunityModal.errorModalTitle2'),
            text: e.response?.data?.errorMessage,
            buttonsCancel: false,
          })
          this.isDisable = true
        }
      } else {
        try {
          if (this.callTarget?.sfRelatedLead?.sfAccountId) {
            this.currentAccount = await this.getSfAccountAction({ id: this.callTarget?.sfRelatedLead?.sfAccountId })
          }
          if (this.callTarget?.sfRelatedLead?.sfContactId) {
            this.currentContact = await this.getSfContactAction({ id: this.callTarget?.sfRelatedLead?.sfContactId })
          }
        } catch (e) {
          this.$bitterAlert.show({
            title: this.$t('salesforceOpportunityModal.errorModalTitle2'),
            text: e.response?.data?.errorMessage,
            buttonsCancel: false,
          })
          this.isDisable = true
        }

      }
    },
    async searchAccountByName(name){
      return await this.searchSfAccountByNameAction(name)
    },
    reset() {
      this.selectedSfAccountId = ''
      this.selectedSfContactId = ''
      this.selectedSfUserId = ''
      this.opportunityId = null
    },
    hide() {
      this.hideSfModal()
      this.reset()
      this.$emit('close-modal')
    },
    hideSfModal() {
      this.$modal.hide(this.modalName)
    },
    changeUser(v) {
      this.selectedSfUserId = v
    },
    async handleSubmit() {
      if (this.validateSubmit() === false) return

      this.setSendAccountId()

      try {
        this.saveErrorMessage = null
        if (this.opportunityId  === null) {
          const opportunity = await this.handleSave()
          this.opportunityId = opportunity.id
        }

        await this.createSfOpportunityAction({
          opportunityId: this.opportunityId,
          selectedSfAccountId: this.selectedSfAccountId,
          selectedSfContactId: this.selectedSfContactId,
          selectedSfOwnerId: this.selectedSfUserId,
          ...this.inputSalesforceItemList,
        }).then(() => {
          this.hide()
          this.getCallTargetAction({ request: { callTargetId: this.callTarget.id } })
        })
      } catch (e) {
        this.$bitterAlert.show({
          title: this.$t('salesforceOpportunityModal.errorModalTitle'),
          text: e.response?.data?.errorMessage,
          buttonsCancel: false,
        })
      } finally {
        this.getLeadHistoriesBackgroundAction({
          callTargetId: this.callTarget.id,
        })
      }
    },
    setSendAccountId() {
      if (this.selectCreateType == 'new') {
        this.selectedSfAccountId = this.callTarget?.sfRelatedLead?.sfAccountId
      }
    },
    validateSubmit() {
      let message = ''
      if (!this.selectedSfUserId) {
        message = `${this.$t('salesforceOpportunityModal.validator.opportunity.attributes.ownerId.required.message')}`
      }

      if (this.selectCreateType == 'exist' && !this.selectedSfAccountId) {
        message = `${this.$t('salesforceOpportunityModal.validator.opportunity.attributes.accountId.required.message')}`
      }

      Object.keys(this.inputSalesforceItemList).forEach((index) => {
        if (!this.inputSalesforceItemList[index] || this.inputSalesforceItemList[index].length <= 0) {
          message = `${this.$t('salesforceOpportunityModal.validator.opportunity.attributes.mustItem.required.message')}`
        }
      });

      if (message.length > 0) {
        this.$bitterAlert.show({
          title: this.$t('salesforceOpportunityModal.errorModalTitle'),
          text: message,
          buttonsCancel: false,
        })

        return false
      }
    },
    beforeUnmount() {
      this.reset()
      this.clearSfOpportunityItemAction()
    },
  },
})
</script>

<style lang="scss" scoped>
  .align-center {
    text-align: center;
  }

  .select-sf-data-block {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 150px;

    &.lead-select {
      grid-template-rows: 50px 80px 80px 80px;
    }
    &.ac-select{
      grid-template-rows: 80px 80px 80px;
    }
    &.convert {
      grid-template-rows: repeat(auto-fill, 80px);
    }
  }

  :deep(.el-radio__input.is-checked .el-radio__inner) {
    border-color: $basecolor-primary;
    background: $basecolor-primary;
  }

  .hr {
    border-bottom: 1px solid #E3E3E3;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 12px;
  }

  .list-item.convert {
    height: 80px;
  }
</style>

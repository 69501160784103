<template>
  <div
    class="b-modal-header"
    :class="classes"
  >
    <slot />
    <BBtn
      v-if="needsCloseBtn"
      class="close-btn"
      size="small"
      fab
      flat
      @click="$emit('modal-close')"
    >
      <BIcon
        size="small"
        bold
      >
        close
      </BIcon>
    </BBtn>
    <div class="modal-title">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BModalHeader',
  props: {
    title: String,
    needsCloseBtn: {
      type: Boolean,
      default: true,
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'modal-close',
  ],
  computed: {
    classes () {
      return {
        'is-center': this.center,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .b-modal-header {
    // @include m-fixed-height(50px);
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 40px 40px 20px;

    .close-btn {
      position: absolute;
      top: 0;
      right: $basespace-300;
      bottom: 0;
      margin: auto;
    }

    .modal-title {
      font-weight: bold;
      font-size: $fontsize-400;
      line-height: 160%;
      display: flex;
      align-items: center;
    }

    &.is-center {
      justify-content: center;
    }
  }
</style>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header-item header-title" }
const _hoisted_3 = { class: "header-item number-of-items" }
const _hoisted_4 = { class: "body" }
const _hoisted_5 = ["infinite-scroll-disabled"]
const _hoisted_6 = { class: "body-header" }
const _hoisted_7 = { class: "company-name" }
const _hoisted_8 = { class: "trigger-name" }
const _hoisted_9 = { class: "date" }
const _hoisted_10 = { class: "actions" }

import { computed } from 'vue';
import { TriggerTargetAction } from '@/api/openapi';
import { TriggerTargetActionApiService } from '@/api/user/resources/trigger_target_action';
import { useInfiniteLoading } from '@/composable/infinite-loading';
import { useWait } from '@/composable/vue-wait';
import TriggerRequireApprovalNotificationItem from './triggerRequireApprovalNotification/TriggerRequireApprovalNotificationItem.vue';

const PER_PAGE = 20;
const KEY_OF_INFINITE_LOADING_WAIT = 'triggerRequireApprovalNotificationInfiniteLoadingWait';

export default /*@__PURE__*/_defineComponent({
  __name: 'TriggerRequireApprovalNotification',
  setup(__props) {

const { wait } = useWait();

const loading = computed(() => wait.is([KEY_OF_INFINITE_LOADING_WAIT, 'triggerApproveWait', 'triggerRejectWait']));

const api = new TriggerTargetActionApiService();
const fetchItems = async (page = 1) => {
  // TODO: mockしてるのでいらなくなったら消してください
  await new Promise((resolve) => setTimeout(resolve, 1000));
  const paginationMeta = {
    totalCount: 40,
    limitValue: 20,
    totalPages: 2,
    currentPage: page,
    isEmpty: false,
  };
  const { data: { triggerTargetActions, meta } } = await api.getTriggerTargetActionApprovals({
    request: {
      page,
    },
  });
  return {
    items: triggerTargetActions,
    paginationMeta: meta,
  };
}

const {
  items,
  paginationMeta,
  infiniteScrollDisabled,
  loadMore,
  clear,
  handleAfterOpen,
  fetchLoadedPage,
} = useInfiniteLoading<TriggerTargetAction>(fetchItems, KEY_OF_INFINITE_LOADING_WAIT);

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BEmptyBox = _resolveComponent("BEmptyBox")!
  const _component_BElementIcon = _resolveComponent("BElementIcon")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BIconWithActionMenu = _resolveComponent("BIconWithActionMenu")!
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")!

  return (_openBlock(), _createBlock(_component_BIconWithActionMenu, {
    class: "trigger-require-approval-notification-container",
    width: 900,
    "badge-count": _unref(paginationMeta)?.totalCount || 0,
    "auto-fetch-interval": 30000,
    onAutoFetchIntervalPassed: _unref(fetchLoadedPage),
    onAfterOpen: _unref(handleAfterOpen),
    onAfterClose: _unref(clear)
  }, {
    icon: _withCtx(() => [
      _createVNode(_component_BIcon, { outlined: "" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" how_to_reg ")
        ])),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('triggerRequireApprovalNotification.title')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(_unref(paginationMeta)?.totalCount || 0) + _toDisplayString(_ctx.$t('general.matter')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives((_openBlock(), _createElementBlock("div", {
          class: "body-inner",
          "infinite-scroll-disabled": _unref(infiniteScrollDisabled),
          "infinite-scroll-distance": PER_PAGE
        }, [
          (!_ctx.$wait.is(KEY_OF_INFINITE_LOADING_WAIT) && !_unref(items).length)
            ? (_openBlock(), _createBlock(_component_BEmptyBox, {
                key: 0,
                class: "empty",
                "display-only": ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('triggerRequireApprovalNotification.empty')), 1)
                ]),
                _: 1
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('callTarget.column.companyName')), 1),
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('trigger.name')), 1),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('triggerRequireApprovalNotification.createdAt')), 1),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('general.approve.text')), 1)
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(items), (item) => {
                  return (_openBlock(), _createBlock(TriggerRequireApprovalNotificationItem, {
                    key: item.id,
                    item: item,
                    loading: loading.value,
                    "onAfter:update": _unref(fetchLoadedPage)
                  }, null, 8, ["item", "loading", "onAfter:update"]))
                }), 128))
              ], 64)),
          (_ctx.$wait.is(KEY_OF_INFINITE_LOADING_WAIT))
            ? (_openBlock(), _createBlock(_component_BLayout, {
                key: 2,
                class: "py-200",
                "justify-center": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BElementIcon, { name: "Loading" })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ], 8, _hoisted_5)), [
          [_directive_infinite_scroll, _unref(loadMore)]
        ])
      ])
    ]),
    _: 1
  }, 8, ["badge-count", "onAutoFetchIntervalPassed", "onAfterOpen", "onAfterClose"]))
}
}

})
<template>
  <div
    v-loading="$wait.is('confirmLeadExcelImportTaskWait')"
    class="lead-excel-import-status"
  >
    <ImportStatus
      v-for="task in leadExcelImportTasks"
      :key="task.id"
      :target-task="task"
      :progress-message="$t('import.excel.status.progress')"
      :error-message="$t('import.excel.status.error')"
      :error-modal-title="$t('import.excel.errorTitle')"
      :success-message="$t('import.excel.status.success')"
      @confirm="() => confirmTask(task.id)"
      @success="$emit('success')"
    />
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import ImportStatus from '@/components/organisms/user/leadImport/ImportStatus.vue'

export default {
  components: {
    ImportStatus,
  },
  emits: [
    'success',
  ],
  computed: {
    ...mapGetters('user', ['leadExcelImportTasks']),
  },
  created() {
    this.getLeadExcelImportTasksAction()
    this.timeObj = this.$setInterval(() => {
      this.getLeadExcelImportTasksAction()
    }, 90000)
  },
  unmounted() {
    this.$clearInterval(this.timeObj)
  },
  methods: {
    ...mapWaitingActions('user', {
      getLeadExcelImportTasksAction: 'getLeadExcelImportTasksWait',
      confirmLeadExcelImportTaskAction: 'confirmLeadExcelImportTaskWait',
    }),
    confirmTask(taskId) {
      this.confirmLeadExcelImportTaskAction({ leadExcelImportTaskId: taskId })
    },
  },
}
</script>

<style scoped lang="scss">
  .import-status:nth-last-child(n + 2) {
    border-bottom: 1px solid $concrete-light;
  }
</style>

<template>
  <div>
    <BListItem>
      <template #header>
        <span>{{ $t('nextAction.title') }}</span>
      </template>
      <BEmptyBox
        class="pt-100 pb-100 mb-300"
        @click="handleAddNextAction"
      >
        <BIcon size="small">
          add
        </BIcon>
      </BEmptyBox>
      <TransitionGroup name="list">
        <div
          v-for="(nextAction, index) in internalNextActions"
          :key="`nextActionCard-${index}`"
        >
          <NextActionCard
            class="mb-200"
            :next-action="nextAction"
            :call-target-id="callTargetId"
            :is-valid="nextActionsIsValid[index]"
            @update:next-action="(val) => handleUpdateNextAction(index, val)"
            @update:is-valid="(val) => handleUpdateNextActionIsValid(index, val)"
            @close="() => handleCloseNextAction(index)"
          />
        </div>
      </TransitionGroup>
    </BListItem>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import NextActionCard from '@/components/organisms/user/call_target/menu/call_result/NextActionCard.vue'
import { TCommonNextAction } from '@/components/organisms/user/general/NextActionFormBody.vue'
import { newDateByTime } from '@/utils/date-time';

type TData = {
  nextActionsIsValid: boolean[];
};
// TODO: type
type TNextAction = Record<string, unknown> & TCommonNextAction;

export default defineComponent({
  components: {
    NextActionCard,
  },
  props: {
    nextActions: {
      type: Array as PropType<TNextAction[]>,
      default () {
        return []
      },
    },
    callTargetId: {
      type: Number,
      default: null,
    },
  },
  emits: [
    'update:nextActions',
    'update:isValid',
  ],
  data(): TData {
    return {
      nextActionsIsValid: [],
    };
  },
  computed: {
    ...mapGetters('user', [
      'currentUser',
    ]),
    internalNextActions: {
      get () {
        return this.nextActions
      },
      set (newVal: TNextAction[]) {
        if (this.nextActions !== newVal) this.$emit('update:nextActions', newVal)
      },
    },
  },
  methods: {
    handleAddNextAction() {
      const newNextActions = [...this.internalNextActions]
      newNextActions.unshift(this.initialNextAction())

      this.internalNextActions = newNextActions
      this.nextActionsIsValid.unshift(false)
    },
    handleUpdateNextAction(index: number, val: TNextAction) {
      const newNextActions = [...this.internalNextActions]
      newNextActions[index] = val
      this.internalNextActions = newNextActions
    },
    handleUpdateNextActionIsValid(index: number, val: boolean) {
      this.nextActionsIsValid[index] = val
      this.$emit('update:isValid', this.nextActionsIsValid.every((isValid) => isValid))
    },
    handleCloseNextAction (index: number) {
      this.internalNextActions = this.internalNextActions.filter((_, actionsIndex) => actionsIndex !== index)
      this.nextActionsIsValid = this.nextActionsIsValid.filter((_, actionsIndex) => actionsIndex !== index)
    },
    initialNextAction(): TNextAction {
      return {
        id: null,
        actionType: 'default1',
        priority: 'middle',
        ownerId: this.currentUser.id,
        reservedAt: newDateByTime(12, 0, 0),
        content: '',
      }
    },
  },
});
</script>

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_SendgridSubUsersList = _resolveComponent("SendgridSubUsersList")!
  const _component_BCard = _resolveComponent("BCard")!
  const _component_SendgridSubUserInviteForm = _resolveComponent("SendgridSubUserInviteForm")!
  const _component_FormDialog = _resolveComponent("FormDialog")!
  const _component_BContainer = _resolveComponent("BContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BContainer, { full: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_BCard, { shadow: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_BLayout, {
              class: "mb-200",
              "justify-space-between": "",
              "align-center": ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("h2", null, _toDisplayString(_ctx.$t('sendgridSubUserManagement.title')), 1),
                (_ctx.isAdminDeveloper)
                  ? (_openBlock(), _createBlock(_component_BBtn, {
                      key: 0,
                      type: "primary",
                      onClick: _ctx.openInviteDialog
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('sendgridSubUserManagement.create')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_SendgridSubUsersList, {
              "is-admin-developer": _ctx.isAdminDeveloper,
              onExecDelete: _ctx.execDelete
            }, null, 8, ["is-admin-developer", "onExecDelete"])
          ]),
          _: 1
        }),
        _createVNode(_component_FormDialog, {
          dialog: _ctx.inviteDialog,
          width: "500"
        }, {
          form: _withCtx(() => [
            _createVNode(_component_SendgridSubUserInviteForm, {
              ref: "inviteForm",
              loading: _ctx.$wait.is(['createAdminWait', 'postSendgridApiSettingWait']),
              onSave: _ctx.exec,
              onCancel: _ctx.closeInviteDialog
            }, null, 8, ["loading", "onSave", "onCancel"])
          ]),
          _: 1
        }, 8, ["dialog"])
      ]),
      _: 1
    })
  ]))
}
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = {
  key: 0,
  class: "defined"
}
const _hoisted_3 = {
  key: 1,
  class: "not-defined"
}
const _hoisted_4 = { class: "more-menu" }

import { onBeforeMount, ref, computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import MfaModal from '@/components/organisms/user/general/modal/MfaModal.vue'
import { useMfaSetting } from '@/composable/user/mfaSetting/mfa-setting';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { KEY_OF_MODAL, KEY_OF_TOAST } from '@/injection-keys';
import { bitterAlert } from '@/plugins/BBitterAlert';

const MFA_MODAL_NAME = 'mfaModal';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserSecurity',
  setup(__props) {

const i18n = useI18n();
const router = useRouter();
const { currentUser, fetchCurrentUser } = useCurrentUser();
const { doActionWithWait, wait } = useWait();
const { mfaSetting, fetchMfaSetting, deleteMfaSetting } = useMfaSetting();
const modal = inject(KEY_OF_MODAL);
const toast = inject(KEY_OF_TOAST);

const loading = computed(() =>
  wait.is(['fetchMfaSettingWait', 'deleteMfaSettingWait']),
);
const isConnect = computed(() =>
  currentUser.value?.mfaAuthenticated,
);
const factor = computed(() =>
  mfaSetting.value?.factor,
);

onBeforeMount(async () => {
  fetch();
});

const fetch = async () => {
  await doActionWithWait('fetchMfaSettingWait', async () => {
    await fetchCurrentUser();
    await fetchMfaSetting();
  });
};

const unconnect = async () => {
  const check = await bitterAlert.show({
    text: i18n.t('userSecurity.mfaSetting.mfaSettingDeleteConfirm'),
  })
  if (!check) { return }

  await doActionWithWait('deleteMfaSettingWait', async () => {
    await deleteMfaSetting();
  });

  router.push({ name: 'UserSignin' });
};

const handleAuthenticated = async () => {
  fetch();
  toast.show(i18n.t('userSecurity.mfaSetting.mfaSettingComplete'));
  modal.hide(MFA_MODAL_NAME);
};

return (_ctx: any,_cache: any) => {
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BMoreMenu = _resolveComponent("BMoreMenu")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BContentBox = _resolveComponent("BContentBox")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_BContentBox, null, {
    content: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock(_component_BLayout, {
        column: "",
        class: "form"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BListItem, null, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('mfaSetting.title')), 1),
              (isConnect.value)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('general.defined')), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('general.notDefined')), 1))
            ]),
            _: 1
          }),
          _createVNode(_component_BListItem, { class: "explain mt-400" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('userSecurity.mfaSetting.explain')), 1)
            ]),
            _: 1
          }),
          (isConnect.value)
            ? (_openBlock(), _createBlock(_component_BListItem, {
                key: 0,
                class: "mt-400"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BLayout, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_BInput, {
                        modelValue: factor.value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((factor).value = $event)),
                        class: "w-input",
                        clearable: "",
                        disabled: true
                      }, null, 8, ["modelValue"]),
                      _createElementVNode("span", _hoisted_4, [
                        _createVNode(_component_BMoreMenu, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_BBtn, {
                              flat: "",
                              fit: "",
                              size: "small",
                              disabled: loading.value,
                              onClick: unconnect
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t('userSecurity.mfaSetting.disconnect')), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled"])
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_BListItem, {
                key: 1,
                class: "mt-400"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BBtn, {
                    type: "primary",
                    outline: "true",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(modal).show(MFA_MODAL_NAME)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.setting.do')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
        ]),
        _: 1
      })), [
        [_directive_loading, loading.value]
      ]),
      _createVNode(MfaModal, {
        "modal-name": MFA_MODAL_NAME,
        onClose: _cache[2] || (_cache[2] = ($event: any) => (_unref(modal).hide(MFA_MODAL_NAME))),
        onAuthenticated: handleAuthenticated
      })
    ]),
    _: 1
  }))
}
}

})
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_AdminsList = _resolveComponent("AdminsList")!
  const _component_BCard = _resolveComponent("BCard")!
  const _component_InviteForm = _resolveComponent("InviteForm")!
  const _component_FormDialog = _resolveComponent("FormDialog")!
  const _component_BContainer = _resolveComponent("BContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BContainer, { full: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_BCard, { shadow: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_BLayout, {
              class: "mb-200",
              "justify-space-between": "",
              "align-center": ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("h2", null, _toDisplayString(_ctx.$t(`adminManagement.title`)), 1),
                _createVNode(_component_BBtn, {
                  type: "primary",
                  onClick: _ctx.openInviteDialog
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t(`general.invite`)), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_AdminsList)
          ]),
          _: 1
        }),
        _createVNode(_component_FormDialog, {
          dialog: _ctx.inviteDialog,
          width: "500"
        }, {
          form: _withCtx(() => [
            _createVNode(_component_InviteForm, {
              ref: "inviteForm",
              loading: _ctx.$wait.is('createAdminWait'),
              onSave: _ctx.inviteAdmin,
              onCancel: _ctx.closeInviteDialog
            }, null, 8, ["loading", "onSave", "onCancel"])
          ]),
          _: 1
        }, 8, ["dialog"])
      ]),
      _: 1
    })
  ]))
}
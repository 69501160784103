import ApiService, { ApiArguments } from '@/api/api_service';
import {
  ApplySequenceMaster200Response,
  GetSequenceInstanceOverviews200Response,
  GetSequenceMaster200Response,
  GetSequenceMasters200Response,
  PostSequenceMaster201Response,
  SequenceMasterApi,
  SequenceMasterApiApplySequenceMasterRequest,
  SequenceMasterApiChangeSequenceMasterOwnerRequest,
  SequenceMasterApiDeleteSequenceMasterRequest,
  SequenceMasterApiGetSequenceInstanceOverviewsRequest,
  SequenceMasterApiGetSequenceMasterRequest,
  SequenceMasterApiGetSequenceMastersRequest,
  SequenceMasterApiPostSequenceMasterRequest,
  SequenceMasterApiPutSequenceMasterRequest,
} from '@/api/openapi';

export class SequenceMasterApiService extends ApiService {
  api = new SequenceMasterApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true)
  }

  async applySequenceMaster({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceMasterApiApplySequenceMasterRequest>) {
    return this.checkResponse<ApplySequenceMaster200Response>(
      this.api.applySequenceMaster(request, options),
      errorHandlers,
    );
  }

  async getSequenceMasters(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<SequenceMasterApiGetSequenceMastersRequest>,
  ) {
    return this.checkResponse<GetSequenceMasters200Response>(
      this.api.getSequenceMasters(request, options),
      errorHandlers,
    );
  }

  async getSequenceMaster(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<SequenceMasterApiGetSequenceMasterRequest>,
  ) {
    return this.checkResponse<GetSequenceMaster200Response>(
      this.api.getSequenceMaster(request, options),
      errorHandlers,
    );
  }

  async getSequenceInstanceOverviews(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<SequenceMasterApiGetSequenceInstanceOverviewsRequest>,
  ) {
    return this.checkResponse<GetSequenceInstanceOverviews200Response>(
      this.api.getSequenceInstanceOverviews(request, options),
      errorHandlers,
    );
  }

  async postSequenceMaster({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceMasterApiPostSequenceMasterRequest>) {
    return this.checkResponse<PostSequenceMaster201Response>(
      this.api.postSequenceMaster(request, options),
      errorHandlers,
    );
  }

  async putSequenceMaster({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceMasterApiPutSequenceMasterRequest>) {
    return this.checkResponse<void>(
      this.api.putSequenceMaster(request, options),
      errorHandlers,
    );
  }

  async deleteSequenceMaster({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceMasterApiDeleteSequenceMasterRequest>) {
    return this.checkResponse<void>(
      this.api.deleteSequenceMaster(request, options),
      errorHandlers,
    );
  }

  async changeSequenceMasterOwner({
    request,
    options,
    errorHandlers,
  }: ApiArguments<SequenceMasterApiChangeSequenceMasterOwnerRequest>) {
    return this.checkResponse<void>(
      this.api.changeSequenceMasterOwner(request, options),
      errorHandlers,
    );
  }
}

import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createSlots as _createSlots, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "infos-area"
}
const _hoisted_3 = { class: "mr-600" }
const _hoisted_4 = { class: "mt-100" }
const _hoisted_5 = { class: "ml-400 mr-600" }
const _hoisted_6 = { class: "slug" }
const _hoisted_7 = { class: "ml-400" }
const _hoisted_8 = { class: "mt-100" }
const _hoisted_9 = { class: "ml-400" }
const _hoisted_10 = { class: "mt-100" }
const _hoisted_11 = { class: "mr-300" }
const _hoisted_12 = { class: "mr-300" }
const _hoisted_13 = { class: "mr-300" }
const _hoisted_14 = { class: "mr-300" }
const _hoisted_15 = { class: "mr-300" }
const _hoisted_16 = { class: "mr-300" }
const _hoisted_17 = { class: "mr-300" }
const _hoisted_18 = { class: "mr-300" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BContainer = _resolveComponent("BContainer")!
  const _component_BCard = _resolveComponent("BCard")!
  const _component_UsersList = _resolveComponent("UsersList")!
  const _component_SendgridForm = _resolveComponent("SendgridForm")!
  const _component_SalesforceForm = _resolveComponent("SalesforceForm")!
  const _component_SalesforceObjectForm = _resolveComponent("SalesforceObjectForm")!
  const _component_SalesforceDescribe = _resolveComponent("SalesforceDescribe")!
  const _component_SisenseUserForm = _resolveComponent("SisenseUserForm")!
  const _component_BTabs = _resolveComponent("BTabs")!
  const _component_ClientForm = _resolveComponent("ClientForm")!
  const _component_FormDialog = _resolveComponent("FormDialog")!
  const _component_InviteForm = _resolveComponent("InviteForm")!
  const _component_LimitForm = _resolveComponent("LimitForm")!
  const _component_SwitchAvailableFeatureForm = _resolveComponent("SwitchAvailableFeatureForm")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BCard, { class: "pa-4" }, {
      default: _withCtx(() => [
        (_ctx.infosLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _withDirectives(_createVNode(_component_BPlaceholder, { "element-loading-background": "transparent" }, null, 512), [
                [_directive_loading, true]
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_BContainer, null, {
                default: _withCtx(() => [
                  _createVNode(_component_BLayout, { class: "mb-200" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t(`client.name`)), 1),
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.client.name), 1)
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t(`client.slug`)), 1),
                        _createVNode(_component_BBtn, {
                          flat: "",
                          onClick: _ctx.moveToLoginPage
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(_ctx.urlProtocol), 1),
                            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.client.slug), 1),
                            _createElementVNode("span", null, _toDisplayString(_ctx.urlDomain), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t(`client.expiredAt`)), 1),
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.clientExpiredAt), 1)
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t(`client.status.title`)), 1),
                        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t(`client.status.enum.${_ctx.client.status}`)), 1)
                      ]),
                      _createVNode(_component_BBtn, {
                        class: "ml-a setting-btn",
                        type: "primary",
                        onClick: _ctx.openClientEditDialog
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(`client.title`) + _ctx.$t(`general.edit.text`)), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_BLayout, { class: "mb-200" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t(`clientLimitSetting.activeUserLimit`)), 1),
                        _createElementVNode("div", null, _toDisplayString(_ctx.clientLimitSetting.activeUserLimit), 1)
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t(`clientLimitSetting.freeTagLimit`)), 1),
                        _createElementVNode("div", null, _toDisplayString(_ctx.clientLimitSetting.freeTagLimit), 1)
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t(`clientLimitSetting.customFieldLimit`)), 1),
                        _createElementVNode("div", null, _toDisplayString(_ctx.clientLimitSetting.customFieldLimit), 1)
                      ]),
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t(`clientLimitSetting.leadRegistrationLimit`)), 1),
                        _createElementVNode("div", null, _toDisplayString(_ctx.clientLimitSetting.leadRegistrationLimit), 1)
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t(`clientLimitSetting.monthlyEmailsLimit`)), 1),
                        _createElementVNode("div", null, _toDisplayString(_ctx.clientLimitSetting.monthlyEmailsLimit), 1)
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t(`clientLimitSetting.mailTemplateRegistrationLimit`)), 1),
                        _createElementVNode("div", null, _toDisplayString(_ctx.clientLimitSetting.mailTemplateRegistrationLimit), 1)
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t('clientLimitSetting.leadStageLimit')), 1),
                        _createElementVNode("div", null, _toDisplayString(_ctx.clientLimitSetting.leadStageLimit), 1)
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t('clientLimitSetting.sequenceLimit')), 1),
                        _createElementVNode("div", null, _toDisplayString(_ctx.clientLimitSetting.sequenceLimit), 1)
                      ]),
                      _createVNode(_component_BBtn, {
                        class: "ml-a setting-btn",
                        type: "primary",
                        onClick: _ctx.openChangeLimitDialog
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(`clientLimitSetting.changeLimit`)), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_BLayout, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_BBtn, {
                        class: "ml-a setting-btn",
                        type: "primary",
                        onClick: _ctx.toggleVisibleDialog
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(`availableFeatureManagement.title`)), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]))
      ]),
      _: 1
    }),
    _createVNode(_component_BContainer, { full: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_BTabs, { "label-items": _ctx.tabLabelItems }, _createSlots({
          user: _withCtx(() => [
            _createVNode(_component_BCard, {
              shadow: "",
              class: "pa-4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BLayout, {
                  class: "mb-200",
                  "justify-space-between": "",
                  "align-center": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BBtn, {
                      type: "primary",
                      onClick: _ctx.openInviteDialog
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(`client.account`) + _ctx.$t(`general.invite`)), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_UsersList)
              ]),
              _: 1
            })
          ]),
          sendgrid: _withCtx(() => [
            _createVNode(_component_BCard, {
              shadow: "",
              class: "pa-4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SendgridForm, {
                  "is-admin-developer": _ctx.isAdminDeveloper,
                  "sendgrid-auth": _ctx.clientSendgridAuth,
                  "sendgrid-api-keys": _ctx.sendgridApiKeys,
                  "client-sendgrid-api-key": _ctx.clientSendgridApiKey,
                  onSave: _ctx.updateClientSendgridAuth,
                  onDecisive: _ctx.createClientSendgridApiKey,
                  onDelete: _ctx.deleteClientSendgridAuth
                }, null, 8, ["is-admin-developer", "sendgrid-auth", "sendgrid-api-keys", "client-sendgrid-api-key", "onSave", "onDecisive", "onDelete"])
              ]),
              _: 1
            })
          ]),
          _: 2
        }, [
          (_ctx.clientAvailableFeatures.feature.salesforce_link)
            ? {
                name: "salesforce",
                fn: _withCtx(() => [
                  _createVNode(_component_BCard, {
                    shadow: "",
                    class: "pa-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SalesforceForm, { id: _ctx.id }, null, 8, ["id"]),
                      _createVNode(_component_SalesforceObjectForm, { id: _ctx.id }, null, 8, ["id"]),
                      _createVNode(_component_SalesforceDescribe, { id: _ctx.id }, null, 8, ["id"])
                    ]),
                    _: 1
                  })
                ]),
                key: "0"
              }
            : undefined,
          (_ctx.isAdminDeveloper)
            ? {
                name: "sisense",
                fn: _withCtx(() => [
                  _createVNode(_component_BCard, {
                    shadow: "",
                    class: "pa-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SisenseUserForm, { "client-id": _ctx.id }, null, 8, ["client-id"])
                    ]),
                    _: 1
                  })
                ]),
                key: "1"
              }
            : undefined
        ]), 1032, ["label-items"])
      ]),
      _: 1
    }),
    _createVNode(_component_FormDialog, {
      dialog: _ctx.clientEditDialog,
      width: "500"
    }, {
      form: _withCtx(() => [
        _createVNode(_component_ClientForm, {
          ref: "clientEditForm",
          client: _ctx.clonedClient,
          loading: _ctx.$wait.is('updateClientWait'),
          onSave: _ctx.updateClient,
          onCancel: _ctx.closeClientEditDialog
        }, null, 8, ["client", "loading", "onSave", "onCancel"])
      ]),
      _: 1
    }, 8, ["dialog"]),
    _createVNode(_component_FormDialog, {
      dialog: _ctx.inviteDialog,
      width: "500"
    }, {
      form: _withCtx(() => [
        _createVNode(_component_InviteForm, {
          ref: "inviteForm",
          loading: _ctx.$wait.is('createUserWait'),
          onSave: _ctx.inviteUser,
          onCancel: _ctx.closeInviteDialog
        }, null, 8, ["loading", "onSave", "onCancel"])
      ]),
      _: 1
    }, 8, ["dialog"]),
    _createVNode(_component_FormDialog, {
      dialog: _ctx.changeLimitDialog,
      width: "500"
    }, {
      form: _withCtx(() => [
        _createVNode(_component_LimitForm, _mergeProps(_ctx.clientLimitSetting, {
          ref: "limitForm",
          loading: _ctx.$wait.is('updateClientLimitSettingWait'),
          onSave: _ctx.changeLimit,
          onCancel: _ctx.closeChangeLimitDialog
        }), null, 16, ["loading", "onSave", "onCancel"])
      ]),
      _: 1
    }, 8, ["dialog"]),
    _createVNode(_component_FormDialog, {
      dialog: _ctx.visibleFeatureDialog,
      width: "500"
    }, {
      form: _withCtx(() => [
        _createVNode(_component_SwitchAvailableFeatureForm, {
          "available-feature": _ctx.clonedClientAvailableFeatures.feature,
          "available-beta-feature": _ctx.clonedClientAvailableFeatures.betaFeature,
          loading: _ctx.$wait.is('updateClientAvailableFeatureWait'),
          onSave: _ctx.changeAvailableFeature,
          onCancel: _ctx.toggleVisibleDialog
        }, null, 8, ["available-feature", "available-beta-feature", "loading", "onSave", "onCancel"])
      ]),
      _: 1
    }, 8, ["dialog"])
  ]))
}
import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import {
  GetZoomSetting200Response,
  ZoomSettingApi,
  ZoomSettingApiCreateZoomSettingRequest,
} from '@/api/openapi';

export class ZoomSettingApiService extends ApiService {
  api = new ZoomSettingApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getZoomSetting(
    apiArguments?: ApiArguments<undefined>,
  ): Promise<AxiosResponse<GetZoomSetting200Response>> {
    return this.checkResponse<GetZoomSetting200Response>(
      this.api.getZoomSetting(apiArguments?.options),
      apiArguments?.errorHandlers,
    );
  }

  async createZoomSetting({
    request,
    options,
    errorHandlers,
  }: ApiArguments<ZoomSettingApiCreateZoomSettingRequest>): Promise<
    AxiosResponse<boolean>
  > {
    return this.checkResponse<boolean>(
      this.api.createZoomSetting(request, options),
      errorHandlers,
    );
  }

  async deleteZoomSetting(
    apiArguments?: ApiArguments<undefined>,
  ): Promise<
    AxiosResponse<boolean>
  > {
    return this.checkResponse<boolean>(
      this.api.deleteZoomSetting(apiArguments?.options),
      apiArguments?.errorHandlers,
    );
  }
}

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

type TProps = {
  modelValue: number | null | undefined;
  tooltipTextWhenReadonly: string;
  readonly: boolean;
  sequenceId: number | undefined;
};
type TEmit = {
  (event: 'update:modelValue', sequenceId: number | null): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceAssociationCheckBox',
  props: {
    modelValue: {},
    tooltipTextWhenReadonly: {},
    readonly: { type: Boolean },
    sequenceId: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const i18n = useI18n();

const checkboxValue = computed<boolean>({
  get() {
    return !!props.modelValue;
  },
  set(value: boolean) {
    const newValue = value ? props.sequenceId : null;
    emit('update:modelValue', newValue);
  },
})

const disabled = computed<boolean>(() => {
  return !!tooltipText.value;
});

const tooltipText = computed<string>(() => {
  if (props.readonly) return props.tooltipTextWhenReadonly;
  if (!props.sequenceId) return i18n.t('opportunity.notAvailableByNoSequence');
  return '';
});

return (_ctx: any,_cache: any) => {
  const _component_BCheckbox = _resolveComponent("BCheckbox")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BTooltip = _resolveComponent("BTooltip")!

  return (_openBlock(), _createBlock(_component_BTooltip, {
    top: "",
    content: tooltipText.value,
    disabled: !disabled.value
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BLayout, {
        class: "mt-300 mb-300",
        "align-center": "",
        "justify-center": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BCheckbox, {
            modelValue: checkboxValue.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((checkboxValue).value = $event)),
            disabled: disabled.value,
            label: _unref(i18n).t('opportunity.associateWithSequence')
          }, null, 8, ["modelValue", "disabled", "label"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["content", "disabled"]))
}
}

})
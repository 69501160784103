import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "result-form-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwitchBtn = _resolveComponent("SwitchBtn")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BSelectBtn = _resolveComponent("BSelectBtn")!
  const _component_BSelect = _resolveComponent("BSelect")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BListItem, { class: "mb-300" }, {
      header: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('callResult.connected.title')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_SwitchBtn, {
          modelValue: $options.internalConnected,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.internalConnected) = $event)),
          class: "mb-300"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      ($options.internalConnected)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_BListItem, { class: "mb-300" }, {
              header: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('callResult.connectedTo.title')), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_BSelectBtn, {
                  ref: "connectedTo",
                  modelValue: $options.internalConnectedTo,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($options.internalConnectedTo) = $event)),
                  items: $options.connectedToItems
                }, null, 8, ["modelValue", "items"])
              ]),
              _: 1
            }),
            ($options.internalConnectedTo !== 'unknown')
              ? (_openBlock(), _createBlock(_component_BListItem, { key: 0 }, {
                  header: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('callResult.title')), 1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_BSelect, {
                      modelValue: $options.internalResult,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($options.internalResult) = $event)),
                      items: $options.connectedCallResultItems,
                      "item-text": "text",
                      "item-value": "value",
                      fit: ""
                    }, null, 8, ["modelValue", "items"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createBlock(_component_BListItem, { key: 1 }, {
            header: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('callResult.title')), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_BSelect, {
                modelValue: $options.internalResult,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($options.internalResult) = $event)),
                items: $options.notConnectedCallResultItems,
                "item-text": "text",
                "item-value": "value",
                fit: ""
              }, null, 8, ["modelValue", "items"])
            ]),
            _: 1
          }))
    ])
  ]))
}
<template>
  <ContentLoader
    :width="width"
    :height="height"
    :speed="speed"
    primary-color="#f5f5f5"
  >
    <slot />
  </ContentLoader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'BContentLoader',
  components: {
    ContentLoader,
  },
  props: {
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 130,
    },
    speed: {
      type: Number,
      default: 2,
    },
  },
}
</script>

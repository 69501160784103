import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sequence-owner"
}
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "content truncate-text" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

type TProps = {
  ownerName: string | undefined;
  forSequenceInstance: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceOwner',
  props: {
    ownerName: {},
    forSequenceInstance: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
const i18n = useI18n();

const ownerInfo = computed(() => {
  if (!props.forSequenceInstance) return '';

  return i18n.t('sequence.ownerInfo.instance');
});

return (_ctx: any,_cache: any) => {
  const _component_BHelp = _resolveComponent("BHelp")!

  return (_ctx.ownerName)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, [
            _createTextVNode(_toDisplayString(_ctx.$t('general.owner')) + " ", 1),
            (ownerInfo.value)
              ? (_openBlock(), _createBlock(_component_BHelp, {
                  key: 0,
                  content: ownerInfo.value
                }, null, 8, ["content"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.ownerName), 1)
      ]))
    : _createCommentVNode("", true)
}
}

})
<template>
  <div class="mail-content">
    <div class="subject">
      <BInput
        v-model="internalSubject"
        class="subject-input"
        :placeholder="$t('mail.titlePlaceholder')"
        :maxlength="255"
        :disabled="isReply"
        underline
      />
      <PlaceholderMenu
        v-if="!isReply && enablePlaceholder"
        v-model="isOpenSubjectInsertMenu"
        :is-mail="isMail"
        @insert-item="insertToSubject"
      />
    </div>
    <div
      v-loading="$wait.is(['appendAttachmentWait'])"
    >
      <MailBodyEditor
        v-model:content="internalContent"
        class="mail-body-editor"
        :enable-placeholder="enablePlaceholder"
        :show-attachment-icon="enableAttachment"
        :unsubscribe-visible="unsubscribeVisible"
        @click-attach-file="handleAttachFileClick"
      />
      <MailAttachments
        ref="mailAttachments"
        :mail-attachment-ids="internalMailAttachmentIds"
        @edit-mail-attachment-ids="internalMailAttachmentIds = $event"
      />
    </div>
  </div>
</template>

<script>
import ApiBase from '@/api/base'
import { FileStorageAccessApiService } from '@/api/user/resources/file_storage_access';
import { MailAttachmentApiService } from '@/api/user/resources/mail_attachment';
import MailAttachments from '@/components/organisms/user/general/MailAttachments.vue'
import PlaceholderMenu from '@/components/organisms/user/general/PlaceholderMenu.vue'
import MailBodyEditor from '@/components/organisms/user/mail/common/drawer/state/form/MailBodyEditor.vue'
import { useErrorHandler } from '@/composable/error-handler';
import { useFileSelect } from '@/composable/file-select';
import { useWait } from '@/composable/vue-wait';

export default {
  name: 'MailContentEditor',
  components: {
    MailBodyEditor,
    PlaceholderMenu,
    MailAttachments,
  },
  props: {
    subject: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    mailAttachmentIds: {
      type: Array,
      default: () => [],
    },
    enableAttachment: {
      type: Boolean,
      default: false,
    },
    unsubscribeVisible: {
      type: Boolean,
      default: true,
    },
    isReply: {
      type: Boolean,
      default: false,
    },
    isMail: {
      type: Boolean,
      default: true,
    },
    enablePlaceholder: {
      type: Boolean,
      default: true,
    },

  },
  emits: [
    'edit-subject',
    'edit-content',
    'edit-mail-attachment-ids',
  ],
  setup() {
    const {
      file,
      openFileSelection,
    } = useFileSelect();

    const {
      fileUploadErrorHandler,
    } = useErrorHandler

    const {
      doActionWithWait,
    } = useWait();

    const maxAttachmentsCount = 10;
    const maxAttachmentsByteSize = 10000000;
    const maxAttachmentsByteSizeInMega = maxAttachmentsByteSize / 1000000;

    return {
      file,
      openFileSelection,
      fileUploadErrorHandler,
      doActionWithWait,
      maxAttachmentsCount,
      maxAttachmentsByteSize,
      maxAttachmentsByteSizeInMega,
    }
  },
  data () {
    return {
      isOpenSubjectInsertMenu: false,
      isOpenContentInsertMenu: false,
    }
  },
  computed: {
    internalSubject: {
      get () {
        return this.subject
      },
      set (v) {
        this.$emit('edit-subject', v)
      },
    },
    internalContent: {
      get () {
        return this.content
      },
      set (v) {
        this.$emit('edit-content', v)
      },
    },
    internalMailAttachmentIds: {
      get () {
        return this.mailAttachmentIds
      },
      set (v) {
        this.$emit('edit-mail-attachment-ids', v)
      },
    },
  },
  watch: {
    file (value) {
      if (value) {
        this.handleAfterFileSelect()
      }
    },
  },
  methods: {
    insertToSubject (content) {
      this.internalSubject += content
      this.isOpenSubjectInsertMenu = false
    },
    handleAttachFileClick() {
      const error = this.validateAttachmentsCount() // MEMO: バックエンドでもやっているが、ファイルを追加する時点でエラーを出したいためにここでもやっている
      if (error) {
        this.showErrorMessage(error)
        return
      }
      this.openFileSelection()
    },
    async handleAfterFileSelect() {
      const error = this.validateAttachmentsByteSize(this.file) // MEMO: バックエンドでもやっているが、ファイルを追加する時点でエラーを出したいためにここでもやっている
      if (error) {
        this.showErrorMessage(error)
        return
      }
      await this.doActionWithWait('appendAttachmentWait', async () => {
        const s3Key = await this.upload(this.file)
        const mailAttachment = await this.save(s3Key, this.file)
        this.internalMailAttachmentIds = [...this.internalMailAttachmentIds, mailAttachment.id]
      });
    },
    validateAttachmentsByteSize(file) {
      const totalFileSize = file.size + this.$refs.mailAttachments.totalByteSize
      if (totalFileSize > this.maxAttachmentsByteSize) {
        return this.$t('mail.tooLargeAttachmentsByteSize', { attachTypeText: this.$t('mail.attached'), sizeInMega: this.maxAttachmentsByteSizeInMega })
      }
      return null
    },
    validateAttachmentsCount() {
      if (this.internalMailAttachmentIds.length >= this.maxAttachmentsCount) {
        return this.$t('mail.tooManyAttachments')
      }
      return null
    },
    showErrorMessage(message) {
      this.$bitterAlert.show({
        title: this.$t('general.error'),
        text: message,
        closeOnClickOutside: true,
        buttonsCancel: false,
      })
    },
    async upload(file) {
      const apiService = new FileStorageAccessApiService();
      const { data } = await apiService.createOutgoingMailAttachmentPresignedUrl({ 
        request: {
          createOutgoingMailAttachmentAccessUrlRequest: {
            fileName: file.name,
          },
        },
      });
      await ApiBase.unauthorizedPut(data.url, {
        body: file,
        header: { 'Content-Type': file.type },
        errorHandlers: {
          403: this.fileUploadErrorHandler,
        },
      });
      return data.key;
    },
    async save(s3Key, file) {
      const apiService =  new MailAttachmentApiService();
      const { data } = await apiService.postMailAttachment({
        request: {
          mailAttachmentPostBody: {
            fileName: file.name,
            fileByteSize: file.size,
            s3Key,
          },
        },
      });
      return data;
    },
  },
}
</script>

<style lang="scss" scoped>
  .mail-content {
    z-index: 1;
    border: 1px solid $concrete;
    border-radius: 5px;
    transition: all 0.3s ease;
    &:focus-within {
      border: 1px solid $bdcolor-active;
    }
  }

  .subject {
    width: 100%;
    min-height: 32px;
    position: relative;
    border-bottom: 1px solid $concrete;
    .subject-input {
      min-height: 32px;
    }
  }

  .mail-body-editor {
    width: 100%;
    min-height: 400px;
  }
</style>

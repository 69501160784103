<template>
  <BDrawerMenu
    v-model="isOpen"
    class="trigger-action-form-container"
    width="600px"
    :title="$t('trigger.actionSetting.title')"
    @cancel="handleCancelClick"
  >
    <div
      class="trigger-action-form-body-container"
    >
      <BListItem class="pb-100">
        <span class="fs-300 fw-bold">{{ $t('trigger.actionSetting.targetSelect') }}</span>
      </BListItem>
      <div class="list-group">
        <BListItem class="list-item">
          <template #header>
            <span>{{ $t('trigger.actionSetting.actionType') }}</span>
          </template>
          <span>{{ i18n.t(`trigger.actionTypes.${internalTriggerAction.actionType}`) }}</span>
        </BListItem>
      </div>
      <div class="list-group">
        <BListItem class="list-item">
          <template #header>
            <div class="mb-200">
              {{ $t('trigger.actionSetting.actionDetail') }}
            </div>
          </template>
        </BListItem>
        <BListItem class="list-item">
          <div
            v-if="!!internalTriggerAction.actionType"
            v-loading="loading"
            class="action-detail-container confirm"
          >
            <div
              v-for="form in forms"
              :key="form.actionDetailKey"
            >
              <template v-if="form.inputType === 'sequence_select'">
                <BListItem class="list-item">
                  <template #header>
                    <span>{{ form.label }}</span>
                  </template>
                  <SequenceMasterSimpleSelect
                    v-model="(internalTriggerAction.actionDetail[form.actionDetailKey] as number | null)"
                    is-display-only
                  />
                </BListItem>
              </template>
            </div>
          </div>
        </BListItem>
      </div>
      <BListItem class="pt-200 pb-200">
        <span class="fs-300 fw-bold">{{ $t('trigger.actionSetting.approveUserSelect') }}</span>
      </BListItem>
      <div class="list-group">
        <BListItem class="list-item">
          <template #header>
            <span>{{ $t('trigger.actionSetting.approveUser') }}</span>
          </template>
          <span>{{ approveUser ? approveUser.name : '' }}</span>
        </BListItem>
      </div>
    </div>
    <template #footer>
      <div
        class="footer"
      >
        <BLayout
          align-center
          justify-center
        >
          <BBtn
            class="mr-300 btn"
            text
            @click="handleCancelClick"
          >
            {{ $t('general.cancel.text') }}
          </BBtn>
          <BBtn
            class="ml-300 btn"
            type="primary"
            outline
            :disabled="!editable"
            @click="handleEditClick"
          >
            {{ $t('general.edit.text') }}
          </BBtn>
        </BLayout>
      </div>
    </template>
  </BDrawerMenu>
</template>

<script lang="ts" setup>
import { ref, watch, nextTick, computed } from 'vue';
import { useI18n } from 'vue-i18n'
import { useTriggerAction, useTriggerActionForm } from '@/composable/user/trigger/trigger-action';
import { useUsers } from '@/composable/user/user/users';
import SequenceMasterSimpleSelect from '../SequenceMasterSimpleSelect.vue';
import { TriggerActionBodyExt } from '../types';

type TProps = {
  editable: boolean;
};
type TEmits = {
  'click:cancel': [];
  'click:triggerAction': [triggerAction: TriggerActionBodyExt, menu: string];
};
defineProps<TProps>();
const emit = defineEmits<TEmits>();

const model = defineModel<TriggerActionBodyExt | null>();
const isOpen = defineModel<boolean>('isOpen', { default: false });

const i18n = useI18n();

const { users } = useUsers();
const approveUser = computed(() => {
  return users.value.find((user) => user.id === internalTriggerAction.value.approveUserId);
});

const { makeInitialTriggerAction } = useTriggerAction();
const { forms, fetchForms, loading } = useTriggerActionForm();
const internalTriggerAction = ref<TriggerActionBodyExt>();

const clearInternalValues = () => {
  internalTriggerAction.value = makeInitialTriggerAction(model.value);
  forms.value = [];
};
clearInternalValues();

watch(() => internalTriggerAction.value.actionType, value => {
  if (value) fetchForms(value);
});

watch(() => isOpen.value, () => {
  nextTick(() => {
    clearInternalValues();
  });
});

const handleCancelClick = () => {
  emit('click:cancel');
};

const handleEditClick = () => {
  emit('click:triggerAction', model.value, 'edit');
};
</script>

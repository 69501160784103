import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { SequenceStepMaster } from '@/api/openapi';
import { useSequenceStepMasters } from '@/composable/user/sequence/sequence-step-masters';
import EditableSequenceStepMasters from '../general/sequence/EditableSequenceStepMasters.vue';
import { TFormSequenceStep } from '../general/sequence/types';

type TProps = {
  duplicatedSequenceMasterId?: number;
  sequenceOwnerId: number;
};
type TEmit = {
  'update': [sequenceSteps: TFormSequenceStep[]];
};

export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceSteps',
  props: {
    duplicatedSequenceMasterId: {},
    sequenceOwnerId: {}
  },
  emits: ["update"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const {
  sequenceStepMasters: duplicatedSequenceStepMasters,
  fetchSequenceStepMasters: fetchDuplicatedSequenceStepMasters,
} = useSequenceStepMasters(props.duplicatedSequenceMasterId);

const sequenceStepMasters = computed<SequenceStepMaster[]>(() =>
  duplicatedSequenceStepMasters.value.map(step => ({
    ...step,
    id: null,
    deletable: true,
    mailAggregates: null,
  } as SequenceStepMaster)),
);

if (props.duplicatedSequenceMasterId) {
  fetchDuplicatedSequenceStepMasters('fetchDuplicatedSequenceStepMasters');
}

const handleUpdate = (sequenceSteps: TFormSequenceStep[]) => {
  emit('update', sequenceSteps);
};

return (_ctx: any,_cache: any) => {
  const _component_VWait = _resolveComponent("VWait")!

  return (_openBlock(), _createBlock(_component_VWait, {
    for: "fetchDuplicatedSequenceStepMasters",
    class: "sequence-steps-container"
  }, {
    default: _withCtx(() => [
      _createVNode(EditableSequenceStepMasters, {
        "sequence-step-masters": sequenceStepMasters.value,
        "sequence-owner-id": _ctx.sequenceOwnerId,
        onUpdate: handleUpdate
      }, null, 8, ["sequence-step-masters", "sequence-owner-id"])
    ]),
    _: 1
  }))
}
}

})
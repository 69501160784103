import { computed, readonly, ref } from 'vue';
import { User } from '@/api/openapi';
import apis from '@/api/user';

const useUsers = (needsInitialFetch = () => true) => {
  const users = ref<User[]>([]);
  const activeUsers = computed(() => users.value.filter((user) => user.isActive));
  const invitingUsers = computed(() => users.value.filter((user) => user.isInviting));
  const archivedUsers = computed(() => users.value.filter((user) => !user.isActive && !user.isInviting));

  const fetchUsers = async () => {
    const res = await apis.getUsers();
    users.value = res.data.users;
  };
  if (needsInitialFetch()) {
    fetchUsers();
  }

  return {
    users: readonly(users),
    activeUsers: readonly(activeUsers),
    invitingUsers: readonly(invitingUsers),
    archivedUsers: readonly(archivedUsers),
    fetchUsers,
  };
};

const useCurrentUser = () => {
  const currentUser = ref<User>(null);
  const fetchCurrentUser = async () => {
    const res = await apis.getCurrentUser();
    currentUser.value = res.data.currentUser;
  };
  const fetchCurrentUserPromise = fetchCurrentUser();

  return {
    currentUser: readonly(currentUser),
    fetchCurrentUser,
    fetchCurrentUserPromise,
  };
};

export {
  useUsers,
  useCurrentUser,
}

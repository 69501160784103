import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "api-connection" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "status" }
const _hoisted_5 = { class: "connected-email" }
const _hoisted_6 = { class: "more-menu" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "explain" }

import { ref, inject, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { GoogleSetting, User } from '@/api/openapi';
import { GoogleSettingService } from '@/api/user/resources/google_setting';
import { useGoogleSetting } from '@/composable/user/googleSetting/google-setting';
import { useWait } from '@/composable/vue-wait';
import { KEY_OF_WAIT, KEY_OF_TOAST } from '@/injection-keys';
import { bitterAlert } from '@/plugins/BBitterAlert';

type TProps = {
  user: User | null;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'GoogleApi',
  props: {
    user: {}
  },
  emits: ['update:user'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const i18n = useI18n();
const route = useRoute();
const router = useRouter();
const wait = inject(KEY_OF_WAIT);
const api = new GoogleSettingService();
const { doActionWithWait } = useWait();
const { googleSetting, fetchGoogleSetting } = useGoogleSetting();

onMounted(async () => {
  showConnectStatus();
  await fetchGoogleSetting();
});

const showConnectStatus = async () => {
  if (route.query.connect_status) {
    const serviceName = i18n.t('oauthCallback.service.google');

    const title = route.query.connect_status === 'true'
      ? i18n.t('oauthCallback.linkComplete', { serviceName })
      : i18n.t('oauthCallback.linkFailure', { serviceName });

    const text = route.query.failure_reason === 'invalid_scope'
      ? i18n.t('oauthCallback.failureReason.invalidScope')
      : '';

    await bitterAlert.show({ title, text, buttonsCancel: false });

    // connect_status を history から削る
    router.replace({
      name: 'Setting',
      params: { tab: route.params.tab, subTab: route.params.subTab },
    })
  }
}

const settingLoading = computed(() =>
  wait.is(['getGoogleSettingWait']),
);
const deleting = computed(() =>
  wait.is(['deleteGoogleSettingWait']),
);
const isConnect = computed(() =>
  googleSetting.value?.connectStatus,
);
const showConnectErrorMessage = computed(() =>
  googleSetting.value?.isGmailImport === false,
);

const connect = async () => {
  const { data } = await api.getGoogleAuthorizationUrl();
  location.href = data.url;
};

const unconnect = async () => {
  const check = await bitterAlert.show({
    title: i18n.t('google.disconnect'),
    text: i18n.t('google.disconnectConfirmText', {
      currentSenderEmail: props.user?.senderEmail,
      afterSenderEmail: props.user?.email,
    }),
  })
  if (!check) { return }

  await doActionWithWait('deleteGoogleSettingWait', async () => {
    await api.deleteGoogleSetting();
    await fetchGoogleSetting();
  });

  emit('update:user');

  window.location.reload(true);
};

return (_ctx: any,_cache: any) => {
  const _component_BAlert = _resolveComponent("BAlert")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BMoreMenu = _resolveComponent("BMoreMenu")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    (showConnectErrorMessage.value)
      ? (_openBlock(), _createBlock(_component_BAlert, {
          key: 0,
          text: _ctx.$t('google.errorMessage.connectError'),
          class: "mb-400",
          type: "error",
          effect: "light",
          "show-icon": ""
        }, null, 8, ["text"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (isConnect.value)
        ? (_openBlock(), _createBlock(_component_BLayout, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_BLayout, { column: "" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("img", {
                      src: require('@/assets/gmail.png')
                    }, null, 8, _hoisted_3),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('google.title')) + " ", 1),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('google.title')) + _toDisplayString(_ctx.$t('google.status.connect')), 1)
                  ]),
                  _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createTextVNode(_toDisplayString(_ctx.user?.senderEmail), 1)
                  ])), [
                    [_directive_loading, settingLoading.value]
                  ])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_BMoreMenu, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_BBtn, {
                      flat: "",
                      fit: "",
                      size: "small",
                      disabled: settingLoading.value || deleting.value,
                      onClick: unconnect
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('google.disconnect')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("img", {
                src: require('@/assets/gmail.png')
              }, null, 8, _hoisted_8),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('google.title')), 1)
            ]),
            _withDirectives((_openBlock(), _createBlock(_component_BLayout, {
              column: "",
              class: "form"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_BIcon, { class: "mr-100" }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode(" check ")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('google.explain')), 1)
                ]),
                _createVNode(_component_BBtn, {
                  type: "primary",
                  outline: "",
                  disabled: settingLoading.value,
                  onClick: connect
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('google.btnText')), 1),
                    _createVNode(_component_BIcon, null, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode("keyboard_arrow_right")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            })), [
              [_directive_loading, settingLoading.value]
            ])
          ], 64))
    ])
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { useI18n } from 'vue-i18n'
import { columns } from './instance-list-header';

type TProps = {
  modelValue: boolean;
};
type TEmit = {
  (event: 'update:modelValue', checked: boolean): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InstanceListHeader',
  props: {
    modelValue: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const i18n = useI18n();
const checkboxValue = computed<boolean>({
  get() {
    return props.modelValue;
  },
  set(value: boolean) {
    emit('update:modelValue', value);
  },
})

const toLabel = (key: string) => {
  if (key === 'companyName') return i18n.t(`companyInfo.item.companyName`);
  if (key === 'fullName') return i18n.t(`callTargetInfo.item.name`);
  if (key === 'registrationDate') return i18n.t(`sequence.registrationDate`);
  if (key === 'status') return i18n.t(`sequence.status.title`);
  if (key === 'isOpportunityAssociated') return i18n.t(`sequence.isOpportunityAssociated`);
  if (key === 'lastLeadStage') return i18n.t(`leadRelatedInfo.lastLeadStageName`);
  if (key === 'numberOfDoneSteps') return i18n.t(`sequence.numberOfDoneSteps`);
  if (key === 'numberOfCurrentStepStays') return i18n.t(`sequence.numberOfCurrentStepStays`);
  if (key === 'owner') return i18n.t(`leadRelatedInfo.owner`);
};

return (_ctx: any,_cache: any) => {
  const _component_BCheckbox = _resolveComponent("BCheckbox")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createBlock(_component_BLayout, {
    class: "instance-list-row",
    "align-center": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "instance-list-item checkbox",
        onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
      }, [
        _createVNode(_component_BCheckbox, {
          modelValue: checkboxValue.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((checkboxValue).value = $event)),
          class: "mt-0",
          indeterminate: checkboxValue.value
        }, null, 8, ["modelValue", "indeterminate"])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns), (column) => {
        return (_openBlock(), _createElementBlock("div", {
          key: column.key,
          class: "instance-list-item instance-list-header",
          style: _normalizeStyle({
        'width': column.width,
        'min-width': column.width,
        'max-width': column.width
      })
        }, [
          _createElementVNode("span", null, _toDisplayString(toLabel(column.key)), 1)
        ], 4))
      }), 128)),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "instance-list-item end-column" }, null, -1))
    ]),
    _: 1
  }))
}
}

})
<template>
  <div v-loading="loading">
    <div class="navbar mb-200">
      <div class="pl-600">
        <SelectUser
          v-model="selectedUserId"
        />
      </div>
      <BPagination
        v-if="allPageCount > 0"
        v-model="currentPage"
        :length="allPageCount"
        :total-visible="5"
      />
    </div>
    <div>
      <div class="theader">
        <div class="table-row theader-row">
          <BSortText
            v-for="cell in headers"
            :key="cell.key"
            class="table-cell header-cell"
            :class="cell.class"
            :item-text="cell.name"
            is-display-only
          />
        </div>
      </div>
      <div class="tbody">
        <div
          v-for="sequenceMaster in sequenceMasters"
          :key="sequenceMaster.id"
          class="table-row tbody-row"
          @click="() => selectSequenceMaster(sequenceMaster.id)"
        >
          <div
            v-for="column in headers"
            :key="column.key"
            class="table-cell"
            :class="column.class"
          >
            <template v-if="column.key === 'name'">
              <div class="name">
                {{ sequenceMaster.name }}
              </div>
            </template>
            <template v-else-if="column.key === 'summary.all'">
              {{ sequenceMaster.numberOfInstances }}
            </template>
            <template v-else-if="column.key === 'summary.registered'">
              {{ sequenceMaster.numberOfRegistered }}
            </template>
            <template v-else-if="column.key === 'summary.stopped'">
              {{ sequenceMaster.numberOfStopped }}
            </template>
            <template v-else-if="column.key === 'summary.done'">
              {{ sequenceMaster.numberOfDone }}
            </template>
            <template v-else-if="column.key === 'summary.associatedOpportunity'">
              {{ sequenceMaster.numberOfOpportunities }}
            </template>
            <template v-else-if="column.key === 'summary.unregistered'">
              {{ sequenceMaster.numberOfUnregistered }}
            </template>
            <template v-else-if="column.key === 'createdUser'">
              <BUserTooltip
                v-if="sequenceMaster.createdUser"
                :image-url="sequenceMaster.createdUser.avatarUrl"
                :name="sequenceMaster.createdUser.name"
                :user-id="sequenceMaster.createdUser.id"
                :size="40"
              />
            </template>
            <template v-else-if="column.key === 'updatedUser'">
              <BUserTooltip
                v-if="sequenceMaster.updatedUser"
                :image-url="sequenceMaster.updatedUser.avatarUrl"
                :name="sequenceMaster.updatedUser.name"
                :user-id="sequenceMaster.updatedUser.id"
                :size="40"
              />
            </template>
            <template v-else-if="column.key === 'owner'">
              <BUserTooltip
                v-if="sequenceMaster.owner"
                :image-url="sequenceMaster.owner.avatarUrl"
                :name="sequenceMaster.owner.name"
                :user-id="sequenceMaster.owner.id"
                :size="40"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { AxiosResponse } from 'axios';
import { computed, inject, ref, watch, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n'
import { GetSequenceMasters200Response, PaginationMeta, SequenceMaster } from '@/api/openapi';
import { SequenceMasterApiService } from '@/api/user/resources/sequence_master';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { KEY_OF_WAIT } from '@/injection-keys';
import SelectUser from '@/plugins/biscuet-materials/components/molecules/SelectUser.vue';

type TEmit = {
  'selectSequenceMaster': [sequenceMasterId: number];
};

const emit = defineEmits<TEmit>();

const {
  doActionWithWait,
} = useWait();

const wait = inject(KEY_OF_WAIT);
const i18n = useI18n();
const loading = computed(() => wait.is(['fetchSequenceMasters']));
const headers = [
  { key: 'name', name: i18n.t('sequence.name'), class: ['name-container'] },
  { key: 'summary.all', name: i18n.t('sequence.summary.all'), class: ['count-text'] },
  { key: 'summary.registered', name: i18n.t('sequence.summary.registered'), class: ['count-text'] },
  { key: 'summary.stopped', name: i18n.t('sequence.summary.stopped'), class: ['count-text'] },
  { key: 'summary.done', name: i18n.t('sequence.summary.done'), class: ['count-text'] },
  { key: 'summary.associatedOpportunity', name: i18n.t('sequence.summary.associatedOpportunity'), class: ['count-text'] },
  { key: 'summary.unregistered', name: i18n.t('sequence.summary.unregistered'), class: ['count-text'] },
  { key: 'createdUser', name: i18n.t('sequence.createdUser'), class: ['user'] },
  { key: 'updatedUser', name: i18n.t('sequence.updatedUser'), class: ['user'] },
  { key: 'owner', name: i18n.t('sequence.owner'), class: ['user'] },
];

const selectSequenceMaster = (sequenceMasterId: number) => {
  emit('selectSequenceMaster', sequenceMasterId);
}

const currentPage = ref(1);
const meta = ref<PaginationMeta>({
  totalPages: 1,
  totalCount: 0,
  limitValue: 0,
  currentPage: 1,
  isEmpty: false,
});

const { currentUser, fetchCurrentUser } = useCurrentUser();
const selectedUserId = ref<number | undefined>();

onBeforeMount(async () => {
  await fetchCurrentUser();
  selectedUserId.value = currentUser.value.id;
  await loadSequenceMasters(currentPage.value);
})

const loadSequenceMasters = async (currentPage: number) => {
  const ownerId = selectedUserId.value
  const api = new SequenceMasterApiService();
  const { data: { sequenceMasters: dataSequenceMasters, meta: dataMeta } } = await doActionWithWait<AxiosResponse<GetSequenceMasters200Response>>('fetchSequenceMasters', async () => {
    return await api.getSequenceMasters({
      request: {
        currentPage,
        ownerId,
      },
    });
  });

  sequenceMasters.value = dataSequenceMasters;
  meta.value = dataMeta;
};

const sequenceMasters = ref<SequenceMaster[]>([]);
const allPageCount = computed(() => meta.value.totalPages);

watch(() => currentPage.value, (newValue) => {
  loadSequenceMasters(newValue);
});

watch(() => selectedUserId.value, (newValue) => {
  loadSequenceMasters(currentPage.value);
});
</script>

<style lang="scss" scoped>
.navbar {
  margin-right: 30px;
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
}

.table-row {
  align-items: center;
  padding-right: $basespace-400;
  display: inline-flex;
  min-width: 100%;
}

.table-cell {
  @include m-fixed-width(50px);
  padding-right: $basespace-200;
  padding-left: 10px;
  background-color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:first-child {
    padding-left: 30px;
  }

  &.name-container {
    @include m-fixed-width(45%);
    min-width: 20rem;
    position: sticky;
    left: 0;
    z-index: 1;
    display: flex;
    // NOTE: user列と高さを揃えている
    min-height: 40px;
    align-items: center;
    .name {
      flex: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &.count-text {
    @include m-fixed-width(5%);
    min-width: 4rem;
  }
  &.user {
    @include m-fixed-width(6%);
    min-width: 80px;
  }
}

.theader {
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 2;
  cursor: text;
  &-row {
    height: inherit;
    background-color: white;
    border-bottom: 1px solid $bdcolor-base;
    padding-bottom: $basespace-100;
    color: $textcolor-light;
    font-size: $fontsize-100;
  }
}

.tbody-row {
  border-bottom: 1px solid $bdcolor-base;
  padding-top: $basespace-200;
  padding-bottom: $basespace-200;

  cursor: pointer;
  &:hover {
    background-color: $bgcolor-base;

    .table-cell {
      background-color: $bgcolor-base;
    }
  }

  .table-cell.count-text {
    font-weight: bold;
  }
}
</style>


import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "file-name truncate-text" }

import { FileStorageAccessApiService } from '@/api/user/resources/file_storage_access';

type TProps = {
  id: number;
  fileName: string;
  enableLink?: boolean;
  showCrossButton?: boolean;
};

type TEmit = {
  (event: 'clickCrossIcon', mailAttachmentId: number): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MailAttachment',
  props: {
    id: {},
    fileName: {},
    enableLink: { type: Boolean, default: true },
    showCrossButton: { type: Boolean, default: false }
  },
  emits: ["clickCrossIcon"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const handleClick = async () => {
  if (!props.enableLink) return;
  const url = await fetchDownloadUrl();
  click(url);
};

const handleCrossIconClick = () => {
  emit('clickCrossIcon', props.id);
};

const fetchDownloadUrl = async () => {
  const apiService = new FileStorageAccessApiService();
  const { data } = await apiService.createMailAttachmentPresignedUrl({ 
    request: {
      createMailAttachmentPresignedUrlRequest: {
        mailAttachmentId: props.id,
      },
    },
  });
  return data.url;
};

const click = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.click();
};

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["mail-attachment-container", { link: _ctx.enableLink }]),
    onClick: handleClick
  }, [
    _createVNode(_component_BIcon, { class: "icon" }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("span", { class: "material-symbols-outlined" }, "draft", -1)
      ])),
      _: 1
    }),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.fileName), 1),
    (_ctx.showCrossButton)
      ? (_openBlock(), _createBlock(_component_BIcon, {
          key: 0,
          class: "cross-icon",
          onClick: handleCrossIconClick
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("span", null, "close", -1)
          ])),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}
}

})
import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "header-title" }

type TProps = {
  title: string;
  loading: boolean;
  name: string;
  content: string;
};
type TEmit = {
  'click:publish': [];
  'click:edit': [];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfirmDrawer',
  props: /*@__PURE__*/_mergeModels({
    title: {},
    loading: { type: Boolean },
    name: {},
    content: {}
  }, {
    "isShow": { type: Boolean, ...{ default: false } },
    "isShowModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["click:publish", "click:edit"], ["update:isShow"]),
  setup(__props: any, { emit: __emit }) {


const emit = __emit;

const isShow = _useModel<boolean>(__props, 'isShow');

const handleClickSave = async () => {
  emit('click:publish');
};
const handleClickEdit = async () => {
  emit('click:edit');
};

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BDrawerMenu = _resolveComponent("BDrawerMenu")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_BDrawerMenu, {
    modelValue: isShow.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isShow).value = $event))
  }, {
    header: _withCtx(() => [
      _createVNode(_component_BLayout, {
        class: "menu-header",
        "align-center": "",
        "justify-space-between": ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
          _createVNode(_component_BBtn, {
            size: "small",
            type: "primary",
            outline: "",
            loading: _ctx.loading,
            onClick: handleClickSave
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.save.text')), 1),
              _createVNode(_component_BIcon, null, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("keyboard_arrow_right")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["loading"])
        ]),
        _: 1
      })
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_BLayout, {
        class: "py-300",
        row: "",
        "align-center": "",
        "justify-center": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BBtn, {
            outline: "",
            loading: _ctx.loading,
            onClick: handleClickEdit
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BIcon, null, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("keyboard_arrow_left")
                ])),
                _: 1
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.edit.moveTo')), 1)
            ]),
            _: 1
          }, 8, ["loading"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_BListItem, { class: "mb-300" }, {
          header: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('quickText.name')), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_BInput, {
              "model-value": _ctx.name,
              readonly: ""
            }, null, 8, ["model-value"])
          ]),
          _: 1
        }),
        _createVNode(_component_BListItem, { class: "mb-300" }, {
          header: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('quickText.content')), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_BInput, {
              "model-value": _ctx.content,
              type: "textarea",
              rows: 16,
              readonly: ""
            }, null, 8, ["model-value"])
          ]),
          _: 1
        })
      ])), [
        [_directive_loading, _ctx.loading]
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})
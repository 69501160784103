import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sequence-leads-container" }

import InstanceList from '@/components/organisms/user/sequenceLeads/InstanceList.vue'
import InstancesSummary from '@/components/organisms/user/sequenceLeads/InstancesSummary.vue'

type TEmits = {
  (event: 'after:moveInstanceListPage', value: number): void;
  (event: 'click:applyToLeadButton'): void;
  (event: 'update:selectedSequenceInstanceIds', values: number[]): void;
};

type TProps = {
  sequenceMasterId: number;
  instanceListPage: number;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceLeads',
  props: {
    sequenceMasterId: {},
    instanceListPage: {}
  },
  emits: ["after:moveInstanceListPage", "click:applyToLeadButton", "update:selectedSequenceInstanceIds"],
  setup(__props: any, { emit: __emit }) {


const emit = __emit;

const handleSelectedSequenceInstanceIdsUpdate = (values: number[]) => {
  emit('update:selectedSequenceInstanceIds', values);
};
const handleAfterMovePage = (value: number) => {
  emit('after:moveInstanceListPage', value);
};
const handleApplyLeadButtonClick = () => {
  emit('click:applyToLeadButton');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(InstancesSummary, {
      "sequence-master-id": _ctx.sequenceMasterId,
      "onClick:applyToLeadButton": handleApplyLeadButtonClick
    }, null, 8, ["sequence-master-id"]),
    _createVNode(InstanceList, {
      class: "instance-list",
      "sequence-master-id": _ctx.sequenceMasterId,
      page: _ctx.instanceListPage,
      "onUpdate:selectedSequenceInstanceIds": handleSelectedSequenceInstanceIdsUpdate,
      "onAfter:movePage": handleAfterMovePage
    }, null, 8, ["sequence-master-id", "page"])
  ]))
}
}

})
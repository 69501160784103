import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, TransitionGroup as _TransitionGroup } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BEmptyBox = _resolveComponent("BEmptyBox")!
  const _component_NextActionCard = _resolveComponent("NextActionCard")!
  const _component_BListItem = _resolveComponent("BListItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BListItem, null, {
      header: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('nextAction.title')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_BEmptyBox, {
          class: "pt-100 pb-100 mb-300",
          onClick: _ctx.handleAddNextAction
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BIcon, { size: "small" }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" add ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_TransitionGroup, { name: "list" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.internalNextActions, (nextAction, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: `nextActionCard-${index}`
              }, [
                _createVNode(_component_NextActionCard, {
                  class: "mb-200",
                  "next-action": nextAction,
                  "call-target-id": _ctx.callTargetId,
                  "is-valid": _ctx.nextActionsIsValid[index],
                  "onUpdate:nextAction": (val) => _ctx.handleUpdateNextAction(index, val),
                  "onUpdate:isValid": (val) => _ctx.handleUpdateNextActionIsValid(index, val),
                  onClose: () => _ctx.handleCloseNextAction(index)
                }, null, 8, ["next-action", "call-target-id", "is-valid", "onUpdate:nextAction", "onUpdate:isValid", "onClose"])
              ]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
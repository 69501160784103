<template>
  <div class="header">
    <div class="table-row">
      <div
        v-for="column in columns"
        :key="column.key"
        class="table-cell"
        :class="column.key"
      >
        {{ column.label }}
      </div>
    </div>
  </div>
  <div
    v-loading="loading"
    class="body"
  >
    <BEmptyBox
      v-if="!quickTexts.length"
      class="py-400 mt-400 mx-400"
      display-only
    >
      <slot name="text">
        {{ $t('apiError.notFound') }}
      </slot>
    </BEmptyBox>
    <template v-else>
      <div
        v-for="quickText in quickTexts"
        :key="quickText.id"
        class="table-row"
        @click="() => emit('select', quickText.content)"
      >
        <div
          v-for="column in columns"
          :key="column.key"
          class="table-cell"
          :class="column.key"
        >
          <template v-if="column.key === 'name'">
            <div class="mb-50">
              {{ quickText.name }}
            </div>
            <BStatus
              :status="quickText.status"
              :status-name="$t(`general.status.${quickText.status}`)"
            />
          </template>
          <template v-else-if="['createdAt', 'updatedAt'].includes(column.key)">
            {{ formatShorterDateTime(quickText[column.key]) }}
          </template>
          <template v-else-if="['createdUser', 'updatedUser'].includes(column.key)">
            <BUserTooltip
              :image-url="quickText[column.key].avatarUrl"
              :name="quickText[column.key].name"
              :user-id="quickText[column.key].id"
              :size="40"
            />
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
// NOTE: 現状クイックテキストの上限数が１０個固定であるため、ページングは実装していない。上限が増えるようなら、ページングや無限スクロールなどの実装が必要になる。
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n'
import { QuickText, QuickTextStatus } from '@/api/openapi';
import { QuickTextApiService } from '@/api/user/resources/quick_text';
import { useWait } from '@/composable/vue-wait';
import { formatShorterDateTime } from '@/utils/date-time';

type TProps = {
  callTargetId: number;
};
type TEmits = {
  select: [content: string];
};
const props = defineProps<TProps>();
const emit = defineEmits<TEmits>();

const i18n = useI18n()

const quickTexts = ref<QuickText[]>([]);

const api = new QuickTextApiService();
const { doActionWithWait, wait } = useWait();
const loadQuickTexts = async () => {
  const currentPage = 1;
  const statuses: QuickTextStatus[] = ['published'];
  const sourceCallTargetId = props.callTargetId;
  doActionWithWait('getQuickTextsWait', async () => {
    const { data } = await api.getQuickTexts({ request: { currentPage, statuses, sourceCallTargetId } });
    quickTexts.value = data.quickTexts;
  });
};
loadQuickTexts();

const columns = [
  { key: 'name', label: i18n.t('quickText.name') },
  { key: 'createdAt', label: i18n.t('general.createdAt') },
  { key: 'updatedAt', label: i18n.t('general.updatedAt') },
  { key: 'createdUser', label: i18n.t('general.createdUser') },
  { key: 'updatedUser', label: i18n.t('general.updatedUser') },
];

const loading = computed(() => {
  return wait.is('getQuickTextsWait');
});
</script>

<style lang="scss" scoped>
$header-height: 50px;
.table-row {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  gap: $basespace-200;
  padding: 0 30px;
}

.table-cell {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &.name {
    width: 400px;
  }

  &.createdAt, &.updatedAt {
    width: 120px;
  }

  &.createdUser, &.updatedUser {
    width: 120px;
  }
}

.header {
  height: $header-height;
  cursor: text;

  .table-row {
    height: inherit;
    background-color: white;
    border-bottom: 1px solid $bdcolor-base;
    padding-bottom: $basespace-100;
    color: $textcolor-light;
    font-size: $fontsize-100;

    .table-cell {
      &.createdUser, &.updatedUser {
        text-align: center;
      }
    }
  }
}

.body {
  overflow-y: auto;
  height: calc(100% - $header-height);
  .table-row {
    border-bottom: 1px solid $bdcolor-base;
    padding-top: $basespace-200;
    padding-bottom: $basespace-200;

    cursor: pointer;
    &:hover {
      background-color: $bgcolor-base;
    }

    .table-cell {
      &.createdUser, &.updatedUser {
        :deep(.b-avatar) {
          justify-content: center;
        }
      }
    }
  }
}
</style>

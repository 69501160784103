import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "sequence-apply-modal" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "explain" }
const _hoisted_4 = { class: "explain" }
const _hoisted_5 = { class: "explain" }
const _hoisted_6 = { class: "explain" }
const _hoisted_7 = { class: "append-option" }
const _hoisted_8 = { class: "append-option" }

import { ref, computed, nextTick } from 'vue';
import { MultiFactorError } from 'firebase/auth';
import { useI18n } from 'vue-i18n';
import { useInputValidations } from '@/composable/input-validations';
import { useFirebaseRecaptcha } from '@/composable/user/mfaSetting/firebase-recaptcha';
import { useFirebaseAuth } from '@/composable/user/mfaSetting/firebase-auth';
import { useWait } from '@/composable/vue-wait';

type TProps = {
  modalName: string;
  multiFactorError: MultiFactorError;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MfaModal',
  props: {
    modalName: {},
    multiFactorError: {}
  },
  emits: ['close', 'authenticated'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const i18n = useI18n();
const { wait } = useWait();
const { requiredRule, phoneRule, numberRule } = useInputValidations();

const password = ref<string | null>(null);
const passwordValid = ref<boolean>();
const phoneNumber = ref<string | null>(null);
const phoneNumberValid = ref<boolean>();
const verificationCode = ref<string | null>(null);
const verificationCodeValid = ref<boolean>();

const signin = computed<boolean>(() =>
  !!props.multiFactorError,
);
const internalMultiFactorError = computed<MultiFactorError>(() =>
  props.multiFactorError,
);

const {
  setRecaptcha,
  resetRecaptcha,
} = useFirebaseRecaptcha(
  'recaptcha-container',
);

const {
  reAuth,
  reAuthed,
  sendVerificationCode,
  sentVerificationCode,
  resolveVerificationCode,
  errorMessage,
  resetErrorMessage,
  resetVerification,
} = useFirebaseAuth(
  signin,
  internalMultiFactorError,
  setRecaptcha,
);

const handleOpen = async () => {
  if (signin.value) {
    nextTick(() => {
      sendVerificationCode(phoneNumber.value);
    });
  }
};

const handleCloseClick = () => {
  password.value = null;
  phoneNumber.value = null;
  verificationCode.value = null;
  resetErrorMessage();
  resetVerification();
  emit('close');
};

const handleReAuthClick = async () => {
  resetErrorMessage();
  await reAuth(password.value);
};

const handleSendVerificationCodeClick = async () => {
  resetErrorMessage();
  await sendVerificationCode(phoneNumber.value);
};

const handleReSendClick = async () => {
  verificationCode.value = null;
  resetErrorMessage();
  resetRecaptcha();
  await sendVerificationCode(phoneNumber.value);
};

const handleChangePhoneNumberClick = () => {
  verificationCode.value = null;
  resetErrorMessage();
  resetVerification();
};

const handleResolveVerificationCodeClick = async () => {
  resetErrorMessage();
  await resolveVerificationCode(phoneNumber.value, verificationCode.value);
  if (!errorMessage.value) {
    emit('authenticated');
  }
};

return (_ctx: any,_cache: any) => {
  const _component_BModalHeader = _resolveComponent("BModalHeader")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BAlert = _resolveComponent("BAlert")!
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BModalBody = _resolveComponent("BModalBody")!
  const _component_BHeightAdjuster = _resolveComponent("BHeightAdjuster")!
  const _component_BModal = _resolveComponent("BModal")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BModal, {
      "modal-name": _ctx.modalName,
      full: "",
      onOpenModalEvent: handleOpen
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BHeightAdjuster, null, {
          default: _withCtx(() => [
            _createVNode(_component_BModalHeader, { onModalClose: handleCloseClick }),
            _createVNode(_component_BModalBody, null, {
              default: _withCtx(() => [
                _withDirectives((_openBlock(), _createBlock(_component_BLayout, {
                  column: "",
                  class: "form"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BListItem, null, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(i18n).t('mfaSetting.title')), 1)
                      ]),
                      _: 1
                    }),
                    (_unref(errorMessage))
                      ? (_openBlock(), _createBlock(_component_BAlert, {
                          key: 0,
                          text: _unref(errorMessage),
                          class: "mt-400 mb-400",
                          type: "error",
                          effect: "light"
                        }, null, 8, ["text"]))
                      : _createCommentVNode("", true),
                    (!signin.value && !_unref(reAuthed))
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createVNode(_component_BListItem, { class: "mt-400" }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(i18n).t('userSecurity.mfaSetting.inputPassword')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_BListItem, { class: "mt-400" }, {
                            header: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(_unref(i18n).t('general.password')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_BInput, {
                                modelValue: password.value,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((password).value = $event)),
                                valid: passwordValid.value,
                                "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => ((passwordValid).value = $event)),
                                type: "password",
                                class: "w-input",
                                color: "focus",
                                rules: [_unref(requiredRule)],
                                disabled: _unref(wait).is('reAuthWait')
                              }, null, 8, ["modelValue", "valid", "rules", "disabled"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_BListItem, { class: "mt-600" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_BBtn, {
                                type: "primary",
                                class: "btn-wide",
                                disabled: !passwordValid.value,
                                loading: _unref(wait).is('reAuthWait'),
                                onClick: handleReAuthClick
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_unref(i18n).t('general.authorize.do')), 1)
                                ]),
                                _: 1
                              }, 8, ["disabled", "loading"])
                            ]),
                            _: 1
                          })
                        ], 64))
                      : (!signin.value && !_unref(sentVerificationCode))
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _createVNode(_component_BListItem, { class: "mt-400" }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(i18n).t('userSecurity.mfaSetting.inputPhoneNumber')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_BListItem, { class: "mt-400" }, {
                              header: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(_unref(i18n).t('general.tel')), 1)
                              ]),
                              default: _withCtx(() => [
                                _createVNode(_component_BInput, {
                                  modelValue: phoneNumber.value,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((phoneNumber).value = $event)),
                                  valid: phoneNumberValid.value,
                                  "onUpdate:valid": _cache[3] || (_cache[3] = ($event: any) => ((phoneNumberValid).value = $event)),
                                  class: "w-input",
                                  rules: [_unref(requiredRule), _unref(phoneRule)],
                                  disabled: _unref(wait).is('sendVerificationCodeWait'),
                                  clearable: ""
                                }, null, 8, ["modelValue", "valid", "rules", "disabled"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_BListItem, { class: "mt-600" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BBtn, {
                                  type: "primary",
                                  class: "btn-wide",
                                  disabled: !phoneNumberValid.value,
                                  loading: _unref(wait).is('sendVerificationCodeWait'),
                                  onClick: handleSendVerificationCodeClick
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(i18n).t('general.toNext')), 1)
                                  ]),
                                  _: 1
                                }, 8, ["disabled", "loading"])
                              ]),
                              _: 1
                            })
                          ], 64))
                        : (signin.value || _unref(sentVerificationCode))
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                              (!signin.value && !_unref(errorMessage))
                                ? (_openBlock(), _createBlock(_component_BListItem, {
                                    key: 0,
                                    class: "mt-400"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(i18n).t('userSecurity.mfaSetting.inputVerificationCodeWhenRegistor')), 1)
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              (signin.value && !_unref(errorMessage))
                                ? (_openBlock(), _createBlock(_component_BListItem, {
                                    key: 1,
                                    class: "mt-400"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(i18n).t('userSecurity.mfaSetting.inputVerificationCodeWhenSignin')), 1)
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              _createVNode(_component_BListItem, { class: "mt-200" }, {
                                header: _withCtx(() => [
                                  _createElementVNode("span", null, _toDisplayString(_unref(i18n).t('mfaSetting.verificationCode')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_BInput, {
                                    modelValue: verificationCode.value,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((verificationCode).value = $event)),
                                    valid: verificationCodeValid.value,
                                    "onUpdate:valid": _cache[5] || (_cache[5] = ($event: any) => ((verificationCodeValid).value = $event)),
                                    "min-length": 6,
                                    "max-length": 6,
                                    rules: [_unref(requiredRule), _unref(numberRule)],
                                    disabled: _unref(wait).is('resolveVerificationCodeWait'),
                                    class: "w-input",
                                    clearable: ""
                                  }, null, 8, ["modelValue", "valid", "rules", "disabled"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_BListItem, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_BBtn, {
                                    type: "primary",
                                    text: "",
                                    size: "small",
                                    onClick: handleReSendClick
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(i18n).t('userSecurity.mfaSetting.reSendVerificationCode')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              (!signin.value && _unref(errorMessage))
                                ? (_openBlock(), _createBlock(_component_BListItem, { key: 2 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_BBtn, {
                                        type: "primary",
                                        text: "",
                                        size: "small",
                                        onClick: handleChangePhoneNumberClick
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(i18n).t('userSecurity.mfaSetting.changePhoneNumber')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              _createVNode(_component_BListItem, { class: "mt-600" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_BBtn, {
                                    type: "primary",
                                    class: "btn-wide",
                                    disabled: !verificationCodeValid.value,
                                    loading: _unref(wait).is('resolveVerificationCodeWait'),
                                    onClick: handleResolveVerificationCodeClick
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_unref(i18n).t('general.authorize.do')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["disabled", "loading"])
                                ]),
                                _: 1
                              })
                            ], 64))
                          : _createCommentVNode("", true)
                  ]),
                  _: 1
                })), [
                  [_directive_loading, _unref(wait).is(['reAuthWait', 'sendVerificationCodeWait', 'resolveVerificationCodeWait'])]
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("div", { id: "recaptcha-container" }, null, -1))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modal-name"])
  ]))
}
}

})
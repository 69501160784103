import { SequenceInstance } from '@/api/openapi';

class SequenceInstances extends Array<SequenceInstance> {

  constructor(...items: SequenceInstance[]) {
    super(...items);
    // https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    // targetのバージョンをあげればたぶんいらなくなる
    Object.setPrototypeOf(this, SequenceInstances.prototype);
  }

  findActive(): SequenceInstance | undefined {
    return this.find(e => ['registered', 'stopped'].includes(e.status));
  }
}

export {
  SequenceInstances,
}

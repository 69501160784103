<template>
  <div class="quick-text-form-drawer">
    <ConfirmDrawer 
      v-if="isConfirm"
      v-model:is-show="isShow"
      :name="name"
      :content="content"
      :title="title"
      :loading="loading"
      @click:publish="handleSaveClick"
      @click:edit="handleEditClick"
    />
    <FormDrawer
      v-else
      v-model:is-show="isShow"
      v-model:name="name"
      v-model:content="content"
      v-model:is-valid-name="isValidName"
      v-model:is-valid-content="isValidContent"
      :title="title"
      :loading="loading"
      @click:draft-save="handleDraftSaveClick"
      @click:confirm="handleConfirmClick"
      @click:close="handleCloseClick"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { QuickTextBody } from '@/api/openapi';
import { QuickTextApiService } from '@/api/user/resources/quick_text';
import { useUnsavedConfirm } from '@/composable/message-dialog';
import { useWait } from '@/composable/vue-wait';
import { KEY_OF_TOAST } from '@/injection-keys';
import ConfirmDrawer from './ConfirmDrawer.vue';
import FormDrawer from './FormDrawer.vue';

type TProps = {
  quickTextId?: number;
};
type TEmit = {
  'afterSave': [];
  'update:isChanged': [isChanged: boolean];
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const isShow = defineModel<boolean>('isShow', { default: false });
const isConfirm = ref<boolean>(false);

const innerQuickTextId = ref<number>(undefined);
const orgQuickText = ref<QuickTextBody>(undefined);
const name = ref<string>('');
const content = ref<string>('');
const isValidName = ref<boolean>(false);
const isValidContent = ref<boolean>(false);

const KEY_OF_SAVE_WAIT = 'saveQuickTextWait';
const toast = inject(KEY_OF_TOAST);
const { doActionWithWait, wait } = useWait();
const { unsavedConfirm } = useUnsavedConfirm();
const i18n = useI18n();
const api = new QuickTextApiService();

const resetFormValues = (newId: number | undefined) => {
  innerQuickTextId.value = newId;
  name.value = '';
  content.value = '';
  isConfirm.value = false;
  orgQuickText.value = undefined;
  isValidName.value = false;
  isValidContent.value = false;
};
watch(() => ({ isShow: isShow.value, quickTextId: props.quickTextId }), async (newValue) => {
  const { isShow: newIsShow, quickTextId: newId } = newValue;
  if (!newIsShow) return;
  resetFormValues(newId);

  if (!newId) return;
  await doActionWithWait('fetchQuickTextWait', async () => {
    const { data: { quickText } } = await api.getQuickText({
      request: {
        quickTextId: newId,
      },
    });

    orgQuickText.value = quickText;
    name.value = quickText.name;
    content.value = quickText.content;
  });
}, { immediate: true });

const title = computed(() => {
  return innerQuickTextId.value == null
    ? i18n.t('general.create.to', { item: i18n.t('quickText.title') })
    : i18n.t('general.edit.to', { item: i18n.t('quickText.title') })
});
const loading = computed(() => {
  return wait.is([KEY_OF_SAVE_WAIT, 'fetchQuickTextWait']);
});
const isChanged = computed(() => {
  const orgName = orgQuickText.value?.name ?? '';
  const orgContent = orgQuickText.value?.content ?? '';
  return orgName !== name.value || orgContent !== content.value;
});
watch(() => isChanged.value, (newValue) => {
  emit('update:isChanged', newValue);
}, { immediate: true });

// methods for FormDrawer
const handleCloseClick = async () => {
  if (isChanged.value) {
    const ok = await unsavedConfirm();
    if (!ok) return;
  }

  closeDrawer();
};
const handleDraftSaveClick = () => {
  save('draft');
};
const handleConfirmClick = () => {
  save('draft', () => {
    isConfirm.value = true;
  });
};

// methods for ConfirmDrawer
const handleSaveClick = () => {
  save('published', () => {
    closeDrawer();
  });
};
const handleEditClick = () => {
  isConfirm.value = false;
};

// ここから共通処理
const closeDrawer = () => {
  isShow.value = false;
};

const save = (status: QuickTextBody['status'], afterSave?: () => void) => {
  if (innerQuickTextId.value != null) {
    update(status, afterSave);
  } else {
    create(status, afterSave);
  }
};
const showSavedToast = () => {
  toast.show(
    i18n.t('general.done.of', { 
      action: i18n.t('general.save.text'),
    }),
  );
};
const makeQuickTextBody = (status: QuickTextBody['status']): QuickTextBody => ({
  name: name.value,
  content: content.value,
  status,
});
const create = async (status: QuickTextBody['status'], afterSave?: () => void) => {
  await doActionWithWait(KEY_OF_SAVE_WAIT, async () => {
    const { data } = await api.postQuickText({
      request: {
        quickTextBody: makeQuickTextBody(status),
      },
    });
    innerQuickTextId.value = data.id;
    if (afterSave) afterSave();
    showSavedToast();
    emit('afterSave');
  });
};
const update = async (status: QuickTextBody['status'], afterSave?: () => void) => {
  await doActionWithWait(KEY_OF_SAVE_WAIT, async () => {
    await api.putQuickText({
      request: {
        quickTextId: innerQuickTextId.value,
        quickTextBody: makeQuickTextBody(status),
      },
    });
    if (afterSave) afterSave();
    showSavedToast();
    emit('afterSave');
  });
};
</script>

<style lang="scss" scoped>
:deep(.header-title) {
  font-weight: 600;
}
.quick-text-form-drawer {
  &:deep(.b-drawer-menu) {
    left: auto;
    right: -320px;
    top: -49px; // NOTE: ヘッダの直下から描画する。示唆分-1px
    &.open {
      left: auto;
      right: 0;
    }
  }
  :deep(.b-drawer-menu-body) {
    height: calc(100% - 168px); // NOTE: 共通ヘッダ, ヘッダ, フッターの高さを引く
  }
}
</style>

import ApiService, { ApiArguments } from '@/api/api_service';
import { SendgridDomain, SendgridDomainApi } from '@/api/openapi';

export class SendgridDomainApiService extends ApiService {
  api = new SendgridDomainApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true)
  }

  async getSendgridDomains({
    options,
    errorHandlers,
  }: ApiArguments<never> | undefined = {}) {
    return this.checkResponse<SendgridDomain[]>(
      this.api.getSendgridDomains(options),
      errorHandlers,
    );
  }
}

<template>
  <div>
    <BLayout class="b-select-btn">
      <BFlex
        v-for="(item, index) in items"
        :key="`selectBtn-${index}`"
        class="select-btn-wrapper"
        :span="12"
      >
        <BBtn
          v-bind="btnAttributes(item.value)"
          fit
          @click="() => handleStatus(item.value)"
        >
          <span>{{ item.text }}</span>
        </BBtn>
      </BFlex>
    </BLayout>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    disabledValue: {
      type: Array,
      default () {
        return []
      },
    },
    modelValue: String,
  },
  emits: [
    'update:modelValue',
  ],
  computed: {
    internalValue: {
      get () {
        return this.modelValue
      },
      set (newVal) {
        if (this.modelValue !== newVal) this.$emit('update:modelValue', newVal)
      },
    },
  },
  methods: {
    btnAttributes (value) {
      return {
        type: this.isSelected(value) ? 'primary' : 'default',
        disabled: this.isDesabled(value),
      }
    },
    isSelected (value) {
      return this.internalValue === value
    },
    isDesabled (value) {
      return this.disabledValue.includes(value)
    },
    handleStatus (newValue) {
      this.internalValue = newValue
    },
  },
}
</script>

<style lang="scss" scoped>
  .b-select-btn {
    margin: -4px;
  }

  .select-btn-wrapper {
    width: 100%;
    padding: 4px;
  }
</style>

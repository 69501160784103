<template>
  <BContent
    class="content"
  >
    <template #navbar>
      <div>
        <span class="page-title fs-400 fw-bold mr-400">{{ $t("todoSync.title") }}</span>
        <br>
        <span class="notice">設定を有効にすると、BALES CLOUD上のTODOデータをSalesforceに同期することができます。</span>
      </div>
    </template>
    <div class="tbody">
      <div
        v-for="(use, todoSync) in todoSyncSettingMap"
        :key="todoSync"
      >
        <div class="row">
          {{ $t(`todoSync.mapping.${todoSync}`) }}
          <BSwitch
            :status="use"
            :before-change="async () => await beforeChange(todoSync, use)"
            color="accent"
          />
        </div>
      </div>
    </div>
  </BContent>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { SfTodoSyncSettingsService } from '@/api/user/resources/salesforce/sf_todo_sync_settings';

const todoSyncSettingMap = ref({
  'bulk_mail': true,
  'call_result': true,
  'direct_mail': true,
  'receive_mail': true,
  'free_action': true,
});

const sfApi = new SfTodoSyncSettingsService();
const beforeChange = async (todoType, active) => {
  await sfApi.upsertSfTodoSyncSetting({
    sfTodoSyncSettingBody: {
      todoType,
      active: !active,
    },
  });
  await load();
}
const load = async () => {
  const results = await sfApi.getSfTodoSyncSettings();
  if (results.data == null) {
    return;
  }
  for (const data of results.data) {
    for (const key in todoSyncSettingMap.value) {
      const todoSyncSetting = data;
      if (key === todoSyncSetting.todoType) {
        todoSyncSettingMap.value[key] = todoSyncSetting.active;
      }
    }
  }
}
onMounted(async () => {
  await load();
});
</script>

<style lang="scss" scoped>
.notice {
  color: $textcolor-light;
}
.tbody {
  margin: 32px;
  width: 500px;
}
.row {
  display: flex;
  margin: 24px 0;
  justify-content: space-between;
  justify-items: center;
}
</style>

<template>
  <input
    :value="modelValue"
    :placeholder="placeholder"
    class="number-input"
    :disabled="disabled"
    type="text"
    inputmode="numeric"
    pattern="\d*"
    @keypress="isNumber($event)"
    @keypress.enter="keyPressEnter"
    @input="onInput"
    @blur="handleBlur"
  >
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    allowFloat: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
    'keypress-enter',
    'blur',
  ],
  computed: {
    innerValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      },
    },
  },
  methods: {
    insertTextAtCursorPosition(newText, el) {
      const start = el.selectionStart
      const end = el.selectionEnd
      const text = el.value
      const before = text.substring(0, start)
      const after = text.substring(end, text.length)
      return before + newText + after
    },
    isNumber (evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      const value = this.insertTextAtCursorPosition(evt.key, evt.target)
      const r = this.allowFloat ? /(?!^)-|[^-\.\d]|[.](?!\d*$)/g : /(?!^)-|[^-\d]/g
      if (!value.match(r)) return true
      evt.preventDefault()
      return false
    },
    onInput (e) {
      this.$emit('update:modelValue', e.target.value)
    },
    keyPressEnter () {
      this.$emit('keypress-enter')
    },
    handleBlur() {
      this.$emit('blur')
    },
  },
}
</script>

<style lang="scss" scoped>
  .number-input {
    border-radius: 4px;
    border: 1px solid $concrete;
    padding-left: 4px;
    transition: all 0.3s ease;

    &:focus {
      outline: 0;
      border: 1px solid $bdcolor-active;
    }
  }
</style>

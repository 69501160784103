import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { stringifyQuery, useRoute, useRouter } from 'vue-router';
import { bitterAlert } from '@/plugins/BBitterAlert';

/**
 * @description: urlからパラメータを除去したい場合は、返却されたparam.valueにundefinedを代入してください
 */
const useQueryParameter = <T extends string | string[]>(key: string) => {
  const router = useRouter();
  const route = useRoute();

  watch(() => route.query, () => {
    innerParam.value = route.query[key];
  });

  const innerParam = ref<string | string[]>(route.query[key]);
  const param = computed({
    get: (): T => {
      return innerParam.value as T;
    },
    set: (value: T) => {
      innerParam.value = value;
      let queryString = stringifyQuery({ ...route.query, [key]: value })
      queryString = queryString ? `?${queryString}` : '';
      router.options.history.push(`${route.path}${queryString}`);
    },
  });

  const getAndValidateParamAsInteger = (errorMessage?: string, errorTitle?: string): number | undefined => {
    const i18n = useI18n();

    if (!(key in route.query)) {
      return;
    }
    const queryValue = Number(route.query[key]);
    if (!queryValue || isNaN(queryValue) || !Number.isInteger(queryValue)) {
      bitterAlert.show({
        title: errorTitle || i18n.t('general.error'),
        text: errorMessage || i18n.t('general.error'),
        buttonsCancel: false,
      });
      return;
    }
    return queryValue;
  };

  return {
    param,
    getAndValidateParamAsInteger,
  };
};

export {
  useQueryParameter,
}

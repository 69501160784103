import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "setting-title" }
const _hoisted_2 = { class: "explain" }

import { onBeforeMount } from 'vue';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import GoogleApi from './userEmail/GoogleApi.vue';
import MailSignature from './userEmail/MailSignature.vue';
import SenderEmail from './userEmail/SenderEmail.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserEmail',
  setup(__props) {

const { doActionWithWait } = useWait();
const { currentUser, fetchCurrentUser } = useCurrentUser();
onBeforeMount(async () => {
  await fetch();
});

const fetch = () => {
  doActionWithWait('fetchCurrentUser', async () => {
    await fetchCurrentUser();
  });
};

return (_ctx: any,_cache: any) => {
  const _component_BContentBox = _resolveComponent("BContentBox")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_BContentBox, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('userEmail.apiConnections.title')), 1),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('userEmail.apiConnections.explain')), 1),
      _createVNode(GoogleApi, {
        user: _unref(currentUser),
        "onUpdate:user": fetch
      }, null, 8, ["user"]),
      _createVNode(SenderEmail, {
        user: _unref(currentUser),
        "onUpdate:user": fetch
      }, null, 8, ["user"]),
      _createVNode(MailSignature)
    ]),
    _: 1
  })), [
    [_directive_loading, _ctx.$wait.is('fetchCurrentUser')]
  ])
}
}

})
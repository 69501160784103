<template>
  <LeadHistoryCard v-bind="attributes">
    <template #date>
      <div>{{ historiedAt }}</div>
    </template>
    <BLeadStagePreview
      :lead-stage="displayLeadStage"
      :truncate-text="false"
    />
  </LeadHistoryCard>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import LeadHistoryCard from '@/components/organisms/user/lead/history/LeadHistoryCard.vue';
import { i18nGlobal as i18n } from '@/i18n';
import BLeadStagePreview, { TDisplayLeadStage } from '@/plugins/biscuet-materials/components/molecules/BLeadStagePreview.vue';
import { LeadStageHistory } from '@/store/types';
import { formatShorterDateTime } from '@/utils/date-time';

type TProps = {
  leadStageHistory: LeadStageHistory;
  expand?: boolean;
};

const props = withDefaults(defineProps<TProps>(), {
  expand: true,
});

const attributes = computed(() => ({
  text: i18n.t('leadStage.title') + i18n.t('leadStage.update'),
  user: props.leadStageHistory.user,
  iconComponent: 'BCustomIcon',
  customIconClass: 'b-stage-cycle',
  iconType: 'hot',
  toggle: true,
  expand: props.expand,
  isDisplaySystemUser: true,
}))
const historiedAt = computed<string>(() => formatShorterDateTime(props.leadStageHistory.historiedAt))
const displayLeadStage = computed<TDisplayLeadStage>(() => ({
  name: props.leadStageHistory.name,
  colorName: props.leadStageHistory.colorName,
}));
</script>

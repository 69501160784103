import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ZoomPhoneCallButton = _resolveComponent("ZoomPhoneCallButton")!
  const _component_MiitelCallButton = _resolveComponent("MiitelCallButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.activeCti === 'zoom')
      ? (_openBlock(), _createBlock(_component_ZoomPhoneCallButton, {
          key: 0,
          "phone-number": _ctx.phoneNumber
        }, null, 8, ["phone-number"]))
      : _createCommentVNode("", true),
    (_ctx.activeCti === 'miitel')
      ? (_openBlock(), _createBlock(_component_MiitelCallButton, {
          key: 1,
          "phone-number": _ctx.phoneNumber
        }, null, 8, ["phone-number"]))
      : _createCommentVNode("", true)
  ], 64))
}
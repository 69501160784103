import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, unref as _unref, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "theader" }
const _hoisted_2 = { class: "table-row theader-row" }
const _hoisted_3 = {
  key: 0,
  class: "tbody"
}
const _hoisted_4 = { class: "table-row tbody-row" }
const _hoisted_5 = { class: "table-cell" }
const _hoisted_6 = { class: "table-cell" }
const _hoisted_7 = { class: "table-cell" }
const _hoisted_8 = { class: "table-cell" }
const _hoisted_9 = { class: "table-cell" }
const _hoisted_10 = { class: "table-cell" }

import { useI18n } from 'vue-i18n';
import { useSequenceMaster } from '@/composable/user/sequence/sequence-master';

type TProps = {
  sequenceMasterId: number;
};
type TEmits = {
  (event: 'click:applyToLeadButton'): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InstancesSummary',
  props: {
    sequenceMasterId: {}
  },
  emits: ["click:applyToLeadButton"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const i18n = useI18n();
const {
  sequenceMaster,
  fetchSequenceMaster,
} = useSequenceMaster(props.sequenceMasterId);

const headers = [
  { key: 'all', name: i18n.t('sequence.summary.all') },
  { key: 'registered', name: i18n.t('sequence.summary.registered') },
  { key: 'stopped', name: i18n.t('sequence.summary.stopped') },
  { key: 'done', name: i18n.t('sequence.summary.done') },
  { key: 'associatedOpportunity', name: i18n.t('sequence.summary.associatedOpportunity') },
  { key: 'unregistered', name: i18n.t('sequence.summary.unregistered') },
];

fetchSequenceMaster('fetchSummary');

const handleApplyLeadButtonClick = () => {
  emit('click:applyToLeadButton');
};

return (_ctx: any,_cache: any) => {
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_VWait = _resolveComponent("VWait")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(headers, (header) => {
          return _createElementVNode("div", {
            key: header.key,
            class: "table-cell header-cell"
          }, _toDisplayString(header.name), 1)
        }), 64))
      ])
    ]),
    _createVNode(_component_VWait, { for: "fetchSummary" }, {
      waiting: _withCtx(() => [
        _withDirectives(_createVNode(_component_BPlaceholder, { "element-loading-background": "transparent" }, null, 512), [
          [_directive_loading, true]
        ])
      ]),
      default: _withCtx(() => [
        (_unref(sequenceMaster))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(sequenceMaster).numberOfInstances), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(sequenceMaster).numberOfRegistered), 1),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(sequenceMaster).numberOfStopped), 1),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(sequenceMaster).numberOfDone), 1),
                _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(sequenceMaster).numberOfOpportunities), 1),
                _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(sequenceMaster).numberOfUnregistered), 1),
                _createVNode(_component_BBtn, {
                  class: "apply-to-lead-button",
                  type: "primary",
                  size: "small",
                  onClick: handleApplyLeadButtonClick
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('sequence.applyToLead')), 1)
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}
}

})
import ApiBase from '@/api/base'

export default {
  unsubscribe (args) {
    return ApiBase.unauthorizedPost(`/unsubscribes`, args)
  },
  checkUnsubscribe (args) {
    return ApiBase.unauthorizedGet(`/check_unsubscribe`, args)
  },
}

import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "confirm-drawer" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import MailDrawerTemplate from '@/components/organisms/user/mail/common/drawer/MailDrawerTemplate.vue';
import ConfirmDrawerBodyCommon from '@/components/organisms/user/mail/direct/drawer/state/confirm/ConfirmDrawerBodyCommon.vue';
import { TSubmitType } from '@/composable/user/sequence/step-actions';
import { formatDateTime } from '@/utils/date-time';
import { SenderData } from '../../../mail/direct/drawer/state/types';
import { TFormDirectMailSequenceStep } from '../types';

type TProps = {
  step: TFormDirectMailSequenceStep;
  isLoading: boolean;
  editable: boolean;
  senderData: SenderData | null;
  submitType: TSubmitType;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfirmDrawer',
  props: {
    step: {},
    isLoading: { type: Boolean },
    editable: { type: Boolean },
    senderData: {},
    submitType: {}
  },
  emits: [
  'edit', 'saveAsDraft', 'saveAsReserve', 'reserve', 'close', 'open', 'sendImmediately',
],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const i18n = useI18n();

const doneAt = computed<string>(() => formatDateTime(props.step.doneAt));
const displayDirectMail = computed(() => ({
  ...props.step,
  notificationSetting: {
    open: props.step.open,
    click: props.step.click,
  },
}));

const draftErrorMessage = computed(() => props.step.immediately ? i18n.t('mail.saveAs.draftErrorMessage') : '');

const handleSaveAsDraftClick = () => emit('saveAsDraft');
const handleSaveAsReserveClick = () => emit('saveAsReserve');
const handleReserveClick = () => emit('reserve');
const handleSendImmediatelyClick = () => emit('sendImmediately');

const handleBackToEdit = () => emit('edit');
const handleClose = () => emit('close');

return (_ctx: any,_cache: any) => {
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? _withDirectives((_openBlock(), _createBlock(_component_BPlaceholder, {
          key: 0,
          height: "50vh",
          "element-loading-background": "transparent"
        }, null, 512)), [
          [_directive_loading, true]
        ])
      : (_openBlock(), _createBlock(MailDrawerTemplate, {
          key: 1,
          title: _ctx.$t('mail.confirmContent'),
          width: 600
        }, {
          drawerHeaderAction: _withCtx(() => [
            (_ctx.editable)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.submitType === 'send')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createVNode(_component_BBtn, {
                          size: "small",
                          type: "primary",
                          class: "mr-100",
                          outline: "",
                          loading: _ctx.isLoading,
                          onClick: handleSendImmediatelyClick
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.sendImmediatelyButton')), 1)
                          ]),
                          _: 1
                        }, 8, ["loading"]),
                        _createVNode(_component_BBtn, {
                          size: "small",
                          type: "primary",
                          outline: "",
                          loading: _ctx.isLoading,
                          onClick: handleReserveClick
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('mail.reserve')), 1),
                            _createVNode(_component_BIcon, null, {
                              default: _withCtx(() => _cache[0] || (_cache[0] = [
                                _createTextVNode("keyboard_arrow_right")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["loading"])
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createVNode(_component_BTooltip, {
                          top: "",
                          content: draftErrorMessage.value,
                          disabled: draftErrorMessage.value === ''
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BBtn, {
                              size: "small",
                              type: "primary",
                              class: "mr-100",
                              outline: "",
                              loading: _ctx.isLoading,
                              disabled: draftErrorMessage.value !== '',
                              onClick: handleSaveAsDraftClick
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t('mail.saveAs.draft')), 1)
                              ]),
                              _: 1
                            }, 8, ["loading", "disabled"])
                          ]),
                          _: 1
                        }, 8, ["content", "disabled"]),
                        _createVNode(_component_BBtn, {
                          size: "small",
                          type: "primary",
                          outline: "",
                          loading: _ctx.isLoading,
                          onClick: handleSaveAsReserveClick
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('mail.saveAs.reserve')), 1)
                          ]),
                          _: 1
                        }, 8, ["loading"])
                      ], 64))
                ], 64))
              : _createCommentVNode("", true)
          ]),
          drawerBody: _withCtx(() => [
            _createVNode(ConfirmDrawerBodyCommon, {
              "direct-mail": displayDirectMail.value,
              "sender-data": _ctx.senderData,
              "is-available-salesforce-api-feature": false
            }, _createSlots({
              default: _withCtx(() => [
                (doneAt.value)
                  ? (_openBlock(), _createBlock(_component_BListItem, {
                      key: 0,
                      class: "list-item mb-600"
                    }, {
                      header: _withCtx(() => [
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t('nextAction.doneAt')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(doneAt.value), 1)
                      ]),
                      _: 1
                    }))
                  : (_ctx.step.immediately)
                    ? (_openBlock(), _createBlock(_component_BListItem, {
                        key: 1,
                        class: "list-item mb-600"
                      }, {
                        header: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('sequence.step.dateInterval')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.sendImmediately')), 1)
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _createVNode(_component_BListItem, { class: "list-item mb-300" }, {
                          header: _withCtx(() => [
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('sequence.step.dateInterval')), 1)
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(_ctx.step.dateInterval) + "日", 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_BListItem, { class: "list-item mb-600" }, {
                          header: _withCtx(() => [
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('sequence.step.reservedTime')), 1)
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(_ctx.step.reservedTime), 1)
                          ]),
                          _: 1
                        })
                      ], 64))
              ]),
              _: 2
            }, [
              (_ctx.step.sendToName)
                ? {
                    name: "mailTo",
                    fn: _withCtx(() => [
                      _createVNode(_component_BListItem, { class: "list-item mb-600" }, {
                        header: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.$t('directMail.sendTo')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(`${_ctx.step.sendToName} <${_ctx.step.sendToAddress}>`), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["direct-mail", "sender-data"])
          ]),
          drawerFooter: _withCtx(() => [
            _createVNode(_component_BLayout, { "justify-center": "" }, {
              default: _withCtx(() => [
                (_ctx.editable)
                  ? (_openBlock(), _createBlock(_component_BBtn, {
                      key: 0,
                      class: "mr-100",
                      onClick: handleBackToEdit
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BIcon, null, {
                          default: _withCtx(() => _cache[1] || (_cache[1] = [
                            _createTextVNode("keyboard_arrow_left")
                          ])),
                          _: 1
                        }),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('mail.backToEdit')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_BBtn, {
                  class: "mr-100",
                  flat: "",
                  onClick: handleClose
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('general.close.text')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["title"]))
  ]))
}
}

})

import { mapGetters } from 'vuex'
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';
import { useSfSync } from '@/composable/sf-feature';

const mixin = {
  setup() {
    const { isAvailableSalesforceApiFeature } = useAvailableFeatureCheck()
    return { isAvailableSalesforceApiFeature }
  },
  computed: {
    ...mapGetters('user', [
      'callTarget',
    ]),
    sfSync() {
      return useSfSync(() => this.callTarget);
    },
    disableConnectToolChipText() {
      if (!this.isLeadSfSync) {
        return this.$t('sfActionTask.checkbox.toolChip.disabledText.notSynced')
      }
      if (this.isEdit) {
        return this.$t('sfActionTask.checkbox.toolChip.disabledText.isEdit')
      }
      return null
    },
    isLeadSfSync() {
      return this.sfSync.isLeadSfSync.value;
    },
    enabledInputSfSync() {
      return this.isAvailableSalesforceApiFeature && this.isLeadSfSync;
    },
    disableConnectCheckbox() {
      // NOTE: checkedIsSfSyncはprops
      return this.isEdit || !this.enabledInputSfSync;
    },
    isSfTaskSync() {
      // NOTE: checkedIsSfSyncはdata
      return !this.disableConnectCheckbox && this.checkedIsSfSync;
    },
  },
}

export default mixin

<template>
  <FilterDetailPopover
    v-model:is-include="isInclude"
    :title="title"
    is-switch
    :is-display-only="isDisplayOnly"
    :key-name="keyName"
    @hide="popoverHide"
    @show="popoverShow"
  >
    <template #reference>
      <BLayout
        align-center
      >
        <BListItem>
          <template #header>
            <BLayout
              justify-space-between
            >
              <div>{{ title }}</div>
              <div
                class="grouping-text"
                :class="{ 'is-include': incExc === 'inc' }"
              >
                {{ groupingText }}
              </div>
            </BLayout>
          </template>
          <div>{{ value }}</div>
        </BListItem>
      </BLayout>
    </template>
    <div>
      <FilterItemCheckbox
        v-model:checked-item="checkedItem"
        :candidate-items="candidateItems"
        :no-blank-item="true"
      />
    </div>
  </FilterDetailPopover>
</template>

<script>
import lodash from 'lodash'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import FilterDetailPopover from '@/components/organisms/user/leadList/filter/FilterDetailPopover.vue'
import FilterItemCheckbox from '@/components/organisms/user/leadList/filter/FilterItemCheckbox.vue'
import { getIncExc } from '@/utils/object'

export default {
  components: {
    FilterDetailPopover,
    FilterItemCheckbox,
  },
  props: {
    leadListFilterParams: {
      type: Object,
      default () {
        return {}
      },
    },
    isDisplayOnly: {
      type: Boolean,
    },
    keyName: {
      type: String,
      require: true,
    },
  },
  emits: [
    'hide',
  ],
  data () {
    return {
      checkedItem: [],
      isInclude: true,
    }
  },
  computed: {
    ...mapGetters('user', [
      'nextActionTypes',
      'defaultNextActionTypes',
      'defaultNextActionTypesSelectableByUser',
      'defaultNextActionTypesNotSelectableByUser',
    ]),
    candidateItems () {
      // TODO: src/api/user/models/next_action_types.tsに実装を統一する
      const defaultNextActionTypes = this.defaultNextActionTypesSelectableByUser.map(candidate => {
        return { label: candidate.name, value: `default${candidate.id}` }
      })
      const userSelectionActionTypes = defaultNextActionTypes.concat(this.nextActionTypes.map(candidate => {
        return { label: candidate.name, value: String(candidate.id) }
      }))
      const notUserSelectionActionTypes = this.defaultNextActionTypesNotSelectableByUser.map(candidate => {
        return { label: candidate.name, value: `default${candidate.id}` }
      })
      return [
        {
          name: 'selectable-by-user',
          items: userSelectionActionTypes,
        },
        {
          name: 'not-selectable-by-user',
          items: notUserSelectionActionTypes,
        },
      ]
    },
    title () {
      return this.$t(`nextAction.actionType.title`)
    },
    value () {
      if (this.target == null || this.targetDetail.length === 0) return '-'
      const values = this.targetDetail.map((v) => {
        if (v === '-1') {
          return this.$t('nextAction.blank')
        } else {
          const isDefault = String(v).includes('default')
          const id = isDefault ? v.replace('default', '') : v
          let name = this.$t('general.delete.done')
          if (isDefault) {
            const type = this.defaultNextActionTypes.find(t => t.id === parseInt(id))
            if (type) name = type.name
          } else {
            const type = this.nextActionTypes.find(t => t.id === parseInt(id))
            if (type) name = type.name
          }
          return name
        }
      })

      return values.join(' / ')
    },
    groupingText () {
      if (this.target == null || this.targetDetail.length === 0) return
      return this.$t(`general.filter.${this.incExc}`)
    },
    target () {
      return this.leadListFilterParams[this.keyName]
    },
    targetDetail () {
      if (this.target == null) return []
      return this.target[this.incExc].or
    },
    incExc () {
      return getIncExc(this.target)
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      getNextActionTypesAction: 'getNextActionTypesWait',
    }),
    popoverShow () {
      this.getNextActionTypesAction()
      this.checkedItem = lodash.cloneDeep(this.targetDetail)
      this.isInclude = this.incExc === 'inc'
    },
    popoverHide () {
      const grouping = this.isInclude ? 'inc' : 'exc'
      this.$emit('hide', { [grouping]: { or: this.checkedItem } })
    },
  },
}
</script>

<style lang="scss" scoped>
  .grouping-text {
    color: $basecolor-cold;

    &.is-include {
      color: $basecolor-hot;
    }
  }
</style>

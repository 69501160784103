import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, ref, watch } from 'vue';

type TProps = {
  active?: boolean;
  duration?: number;
  tag?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BSlideUpDown',
  props: {
    active: { type: Boolean, default: false },
    duration: { default: 500 },
    tag: { default: 'div' }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "0788f7d6": (styleDuration.value),
  "9e7b5cc8": (maxHeight.value)
}))

const props = __props;

const el = ref<HTMLElement | null>(null);
const maxHeight = ref<string | null>(props.active ? null : '0px');
const styleDuration = computed(() => `${props.duration}ms`);

watch(() => props.active, (active) => {
  toggle(active);
});

const toggle = (active: boolean) => {
  // NOTE: scrollHeightは動的に変わるため、直前のこのタイミングで取得している
  const beforeHeight = active ? 0 : el.value?.scrollHeight;
  const afterHeight = active ? el.value?.scrollHeight : 0;
  maxHeight.value = `${beforeHeight}px`;
  // NOTE: 上記css適用を待つ
  setTimeout(() => {
    maxHeight.value = `${afterHeight}px`;
  }, 50);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    ref_key: "el",
    ref: el,
    class: _normalizeClass(["b-slide-up-down", { active: _ctx.active }])
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class"]))
}
}

})
<template>
  <div class="sidebar">
    <BHeightAdjuster>
      <div class="sidebar-menu">
        <RouterLink
          v-for="item in menuItems"
          :key="item.routeTo"
          class="list-item"
          :to="{ name: item.routeTo }"
          :data-test="item.dataAttr.test"
        >
          <BTooltip
            right
            :content="item.label"
          >
            <BIcon
              v-if="isMenuItemWithIcon(item)"
              class="mr-400"
              :type="setCurrentRouteIconClass(item.routeTo)"
            >
              <span :class="item.iconClass">{{ item.icon }}</span>
            </BIcon>
            <BCustomIcon
              v-if="isMenuItemWithCustomIcon(item)"
              class="mr-400"
              :icon-class="item.customIcon"
              :type="setCurrentRouteIconClass(item.routeTo)"
            />
          </BTooltip>
        </RouterLink>
        <a
          :href="helpPageUrl"
          target="_blank"
          rel="noopener noreferrer"
          class="list-item"
        >
          <BTooltip
            right
            :content="$t('helpPage.link')"
          >
            <BIcon
              type="default"
              class="mr-400"
            >
              help_outline
            </BIcon>
          </BTooltip>
        </a>
      </div>
    </BHeightAdjuster>
  </div>
</template>

<script lang="ts">
import lodash from 'lodash'
import { defineComponent } from 'vue'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import UserService from '@/services/user_service'

interface BaseMenuItem {
  label: string,
  routeTo: string,
  dataAttr: {
    test: string
  }
}
interface MenuItemWithIcon extends BaseMenuItem {
  icon: string,
  iconClass?: string
}
function isMenuItemWithIcon(arg: Partial<MenuItemWithIcon>): arg is MenuItemWithIcon {
  if (arg.routeTo === undefined) return false
  if (arg.dataAttr?.test === undefined) return false
  if (arg.icon === undefined) return false
  return true
}
interface MenuItemWithCustomIcon extends BaseMenuItem {
  customIcon: string
}
function isMenuItemWithCustomIcon(arg: Partial<MenuItemWithCustomIcon>): arg is MenuItemWithCustomIcon {
  if (arg.routeTo === undefined) return false
  if (arg.dataAttr?.test === undefined) return false
  if (arg.customIcon === undefined) return false
  return true
}
type MenuItem = MenuItemWithIcon | MenuItemWithCustomIcon

export default defineComponent({
  name: 'Sidebar',
  setup() {
    const { isAvailableBulkMailFeature, isAvailableMailTemplateFeature, isAvailableAnalyticsLinkFeature, isAvailableSequenceFeature, isAvailableTriggerFeature, isAvailableBetaPage } = useAvailableFeatureCheck()
    return {
      isAvailableBulkMailFeature,
      isAvailableMailTemplateFeature,
      isAvailableAnalyticsLinkFeature,
      isAvailableSequenceFeature,
      isAvailableTriggerFeature,
      isAvailableBetaPage,
    }
  },
  computed: {
    ...mapGetters('userUi', [
      'leadListFilterParams',
      'leadListPageParams',
      'filterControlPanelVisible',
    ]),
    menuItems(): MenuItem[] {
      const items:MenuItem[] = []
      items.push({ label: this.$t('home.title'), customIcon: 'b-home', routeTo: 'Home', dataAttr: { test: '' } })
      items.push({ label: this.$t('leadList.title'), icon: 'list', routeTo: 'LeadList', dataAttr: { test: '' } })
      if (this.isAvailableBulkMailFeature) items.push({ label: this.$t('mail.title'), customIcon: 'b-mail', routeTo: 'Mail', dataAttr: { test: 'Mail' } })
      if (this.isAvailableMailTemplateFeature) items.push({ label: this.$t('mailTemplate.title'), customIcon: 'b-template', routeTo: 'MailTemplate', dataAttr: { test: 'MailTemplate' } })
      items.push({ label: this.$t('callAnalytics.title'), customIcon: 'b-call-analytics', routeTo: 'CallAnalytics', dataAttr: { test: '' } })
      if (this.isAvailableAnalyticsLinkFeature) items.push({ label: this.$t('analytics.title'), customIcon: 'b-graph-bar-modern', routeTo: 'Analytics', dataAttr: { test: '' } })
      if (this.isAvailableSequenceFeature) items.push({ label: this.$t('sequence.title'), customIcon: 'b-sequence', routeTo: 'Sequences', dataAttr: { test: '' } })
      if (this.isAvailableTriggerFeature) items.push({ label: this.$t('trigger.title'), icon: 'bolt', iconClass: 'material-symbols-outlined', routeTo: 'Triggers', dataAttr: { test: '' } })
      if (this.isAvailableBetaPage) items.push({ label: this.$t('beta.title'), icon: 'engineering', routeTo: 'Beta', dataAttr: { test: '' } })
      items.push({ label: this.$t('corpSetting.title'), customIcon: 'b-setting', routeTo: 'Setting', dataAttr: { test: '' } })
      return items
    },
    helpPageUrl() {
      return process.env.VUE_APP_HELP_URL
    },
  },
  created() {
    this.userService = new UserService()
    this.getCurrentUserAction()
    this.getSalesforceSettingAction()
    this.getCustomFieldsAction()
    this.getNextActionTypesAction()
    this.getCallResultTypesAction()
  },
  beforeUnmount() {
    this.$clearInterval(this.timerObj)
  },
  methods: {
    ...mapWaitingActions('user', {
      getCurrentUserAction: 'getCurrentUserWait',
      getSalesforceSettingAction: 'getSalesforceSettingWait',
      getCustomFieldsAction: 'getCustomFieldsWait',
      getCallTargetsAction: 'getCallTargetsWait',
      getNextActionTypesAction: 'getNextActionTypesWait',
      getCallResultTypesAction: 'getCallResultTypesWait',
    }),
    ...mapWaitingActions('userUi', {
      setLeadListFilterParamsAction: 'setLeadListFilterParamsWait',
      setFilterControlPanelVisibleAction: 'setFilterControlPanelVisibleWait',
      setLeadListPageParamsAction: 'setLeadListPageParamsWait',
    }),
    resetAtLeadList() {
      // すでにリードリスト画面にいる場合はかかっているフィルタをオフにしてリードリストを再取得する
      if (this.$route.name === 'LeadList') {
        this.setLeadListFilterParamsAction({})
        this.setLeadListPageParamsAction(
          Object.assign(lodash.cloneDeep(this.leadListPageParams), { currentPage: 1 }),
        )
        this.setFilterControlPanelVisibleAction(false)
        this.getCallTargetsAction()
      }
    },
    isCurrentRoute(routeName): boolean {
      return this.$route.name == routeName
    },
    setCurrentRouteIconClass(routeName): string {
      return this.isCurrentRoute(routeName) ? 'primary' : 'default'
    },
    setCurrentRouteTextClass(routeName): string {
      return this.isCurrentRoute(routeName) ? 'current-route-text ' : ''
    },
    isMenuItemWithIcon(arg: Partial<MenuItemWithIcon>): arg is MenuItemWithIcon {
      return isMenuItemWithIcon(arg)
    },
    isMenuItemWithCustomIcon(arg: Partial<MenuItemWithCustomIcon>): arg is MenuItemWithCustomIcon {
      return isMenuItemWithCustomIcon(arg)
    },
  },
})
</script>

<style lang="scss" scoped>
.sidebar {
  @include m-fixed-width($sidebar-width);
  @include m-fixed-height($page-height);
  background-color: $bgcolor-white;
  margin-top: 50px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.06));
  z-index: 8;
}
.sidebar-menu {
  margin-top: 8px;
  .list-item {
    position: relative;
    display: flex;
    align-items: center;
    color: #000000;
    text-decoration: none;
    padding: $basespace-100 $basespace-400;
    margin-bottom: $basespace-50;
    transition: $transition-base;
    &:hover {
      background-color: $bgcolor-dark;
    }
    &.router-link-active {
      transition: none;
      background: linear-gradient(90deg, rgba(43, 192, 184, 0.5) -4%, rgba(43, 192, 184, 0) 100%);
    }
    &.router-link-active::before {
      content: "";
      display: inline-block;
      width: 3px;
      height: 100%;
      background-color: $basecolor-success;
      position: absolute;
      left: 0;
    }
    .material-symbols-outlined {
      font-size: 20px;
    }
  }
}
.current-route-text {
  color: $basecolor-primary;
  font-weight: 600;
}
</style>

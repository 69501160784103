<template>
  <BModal
    :modal-name="modalName"
    :width="width"
    :height="height"
  >
    <BHeightAdjuster>
      <BModalHeader
        :title="$t('mailTemplateModal.title')"
        @modal-close="hide"
      />
      <BModalBody class="mail-template-modal-body">
        <MailTemplateList
          :mail-templates="completedMailTemplates"
          :can-duplicate="false"
          @click="$emit('click', $event)"
        />
      </BModalBody>
    </BHeightAdjuster>
  </BModal>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import MailTemplateList from '@/components/organisms/user/mailTemplate/MailTemplateList.vue';

export default {
  components: {
    MailTemplateList,
  },
  props: {
    width: {
      type: String,
      default: '70%',
    },
    height: {
      type: String,
      default: '70%',
    },
  },
  emits: [
    'click',
  ],
  computed: {
    ...mapGetters('user', ['mailTemplates']),
    modalName() {
      return 'MailTemplateSelectModal';
    },
    completedMailTemplates(){
      return this.mailTemplates.filter((mt) => mt.status === 'complete')
    },
  },
  created() {
    this.getMailTemplatesAction();
  },
  methods: {
    ...mapWaitingActions('user', {
      getMailTemplatesAction: 'getMailTemplatesWait',
    }),
    hide() {
      this.$modal.hide(this.modalName);
    },
  },
};
</script>

<style scoped>
:deep(.mail-template-modal-body.b-modal-body) {
  padding: 0;
}
</style>

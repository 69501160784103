import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

type TProps = {
  content?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BHelp',
  props: {
    content: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BTooltip = _resolveComponent("BTooltip")!

  return (_openBlock(), _createBlock(_component_BTooltip, {
    placement: "top",
    width: "200",
    trigger: "hover",
    content: _ctx.content
  }, {
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_BIcon, {
        class: "ml-50",
        size: "small",
        type: "gray"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" help ")
        ])),
        _: 1
      })
    ]),
    _: 3
  }, 8, ["content"]))
}
}

})
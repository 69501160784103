import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "sub-title" }
const _hoisted_3 = { class: "confirm-lead-items" }
const _hoisted_4 = { class: "tbody" }
const _hoisted_5 = { class: "table-cell header-cell truncate-text" }
const _hoisted_6 = { class: "table-cell truncate-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BModalBody = _resolveComponent("BModalBody")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BModalFooter = _resolveComponent("BModalFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('duplicatedList.confirm.subTitle')), 1)
    ]),
    _createVNode(_component_BModalBody, { style: { padding: 0 } }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confirmLeadItems, ({groupKey, key, label, displayValue}) => {
              return (_openBlock(), _createElementBlock("div", {
                key: `${groupKey}.${key}`,
                class: "table-row tbody-row"
              }, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(label), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString(displayValue || '-'), 1)
              ]))
            }), 128))
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_BModalFooter, null, {
      default: _withCtx(() => [
        _createVNode(_component_BLayout, {
          class: "mt-200",
          "justify-center": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BBtn, {
              type: "primary",
              loading: _ctx.loading,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click-merge')))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('duplicatedList.confirm.doMerge')), 1)
              ]),
              _: 1
            }, 8, ["loading"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
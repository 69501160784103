import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { SequenceBody } from '@/api/openapi';
import { useSequenceMaster } from '@/composable/user/sequence/sequence-master';
import EditableSequence from '../general/sequence/EditableSequence.vue';

type TProps = {
  sequenceMasterId: number;
  editable?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'Sequence',
  props: {
    sequenceMasterId: {},
    editable: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props;

const {
  sequenceMaster,
  fetchSequenceMaster,
} = useSequenceMaster(props.sequenceMasterId);

fetchSequenceMaster('fetchSequenceMaster');

const sequenceBody = computed<SequenceBody>(() => ({
  name: sequenceMaster.value?.name ?? '',
}));

return (_ctx: any,_cache: any) => {
  const _component_VWait = _resolveComponent("VWait")!

  return (_openBlock(), _createBlock(_component_VWait, { for: "fetchSequenceMaster" }, {
    default: _withCtx(() => [
      _createVNode(EditableSequence, {
        "sequence-body": sequenceBody.value,
        "owner-name": _unref(sequenceMaster)?.owner?.name,
        "enabled-to-add-steps": false,
        editable: _ctx.editable
      }, null, 8, ["sequence-body", "owner-name", "editable"])
    ]),
    _: 1
  }))
}
}

})
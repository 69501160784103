
export const columns = [
  {
    key: 'companyName',
    width: '240px',
  },
  {
    key: 'fullName',
    width: '160px',
  },
  {
    key: 'registrationDate',
    width: '160px',
  },
  {
    key: 'status',
    width: '160px',
  },
  {
    key: 'isOpportunityAssociated',
    width: '120px',
  },
  {
    key: 'lastLeadStage',
    width: '160px',
  },
  {
    key: 'numberOfDoneSteps',
    width: '120px',
  },
  {
    key: 'numberOfCurrentStepStays',
    width: '120px',
  },
  {
    key: 'owner',
    width: '120px',
  },
]

import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import type { TabsPaneContext } from 'element-plus';
import { ref, watch } from 'vue';

type TElTabsType = '' | 'card' | 'border-card';

export type TLableItem = {
  label: string;
  slotName: string;
};

type TProps = {
  modelValue?: string;
  position?: string;
  type?: TElTabsType;
  labelItems?: TLableItem[];
  beforeLeave?: (activeName: string) => void;
};
type TEmit = {
  (event: 'update:modelValue', activeName: string): void;
  (event: 'click:tab', tabName: string): void;
  (event: 'before:leave', tabName: string): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BTabs',
  props: {
    modelValue: { default: '' },
    position: { default: 'top' },
    type: { default: '' },
    labelItems: { default: () => [] },
    beforeLeave: { type: Function, default: () => true }
  },
  emits: ["update:modelValue", "click:tab", "before:leave"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const activeName = ref(props.modelValue || props.labelItems[0]?.slotName || '');
watch(activeName, (value) => {
  emit('update:modelValue', value);
});

const handleTabClick = (pane: TabsPaneContext) => {
  emit('click:tab', `${pane.paneName}`);
};

return (_ctx: any,_cache: any) => {
  const _component_ElTabPane = _resolveComponent("ElTabPane")!
  const _component_ElTabs = _resolveComponent("ElTabs")!

  return (_openBlock(), _createBlock(_component_ElTabs, {
    modelValue: activeName.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((activeName).value = $event)),
    "tab-position": _ctx.position,
    type: _ctx.type,
    "before-leave": _ctx.beforeLeave,
    onTabClick: handleTabClick
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labelItems, (labelItem, index) => {
        return (_openBlock(), _createBlock(_component_ElTabPane, {
          key: index,
          label: labelItem.label,
          name: labelItem.slotName
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, labelItem.slotName)
          ]),
          _: 2
        }, 1032, ["label", "name"]))
      }), 128))
    ]),
    _: 3
  }, 8, ["modelValue", "tab-position", "type", "before-leave"]))
}
}

})
<template>
  <div
    v-loading="$wait.is(['updateNextActionWait', 'getNextActionsWait', 'doneNextActionWait'])"
    class="draft-card"
  >
    <div
      v-for="directMail in draftItems"
      :key="`draft-${directMail.id}`"
      class="draft-list"
      @click="() => handleDirectMailConfirm(directMail)"
    >
      <DirectMailItem
        :direct-mail="directMail"
        @click:confirm="() => handleDirectMailConfirm(directMail)"
        @after-delete="handleDirectMailReload"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { DraftDirectMail } from '@/api/openapi/models/draft-direct-mail';
import { isRelatedToSequence } from '@/composable/user/directMail/direct-mail';
import DirectMailItem from './general/DirectMail.vue';

// NOTE: directMailしか含まれていない前提の構造なので、objectの種類が増えた時は修正する
type TProps = {
  draftItems: DraftDirectMail[];
};
type TEmits = {
  'click:sequenceAction': [sequenceInstanceId: number];
  'click:directMailConfirm': [directMail: DraftDirectMail];
  'afterDeleteDirectMail': [];
};

defineProps<TProps>();

const emit = defineEmits<TEmits>()

const handleDirectMailConfirm = (directMail: DraftDirectMail) => {
  if (isRelatedToSequence(directMail)) {
    emit('click:sequenceAction', directMail.sequenceInstanceId)
  } else {
    emit('click:directMailConfirm', directMail)
  }
};
const handleDirectMailReload = () => { emit('afterDeleteDirectMail') };
</script>

<style lang="scss" scoped>
.draft-card {
  background-color: $bgcolor-white;
  padding: $basespace-100 0;
}

.draft-list {
  transition: all 300ms $standard-easing;
  padding: $basespace-100 $basespace-600;

  &:hover {
    background: $bgcolor-base;
    cursor: pointer;
  }
}
</style>


import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "mt-600" }

import { onBeforeMount, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { UserSenderEmailApiService } from '@/api/user/resources/user_sender_email';
import { useWait } from '@/composable/vue-wait';
import { i18nGlobal as i18n } from '@/i18n';
import { bitterAlert } from '@/plugins/BBitterAlert';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserSenderEmailConfirm',
  setup(__props) {

const success = ref<boolean>(true);
const route = useRoute();
const router = useRouter();
const {
  wait,
  doActionWithWait,
} = useWait();

onBeforeMount(async () => {
  const confirmationToken = route.params.token as string;
  confirmUserSenderEmail(confirmationToken);
});

const settingLoading = computed(() => wait.is(['confirmUserSenderEmailWait']));

const confirmUserSenderEmail = async (confirmationToken: string) => {
  const api = new UserSenderEmailApiService();
  doActionWithWait('confirmUserSenderEmailWait', async () => {
    await api.confirmUserSenderEmail({
      request: {
        userSenderEmailConfirmBody: {
          confirmationToken,
        },
      },
      errorHandlers: {
        404: errorHandler,
        422: errorHandler,
      },
    });
  });
};

const errorHandler = () => {
  success.value = false;
  bitterAlert.show({
    title: i18n.t('changeEmail.confirmFailed.title'),
    text: i18n.t('changeEmail.confirmFailed.message', { itemName: i18n.t('senderEmail.title') }),
    buttonsCancel: false,
  });
}

const toHome = () => {
  router.push({ name: 'Home' })
};

return (_ctx: any,_cache: any) => {
  const _component_BAlert = _resolveComponent("BAlert")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BFlex = _resolveComponent("BFlex")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_BFlex, { class: "user-sender-email-confirm" }, {
    default: _withCtx(() => [
      (!settingLoading.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (success.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_BAlert, {
                    text: _unref(i18n).t('general.done.withTarget', { action: _unref(i18n).t('general.update'), target: _unref(i18n).t('senderEmail.title') }),
                    class: "success",
                    type: "success",
                    "show-icon": ""
                  }, null, 8, ["text"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_BAlert, {
                    text: _unref(i18n).t('general.fail.withTarget', { action: _unref(i18n).t('general.update'), target: _unref(i18n).t('senderEmail.title') }),
                    class: "alert",
                    type: "error",
                    "show-icon": ""
                  }, null, 8, ["text"])
                ])),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_BBtn, {
                disabled: settingLoading.value,
                loading: settingLoading.value,
                onClick: toHome
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('senderEmail.toTopPage')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "loading"])
            ])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  })), [
    [_directive_loading, settingLoading.value]
  ])
}
}

})
// IMPORTANT: routerのimportでvue-componentのcssが読み込まれるので、前に読み込みたいcssはこれより上でimportする
import { i18n } from './i18n'
import bugsnagConfig from './lib/bugsnag'
import router from './router'
import store, { key } from './store'

import { initializeApp } from 'firebase/app'

// @ts-ignore
import Lottie from 'vue-lottie'
import { ApmVuePlugin } from '@elastic/apm-rum-vue'
import smartlookClient from 'smartlook-client'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import BBitterAlert from '@/plugins/BBitterAlert'
import BMaterials from '@/plugins/biscuet-materials'
import BModal from '@/plugins/BModal'
import BSetInterval from '@/plugins/BSetInterval'
import BStringify from '@/plugins/BStringify'
import BToast from '@/plugins/BToast'
import ElementPlus from '@/plugins/element'
import { createVueWait } from 'vue-wait'

import './lib/intercom'

import App from './App.vue'
import AuthService from '@/services/auth_service'
import { createApp } from 'vue';
import { KEY_OF_WAIT } from './injection-keys'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
}
initializeApp(firebaseConfig)

if (process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    ...bugsnagConfig,
    plugins: [new BugsnagPluginVue()],
  })
}
const bugsnagVue = Bugsnag.getPlugin('vue')

const app = createApp(App)
app.use(ElementPlus)
  .use(BToast)
  .use(BModal)
  .use(BSetInterval)
  .use(BStringify)
  .use(BBitterAlert)
  .use(BMaterials)
  .use(router)
  .use(store, key)
  .use(createVueWait({
    componentName: 'VWait',
  }))
  .use(i18n)
  .use(bugsnagVue)
  .component('Lottie', Lottie)

app.provide(KEY_OF_WAIT, app.config.globalProperties.$wait);

if (process.env.VUE_APP_ELASTIC_APM_SECRET_TOKEN) {
  app.use(ApmVuePlugin, {
    router,
    config: {
      distributedTracingOrigins: [process.env.VUE_APP_API_ENDPOINT],
      serverUrl: process.env.VUE_APP_ELASTIC_APM_SERVER_URL,
      secretToken: process.env.VUE_APP_ELASTIC_APM_SECRET_TOKEN,
      serviceName: 'SaaSClient',
      environment: process.env.NODE_ENV,
    },
  })
}

if (location.host.match(process.env.VUE_APP_SMARTLOOK_ENABLE_REG)) {
  smartlookClient.init(process.env.VUE_APP_SMARTLOOK_API_KEY);
}

export type Role = 'owner' | 'member' | 'admin_owner' | 'admin_member'
const authService = new AuthService()
app.config.globalProperties.$can = (role: Role) => {
  return authService.currentRole() === role
}
if (process.env.NODE_ENV !== 'production') {
  app.config.errorHandler = (err, _instance, info) => {
    // NOTE: エラーを捕まえて処理しておく
    console.error('error handle:', err, info);
  }
}

app.mount('#app')

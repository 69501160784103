<template>
  <BLayout
    class="set-operator"
    align-center
  >
    <BFlex :span="6">
      {{ $t('operatorAssign.assignedOperator') }}
    </BFlex>
    <BFlex :span="18">
      <BSelect
        v-model="selectedOperatorId"
        :items="filteredOptions"
        item-text="name"
        item-value="id"
        :placeholder="$t('operatorAssign.select')"
        :filterable="true"
        @change="changeOperator"
      />
    </BFlex>
  </BLayout>
</template>

<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { User } from '@/api/openapi';
import { KEY_OF_MODAL } from '@/injection-keys';

type TProps = {
  currentOperatorId: number;
  operatorUsers: User[];
  needsWithdrawOption: boolean;
}

type TEmit = {
  (e: 'assign-operator', operatorId: number): void
  (e: 'withdraw-operator'): void
}

const props = defineProps<TProps>()
const emit = defineEmits<TEmit>()
const i18n = useI18n();
const modal = inject(KEY_OF_MODAL);

const selectedOperatorId = ref<number>();

watch(() => props.currentOperatorId, (currentOperatorId) => {
  selectedOperatorId.value = currentOperatorId
})

const filteredOptions = computed(() => {
  if (props.needsWithdrawOption) {
    const addData = props.currentOperatorId ? [
      {
        name: i18n.t('operatorAssign.unassigned'),
        id: 0,
      },
    ] : []
    return addData.concat(props.operatorUsers)
  } else {
    return props.operatorUsers
  }
});

const changeOperator = () => {
  if (selectedOperatorId.value !== 0) {
    emit('assign-operator', selectedOperatorId.value)
  } else {
    emit('withdraw-operator')
  }

  modal.hide('setOperator')
}
</script>

<style lang="scss" scoped>
  .set-operator {
    padding: $basespace-600;
  }
</style>

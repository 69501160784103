<template>
  <BDrawerMenu
    v-model="drawer"
    v-loading="loading"
    width="600px"
    class="sequence-step-form"
    :title="sequenceInstance?.name"
    @cancel="handleCancel"
  >
    <template #headerAddition>
      <SequenceOwner
        :owner-name="sequenceInstance?.owner?.name"
        for-sequence-instance
      />
    </template>
    <SequenceSteps
      v-if="isVisible && !isDisplaySendingAnimation"
      :call-target="callTarget"
      :sequence-instance-id="sequenceInstance?.id"
      :owner-id="sequenceInstance?.owner?.id"
      :loading="loading"
      @after-update="$emit('afterUpdate')"
      @before-send="handleBeforeSend"
      @stop-sending="hideSendingAnimation"
    />
    <SendingDrawer
      v-if="isDisplaySendingAnimation"
      :width="600"
      @sending-animation-completed="hideSendingAnimation"
    />
  </BDrawerMenu>
</template>

<script lang="ts">
import lodash from 'lodash'
import { PropType, defineComponent } from 'vue'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import { CallTarget, SequenceInstance } from '@/api/openapi'
import SequenceOwner from '@/components/organisms/user/general/sequence/SequenceOwner.vue'
import SequenceSteps from '@/components/organisms/user/lead/SequenceSteps.vue'
import SendingDrawer from '@/components/organisms/user/mail/common/drawer/state/sending/SendingDrawer.vue';

type TData = {
  sequenceInstance: SequenceInstance;
  isDisplaySendingAnimation: boolean;
};

export default defineComponent({
  components: {
    SequenceSteps,
    SendingDrawer,
    SequenceOwner,
  },
  props: {
    callTarget: {
      type: Object as PropType<CallTarget>,
      required: false,
      default: null,
    },
  },
  emits: ['afterUpdate'],
  data(): TData {
    return {
      sequenceInstance: null,
      isDisplaySendingAnimation: false,
    }
  },
  computed: {
    ...mapGetters('userUi', [
      'targetSequenceInstanceFormVisible',
      'selectedSequenceInstanceId',
    ]),
    isVisible() {
      return !lodash.isEmpty(this.sequenceInstance) && this.targetSequenceInstanceFormVisible
    },
    drawer: {
      get () {
        return this.targetSequenceInstanceFormVisible
      },
      set (newVal) {
        if (this.targetSequenceInstanceFormVisible !== newVal) this.setTargetSequenceInstanceFormVisibleAction(newVal)
      },
    },
    loading() {
      return this.$wait.is(['fetchSequenceStepInstances', 'putSequenceStepInstance', 'skipSequenceStepInstance', 'executeSequenceStepInstance']);
    },
  },
  watch: {
    async selectedSequenceInstanceId (val) {
      if (val != null) {
        this.sequenceInstance = await this.getSequenceInstanceAction({ request: { sequenceInstanceId: val } })
      } else {
        this.sequenceInstance = null
      }
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      getSequenceInstanceAction: 'getSequenceInstanceWait',
    }),
    ...mapWaitingActions('userUi', {
      setSelectedSequenceInstanceIdAction: 'setSelectedSequenceInstanceIdWait',
      setTargetSequenceInstanceFormVisibleAction: 'setTargetSequenceInstanceFormVisibleWait',
    }),
    async handleCancel () {
      this.closeDrawer()
    },
    closeDrawer () {
      this.drawer = false
      this.setSelectedSequenceInstanceIdAction(null)
    },
    hideSendingAnimation() {
      this.isDisplaySendingAnimation = false;
    },
    handleBeforeSend() {
      this.isDisplaySendingAnimation = true;
    },
  },
})
</script>

<style lang="scss" scoped>
.sequence-step-form {
  &.b-drawer-menu {
    &.open {
      left: -600px !important;
    }
  }
  :deep(> .b-height-adjuster) {
    > .b-drawer-menu-body {
      padding: 0 !important;
    }
  }
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "time" }
const _hoisted_2 = { class: "send-to" }
const _hoisted_3 = { class: "company-name" }
const _hoisted_4 = { class: "send-to-name" }
const _hoisted_5 = { class: "subject" }

import { computed } from 'vue';
import { MailRemindItem } from '@/api/openapi';
import LeadLink from '@/components/organisms/user/general/LeadLink.vue';
import { i18nGlobal as i18n } from '@/i18n';
import { formatShortTime } from '@/utils/date-time';

type TProps = {
  item: MailRemindItem;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ReservedMailReminderItem',
  props: {
    item: {}
  },
  setup(__props: any) {

const props = __props;

const callTargetId = computed(() => {
  return props.item.callTargetId
})

const callBackReservedAt = computed(() => {
  return formatShortTime(props.item.reservedAt)
})

const sendToName = computed(() => props.item.fullName ? `${props.item.fullName}${i18n.t('reservedMailReminder.nameSuffix')}` : '')

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(LeadLink, {
    "call-target-id": callTargetId.value,
    tag: "div",
    class: "reminder-item"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(callBackReservedAt.value), 1),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item.companyName), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(sendToName.value), 1)
        ]),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.item.subject), 1)
      ])
    ]),
    _: 1
  }, 8, ["call-target-id"]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "status" }

type TProps = {
  status: string;
  statusName: string;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'BStatus',
  props: {
    status: {},
    statusName: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["badge", [ _ctx.status ]])
    }, null, 2),
    _createElementVNode("span", null, _toDisplayString(_ctx.statusName), 1)
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { SequenceStepMaster } from '@/api/openapi';
import { useSequenceStepMasters } from '@/composable/user/sequence/sequence-step-masters';
import EditableSequenceStepMasters from '../general/sequence/EditableSequenceStepMasters.vue';
import { TFormSequenceStep } from '../general/sequence/types';

type TProps = {
  sequenceMasterId: number;
  editable: boolean;
  sequenceOwnerId: number | undefined;
};
type TEmit = {
  (event: 'update', value: TFormSequenceStep[]): void;
  (e: 'update:changed', changed: boolean): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceSteps',
  props: {
    sequenceMasterId: {},
    editable: { type: Boolean },
    sequenceOwnerId: {}
  },
  emits: ["update", "update:changed"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const {
  sequenceStepMasters,
  fetchSequenceStepMasters,
} = useSequenceStepMasters(props.sequenceMasterId);
fetchSequenceStepMasters('fetchSequenceStepMasters');

const handleUpdate = (steps: TFormSequenceStep[]) => {
  emit('update', steps);
};
const handleUpdateChanged = (changed: boolean) => {
  emit('update:changed', changed);
};

return (_ctx: any,_cache: any) => {
  const _component_VWait = _resolveComponent("VWait")!

  return (_openBlock(), _createBlock(_component_VWait, {
    for: "fetchSequenceStepMasters",
    class: "sequence-steps-container"
  }, {
    default: _withCtx(() => [
      _createVNode(EditableSequenceStepMasters, {
        "sequence-step-masters": (_unref(sequenceStepMasters) as SequenceStepMaster[]),
        "sequence-owner-id": _ctx.sequenceOwnerId,
        onUpdate: handleUpdate,
        "onUpdate:changed": handleUpdateChanged
      }, null, 8, ["sequence-step-masters", "sequence-owner-id"])
    ]),
    _: 1
  }))
}
}

})
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ml-50 fw-bold" }
const _hoisted_2 = { class: "footer" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BInputDateTime = _resolveComponent("BInputDateTime")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_SequenceAssociationCheckBox = _resolveComponent("SequenceAssociationCheckBox")!
  const _component_SyncCheckbox = _resolveComponent("SyncCheckbox")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BDrawerMenu = _resolveComponent("BDrawerMenu")!
  const _component_SalesforceOpportunityModal = _resolveComponent("SalesforceOpportunityModal")!
  const _component_QuickTextSelectModal = _resolveComponent("QuickTextSelectModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BDrawerMenu, {
      modelValue: _ctx.drawer,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.drawer) = $event)),
      title: _ctx.$t('opportunity.title'),
      onCancel: _ctx.handleCancelCreateOpportunity
    }, {
      footer: _withCtx(() => [
        (_ctx.isAvailableSequenceFeature)
          ? (_openBlock(), _createBlock(_component_SequenceAssociationCheckBox, {
              key: 0,
              modelValue: _ctx.sequenceInstanceId,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.sequenceInstanceId) = $event)),
              "tooltip-text-when-readonly": _ctx.$t('opportunity.notEditSequenceAssociation'),
              readonly: _ctx.isEdit,
              "sequence-id": _ctx.activeSequenceId
            }, null, 8, ["modelValue", "tooltip-text-when-readonly", "readonly", "sequence-id"]))
          : _createCommentVNode("", true),
        (_ctx.isAvailableSalesforceApiFeature)
          ? (_openBlock(), _createBlock(_component_SyncCheckbox, {
              key: 1,
              "is-edit": _ctx.isEdit,
              "checked-is-sf-sync": _ctx.checkedIsSfSync,
              "on-click": _ctx.checkIsSfSync
            }, null, 8, ["is-edit", "checked-is-sf-sync", "on-click"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BLayout, {
            "align-center": "",
            "justify-center": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BBtn, {
                class: "mr-300",
                text: "",
                disabled: _ctx.waiting,
                onClick: _ctx.handleCancelCreateOpportunity
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.cancel.text')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]),
              _createVNode(_component_BBtn, {
                class: "ml-300",
                type: "primary",
                loading: _ctx.waiting,
                "data-test": "saveOpportunity",
                onClick: _ctx.saveOpportunity
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.save.text')), 1)
                ]),
                _: 1
              }, 8, ["loading", "onClick"])
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_BListItem, { class: "mb-300" }, {
            header: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString(_ctx.$t('opportunity.startedAt')), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_BInputDateTime, {
                date: _ctx.startedAtDate,
                "onUpdate:date": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.startedAtDate) = $event)),
                time: _ctx.startedAtTime,
                "onUpdate:time": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.startedAtTime) = $event)),
                "is-step": ""
              }, null, 8, ["date", "time"])
            ]),
            _: 1
          }),
          _createVNode(_component_BListItem, { class: "mb-300" }, {
            header: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString(_ctx.$t('opportunity.amount')), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_BInput, {
                modelValue: _ctx.amount,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.amount) = $event)),
                valid: _ctx.amountValid,
                "onUpdate:valid": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.amountValid) = $event)),
                type: "number",
                "max-digits": 18,
                validation: "",
                "data-test": "opportunityAmount"
              }, null, 8, ["modelValue", "valid"])
            ]),
            _: 1
          }),
          _createVNode(_component_BListItem, null, {
            default: _withCtx(() => [
              _createVNode(_component_BBtn, {
                text: "",
                type: "success",
                onClick: _ctx.handleQuickTextButtonClick
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BIcon, {
                    type: "success",
                    outlined: ""
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode(" article ")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('quickText.apply')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_BInput, {
                ref: "memoTextarea",
                modelValue: _ctx.memo,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.memo) = $event)),
                autosize: { minRows: 10, maxRows: 4 },
                resize: "none",
                "data-test": "opportunityMemo",
                placeholder: _ctx.$t('opportunity.memo'),
                type: "textarea",
                copyable: ""
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "title", "onCancel"]),
    (_ctx.isSfModalAvailable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_SalesforceOpportunityModal, {
            "call-target": _ctx.callTarget,
            "handle-save": _ctx.createOpportunity,
            onCloseModal: _ctx.handleClose
          }, null, 8, ["call-target", "handle-save", "onCloseModal"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_QuickTextSelectModal, {
      "modal-name": "quickTextSelectInOpportunityForm",
      "call-target-id": _ctx.callTargetId,
      onSelect: _ctx.handleQuickTextSelect
    }, null, 8, ["call-target-id", "onSelect"])
  ]))
}
import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

import { computed, ref, watch } from 'vue';
import { PartialSequenceStepMaster } from '@/api/openapi';
import { SequenceStepMasterApiService } from '@/api/user/resources/sequence_step_master';
import { TWithDaysPartialSequenceStepMaster, TStepType } from '@/components/organisms/user/general/sequence/types';
import { useMakeSequenceStepTitle, getStepType } from '@/composable/user/sequence/step-computed-property';
import { useWithDaysSequenceSteps } from '@/composable/user/sequence/steps-computed-property';
import { useWait } from '@/composable/vue-wait';

type Condition = {
  stepType: TStepType;
};

type TItem = TWithDaysPartialSequenceStepMaster & {
  name: string;
};

type TProps = {
  sequenceMasterId: number | null;
  condition: Condition;
  required: boolean;
  isDisplayOnly: boolean;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceStepMasterSimpleSelect',
  props: /*@__PURE__*/_mergeModels({
    sequenceMasterId: {},
    condition: {},
    required: { type: Boolean },
    isDisplayOnly: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
    "isValid": { type: Boolean, ...{ default: false } },
    "isValidModifiers": {},
  }),
  emits: ["update:modelValue", "update:isValid"],
  setup(__props: any) {

const props = __props;

const model = _useModel<number | null>(__props, "modelValue")
const isValid = _useModel<boolean>(__props, 'isValid');

const { doActionWithWait } = useWait();

const { makeSequenceStepTitle } = useMakeSequenceStepTitle();
const { withDaysSequenceSteps } = useWithDaysSequenceSteps<TItem, TWithDaysPartialSequenceStepMaster>(
  () => sequenceStepMasters.value,
  (withDaysStep) => {
    return {
      ...withDaysStep,
      name: makeSequenceStepTitle(withDaysStep),
    };
  },
);
// NOTE: バックエンドでフィルタリングすると日数が変わるため、フロントエンドでフィルタリングする
const options = computed(() => {
  return withDaysSequenceSteps.value.filter((step) => getStepType(step) === props.condition.stepType);
});

const sequenceStepMasters = ref<PartialSequenceStepMaster[]>([]);
const loadSequenceStepMasters = async () => {
  const api = new SequenceStepMasterApiService();
  await doActionWithWait('fetchSequenceMasters', async () => {
    const { data } = await api.getSequenceStepMastersBySpecifyingFields({
      request: {
        sequenceMasterId: props.sequenceMasterId,
        fields: ['sort_order', 'action_type', 'date_interval'],
      },
    });
    sequenceStepMasters.value = data;
  });
};

watch(() => props.sequenceMasterId, async (newValue, oldValue) => {
  if (oldValue != null) model.value = null; // シーケンスマスターが変更されたら、シーケンスステップをリセットする必要がある。（未選択の場合は何もしなくていい）
  if (newValue) {
    await loadSequenceStepMasters();
  }
}, { immediate: true });

const sequenceStepMasterName = (sequenceStepMasterId: number) => {
  return options.value.find((option) => option.id === sequenceStepMasterId)?.name;
};

return (_ctx: any,_cache: any) => {
  const _component_BSelect = _resolveComponent("BSelect")!

  return (_ctx.isDisplayOnly)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(sequenceStepMasterName(model.value)), 1))
    : (_openBlock(), _createBlock(_component_BSelect, {
        key: 1,
        modelValue: model.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
        valid: isValid.value,
        "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => ((isValid).value = $event)),
        items: options.value,
        required: _ctx.required,
        "item-text": "name",
        "item-value": "id",
        fit: "",
        filterable: ""
      }, null, 8, ["modelValue", "valid", "items", "required"]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "fs-400 fw-bold" }

import { AxiosResponse } from 'axios';
import { computed, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { PostSequenceMaster201Response } from '@/api/openapi';
import { SequenceMasterApiService } from '@/api/user/resources/sequence_master';
import SequenceFooter from '@/components/organisms/user/general/sequence/SequenceFooter.vue';
import Sequence from '@/components/organisms/user/sequenceNew/Sequence.vue';
import SequenceSteps from '@/components/organisms/user/sequenceNew/SequenceSteps.vue';
import { useQueryParameter } from '@/composable/query-parameter';
import { usePostPutBody } from '@/composable/user/sequence/post-put-body';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { KEY_OF_TOAST } from '@/injection-keys';


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceNew',
  setup(__props) {

const toast = inject(KEY_OF_TOAST);
const router = useRouter();
const i18n = useI18n();
const api = new SequenceMasterApiService();
const { currentUser, fetchCurrentUser } = useCurrentUser();
const sequenceOwnerId = ref<number>();

onMounted(async () => {
  await fetchCurrentUser();
  sequenceOwnerId.value = currentUser.value.id;
});

const waiting = computed(() => sequenceOwnerId.value == null);

const {
  wait,
  doActionWithWait,
} = useWait();
const {
  getAndValidateParamAsInteger: getAndValidateDuplicateId,
} = useQueryParameter('duplicateId');

const {
  sequenceBodyData,
  sequenceStepsBodyData,
  canSave,
  handleUpdateSequence,
  handleUpdateSequenceSteps,
} = usePostPutBody();

const tabLabelItems = [
  { label: i18n.t('sequence.step.title'), slotName: 'step' },
];

const loading = computed(() =>
  wait.is(['fetchDuplicateSequenceMaster', 'fetchDuplicateSequenceStepMasters', 'putSequenceMaster']),
);
const duplicateId = getAndValidateDuplicateId(i18n.t('sequence.message.duplicateIdError'));

const handleClickSave = async () => {
  if (!canSave.value) return;

  const { data: { id } } = await doActionWithWait<AxiosResponse<PostSequenceMaster201Response>>('postSequenceMaster', async () => {
    return await api.postSequenceMaster({
      request: {
        postSequenceMasterBody: {
          sequence: sequenceBodyData.value,
          sequenceSteps: sequenceStepsBodyData.value,
        },
      },
    });
  });

  toast.show(
    i18n.t('general.done.withTarget', { 
      action: i18n.t('general.save.text'),
      target: i18n.t('sequence.title'),
    }),
  );
  router.push({ name: 'Sequence', params: { id } });
};

return (_ctx: any,_cache: any) => {
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BTabs = _resolveComponent("BTabs")!
  const _component_BContent = _resolveComponent("BContent")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_BContent, null, {
    navbar: _withCtx(() => [
      _createVNode(_component_BLayout, {
        "align-start": "",
        "justify-start": ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('sequence.title')), 1)
        ]),
        _: 1
      })
    ]),
    footer: _withCtx(() => [
      _createVNode(SequenceFooter, {
        "can-save": _unref(canSave),
        loading: loading.value,
        "need-confirmation-to-leave": "",
        "onClick:save": handleClickSave
      }, null, 8, ["can-save", "loading"])
    ]),
    default: _withCtx(() => [
      (!waiting.value)
        ? (_openBlock(), _createBlock(Sequence, {
            key: 0,
            "duplicated-sequence-master-id": _unref(duplicateId),
            onUpdate: _unref(handleUpdateSequence)
          }, null, 8, ["duplicated-sequence-master-id", "onUpdate"]))
        : _createCommentVNode("", true),
      _createVNode(_component_BTabs, {
        class: "tabs",
        "label-items": tabLabelItems
      }, {
        step: _withCtx(() => [
          (!waiting.value)
            ? (_openBlock(), _createBlock(SequenceSteps, {
                key: 0,
                "duplicated-sequence-master-id": _unref(duplicateId),
                "sequence-owner-id": sequenceOwnerId.value,
                onUpdate: _unref(handleUpdateSequenceSteps)
              }, null, 8, ["duplicated-sequence-master-id", "sequence-owner-id", "onUpdate"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  })), [
    [_directive_loading, loading.value]
  ])
}
}

})
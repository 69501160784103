import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "filter-control-panel-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "filter-background"
}
const _hoisted_3 = { class: "header-text fw-bold" }
const _hoisted_4 = { class: "panel-footer" }
const _hoisted_5 = { class: "footer-text reset" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_FilterControlPanelBody = _resolveComponent("FilterControlPanelBody")!
  const _component_BFlex = _resolveComponent("BFlex")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.selectedFilterDetailKeyName && _ctx.filterControlPanelVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", {
      class: _normalizeClass(["filter-control-panel", _ctx.classes])
    }, [
      _createVNode(_component_BLayout, {
        class: "panel-header",
        "align-center": "",
        "justify-end": ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('general.filter.item')), 1),
          _createVNode(_component_BBtn, {
            flat: "",
            fab: "",
            size: "small",
            onClick: _ctx.hideFilterControlPanelVisible
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BIcon, null, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("chevron_left")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_FilterControlPanelBody, { "lead-list-filter-params": _ctx.leadListFilterParams }, null, 8, ["lead-list-filter-params"]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_BLayout, {
          "align-center": "",
          "justify-center": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BFlex, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_BBtn, {
                  text: "",
                  onClick: _ctx.resetFilter
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('general.reset.text')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ], 2)
  ]))
}
<template>
  <span
    class="mail-attachment-container"
    :class="{ link: enableLink }"
    @click="handleClick"
  >
    <BIcon class="icon">
      <span class="material-symbols-outlined">draft</span>
    </BIcon>
    <span class="file-name truncate-text">{{ fileName }}</span>
    <BIcon
      v-if="showCrossButton"
      class="cross-icon"
      @click="handleCrossIconClick"
    >
      <span>close</span>
    </BIcon>
  </span>
</template>

<script setup lang="ts">
import { FileStorageAccessApiService } from '@/api/user/resources/file_storage_access';

type TProps = {
  id: number;
  fileName: string;
  enableLink?: boolean;
  showCrossButton?: boolean;
};

type TEmit = {
  (event: 'clickCrossIcon', mailAttachmentId: number): void;
};

const props = withDefaults(defineProps<TProps>(), {
  enableLink: true,
  showCrossButton: false,
});

const emit = defineEmits<TEmit>();

const handleClick = async () => {
  if (!props.enableLink) return;
  const url = await fetchDownloadUrl();
  click(url);
};

const handleCrossIconClick = () => {
  emit('clickCrossIcon', props.id);
};

const fetchDownloadUrl = async () => {
  const apiService = new FileStorageAccessApiService();
  const { data } = await apiService.createMailAttachmentPresignedUrl({ 
    request: {
      createMailAttachmentPresignedUrlRequest: {
        mailAttachmentId: props.id,
      },
    },
  });
  return data.url;
};

const click = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.click();
};
</script>

<style lang="scss" scoped>
.mail-attachment-container {
  padding: 10px;
  background-color: #F5F7FA;
  border: 1px solid #E3E3E3;
  border-radius: 4px;
  display: flex;
  height: 36px;
  gap: 4px;
  align-items: center;
  max-width: 178px;
  &.link {
    cursor: pointer;
    &:hover {
      background-color: $bgcolor-dark;
    }
  }

  .material-symbols-outlined, .cross-icon {
    font-size: 18px;
    font-weight: bold;
    color: $concrete-dark;
  }

  .cross-icon {
    cursor: pointer;
  }

  .file-name {
    color: $uranus;
    vertical-align: middle;
    font-size: 14px;
  }
}
</style>

<template>
  <FilterDetailPopover
    v-model:is-include="isInclude"
    :title="title"
    :width="420"
    is-switch
    :is-display-only="isDisplayOnly"
    :key-name="keyName"
    :is-custom="isCustom"
    @hide="submitInputValues"
    @show="resetInputValues"
  >
    <template #reference>
      <BLayout
        align-center
      >
        <BListItem>
          <template #header>
            <BLayout
              justify-space-between
            >
              <div>{{ title }}</div>
              <div
                class="grouping-text"
                :class="{ 'is-include': (incExc === 'inc') }"
              >
                {{ referenceGroupingText }}
              </div>
            </BLayout>
          </template>
          <div>{{ referenceValue }}</div>
        </BListItem>
      </BLayout>
    </template>
    <div>
      <div class="item-filter-area">
        <div class="item-filter-area-candidate">
          <div
            v-for="item in dateTimeOptions"
            :key="item.value"
            class="my-100"
          >
            <BRadio
              v-model="inputType"
              :label="item.value"
            >
              {{ item.label }}
            </BRadio>
          </div>
          <BDateRangeForm
            v-if="inputType === 'custom'"
            v-model="inputDateTimeRange"
            :teleported="false"
            type="datetimerange"
          />
        </div>
      </div>
    </div>
  </FilterDetailPopover>
</template>

<script lang="ts" setup>
import { camelCase, lowerFirst } from 'lodash';
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { DateTimeEnum, DateTimeFilter, LeadFilterDateTimeOr } from '@/api/openapi';
import { formatShorterDateTime } from '@/utils/date-time';
import { getIncExc } from '@/utils/object';
import FilterDetailPopover from '../FilterDetailPopover.vue';

type TFilterParam = { inc: DateTimeFilter } | { exc: DateTimeFilter };
type TType = DateTimeEnum | 'custom';
type TDateTimeRange = [string, string] | [];
type TCommonProps = {
  isCustom?: boolean;
  isDisplayOnly?: boolean;
  keyName: string;
  title: string;
};
type TProps = TCommonProps & {
  filterParam: TFilterParam;
};
type TEmit = {
  hide: [filterParam: TFilterParam];
};

const props = withDefaults(defineProps<TProps>(), {
  isCustom: false,
  isDisplayOnly: false,
});
const emit = defineEmits<TEmit>();

const i18n = useI18n();

const dateTimeOptions = Object.values<TType>(DateTimeEnum).concat(['custom'])
  .map((value) => ({
    label: i18n.t(`leadFilter.dateTime.type.${lowerFirst(camelCase(value))}`),
    value,
  }));

const inputType = ref<TType>(null);
const inputDateTimeRange = ref<TDateTimeRange>([]);
const isInclude = ref(true);

const makeInputValues = (newValue: LeadFilterDateTimeOr) => {
  let type: TType = null;
  let dateTimeRange: TDateTimeRange = [];
  if (!Array.isArray(newValue)) {
    type = newValue;
    dateTimeRange = [];
  } else if (newValue.length === 2) {
    type = 'custom';
    dateTimeRange = [newValue[0], newValue[1]];
  }
  return { type, dateTimeRange };
};
const isBlankInputValues = (type: TType, dateTimeRange: TDateTimeRange) => {
  return type == null
    || type === 'custom' && dateTimeRange.length === 0;
};

const incExc = computed<'inc' | 'exc'>(() => {
  return getIncExc(props.filterParam);
});
const targetDetail = computed<LeadFilterDateTimeOr | undefined>(() => {
  return props.filterParam?.[incExc.value].or;
});

const referenceValue = computed(() => {
  const { type, dateTimeRange } = makeInputValues(targetDetail.value);
  if (isBlankInputValues(type, dateTimeRange)) return '-';
  if (type !== 'custom') {
    return dateTimeOptions.find((item) => item.value === type)?.label;
  }

  const start = formatShorterDateTime(dateTimeRange[0])
  const end = formatShorterDateTime(dateTimeRange[1])
  return `${start} 〜 ${end}`;
});
const referenceGroupingText = computed(() => {
  const { type, dateTimeRange } = makeInputValues(targetDetail.value);
  if (isBlankInputValues(type, dateTimeRange)) return '';
  return i18n.t(`general.filter.${incExc.value}`);
});

const submitInputValues = () => {
  // NOTE: 「絞り込む」or「閉じる」を押さずにclose - 入力値を上位Componentへ渡す→絞り込み実施
  // NOTE: filterの入力がない時の値は `{ inc: { or: [] } }`
  const value = isBlankInputValues(inputType.value, inputDateTimeRange.value) ? []
    : inputType.value === 'custom' ? inputDateTimeRange.value
      : inputType.value;
  const incExc = isInclude.value ? 'inc' : 'exc';
  emit('hide', { [incExc]: { or: value } } as TFilterParam);
};

const resetInputValues = () => {
  // NOTE: 開く（含:「閉じる」→再度開く） - 入力値のリセット
  const { type, dateTimeRange } = makeInputValues(targetDetail.value);
  inputType.value = type;
  inputDateTimeRange.value = dateTimeRange;
  isInclude.value = incExc.value !== 'exc';
};

export type {
  TFilterParam,
  TCommonProps,
  TEmit,
}
</script>

<style lang="scss" scoped>
.grouping-text {
  color: $basecolor-cold;
  min-width: 2rem;

  &.is-include {
    color: $basecolor-hot;
  }
}
</style>

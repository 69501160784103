import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sequence" }
const _hoisted_2 = { class: "sequence-title" }

import { ref, watch } from 'vue';
import { SequenceBody } from '@/api/openapi';
import SequenceOwner from './SequenceOwner.vue'

type TProps = {
  editable?: boolean;
  sequenceBody?: SequenceBody;
  ownerName?: string;
  forSequenceInstance?: boolean;
};
type TEmit = {
  'update': [sequenceBody: SequenceBody];
};

// NOTE: 編集の場合、取得したデータをsequenceBodyに詰めて渡す

export default /*@__PURE__*/_defineComponent({
  __name: 'EditableSequence',
  props: {
    editable: { type: Boolean, default: true },
    sequenceBody: { default: (): SequenceBody => ({
    name: '',
    ownerId: null,
  }) },
    ownerName: { default: undefined },
    forSequenceInstance: { type: Boolean, default: false }
  },
  emits: ["update"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const isNameEditing = ref(false);
const internalSequenceBody = ref<SequenceBody>({
  name: '',
  ownerId: null,
});

watch(() => props.sequenceBody, (newValue) => {
  internalSequenceBody.value = { ...newValue };
}, { immediate: true });
watch(internalSequenceBody, (newValue) => {
  emit('update', newValue);
}, { immediate: true });

const handleUpdateName = (name: string) => {
  isNameEditing.value = false;
  internalSequenceBody.value.name = name;
  emit('update', internalSequenceBody.value);
};

return (_ctx: any,_cache: any) => {
  const _component_BEditableContent = _resolveComponent("BEditableContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BEditableContent, {
        editing: isNameEditing.value,
        "onUpdate:editing": _cache[0] || (_cache[0] = ($event: any) => ((isNameEditing).value = $event)),
        uneditable: !props.editable,
        value: internalSequenceBody.value.name,
        placeholder: _ctx.$t('sequence.name'),
        "max-length": 80,
        size: "small",
        "settle-type": "blur",
        required: "",
        "is-display-value-or-place-holder": "",
        onCheck: handleUpdateName
      }, null, 8, ["editing", "uneditable", "value", "placeholder"])
    ]),
    _createVNode(SequenceOwner, {
      "owner-name": _ctx.ownerName,
      "for-sequence-instance": _ctx.forSequenceInstance
    }, null, 8, ["owner-name", "for-sequence-instance"])
  ]))
}
}

})
import { App, readonly, ref } from 'vue';
import { KEY_OF_TOAST } from '@/injection-keys';
import BToast from './BToast.vue'
import { TToastOptions } from './types';

const defaults: TToastOptions = {
  duration: 3000,
};

const maxId = ref(0);
const toasts = ref<TToastOptions[]>([]);

const generateId = () => {
  maxId.value += 1;
  return maxId.value;
};
const addToast = (payload: TToastOptions) => {
  const id = generateId();
  payload.id = id;
  setTimeout(() => deleteToast(id), payload.duration);
  toasts.value = [...toasts.value, payload];
};
const deleteToast = (id: number) => {
  toasts.value = toasts.value.filter((toast) => toast.id !== id)
};

const toast = {
  options: { ...defaults },
  setOptions (options: TToastOptions) {
    this.options = {
      ...defaults,
      ...options,
    }
    return this
  },
  show (params: TToastOptions | string) {
    if (typeof params === 'string') {
      params = {
        message: params,
      }
    }

    if (typeof params === 'object') {
      const data = {
        ...params,
        ...this.options,
      }

      addToast(data)
    }
  },
};
export type TToast = typeof toast;

export default {
  install(app: App, options: TToastOptions = {}) {
    app.provide('toasts', readonly(toasts));
    app.component('BToast', BToast);

    // Optional API用（前方互換）
    /**
     * @duplicated inject(KEY_OF_TOAST)を使用してください
     */
    app.config.globalProperties.$toast = toast.setOptions(options);
    // Composition API用
    app.provide(KEY_OF_TOAST, app.config.globalProperties.$toast);
  },
}

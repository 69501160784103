import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue'


export const DATA_TYPE = {
  Text: 'text',
  DateTime: 'datetime',
  Option: 'option',
  Number: 'number',
  MultiOption: 'multi_option',
  SFReference: 'sf_reference',
} as const;

export type TDataType = typeof DATA_TYPE[keyof typeof DATA_TYPE];

type TProps = {
  dataType: TDataType
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BDataTypeIcon',
  props: {
    dataType: {}
  },
  setup(__props: any) {

const props = __props;

const iconName = computed(() => {
  switch (props.dataType) {
    case 'text':
      return 'text_fields'
    case 'datetime':
      return 'calendar_today'
    case 'option':
      return 'radio_button_checked'
    case 'number':
      return 'looks_one'
    case 'multi_option':
      return 'checklist'
    case 'sf_reference':
      return 'account_box'
    default:
      return ''
  }
});

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!

  return (_openBlock(), _createBlock(_component_BIcon, {
    class: "mr-100",
    size: "small",
    type: "gray"
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(iconName.value), 1)
    ]),
    _: 1
  }))
}
}

})
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'

let mixin = {
  // NOTE: dummy method
  setup() {
    return {}
  },
}

if (process.env.NODE_ENV === 'production') {
  mixin = {
    setup() {
      const { isAvailableIntercomFeature } = useAvailableFeatureCheck()
      return {
        isAvailableIntercomFeature,
      }
    },
    computed: {
      ...mapGetters('user', ['currentUser']),
    },
    methods: {
      ...mapWaitingActions('user', {
        getAvailableFeaturesAction: 'getAvailableFeatureWait',
      }),
    },
    mounted() {
      this.$watch(
        () => [this.isAvailableIntercomFeature, this.currentUser],
        () => {
          if (typeof window.Intercom !== 'function') throw new Error('intercom is not set')
          if (!this.isAvailableIntercomFeature) return window.Intercom('shutdown')
          if (!this.currentUser) return

          const user = this.currentUser
          window.Intercom('boot', {
            app_id: 'u5oi15m2',
            name: user.name,
            email: user.email,
            user_id: user.id,
            created_at: user.createdAt,
            client_name: user.clientName,
          })
        },
      )
    },
  }
}

export default mixin

<template>
  <div
    class="b-toolbar"
  >
    <RouterLink
      class="brand-logo"
      :to="{ name: 'AdminClients' }"
    >
      <img
        :src="logoSrc"
        height="34"
      >
    </RouterLink>
    <BMenu>
      <template #activator>
        <ElAvatar
          class="el-avatar ml-a avatar-box"
          :size="36"
        >
          <img
            v-if="avatarUrl"
            :src="avatarUrl"
            alt="avatar"
            @error="handleImageUrlError"
          >
          <img
            v-else
            src="@/assets/default_avatar.png"
            alt="avatar"
          >
        </ElAvatar>
      </template>
      <BCard small>
        <div>
          <div>{{ name }}</div>
          <div>{{ email }}</div>
        </div>
        <BDivider class="my-200" />
        <BBtn
          text
          @click="signout"
        >
          {{ $t('general.signOut') }}
        </BBtn>
      </BCard>
    </BMenu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AdminService from '@/services/admin_service'
import AuthService from '@/services/auth_service'

export default {

  props: {
    name: {
      type: String,
      default: 'no name',
    },
    email: {
      type: String,
      default: 'mail@example.com',
    },
    avatarUrl: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      role: '',
      todayDate: '',
    }
  },
  computed: {
    ...mapGetters('breadcrumbs', ['items']),
    logoSrc () {
      return require('@/assets/brand_logo_white.svg')
    },
  },
  created () {
    this.adminService = new AdminService()
    this.authService = new AuthService()
    this.role = this.authService.currentRole()
  },
  beforeUnmount () {
    this.$clearInterval(this.timeObj)
  },
  methods: {
    signout () {
      this.adminService.signout().then(() => {
        this.$router.push({ name: 'AdminSignin' })
      })
    },
    toDashboard () {
      this.$router.push(`/${this.role}`)
    },
    handleImageUrlError (e) {
      console.error('handleImageUrlError', e)
    },
  },
}
</script>

<style lang="scss" scoped>
  .b-toolbar {
    background-color: $charcoal;
    border-bottom: 1px solid $shadowcolor-base;
    height: 60px;
    color: $textcolor-base;
    box-shadow: $boxshadow-base;
    display: flex;
    align-items: center;
    padding:10px;
  }

  .brand-logo {
    display: flex;
    align-items: center;
    margin: 0 $basespace-200 0 $basespace-100;
  }

  .el-avatar {
    cursor: pointer;
  }
  .avatar-box {
    display:flex;
  }
</style>

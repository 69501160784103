<template>
  <div
    class="b-modal-body"
    :class="classes"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    full: Boolean,
  },
  computed: {
    classes () {
      return {
        'b-modal-body-full': this.full,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .b-modal-body {
    width: 100%;
    height: 100%;
    padding: 0 40px 40px;
    overflow-y: auto;

    &-full {
      padding: 0;
    }
  }
</style>

import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, inject, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { User } from '@/api/openapi';
import { KEY_OF_MODAL } from '@/injection-keys';

type TProps = {
  currentOperatorId: number;
  operatorUsers: User[];
  needsWithdrawOption: boolean;
}

type TEmit = {
  (e: 'assign-operator', operatorId: number): void
  (e: 'withdraw-operator'): void
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SetOperator',
  props: {
    currentOperatorId: {},
    operatorUsers: {},
    needsWithdrawOption: { type: Boolean }
  },
  emits: ["assign-operator", "withdraw-operator"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit
const i18n = useI18n();
const modal = inject(KEY_OF_MODAL);

const selectedOperatorId = ref<number>();

watch(() => props.currentOperatorId, (currentOperatorId) => {
  selectedOperatorId.value = currentOperatorId
})

const filteredOptions = computed(() => {
  if (props.needsWithdrawOption) {
    const addData = props.currentOperatorId ? [
      {
        name: i18n.t('operatorAssign.unassigned'),
        id: 0,
      },
    ] : []
    return addData.concat(props.operatorUsers)
  } else {
    return props.operatorUsers
  }
});

const changeOperator = () => {
  if (selectedOperatorId.value !== 0) {
    emit('assign-operator', selectedOperatorId.value)
  } else {
    emit('withdraw-operator')
  }

  modal.hide('setOperator')
}

return (_ctx: any,_cache: any) => {
  const _component_BFlex = _resolveComponent("BFlex")!
  const _component_BSelect = _resolveComponent("BSelect")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createBlock(_component_BLayout, {
    class: "set-operator",
    "align-center": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BFlex, { span: 6 }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('operatorAssign.assignedOperator')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_BFlex, { span: 18 }, {
        default: _withCtx(() => [
          _createVNode(_component_BSelect, {
            modelValue: selectedOperatorId.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedOperatorId).value = $event)),
            items: filteredOptions.value,
            "item-text": "name",
            "item-value": "id",
            placeholder: _ctx.$t('operatorAssign.select'),
            filterable: true,
            onChange: changeOperator
          }, null, 8, ["modelValue", "items", "placeholder"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "days" }
const _hoisted_2 = { class: "number-input-container" }
const _hoisted_3 = { class: "number-input-assist-text" }
const _hoisted_4 = { class: "number-input-container mt-300" }
const _hoisted_5 = { class: "number-input-assist-text" }

import lodash from 'lodash'
import { computed, ref } from 'vue';
import FilterDetailPopover from '@/components/organisms/user/leadList/filter/FilterDetailPopover.vue'
import { i18nGlobal as i18n } from '@/i18n';

// TODO: leadListFilterParamsの型定義を共通化する
type TProps = {
  leadListFilterParams?: Record<string, {
    inc: {
      or: string[];
    };
    exc: {
      or: string[];
    };
  }>;
  isDisplayOnly?: boolean;
  keyName: string; 
}

type TEmits = {
  (e: 'hide', value: object): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LastLeadStageStayDays',
  props: {
    leadListFilterParams: { default: null },
    isDisplayOnly: { type: Boolean, default: false },
    keyName: {}
  },
  emits: ["hide"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit;

const isInclude = ref(true);
// NOTE: 文字列型の半角英数 or '' が入る
const inputEndDay = ref<string>('');
const inputStartDay = ref<string>('');

const title = computed(() => {
  return i18n.t(`filterSelect.historyInfo.item.${props.keyName}`)
})

const valueForDisplay = computed(() => {
  const startDay = lodash.isEmpty(targetDetail.value[0]) ? '' : targetDetail.value[0] + i18n.t('general.startDay')
  const endDay = lodash.isEmpty(targetDetail.value[1]) ? '' : targetDetail.value[1] + i18n.t('general.endDay')
  if (startDay === '' && endDay === '') return '-'
  return startDay + endDay
})

const target = computed(() => {
  if (props.leadListFilterParams == null) return null
  return props.leadListFilterParams[props.keyName]
})

const targetDetail = computed(() => {
  if (target.value == null) return []
  return target.value.inc.or
})

const popoverShow = () => {
  if (targetDetail.value.length === 2) {
    inputStartDay.value = lodash.isEmpty(targetDetail.value[0]) ? '' : targetDetail.value[0]
    inputEndDay.value = lodash.isEmpty(targetDetail.value[1]) ? '' : targetDetail.value[1]
  }
  isInclude.value = true
}

const popoverHide = () => {
  const grouping = isInclude.value ? 'inc' : 'exc'
  emit('hide', { [grouping]: { or: makeQueryObject() } })
}

const makeQueryObject = () => {
  const startDay = lodash.isEmpty(inputStartDay.value) ? '' : inputStartDay.value
  const endDay = lodash.isEmpty(inputEndDay.value) ? '' : inputEndDay.value
  return [startDay, endDay]
}

return (_ctx: any,_cache: any) => {
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BNumberInput = _resolveComponent("BNumberInput")!

  return (_openBlock(), _createBlock(FilterDetailPopover, {
    "is-include": isInclude.value,
    "onUpdate:isInclude": _cache[2] || (_cache[2] = ($event: any) => ((isInclude).value = $event)),
    title: title.value,
    "is-display-only": _ctx.isDisplayOnly,
    "key-name": _ctx.keyName,
    onHide: popoverHide,
    onShow: popoverShow
  }, {
    reference: _withCtx(() => [
      _createVNode(_component_BLayout, { "align-center": "" }, {
        default: _withCtx(() => [
          _createVNode(_component_BListItem, null, {
            header: _withCtx(() => [
              _createVNode(_component_BLayout, { "justify-space-between": "" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, _toDisplayString(title.value), 1)
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(valueForDisplay.value), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BNumberInput, {
          modelValue: inputStartDay.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputStartDay).value = $event))
        }, null, 8, ["modelValue"]),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('general.startDay')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_BNumberInput, {
          modelValue: inputEndDay.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((inputEndDay).value = $event))
        }, null, 8, ["modelValue"]),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('general.endDay')), 1)
      ])
    ]),
    _: 1
  }, 8, ["is-include", "title", "is-display-only", "key-name"]))
}
}

})
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BLeadStageColorSelect = _resolveComponent("BLeadStageColorSelect")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createBlock(_component_BLayout, {
    "align-center": "",
    "justify-center": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BInput, {
        modelValue: _ctx.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
        valid: _ctx.valid,
        "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.valid) = $event)),
        class: "input-name mr-400",
        placeholder: _ctx.$t('leadStage.name'),
        rules: [_ctx.requiredRule],
        "max-length": _ctx.maxNameLength,
        onKeypressEnter: _ctx.handleSubmit
      }, null, 8, ["modelValue", "valid", "placeholder", "rules", "max-length", "onKeypressEnter"]),
      _createVNode(_component_BLeadStageColorSelect, {
        modelValue: _ctx.colorName,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.colorName) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_BBtn, {
        type: "primary",
        disabled: !_ctx.valid,
        loading: _ctx.$wait.is('createLeadStageWait'),
        onClick: _ctx.handleSubmit
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.append.text')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "loading", "onClick"])
    ]),
    _: 1
  }))
}
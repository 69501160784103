import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  "infinite-scroll-disabled": "scrollDisabled",
  "infinite-scroll-distance": "20"
}
const _hoisted_2 = {
  key: 0,
  class: "current-time-line"
}
const _hoisted_3 = {
  key: 1,
  class: "current-time-line"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BEmptyBox = _resolveComponent("BEmptyBox")!
  const _component_ReminderItem = _resolveComponent("ReminderItem")!
  const _component_BElementIcon = _resolveComponent("BElementIcon")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.existActions)
      ? (_openBlock(), _createBlock(_component_BEmptyBox, {
          key: 0,
          class: "empty",
          "display-only": ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('reminder.empty')), 1)
          ]),
          _: 1
        }))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.orderedActions, (timelineActions, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: timelineActions.key
          }, [
            (timelineActions.status === 'isCurrent')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(timelineActions.value, (item) => {
              return (_openBlock(), _createBlock(_component_ReminderItem, {
                key: item.nextActionId,
                item: item
              }, null, 8, ["item"]))
            }), 128)),
            (index === _ctx.orderedActions.length - 1 && timelineActions.status === 'isPast')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3))
              : _createCommentVNode("", true)
          ]))
        }), 128)),
    (_ctx.scrollDisabled && _ctx.$wait.is('infiniteLoadingWait'))
      ? (_openBlock(), _createBlock(_component_BLayout, {
          key: 2,
          class: "py-200",
          "justify-center": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BElementIcon, { name: "Loading" })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ])), [
    [_directive_infinite_scroll, _ctx.load]
  ])
}
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-drawer" }
const _hoisted_2 = { class: "mt-100 immediately" }
const _hoisted_3 = {
  key: 1,
  class: "time-items mb-600"
}

import { computed, ref } from 'vue';
import { User } from '@/api/openapi';
import MailDrawerTemplate from '@/components/organisms/user/mail/common/drawer/MailDrawerTemplate.vue'
import FormDrawerBodyCommon from '@/components/organisms/user/mail/direct/drawer/state/form/FormDrawerBodyCommon.vue';
import { SenderData } from '../../../mail/direct/drawer/state/types';
import { TFormDirectMailSequenceStep } from '../types';

type TProps = {
  modelValue: TFormDirectMailSequenceStep;
  users: readonly User[];
  isLoading: boolean;
  isAvailableMailTemplateFeature: boolean;
  senderData: SenderData | null;
  draftable: boolean;
  confirmInfo?: string;
};
type TEmit = {
  'update:modelValue': [value: TFormDirectMailSequenceStep];
  'cancel': [];
  'draft': [];
  'changeCurrentStateToConfirm': [];
  'closeOpenai': [cancel: () => void];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'FormDrawer',
  props: {
    modelValue: {},
    users: {},
    isLoading: { type: Boolean },
    isAvailableMailTemplateFeature: { type: Boolean },
    senderData: {},
    draftable: { type: Boolean },
    confirmInfo: {}
  },
  emits: ["update:modelValue", "cancel", "draft", "changeCurrentStateToConfirm", "closeOpenai"],
  setup(__props: any, { emit: __emit }) {

const props =  __props;
const emit = __emit;

const isValidFormValuesCommon = ref(false);

const isDateIntervalValid = ref(false);
const dateInterval = computed({
  get: () => props.modelValue.dateInterval,
  set: (v) => updateFormValues('dateInterval', v),
});
const reservedTime = computed({
  get: () => props.modelValue.reservedTime,
  set: (v) => updateFormValues('reservedTime', v),
});

const isValid = computed(() =>
  isValidFormValuesCommon.value
  && (isDateIntervalValid.value || props.modelValue.immediately)
  && !!reservedTime.value,
);

const handleUpdateFormValuesCommon = (newValue: TFormDirectMailSequenceStep) => {
  emit('update:modelValue', newValue)
};
const updateFormValues = (key: keyof TFormDirectMailSequenceStep, value: unknown) => {
  emit('update:modelValue', {
    ...props.modelValue,
    [key]: value,
  })
};
const handleUpdateIsValidFormValuesCommon = (value: boolean) => {
  isValidFormValuesCommon.value = value;
};
const handleCancelClick = () => {
  emit('cancel')
};
const handleDraftClick = () => {
  emit('draft')
};
const handleClickConfirm = () => {
  emit('changeCurrentStateToConfirm')
};
const handleOpenAiCloseModal = (cancel: () => void) => {
  emit('closeOpenai', cancel);
};

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BTimePicker = _resolveComponent("BTimePicker")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MailDrawerTemplate, {
      title: _ctx.$t('mail.mailCreate'),
      width: 600
    }, {
      drawerHeaderAction: _withCtx(() => [
        _createVNode(_component_BTooltip, {
          top: "",
          content: _ctx.confirmInfo,
          disabled: !_ctx.confirmInfo
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BBtn, {
              size: "small",
              type: "primary",
              outline: "",
              loading: _ctx.isLoading,
              disabled: !isValid.value,
              onClick: handleClickConfirm
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('mail.confirmContent')), 1),
                _createVNode(_component_BIcon, null, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("keyboard_arrow_right")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["loading", "disabled"])
          ]),
          _: 1
        }, 8, ["content", "disabled"])
      ]),
      drawerBody: _withCtx(() => [
        _createVNode(FormDrawerBodyCommon, {
          "model-value": _ctx.modelValue,
          "is-available-mail-template-feature": _ctx.isAvailableMailTemplateFeature,
          "sender-data": _ctx.senderData,
          "onUpdate:modelValue": handleUpdateFormValuesCommon,
          "onUpdate:isValid": handleUpdateIsValidFormValuesCommon,
          onCloseOpenai: handleOpenAiCloseModal
        }, {
          default: _withCtx(() => [
            (_ctx.modelValue.immediately)
              ? (_openBlock(), _createBlock(_component_BListItem, {
                  key: 0,
                  class: "mb-300"
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('sequence.step.dateInterval')), 1)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('general.sendImmediately')), 1)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_BListItem, { class: "date-interval-container" }, {
                    header: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('sequence.step.dateInterval')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_BInput, {
                        modelValue: dateInterval.value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dateInterval).value = $event)),
                        valid: isDateIntervalValid.value,
                        "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => ((isDateIntervalValid).value = $event)),
                        type: "number",
                        max: 30,
                        min: 0,
                        validation: "",
                        required: "",
                        "needs-parse-int": ""
                      }, null, 8, ["modelValue", "valid"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_BListItem, { class: "reserved-time-container" }, {
                    header: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('sequence.step.reservedTime')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_BTimePicker, {
                        modelValue: reservedTime.value,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((reservedTime).value = $event)),
                        "value-format": "HH:mm",
                        format: "HH:mm",
                        required: ""
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]))
          ]),
          _: 1
        }, 8, ["model-value", "is-available-mail-template-feature", "sender-data"])
      ]),
      drawerFooter: _withCtx(() => [
        _createVNode(_component_BLayout, { "justify-center": "" }, {
          default: _withCtx(() => [
            (_ctx.draftable)
              ? (_openBlock(), _createBlock(_component_BBtn, {
                  key: 0,
                  type: "primary",
                  class: "mr-100",
                  loading: _ctx.isLoading,
                  disabled: !isValid.value,
                  flat: "",
                  onClick: handleDraftClick
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('general.draftSave.text')), 1)
                  ]),
                  _: 1
                }, 8, ["loading", "disabled"]))
              : _createCommentVNode("", true),
            _createVNode(_component_BBtn, {
              flat: "",
              onClick: handleCancelClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.close.text')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}
}

})
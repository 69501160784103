import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "navbar-left" }
const _hoisted_2 = { class: "page-title fs-400 fw-bold" }
const _hoisted_3 = { class: "ml-100" }
const _hoisted_4 = { class: "mt-50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BCustomIcon = _resolveComponent("BCustomIcon")!
  const _component_MailTemplateList = _resolveComponent("MailTemplateList")!
  const _component_MailTemplateForm = _resolveComponent("MailTemplateForm")!
  const _component_MailFormTransition = _resolveComponent("MailFormTransition")!
  const _component_OpenaiContentGenerate = _resolveComponent("OpenaiContentGenerate")!
  const _component_BContent = _resolveComponent("BContent")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_BContent, { class: "content" }, {
    navbar: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("mailTemplate.pageTitle")), 1),
        _createElementVNode("button", {
          class: "create-btn",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showNewForm && _ctx.showNewForm(...args)))
        }, [
          _createVNode(_component_BCustomIcon, {
            class: "template-icon",
            "icon-class": "b-template"
          }),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("mailTemplate.create")), 1)
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_MailTemplateList, {
          "mail-templates": _ctx.mailTemplates,
          onClick: _ctx.showEditForm,
          onDuplicate: _ctx.showDuplicatedNewForm
        }, null, 8, ["mail-templates", "onClick", "onDuplicate"]),
        _createVNode(_component_MailFormTransition, { "is-visible": _ctx.formVisible }, {
          default: _withCtx(() => [
            _createVNode(_component_MailTemplateForm, {
              "mail-template": _ctx.mailTemplate,
              "onUpdate:mailTemplate": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.mailTemplate) = $event)),
              "is-creating": _ctx.isCreating,
              onCancel: _ctx.cancel,
              onClose: _ctx.close,
              onChange: _ctx.onChange,
              onDuplicate: _ctx.showDuplicatedNewForm,
              onMailTemplateChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.mailTemplate = $event))
            }, null, 8, ["mail-template", "is-creating", "onCancel", "onClose", "onChange", "onDuplicate"])
          ]),
          _: 1
        }, 8, ["is-visible"]),
        _createVNode(_component_OpenaiContentGenerate, {
          ref: "openai",
          "set-content": _ctx.setOpenAiContent
        }, null, 8, ["set-content"])
      ])
    ]),
    _: 1
  })), [
    [_directive_loading, _ctx.$wait.is(['getMailTemplatesWait'])]
  ])
}
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "toast-inner" }
const _hoisted_2 = { class: "contents" }
const _hoisted_3 = {
  key: 0,
  class: "toast-title"
}
const _hoisted_4 = { class: "toast-message" }

import { computed } from 'vue';
import { TToastType } from './types';

type TProps = {
  message: string;
  title?: string;
  type?: TToastType;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ToastItem',
  props: {
    message: {},
    title: { default: '' },
    type: { default: 'success' }
  },
  setup(__props: any) {

const props = __props;

const typeStyle = computed(() => {
  switch (props.type) {
  case 'success':
    return { class: 'toast-success', icon: 'check_circle' }
  case 'info':
    return { class: 'toast-info', icon: 'info' }
  case 'warning':
    return { class: 'toast-warning', icon: 'warning' }
  case 'error':
    return { class: 'toast-error', icon: 'block' }
  default:
    return { class: 'toast-success', icon: 'check_circle' }
  }
});

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BCard = _resolveComponent("BCard")!

  return (_openBlock(), _createBlock(_component_BCard, {
    class: _normalizeClass(["toast", typeStyle.value.class]),
    full: "",
    shadow: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BIcon, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(typeStyle.value.icon), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.message), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})
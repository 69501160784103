import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "error-area" }
const _hoisted_2 = { class: "error-title" }
const _hoisted_3 = { class: "error" }

type TProps = {
  errorTitle: string;
  errorInfo: string;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'BError',
  props: {
    errorTitle: {},
    errorInfo: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BIcon, { class: "warning-icon" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" warning ")
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.errorTitle), 1)
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.errorInfo), 1)
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-body" }
const _hoisted_2 = { class: "error-value" }

import { inject, ref } from 'vue';
import { KEY_OF_MODAL } from '@/injection-keys';

type TProps = {
  importMappingErrors: string[];
};

export default /*@__PURE__*/_defineComponent({
  __name: 'SalesforceReportErrorMappingModal',
  props: {
    importMappingErrors: {}
  },
  setup(__props: any) {


const modalName = ref('salesforceReportErrorMappingModal')
const modal = inject(KEY_OF_MODAL);
const close = () => {
  modal.hide('salesforceReportErrorMappingModal');
}

return (_ctx: any,_cache: any) => {
  const _component_BModalHeader = _resolveComponent("BModalHeader")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BModalBody = _resolveComponent("BModalBody")!
  const _component_BHeightAdjuster = _resolveComponent("BHeightAdjuster")!
  const _component_BModal = _resolveComponent("BModal")!

  return (_openBlock(), _createBlock(_component_BModal, {
    "modal-name": modalName.value,
    height: "auto",
    width: "auto",
    class: "error-mapping-modal",
    scroll: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BHeightAdjuster, null, {
        default: _withCtx(() => [
          _createVNode(_component_BModalHeader, {
            title: _ctx.$t('apiError.salesforce.validation.required.necessaryMappingMissing'),
            center: "",
            onModalClose: close
          }, null, 8, ["title"]),
          _createVNode(_component_BModalBody, null, {
            default: _withCtx(() => [
              _createVNode(_component_BLayout, {
                "justify-center": "",
                "align-center": "",
                "is-fit": ""
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.importMappingErrors, (error, group) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: group,
                        class: "error-row"
                      }, [
                        _createElementVNode("div", null, _toDisplayString(group), 1),
                        _createElementVNode("div", _hoisted_2, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(error, (value) => {
                            return (_openBlock(), _createElementBlock("div", { key: value }, _toDisplayString(value), 1))
                          }), 128))
                        ])
                      ]))
                    }), 128))
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-name"]))
}
}

})
<template>
  <BCard
    class="toast"
    full
    shadow
    :class="typeStyle.class"
  >
    <div class="toast-inner">
      <BIcon>{{ typeStyle.icon }}</BIcon>
      <div class="contents">
        <div
          v-if="title"
          class="toast-title"
        >
          {{ title }}
        </div>
        <div class="toast-message">
          {{ message }}
        </div>
      </div>
    </div>
  </BCard>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { TToastType } from './types';

type TProps = {
  message: string;
  title?: string;
  type?: TToastType;
};

const props = withDefaults(defineProps<TProps>(), {
  title: '',
  type: 'success',
});

const typeStyle = computed(() => {
  switch (props.type) {
  case 'success':
    return { class: 'toast-success', icon: 'check_circle' }
  case 'info':
    return { class: 'toast-info', icon: 'info' }
  case 'warning':
    return { class: 'toast-warning', icon: 'warning' }
  case 'error':
    return { class: 'toast-error', icon: 'block' }
  default:
    return { class: 'toast-success', icon: 'check_circle' }
  }
});
</script>

<style lang="scss" scoped>
.toast {
  width: 100%;
  height: auto;
  min-height: 40px;
  font-size: $fontsize-200;
  // white-space: pre-wrap; 高さがかなり変わる
  word-wrap: break-word;
  line-height: 1.77;
  background-color: $bgcolor-white;
  margin-top: $basespace-100;

  &-inner {
    padding: $basespace-200 $basespace-300;
    display: flex;
    align-items: center;
  }
}

.contents {
  padding-left: $basespace-300;
  display: flex;
  flex-wrap: wrap;
}

.toast-message {
  width: 100%;
}

.toast-title {
  width: 100%;
  font-weight: bold;
}

.toast-success {
  .b-icon {
    color: $basecolor-success;
  }
}

.toast-info {
  .b-icon {
    color: $basecolor-info;
  }
}

.toast-warning {
  .b-icon {
    color: $basecolor-warning;
  }
}

.toast-error {
  .b-icon {
    color: $basecolor-error;
  }
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { SequenceBody } from '@/api/openapi';
import { useSequenceMaster } from '@/composable/user/sequence/sequence-master';
import EditableSequence from '../general/sequence/EditableSequence.vue';

type TProps = {
  duplicatedSequenceMasterId?: number;
};
type TEmit = {
  'update': [sequenceBody: SequenceBody];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'Sequence',
  props: {
    duplicatedSequenceMasterId: {}
  },
  emits: ["update"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const i18n = useI18n();
const {
  sequenceMaster: duplicateSequenceMaster,
  fetchSequenceMaster: fetchDuplicateSequenceMaster,
} = useSequenceMaster(props.duplicatedSequenceMasterId);
if (props.duplicatedSequenceMasterId) {
  fetchDuplicateSequenceMaster('fetchDuplicateSequenceMaster');
}

const sequenceBody = computed<SequenceBody>(() => {
  const name = duplicateSequenceMaster.value
    ? i18n.t('sequence.duplicatedName', { originalName: duplicateSequenceMaster.value.name })
    : '';

  return {
    name,
    // TODO: API側で設定しているのでownerId不要だが、型で必須になっているので一旦undefinedを渡している。型を修正したら削除する。
    ownerId: undefined,
  };
});


const handleUpdate = (sequenceBody: SequenceBody) => {
  emit('update', sequenceBody);
};

return (_ctx: any,_cache: any) => {
  const _component_VWait = _resolveComponent("VWait")!

  return (_openBlock(), _createBlock(_component_VWait, { for: "fetchDuplicateSequenceMaster" }, {
    default: _withCtx(() => [
      _createVNode(EditableSequence, {
        "sequence-body": sequenceBody.value,
        onUpdate: handleUpdate
      }, null, 8, ["sequence-body"])
    ]),
    _: 1
  }))
}
}

})
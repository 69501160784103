import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import DateTimeFilterCommon, { TFilterParam, TCommonProps } from './DateTimeFilterCommon.vue';

type TProps = TCommonProps & {
  leadListFilterParams: {
    [key: string]: TFilterParam;
  };
};


export default /*@__PURE__*/_defineComponent({
  __name: 'DateTimeFilter',
  props: {
    isCustom: { type: Boolean },
    isDisplayOnly: { type: Boolean, default: false },
    keyName: {},
    title: {},
    leadListFilterParams: {}
  },
  setup(__props: any) {

const props = __props;

const filterParam = computed<TFilterParam>(() => {
  return props.leadListFilterParams[props.keyName];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DateTimeFilterCommon, {
    "is-custom": _ctx.isCustom,
    "is-display-only": _ctx.isDisplayOnly,
    "key-name": _ctx.keyName,
    title: _ctx.title,
    "filter-param": filterParam.value
  }, null, 8, ["is-custom", "is-display-only", "key-name", "title", "filter-param"]))
}
}

})
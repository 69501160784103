import ApiService, { ApiArguments } from '@/api/api_service';
import {
  GetTriggerActionTypes200Response,
  TriggerActionTypeApi,
} from '@/api/openapi';

export class TriggerActionTypeApiService extends ApiService {
  api = new TriggerActionTypeApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true)
  }

  async getTriggerActionTypes(
    apiArguments?: ApiArguments<undefined>,
  ) {
    return this.checkResponse<GetTriggerActionTypes200Response>(
      this.api.getTriggerActionTypes(apiArguments?.options),
      apiArguments?.errorHandlers,
    );
  }
}

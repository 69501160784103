<template>
  <FilterDetailPopover
    v-model:is-include="isInclude"
    :title="title"
    is-switch
    :is-display-only="isDisplayOnly"
    :key-name="keyName"
    @hide="popoverHide"
    @show="popoverShow"
  >
    <template #reference>
      <BLayout
        align-center
      >
        <BListItem>
          <template #header>
            <BLayout
              justify-space-between
            >
              <div>{{ title }}</div>
              <div
                class="grouping-text"
                :class="{ 'is-include': incExc === 'inc' }"
              >
                {{ groupingText }}
              </div>
            </BLayout>
          </template>
          <div>{{ value }}</div>
        </BListItem>
      </BLayout>
    </template>
    <div>
      <FilterItemCheckbox
        v-model:checked-item="checkedItem"
        :candidate-items="candidateItems"
        no-blank-item
      />
    </div>
  </FilterDetailPopover>
</template>

<script>
import lodash from 'lodash'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import FilterDetailPopover from '@/components/organisms/user/leadList/filter/FilterDetailPopover.vue'
import FilterItemCheckbox from '@/components/organisms/user/leadList/filter/FilterItemCheckbox.vue'
import { getIncExc } from '@/utils/object'

export default {
  components: {
    FilterDetailPopover,
    FilterItemCheckbox,
  },
  props: {
    leadListFilterParams: {
      type: Object,
      default () {
        return {}
      },
    },
    isDisplayOnly: {
      type: Boolean,
    },
    keyName: {
      type: String,
      require: true,
    },
  },
  emits: [
    'hide',
  ],
  data () {
    return {
      checkedItem: [],
      isInclude: true,
    }
  },
  computed: {
    ...mapGetters('user', [
      'leadSources',
    ]),
    candidateItems () {
      return this.leadSources.map(candidate => {
        return { label: candidate.name, value: candidate.id }
      })
    },
    title () {
      return this.$t(`filterSelect.leadAdditionalInfo.leadSourceItem.${this.keyName}`)
    },
    value () {
      if (this.target == null || this.targetDetail.length === 0) return '-'
      const values = this.targetDetail.map((v) => {
        const leadSource = this.getLeadSource(v)
        return leadSource == null ? this.$t('general.delete.done') : leadSource.name
      })
      return values.join(' / ')
    },
    groupingText () {
      if (this.target == null || this.targetDetail.length === 0) return
      return this.$t(`general.filter.${this.incExc}`)
    },
    target () {
      return this.leadListFilterParams[this.keyName]
    },
    targetDetail () {
      if (this.target == null) return []
      return this.target[this.incExc].or.map(v => parseInt(v))
    },
    incExc () {
      return getIncExc(this.target)
    },
  },
  created () {
    this.getLeadSourcesAction()
  },
  methods: {
    ...mapWaitingActions('user', {
      getLeadSourcesAction: 'getLeadSourcesWait',
    }),
    popoverShow () {
      this.getLeadSourcesAction()
      this.checkedItem = lodash.cloneDeep(this.targetDetail)
      this.isInclude = this.incExc === 'inc'
    },
    popoverHide () {
      const grouping = this.isInclude ? 'inc' : 'exc'
      this.$emit('hide', { [grouping]: { or: this.checkedItem } })
    },
    getLeadSource (id) {
      return this.leadSources.find(ft => ft.id === id)
    },
  },
}
</script>

<style lang="scss" scoped>
  .grouping-text {
    color: $basecolor-cold;
    &.is-include {
      color: $basecolor-hot;
    }
  }
</style>

import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import {
  CustomActionApi,
  CustomAction,
  CustomActionApiPostCustomActionRequest,
  CustomActionApiDeleteCustomActionRequest,
  CustomActionApiPutCustomActionRequest,
} from '@/api/openapi';

export class CustomActionApiService extends ApiService {
  api = new CustomActionApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true)
  }

  async getCustomActions(
    apiArguments?: ApiArguments<undefined>,
  ): Promise<AxiosResponse<CustomAction[]>> {
    return this.checkResponse<CustomAction[]>(
      this.api.getCustomActions(apiArguments?.options),
      apiArguments?.errorHandlers,
    );
  }

  async createCustomAction({
    request,
    options,
    errorHandlers,
  }: ApiArguments<CustomActionApiPostCustomActionRequest>): Promise<AxiosResponse<CustomAction>> {
    return this.checkResponse<CustomAction>(
      this.api.postCustomAction(request, options),
      errorHandlers,
    );
  }

  async updateCustomAction({
    request,
    options,
    errorHandlers,
  }: ApiArguments<CustomActionApiPutCustomActionRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.putCustomAction(request, options),
      errorHandlers,
    );
  }

  async deleteCustomAction({
    request,
    options,
    errorHandlers,
  }: ApiArguments<CustomActionApiDeleteCustomActionRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.deleteCustomAction(request, options),
      errorHandlers,
    );
  }
}

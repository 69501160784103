import ApiService, { ApiArguments } from '@/api/api_service';
import {
  GetTrigger200Response,
  GetTriggers200Response,
  TriggerApi,
  TriggerApiDeleteTriggerRequest,
  TriggerApiGetTriggerRequest,
  TriggerApiPostTriggerRequest,
  TriggerApiPutTriggerRequest,
  TriggerApiPutTriggerStatusRequest,
  PostSequenceMaster201Response,
  TriggerApiGetTriggersRequest,
} from '@/api/openapi';

export class TriggerApiService extends ApiService {
  api = new TriggerApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true)
  }

  async getTriggers(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<TriggerApiGetTriggersRequest>,
  ) {
    return this.checkResponse<GetTriggers200Response>(
      this.api.getTriggers(request, options),
      errorHandlers,
    );
  }

  async getTrigger(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<TriggerApiGetTriggerRequest>,
  ) {
    return this.checkResponse<GetTrigger200Response>(
      this.api.getTrigger(request, options),
      errorHandlers,
    );
  }

  async postTrigger({
    request,
    options,
    errorHandlers,
  }: ApiArguments<TriggerApiPostTriggerRequest>) {
    return this.checkResponse<PostSequenceMaster201Response>(
      this.api.postTrigger(request, options),
      errorHandlers,
    );
  }

  async putTrigger({
    request,
    options,
    errorHandlers,
  }: ApiArguments<TriggerApiPutTriggerRequest>) {
    return this.checkResponse<void>(
      this.api.putTrigger(request, options),
      errorHandlers,
    );
  }

  async deleteTrigger({
    request,
    options,
    errorHandlers,
  }: ApiArguments<TriggerApiDeleteTriggerRequest>) {
    return this.checkResponse<void>(
      this.api.deleteTrigger(request, options),
      errorHandlers,
    );
  }

  async putTriggerStatus({
    request,
    options,
    errorHandlers,
  }: ApiArguments<TriggerApiPutTriggerStatusRequest>) {
    return this.checkResponse<void>(
      this.api.putTriggerStatus(request, options),
      errorHandlers,
    );
  }
}

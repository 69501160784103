import ApiService from '@/api/api_service'
import PollingApiService from '@/api/polling_api_service'
import UnauthorizedApiService from '@/api/unauthorized_api_service'

export default {
  cancel(message) {
    const apiService = new ApiService()
    apiService.cancel(message)
  },
  async get(
    path,
    { params = null, header = {}, errorHandlers = {}, timeout = null, responseType = null } = {},
  ) {
    const apiService = new ApiService()
    return apiService.get(path, { params, header, errorHandlers, timeout, responseType })
  },
  async pollingGet(
    path,
    { params = null, header = {}, errorHandlers = {}, timeout = null, responseType = null } = {},
  ) {
    const pollingApiService = new PollingApiService()
    return pollingApiService.get(path, { params, header, errorHandlers, timeout, responseType })
  },
  async unauthorizedGet(
    path,
    { params = null, header = {}, errorHandlers = {}, timeout = null, responseType = null } = {},
  ) {
    const unauthorizedApiService = new UnauthorizedApiService()
    return unauthorizedApiService.get(path, { params, header, errorHandlers, timeout, responseType })
  },
  async delete(
    path,
    { params = null, header = {}, errorHandlers = {}, timeout = null } = {},
  ) {
    const apiService = new ApiService()
    return apiService.delete(path, { params, header, errorHandlers, timeout })
  },
  async post(
    path,
    { body = null, header = {}, errorHandlers = {}, timeout = null } = {},
  ) {
    const apiService = new ApiService()
    return apiService.post(path, { body, header, errorHandlers, timeout })
  },
  async unauthorizedPost(
    path,
    { body = null, header = {}, errorHandlers = {}, timeout = null } = {},
  ) {
    const unauthorizedApiService = new UnauthorizedApiService()
    return unauthorizedApiService.post(path, { body, header, errorHandlers, timeout })
  },
  async put(
    path,
    { body = null, header = {}, errorHandlers = {}, timeout = null } = {},
  ) {
    const apiService = new ApiService()
    return apiService.put(path, { body, header, errorHandlers, timeout })
  },
  async unauthorizedPut(
    path,
    { body = null, header = {}, errorHandlers = {}, timeout = null } = {},
  ) {
    const unauthorizedApiService = new UnauthorizedApiService()
    return unauthorizedApiService.put(path, { body, header, errorHandlers, timeout })
  },
}

import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"100%"} }
const _hoisted_2 = { class: "confirm-loading-area" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormDrawer = _resolveComponent("FormDrawer")!
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_ConfirmDrawer = _resolveComponent("ConfirmDrawer")!
  const _component_VWait = _resolveComponent("VWait")!
  const _component_SendingDrawer = _resolveComponent("SendingDrawer")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.currentState === _ctx.state.form && _ctx.senderData)
      ? (_openBlock(), _createBlock(_component_FormDrawer, _mergeProps({ key: 0 }, _ctx.formAttr, {
          modelValue: _ctx.formValues,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formValues) = $event)),
            _ctx.updateFormValues
          ],
          "is-loading": _ctx.$wait.is(['getDirectMailWait', 'createDirectMailWait', 'updateDirectMailWait']),
          "sender-data": _ctx.senderData,
          onChangeCurrentStateToConfirm: _ctx.handleChangeToConfirm,
          onSaveDraft: _ctx.saveDraft,
          onCancel: _ctx.checkCancelDrawer,
          onCloseOpenai: _ctx.handleCloseOpenAi
        }), null, 16, ["modelValue", "is-loading", "sender-data", "onUpdate:modelValue", "onChangeCurrentStateToConfirm", "onSaveDraft", "onCancel", "onCloseOpenai"]))
      : (_ctx.currentState === _ctx.state.confirm)
        ? (_openBlock(), _createBlock(_component_VWait, {
            key: 1,
            for: "none",
            style: {"height":"100%"},
            visible: _ctx.$wait.is(['getDirectMailWait']) || !_ctx.innerDirectMail
          }, {
            waiting: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _withDirectives(_createVNode(_component_BPlaceholder, {
                  height: "50vh",
                  "element-loading-background": "transparent"
                }, null, 512), [
                  [_directive_loading, true]
                ])
              ])
            ]),
            default: _withCtx(() => [
              (_ctx.innerDirectMail && _ctx.senderData)
                ? (_openBlock(), _createBlock(_component_ConfirmDrawer, _mergeProps({ key: 0 }, _ctx.confirmAttr, {
                    "is-loading": false,
                    "sender-data": _ctx.senderData,
                    onEdit: _ctx.edit,
                    onReserve: _ctx.reserve,
                    onSend: _ctx.send,
                    onClose: _ctx.closeModal
                  }), null, 16, ["sender-data", "onEdit", "onReserve", "onSend", "onClose"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["visible"]))
        : (_ctx.currentState === _ctx.state.sending)
          ? (_openBlock(), _createBlock(_component_SendingDrawer, _mergeProps({ key: 2 }, _ctx.sendingAttr, {
              onCancel: _ctx.checkCancelDrawer,
              onSendingAnimationCompleted: _ctx.onSendingAnimationCompleted
            }), null, 16, ["onCancel", "onSendingAnimationCompleted"]))
          : _createCommentVNode("", true)
  ]))
}
import { GetterTree } from 'vuex';
import { RootState, ZoomPhoneState } from '../types'

const getters: GetterTree<ZoomPhoneState, RootState> = {
  zoomSetting(state: ZoomPhoneState): ZoomPhoneState['zoomSetting'] {
    return state.zoomSetting
  },
  zoomPhoneConnected(state: ZoomPhoneState): boolean {
    return !!state.zoomSetting
  },
  isCallable(state: ZoomPhoneState): boolean {
    return !state.isCalling
  },
  isCalling(state: ZoomPhoneState): boolean {
    return state.isCalling
  },
  isInboundCalling(state: ZoomPhoneState): boolean {
    return state.isCalling && state.callInfo.direction === 'inbound'
  },
  isOutboundCalling(state: ZoomPhoneState): boolean {
    return state.isCalling && state.callInfo.direction === 'outbound'
  },
  callInfo(state: ZoomPhoneState): ZoomPhoneState['callInfo'] {
    return state.callInfo
  },
}

export default getters

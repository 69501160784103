import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';
import { PaginationMeta } from '@/api/openapi';

type TProps = {
  currentPage: number;
  pagingMeta: PaginationMeta;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BPaginationMetaText',
  props: {
    currentPage: {},
    pagingMeta: {}
  },
  setup(__props: any) {

const props = __props;

const text = computed(() => {
  const { totalCount, limitValue } = props.pagingMeta;
  let from = (props.currentPage - 1) * limitValue + 1;
  if (totalCount === 0) from = 0;
  const to = Math.min(from + limitValue - 1, totalCount);
  return `${from} - ${to} / ${totalCount}`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", null, _toDisplayString(text.value), 1)
  ]))
}
}

})
import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "mb-200" }
const _hoisted_2 = { class: "fw-bold mr-100" }
const _hoisted_3 = { class: "placeholder-list" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "placeholder-title" }
const _hoisted_6 = { class: "placeholder-item" }
const _hoisted_7 = {
  key: 1,
  class: "text-annotation"
}

import { ref, onMounted, computed } from 'vue';
import { ApiArguments } from '@/api/api_service';
import { PlaceholderApiGetPlaceholdersRequest, PlaceholderGroup } from '@/api/openapi';
import { PlaceholderApiService } from '@/api/user/resources/placeholder';
import { useWait } from '@/composable/vue-wait';
import { i18nGlobal as i18n } from '@/i18n';

const UNSUBSCRIBE_PLACEHOLDER = '{{Unsubscribe}}';

type TProps = {
  isMail?: boolean;
  unsubscribeVisible?: boolean;
  content?: string;
};
type TEmits = {
  'insertItem': [content: string];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'PlaceholderMenu',
  props: /*@__PURE__*/_mergeModels({
    isMail: { type: Boolean, default: false },
    unsubscribeVisible: { type: Boolean, default: false },
    content: { default: '' }
  }, {
    "modelValue": { type: Boolean, ...{ default: true } },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["insertItem"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { doActionWithWait, wait } = useWait();

const isShow = _useModel<boolean>(__props, "modelValue")
const searchText = ref('');
const placeholderGroups = ref<PlaceholderGroup[]>([]);

onMounted(() => {
  fetchData();
});

const fetchData = async () => {
  await doActionWithWait('fetchPlaceholders', async () => {
    const api = new PlaceholderApiService();
    const apiArguments: ApiArguments<PlaceholderApiGetPlaceholdersRequest> = {
      request: {
        isMail: props.isMail,
      },
    };
    const { data } = await api.getPlaceholders(apiArguments);
    placeholderGroups.value = data.placeholderGroups;
  });
};

const loading = computed(() => {
  return wait.is('fetchPlaceholders');
});

const filteredPlaceholders = computed(() => {
  const result = []
  for (const placeholderGroup of placeholderGroups.value) {
    const filtered = placeholderGroup.placeholders.filter((placeholder) => {
      if (placeholder.key === UNSUBSCRIBE_PLACEHOLDER && !props.unsubscribeVisible) {
        return false;
      }
      return placeholder.key.includes(searchText.value) || placeholder.name.includes(searchText.value);
    });
    if (filtered.length > 0) {
      result.push({ name: placeholderGroup.name, placeholders: filtered } as PlaceholderGroup);
    }
  }
  return result;
});
const isMatching = computed(() => filteredPlaceholders.value.length > 0);

const disablePlaceholder = (key: string) => {
  return key === UNSUBSCRIBE_PLACEHOLDER && alreadyContainUnsubscribe.value;
}

const alreadyContainUnsubscribe = computed(() => {
  const regexp = new RegExp('{{Unsubscribe:(.+?)}}', 'g')
  const unsubLinks = props.content.match(regexp)
  return Array.isArray(unsubLinks) && unsubLinks.length > 0
});

const insertItem = (content: string) => {
  if (content === UNSUBSCRIBE_PLACEHOLDER) {
    content = `{{Unsubscribe:${i18n.t('mail.optOutLink')}}}`
  }
  emit('insertItem', content);
  isShow.value = false;
};

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BCard = _resolveComponent("BCard")!
  const _component_BMenu = _resolveComponent("BMenu")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_BMenu, {
    modelValue: isShow.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((isShow).value = $event)),
    placement: "bottom-end"
  }, {
    activator: _withCtx(() => [
      _createVNode(_component_BBtn, {
        class: "placeholder-menu-button",
        size: "mini",
        fab: "",
        shadow: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BIcon, { size: "small" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('add')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_BCard, {
        class: "placeholder-menu",
        small: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('placeholderMenu.title')), 1),
            _createVNode(_component_BTooltip, {
              bottom: "",
              content: _ctx.$t('placeholderMenu.description')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BIcon, { size: "small" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('help')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["content"])
          ]),
          _createVNode(_component_BInput, {
            modelValue: searchText.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchText).value = $event)),
            class: "mb-300",
            placeholder: _ctx.$t('placeholderMenu.placeholder')
          }, null, 8, ["modelValue", "placeholder"]),
          _createElementVNode("div", _hoisted_3, [
            (isMatching.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredPlaceholders.value, (placeholderGroup) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: placeholderGroup.name
                    }, [
                      _createElementVNode("div", _hoisted_5, _toDisplayString(placeholderGroup.name), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(placeholderGroup.placeholders, (placeholder) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: placeholder.key
                        }, [
                          _createVNode(_component_BBtn, {
                            class: "placeholder-button",
                            flat: "",
                            fit: "",
                            position: "left",
                            disabled: disablePlaceholder(placeholder.key),
                            onClick: () => insertItem(placeholder.key)
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_6, [
                                _createElementVNode("div", null, _toDisplayString(placeholder.key), 1),
                                _createElementVNode("div", null, _toDisplayString(placeholder.name), 1)
                              ])
                            ]),
                            _: 2
                          }, 1032, ["disabled", "onClick"])
                        ]))
                      }), 128))
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('general.noCandidate')), 1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"])), [
    [_directive_loading, loading.value]
  ])
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-300" }
const _hoisted_2 = {
  key: 0,
  class: "import-confirm-list my-200"
}
const _hoisted_3 = { key: 0 }

import { ref } from 'vue';
import api from '@/api/admin/resources/salesforce';
  
  type TProps = {
    id: number;
  };

export default /*@__PURE__*/_defineComponent({
  __name: 'SalesforceObjectForm',
  props: {
    id: {}
  },
  setup(__props: any) {

const props = __props;
  
const loading = ref<boolean>(false)
const targetObject = ref<string>('')
const objectId = ref<string>('')
const object = ref<object>({})
  
const errorHandler = (error) => {
  object.value = {}
  loading.value = false
}
  
const search = async () => {
  loading.value = true
  object.value = {}
  const result = await api.searchSalesforceObject({
    objectId: objectId.value,
    targetObject: targetObject.value,
    clientId: props.id,
  }).catch(errorHandler)
  if (result == undefined) return;
  
  object.value = JSON.stringify(result.data.salesforce.object, null, 2)
  loading.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createBlock(_component_BLayout, {
    column: "",
    class: "my-600"
  }, {
    default: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('import.salesforce.idSearchTitle')), 1),
      _createVNode(_component_BLayout, { "align-center": "" }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('import.salesforce.nameTargetObject')), 1),
          _createVNode(_component_BInput, {
            modelValue: targetObject.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((targetObject).value = $event)),
            class: "mx-200",
            placeholder: _ctx.$t('general.search.placeholder'),
            disabled: loading.value
          }, null, 8, ["modelValue", "placeholder", "disabled"]),
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('import.salesforce.idObjectKey')), 1),
          _createVNode(_component_BInput, {
            modelValue: objectId.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((objectId).value = $event)),
            class: "mx-200",
            placeholder: _ctx.$t('general.search.placeholder'),
            disabled: loading.value
          }, null, 8, ["modelValue", "placeholder", "disabled"]),
          _createVNode(_component_BBtn, {
            size: "small",
            type: "primary",
            disabled: loading.value,
            onClick: search
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.search.exec')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      }),
      (Object.keys(object.value).length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (Object.keys(object.value).length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("pre", null, _toDisplayString(object.value), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})
import { AxiosResponse } from 'axios'
import ApiService, { ApiArguments } from '@/api/api_service';
import ApiBase from '@/api/base'
import { MiitelAuthApi } from '@/api/openapi';

// TODO: 適切な型付け
export default {
  getMiitelAuth (): Promise<AxiosResponse<any>> {
    return ApiBase.get(`/user/miitel_auth`)
  },
  updateMiitelAuth (args: any): Promise<AxiosResponse<any>> {
    const { id } = args
    return ApiBase.put(`/user/miitel_auths/${id}`, args)
  },
}

export class MiitelAuthApiService extends ApiService {
  api = new MiitelAuthApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async deleteMiitelAuth(
    apiArguments?: ApiArguments<undefined>,
  ): Promise<
      AxiosResponse<boolean>
    > {
    return this.checkResponse<boolean>(
      this.api.deleteMiitelAuth(apiArguments?.options),
      apiArguments?.errorHandlers,
    );
  }
}

<template>
  <BTooltip
    top
    :content="tooltipMessage"
    :disabled="disabled"
  >
    <BBtn
      class="mr-200"
      :disabled="disabled"
      size="small"
      fab
      flat
      @click="$emit('click')"
    >
      <BCustomIcon
        icon-class="b-call-call"
      />
    </BBtn>
  </BTooltip>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    tooltipMessage: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'click',
  ],
})
</script>

<style lang="scss" scoped>
.b-icon {
  font-size: 20px;
}

img {
  height: 20px;
}
.miitel-icon {
  height: 12px;

  &.disabled {
    filter: grayscale(100%);
  }
}
</style>

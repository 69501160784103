import Api from '@/api/user'
import AuthService from './auth_service'

export default class UserService {
  constructor () {
    this.authService = new AuthService()
  }
  async signinUser (email, password) {
    return this.authService.signinWithEmailAndPassword(email.trim(), password).then(() => {
      return this.signin();
    })
  }
  async signin () {
    return Api.signinUser().then((res) => {
      this.authService.changeRole(res.data.role)
    })
  }
  async signout () {
    try {
      await Api.signoutUser()
      await this.authService.signout()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

import { watch } from 'vue';
import { useCustomActions } from '@/composable/user/customAction/custom-actions';

export type CustomActionOptionValue = {
  customActionId: number;
  customActionOptionId: number;
};

type TEmit = {
  'update:modelValue': [value: CustomActionOptionValue[]];
  'afterFetch': [itemCount: number];
};
type TProps = {
  modelValue: CustomActionOptionValue[];
};

export default /*@__PURE__*/_defineComponent({
  __name: 'CustomActionForm',
  props: {
    modelValue: {}
  },
  emits: ["update:modelValue", "afterFetch"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const { customActions } = useCustomActions();
watch(customActions, () => {
  emit('afterFetch', customActions.value.length);
});

const customActionOptionId = (customActionId: number) => {
  return props.modelValue.find((v) => v.customActionId === customActionId)?.customActionOptionId || null;
};

const updateModelValue = (customActionId: number, customActionOptionId: number) => {
  const modelValue = props.modelValue.filter((e) => e.customActionId !== customActionId).concat([{ customActionId, customActionOptionId }]);
  emit('update:modelValue', modelValue)
};

return (_ctx: any,_cache: any) => {
  const _component_BSelect = _resolveComponent("BSelect")!
  const _component_BListItem = _resolveComponent("BListItem")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(customActions), (customAction) => {
    return (_openBlock(), _createElementBlock("div", {
      key: customAction.id
    }, [
      _createVNode(_component_BListItem, { class: "mb-300" }, {
        header: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(customAction.name), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_BSelect, {
            "model-value": customActionOptionId(customAction.id),
            items: customAction.customActionOptions,
            "item-text": "name",
            "item-value": "id",
            fit: "",
            "onUpdate:modelValue": (customActionOptionId) => updateModelValue(customAction.id, customActionOptionId)
          }, null, 8, ["model-value", "items", "onUpdate:modelValue"])
        ]),
        _: 2
      }, 1024)
    ]))
  }), 128))
}
}

})
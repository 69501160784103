import { SfCampaignService } from '@/api/user/resources/salesforce/sf_campaign';
import Waiting from '@/store/waiting'

export default class SfCampaignActions {
  constructor(Api, types) {
    const actions = {
      async getSfCampaignsAction({ commit }, args) {
        await waiting.check('getSfCampaignsAction', async () => {
          try {
            const api = new SfCampaignService();
            const res = await api.getSfCampaigns()
            commit(types.SET_SF_CAMPAIGNS, Object.values(res.data))
          } catch (err) {
            commit(types.CLEAR_SF_CAMPAIGNS)
            throw err
          }
        })
      },
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

<template>
  <div 
    v-loading="$wait.is(keyOfGetReserveItemsWait)" 
    class="reserve-card"
  >
    <div 
      v-for="directMail in reserveItems" 
      :key="`reserve-${directMail.id}`" 
      class="reserve-list"
      @click="() => handleDirectMailConfirm(directMail)"
    >
      <DirectMailItem 
        :direct-mail="directMail" 
        display-datetime-column="reservedAt"
        @click:confirm="() => handleDirectMailConfirm(directMail)"
        @after-delete="handleDirectMailReload"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ReserveDirectMail } from '@/api/openapi';
import { isRelatedToSequence } from '@/composable/user/directMail/direct-mail';
import DirectMailItem from './general/DirectMail.vue';

type TProps = {
  reserveItems: ReserveDirectMail[];
  keyOfGetReserveItemsWait: string;
};
type TEmits = {
  (event: 'click:directMailConfirm', directMail: ReserveDirectMail): void,
  (event: 'afterDeleteDirectMail'): void,
  (event: 'click:sequenceAction', sequenceInstanceId: number): void
};

defineProps<TProps>();

const emit = defineEmits<TEmits>()

const handleDirectMailConfirm = (directMail: ReserveDirectMail) => {
  if (isRelatedToSequence(directMail)) {
    emit('click:sequenceAction', directMail.sequenceInstanceId)
  } else {
    emit('click:directMailConfirm', directMail)
  }
};
const handleDirectMailReload = () => { emit('afterDeleteDirectMail') };
</script>

<style lang="scss" scoped>
.reserve-card {
  background-color: $bgcolor-white;
  padding: $basespace-100 0;
}

.reserve-list {
  transition: all 300ms $standard-easing;
  padding: $basespace-100 $basespace-600;

  &:hover {
    background: $bgcolor-base;
    cursor: pointer;
  }
}
</style>


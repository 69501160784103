import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "fs-400 fw-bold" }

import { computed, inject, nextTick, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { SequenceMasterApiService } from '@/api/user/resources/sequence_master';
import SequenceFooter from '@/components/organisms/user/general/sequence/SequenceFooter.vue';
import SequenceMaster from '@/components/organisms/user/sequence/Sequence.vue';
import SequenceSteps from '@/components/organisms/user/sequence/SequenceSteps.vue'
import { useUrlLinkBtabsHandlers } from '@/composable/url-link-b-tabs';
import { useChanged } from '@/composable/user/sequence/changed';
import { usePostPutBody } from '@/composable/user/sequence/post-put-body';
import { useSequenceMaster } from '@/composable/user/sequence/sequence-master';
import { useWait } from '@/composable/vue-wait';
import { i18nGlobal as i18n } from '@/i18n';
import { KEY_OF_WAIT } from '@/injection-keys';

const WAIT_FETCH_SEQUENCE_MASTER = 'fetchSequenceMaster';

export default /*@__PURE__*/_defineComponent({
  __name: 'Sequence',
  setup(__props) {

const wait = inject(KEY_OF_WAIT);
const route = useRoute()
const sequenceMasterId = parseInt(route.params.id as string);

const tabLabelItems = [
  { label: i18n.t('sequence.step.title'), slotName: 'step' },
  { label: i18n.t('sequence.lead'), slotName: 'lead', route: { name: 'SequenceLeads', params: { id: sequenceMasterId } } },
];

onMounted(async () => {
  await fetchSequenceMaster(WAIT_FETCH_SEQUENCE_MASTER);
});

const waitingForSequenceMaster = computed(() => sequenceMaster.value == null);

const activeName = ref('step');
const loading = computed(() => wait.is([WAIT_FETCH_SEQUENCE_MASTER, 'fetchSequenceStepMasters', 'putSequenceMaster']));

const api = new SequenceMasterApiService();
const {
  doActionWithWait,
} = useWait();
const {
  sequenceBodyData,
  sequenceStepsBodyData,
  canSave,
  handleUpdateSequence,
  handleUpdateSequenceSteps,
} = usePostPutBody();
const {
  handleUpdateSequenceChanged,
  handleUpdateSequenceStepsChanged,
  needConfirmationToLeave,
} = useChanged();
const {
  sequenceMaster,
  fetchSequenceMaster,
} = useSequenceMaster(sequenceMasterId);
const { handleTabClick, handleTabBeforeLeave } = useUrlLinkBtabsHandlers(tabLabelItems, needConfirmationToLeave);

const renderComponents = ref(true);
const reRenderComponents = async () => {
  renderComponents.value = false;
  await nextTick();
  renderComponents.value = true;
};

const handleClickSave = async () => {
  if (!canSave.value) return;

  await doActionWithWait('putSequenceMaster', async () => {
    await api.putSequenceMaster({
      request: {
        sequenceMasterId,
        postSequenceMasterBody: {
          sequence: sequenceBodyData.value,
          sequenceSteps: sequenceStepsBodyData.value,
        },
      },
    });
  });

  // NOTE: fetchSequenceMasterの後に実行するとステップのComponentが正しく再描画されないため、先に実行（nextTickがうまく動かなくなるものと思われる）
  reRenderComponents();
  fetchSequenceMaster(WAIT_FETCH_SEQUENCE_MASTER)
};

return (_ctx: any,_cache: any) => {
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_BTabs = _resolveComponent("BTabs")!
  const _component_BContent = _resolveComponent("BContent")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_BContent, null, {
    navbar: _withCtx(() => [
      _createVNode(_component_BLayout, {
        "align-start": "",
        "justify-start": ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('sequence.title')), 1)
        ]),
        _: 1
      })
    ]),
    footer: _withCtx(() => [
      _createVNode(SequenceFooter, {
        "can-save": _unref(canSave),
        loading: loading.value,
        "need-confirmation-to-leave": _unref(needConfirmationToLeave),
        "onClick:save": handleClickSave
      }, null, 8, ["can-save", "loading", "need-confirmation-to-leave"])
    ]),
    waiting: _withCtx(() => [
      _withDirectives(_createVNode(_component_BPlaceholder, { "element-loading-background": "transparent" }, null, 512), [
        [_directive_loading, true]
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(SequenceMaster, {
        "sequence-master": _unref(sequenceMaster),
        "wait-for": WAIT_FETCH_SEQUENCE_MASTER,
        onUpdate: _unref(handleUpdateSequence),
        "onUpdate:changed": _unref(handleUpdateSequenceChanged)
      }, null, 8, ["sequence-master", "onUpdate", "onUpdate:changed"]),
      _createVNode(_component_BTabs, {
        modelValue: activeName.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((activeName).value = $event)),
        class: "tabs",
        "label-items": tabLabelItems,
        "before-leave": _unref(handleTabBeforeLeave),
        "onClick:tab": _unref(handleTabClick)
      }, {
        step: _withCtx(() => [
          (renderComponents.value && !waitingForSequenceMaster.value)
            ? (_openBlock(), _createBlock(SequenceSteps, {
                key: 0,
                "sequence-master-id": _unref(sequenceMasterId),
                "sequence-owner-id": _unref(sequenceMaster)?.owner?.id,
                editable: "",
                onUpdate: _unref(handleUpdateSequenceSteps),
                "onUpdate:changed": _unref(handleUpdateSequenceStepsChanged)
              }, null, 8, ["sequence-master-id", "sequence-owner-id", "onUpdate", "onUpdate:changed"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue", "before-leave", "onClick:tab"])
    ]),
    _: 1
  })), [
    [_directive_loading, loading.value]
  ])
}
}

})
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "corp-setting-main" }
const _hoisted_2 = { class: "corp-setting-sidebar" }
const _hoisted_3 = { class: "corp-setting-menu" }
const _hoisted_4 = {
  key: 0,
  class: "hr"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "corp-setting-content" }
const _hoisted_7 = { class: "modal-tab-box" }
const _hoisted_8 = { class: "tab-header" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 1,
  class: "tab-item active"
}
const _hoisted_11 = {
  key: 2,
  class: "header-submenu-area"
}
const _hoisted_12 = { class: "header-submenu" }
const _hoisted_13 = { class: "py-100" }
const _hoisted_14 = { class: "py-100" }
const _hoisted_15 = { class: "tab-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BHeightAdjuster = _resolveComponent("BHeightAdjuster")!
  const _component_BCustomIcon = _resolveComponent("BCustomIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BCard = _resolveComponent("BCard")!
  const _component_BMenu = _resolveComponent("BMenu")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_Account = _resolveComponent("Account")!
  const _component_Member = _resolveComponent("Member")!
  const _component_UserProfile = _resolveComponent("UserProfile")!
  const _component_UserEmail = _resolveComponent("UserEmail")!
  const _component_UserSecurity = _resolveComponent("UserSecurity")!
  const _component_LeadStageItem = _resolveComponent("LeadStageItem")!
  const _component_LeadSourceItem = _resolveComponent("LeadSourceItem")!
  const _component_FreeTagItem = _resolveComponent("FreeTagItem")!
  const _component_CustomFieldItem = _resolveComponent("CustomFieldItem")!
  const _component_NextActionItem = _resolveComponent("NextActionItem")!
  const _component_CallResultItem = _resolveComponent("CallResultItem")!
  const _component_CustomActionItem = _resolveComponent("CustomActionItem")!
  const _component_QuickTexts = _resolveComponent("QuickTexts")!
  const _component_SequenceSetting = _resolveComponent("SequenceSetting")!
  const _component_SalesforceApi = _resolveComponent("SalesforceApi")!
  const _component_TodoSync = _resolveComponent("TodoSync")!
  const _component_SalesforceApiMap = _resolveComponent("SalesforceApiMap")!
  const _component_SalesforceApiUser = _resolveComponent("SalesforceApiUser")!
  const _component_SalesforceErrorLogs = _resolveComponent("SalesforceErrorLogs")!
  const _component_SalesforceScheduleImport = _resolveComponent("SalesforceScheduleImport")!
  const _component_MiitelApi = _resolveComponent("MiitelApi")!
  const _component_ZoomApi = _resolveComponent("ZoomApi")!
  const _component_BoxilApi = _resolveComponent("BoxilApi")!
  const _component_CustomImportModal = _resolveComponent("CustomImportModal")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BHeightAdjuster, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([ _ctx.tabUsers, _ctx.tabItemSetting, _ctx.tabSystemSetting, _ctx.tabApiSetting ], (currentTabs, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                (index !== 0 && _ctx.someTabEnabled(currentTabs))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentTabs, (item) => {
                  return _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: item.tabType,
                    class: _normalizeClass(["list-item", { active: _ctx.isCurrentTab(item.tabType) }]),
                    onClick: () => _ctx.setTabsToPath(item.tabType)
                  }, _toDisplayString(item.text), 11, _hoisted_5)), [
                    [_vShow, item.tabAvailable]
                  ])
                }), 128))
              ], 64))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_BLayout, null, {
            default: _withCtx(() => [
              (_ctx.currentTab in _ctx.subTabs)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.subTabs[_ctx.currentTab], (tab, index) => {
                    return _withDirectives((_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: _normalizeClass(["tab-item", { active: _ctx.isCurrentSubTab(tab.tabType) }]),
                      onClick: () => _ctx.setTabsToPath(_ctx.currentTab, tab.tabType)
                    }, _toDisplayString(tab.tabTitle), 11, _hoisted_9)), [
                      [_vShow, tab.tabAvailable]
                    ])
                  }), 128))
                : (_ctx.currentTabTitle)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.currentTabTitle), 1))
                  : _createCommentVNode("", true),
              (_ctx.currentTab == 'customField')
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_BTooltip, {
                        top: "",
                        content: _ctx.$t('customField.import')
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createVNode(_component_BMenu, {
                              modelValue: _ctx.isImportMenuVisible,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isImportMenuVisible) = $event)),
                              placement: "bottom"
                            }, {
                              activator: _withCtx(() => [
                                _createVNode(_component_BCustomIcon, {
                                  class: "mr-100 ml-100",
                                  size: "small",
                                  "icon-class": "b-import",
                                  hover: ""
                                })
                              ]),
                              default: _withCtx(() => [
                                _createVNode(_component_BCard, { full: "" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_13, [
                                      _createVNode(_component_BBtn, {
                                        flat: "",
                                        fit: "",
                                        size: "small",
                                        onClick: _ctx.openModal
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('customField.importSelect.excel')), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["onClick"])
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["modelValue"])
                          ])
                        ]),
                        _: 1
                      }, 8, ["content"]),
                      (_ctx.isAvailableSalesforceLinkFeature)
                        ? (_openBlock(), _createBlock(_component_BTooltip, {
                            key: 0,
                            top: "",
                            content: _ctx.$t('customField.export')
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createVNode(_component_BMenu, {
                                  modelValue: _ctx.isExportMenuVisible,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isExportMenuVisible) = $event)),
                                  placement: "bottom"
                                }, {
                                  activator: _withCtx(() => [
                                    _createVNode(_component_BCustomIcon, {
                                      class: "mr-100 ml-100",
                                      size: "small",
                                      "icon-class": "b-export",
                                      hover: ""
                                    })
                                  ]),
                                  default: _withCtx(() => [
                                    _createVNode(_component_BCard, { full: "" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_14, [
                                          _createVNode(_component_BBtn, {
                                            flat: "",
                                            fit: "",
                                            size: "small",
                                            onClick: _ctx.exportSalesforceLeadObjects
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('customField.exportSelect.sfLead')), 1)
                                            ]),
                                            _: 1
                                          }, 8, ["onClick"]),
                                          _createVNode(_component_BBtn, {
                                            flat: "",
                                            fit: "",
                                            size: "small",
                                            onClick: _ctx.exportSalesforceContactObjects
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('customField.exportSelect.sfContact')), 1)
                                            ]),
                                            _: 1
                                          }, 8, ["onClick"]),
                                          _createVNode(_component_BBtn, {
                                            flat: "",
                                            fit: "",
                                            size: "small",
                                            onClick: _ctx.exportSalesforceAccountObjects
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('customField.exportSelect.sfAccount')), 1)
                                            ]),
                                            _: 1
                                          }, 8, ["onClick"])
                                        ])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ])
                            ]),
                            _: 1
                          }, 8, ["content"]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_15, [
          (_ctx.isCurrentTab(_ctx.tabUsers[0].tabType))
            ? (_openBlock(), _createBlock(_component_Account, {
                key: 0,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab(_ctx.tabUsers[1].tabType))
            ? (_openBlock(), _createBlock(_component_Member, {
                key: 1,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('userSetting') && _ctx.isCurrentSubTab('profile'))
            ? (_openBlock(), _createBlock(_component_UserProfile, {
                key: 2,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('userSetting') && _ctx.isCurrentSubTab('userEmail'))
            ? (_openBlock(), _createBlock(_component_UserEmail, {
                key: 3,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('userSetting') && _ctx.isCurrentSubTab('security'))
            ? (_openBlock(), _createBlock(_component_UserSecurity, {
                key: 4,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('leadStage'))
            ? (_openBlock(), _createBlock(_component_LeadStageItem, {
                key: 5,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('leadSource'))
            ? (_openBlock(), _createBlock(_component_LeadSourceItem, {
                key: 6,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('freeTag'))
            ? (_openBlock(), _createBlock(_component_FreeTagItem, {
                key: 7,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('customField'))
            ? (_openBlock(), _createBlock(_component_CustomFieldItem, {
                key: 8,
                ref: "customFieldItem",
                class: "tab-contents"
              }, null, 512))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('nextAction'))
            ? (_openBlock(), _createBlock(_component_NextActionItem, {
                key: 9,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('callResult'))
            ? (_openBlock(), _createBlock(_component_CallResultItem, {
                key: 10,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('customAction'))
            ? (_openBlock(), _createBlock(_component_CustomActionItem, {
                key: 11,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('quickText'))
            ? (_openBlock(), _createBlock(_component_QuickTexts, {
                key: 12,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('sequenceSetting'))
            ? (_openBlock(), _createBlock(_component_SequenceSetting, {
                key: 13,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('salesforce') && _ctx.isCurrentSubTab('salesforceRelationInfo'))
            ? (_openBlock(), _createBlock(_component_SalesforceApi, {
                key: 14,
                "salesforce-api-info": _ctx.salesforceApiInfo,
                class: "tab-contents",
                onReloadSalesforceApiInfo: _ctx.fetchSalesforceApiInfo
              }, null, 8, ["salesforce-api-info", "onReloadSalesforceApiInfo"]))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('salesforce') && _ctx.isCurrentSubTab('todoSync'))
            ? (_openBlock(), _createBlock(_component_TodoSync, {
                key: 15,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('salesforce') && _ctx.isCurrentSubTab('salesforceRelationMap'))
            ? (_openBlock(), _createBlock(_component_SalesforceApiMap, {
                key: 16,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('salesforce') && _ctx.isCurrentSubTab('salesforceRelationUser'))
            ? (_openBlock(), _createBlock(_component_SalesforceApiUser, {
                key: 17,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('salesforce') && _ctx.isCurrentSubTab('salesforceErrorLogs'))
            ? (_openBlock(), _createBlock(_component_SalesforceErrorLogs, {
                key: 18,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('salesforce') && _ctx.isCurrentSubTab('salesforceScheduleImport'))
            ? (_openBlock(), _createBlock(_component_SalesforceScheduleImport, {
                key: 19,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('miitel'))
            ? (_openBlock(), _createBlock(_component_MiitelApi, {
                key: 20,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('zoom'))
            ? (_openBlock(), _createBlock(_component_ZoomApi, {
                key: 21,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true),
          (_ctx.isCurrentTab('boxil'))
            ? (_openBlock(), _createBlock(_component_BoxilApi, {
                key: 22,
                class: "tab-contents"
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode(_component_CustomImportModal, { onRefresh: _ctx.refreshCustomFieldItems }, null, 8, ["onRefresh"])
    ])
  ])), [
    [_directive_loading, _ctx.loading || _ctx.currentTab == null]
  ])
}
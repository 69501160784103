import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "draft-card" }
const _hoisted_2 = ["onClick"]

import { DraftDirectMail } from '@/api/openapi/models/draft-direct-mail';
import { isRelatedToSequence } from '@/composable/user/directMail/direct-mail';
import DirectMailItem from './general/DirectMail.vue';

// NOTE: directMailしか含まれていない前提の構造なので、objectの種類が増えた時は修正する
type TProps = {
  draftItems: DraftDirectMail[];
};
type TEmits = {
  'click:sequenceAction': [sequenceInstanceId: number];
  'click:directMailConfirm': [directMail: DraftDirectMail];
  'afterDeleteDirectMail': [];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'DraftItemArea',
  props: {
    draftItems: {}
  },
  emits: ["click:sequenceAction", "click:directMailConfirm", "afterDeleteDirectMail"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit

const handleDirectMailConfirm = (directMail: DraftDirectMail) => {
  if (isRelatedToSequence(directMail)) {
    emit('click:sequenceAction', directMail.sequenceInstanceId)
  } else {
    emit('click:directMailConfirm', directMail)
  }
};
const handleDirectMailReload = () => { emit('afterDeleteDirectMail') };

return (_ctx: any,_cache: any) => {
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.draftItems, (directMail) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `draft-${directMail.id}`,
        class: "draft-list",
        onClick: () => handleDirectMailConfirm(directMail)
      }, [
        _createVNode(DirectMailItem, {
          "direct-mail": directMail,
          "onClick:confirm": () => handleDirectMailConfirm(directMail),
          onAfterDelete: handleDirectMailReload
        }, null, 8, ["direct-mail", "onClick:confirm"])
      ], 8, _hoisted_2))
    }), 128))
  ])), [
    [_directive_loading, _ctx.$wait.is(['updateNextActionWait', 'getNextActionsWait', 'doneNextActionWait'])]
  ])
}
}

})
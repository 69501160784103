<template>
  <FilterDetailPopover 
    v-model:is-include="isInclude" 
    :title="title" 
    :is-display-only="isDisplayOnly" 
    :key-name="keyName" 
    @hide="popoverHide" 
    @show="popoverShow"
  >
    <template #reference>
      <BLayout align-center>
        <BListItem>
          <template #header>
            <BLayout justify-space-between>
              <div>{{ title }}</div>
            </BLayout>
          </template>
          <div class="priority">
            {{ valueForDisplay }}
          </div>
        </BListItem>
      </BLayout>
    </template>
    <div>
      <FilterItemCheckbox 
        v-model:checked-item="checkedItem" 
        :candidate-items="candidateItems"
        :is-search-text="isSearchText" 
        no-blank-item 
      />
    </div>
  </FilterDetailPopover>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { PriorityPriorityEnum } from '@/api/openapi';
import FilterDetailPopover from '@/components/organisms/user/leadList/filter/FilterDetailPopover.vue'
import FilterItemCheckbox from '@/components/organisms/user/leadList/filter/FilterItemCheckbox.vue'
import { i18nGlobal as i18n } from '@/i18n';
import priorityWithoutNotSet from '@/plugins/biscuet-materials/components/molecules/priority';

type TProps = {
  leadListFilterParams?: Record<string, {
    inc: {
      or: string[];
    };
    exc: {
      or: string[];
    };
  }>;
  isDisplayOnly?: boolean;
  keyName: string;
}
const props = withDefaults(defineProps<TProps>(), {
  leadListFilterParams: null,
  isDisplayOnly: false,
})

const emit = defineEmits<TEmits>();
type TEmits = {
  'hide': [value: Record<string, { inc: { or: string[]; }; }>];
}
const isInclude = ref(true);
const checkedItem = ref([]);
const isSearchText = ref(false);

const title = computed(() => i18n.t(`filterSelect.nextActions.item.${props.keyName}`));

const candidateItems = computed(() => {
  return priorityWithoutNotSet.map((priority) => ({
    label: '',
    value: priority,
    labelPrefixComponent: 'BPriorityPreview',
    labelPrefixAttributes: { size: 'small', priority, class: 'mr-50' },
  }));
})

const valueForDisplay = computed(() => {
  if (target.value == null || targetDetail.value.length === 0) return '-'
  const values = targetDetail.value.map((item) => {
    return i18n.t(`general.priority.${item}`)
  })
  return values.join(' / ')
})

const target = computed(() => {
  if (props.leadListFilterParams == null) return null
  return props.leadListFilterParams[props.keyName];
})

const targetDetail = computed(() => {
  if (target.value == null) return []
  return target.value.inc.or
})

const popoverShow = () => {
  checkedItem.value = targetDetail.value
  isInclude.value = true
} 

const popoverHide = () => {
  emit('hide', { inc: { or: checkedItem.value } })
}
</script>

<template>
  <div class="b-switch-btn">
    <BLayout wrap>
      <BFlex :span="12">
        <div
          class="b-switch-btn-item left"
          :class="trueClasses"
          @click="handleStatus(true)"
        >
          {{ trueText || $t('general.filter.present') }}
        </div>
      </BFlex>
      <BFlex :span="12">
        <div
          class="b-switch-btn-item right"
          :class="falseClasses"
          @click="handleStatus(false)"
        >
          {{ falseText || $t('general.filter.notPresent') }}
        </div>
      </BFlex>
    </BLayout>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: [Boolean],
    trueText: String,
    trueColor: {
      type: String,
      default: 'hot',
    },
    falseText: String,
    falseColor: {
      type: String,
      default: 'cold',
    },
  },
  emits: [
    'update:modelValue',
  ],
  computed: {
    internalValue: {
      get () {
        return this.modelValue
      },
      set (newVal) {
        if (this.modelValue !== newVal) this.$emit('update:modelValue', newVal)
      },
    },
    trueClasses() {
      return {
        'selected': this.isSelected(true),
        'hot-color': this.trueColor === 'hot',
        'success-color': this.trueColor === 'success',
      }
    },
    falseClasses() {
      return {
        'selected': this.isSelected(false),
        'cold-color': this.falseColor === 'cold',
        'concrete-dark-color': this.falseColor === 'concrete-dark',
      }
    },
  },
  methods: {
    isSelected (bool) {
      return this.internalValue === bool
    },
    handleStatus (bool) {
      this.internalValue = bool
    },
  },
}
</script>

<style lang="scss" scoped>
  .b-switch-btn-item {
    width: 100%;
    height: 26px;
    text-align: center;
    border: 1px solid $bdcolor-base;
    padding: 4px $basespace-100;
    cursor: pointer;
    transition: all 200ms $standard-easing;
    color: $bdcolor-dark;
    font-size: $fontsize-100;

    &.left {
      border-radius: 4px 0 0 4px;

      &.selected {
        &.hot-color {
          color: $basecolor-hot;
          border-color: $basecolor-hot;
        }
        &.success-color {
          color: $basecolor-success;
          border-color: $basecolor-success;
        }
      }

      &:not(.selected):hover {
        &.hot-color {
          border-color: rgba($basecolor-hot, .6);
          color: $basecolor-hot;
        }
        &.success-color {
          border-color: rgba($basecolor-success, .6);
          color: $basecolor-success;
        }
      }
    }

    &.right {
      border-radius: 0 4px 4px 0;

      &.selected {
        &.cold-color {
          color: $basecolor-cold;
          border-color: $basecolor-cold;
        }
        &.concrete-dark-color {
          color: $concrete-dark;
          border-color: $concrete-dark;
        }
      }

      &:not(.selected):hover {
        &.cold-color {
          border-color: rgba($basecolor-cold, .6);
          color: $basecolor-cold;
        }
        &.concrete-dark-color {
          border-color: rgba($concrete-dark, .6);
          color: $concrete-dark;
        }
      }
    }
  }
</style>

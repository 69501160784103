<template>
  <BIconWithActionMenu
    :badge-count="mailRemindItems.length"
    :auto-fetch-interval="30000"
    @auto-fetch-interval-passed="fetchMailReminder"
    @after-open="handleAfterOpen"
  >
    <template #icon>
      <BCustomIcon icon-class="b-mail-remind" />
    </template>
    <div class="mail-reminder-header">
      <div class="mail-reminder-header-item header-title">
        {{ $t('reservedMailReminder.title') }}
      </div>
      <div class="mail-reminder-header-item number-of-items">
        <span>{{ mailRemindItems.length }}件</span>
      </div>
    </div>
    <div class="mail-reminder-body">
      <ReservedMailReminderTimeline
        :items="mailRemindItems"
      />
    </div>
  </BIconWithActionMenu>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import { CallTargetApiGetMailRemindItemsRequest, MailRemindItem } from '@/api/openapi';
import apis from '@/api/user';
import { CallTargetApiService } from '@/api/user/resources/call_target';

const mailRemindItems = ref<MailRemindItem[]>([]);
const currentUserId = ref<number>();

const handleAfterOpen = () => {
  fetchMasterData();
}

onBeforeMount(async () => {
  await fetchCurrentUser();
  await fetchMasterData();
})

const fetchMailReminder = async () => {
  const userId = currentUserId.value;
  const reserved = true;
  const request: CallTargetApiGetMailRemindItemsRequest = {
    userId,
    reserved,
  }
  const callTargetApi = new CallTargetApiService()
  mailRemindItems.value = (await callTargetApi.getMailRemindItems({ request })).data.mailRemindItems
}

const fetchCurrentUser = async () => {
  const res = await apis.getCurrentUser();
  currentUserId.value = res.data.currentUser.id;
};

const fetchMasterData = async () => {
  await fetchMailReminder();
}
</script>

<style lang="scss" scoped>
.mail-reminder-header {
  display: flex;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid $bdcolor-base;
  padding: 0 $basespace-200;

  &-item {
    margin: $basespace-250 0;

    &.header-title {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      margin: $basespace-500 0;
    }

    &.number-of-items {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      margin: $basespace-500 0;

      span {
        display:inline-block;
        vertical-align: middle;
        text-align: center;
        width: 54px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 0 $basespace-400;
      }
    }

    .type-select {
      width: 160px;
      height: 36px;
    }
  }
}
 .mail-reminder-body {
    @include m-fixed-height(calc(580px - 70px - 60px));
    overflow-y: auto;
    overflow-x: auto;
  }
</style>

import ApiBase from '@/api/base'

export default {
  getLeadViews (args) {
    return ApiBase.get(`/user/lead_views`, args)
  },
  getLeadView (args) {
    const { leadViewId } = args
    return ApiBase.get(`/user/lead_views/${leadViewId}`, args)
  },
  createLeadView (args) {
    return ApiBase.post(`/user/lead_views`, args)
  },
  updateLeadView (args) {
    const { leadViewId } = args
    return ApiBase.put(`/user/lead_views/${leadViewId}`, args)
  },
  deleteLeadView (args) {
    const { leadViewId } = args
    return ApiBase.delete(`/user/lead_views/${leadViewId}`, args)
  },
}

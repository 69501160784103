<template>
  <div
    v-loading="$wait.is('confirmCustomFieldDestroyTaskTaskWait')"
    class="confirm-custom-field-destroy-status"
  >
    <ImportStatus
      v-for="task in customFieldDestroyTasks"
      :key="task.id"
      :target-task="task"
      :progress-message="$t('customField.destroyTask.status.progress', { customFieldName: task.customFieldName })"
      :error-message="$t('customField.destroyTask.status.error', { customFieldName: task.customFieldName })"
      :can-show-error-modal="false"
      :success-message="$t('customField.destroyTask.status.success', { customFieldName: task.customFieldName })"
      @confirm="() => confirmTask(task.id)"
    />
  </div>
</template>

<script lang="ts" setup>
import { AxiosResponse } from 'axios';
import { onBeforeMount, onBeforeUnmount, ref } from 'vue';
import { CustomFieldDestroyTask } from '@/api/openapi';
import { CustomFieldDestroyTaskApiService } from '@/api/user/resources/custom_field_destroy_task';
import ImportStatus from '@/components/organisms/user/leadImport/ImportStatus.vue';
import { useWait } from '@/composable/vue-wait';

const {
  doActionWithWait,
} = useWait();
const api = new CustomFieldDestroyTaskApiService();

const timeObj = ref<NodeJS.Timeout | null>(null);
const customFieldDestroyTasks = ref<CustomFieldDestroyTask[]>([]);

onBeforeMount(() => {
  fetchCustomFieldDestroyTasks();
  timeObj.value = setInterval(() => {
    fetchCustomFieldDestroyTasks();
  }, 30000)
});
onBeforeUnmount(() => {
  clearInterval(timeObj.value);
});

const fetchCustomFieldDestroyTasks = async () => {
  const { data } = await doActionWithWait<AxiosResponse<GetCustomFieldDestroyTasks200Response>>('getCustomFieldDestroyTaskTasksWait', async () => {
    return await api.getCustomFieldDestroyTasks({});
  });

  console.log('customFieldDestroyTasks', data, new Date());
  customFieldDestroyTasks.value = data;
};

const confirmTask = async (customFieldDestroyTaskId: number) => {
  await doActionWithWait('confirmCustomFieldDestroyTaskTaskWait', async () => {
    await api.confirmCustomFieldDestroyTask({ request: { customFieldDestroyTaskId } });
  });
  await fetchCustomFieldDestroyTasks();
};
</script>

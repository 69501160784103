<template>
  <CallButton
    :tooltip-message="tooltipMessage"
    :disabled="disabled"
    @click="handleCall"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import CallButton from './CallButton.vue'
// import call from '@/assets/lottie/incoming_call.json'

export default defineComponent({
  components: {
    CallButton,
  },
  props: {
    phoneNumber: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters('miitel', [
      'isCallable',
      'isCalling',
    ]),
    ...mapGetters('userUi', [
      'miitelWidgetVisible',
    ]),
    tooltipMessage(): string {
      if (this.isCalling) {
        return this.$t('miitel.busy')
      } else if (this.disabled) {
        return this.$t('miitel.notStarted')
      } else {
        return this.$t('miitel.call')
      }
    },
    isValidPhoneNumber(): boolean {
      return typeof this.phoneNumber === 'string' && this.phoneNumber.length > 0
    },
    disabled(): boolean {
      return !this.isValidPhoneNumber || !this.miitelWidgetVisible || this.isCalling
    },
  },
  methods: {
    handleCall(): void {
      if (this.disabled) {
        this.$toast.show({
          message: this.$t('miitel.notStarted'),
          type: 'error',
        })
        return
      }

      // 発信
      window.miitelWidget.call(this.phoneNumber)
    },
  },
})
</script>

import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "trigger-action-card-container" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "more-menu" }
const _hoisted_4 = { class: "body" }
const _hoisted_5 = { class: "mt-100" }
const _hoisted_6 = {
  key: 0,
  class: "footer"
}
const _hoisted_7 = { class: "label ml-100" }
const _hoisted_8 = { class: "approve-user ml-100" }

import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n'
import { TriggerActionBody } from '@/api/openapi';
import { useUsers } from '@/composable/user/user/users';
import { TTriggerActionFormMode } from './types';

type TProps = {
  triggerAction: TriggerActionBody | null;
  editable: boolean;
};
type TEmits = {
  'click:setting': [mode: TTriggerActionFormMode];
};

export default /*@__PURE__*/_defineComponent({
  __name: 'TriggerActionCard',
  props: {
    triggerAction: {},
    editable: { type: Boolean }
  },
  emits: ["click:setting"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const i18n = useI18n();
const isMenuVisible = ref<boolean>();

const clickMenuButton = (mode: TTriggerActionFormMode) => {
  isMenuVisible.value = false;
  emit('click:setting', mode);
};

const actionDescription = computed(() => {
  let description = '';
  switch (props.triggerAction?.actionType) {
    case 'sequence_register':
      description = i18n.t(`trigger.actionTypes.${props.triggerAction?.actionType}`);
      break;
  }
  return description;
});

const { users } = useUsers()
const approveUser = computed(() => {
  return users.value.find((user) => user.id === props.triggerAction?.approveUserId);
})

return (_ctx: any,_cache: any) => {
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BMoreMenu = _resolveComponent("BMoreMenu")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BUserTooltip = _resolveComponent("BUserTooltip")!
  const _component_BCard = _resolveComponent("BCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BCard, { border: "" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('trigger.action')), 1),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BMoreMenu, {
              modelValue: isMenuVisible.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((isMenuVisible).value = $event)),
              left: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BBtn, {
                  flat: "",
                  fit: "",
                  position: "center",
                  size: "small",
                  disabled: !_ctx.editable,
                  onClick: _cache[0] || (_cache[0] = () => clickMenuButton('form'))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('general.edit.text')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_BBtn, {
                  flat: "",
                  fit: "",
                  position: "center",
                  size: "small",
                  onClick: _cache[1] || (_cache[1] = () => clickMenuButton('confirm'))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('trigger.actionSetting.detail')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_BListItem, { class: "list-item" }, {
            header: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('trigger.actionSetting.actionType')), 1)
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, _toDisplayString(actionDescription.value), 1)
            ]),
            _: 1
          })
        ]),
        (approveUser.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_BUserTooltip, {
                "image-url": approveUser.value.avatarUrl,
                name: approveUser.value.name,
                "user-id": approveUser.value.id,
                size: 30
              }, null, 8, ["image-url", "name", "user-id"]),
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('trigger.actionSetting.approveUser')), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(approveUser.value?.name || ''), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})
import actions from './actions'
import getters from './getters'
import { mutations } from './mutations'

const store = {
  namespaced: true,
  state: {
    currentUser: {},
    admin: {},
    admins: [],
    user: {},
    users: [],
    client: {},
    clients: [],
    clientLimitSetting: {},
    clientAvailableFeatures: {
      feature: {},
      betaFeature: {},
    },
    clientSendgridAuth: {},
    mailVerificationInfo: {
      unverifiedMailCount: 0,
      mailVerifications: [],
    },
    mailVerificationBalance: 0,
    sendgridSubUsers: [],
    sendgridApiKeys: [],
    clientSendgridApiKey: {},
  },
  mutations,
  actions,
  getters,
}

export default store

import Waiting from '@/store/waiting'

export default class MailNotificationActions {
  constructor(Api, types) {
    const actions = {
      async getMailNotificationAction({ commit }, args) {
        await waiting.check('getMailNotificationAction', async () => {
          try {
            const res = await Api.getMailNotifications(args)
            commit(types.SET_MAIL_NOTIFICATIONS, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
      async postMailNotificationConfirmedAction({ dispatch }, args) {
        await waiting.check('postMailNotificationConfirmedAction', async () => {
          try {
            Api.postMailNotificationConfirmed(args)
            await dispatch('getMailNotificationCountAction')
          } catch (err) {
            throw err
          }
        })
      },
      async getMailNotificationCountAction({ commit }, args) {
        await waiting.check('getMailNotificationCountAction', async () => {
          try {
            const res = await Api.getMailNotificationCount(args)
            commit(types.SET_MAIL_NOTIFICATION_COUNT, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

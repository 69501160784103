import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-drawer" }

import { computed, ref } from 'vue';
import ReservedAt from '@/components/organisms/user/mail/common/drawer/input/ReservedAt.vue'
import MailDrawerTemplate from '@/components/organisms/user/mail/common/drawer/MailDrawerTemplate.vue'
import { formatDateTime } from '@/utils/date-time';
import { TPartialFormLeadDirectMail, SenderData } from '../types';
import FormDrawerBodyCommon from './FormDrawerBodyCommon.vue';

type TProps = {
  modelValue: TPartialFormLeadDirectMail;
  isLoading: boolean;
  callTargetId: number | null;
  title: string;
  isAvailableMailTemplateFeature: boolean;
  senderData: SenderData;
};
type TEmit = {
  (event: 'update:modelValue', value: TPartialFormLeadDirectMail): void;
  (event: 'cancel'): void;
  (event: 'save-draft'): void;
  (event: 'change-current-state-to-confirm'): void;
  (event: 'close-openai', cancel: () => void): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'FormDrawer',
  props: {
    modelValue: {},
    isLoading: { type: Boolean },
    callTargetId: {},
    title: {},
    isAvailableMailTemplateFeature: { type: Boolean },
    senderData: {}
  },
  emits: ["update:modelValue", "cancel", "save-draft", "change-current-state-to-confirm", "close-openai"],
  setup(__props: any, { emit: __emit }) {

const props =  __props;
const emit = __emit;

const isValidFormValuesCommon = ref(false);
const isValidReservedAt = ref(false);

const reservedAt = computed({
  get: () => props.modelValue.reservedAt,
  set: (v) => updateFormValues('reservedAt', formatDateTime(v)),
});

const isValid = computed(() => isValidFormValuesCommon.value && isValidReservedAt.value);

const handleUpdateFormValuesCommon = (newValue: TPartialFormLeadDirectMail) => {
  emit('update:modelValue', newValue)
};
const updateFormValues = (key: keyof TPartialFormLeadDirectMail, value: unknown) => {
  emit('update:modelValue', {
    ...props.modelValue,
    [key]: value,
  })
};
const handleUpdateIsValidFormValuesCommon = (value: boolean) => {
  isValidFormValuesCommon.value = value;
};
const handleUpdateIsValidReservedAt = (value: boolean) => {
  isValidReservedAt.value = value;
};
const emitCancel = () => {
  emit('cancel')
};
const handleClickSaveDraft = () => {
  emit('save-draft')
};
const handleClickConfirm = () => {
  emit('change-current-state-to-confirm')
};
const handleOpenAiCloseModal = (cancel: () => void) => {
  emit('close-openai', cancel);
};

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MailDrawerTemplate, {
      title: _ctx.title,
      width: 600
    }, {
      drawerHeaderAction: _withCtx(() => [
        _createVNode(_component_BTooltip, {
          top: "",
          content: _ctx.$t('mail.confirmInfo')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BBtn, {
              size: "small",
              type: "primary",
              outline: "",
              loading: _ctx.isLoading,
              disabled: !isValid.value,
              onClick: handleClickConfirm
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('mail.confirmContent')), 1),
                _createVNode(_component_BIcon, null, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("keyboard_arrow_right")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["loading", "disabled"])
          ]),
          _: 1
        }, 8, ["content"])
      ]),
      drawerBody: _withCtx(() => [
        _createVNode(FormDrawerBodyCommon, {
          "model-value": props.modelValue,
          "is-available-mail-template-feature": _ctx.isAvailableMailTemplateFeature,
          "sender-data": _ctx.senderData,
          "onUpdate:modelValue": handleUpdateFormValuesCommon,
          "onUpdate:isValid": handleUpdateIsValidFormValuesCommon,
          onCloseOpenai: handleOpenAiCloseModal
        }, {
          default: _withCtx(() => [
            _createVNode(ReservedAt, {
              "reserved-at": reservedAt.value,
              "onUpdate:reservedAt": _cache[0] || (_cache[0] = ($event: any) => ((reservedAt).value = $event)),
              "onUpdate:isValid": handleUpdateIsValidReservedAt
            }, null, 8, ["reserved-at"])
          ]),
          _: 1
        }, 8, ["model-value", "is-available-mail-template-feature", "sender-data"])
      ]),
      drawerFooter: _withCtx(() => [
        _createVNode(_component_BLayout, { "justify-center": "" }, {
          default: _withCtx(() => [
            _createVNode(_component_BBtn, {
              class: "mr-100",
              type: "primary",
              loading: _ctx.isLoading,
              disabled: !isValid.value,
              onClick: handleClickSaveDraft
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('mail.saveDraft')), 1)
              ]),
              _: 1
            }, 8, ["loading", "disabled"]),
            _createVNode(_component_BBtn, {
              flat: "",
              onClick: emitCancel
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.close.text')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}
}

})
<template>
  <div class="confirm-drawer">
    <BPlaceholder
      v-if="isLoading"
      v-loading="true"
      height="50vh"
      element-loading-background="transparent"
    />
    <MailDrawerTemplate
      v-else
      :title="$t('mail.confirmContent')"
      :width="600"
    >
      <template #drawerHeaderAction>
        <BBtn
          v-if="isSendNow"
          size="small"
          type="primary"
          outline
          :loading="$wait.is('sendDirectWait')"
          :disabled="!enableSendOrReserve"
          @click="clickSendButton"
        >
          <span>{{ $t('mail.send') }}</span>
          <BIcon>keyboard_arrow_right</BIcon>
        </BBtn>
        <BBtn
          v-else
          size="small"
          type="primary"
          outline
          :disabled="!enableSendOrReserve"
          @click="clickReserveButton"
        >
          <span>{{ $t('mail.reserve') }}</span>
          <BIcon>keyboard_arrow_right</BIcon>
        </BBtn>
      </template>
      <template #drawerBody>
        <ConfirmDrawerBodyCommon
          :direct-mail="directMail"
          :is-available-salesforce-api-feature="isAvailableSalesforceApiFeature"
          :sender-data="senderData"
        >
          <template #mailTo>
            <BListItem class="list-item mb-600">
              <template #header>
                <div>{{ $t('directMail.sendTo') }}</div>
              </template>
              <span>{{ `${directMail.sendToName} <${directMail.sendToAddress}>` }}</span>
            </BListItem>
          </template>
          <BListItem class="list-item mb-600">
            <template #header>
              <div>{{ $t('mail.sendAt') }}</div>
            </template>
            <span>{{ displayReservedAt }}</span>
          </BListItem>
        </ConfirmDrawerBodyCommon>
      </template>

      <template #drawerFooter>
        <BLayout justify-center>
          <BBtn
            class="mr-100"
            @click="handleBackToEdit"
          >
            <BIcon>keyboard_arrow_left</BIcon>
            <span>{{ $t('mail.backToEdit') }}</span>
          </BBtn>
          <BBtn
            class="mr-100"
            flat
            @click="handleClose"
          >
            {{ $t('general.close.text') }}
          </BBtn>
        </BLayout>
      </template>
    </MailDrawerTemplate>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { DirectMail } from '@/api/openapi/models/direct-mail';
import MailDrawerTemplate from '@/components/organisms/user/mail/common/drawer/MailDrawerTemplate.vue';
import { formatShorterDateTime } from '@/utils/date-time';
import { SenderData } from '../types';
import ConfirmDrawerBodyCommon from './ConfirmDrawerBodyCommon.vue';

type TProps = {
  directMail: DirectMail;
  isAvailableSalesforceApiFeature: boolean;
  isLoading: boolean;
  senderData: SenderData;
};

const props = defineProps<TProps>();
const emit = defineEmits([
  'edit', 'reserve', 'send', 'close', 'open', 'change-sf-owner-id',
]);

const isSendNow = computed(() => props.directMail.reservedAt === null);
const enableSendOrReserve = computed(() =>
  props.directMail.sendToAddress !== ''
  && props.directMail.status === 'draft',
);

const displayReservedAt = computed(() => props.directMail.reservedAt ? formatShorterDateTime(props.directMail.reservedAt) : '');
const modalName = `SalesforceMailSend${props.directMail.id}`

const clickSendButton = () => {
  emit('send');
}

const clickReserveButton = () => {
  emit('reserve');
}

const handleBackToEdit = () => emit('edit');
const handleClose = () => emit('close');
</script>

<style lang="scss" scoped>
.confirm-drawer {
  height: 100%;
}
</style>

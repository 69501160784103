<template>
  <BLayout
    class="instance-list-row"
    align-center
  >
    <div
      class="instance-list-item checkbox"
      @click.stop
    >
      <BCheckbox
        v-model="model"
        class="mt-0"
        :indeterminate="model"
      />
    </div>
    <div
      v-for="column in columns"
      :key="column.key"
      class="instance-list-item instance-list-header"
      :style="{
        'width': column.width,
        'min-width': column.width,
        'max-width': column.width
      }"
    >
      <span>{{ toLabel(column.key) }}</span>
      <BTooltip
        v-if="toInfo(column.key)"
        top
        :content="toInfo(column.key)"
      >
        <BIcon
          size="small"
          class="info"
        >
          help
        </BIcon>
      </BTooltip>
    </div>
    <div class="instance-list-item end-column" />
  </BLayout>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { columns } from './instance-list-header';

const model = defineModel<boolean>();

const i18n = useI18n();

const toLabel = (key: string) => {
  if (key === 'companyName') return i18n.t('companyInfo.item.companyName');
  if (key === 'fullName') return i18n.t('callTargetInfo.item.name');
  if (key === 'createdAt') return i18n.t('trigger.target.createdAt');
  if (key === 'event') return i18n.t('trigger.target.event');
  if (key === 'statusChangedAt') return i18n.t('trigger.target.statusChangedAt');
  if (key === 'status') return i18n.t('trigger.target.status.title');
  if (key === 'owner') return i18n.t('trigger.target.owner');
  if (key === 'approveUser') return i18n.t('trigger.target.approveUser');
};

const toInfo = (key: string) => {
  if (key === 'event') return i18n.t('trigger.target.eventNote');
};
</script>

<style lang="scss" scoped>
.checkbox {
  @include m-fixed-width(40px);
  z-index: 0;
}

.instance-list-row {
  transition: $transition-base;
  @include m-fixed-height(40px);

  .instance-list-item {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 $basespace-200;
    border-bottom: 1px solid $bdcolor-light;
    background-color: $bgcolor-white;
  }

  .instance-list-header {
    font-size: $fontsize-100;
    color: $textcolor-light;

    .b-icon.info {
      color: #969696;
      margin-left: 4px;
      display: inline-block;
      width: 16px;
    }
  }

  .end-column {
    width: 100%;
  }
}
</style>

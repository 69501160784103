import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }

import { computed, nextTick, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { PriorityPriorityEnum } from '@/api/openapi';
import BPriorityPreview from './BPriorityPreview.vue';
import priorityWithoutNotSet from './priority';

type TProps = {
  modelValue: PriorityPriorityEnum | null;
  // for BSelect
  loading?: boolean;
  required?: boolean;
  fit?: boolean;
  needsAllOption?: boolean;
  teleported?: boolean;
};
type TEmit = {
  'update:modelValue': [modelValue: PriorityPriorityEnum | null];
}

const VALUE_ALL = 'all';

export default /*@__PURE__*/_defineComponent({
  __name: 'BPrioritySelect',
  props: {
    modelValue: {},
    loading: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    fit: { type: Boolean, default: false },
    needsAllOption: { type: Boolean, default: false },
    teleported: { type: Boolean, default: true }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const i18n = useI18n();
const prependedPriorities = [];
if (props.needsAllOption) {
  prependedPriorities.push({
    priority: VALUE_ALL,
    name: i18n.t('general.priority.all'),
  });
}
const priorities = prependedPriorities.concat(priorityWithoutNotSet.map((priority) => ({
  priority,
  name: i18n.t(`general.priority.${priority}`),
})));

const automaticDropdown = ref(false);
const refSelect = ref<HTMLElement>(null);
const value = computed<PriorityPriorityEnum | typeof VALUE_ALL | null>({
  get() {
    return props.modelValue === null ? VALUE_ALL : props.modelValue
  },
  set(v) {
    emit('update:modelValue', v === VALUE_ALL ? null : v);
  },
});

const focus = async () => {
  // NOTE: automaticDropdown=trueにしないとfocus時にdropdownが開かないが、ONのままにしておくとclick時にdropdownが開かなくなってしまう（Element Plusのバグかも）
  automaticDropdown.value = true;
  refSelect.value.focus();
  await nextTick();
  automaticDropdown.value = false;
};

__expose({
  focus,
});

return (_ctx: any,_cache: any) => {
  const _component_BPriorityColorPreview = _resolveComponent("BPriorityColorPreview")!
  const _component_BSelect = _resolveComponent("BSelect")!

  return (_openBlock(), _createBlock(_component_BSelect, {
    ref_key: "refSelect",
    ref: refSelect,
    modelValue: value.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
    items: _unref(priorities),
    loading: _ctx.loading,
    required: _ctx.required,
    fit: _ctx.fit,
    "automatic-dropdown": automaticDropdown.value,
    teleported: _ctx.teleported,
    "item-text": "name",
    "item-value": "priority"
  }, {
    prefix: _withCtx(() => [
      (value.value !== VALUE_ALL)
        ? (_openBlock(), _createBlock(_component_BPriorityColorPreview, {
            key: 0,
            priority: value.value,
            size: "small"
          }, null, 8, ["priority"]))
        : _createCommentVNode("", true)
    ]),
    optionTemplate: _withCtx(({ item }) => [
      (item.priority == VALUE_ALL)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(item.name), 1))
        : (_openBlock(), _createBlock(BPriorityPreview, {
            key: 1,
            priority: item.priority,
            size: "small"
          }, null, 8, ["priority"]))
    ]),
    _: 1
  }, 8, ["modelValue", "items", "loading", "required", "fit", "automatic-dropdown", "teleported"]))
}
}

})
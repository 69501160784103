import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "theader" }
const _hoisted_2 = { class: "table-row theader-row" }
const _hoisted_3 = { class: "tbody" }
const _hoisted_4 = ["onClick"]

import { useI18n } from 'vue-i18n'
import { QuickText } from '@/api/openapi';
import { QuickTextApiService } from '@/api/user/resources/quick_text';
import { useDeleteConfirm } from '@/composable/message-dialog';
import { useWait } from '@/composable/vue-wait';
import { formatShorterDateTime } from '@/utils/date-time';

type TProps = {
  quickTexts: QuickText[];
};
type TEmit = {
  'afterDelete': [];
  'click:row': [id: number];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'QuickTextList',
  props: {
    quickTexts: {}
  },
  emits: ["afterDelete", "click:row"],
  setup(__props: any, { emit: __emit }) {


const emit = __emit;

const i18n = useI18n();
const { deleteConfirm } = useDeleteConfirm();
const api = new QuickTextApiService();
const { doActionWithWait } = useWait();

const headers = [
  { key: 'name', name: i18n.t('quickText.name'), class: ['name-container'] },
  { key: 'createdAt', name: i18n.t('general.createdAt'), class: ['date-time'] },
  { key: 'updatedAt', name: i18n.t('general.updatedAt'), class: ['date-time'] },
  { key: 'createdUser', name: i18n.t('general.createdUser'), class: ['user'] },
  { key: 'updatedUser', name: i18n.t('general.updatedUser'), class: ['user'] },
];

const handleRowClick = (id: number) => emit('click:row', id);
const handleDeleteButtonClick = async (quickTextId: number) => {
  const ok = await deleteConfirm();
  if (!ok) return;
  await doActionWithWait('deleteQuickTextWait', async () => {
    await api.deleteQuickText({
      request: { quickTextId },
    });
  });
  emit('afterDelete');
};

return (_ctx: any,_cache: any) => {
  const _component_BSortText = _resolveComponent("BSortText")!
  const _component_BStatus = _resolveComponent("BStatus")!
  const _component_BUserTooltip = _resolveComponent("BUserTooltip")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BMoreMenu = _resolveComponent("BMoreMenu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(headers, (cell) => {
          return _createVNode(_component_BSortText, {
            key: cell.key,
            class: _normalizeClass(["table-cell header-cell", cell.class]),
            "item-text": cell.name,
            "is-display-only": ""
          }, null, 8, ["class", "item-text"])
        }), 64)),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "table-cell menu" }, null, -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quickTexts, (quickText) => {
        return (_openBlock(), _createElementBlock("div", {
          key: quickText.id,
          class: "table-row tbody-row",
          onClick: () => handleRowClick(quickText.id)
        }, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(headers, (column) => {
            return _createElementVNode("div", {
              key: column.key,
              class: _normalizeClass(["table-cell", column.class])
            }, [
              (column.key === 'name')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(quickText.name) + " ", 1),
                    _createVNode(_component_BStatus, {
                      class: "mt-50",
                      status: quickText.status,
                      "status-name": _ctx.$t(`general.status.${quickText.status}`)
                    }, null, 8, ["status", "status-name"])
                  ], 64))
                : _createCommentVNode("", true),
              (column.key === 'createdAt')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_unref(formatShorterDateTime)(quickText.createdAt)), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (column.key === 'updatedAt')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createTextVNode(_toDisplayString(_unref(formatShorterDateTime)(quickText.updatedAt)), 1)
                  ], 64))
                : (column.key === 'createdUser')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                      (quickText.createdUser)
                        ? (_openBlock(), _createBlock(_component_BUserTooltip, {
                            key: 0,
                            "image-url": quickText.createdUser.avatarUrl,
                            name: quickText.createdUser.name,
                            "user-id": quickText.createdUser.id,
                            size: 40
                          }, null, 8, ["image-url", "name", "user-id"]))
                        : _createCommentVNode("", true)
                    ], 64))
                  : (column.key === 'updatedUser')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                        (quickText.updatedUser)
                          ? (_openBlock(), _createBlock(_component_BUserTooltip, {
                              key: 0,
                              "image-url": quickText.updatedUser.avatarUrl,
                              name: quickText.updatedUser.name,
                              "user-id": quickText.updatedUser.id,
                              size: 40
                            }, null, 8, ["image-url", "name", "user-id"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true)
            ], 2)
          }), 64)),
          _createElementVNode("div", {
            class: "table-cell menu",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }, [
            _createVNode(_component_BMoreMenu, { "z-index100": "" }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_BBtn, {
                    flat: "",
                    fit: "",
                    size: "small",
                    onClick: () => handleDeleteButtonClick(quickText.id)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.delete.text')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ])
              ]),
              _: 2
            }, 1024)
          ])
        ], 8, _hoisted_4))
      }), 128))
    ])
  ], 64))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "fs-400 fw-bold" }
const _hoisted_2 = { class: "fs-100" }
const _hoisted_3 = { class: "fs-100" }
const _hoisted_4 = { class: "fs-100" }
const _hoisted_5 = { class: "fs-100" }
const _hoisted_6 = { class: "trigger-container" }
const _hoisted_7 = { class: "trigger-container--inner" }
const _hoisted_8 = { class: "scrollable-container" }
const _hoisted_9 = { class: "scrollable-container--inner" }
const _hoisted_10 = { class: "end" }
const _hoisted_11 = { class: "side-menu-container" }
const _hoisted_12 = { class: "side-menu-container" }

import lodash from 'lodash';
import { computed, inject, onMounted, ref, watch, getCurrentInstance } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Trigger, TriggerBody, TriggerStatusBody, TriggerEventBody, TriggerActionBody, TriggerStatus } from '@/api/openapi';
import { TriggerApiService } from '@/api/user/resources/trigger';
import TriggerActionEnd from '@/components/organisms/user/general/trigger/TriggerActionEnd.vue';
import TriggerAction from '@/components/organisms/user/trigger/TriggerAction.vue';
import TriggerActionForm from '@/components/organisms/user/trigger/triggerActionForm/TriggerActionForm.vue';
import TriggerEvent from '@/components/organisms/user/trigger/TriggerEvent.vue';
import TriggerEventForm from '@/components/organisms/user/trigger/triggerEventForm/TriggerEventForm.vue';
import TriggerFooter from '@/components/organisms/user/trigger/TriggerFooter.vue';
import TriggerForm from '@/components/organisms/user/trigger/TriggerForm.vue';
import { TTriggerActionFormMode, TTriggerEventFormMode, TriggerActionBodyExt } from '@/components/organisms/user/trigger/types';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import { useErrorHandler } from '@/composable/error-handler';
import { useUrlLinkBtabsHandlers } from '@/composable/url-link-b-tabs';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { i18nGlobal as i18n } from '@/i18n';
import { KEY_OF_TOAST } from '@/injection-keys';
import { formatShorterDateTime } from '@/utils/date-time';


export default /*@__PURE__*/_defineComponent({
  __name: 'Trigger',
  setup(__props) {

const { isAvailableTriggerFeature } = useAvailableFeatureCheck()
const toast = inject(KEY_OF_TOAST);
const route = useRoute();
const router = useRouter();
const { currentUser, fetchCurrentUserPromise } = useCurrentUser();
const {
  wait,
  doActionWithWait,
} = useWait();
const {
  redirectToNotFoundPage,
} = useErrorHandler();

const api = new TriggerApiService();

onMounted(async () => {
  await doActionWithWait('onMountedWait', async () => {
    await fetchCurrentUserPromise;
    await fetchTrigger();
  });
});

const activeName = ref('action');
const triggerId = parseInt(route.params.id as string);
const trigger = ref<Trigger>();
const triggerBody = ref<TriggerBody>({
  name: '',
  ownerId: null,
});
const triggerStatus = ref<boolean>();
const triggerEventBody = ref<TriggerEventBody | null>(null);
const triggerActionBodies = ref<TriggerActionBody[]>([]);
const canSave = ref(false);
const app = getCurrentInstance()

const selectedTriggerEvent = ref<TriggerEventBody | null | undefined>(undefined);
const triggerEventFormMode = ref<TTriggerEventFormMode | null | undefined>(undefined);
const isTriggerEventFormOpen = computed(() => selectedTriggerEvent.value !== undefined);

const selectedTriggerAction = ref<TriggerActionBodyExt | null | undefined>(undefined);
const triggerActionFormMode = ref<TTriggerActionFormMode | null | undefined>(undefined);
const isTriggerActionFormOpen = computed(() => selectedTriggerAction.value !== undefined);

const tabLabelItems = [
  { label: i18n.t('trigger.action'), slotName: 'action' },
  { label: i18n.t('trigger.lead'), slotName: 'lead', route: { name: 'TriggerLeads', params: { id: triggerId } } },
];

watch(trigger, (newValue) => {
  if (newValue) {
    triggerBody.value.name = newValue.name;
    triggerBody.value.ownerId = newValue.owner?.id || null;
    triggerStatus.value = newValue.status === TriggerStatus.Active;
  }
});
watch([
  () => triggerBody.value.name,
  () => isTriggerEventFormOpen.value,
  () => isTriggerActionFormOpen.value,
], ([
  newTriggerBodyName,
  newIsTriggerEventFormOpen,
  newIsTriggerActionFormOpen,
]) => {
  canSave.value = !!newTriggerBodyName
    && !newIsTriggerEventFormOpen
    && !newIsTriggerActionFormOpen
    && editable.value;
});

const loading = computed(() => wait.is(['onMountedWait', 'getTriggerWait', 'postTriggerWait', 'putTriggerWait', 'putTriggerStatusWait']));
const triggerActionSortOrderMax = computed(() => {
  if (triggerActionBodies.value.length < 1) return 1;
  return triggerActionBodies.value.map((a) => a.sortOrder).reduce((a, b) => Math.max(a, b)) + 1;
});

const needConfirmationToLeave = computed(() => {
  const orgName = trigger.value?.name ?? '';
  return orgName !== triggerBody.value.name;
});

const editable = computed(() => {
  const isOwnerUser = app.appContext.config.globalProperties.$can('owner');
  const isNewTrigger = !trigger.value || !trigger.value?.id;
  const isTriggerOwner = !!trigger.value?.owner?.id && trigger.value?.owner?.id === currentUser.value?.id;
  return isOwnerUser && (isNewTrigger || isTriggerOwner);
});

const {
  handleTabClick,
  handleTabBeforeLeave,
} = useUrlLinkBtabsHandlers(tabLabelItems, needConfirmationToLeave);

const fetchTrigger = async () => {
  if (!triggerId) return;

  await doActionWithWait('getTriggerWait', async () => {
    const { data } = await api.getTrigger({
      request: {
        triggerId,
      },
      errorHandlers: {
        404: redirectToNotFoundPage,
      },
    });
    trigger.value = data.trigger;
    triggerEventBody.value = data.trigger.triggerEvent;
    triggerActionBodies.value = data.trigger.triggerActions.map((triggerAction) => {
      const { id, actionType, actionDetail, sortOrder, approveUser } = triggerAction;
      return {
        id,
        actionType,
        actionDetail,
        sortOrder,
        approveUserId: approveUser?.id,
      } as TriggerActionBody;
    });
  });
};

const handleSelectTriggerEvent = (triggerEvent: TriggerEventBody, mode: TTriggerEventFormMode) => {
  selectedTriggerEvent.value = undefined;
  selectedTriggerAction.value = undefined;
  setTimeout(() => {
    selectedTriggerEvent.value = triggerEvent;
    triggerEventFormMode.value = mode;
  }, 200);
};

const handleSelectTriggerAction = (triggerAction: TriggerActionBodyExt, mode: TTriggerActionFormMode) => {
  selectedTriggerEvent.value = undefined;
  selectedTriggerAction.value = undefined;
  setTimeout(() => {
    selectedTriggerAction.value = triggerAction;
    triggerActionFormMode.value = mode;
  }, 200);
};

const handleCancelTriggerEvent = () => {
  selectedTriggerEvent.value = undefined;
};

const handleCancelTriggerAction = () => {
  selectedTriggerAction.value = undefined;
};

const handleSaveTriggerEvent = (triggerEvent: TriggerEventBody) => {
  triggerEventBody.value = triggerEvent;
  selectedTriggerEvent.value = undefined;
};

const handleSaveTriggerAction = (triggerAction: TriggerActionBodyExt) => {
  if (triggerAction.appendIndex > 0) {
    // 追加する (sortOrderの間)
    const newTriggerActionBodies = lodash.cloneDeep(triggerActionBodies.value);
    newTriggerActionBodies.splice(triggerAction.appendIndex - 1, 0, triggerAction);
    newTriggerActionBodies.forEach((e, i) => e.sortOrder = i + 1);
    triggerActionBodies.value = newTriggerActionBodies;
  } else if (triggerAction.sortOrder) {
    // 更新する
    triggerActionBodies.value = triggerActionBodies.value.map((triggerActionBody) =>
      triggerActionBody.sortOrder == triggerAction.sortOrder ? triggerAction : triggerActionBody,
    );
  } else {
    // 追加する (sortOrderの一番後ろ)
    triggerAction.sortOrder = triggerActionSortOrderMax.value;
    triggerActionBodies.value.push(triggerAction);
  }
  selectedTriggerAction.value = undefined;
};

const handleToggleStatus = async () => {
  await doActionWithWait('putTriggerStatusWait', async () => {
    await api.putTriggerStatus({
      request: {
        triggerId,
        putTriggerStatusRequest: {
          trigger: {
            status: triggerStatus.value ? TriggerStatus.Inactive : TriggerStatus.Active,
          },
        },
      },
    });
  });
  await fetchTrigger();
}

const handleClickSave = async () => {
  let newTriggerId: number;

  if (!canSave.value) return;
  
  if (triggerId) {
    await doActionWithWait('putTriggerWait', async () => {
      await api.putTrigger({
        request: {
          triggerId,
          postTriggerRequest: {
            trigger: triggerBody.value,
            triggerEvent: triggerEventBody.value,
            triggerActions: triggerActionBodies.value,
          },
        },
      });
    });
  } else {
    await doActionWithWait('postTriggerWait', async () => {
      const { data } = await api.postTrigger({
        request: {
          postTriggerRequest: {
            trigger: {
              ...triggerBody.value,
              ownerId: currentUser.value.id,
            },
            triggerEvent: triggerEventBody.value,
            triggerActions: triggerActionBodies.value,
          },
        },
      });
      newTriggerId = data.id;
    });
  }

  toast.show(
    i18n.t('general.done.withTarget', {
      action: i18n.t('general.save.text'),
      target: i18n.t('trigger.title'),
    }),
  );

  if (triggerId) {
    await fetchTrigger();
  } else {
    router.replace({ name: 'Trigger', params: { id: newTriggerId } });
  }
};

return (_ctx: any,_cache: any) => {
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BSwitch = _resolveComponent("BSwitch")!
  const _component_BPopover = _resolveComponent("BPopover")!
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_BTabs = _resolveComponent("BTabs")!
  const _component_BContent = _resolveComponent("BContent")!
  const _directive_loading = _resolveDirective("loading")!

  return (_unref(isAvailableTriggerFeature))
    ? _withDirectives((_openBlock(), _createBlock(_component_BContent, { key: 0 }, {
        navbar: _withCtx(() => [
          _createVNode(_component_BLayout, {
            "align-start": "",
            "justify-start": ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('trigger.title')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_BLayout, {
            column: "",
            class: "trigger-switch"
          }, {
            default: _withCtx(() => [
              (editable.value)
                ? (_openBlock(), _createBlock(_component_BLayout, {
                    key: 0,
                    "align-center": "",
                    "justify-space-between": ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t(`trigger.status`)), 1),
                      _createVNode(_component_BSwitch, {
                        status: triggerStatus.value,
                        "before-change": handleToggleStatus,
                        color: "accent"
                      }, null, 8, ["status"])
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_BPopover, {
                    key: 1,
                    placement: "top",
                    width: "240",
                    trigger: "hover"
                  }, {
                    reference: _withCtx(() => [
                      _createVNode(_component_BLayout, {
                        "align-center": "",
                        "justify-space-between": "",
                        class: "trigger-switch-disabled"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(`trigger.status`)), 1),
                          _createVNode(_component_BSwitch, {
                            status: triggerStatus.value,
                            color: "accent"
                          }, null, 8, ["status"])
                        ]),
                        _: 1
                      })
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('trigger.statusChangeOnlyOwner')), 1)
                    ]),
                    _: 1
                  })),
              (trigger.value?.statusChangedAt)
                ? (_openBlock(), _createBlock(_component_BLayout, {
                    key: 2,
                    "justify-space-between": "",
                    class: "text-annotation trigger-switch-annotation"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_4, [
                        (triggerStatus.value)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createTextVNode(_toDisplayString(_ctx.$t(`trigger.statusChangedAt`)) + ": ", 1)
                            ], 64))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createTextVNode(_toDisplayString(_ctx.$t(`trigger.statusStoppedAt`)) + ": ", 1)
                            ], 64))
                      ]),
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(formatShorterDateTime)(trigger.value.statusChangedAt)), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        footer: _withCtx(() => [
          _createVNode(TriggerFooter, {
            "can-save": canSave.value,
            loading: loading.value,
            "need-confirmation-to-leave": needConfirmationToLeave.value,
            "onClick:save": handleClickSave
          }, null, 8, ["can-save", "loading", "need-confirmation-to-leave"])
        ]),
        waiting: _withCtx(() => [
          _withDirectives(_createVNode(_component_BPlaceholder, { "element-loading-background": "transparent" }, null, 512), [
            [_directive_loading, true]
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(TriggerForm, {
            modelValue: triggerBody.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((triggerBody).value = $event)),
            "wait-for": "getTriggerWait"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_BTabs, {
            modelValue: activeName.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((activeName).value = $event)),
            class: "tabs",
            "label-items": tabLabelItems,
            "before-leave": _unref(handleTabBeforeLeave),
            "onClick:tab": _unref(handleTabClick)
          }, {
            action: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(TriggerEvent, {
                        "trigger-event": triggerEventBody.value,
                        editable: editable.value,
                        "onClick:triggerEvent": handleSelectTriggerEvent
                      }, null, 8, ["trigger-event", "editable"]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(triggerActionBodies.value, (triggerActionBody) => {
                        return (_openBlock(), _createBlock(TriggerAction, {
                          key: triggerActionBody.sortOrder,
                          "trigger-action": triggerActionBody,
                          editable: editable.value,
                          "onClick:triggerAction": handleSelectTriggerAction
                        }, null, 8, ["trigger-action", "editable"]))
                      }), 128)),
                      (_openBlock(), _createBlock(TriggerAction, {
                        key: triggerActionSortOrderMax.value,
                        editable: editable.value,
                        "onClick:triggerAction": handleSelectTriggerAction
                      }, null, 8, ["editable"])),
                      _createElementVNode("div", _hoisted_10, [
                        _createVNode(TriggerActionEnd)
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "before-leave", "onClick:tab"]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(TriggerEventForm, {
              modelValue: selectedTriggerEvent.value,
              "onUpdate:modelValue": [
                _cache[2] || (_cache[2] = ($event: any) => ((selectedTriggerEvent).value = $event)),
                handleSaveTriggerEvent
              ],
              mode: triggerEventFormMode.value,
              "onUpdate:mode": _cache[3] || (_cache[3] = ($event: any) => ((triggerEventFormMode).value = $event)),
              "is-open": isTriggerEventFormOpen.value,
              "onUpdate:isOpen": _cache[4] || (_cache[4] = ($event: any) => ((isTriggerEventFormOpen).value = $event)),
              editable: editable.value,
              "onClick:cancel": handleCancelTriggerEvent,
              "onClick:triggerEvent": handleSelectTriggerEvent
            }, null, 8, ["modelValue", "mode", "is-open", "editable"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(TriggerActionForm, {
              modelValue: selectedTriggerAction.value,
              "onUpdate:modelValue": [
                _cache[5] || (_cache[5] = ($event: any) => ((selectedTriggerAction).value = $event)),
                handleSaveTriggerAction
              ],
              mode: triggerActionFormMode.value,
              "onUpdate:mode": _cache[6] || (_cache[6] = ($event: any) => ((triggerActionFormMode).value = $event)),
              "is-open": isTriggerActionFormOpen.value,
              "onUpdate:isOpen": _cache[7] || (_cache[7] = ($event: any) => ((isTriggerActionFormOpen).value = $event)),
              editable: editable.value,
              "onClick:cancel": handleCancelTriggerAction,
              "onClick:triggerAction": handleSelectTriggerAction
            }, null, 8, ["modelValue", "mode", "is-open", "editable"])
          ])
        ]),
        _: 1
      })), [
        [_directive_loading, loading.value]
      ])
    : _createCommentVNode("", true)
}
}

})
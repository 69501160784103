<template>
  <BMenu
    v-model="isOpen"
  >
    <template #activator>
      <div class="color-select">
        <BColorPreview
          v-if="!!selectedColor"
          :color-code="selectedColor.colorCode"
        />
        <span
          v-else
          class="placeholder truncate-text"
        >{{ $t('general.select.please') }}</span>
        <div class="icons">
          <BElementIcon
            name="ArrowDown"
            type="gray"
            size="medium"
          />
          <span @click.stop="handleClear">
            <BElementIcon
              v-if="clearable && !!selectedColor"
              name="Close"
              type="gray"
              size="medium"
            />
          </span>
        </div>
      </div>
    </template>
    <template #default>
      <div class="color-container">
        <template
          v-for="color in colors"
          :key="color.value"
        >
          <BColorPreview
            :color-code="color.colorCode"
            @click="() => handleSelectColor(color)"
          />
        </template>
      </div>
    </template>
  </BMenu>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { TColor } from '../../types';

type TProps = {
  modelValue: string | null;
  colors: TColor[];
  clearable?: boolean;
};

const props = withDefaults(defineProps<TProps>(), {
  clearable: false,
});
const emit = defineEmits(['update:modelValue'])

const selectedColor = computed(() => props.modelValue ? props.colors.find(c => props.modelValue === c.value) : undefined);

const isOpen = ref(false);
const toggleSelector = () => isOpen.value = !isOpen.value;

const handleSelectColor = (color: TColor) => {
  emit('update:modelValue', color.value);
  toggleSelector();
};

// NOTE: BElementIconにclickがうまくhookできないので一枚span着せてます
const handleClear = () => emit('update:modelValue', null);
</script>

<style lang="scss" scoped>
.color-select {
  cursor: pointer;
  -webkit-appearance: none;
  background-color: white;
  background-image: none;
  border-radius: 4px;
  border: 1px solid $concrete;
  box-sizing: border-box;
  color: $charcoal;
  display: flex;
  align-items: center;
  font-size: inherit;
  height: 40px;
  outline: none;
  padding: 0 12px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  .icons {
    margin-left: auto;

    .b-icon {
      margin-left: 6px;
    }
  }
  .placeholder {
    color: $concrete-dark;
  }
}
.color-container {
  padding: 8px;
  // NOTE: .el-popover.el-popper line-heightのリセット
  line-height: 1;

  .color-preview {
    cursor: pointer;
  }

  .color-preview:not(:last-child) {
    margin-right: 8px;
  }
}
</style>

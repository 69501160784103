<template>
  <MailDrawerTemplate
    :title="$t('mailTemplate.mailTemplateCreate')"
    :loading="$wait.is(['deleteMailTemplateWait'])"
    :can-delete="isSaved"
    :can-duplicate="isSaved"
    @duplicate="$emit('duplicate')"
    @click-delete="deleteMailTemplate"
  >
    <template #drawerHeaderAction>
      <BTooltip
        top
        :content="$t('mailTemplate.confirmInfo')"
      >
        <BBtn
          size="small"
          type="primary"
          outline
          :loading="isLoading"
          :disabled="!isValidAll"
          @click="$emit('to-confirm')"
        >
          <span>{{ $t("mailTemplate.confirmContent") }}</span>
          <BIcon>keyboard_arrow_right</BIcon>
        </BBtn>
      </BTooltip>
    </template>
    <template #drawerBody>
      <div class="mail-template-name">
        <BInput
          v-model="internalMailTemplate.name"
          v-model:valid="isValidName"
          :placeholder="$t('mailTemplate.namePlaceholder')"
          :rules="[requiredRule]"
          :max-length="255"
        />
      </div>
      <!-- NOTE: 自動生成の動線を一時的に消している -->
      <!--
        <div class="pop-up-box">
        <BBtn
        text
        class="openai-button"
        @click="handleOpenaiModalShow"
        >
        <BIcon
        type="success"
        class="openai-icon"
        size="large"
        >
        psychology
        </BIcon>
        <span class="fw-bold">{{ $t('openaiGenarate.mailGenerate') }}</span>
        </BBtn>
        </div> 
      -->
      <MailContentEditor
        v-loading="isWaitOpenaiLoading"
        class="mb-600"
        :subject="internalMailTemplate.subject"
        :content="internalMailTemplate.content"
        @edit-subject="editEmit('subject', $event)"
        @edit-content="editEmit('content', $event)"
      />
      <BListItem class="mb-600">
        <template #header>
          <span>
            <div class="header-group">
              <span class="text">{{ $t("mailTemplate.reply") }}</span>
              <BCheckbox
                v-model="internalIsDisabledReplyForm"
                class="ml-100"
                :label="$t('mailTemplate.noSelectReplyTo')"
              />
            </div>
          </span>
        </template>
        <div
          v-if="!internalIsDisabledReplyForm"
          class="input-group"
        >
          <BInput
            v-model="internalMailTemplate.replyName"
            v-model:valid="isValidReplyName"
            class="flat-input name"
            :placeholder="$t('mailTemplate.mailName')"
            :rules="[requiredRule]"
            :max-length="255"
            flat
          />
          <BInput
            v-model="internalMailTemplate.replyAddress"
            v-model:valid="isValidReplyAddress"
            class="flat-input address"
            :placeholder="$t('mailTemplate.mailAddress')"
            :rules="[requiredRule, emailRule]"
            :max-length="255"
            flat
          />
        </div>
      </BListItem>
    </template>
    <template #drawerFooter>
      <BLayout justify-center>
        <BBtn
          class="mr-100"
          flat
          @click="$emit('cancel')"
        >
          {{ $t("general.close.text") }}
        </BBtn>
        <BBtn
          class="ml-100"
          type="primary"
          :loading="isLoading"
          @click="$emit('save-draft')"
        >
          {{ $t("mailTemplate.saveDraft") }}
        </BBtn>
      </BLayout>
    </template>
  </MailDrawerTemplate>
</template>

<script>
import MailDrawerTemplate from '@/components/organisms/user/mail/common/drawer/MailDrawerTemplate.vue';
import MailContentEditor from '@/components/organisms/user/mail/common/drawer/state/form/MailContentEditor.vue';
import inputValidation from '@/mixins/input_validation';

export default {
  name: 'FormDrawer',
  components: {
    MailDrawerTemplate,
    MailContentEditor,
  },
  mixins: [inputValidation],
  props: {
    mailTemplate: {
      type: Object,
      required: true,
    },
    isSaved: {
      type: Boolean,
      default: false,
    },
    isDisabledReplyForm: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'duplicate',
    'to-confirm',
    'cancel',
    'save-draft',
    'change',
    'update:isDisabledReplyForm',
    'delete',
  ],
  data() {
    return {
      isValidName: false,
      isValidReplyName: false,
      isValidReplyAddress: false,
      internalIsDisabledReplyForm: this.isDisabledReplyForm,
    };
  },
  computed: {
    internalMailTemplate: {
      get() {
        return this.mailTemplate;
      },
      set(v) {
        this.$emit('change', v);
      },
    },
    isLoading() {
      return this.$wait.is([
        'createMailTemplateWait',
        'updateMailTemplateWait',
        'deleteMailTemplateWait',
      ]);
    },
    isValidAll() {
      return (
        this.isValidName
        && this.isValidSubjectAndContent
        && this.isValidReply
      );
    },
    isValidSubjectAndContent() {
      return (
        !!this.internalMailTemplate.subject
        && !!this.internalMailTemplate.content
      );
    },
    isValidReply() {
      if (this.isDisabledReplyForm === true) return true;
      return this.isValidReplyName && this.isValidReplyAddress;
    },
    isWaitOpenaiLoading() {
      return this.$wait.is(['generateContentByOpenaiWait'])
    },
  },
  watch: {
    internalIsDisabledReplyForm(value) {
      this.$emit('update:isDisabledReplyForm', value)
      if (value) this.resetReply();
    },
  },
  methods: {
    editEmit(key, value) {
      this.internalMailTemplate[key] = value;
    },
    resetReply() {
      this.editEmit('replyName', null);
      this.editEmit('replyAddress', null);
    },
    async deleteMailTemplate() {
      const check = await this.$bitterAlert.show({
        title: this.$t('general.confirm.text'),
        text: this.$t('general.confirm.of', {
          action: this.$t('general.delete.text'),
        }),
      });
      if (!check) {
        return;
      }
      this.$emit('delete');
    },
    handleOpenaiModalShow() {
      this.$modal.show('openai-modal')
    },
  },
};
</script>

<style lang="scss" scoped>
.mail-template-name {
  margin-top: -$basespace-400;
  margin-right: -$basespace-400;
  margin-left: -$basespace-400;
  padding: $basespace-200 $basespace-400;
  background-color: $concrete-light;
}
.input-group {
  display: flex;
  .name {
    flex: 1;
    margin-right: 10px;
  }
  .address {
    flex: 2;
  }
}
.flat-input {
  border-bottom: 1px solid $bdcolor-light;
}
.header-group {
  font-size: $fontsize-100;
  color: $concrete-dark;
  margin-bottom: 4px;
  display: flex;
  line-height: 24px;
  .text {
    margin-right: 10px;
  }
}
.pop-up-box {
  border-bottom: 1px solid $bdcolor-base;
  margin: 0 -20px 16px -20px;
  padding: 4px 20px;
  .openai-button {
    color: $basecolor-accent;
    margin-left: 15px;
  }
}
.openai-icon {
  transform: scale(-1, 1);
  color: $basecolor-success;
}
</style>

import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "reminder-header" }
const _hoisted_2 = { class: "reminder-header-item header-title" }
const _hoisted_3 = { class: "reminder-header-item number-of-items" }
const _hoisted_4 = { class: "reminder-header-item" }
const _hoisted_5 = { class: "reminder-header-item" }
const _hoisted_6 = { class: "reminder-header-item" }
const _hoisted_7 = { class: "reminder-body" }
const _hoisted_8 = { class: "reminder-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BSelect = _resolveComponent("BSelect")!
  const _component_BPrioritySelect = _resolveComponent("BPrioritySelect")!
  const _component_ReminderTimeline = _resolveComponent("ReminderTimeline")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BIconWithActionMenu = _resolveComponent("BIconWithActionMenu")!

  return (_openBlock(), _createBlock(_component_BIconWithActionMenu, {
    ref: "bIconWithActionMenuContainer",
    class: "reminder-container",
    width: 720,
    "badge-count": _ctx.todayActions.length,
    "auto-fetch-interval": 30000,
    onAutoFetchIntervalPassed: _ctx.fetchReminder,
    onAfterOpen: _ctx.handleAfterOpen
  }, {
    icon: _withCtx(() => [
      _createVNode(_component_BIcon, null, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("notifications")
        ])),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('reminder.todayActions')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(_ctx.todayActions.length) + "件", 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_BSelect, {
            modelValue: _ctx.selectedActionType,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedActionType) = $event)),
            class: "type-select",
            items: _ctx.nextActionTypeItems,
            "item-text": "text",
            "item-value": "value",
            filterable: "",
            teleported: false
          }, null, 8, ["modelValue", "items"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_BSelect, {
            modelValue: _ctx.selectedUser,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedUser) = $event)),
            class: "owner-select",
            items: _ctx.userItems,
            "item-text": "text",
            "item-value": "value",
            filterable: "",
            teleported: false
          }, null, 8, ["modelValue", "items"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_BPrioritySelect, {
            modelValue: _ctx.selectedPriority,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedPriority) = $event)),
            class: "priority-select",
            "needs-all-option": "",
            teleported: false
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.isToday)
          ? (_openBlock(), _createBlock(_component_ReminderTimeline, {
              key: 0,
              actions: _ctx.todayActions,
              "fetch-action": _ctx.getReminderAndNextActionType
            }, null, 8, ["actions", "fetch-action"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_BBtn, {
          class: "footer-item",
          text: "",
          onClick: _ctx.showLeadsHasPastAction
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('reminder.pastActions')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_BBtn, {
          class: "footer-item",
          text: "",
          onClick: _ctx.showLeadsHasFutureAction
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('reminder.futureActions')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["badge-count", "onAutoFetchIntervalPassed", "onAfterOpen"]))
}
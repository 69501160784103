import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { inject } from 'vue';
import { KEY_OF_MODAL } from '@/injection-keys';
import BHeightAdjuster from '@/plugins/biscuet-materials/components/atoms/BHeightAdjuster.vue';
import QuickTextSelectTable from './QuickTextSelectTable.vue';

type TEmits = {
  select: [content: string];
};
type TProps = {
  callTargetId: number;
  modalName: string;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'QuickTextSelectModal',
  props: {
    callTargetId: {},
    modalName: {}
  },
  emits: ["select"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const modal = inject(KEY_OF_MODAL);

const handleCloseClick = () => {
  modal.hide(props.modalName);
};

const handleSelect = (content: string) => {
  emit('select', content);
  handleCloseClick();
};

return (_ctx: any,_cache: any) => {
  const _component_BModalHeader = _resolveComponent("BModalHeader")!
  const _component_BModalBody = _resolveComponent("BModalBody")!
  const _component_BModal = _resolveComponent("BModal")!

  return (_openBlock(), _createBlock(_component_BModal, {
    "modal-name": _ctx.modalName,
    width: "70%",
    height: "85%"
  }, {
    default: _withCtx(() => [
      _createVNode(BHeightAdjuster, null, {
        default: _withCtx(() => [
          _createVNode(_component_BModalHeader, {
            title: _ctx.$t('general.select.of', { item: _ctx.$t('quickText.title') }),
            onModalClose: handleCloseClick
          }, null, 8, ["title"]),
          _createVNode(_component_BModalBody, { full: "" }, {
            default: _withCtx(() => [
              _createVNode(QuickTextSelectTable, {
                "call-target-id": props.callTargetId,
                onSelect: handleSelect
              }, null, 8, ["call-target-id"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-name"]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "navbar mb-200" }
const _hoisted_2 = { class: "pl-600" }
const _hoisted_3 = { class: "theader" }
const _hoisted_4 = { class: "table-row theader-row" }
const _hoisted_5 = { class: "tbody" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 0,
  class: "name"
}

import { AxiosResponse } from 'axios';
import { computed, inject, ref, watch, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n'
import { GetSequenceMasters200Response, PaginationMeta, SequenceMaster } from '@/api/openapi';
import { SequenceMasterApiService } from '@/api/user/resources/sequence_master';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { KEY_OF_WAIT } from '@/injection-keys';
import SelectUser from '@/plugins/biscuet-materials/components/molecules/SelectUser.vue';

type TEmit = {
  'selectSequenceMaster': [sequenceMasterId: number];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceMasterSelect',
  emits: ["selectSequenceMaster"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const {
  doActionWithWait,
} = useWait();

const wait = inject(KEY_OF_WAIT);
const i18n = useI18n();
const loading = computed(() => wait.is(['fetchSequenceMasters']));
const headers = [
  { key: 'name', name: i18n.t('sequence.name'), class: ['name-container'] },
  { key: 'summary.all', name: i18n.t('sequence.summary.all'), class: ['count-text'] },
  { key: 'summary.registered', name: i18n.t('sequence.summary.registered'), class: ['count-text'] },
  { key: 'summary.stopped', name: i18n.t('sequence.summary.stopped'), class: ['count-text'] },
  { key: 'summary.done', name: i18n.t('sequence.summary.done'), class: ['count-text'] },
  { key: 'summary.associatedOpportunity', name: i18n.t('sequence.summary.associatedOpportunity'), class: ['count-text'] },
  { key: 'summary.unregistered', name: i18n.t('sequence.summary.unregistered'), class: ['count-text'] },
  { key: 'createdUser', name: i18n.t('sequence.createdUser'), class: ['user'] },
  { key: 'updatedUser', name: i18n.t('sequence.updatedUser'), class: ['user'] },
  { key: 'owner', name: i18n.t('sequence.owner'), class: ['user'] },
];

const selectSequenceMaster = (sequenceMasterId: number) => {
  emit('selectSequenceMaster', sequenceMasterId);
}

const currentPage = ref(1);
const meta = ref<PaginationMeta>({
  totalPages: 1,
  totalCount: 0,
  limitValue: 0,
  currentPage: 1,
  isEmpty: false,
});

const { currentUser, fetchCurrentUser } = useCurrentUser();
const selectedUserId = ref<number | undefined>();

onBeforeMount(async () => {
  await fetchCurrentUser();
  selectedUserId.value = currentUser.value.id;
  await loadSequenceMasters(currentPage.value);
})

const loadSequenceMasters = async (currentPage: number) => {
  const ownerId = selectedUserId.value
  const api = new SequenceMasterApiService();
  const { data: { sequenceMasters: dataSequenceMasters, meta: dataMeta } } = await doActionWithWait<AxiosResponse<GetSequenceMasters200Response>>('fetchSequenceMasters', async () => {
    return await api.getSequenceMasters({
      request: {
        currentPage,
        ownerId,
      },
    });
  });

  sequenceMasters.value = dataSequenceMasters;
  meta.value = dataMeta;
};

const sequenceMasters = ref<SequenceMaster[]>([]);
const allPageCount = computed(() => meta.value.totalPages);

watch(() => currentPage.value, (newValue) => {
  loadSequenceMasters(newValue);
});

watch(() => selectedUserId.value, (newValue) => {
  loadSequenceMasters(currentPage.value);
});

return (_ctx: any,_cache: any) => {
  const _component_BPagination = _resolveComponent("BPagination")!
  const _component_BSortText = _resolveComponent("BSortText")!
  const _component_BUserTooltip = _resolveComponent("BUserTooltip")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(SelectUser, {
          modelValue: selectedUserId.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedUserId).value = $event))
        }, null, 8, ["modelValue"])
      ]),
      (allPageCount.value > 0)
        ? (_openBlock(), _createBlock(_component_BPagination, {
            key: 0,
            modelValue: currentPage.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((currentPage).value = $event)),
            length: allPageCount.value,
            "total-visible": 5
          }, null, 8, ["modelValue", "length"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(headers, (cell) => {
            return _createVNode(_component_BSortText, {
              key: cell.key,
              class: _normalizeClass(["table-cell header-cell", cell.class]),
              "item-text": cell.name,
              "is-display-only": ""
            }, null, 8, ["class", "item-text"])
          }), 64))
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sequenceMasters.value, (sequenceMaster) => {
          return (_openBlock(), _createElementBlock("div", {
            key: sequenceMaster.id,
            class: "table-row tbody-row",
            onClick: () => selectSequenceMaster(sequenceMaster.id)
          }, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(headers, (column) => {
              return _createElementVNode("div", {
                key: column.key,
                class: _normalizeClass(["table-cell", column.class])
              }, [
                (column.key === 'name')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(sequenceMaster.name), 1))
                  : (column.key === 'summary.all')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(sequenceMaster.numberOfInstances), 1)
                      ], 64))
                    : (column.key === 'summary.registered')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                          _createTextVNode(_toDisplayString(sequenceMaster.numberOfRegistered), 1)
                        ], 64))
                      : (column.key === 'summary.stopped')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                            _createTextVNode(_toDisplayString(sequenceMaster.numberOfStopped), 1)
                          ], 64))
                        : (column.key === 'summary.done')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                              _createTextVNode(_toDisplayString(sequenceMaster.numberOfDone), 1)
                            ], 64))
                          : (column.key === 'summary.associatedOpportunity')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                                _createTextVNode(_toDisplayString(sequenceMaster.numberOfOpportunities), 1)
                              ], 64))
                            : (column.key === 'summary.unregistered')
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                                  _createTextVNode(_toDisplayString(sequenceMaster.numberOfUnregistered), 1)
                                ], 64))
                              : (column.key === 'createdUser')
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
                                    (sequenceMaster.createdUser)
                                      ? (_openBlock(), _createBlock(_component_BUserTooltip, {
                                          key: 0,
                                          "image-url": sequenceMaster.createdUser.avatarUrl,
                                          name: sequenceMaster.createdUser.name,
                                          "user-id": sequenceMaster.createdUser.id,
                                          size: 40
                                        }, null, 8, ["image-url", "name", "user-id"]))
                                      : _createCommentVNode("", true)
                                  ], 64))
                                : (column.key === 'updatedUser')
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 8 }, [
                                      (sequenceMaster.updatedUser)
                                        ? (_openBlock(), _createBlock(_component_BUserTooltip, {
                                            key: 0,
                                            "image-url": sequenceMaster.updatedUser.avatarUrl,
                                            name: sequenceMaster.updatedUser.name,
                                            "user-id": sequenceMaster.updatedUser.id,
                                            size: 40
                                          }, null, 8, ["image-url", "name", "user-id"]))
                                        : _createCommentVNode("", true)
                                    ], 64))
                                  : (column.key === 'owner')
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 9 }, [
                                        (sequenceMaster.owner)
                                          ? (_openBlock(), _createBlock(_component_BUserTooltip, {
                                              key: 0,
                                              "image-url": sequenceMaster.owner.avatarUrl,
                                              name: sequenceMaster.owner.name,
                                              "user-id": sequenceMaster.owner.id,
                                              size: 40
                                            }, null, 8, ["image-url", "name", "user-id"]))
                                          : _createCommentVNode("", true)
                                      ], 64))
                                    : _createCommentVNode("", true)
              ], 2)
            }), 64))
          ], 8, _hoisted_6))
        }), 128))
      ])
    ])
  ])), [
    [_directive_loading, loading.value]
  ])
}
}

})
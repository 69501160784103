import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "drawer-body" }
const _hoisted_2 = { class: "mail-confirm mb-600" }
const _hoisted_3 = { class: "subject-confirm" }
const _hoisted_4 = { class: "content-confirm" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "mt-100" }

import { computed } from 'vue';
import { CommonDirectMail } from '@/api/openapi';
import MailAttachments from '@/components/organisms/user/general/MailAttachments.vue';
import { i18nGlobal as i18n } from '@/i18n';
import { SenderData } from '../types';

type TProps = {
  directMail: CommonDirectMail;
  isAvailableSalesforceApiFeature: boolean;
  senderData: SenderData | null;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ConfirmDrawerBodyCommon',
  props: {
    directMail: {},
    isAvailableSalesforceApiFeature: { type: Boolean },
    senderData: {}
  },
  setup(__props: any) {

const props = __props;

const displayMailEventOpen = computed<string>(() => {
  const message = props.directMail.notificationSetting.open ? 'receive' : 'unreceive'
  return i18n.t(`mailNotificationEvent.${message}`, { event: i18n.t(`mailNotificationEvent.open`) })
});

const displayMailEventClick = computed<string>(() => {
  const message = props.directMail.notificationSetting.click ? 'receive' : 'unreceive'
  return i18n.t(`mailNotificationEvent.${message}`, { event: i18n.t(`mailNotificationEvent.click`) })
});

return (_ctx: any,_cache: any) => {
  const _component_BListItem = _resolveComponent("BListItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.directMail.subject), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "quill-confirm",
          innerHTML: _ctx.directMail.content
        }, null, 8, _hoisted_5),
        _createVNode(MailAttachments, {
          "mail-attachment-ids": _ctx.directMail.mailAttachmentIds || [],
          "show-cross-button": false
        }, null, 8, ["mail-attachment-ids"])
      ])
    ]),
    _renderSlot(_ctx.$slots, "mailTo"),
    _createVNode(_component_BListItem, { class: "list-item mb-600" }, {
      header: _withCtx(() => [
        _createElementVNode("div", null, _toDisplayString(_ctx.$t('mail.sender')), 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(`${_ctx.senderData?.senderName} <${_ctx.senderData?.senderEmail}>`), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_BListItem, { class: "list-item mb-600" }, {
      header: _withCtx(() => [
        _createElementVNode("div", null, _toDisplayString(_ctx.$t('mail.cc')), 1)
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.directMail.sendCc, ({ email }) => {
          return (_openBlock(), _createElementBlock("div", { key: email }, _toDisplayString(email), 1))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_BListItem, { class: "list-item mb-600" }, {
      header: _withCtx(() => [
        _createElementVNode("div", null, _toDisplayString(_ctx.$t('mail.bcc')), 1)
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.directMail.sendBcc, ({ email }) => {
          return (_openBlock(), _createElementBlock("div", { key: email }, _toDisplayString(email), 1))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_BListItem, { class: "list-item mb-600" }, {
      header: _withCtx(() => [
        _createElementVNode("div", null, _toDisplayString(_ctx.$t('mailNotificationEvent.formTitle')), 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, _toDisplayString(displayMailEventOpen.value), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(displayMailEventClick.value), 1)
      ]),
      _: 1
    }),
    (_ctx.isAvailableSalesforceApiFeature)
      ? (_openBlock(), _createBlock(_component_BListItem, {
          key: 0,
          class: "list-item mb-600"
        }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})
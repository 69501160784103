<template>
  <button
    :class="[classes]"
    :disabled="disabled || loading"
    :data-test="dataTest"
    :type="btnType"
  >
    <BElementIcon
      v-if="loading"
      name="Loading"
    />
    <div
      v-else
      class="inner"
    >
      <slot />
    </div>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'medium',
    },
    position: {
      type: String,
      default: 'center',
    },
    outline: Boolean,
    text: Boolean,
    fab: Boolean,
    disabled: Boolean,
    loading: Boolean,
    shadow: Boolean,
    flat: Boolean,
    fit: Boolean,
    dataTest: String,
    btnType: {
      type: String,
      default: null,
    },
  },
  computed: {
    classes() {
      return {
        'b-btn': true,
        // Type
        'b-btn-default': this.type === 'default',
        'b-btn-primary': this.type === 'primary',
        'b-btn-white': this.type === 'white',
        'b-btn-error': this.type === 'error',
        'b-btn-warning': this.type === 'warning',
        'b-btn-success': this.type === 'success',
        'b-btn-emphasis': this.type == 'emphasis',
        // Size
        'b-btn-medium': this.size === 'medium',
        'b-btn-small': this.size === 'small',
        'b-btn-mini': this.size === 'mini',
        // Position
        'b-btn-center': this.position === 'center',
        'b-btn-right': this.position === 'right',
        'b-btn-left': this.position === 'left',
        'is-outline': this.outline,
        'is-text': this.text,
        'is-fab': this.fab,
        'is-disabled': this.disabled,
        'is-loading': this.loading,
        'is-shadow': this.shadow,
        'is-flat': this.flat,
        'is-fit': this.fit,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  $disabled-opacity: 0.4;

  .b-btn {
    display: inline-block;
    height: 36px;
    line-height: 36px;
    white-space: nowrap;
    cursor: pointer;
    box-sizing: border-box;
    text-align: center;
    outline: none;
    transition: all 200ms $standard-easing;
    font-weight: 500;
    font-size: $fontsize-200;
    padding-right: $basespace-400;
    padding-left: $basespace-400;
    border-radius: 4px;

    :deep(.inner) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.is-text {
      padding-right: 0px;
      padding-left: 0px;
    }

    &.is-fab {
      padding: 0;
      border-radius: 50%;
    }

    &.is-shadow {
      box-shadow: $boxshadow-base;

      &:hover {
        box-shadow: none;
      }
    }

    &.is-disabled,
    &.is-loading {
      cursor: not-allowed;
      opacity: $disabled-opacity;

      &:hover {
        opacity: $disabled-opacity;
      }
    }

    &.is-fit {
      display: block;
      width: 100%;
    }
  }

  // ---Size
  .b-btn-medium {
    &.is-fab {
      $size: 40px;
      @include m-fixed-width($size);
      @include m-fixed-height($size);

      :deep(.b-icon) {
        font-size: $fontsize-400;
      }
    }
  }

  .b-btn-small {
    height: 32px;
    line-height: 32px;
    font-size: $fontsize-100;

    &.is-fab {
      $size: 32px;
      @include m-fixed-width($size);
      @include m-fixed-height($size);

      :deep(.b-icon) {
        font-size: $fontsize-400;
      }
    }

    &.is-flat {
      padding-right: $basespace-200;
      padding-left: $basespace-200;
    }
  }

  .b-btn-mini {
    height: 28px;
    line-height: 28px;
    font-size: $fontsize-100;

    &.is-fab {
      $size: 28px;
      @include m-fixed-width($size);
      @include m-fixed-height($size);
    }
  }

  .b-btn-default {
    color: $textcolor-base;
    background-color: $bgcolor-white;
    border: 1px solid $bdcolor-base;

    &:not(.is-disabled):not(.is-loading):hover {
      border-color: $bdcolor-dark;
    }

    &.is-text {
      background: transparent;
      border: none;

      &:not(.is-disabled):not(.is-loading):hover {
        background: transparent;
        border: none;
        opacity: 0.5;
      }
    }

    &.is-flat {
      background: transparent;
      border: none;

      &:hover {
        background-color: $bgcolor-dark;
      }
    }
  }

  // ---Type
  .b-btn-primary {
    color: $textcolor-white;
    background-color: $basecolor-primary;
    border: 1px solid $basecolor-primary;

    &:not(.is-disabled):not(.is-loading):hover {
      opacity: 0.7;
    }

    &.is-outline {
      color: $basecolor-primary;
      background-color: $bgcolor-white;
      :deep(i) {
        color: $basecolor-primary;
      }

      &:not(.is-disabled):not(.is-loading):hover {
        opacity: 1;
        color: $textcolor-white;
        background-color: $basecolor-primary;

        :deep(i) {
          color: $textcolor-white;
        }
      }
    }

    &.is-text {
      color: $basecolor-primary;
      background: transparent;
      border: none;
    }

    &.is-fab {
      :deep(.b-icon) {
        color: $textcolor-white;
      }
    }
  }

  .b-btn-white {
    color: $textcolor-white;

    &.is-outline {
      color: $textcolor-white;
      background-color: $bgcolor-white;
    }

    &.is-text {
      color: $textcolor-white;
      border: none;
    }

    &.is-fab {
      :deep(.b-icon) {
        color: $textcolor-white;
      }
    }
  }

  .b-btn-emphasis {
    line-height: 0;
    color: $textcolor-white;
    background: $bgcolor-aurora;

    &:not(.is-disabled):not(.is-loading):hover {
      opacity: 0.7;
    }

    &.is-text {
      color: $basecolor-primary;
      background: transparent;
      border: none;
    }

    &.is-fab {
      :deep(.b-icon) {
        color: $textcolor-white;
      }
    }
  }

  $types: ("error", $basecolor-error), ("success", $basecolor-success), ("warning", $basecolor-warning);
  @each $type, $color in $types {
    .b-btn-#{$type} {
      color: $color;
      background-color: $bgcolor-white;
      border: 1px solid $color;

      &:not(.is-disabled):not(.is-loading):hover {
        opacity: 0.7;
      }

      &.is-outline {
        color: $color;
        background-color: $bgcolor-white;
        :deep(i) {
          color: $color;
        }

        &:not(.is-disabled):not(.is-loading):hover {
          opacity: 1;
          color: $textcolor-white;
          background-color: $color;

          :deep(i) {
            color: $textcolor-white;
          }
        }
      }

      &.is-text {
        color: $color;
        background: transparent;
        border: none;
      }

      &.is-fab {
        :deep(.b-icon) {
          color: $textcolor-white;
        }
      }
    }
  }

  // ---Position
  .b-btn-right {
    text-align: right;

    :deep(.inner) {
      justify-content: flex-end;
    }
  }

  .b-btn-left {
    text-align: left;

    :deep(.inner) {
      justify-content: flex-start;
    }
  }
</style>

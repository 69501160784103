import { computed, ref } from 'vue';
import { SendgridDomainApiService } from '@/api/admin/resources/sendgrid_domain';
import { SendgridDomain } from '@/api/openapi';
import { useWait } from '../vue-wait';

const DEFAULT_SENDGRID_DOMAIN_ID = 1;

const useSendgridDomains = () => {
  const { doActionWithWait } = useWait();

  const sendgridDomains = ref<SendgridDomain[]>([]);
  const domainForSelectItems = computed(() =>
    sendgridDomains.value.map(domain => ({
      value: domain.id,
      text: domain.domain,
    })),
  );

  const fetchSendgridDomains = async () => {
    await doActionWithWait('fetchSendgridSubUserByIdWait', async () => {
      const api = new SendgridDomainApiService();
      const { data } = await api.getSendgridDomains();
      sendgridDomains.value = data;
    });
  };

  return {
    fetchSendgridDomains,
    domainForSelectItems,
  };
};

export {
  DEFAULT_SENDGRID_DOMAIN_ID,
  useSendgridDomains,
}

<template>
  <div>
    <BAlert
      v-if="showConnectErrorMessage"
      :text="$t('google.errorMessage.connectError')"
      class="mb-400"
      type="error"
      effect="light"
      show-icon
    />
    <div class="api-connection">
      <template v-if="isConnect">
        <BLayout>
          <BLayout column>
            <div class="title">
              <img :src="require('@/assets/gmail.png')">
              {{ $t('google.title') }}
              <span class="status">{{ $t('google.title') }}{{ $t('google.status.connect') }}</span>
            </div>
            <div
              v-loading="settingLoading"
              class="connected-email"
            >
              {{ user?.senderEmail }}
            </div>
          </BLayout>
          <div class="more-menu">
            <BMoreMenu>
              <BBtn
                flat
                fit
                size="small"
                :disabled="settingLoading || deleting"
                @click="unconnect"
              >
                <span>{{ $t('google.disconnect') }}</span>
              </BBtn>
            </BMoreMenu>
          </div>
        </BLayout>
      </template>
      <template v-else>
        <div class="title">
          <img :src="require('@/assets/gmail.png')">
          {{ $t('google.title') }}
        </div>
        <BLayout
          v-loading="settingLoading"
          column
          class="form"
        >
          <div class="explain">
            <BIcon class="mr-100">
              check
            </BIcon>
            <span>{{ $t('google.explain') }}</span>
          </div>
          <BBtn
            type="primary"
            outline
            :disabled="settingLoading"
            @click="connect"
          >
            <span>{{ $t('google.btnText') }}</span>
            <BIcon>keyboard_arrow_right</BIcon>
          </BBtn>
        </BLayout>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, inject, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { GoogleSetting, User } from '@/api/openapi';
import { GoogleSettingService } from '@/api/user/resources/google_setting';
import { useGoogleSetting } from '@/composable/user/googleSetting/google-setting';
import { useWait } from '@/composable/vue-wait';
import { KEY_OF_WAIT, KEY_OF_TOAST } from '@/injection-keys';
import { bitterAlert } from '@/plugins/BBitterAlert';

type TProps = {
  user: User | null;
};
const props = defineProps<TProps>();
const emit = defineEmits(['update:user']);

const i18n = useI18n();
const route = useRoute();
const router = useRouter();
const wait = inject(KEY_OF_WAIT);
const api = new GoogleSettingService();
const { doActionWithWait } = useWait();
const { googleSetting, fetchGoogleSetting } = useGoogleSetting();

onMounted(async () => {
  showConnectStatus();
  await fetchGoogleSetting();
});

const showConnectStatus = async () => {
  if (route.query.connect_status) {
    const serviceName = i18n.t('oauthCallback.service.google');

    const title = route.query.connect_status === 'true'
      ? i18n.t('oauthCallback.linkComplete', { serviceName })
      : i18n.t('oauthCallback.linkFailure', { serviceName });

    const text = route.query.failure_reason === 'invalid_scope'
      ? i18n.t('oauthCallback.failureReason.invalidScope')
      : '';

    await bitterAlert.show({ title, text, buttonsCancel: false });

    // connect_status を history から削る
    router.replace({
      name: 'Setting',
      params: { tab: route.params.tab, subTab: route.params.subTab },
    })
  }
}

const settingLoading = computed(() =>
  wait.is(['getGoogleSettingWait']),
);
const deleting = computed(() =>
  wait.is(['deleteGoogleSettingWait']),
);
const isConnect = computed(() =>
  googleSetting.value?.connectStatus,
);
const showConnectErrorMessage = computed(() =>
  googleSetting.value?.isGmailImport === false,
);

const connect = async () => {
  const { data } = await api.getGoogleAuthorizationUrl();
  location.href = data.url;
};

const unconnect = async () => {
  const check = await bitterAlert.show({
    title: i18n.t('google.disconnect'),
    text: i18n.t('google.disconnectConfirmText', {
      currentSenderEmail: props.user?.senderEmail,
      afterSenderEmail: props.user?.email,
    }),
  })
  if (!check) { return }

  await doActionWithWait('deleteGoogleSettingWait', async () => {
    await api.deleteGoogleSetting();
    await fetchGoogleSetting();
  });

  emit('update:user');

  window.location.reload(true);
};
</script>

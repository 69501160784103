import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "custom-action-options-container" }
const _hoisted_2 = { class: "option-container" }
const _hoisted_3 = { class: "append-option" }

import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { CustomActionOption } from '@/api/openapi';
import { CustomActionOptionApiService } from '@/api/user/resources/custom_action_option';
import { useInputValidations } from '@/composable/input-validations';
import { useWait } from '@/composable/vue-wait';
import { bitterAlert } from '@/plugins/BBitterAlert';

type TEmit = {
  (event: 'afterSave'): void;
};
type TProps = {
  customActionId: number,
  customActionOptions: CustomActionOption[],
  loading?: boolean, // 名前の更新時、前の値がチラつくのを防止するために使っている。
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomActionItemOptions',
  props: {
    customActionId: {},
    customActionOptions: {},
    loading: { type: Boolean, default: false }
  },
  emits: ["afterSave"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const { wait, doActionWithWait } = useWait();
const { requiredRule } = useInputValidations();
const i18n = useI18n();

const isCreatingInputVisible = ref(false);
const creatingInputValue = ref('');

const handleUpdateNameClick = async (name: string, customActionOptionId: number) => {
  await doActionWithWait(`updateCustomActionOptionWait-${customActionOptionId}`, async () => {
    const api = new CustomActionOptionApiService();
    await api.updateCustomActionOption(
      {
        request: {
          customActionOptionId,
          putCustomActionOptionBody: {
            name,
          },
        },
      },
    );
    emit('afterSave');
  });
};

const handleDeleteClick = async (customActionOptionId: number) => {
  const customAction = props.customActionOptions.find((option) => option.id === customActionOptionId);
  const isAccepted = await bitterAlert.show({
    text: i18n.t('general.confirm.actionWithTarget', { target: customAction.name, action: i18n.t('general.delete.text') }),
  });
  if (!isAccepted) return;
  await doActionWithWait('deleteCustomActionOptionWait', async () => {
    const api = new CustomActionOptionApiService();
    await api.deleteCustomActionOption(
      {
        request: {
          customActionOptionId,
        },
      },
    );
    emit('afterSave');
  });
};
const handleAppendClick = () => {
  isCreatingInputVisible.value = true;
};
const handleCreateingInputCheckClick = async () => {
  await doActionWithWait('createCustomActionOptionWait', async () => {
    const api = new CustomActionOptionApiService();
    await api.createCustomActionOption(
      {
        request: {
          customActionId: props.customActionId,
          postCustomActionOptionBody: {
            name: creatingInputValue.value,
          },
        },
      },
    );
    emit('afterSave');
  });
  clearCreatingInput();
};
const handleCreateingInputCloseClick = () => {
  clearCreatingInput();
};
const clearCreatingInput = () => {
  isCreatingInputVisible.value = false;
  creatingInputValue.value = '';
};

return (_ctx: any,_cache: any) => {
  const _component_BEditableContent = _resolveComponent("BEditableContent")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BPopover = _resolveComponent("BPopover")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BInputBox = _resolveComponent("BInputBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customActionOptions, (option) => {
      return (_openBlock(), _createElementBlock("div", {
        key: option.id
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BEditableContent, {
            value: option.name,
            rules: [_unref(requiredRule)],
            "max-length": 175,
            loading: _unref(wait).is(`updateCustomActionOptionWait-${option.id}`) || _ctx.loading,
            onCheck: (inputValue) => handleUpdateNameClick(inputValue, option.id)
          }, null, 8, ["value", "rules", "loading", "onCheck"]),
          _createVNode(_component_BBtn, { text: "" }, {
            default: _withCtx(() => [
              (!option.isDeletable)
                ? (_openBlock(), _createBlock(_component_BPopover, {
                    key: 0,
                    placement: "top",
                    width: "240",
                    trigger: "hover"
                  }, {
                    reference: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createVNode(_component_BIcon, {
                          class: "px-200",
                          size: "small",
                          type: "gray"
                        }, {
                          default: _withCtx(() => _cache[1] || (_cache[1] = [
                            _createTextVNode(" close ")
                          ])),
                          _: 1
                        })
                      ])
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('general.alreadyUsed')), 1)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_BIcon, {
                    key: 1,
                    class: "px-200",
                    size: "small",
                    type: "gray",
                    onClick: () => handleDeleteClick(option.id)
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" close ")
                    ])),
                    _: 2
                  }, 1032, ["onClick"]))
            ]),
            _: 2
          }, 1024)
        ])
      ]))
    }), 128)),
    (isCreatingInputVisible.value)
      ? (_openBlock(), _createBlock(_component_BInputBox, {
          key: 0,
          modelValue: creatingInputValue.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((creatingInputValue).value = $event)),
          class: "creating-input",
          horiz: "",
          required: "",
          loading: _unref(wait).is(['createCustomActionOptionWait']),
          onCheck: handleCreateingInputCheckClick,
          onClose: handleCreateingInputCloseClick
        }, null, 8, ["modelValue", "loading"]))
      : _createCommentVNode("", true),
    _createVNode(_component_BBtn, {
      type: "primary",
      text: "",
      size: "small",
      onClick: handleAppendClick
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('general.append.of', {item: _ctx.$t('general.option')})), 1)
      ]),
      _: 1
    })
  ]))
}
}

})
const MAIL_STATUSES = {
  DRAFT: 'draft',
  SENDING: 'sending',
  SENDING_FAILED: 'sending_failed',
  SEND_REQUEST: 'send_request',
  PENDING: 'pending',
  SENT: 'sent',
  READY: 'ready',
} as const

export default MAIL_STATUSES

import Waiting from '@/store/waiting'

export default class SendgridApActions {
  constructor(Api, types) {
    const actions = {
      async getSendgridApiKeysAction({ commit }, args) {
        await waiting.check('getSendgridApiKeysAction', async () => {
          try {
            const res = await Api.getSendgridApiKeys(args)
            commit(types.SET_SENDGRID_API_KEYS, Object.values(res.data)[0])
          } catch (err) {
            commit(types.CLEAR_SENDGRID_API_KEYS)
            throw err
          }
        })
      },
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

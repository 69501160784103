<template>
  <div
    class="placeholder"
    :style="styles"
  />
</template>

<script>
export default {
  name: 'BPlaceholder',
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '200px',
    },
    backgroundColor: {
      type: String,
      default: 'transparent',
    },
  },
  computed: {
    styles () {
      return {
        'width': this.width,
        'height': this.height,
        'background-color': this.backgroundColor,
      }
    },
  },
}
</script>

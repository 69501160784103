<template>
  <BHeightAdjuster>
    <div class="custom-action-item-container">
      <div
        v-loading="loading"
        class="list-container"
      >
        <BEmptyBox
          v-if="!draggableItems.length"
          class="py-400"
          display-only
        >
          <slot name="text">
            {{ $t('customAction.notFound') }}
          </slot>
        </BEmptyBox>
        <Draggable
          v-else
          v-model="draggableItems"
          class="item-row"
          item-key="id"
          handle=".handle"
          :animation="300"
          @change="handleSortOrderChange"
        >
          <template #item="{ element: customAction }">
            <div>
              <BLayout
                class="py-100"
                align-center
                justify-space-between
              >
                <BLayout
                  class="ml-100 mr-100"
                  align-center
                >
                  <span class="handle-wrap">
                    <BIcon
                      class="handle"
                      size="small"
                      type="gray"
                    >
                      drag_indicator
                    </BIcon>
                  </span>
                  <BDataTypeIcon data-type="option" />
                  <BEditableContent
                    :value="customAction.name"
                    :placeholder="$t('customAction.name')"
                    :rules="[requiredRule]"
                    :max-length="255"
                    :loading="wait.is(`updatingCustomActionWait-${customAction.id}`)"
                    @check="(inputValue) => handleUpdateNameClick(inputValue, customAction.id)"
                  />
                </BLayout>
                <span class="more-menu-wrap">
                  <BMoreMenu>
                    <BPopover
                      v-if="!customAction.isDeletable"
                      placement="top"
                      width="240"
                      trigger="hover"
                    >
                      <template #reference>
                        <div>
                          <BBtn
                            flat
                            fit
                            size="small"
                            disabled
                          >
                            {{ $t('general.delete.do') }}
                          </BBtn>
                        </div>
                      </template>
                      <div>{{ $t('general.alreadyUsed') }}</div>
                    </BPopover>
                    <BBtn
                      v-else
                      flat
                      fit
                      size="small"
                      @click="() => handleDeleteClick(customAction.id)"
                    >
                      {{ $t('general.delete.do') }}
                    </BBtn>
                  </BMoreMenu>
                </span>
              </BLayout>
              <CustomActionItemOptions
                class="custom-action-item-options-container"
                :custom-action-id="customAction.id"
                :custom-action-options="customAction.customActionOptions"
                :loading="loading"
                @after-save="fetchCustomActions"
              />
            </div>
          </template>
        </Draggable>
      </div>
      <div class="creating-form-container">
        <CustomActionCreatingForm @after-create="fetchCustomActions" />
      </div>
    </div>
  </BHeightAdjuster>
</template>

<script lang="ts" setup>
import lodash from 'lodash';
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Draggable from 'vuedraggable';
import { CustomAction } from '@/api/openapi';
import { CustomActionApiService } from '@/api/user/resources/custom_action';
import { useInputValidations } from '@/composable/input-validations';
import { useCustomActions } from '@/composable/user/customAction/custom-actions';
import { useWait } from '@/composable/vue-wait';
import { bitterAlert } from '@/plugins/BBitterAlert';
import CustomActionCreatingForm from './CustomActionCreatingForm.vue';
import CustomActionItemOptions from './CustomActionItemOptions.vue';

const { requiredRule } = useInputValidations();
const { wait, doActionWithWait } = useWait();
const i18n = useI18n();

const { customActions, fetchCustomActions } = useCustomActions();

const draggableItems = ref<CustomAction[]>([]);
watch(customActions, (newVal) => {
  draggableItems.value = lodash.cloneDeep(newVal);
}, { immediate: true });

const handleUpdateNameClick = async (name: string, customActionId: number) => {
  await doActionWithWait(`updatingCustomActionWait-${customActionId}`, async () => {
    const api = new CustomActionApiService();
    await api.updateCustomAction(
      {
        request: {
          customActionId,
          putCustomActionBody: {
            name,
          },
        },
      },
    );
    await fetchCustomActions(); // 前の値がチラつくのを防止するために、「doActionWithWait」の中に入れている。
  });
};

const handleSortOrderChange = async (event) => {
  if (!event.moved) return;
  const sortOrder = customActions.value[event.moved.newIndex].sortOrder
  const dragingItem = event.moved.element;
  const customActionId = dragingItem.id;
  await doActionWithWait('updatingCustomActionsWait', async () => {
    const api = new CustomActionApiService();
    await api.updateCustomAction(
      {
        request: {
          customActionId,
          putCustomActionBody: {
            sortOrder,
          },
        },
      },
    );
    await fetchCustomActions();
  });
};

const handleDeleteClick = async (customActionId: number) => {
  const customAction = customActions.value.find((action) => action.id === customActionId);
  const isAccepted = await bitterAlert.show({
    text: i18n.t('general.confirm.actionWithTarget', { target: customAction.name, action: i18n.t('general.delete.text') }),
  });
  if (!isAccepted) return;
  await doActionWithWait('deletingCustomActionWait', async () => {
    const api = new CustomActionApiService();
    await api.deleteCustomAction({ request: { customActionId } });
    await fetchCustomActions();
  });
};

const loading = computed(() => {
  return wait.is(['listCustomActionsWait', 'deletingCustomActionWait', 'updatingCustomActionsWait'])
});
</script>

<style lang="scss" scoped>
.custom-action-item-container {
  margin: 20px 0;
  padding: 0 22px;
  .list-container {
    overflow-y: auto;
    width: 100%;
    height: calc(100vh - 240px);
    .item-row {
      overflow-wrap: break-word;
      word-break: break-all;
      margin: 0;
      @media only screen and (min-width: 959px) {
        width: 50%;
      }
    }
  }

  .handle {
    cursor: grab;
    flex-shrink: 0;
  }
  .handle-wrap {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }

  .custom-action-item-options-container {
    margin-left: 60px;
  }

  .creating-form-container {
    margin-top: 24px;
    padding: 0px 32px;
  }
}
</style>

<template><div /></template>

<script>
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import UserService from '@/services/user_service'

export default {
  name: 'ClientExpiredNotification',
  computed: {
    ...mapGetters('user', [
      'currentUser',
      'client',
    ]),
  },
  async created() {
    await this.getCurrentUserAction({
      errorHandlers: {
        401: this.forceSignOut,
        403: this.forceSignOut,
      }
    })
    this.displayNotification()
    this.timerObj = this.$setInterval(() => {
      this.displayNotification()
    }, 30000)
  },
  beforeUnmount() {
    this.$clearInterval(this.timerObj)
  },
  methods: {
    ...mapWaitingActions('user', {
      getCurrentUserAction: 'getCurrentUserWait',
      getClientAction: 'getClientWait',
    }),
    async displayNotification() {
      await this.getClientAction(
        {
          id: this.currentUser.clientId,
          errorHandlers: {
            401: this.forceSignOut,
            403: this.forceSignOut,
          },
        },
      )
      if (new Date(this.client.expiredAt) < new Date()) {
        localStorage.setItem('errorName', 'clientExpiredError')
        localStorage.setItem('errorClientName', this.client.name)
        this.forceSignOut()
      }
    },
    forceSignOut() {
      this.userService = new UserService()
      this.userService.signout().finally(() => {
        this.$router.push({ name: 'UserSignin' })
      })
    },
  },
}
</script>

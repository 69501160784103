<template>
  <FilterDetailPopover
    v-model:is-include="isInclude"
    :title="title"
    :is-display-only="isDisplayOnly"
    :key-name="keyName"
    @hide="popoverHide"
    @show="popoverShow"
  >
    <template #reference>
      <BLayout align-center>
        <BListItem>
          <template #header>
            <BLayout justify-space-between>
              <div>{{ title }}</div>
            </BLayout>
          </template>
          <div class="days">
            {{ valueForDisplay }}
          </div>
        </BListItem>
      </BLayout>
    </template>
    <div class="number-input-container">
      <BNumberInput
        v-model="inputStartDay"
      />
      <span class="number-input-assist-text">{{ $t('general.startDay') }}</span>
    </div>
    <div class="number-input-container mt-300">
      <BNumberInput
        v-model="inputEndDay"
      />
      <span class="number-input-assist-text">{{ $t('general.endDay') }}</span>
    </div>  
  </FilterDetailPopover>
</template>

<script lang="ts" setup>
import lodash from 'lodash'
import { computed, ref } from 'vue';
import FilterDetailPopover from '@/components/organisms/user/leadList/filter/FilterDetailPopover.vue'
import { i18nGlobal as i18n } from '@/i18n';

// TODO: leadListFilterParamsの型定義を共通化する
type TProps = {
  leadListFilterParams?: Record<string, {
    inc: {
      or: string[];
    };
    exc: {
      or: string[];
    };
  }>;
  isDisplayOnly?: boolean;
  keyName: string; 
}

type TEmits = {
  (e: 'hide', value: object): void;
}

const props = withDefaults(defineProps<TProps>(), {
  leadListFilterParams: null,
  isDisplayOnly: false,
})

const emit = defineEmits<TEmits>();

const isInclude = ref(true);
// NOTE: 文字列型の半角英数 or '' が入る
const inputEndDay = ref<string>('');
const inputStartDay = ref<string>('');

const title = computed(() => {
  return i18n.t(`filterSelect.historyInfo.item.${props.keyName}`)
})

const valueForDisplay = computed(() => {
  const startDay = lodash.isEmpty(targetDetail.value[0]) ? '' : targetDetail.value[0] + i18n.t('general.startDay')
  const endDay = lodash.isEmpty(targetDetail.value[1]) ? '' : targetDetail.value[1] + i18n.t('general.endDay')
  if (startDay === '' && endDay === '') return '-'
  return startDay + endDay
})

const target = computed(() => {
  if (props.leadListFilterParams == null) return null
  return props.leadListFilterParams[props.keyName]
})

const targetDetail = computed(() => {
  if (target.value == null) return []
  return target.value.inc.or
})

const popoverShow = () => {
  if (targetDetail.value.length === 2) {
    inputStartDay.value = lodash.isEmpty(targetDetail.value[0]) ? '' : targetDetail.value[0]
    inputEndDay.value = lodash.isEmpty(targetDetail.value[1]) ? '' : targetDetail.value[1]
  }
  isInclude.value = true
}

const popoverHide = () => {
  const grouping = isInclude.value ? 'inc' : 'exc'
  emit('hide', { [grouping]: { or: makeQueryObject() } })
}

const makeQueryObject = () => {
  const startDay = lodash.isEmpty(inputStartDay.value) ? '' : inputStartDay.value
  const endDay = lodash.isEmpty(inputEndDay.value) ? '' : inputEndDay.value
  return [startDay, endDay]
}
</script>

<style lang="scss" scoped>
.number-input-container {
  display: flex;
  line-height: var(--el-component-size);
  .number-input {
    flex: auto;
    width: 5rem;
  }
  .number-input-assist-text {
    padding-left: 8px;
  }
}
</style>

<template>
  <div class="member-table">
    <ElTable
      :data="tableData"
      show-summary
      :summary-method="getSummaries"
      row-class-name="table-row"
      style="width: 100%;"
      :height="600"
    >
      <ElTableColumn
        width="200"
        fixed
        prop="name"
        :label="$t('general.username')"
      />
      <ElTableColumn
        width="200"
        sortable
        prop="operatingTime"
        :label="$t('callAnalytics.operatingTime')"
        :formatter="toTimeFormatter"
      />
      <ElTableColumn
        width="200"
        sortable
        prop="callTime"
        :label="$t('callAnalytics.callTime')"
        :formatter="toTimeFormatter"
      />
      <ElTableColumn
        width="200"
        sortable
        prop="callCount"
        :label="$t('callAnalytics.callCount')"
      />
      <ElTableColumn
        width="200"
        sortable
        prop="reachCount"
        :label="$t('callAnalytics.reachCount')"
      />
      <ElTableColumn
        width="200"
        sortable
        prop="opportunityCount"
        :label="$t('callAnalytics.opportunityCount')"
      />
      <ElTableColumn
        width="200"
        sortable
        prop="totalOpportunityAmout"
        :label="$t('callAnalytics.totalOpportunityAmout')"
        :formatter="toCurrencyFormatter"
      />
      <ElTableColumn
        width="250"
        sortable
        prop="reachCountParCallCount"
        :label="$t('callAnalytics.reachCountParCallCount')"
        :formatter="toPercentFormatter"
      />
      <ElTableColumn
        width="250"
        sortable
        prop="opportunityCountParReachCount"
        :label="$t('callAnalytics.opportunityCountParReachCount')"
        :formatter="toPercentFormatter"
      />
      <ElTableColumn
        width="250"
        sortable
        prop="opportunityCountParCallCount"
        :label="$t('callAnalytics.opportunityCountParCallCount')"
        :formatter="toPercentFormatter"
      />
      <ElTableColumn
        width="200"
        sortable
        prop="operatingTimeParCall"
        :label="$t('callAnalytics.operatingTimeParCall')"
        :formatter="toTimeFormatter"
      />
      <ElTableColumn
        width="200"
        sortable
        prop="callTimeParCall"
        :label="$t('callAnalytics.callTimeParCall')"
        :formatter="toTimeFormatter"
      />
      <ElTableColumn
        width="200"
        sortable
        prop="callCountParHour"
        :label="$t('callAnalytics.callCountParHour')"
      />
      <ElTableColumn
        width="200"
        sortable
        prop="callTimeParReach"
        :label="$t('callAnalytics.callTimeParReach')"
        :formatter="toTimeFormatter"
      />
    </ElTable>
  </div>
</template>

<script lang="ts">
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { defineComponent } from 'vue';

dayjs.extend(utc)

export default defineComponent({
  name: 'MemberTable',
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  methods: {
    toCurrencyFormatter (row, column) {
      return `${row[column.property].toLocaleString()}${this.$t('general.currency')}`
    },
    toPercentFormatter (row, column) {
      return `${row[column.property]}%`
    },
    toTimeFormatter (row, column) {
      return this.toTimeFormat(row[column.property])
    },
    toTimeFormat (value: number) {
      const time = dayjs.unix(value).utc()
      const dateCount = time.get('date')
      if (dateCount === 1) {
        return time.format('H:mm:ss')
      } else {
        const separatedTime = time.format('H:mm:ss').split(':')
        const hours = Number(separatedTime[0]) + 24 * (dateCount -1) // 数値化したhourに経過日数(dateCount -1)をhour化したものを足す
        return `${hours}:${separatedTime[1]}:${separatedTime[2]}`
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('general.total')
          return;
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value)) && !this.isPercentColumn(column.property)) {
          sums[index] = this.sumColumn(values, column.property)
        } else {
          sums[index] = 'N/A'
        }
      });

      return sums;
    },
    isCurrencyColumn (columnName) {
      return ['totalOpportunityAmout',].includes(columnName)
    },
    isPercentColumn (columnName) {
      return ['reachCountParCallCount', 'opportunityCountParReachCount', 'opportunityCountParCallCount'].includes(columnName)
    },
    isTimeColumn (columnName) {
      return ['operatingTime', 'callTime', 'operatingTimeParCall', 'callTimeParCall', 'callTimeParReach'].includes(columnName)
    },
    sumColumn (values, columnName) {
      const isTimeColumn = this.isTimeColumn(columnName)
      const isCurrencyColumn = this.isCurrencyColumn(columnName)
      const sum = values.reduce((prev, curr) => {
        const value = Number(curr)
        if (!isNaN(value)) {
          return prev + curr
        } else {
          return prev
        }
      }, 0)
      let result = sum
      if (isCurrencyColumn) {
        result = `${result.toLocaleString()}${this.$t('general.currency')}`
      } else if (isTimeColumn) {
        result = this.toTimeFormat(sum)
      }
      return result
    },
  },
});
</script>

<style scoped lang="scss">
  :deep(.el-table) {
    thead {
      color: #000;
    }
    .table-row {
      background-color: #fff;
    }
    .el-table__body-wrapper {
      td.el-table-fixed-column--left {
        background-color: #fafafa;
      }
    }
    .el-table__footer-wrapper {
      td.el-table-fixed-column--left {
        background-color: var(--el-table-row-hover-bg-color);
      }
    }
  }
</style>

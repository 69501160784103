import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

type CustomActionOptionValue = {
  customActionName: string;
  customActionId: number;
  valueLabel: string;
};

type TProps = {
  customActionValues: CustomActionOptionValue[];
};

export default /*@__PURE__*/_defineComponent({
  __name: 'CustomActionValues',
  props: {
    customActionValues: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_BListItem = _resolveComponent("BListItem")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customActionValues, (customActionValue) => {
    return (_openBlock(), _createElementBlock("div", {
      key: customActionValue.customActionId
    }, [
      _createVNode(_component_BListItem, { class: "mb-300" }, {
        header: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(customActionValue.customActionName), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(customActionValue.valueLabel || '-'), 1)
        ]),
        _: 2
      }, 1024)
    ]))
  }), 128))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "b-date-range-form" }

import { Dayjs, isDayjs } from 'dayjs';
import { computed } from 'vue';

type TDateType = 'daterange' | 'datetimerange';
type TFormat = null | 'YYYY-MM-DD HH:mm'
type TProps = {
  modelValue: Date[];
  type?: TDateType;
  defaultTime?: Date[];
  teleported?: boolean;
};

type TEmits = {
  'update:modelValue': [date: Date[]];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BDateRangeForm',
  props: {
    modelValue: {},
    type: { default: 'daterange' },
    defaultTime: { default: () => {
    const from = new Date()
    const to = new Date()
    from.setHours(0, 0, 0)
    to.setHours(23, 59, 59)
    return [
      from,
      to,
    ]
  } },
    teleported: { type: Boolean, default: true }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const formats = {
  daterange: null,
  datetimerange: 'YYYY-MM-DD HH:mm',
};

const date = computed({
  get () {
    return props.modelValue
  },
  set (v: Date[] | Dayjs[] | null) {
    // clearボタンを押下すると配列でなく突如`null`になるので空配列で上書きする
    if (v === null) v = []

    // ElementPlusの内部でdayjsが使われており、直接入力をすると、dayjsオブジェクト（https://day.js.org/）が返却される（不具合？）。
    // この問題自体は「value-formatを指定する」でも解消可能だが、影響範囲が大きいのと、
    // Dateオブジェクトの方が扱いやすいことが多い気がするため、Dateオブジェクトへ変換する方針とした。
    // 変換方法は、「new Date(v)」でもいけたが、一応公式に従っておく（https://day.js.org/docs/en/display/as-javascript-date）。
    const converteds = v.map((e: Date | Dayjs): Date => {
      if (isDayjs(e)) return e.toDate()
      return e
    })

    // ライブラリ側に全て任せると、以下問題により、いい感じにやってくれないケースがあるため、非表示桁以下の値は自前で調整する。
    // - Fromのミリ秒について、"000"や"999"ではなく、"現在のミリ秒"が設定される。
    // - カレンダーから選択した場合は、Toの非表示桁以下が"59"になるが、手動入力した場合は"00"となる。
    const from = converteds[0]
    const to = converteds[1]
    if (props.type === 'daterange') {
      converteds.length && clearTimes(from, to);
    }
    if (props.type === 'datetimerange') {
      converteds.length && clearMilliseconds(from, to);
    }
    if (format.value === 'YYYY-MM-DD HH:mm') {
      converteds.length && clearSeconds(from, to);
    }
    emit('update:modelValue', converteds)
  },
});

const format = computed((): TFormat => {
  return formats[props.type]
});

const clearMilliseconds = (fromDate: Date, toDate: Date) => {
  fromDate.setMilliseconds(0);
  toDate.setMilliseconds(999);
};

const clearSeconds = (fromDate: Date, toDate: Date) => {
  fromDate.setSeconds(0);
  toDate.setSeconds(59);
};

const clearTimes = (fromDate: Date, toDate: Date) => {
  fromDate.setHours(0);
  fromDate.setMinutes(0);
  toDate.setHours(23);
  toDate.setMinutes(59);
  clearSeconds(fromDate, toDate);
  clearMilliseconds(fromDate, toDate);
};

return (_ctx: any,_cache: any) => {
  const _component_ElDatePicker = _resolveComponent("ElDatePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElDatePicker, {
      modelValue: date.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((date).value = $event)),
      type: _ctx.type,
      "start-placeholder": _ctx.$t('general.start'),
      "end-placeholder": _ctx.$t('general.end'),
      "default-time": _ctx.defaultTime,
      format: format.value,
      teleported: _ctx.teleported
    }, null, 8, ["modelValue", "type", "start-placeholder", "end-placeholder", "default-time", "format", "teleported"])
  ]))
}
}

})
import { onUnmounted, ref } from 'vue';

const useFileSelect = (acceptedTypes: string[] = []) => {

  const file = ref<File | null>(null);

  const handleAfterAttachFileSelect = () => {
    file.value = fileHolder.files[0];
  }

  const fileHolder = document.createElement('input');
  fileHolder.setAttribute('type', 'file');
  if (acceptedTypes.length > 0) {
    fileHolder.setAttribute('accept', acceptedTypes.join(','));
  }
  fileHolder.setAttribute('style', 'visibility:hidden;position:fixed;');
  fileHolder.onchange = handleAfterAttachFileSelect;
  document.body.appendChild(fileHolder);

  onUnmounted(() => {
    document.body.removeChild(fileHolder);
  })

  const openFileSelection = () => {
    fileHolder.click();
  }


  return {
    file,
    openFileSelection,
  };
};

export {
  useFileSelect,
}

<template>
  <div class="theader">
    <div class="table-row theader-row">
      <div
        v-for="header in headers"
        :key="header.key"
        class="table-cell header-cell"
      >
        {{ header.name }}
      </div>
    </div>
  </div>
  <VWait for="fetchSummary">
    <template #waiting>
      <BPlaceholder
        v-loading="true"
        element-loading-background="transparent"
      />
    </template>
    <div
      v-if="sequenceMaster"
      class="tbody"
    >
      <div class="table-row tbody-row">
        <div class="table-cell">
          {{ sequenceMaster.numberOfInstances }}
        </div>
        <div class="table-cell">
          {{ sequenceMaster.numberOfRegistered }}
        </div>
        <div class="table-cell">
          {{ sequenceMaster.numberOfStopped }}
        </div>
        <div class="table-cell">
          {{ sequenceMaster.numberOfDone }}
        </div>
        <div class="table-cell">
          {{ sequenceMaster.numberOfOpportunities }}
        </div>
        <div class="table-cell">
          {{ sequenceMaster.numberOfUnregistered }}
        </div>
        <BBtn
          class="apply-to-lead-button"
          type="primary"
          size="small"
          @click="handleApplyLeadButtonClick"
        >
          <span>{{ $t('sequence.applyToLead') }}</span>
        </BBtn>
      </div>
    </div>
  </VWait>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useSequenceMaster } from '@/composable/user/sequence/sequence-master';

type TProps = {
  sequenceMasterId: number;
};
type TEmits = {
  (event: 'click:applyToLeadButton'): void;
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmits>();

const i18n = useI18n();
const {
  sequenceMaster,
  fetchSequenceMaster,
} = useSequenceMaster(props.sequenceMasterId);

const headers = [
  { key: 'all', name: i18n.t('sequence.summary.all') },
  { key: 'registered', name: i18n.t('sequence.summary.registered') },
  { key: 'stopped', name: i18n.t('sequence.summary.stopped') },
  { key: 'done', name: i18n.t('sequence.summary.done') },
  { key: 'associatedOpportunity', name: i18n.t('sequence.summary.associatedOpportunity') },
  { key: 'unregistered', name: i18n.t('sequence.summary.unregistered') },
];

fetchSequenceMaster('fetchSummary');

const handleApplyLeadButtonClick = () => {
  emit('click:applyToLeadButton');
};
</script>

<style lang="scss" scoped>
.table-row {
  display: flex;
  align-items: center;
  padding-right: $basespace-400;


  .apply-to-lead-button {
    margin-left: auto;
  }
}

.table-cell {
  text-align: center;
  padding-right: $basespace-200;
  padding-left: 10px;
  min-width: 5rem;
  width: 5rem;
  background-color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:first-child {
    padding-left: $basespace-600;
    width: calc(5rem + $basespace-600 - 10px);
    margin-left: -12px;
  }
}

.theader {
  &-row {
    background-color: white;
    border-bottom: 1px solid $bdcolor-base;
    padding-top: $basespace-200;
    padding-bottom: $basespace-200;
    color: $textcolor-light;
    font-size: $fontsize-100;
  }
}

.tbody {
  &-row {
    border-bottom: 1px solid $bdcolor-base;
    padding-top: $basespace-200;
    padding-bottom: $basespace-200;
    font-weight: bold;
    font-size: $fontsize-500;
  }
}
</style>

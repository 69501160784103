import { useI18n } from 'vue-i18n';
import { bitterAlert } from '@/plugins/BBitterAlert';

type TParams = {
  title?: string;
  message: string;
};
const useConfirm = () => {
  const confirm = async ({ title, message }: TParams) => {
    return await bitterAlert.show({
      title,
      text: message,
    }) as boolean;
  };
  return { confirm };
};
const useAlert = () => {
  const alert = async ({ title, message }: TParams) => {
    return await bitterAlert.show({
      title,
      text: message,
      buttonsCancel: false,
    }) as boolean;
  };
  return { alert };
};
const useDeleteConfirm = () => {
  const i18n = useI18n();
  const { confirm } = useConfirm();
  const deleteConfirm = async () => {
    return await confirm({
      message: i18n.t('general.delete.message'),
    });
  };
  return { deleteConfirm };
};
const useUnsavedConfirm = () => {
  const i18n = useI18n();
  const { confirm } = useConfirm();
  const unsavedConfirm = async () => {
    return await confirm({
      message: i18n.t('general.alert.of', { target: i18n.t('general.unsavedValue'), action: i18n.t('general.clear') }),
    });
  };
  return { unsavedConfirm };
};

export {
  useConfirm,
  useAlert,
  useDeleteConfirm,
  useUnsavedConfirm,
}

<template>
  <BModal
    :modal-name="modalName"
    full
  >
    <BHeightAdjuster>
      <BLayout
        align-center
        class="wrap-header"
      >
        <BBtn
          v-if="[state.mappingField, state.confirm].includes(currentState)"
          class="btn-back-step"
          size="small"
          fab
          flat
          @click="handleBackStep"
        >
          <BIcon
            size="small"
            bold
          >
            keyboard_arrow_left
          </BIcon>
        </BBtn>
        <BModalHeader
          :title="$t('import.salesforce.title')"
          center
          @modal-close="hide"
        />
      </BLayout>
      <BModalBody v-loading="$wait.is(waitName)">
        <BContainer>
          <div class="progress-status">
            <div class="progress-point-line">
              <div class="point active">
                1
              </div>
              <div
                class="point-line"
                :class="{ active: currentState >= state.mappingField }"
              />
              <div
                class="point"
                :class="{ active: currentState >= state.mappingField }"
              >
                2
              </div>
              <div
                class="point-line"
                :class="{ active: currentState >= state.confirm }"
              />
              <div
                class="point"
                :class="{ active: currentState >= state.confirm }"
              >
                3
              </div>
              <div
                class="point-line"
                :class="{ active: currentState >= state.completed }"
              />
              <div
                class="point"
                :class="{ active: currentState >= state.completed }"
              >
                4
              </div>
            </div>
            <div class="progress-point-text">
              <div class="progress-text">
                {{ $t('import.salesforce.header.importTarget') }}
              </div>
              <div class="progress-text">
                {{ $t('import.salesforce.header.mapping') }}
              </div>
              <div class="progress-text">
                {{ $t('import.salesforce.header.confirm') }}
              </div>
              <div class="progress-text">
                {{ $t('import.salesforce.header.complete') }}
              </div>
            </div>
          </div>
          <div class="modal-message mb-400">
            <div>{{ modalMessage }}</div>
          </div>
          <div
            v-if="currentState === state.searchForm"
            class="salesforce-report-search"
          >
            <div class="grid">
              <SalesforceReportSearch
                v-model:selected-id="selectedSfReportId"
                v-model:selected-report="selectedReport"
                v-model:exec-setting="execSetting"
                :label="$t('import.salesforce.formLabel')"
                :search="searchReportByName"
                @select-sf-report="selectSfReport"
              />
            </div>
            <div class="schedule-area">
              <div class="schedule-header">
                {{ $t('import.salesforce.execScheduleSettingHeader') }}
              </div>
              <div
                class="schedule-body"
              >
                <BRadio
                  v-for="(execType, index) in execTypes"
                  :key="index"
                  v-model="execSetting"
                  :label="execType"
                  class="schedule-box"
                >
                  {{ $t(`import.salesforce.execScheduleSetting.${execType}`) }}
                </BRadio>
              </div>
            </div>
          </div>
          <div
            v-if="currentState === state.mappingField"
            class="salesforce-report-field"
          >
            <SalesforceReportMapping
              :selected-object="currentSfReport.mappingObjectKey"
              @back="backAction"
            />
          </div>
          <div
            v-if="currentState === state.confirm"
            class="salesforce-import-list"
          >
            <SalesforceImportList
              :sf-records="sfRecords"
              :import-count="importCount"
            />
          </div>
          <template v-if="currentState === state.completed">
            <div class="done">
              <div class="loader" />
              <div class="title">
                {{ $t(`callList.upload.accept.title`) }}
              </div>
            </div>
          </template>
          <SalesforceReportErrorMappingModal
            :import-mapping-errors="importMappingErrors"
          />
        </BContainer>
      </BModalBody>
      <BModalFooter class="modal-footer">
        <BLayout
          v-if="[state.searchForm, state.mappingField].includes(currentState)"
          class="mt-100"
          justify-center
          align-center
        >
          <BBtn
            type="primary"
            :disabled="isSfReportEmpty"
            @click.stop="handleNextStep(currentState)"
          >
            {{ $t(`import.salesforce.btnNextStep`) }}
          </BBtn>
        </BLayout>
        <BLayout
          v-if="currentState === state.confirm"
          class="mt-100"
          justify-center
          align-center
        >
          <BBtn
            type="primary"
            class="btn-import"
            :loading="$wait.is(waitName)"
            @click="handleImport"
          >
            {{ $t('import.salesforce.btnImport') }}
          </BBtn>
        </BLayout>
      </BModalFooter>
    </BHeightAdjuster>
  </BModal>
</template>

<script>
import { mapWaitingActions } from 'vue-wait'
import Api from '@/api/user'
import SalesforceSettingApi from '@/api/user/resources/salesforce/salesforce_setting';
import SalesforceImportList from '@/components/organisms/user/leadList/modal/SalesforceImportList.vue'
import SalesforceReportErrorMappingModal from '@/components/organisms/user/leadList/modal/SalesforceReportErrorMappingModal.vue';
import SalesforceReportMapping from '@/components/organisms/user/leadList/modal/SalesforceReportMapping.vue'
import SalesforceReportSearch from '@/components/organisms/user/leadList/modal/SalesforceReportSearch.vue'
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import errorHandler from '@/mixins/error_handler'

export default {
  name: 'SalesforceReportModal',
  components: {
    SalesforceReportSearch,
    SalesforceImportList,
    SalesforceReportMapping,
    SalesforceReportErrorMappingModal,
  },
  mixins: [errorHandler],
  setup() {
    const { isAvailableSalesforceLinkFeature } = useAvailableFeatureCheck()
    return {
      isAvailableSalesforceLinkFeature,
    }
  },
  data() {
    return {
      currentState: 0,
      selectedSfReportId: '',
      selectedReport: {},
      currentSfReport: {},
      loading: false,
      execTypes: ['oneTime', 'scheduleExec'],
      execSetting: 'oneTime',
      importMappingErrors: [],
    }
  },
  computed: {
    state() {
      return {
        searchForm: 0,
        mappingField: 1,
        confirm: 2,
        completed: 3,
      }
    },
    modalMessage () {
      if (this.currentState === this.state.searchForm) return this.$t('import.salesforce.modalMessage')
      else if (this.currentState === this.state.mappingField) return this.$t('import.salesforce.modalMappingFieldMessage')
      else if (this.currentState === this.state.confirm) return this.$t('import.salesforce.modalImportMessage')
      else return ''
    },
    modalName () {
      return 'SalesforceReportModal'
    },
    waitName() {
      return 'waiting'
    },
    isSfReportEmpty() {
      return this.selectedSfReportId === '' || Object.keys(this.currentSfReport).length === 0 ? true : false
    },
    importCount() {
      const total = this.currentSfReport?.reportRowCount || 0
      return total?.toLocaleString()
    },
    sfRecords() {
      return this.currentSfReport || []
    },
  },
  watch: {
    currentState(value) {
      if (value === this.state.completed) {
        setTimeout(() => this.hide(), 2000)
      }
    },
  },
  async created () {
    this.salesforceApiInfo = await SalesforceSettingApi.getSalesforceSetting()
  },
  methods: {
    ...mapWaitingActions('user', {
      getSfReportImportTasksAction: 'getSfReportImportTasksWait',
    }),
    ...mapWaitingActions('userUi', {
      addPendingSfImportTaskIdAction: 'addPendingSfImportTaskIdWait',
    }),
    async searchReportByName(name) {
      const res = await Api.searchSfReportByName(name);
      return res.data.sfReports
    },
    selectSfReport(sfReportId){
      this.$wait.start(this.waitName)
      this.currentSfReport = {}
      this.selectedSfReportId = sfReportId
      Api.getSfReport({
        id: this.selectedSfReportId,
        errorHandlers: {
          406: this.defaultHandler,
          422: this.leadMappingErrorHandler,
        },
      }).then(data => {
        this.currentSfReport = data.data.sfReport
      }).finally(() => {
        this.$wait.end(this.waitName)
      });
    },
    show() {
      this.$modal.show(this.modalName)
    },
    hide() {
      this.reset()
      this.$modal.hide(this.modalName)
    },
    handleBackStep() {
      const statesOrder = [this.state.searchForm, this.state.mappingField, this.state.confirm]
      this.currentState = statesOrder[statesOrder.indexOf(this.currentState) - 1]
    },
    handleNextStep(state) {
      if (this.isSfReportEmpty) return
      const { searchForm,　mappingField, confirm } = this.state
      this.currentState = state === searchForm ? mappingField : confirm
    },
    handleImport() {
      this.$wait.start(this.waitName)
      const args = {
        sfReportId: this.selectedSfReportId,
        execSetting: this.execSetting,
      }
      Api.importSfReports({ body: args }).then((res) => {
        this.addPendingSfImportTaskIdAction(res.data.taskId)
        return this.getSfReportImportTasksAction()
      }).then(() => {
        this.currentState = this.state.completed
      }).finally(() => {
        this.$wait.end(this.waitName)
      })
    },
    reset() {
      this.selectedSfReportId = ''
      this.currentSfReport = {}
      this.currentState = this.state.searchForm
      this.selectedReport = {}
    },
    beforeUnmount() {
      this.reset()
    },
    leadMappingErrorHandler(e) {
      this.importMappingErrors = e.response.data
      if (this.importMappingErrors.errorMessage != null) {
        // インポートマッピング以外のサーバー側raiseのとき
        this.defaultHandler(e)
      } else {
        this.$modal.show('salesforceReportErrorMappingModal')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-message {
  text-align: left;
  color: $textcolor-light;
  background-color: $bgcolor-base;
  padding: $basespace-300 $basespace-200;
  border-radius: 4px;
}
.grid {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: 959px) {
    grid-template-columns: 1fr;
  }
}
.select-lead-source p {
  margin-bottom: 5px;
}
.additional-attr {
  width: 230px !important;
}
.btn-import {
  margin-top: 22px;
}
.done {
  .title {
    text-align: center;
    margin-bottom: $basespace-400;
  }
  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }
  .loader {
    color: $basecolor-primary;
    font-size: 10px;
    margin: 80px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 3.5em;
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
}
.wrap-header {
  position: relative;
  .btn-back-step {
    position: absolute;
    top: 0;
    left: 16px;
    bottom: 0;
    margin: auto;
    z-index: 99;
  }
}
.progress-status {
  display: flex;
  justify-content: flex-start;
  justify-items: space-between;
  flex-direction: column;
  margin: 28px auto;
  width: 486px;
  .progress-point-line {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    margin: 0 23px;
    .point {
      background-color: #969696;
      color: #FFFFFF;
      border-radius: 50%;
      padding: 10px;
      text-align: center;
      vertical-align: middle;
      font-size: 18px;
      width: 40px;
      min-width: 40px;
      height: 40px;
      &.active {
        background-color: #2BC0B8;
      }
    }
    .point-line {
      width: 100%;
      background-color: #969696;
      height: 3px;
      margin: auto;
      &.active {
        background-color: #2BC0B8;
      }
    }
  }
  .progress-point-text {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    .progress-text {
      width: 84px;
      margin: 8px 0;
      text-align: center;
      color: #2B2B2B;
      font-size: 12px;
    }
  }
}
.schedule-area {
  max-width: 464px;
  .schedule-header {
    font-size: $fontsize-100;
    color: $textcolor-light;
    margin-top: $basespace-100;
    margin-bottom: $basespace-50;
  }
  .schedule-body {
    margin-top: $basespace-50;
    display: flex;
    justify-content: flex-start;
    .schedule-box {
      padding: 8px 20px;
      border: solid 1px #E3E3E3;
      width: 222px;
      height: 40px;
      border-radius: 4px;
    }
  }
}
</style>

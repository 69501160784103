import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sisense-user-form" }
const _hoisted_2 = { class: "mb-300" }

import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { SisenseApiService } from '@/api/admin/resources/sisense';
import { PostSisenseUserBodyRoleEnum } from '@/api/openapi';
import { useErrorHandler } from '@/composable/error-handler';
import { useInputValidations } from '@/composable/input-validations';
import { useAlert } from '@/composable/message-dialog';
import { useWait } from '@/composable/vue-wait';
import { TOptions } from '@/plugins/biscuet-materials/components/atoms/BSelect.vue';

type TProps = {
  clientId: number;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'SisenseUserForm',
  props: /*@__PURE__*/_mergeModels({
    clientId: {}
  }, {
    "email": {},
    "emailModifiers": {},
    "role": {},
    "roleModifiers": {},
  }),
  emits: ["update:email", "update:role"],
  setup(__props: any) {

const props = __props;

const emailValid = ref(false);
const roleValid = ref(false);
const formValid = computed(() => emailValid.value && roleValid.value);

const email = _useModel<string>(__props, 'email');
const role = _useModel<PostSisenseUserBodyRoleEnum>(__props, 'role');

const i18n = useI18n();
const { doActionWithWait } = useWait();
const { alert } = useAlert();
const { emailRule } = useInputValidations();
const { defaultHandler } = useErrorHandler();
const api = new SisenseApiService();

const roles: TOptions = Object.values(PostSisenseUserBodyRoleEnum).map((role) => ({
  text: i18n.t(`sisense.roles.${role}`),
  value: role,
}));

const handleCreateClick = async () => {
  await doActionWithWait('createSisenseUserWait', async () => {
    const { data: { groupCreated } } = await api.postSisenseUser({
      request: {
        clientId: props.clientId,
        postSisenseUserBody: {
          email: email.value,
          role: role.value,
        },
      },
      errorHandlers: {
        500: defaultHandler,
      },
    });
    await alert({
      title: i18n.t('sisense.createUser'),
      message: groupCreated ? i18n.t('sisense.createUserAndGroupSuccess') : i18n.t('sisense.createUserSuccess'),
    });
  });
};

return (_ctx: any,_cache: any) => {
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BSelect = _resolveComponent("BSelect")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('sisense.createUser')), 1),
    _createVNode(_component_BListItem, { class: "mb-300" }, {
      header: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('sisense.email')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_BInput, {
          modelValue: email.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
          valid: emailValid.value,
          "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => ((emailValid).value = $event)),
          rules: [_unref(emailRule)],
          placeholder: _ctx.$t('userManagement.placeholder.email'),
          required: "",
          validation: ""
        }, null, 8, ["modelValue", "valid", "rules", "placeholder"])
      ]),
      _: 1
    }),
    _createVNode(_component_BListItem, { class: "mb-600" }, {
      header: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('sisense.role')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_BSelect, {
          modelValue: role.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((role).value = $event)),
          valid: roleValid.value,
          "onUpdate:valid": _cache[3] || (_cache[3] = ($event: any) => ((roleValid).value = $event)),
          items: _unref(roles),
          required: ""
        }, null, 8, ["modelValue", "valid", "items"])
      ]),
      _: 1
    }),
    _createVNode(_component_BLayout, { "justify-center": "" }, {
      default: _withCtx(() => [
        _createVNode(_component_BBtn, {
          type: "primary",
          disabled: !formValid.value,
          onClick: handleCreateClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('general.create.text')), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    })
  ]))
}
}

})
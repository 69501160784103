import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "notice success" }
const _hoisted_2 = { class: "navbar-left" }
const _hoisted_3 = { class: "page-title fs-400 fw-bold mr-400" }
const _hoisted_4 = { class: "navbar-right" }
const _hoisted_5 = { class: "theader" }
const _hoisted_6 = { class: "table-row theader-row" }
const _hoisted_7 = { class: "tbody" }
const _hoisted_8 = {
  key: 0,
  class: "sf-report"
}
const _hoisted_9 = {
  key: 0,
  class: "status-area"
}
const _hoisted_10 = {
  key: 1,
  class: "status-area"
}
const _hoisted_11 = {
  key: 2,
  class: "status-area"
}
const _hoisted_12 = {
  key: 0,
  class: "error-message"
}
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 2 }
const _hoisted_15 = { key: 3 }
const _hoisted_16 = { key: 5 }

import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n'
import { PaginationMeta } from '@/api/openapi/models/pagination-meta';
import { SfReportImportTasks } from '@/api/openapi/models/sf-report-import-tasks';
import { SfReportImportSettingsService } from '@/api/user/resources/salesforce/sf_report_import_settings';
import { SfReportImportTaskService } from '@/api/user/resources/salesforce/sf_report_import_tasks';
import ErrorDetail from '@/components/organisms/user/setting/api/SalesforceScheduleImport/ErrorDetail.vue';
import { bitterAlert } from '@/plugins/BBitterAlert';
import { formatShorterDateTime } from '@/utils/date-time';


export default /*@__PURE__*/_defineComponent({
  __name: 'SalesforceScheduleImport',
  setup(__props) {

const i18n = useI18n();
const currentPage = ref(1);
const sfReportImportTasks = ref<SfReportImportTasks[]>([]);
const reportName = ref({})
const meta = ref<PaginationMeta>({
  totalPages: 1,
  totalCount: 0,
  limitValue: 0,
});
const sfApi = new SfReportImportSettingsService();

const settingPageTitle = ref(i18n.t('salesforceScheduleImport.title'));
const page = ref('settingPage');

const selectExecSettingItems = [
  { text: i18n.t('salesforceScheduleImport.selectExecSettings.stop'), value: 'disable' },
  { text: i18n.t('salesforceScheduleImport.selectExecSettings.start'), value: 'enable' },
  { text: i18n.t('salesforceScheduleImport.selectExecSettings.destory'), value: 'destory' },
]
const selectedScheduleImportMethod = ref([]);
const internalImportError = ref('');
const internalImportErrors = ref([]);
const internalMappingErrors = ref([]);
const internalSfLink = ref('');
const internalModelId = ref(0);
const success = ref('');

const allPageCount = computed(() => meta.value.totalPages);
const loadSfScheduleImport = async (currentPage: number) => {
  // リクエスト送るところ
  const api = new SfReportImportTaskService();
  const res = await api.allShowSfReportImportTasks({ request: { currentPage } });
  res.data.report.forEach((r) => {
    reportName.value[r.id] = r.name;
  });
  sfReportImportTasks.value = res.data.sfReportImportTasks;
  
  sfReportImportTasks.value.forEach((v) => {
    if (v.isEnabled) {
      selectedScheduleImportMethod.value[v.id] = 'enable';
    } else {
      selectedScheduleImportMethod.value[v.id] = 'disable';
    }
  });
  meta.value = res.data.meta;
};
watch(() => currentPage.value, (newValue) => {
  loadSfScheduleImport(newValue);
}, { immediate: true });

const open = (url: string) => {
  window.open(url, '_blank')
}

const sfReportImportSettingFunc = async (modelId: number, value: string, sfReportId: string) => {
  success.value = '';
  switch (value) {
    case 'disable': {
      const disableResult = await sfApi.sfReportImportSettingsDisable(
        { request: { sfReportImportSettingId: modelId } },
      );
      if (disableResult.status === 204) {
        success.value = '更新に成功しました';
      }
      break;
    }
    case 'enable': {
      const denableResult = await sfApi.sfReportImportSettingsEnable(
        { request: { sfReportImportSettingId: modelId } },
      );
      if (denableResult.status === 204) {
        success.value = '更新に成功しました';
      }
      loadSfScheduleImport(currentPage.value);
      break;
    }
    case 'destory': {
      const ok = await bitterAlert.show({
        closeOnClickOutside: true,
        title: i18n.t('salesforceScheduleImport.delete.title'),
        text: i18n.t('salesforceScheduleImport.delete.desc', { reportName: reportName.value[sfReportId] }),
      })
      if (ok) {
        await sfApi.sfReportImportSettingsDestory(
          { request: { sfReportImportSettingId: modelId } },
        );
        sfReportImportTasks.value = sfReportImportTasks.value.filter((v) => {
          return v.id !== modelId;
        });
      } else {
        await sfApi.sfReportImportSettingsDisable(
          { request: { sfReportImportSettingId: modelId } },
        );
        selectedScheduleImportMethod.value[modelId] = 'disable'
      }
      break;
    }
    default:
      console.error(`not found ${value}`);
  }
}

const headers = [
  { key: 'reportName', name: i18n.t('salesforceScheduleImport.list.reportName'), class: [] },
  { key: 'status', name: i18n.t('salesforceScheduleImport.list.status'), class: ['short-container'] },
  { key: 'errorMessage', name: i18n.t('salesforceScheduleImport.list.errorMessage'), class: ['long-container'] },
  { key: 'lastExecAt', name: i18n.t('salesforceScheduleImport.list.lastExecAt'), class: ['short-container'] },
  { key: 'execSetting', name: i18n.t('salesforceScheduleImport.list.execSetting'), class: ['mini-container'] },
  { key: 'owner', name: i18n.t('salesforceScheduleImport.list.owner'), class: ['mini-container'] },
];

const clickErrorDetail = (sfImportTask: SfReportImportTasks) => {
  internalImportError.value = sfImportTask.singleErrorData;
  internalImportErrors.value = sfImportTask.errorData;
  internalMappingErrors.value = sfImportTask.covertErrorData;
  internalModelId.value = sfImportTask.id;
  internalSfLink.value = sfImportTask.sfReportUrl;
  page.value = 'errorReportPage';
  settingPageTitle.value = reportName.value[sfImportTask.sfReportId];
}

const backAction = () => {
  page.value = 'settingPage';
  internalImportError.value = null;
  internalImportErrors.value = null;
  internalSfLink.value = null;
  settingPageTitle.value = i18n.t('salesforceScheduleImport.title');
}

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BPagination = _resolveComponent("BPagination")!
  const _component_BSortText = _resolveComponent("BSortText")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_BSelect = _resolveComponent("BSelect")!
  const _component_BAvatar = _resolveComponent("BAvatar")!
  const _component_BContent = _resolveComponent("BContent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", null, _toDisplayString(success.value), 1),
      _createVNode(_component_BBtn, {
        text: "",
        position: "right",
        class: "close"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BIcon, {
            class: "px-200",
            size: "small",
            type: "white",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (success.value = ''))
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" close ")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ], 512), [
      [_vShow, success.value != '']
    ]),
    _createVNode(_component_BContent, { class: "content" }, {
      navbar: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(settingPageTitle.value), 1)
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          _createVNode(_component_BPagination, {
            modelValue: currentPage.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((currentPage).value = $event)),
            length: allPageCount.value,
            "total-visible": 5
          }, null, 8, ["modelValue", "length"])
        ], 512), [
          [_vShow, page.value === 'settingPage']
        ])
      ]),
      default: _withCtx(() => [
        (page.value === 'settingPage')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(headers, (cell) => {
                    return _createVNode(_component_BSortText, {
                      key: cell.key,
                      class: _normalizeClass(["table-cell header-cell", cell.class]),
                      "item-text": cell.name,
                      "is-display-only": ""
                    }, null, 8, ["class", "item-text"])
                  }), 64))
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sfReportImportTasks.value, (sfImportTask, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "table-row tbody-row"
                  }, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(headers, (column) => {
                      return _createElementVNode("div", {
                        key: column.key,
                        class: _normalizeClass(["table-cell", column.class])
                      }, [
                        (column.key === 'reportName')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createTextVNode(_toDisplayString(reportName.value[sfImportTask.sfReportId]) + " ", 1),
                              _createVNode(_component_BTooltip, { top: "" }, {
                                content: _withCtx(() => [
                                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('salesforceScheduleImport.sfLink')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_BIcon, {
                                    class: "pointer",
                                    onClick: () => open(sfImportTask.sfReportUrl)
                                  }, {
                                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                                      _createTextVNode(" open_in_new ")
                                    ])),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]),
                                _: 2
                              }, 1024)
                            ]))
                          : _createCommentVNode("", true),
                        (column.key === 'status')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              (sfImportTask.finishedAt == null)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                    _createElementVNode("div", null, [
                                      _createVNode(_component_Icon, { class: "import-icon import-icon-progress" }),
                                      _createTextVNode(" " + _toDisplayString(_unref(i18n).t('salesforceScheduleImport.list.import.progress')), 1)
                                    ])
                                  ]))
                                : (sfImportTask.errorData && sfImportTask.errorData.length >= 1)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                      _createElementVNode("div", null, [
                                        _createVNode(_component_Icon, { class: "import-icon import-icon-error" }),
                                        _createTextVNode(" " + _toDisplayString(_unref(i18n).t('salesforceScheduleImport.list.import.error')), 1)
                                      ])
                                    ]))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                      _createElementVNode("div", null, [
                                        _createVNode(_component_Icon, { class: "import-icon import-icon-success" }),
                                        _createTextVNode(" " + _toDisplayString(_unref(i18n).t('salesforceScheduleImport.list.import.success')), 1)
                                      ])
                                    ]))
                            ], 64))
                          : _createCommentVNode("", true),
                        (column.key === 'errorMessage')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                              (Array.isArray(sfImportTask.errorData))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sfImportTask.errorData, (data) => {
                                      return (_openBlock(), _createElementBlock(_Fragment, null, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.record, (value, key) => {
                                          return (_openBlock(), _createElementBlock(_Fragment, null, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value.errors, (error) => {
                                              return (_openBlock(), _createElementBlock("span", {
                                                key: `${data.row_number}-${key}-${error}`
                                              }, [
                                                _withDirectives(_createElementVNode("span", null, _toDisplayString(data.row_number) + _toDisplayString(_unref(i18n).t('salesforceScheduleImport.list.import.errorColumn')), 513), [
                                                  [_vShow, data.row_number > 0]
                                                ]),
                                                _createTextVNode(_toDisplayString(value.header) + _toDisplayString(error) + "。 ", 1)
                                              ]))
                                            }), 128))
                                          ], 64))
                                        }), 256))
                                      ], 64))
                                    }), 256))
                                  ]))
                                : (sfImportTask.singleErrorData)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                      _createElementVNode("span", null, _toDisplayString(sfImportTask.singleErrorData), 1)
                                    ]))
                                  : (sfImportTask.covertErrorData)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sfImportTask.covertErrorData, (errorData, index) => {
                                          return (_openBlock(), _createElementBlock("div", {
                                            key: `convert-${index}`
                                          }, [
                                            _createElementVNode("span", null, _toDisplayString(errorData), 1)
                                          ]))
                                        }), 128))
                                      ]))
                                    : _createCommentVNode("", true),
                              _withDirectives(_createElementVNode("div", null, [
                                _createVNode(_component_BBtn, {
                                  size: "small",
                                  outline: "",
                                  onClick: () => clickErrorDetail(sfImportTask)
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(i18n).t('salesforceScheduleImport.list.import.errorDetail')), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ], 512), [
                                [_vShow, sfImportTask.errorData.length]
                              ])
                            ], 64))
                          : _createCommentVNode("", true),
                        (column.key === 'lastExecAt')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_unref(formatShorterDateTime)(sfImportTask.finishedAt)), 1))
                          : _createCommentVNode("", true),
                        (column.key === 'execSetting')
                          ? (_openBlock(), _createBlock(_component_BSelect, {
                              key: 4,
                              modelValue: selectedScheduleImportMethod.value[sfImportTask.id],
                              "onUpdate:modelValue": ($event: any) => ((selectedScheduleImportMethod.value[sfImportTask.id]) = $event),
                              class: "owner-select",
                              items: selectExecSettingItems,
                              onChange: () => sfReportImportSettingFunc(
                    sfImportTask.id,
                    selectedScheduleImportMethod.value[sfImportTask.id],
                    sfImportTask.sfReportId
                  )
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]))
                          : _createCommentVNode("", true),
                        (column.key === 'owner')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                              _createVNode(_component_BAvatar, {
                                class: "ml-100",
                                size: 36,
                                "image-url": sfImportTask.userAvatarUrl,
                                name: sfImportTask.userName
                              }, null, 8, ["image-url", "name"])
                            ]))
                          : _createCommentVNode("", true)
                      ], 2)
                    }), 64))
                  ]))
                }), 128))
              ])
            ], 64))
          : (page.value === 'errorReportPage')
            ? (_openBlock(), _createBlock(ErrorDetail, {
                key: 1,
                "model-id": internalModelId.value,
                "import-errors": internalImportErrors.value,
                "import-error": internalImportError.value,
                "import-mapping-errors": internalMappingErrors.value,
                "sf-link": internalSfLink.value,
                onBack: backAction
              }, null, 8, ["model-id", "import-errors", "import-error", "import-mapping-errors", "sf-link"]))
            : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})
import  { AxiosError } from 'axios';
import swal from 'sweetalert';
import ApiService from '@/api/api_service'
import { i18nGlobal as i18n } from '@/i18n';
import ERROR_CODE from './error_code'

export default class SalesForceApiService extends ApiService {
  defaultErrorHandlers = {
    [ERROR_CODE.UNAUTHORIZED]: () => {
      this.createSwal({ title: i18n.t('apiError.salesforce.unauthorized.title'), text: i18n.t('apiError.salesforce.unauthorized.message') })
    },
    [ERROR_CODE.TIMEOUT_ERROR_CODE]: () => {
      this.createSwal({ title: i18n.t('apiError.timeoutError.title'), text: i18n.t('apiError.timeoutError.message') })
    },
    [ERROR_CODE.NETWORK_ERROR_CODE]: () => {
      this.createSwal({ title: i18n.t('apiError.networkError.title'), text: i18n.t('apiError.networkError.message') })
    },
    [ERROR_CODE.BAD_REQUEST]: () => {
      this.createSwal({ title: '400 Bad Request', text: i18n.t('apiError.badRequest') })
    },
    [ERROR_CODE.FORBIDDEN]: () => {
      this.createSwal({ title: '403 Forbidden', text: i18n.t('apiError.forbidden') })
    },
    [ERROR_CODE.NOT_FOUND]: () => {
      this.createSwal({ title: '404 Not Found', text: i18n.t('apiError.notFound') })
    },
    [ERROR_CODE.UNPROCESSABLE_ENTITY]: (error: AxiosError) => {
      const message = error?.response?.data?.errorMessage
      if (message) {
        swal(message)
        return
      }
      this.createSwal({
        title: '422 Unprocessable Entity',
        text: i18n.t('apiError.unprocessableEntity'),
      });
    },
    [ERROR_CODE.INTERNAL_SERVER_ERROR]: () => {
      this.createSwal({ title: '500 Internal Server Error', text: i18n.t('apiError.internalServerError') })
    },
    [ERROR_CODE.SF_TOKEN_EXPIRED]: () => {
      this.createSwal({ title: '512 Token Expired Error', text: i18n.t('salesforceNotification.tokenExpiredMessage') })
    },
    [ERROR_CODE.SERVICE_UNAVAILABLE]: () => {
      this.createSwal({ title: '503 Service Unavailable', text: i18n.t('apiError.serviceUnavailable') })
    },
  }
}

import { computed, readonly, ref } from 'vue';
import { NextActionTypeOrDefaultType, NextActionTypesAndDefaultTypes, makeNextActionType } from '@/api/user/models/next_action_types';
import { NextActionTypeApiService } from '@/api/user/resources/next_action_type';

const useNextActionTypes = () => {
  const nextActionTypesAndDefaultTypes = ref<NextActionTypesAndDefaultTypes>(null);

  const fetchNextActionTypes = async () => {
    const api = new NextActionTypeApiService();
    nextActionTypesAndDefaultTypes.value = await api.getNextActionTypes();
  };
  fetchNextActionTypes();

  const nextActionTypes = computed<NextActionTypeOrDefaultType[]>(() =>
    nextActionTypesAndDefaultTypes.value?.getMergedNextActionTypesAndDefaultTypes() || [],
  );
  
  const findNextActionType = (nextActionTypeId: string): NextActionTypeOrDefaultType | undefined =>
    nextActionTypes.value.find(nextActionType => nextActionType.id === nextActionTypeId);

  return {
    nextActionTypes: readonly(nextActionTypes),
    findNextActionType,
  };
};

export {
  makeNextActionType,
  useNextActionTypes,
}

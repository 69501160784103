import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BSelect = _resolveComponent("BSelect")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BCard = _resolveComponent("BCard")!

  return (_openBlock(), _createBlock(_component_BCard, null, {
    default: _withCtx(() => [
      _createVNode(_component_BListItem, { class: "mb-400" }, {
        header: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('sendgridSubUserManagement.select')), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_BSelect, {
            modelValue: _ctx.inputClientId,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputClientId) = $event)),
            items: _ctx.items,
            loading: _ctx.loading,
            filterable: "",
            required: "",
            teleported: false
          }, null, 8, ["modelValue", "items", "loading"])
        ]),
        _: 1
      }),
      _createVNode(_component_BListItem, { class: "mb-400" }, {
        header: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('sendgridSubUserManagement.selectDomain')), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_BSelect, {
            modelValue: _ctx.selectedSendgridDomainId,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedSendgridDomainId) = $event)),
            items: _ctx.domainForSelectItems,
            loading: _ctx.loading,
            filterable: "",
            required: "",
            teleported: false
          }, null, 8, ["modelValue", "items", "loading"])
        ]),
        _: 1
      }),
      _createVNode(_component_BLayout, {
        "align-center": "",
        "justify-center": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BBtn, {
            class: "mr-400",
            text: "",
            onClick: _ctx.cancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(`general.close.text`)), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_BBtn, {
            class: "ml-400",
            type: "primary",
            loading: _ctx.loading,
            disabled: !_ctx.isValid,
            onClick: _ctx.save
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('sendgridSubUserManagement.exec')), 1)
            ]),
            _: 1
          }, 8, ["loading", "disabled", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
<template>
  <LeadHistoryCard v-bind="attributes">
    <template #date>
      <div>{{ historiedAt }}</div>
    </template>
  </LeadHistoryCard>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import LeadHistoryCard from '@/components/organisms/user/lead/history/LeadHistoryCard.vue';
import { SequenceInstanceHistory } from '@/store/types';
import { formatShorterDateTime } from '@/utils/date-time';

type TProps = {
  sequenceInstanceHistory: SequenceInstanceHistory;
};

const props = withDefaults(defineProps<TProps>(), {});

const attributes = computed(() => ({
  text: title(),
  // TODO: user が必要？システムユーザーとして表示？
  // user: props.sequenceInstanceHistory.user,
  isDisplaySystemUser: true,
  iconComponent: 'BCustomIcon',
  customIconClass: 'b-sequence',
  iconType: 'hot',
  toggle: false,
}));

const historiedAt = computed<string>(() => formatShorterDateTime(props.sequenceInstanceHistory.historiedAt));
const title = () => {
  return `${props.sequenceInstanceHistory.actionTypeText} - ${props.sequenceInstanceHistory?.name}`
};
</script>

<style lang="scss" scoped>
:deep(.lead-history-expand-indicator) {
  height: 0;
}
</style>

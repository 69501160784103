<template>
  <div
    v-loading="loading"
    :style="style"
    class="b-drawer-menu"
  >
    <BHeightAdjuster>
      <div class="b-drawer-menu-header">
        <BLayout
          class="menu-header"
          align-center
        >
          <div class="header-title">
            {{ title }}
          </div>
          <div class="tool-area">
            <BTooltip
              v-if="canDuplicate"
              top
              :content="$t('general.duplicate.do')"
            >
              <BCustomIcon
                size="small"
                icon-class="b-clone"
                hover
                @click.stop="$emit('duplicate')"
              />
            </BTooltip>
            <BMoreMenu v-if="canDelete">
              <BBtn
                flat
                fit
                size="small"
                @click="$emit('click-delete')"
              >
                {{ $t(`general.delete.do`) }}
              </BBtn>
            </BMoreMenu>
          </div>
          <div
            v-if="$slots.drawerHeaderAction"
            class="ml-a"
          >
            <slot name="drawerHeaderAction" />
          </div>
        </BLayout>
      </div>
      <div
        v-if="$slots.drawerBody"
        class="b-drawer-menu-body"
      >
        <slot name="drawerBody" />
      </div>
      <div
        v-if="$slots.drawerFooter"
        class="b-drawer-menu-footer"
      >
        <slot name="drawerFooter" />
      </div>
    </BHeightAdjuster>
  </div>
</template>

<script>
export default {
  name: 'MailDrawerTemplate',
  props: {
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    canDuplicate: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 900,
    },
  },
  emits: [
    'duplicate',
    'click-delete',
  ],
  computed: {
    style() {
      return {
        width: `${this.width}px`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .b-drawer-menu {
    height: 100%;
    background-color: $paper;
    box-shadow: rgba(0, 0, 0, 0.25) -1px 0px 10px;
  }

  .b-drawer-menu-header {
    @include m-fixed-height(50px);
    display: flex;
    align-items: center;
    border-bottom: 1px solid $bdcolor-light;
    padding: 0 $basespace-400;

    .header-title {
      font-weight: 600;
    }
    .tool-area {
      margin-left: 10px;
      display: flex;
    }
  }

  .b-drawer-menu-body {
    height: 100%;
    overflow-y: auto;
    padding: $basespace-400;
  }

  .b-drawer-menu-footer {
    display: flex;
    align-items: center;
    background-color: $concrete-light;
    height: 75px;
  }
</style>

import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "lead-stage-item-container" }
const _hoisted_2 = { class: "list-container" }
const _hoisted_3 = { class: "handle-wrap" }
const _hoisted_4 = { class: "more-menu-wrap" }
const _hoisted_5 = { class: "creating-form-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_EditableLeadStageColorPreview = _resolveComponent("EditableLeadStageColorPreview")!
  const _component_BEditableContent = _resolveComponent("BEditableContent")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BMoreMenu = _resolveComponent("BMoreMenu")!
  const _component_Draggable = _resolveComponent("Draggable")!
  const _component_LeadStageCreatingForm = _resolveComponent("LeadStageCreatingForm")!
  const _component_BHeightAdjuster = _resolveComponent("BHeightAdjuster")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_BHeightAdjuster, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Draggable, {
            modelValue: _ctx.internalLeadStages,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalLeadStages) = $event)),
            class: "item-row",
            "item-key": "id",
            handle: ".handle",
            animation: 300,
            move: _ctx.handleMove,
            onChange: _ctx.handleSortOrderChange
          }, {
            item: _withCtx(({ element: leadStage }) => [
              _createVNode(_component_BLayout, {
                class: "py-100",
                "align-center": "",
                "justify-space-between": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BLayout, {
                    class: "ml-100 mr-100",
                    "align-center": ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_3, [
                        (leadStage.isPreset === false)
                          ? (_openBlock(), _createBlock(_component_BIcon, {
                              key: 0,
                              class: "handle",
                              size: "small",
                              type: "gray"
                            }, {
                              default: _withCtx(() => _cache[1] || (_cache[1] = [
                                _createTextVNode(" drag_indicator ")
                              ])),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _createVNode(_component_EditableLeadStageColorPreview, {
                        uneditable: leadStage.isPreset,
                        "model-value": leadStage.colorName,
                        "onUpdate:modelValue": ($event: any) => (_ctx.handleColorChange($event, leadStage.id))
                      }, null, 8, ["uneditable", "model-value", "onUpdate:modelValue"]),
                      _createVNode(_component_BEditableContent, {
                        uneditable: leadStage.isPreset,
                        value: leadStage.name,
                        placeholder: _ctx.$t('leadStage.name'),
                        rules: [_ctx.requiredRule],
                        "max-length": _ctx.maxNameLength,
                        loading: _ctx.$wait.is(['updateLeadStageWait']),
                        onCheck: ($event: any) => (_ctx.handleNameChange($event, leadStage.id))
                      }, null, 8, ["uneditable", "value", "placeholder", "rules", "max-length", "loading", "onCheck"])
                    ]),
                    _: 2
                  }, 1024),
                  _createElementVNode("span", _hoisted_4, [
                    (leadStage.isPreset === false)
                      ? (_openBlock(), _createBlock(_component_BMoreMenu, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BBtn, {
                              flat: "",
                              fit: "",
                              size: "small",
                              onClick: () => _ctx.handleDeleteClick(leadStage)
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('general.delete.do')), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }, 8, ["modelValue", "move", "onChange"])
        ])), [
          [_directive_loading, _ctx.loading]
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_LeadStageCreatingForm, { onAfterCreate: _ctx.handleLeadStageAfterCreate }, null, 8, ["onAfterCreate"])
        ])
      ])
    ]),
    _: 1
  }))
}
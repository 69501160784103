<template>
  <BContentBox>
    <template #content>
      <div v-loading="settingLoading">
        <BLayout
          justify-center
          column
        >
          <BListItem
            class="connect-explain"
            v-html="$t('corpSettingApi.zoom.explain')"
          />
          <div class="mt-100">
            <span>{{ $t('corpSettingApi.zoom.connectStatus') }}：</span>
            <span v-if="!isConnect">{{ $t('corpSettingApi.zoom.status.unconnect') }}</span>
            <span v-if="isConnect">{{ $t('corpSettingApi.zoom.status.connect') }}</span>
            <span
              v-if="isExpired"
              class="token-expire"
            >
              {{ $t('corpSettingApi.zoom.status.expired') }}
            </span>
          </div>
          <BListItem
            v-if="isConnect"
            class="mt-50"
          >
            <span>{{ $t('corpSettingApi.zoom.connectUserName') }}：</span>
            <span>{{ zoomSetting.connectUserName }}</span>
          </BListItem>
          <BListItem class="mt-300">
            <BBtn
              v-if="isConnect"
              type="primary"
              :disabled="settingLoading || deleting || !$can('owner')"
              @click="unconnect"
            >
              <span>{{ $t('corpSettingApi.zoom.btnDisconnect') }}</span>
            </BBtn>
            <BBtn
              v-else
              type="primary"
              :disabled="settingLoading || !$can('owner')"
              @click="connect"
            >
              <span>{{ $t('corpSettingApi.zoom.btnText') }}</span>
            </BBtn>
          </BListItem>
        </BLayout>
      </div>
    </template>
  </BContentBox>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import ZoomSettingStatuses from '@/const/zoom_setting_statuses'
import errorHandler from '@/mixins/error_handler'
import inputValidation from '@/mixins/input_validation'

export default defineComponent({
  mixins: [inputValidation, errorHandler],
  computed: {
    ...mapGetters('user', [
      'currentUser',
    ]),
    ...mapGetters('zoomPhone', [
      'zoomSetting',
    ]),
    settingLoading(): boolean {
      return this.$wait.is(['getZoomSettingWait'])
    },
    deleting(): boolean {
      return this.$wait.is(['deleteZoomSettingWait'])
    },
    isConnect(): boolean {
      return this.zoomSetting?.connectStatus ==  ZoomSettingStatuses.CONNECTED
    },
    isExpired(): boolean {
      return this.zoomSetting?.connectStatus == ZoomSettingStatuses.TOKEN_EXPIRED
    },
    authLink(): string {
      const url = 'https://zoom.us/oauth/authorize'
      const clientId = process.env.VUE_APP_ZOOM_CLIENT_ID
      const splitHostname = location.hostname.split('.')
      splitHostname.shift()
      const appDomain = splitHostname.join('.')
      const redirectUrl = `https://${this.currentUser.slug}.${appDomain}/app/zoom/oauth/callback`;
      return `${url}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}`;
    },
  },
  created() {
    this.getZoomSettingAction();
  },
  methods: {
    ...mapWaitingActions('zoomPhone', {
      deleteZoomSettingAction: 'deleteZoomSettingWait',
      getZoomSettingAction: 'getZoomSettingWait',
    }),
    connect(): void {
      location.href = this.authLink;
    },
    async unconnect(): Promise<void> {
      const check = await this.$bitterAlert.show({
        title: this.$t('corpSettingApi.zoom.cancelLinkConfirmText'),
        text: this.$t('general.confirmText'),
      })
      if (!check) { return }

      this.deleteZoomSettingAction();
    },
  },
})
</script>

<style lang="scss" scoped>
.connect-explain {
  color: $concrete-dark;
  margin-bottom: 20px;
}

.token-expire {
  color: $basecolor-error;
}

.general-footer {
  padding-top: $basespace-600;
}
</style>

import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "quick-text-form-drawer" }

import { ref, computed, watch, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { QuickTextBody } from '@/api/openapi';
import { QuickTextApiService } from '@/api/user/resources/quick_text';
import { useUnsavedConfirm } from '@/composable/message-dialog';
import { useWait } from '@/composable/vue-wait';
import { KEY_OF_TOAST } from '@/injection-keys';
import ConfirmDrawer from './ConfirmDrawer.vue';
import FormDrawer from './FormDrawer.vue';

type TProps = {
  quickTextId?: number;
};
type TEmit = {
  'afterSave': [];
  'update:isChanged': [isChanged: boolean];
};

const KEY_OF_SAVE_WAIT = 'saveQuickTextWait';

export default /*@__PURE__*/_defineComponent({
  __name: 'QuickTextFormDrawer',
  props: /*@__PURE__*/_mergeModels({
    quickTextId: {}
  }, {
    "isShow": { type: Boolean, ...{ default: false } },
    "isShowModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["afterSave", "update:isChanged"], ["update:isShow"]),
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const isShow = _useModel<boolean>(__props, 'isShow');
const isConfirm = ref<boolean>(false);

const innerQuickTextId = ref<number>(undefined);
const orgQuickText = ref<QuickTextBody>(undefined);
const name = ref<string>('');
const content = ref<string>('');
const isValidName = ref<boolean>(false);
const isValidContent = ref<boolean>(false);

const toast = inject(KEY_OF_TOAST);
const { doActionWithWait, wait } = useWait();
const { unsavedConfirm } = useUnsavedConfirm();
const i18n = useI18n();
const api = new QuickTextApiService();

const resetFormValues = (newId: number | undefined) => {
  innerQuickTextId.value = newId;
  name.value = '';
  content.value = '';
  isConfirm.value = false;
  orgQuickText.value = undefined;
  isValidName.value = false;
  isValidContent.value = false;
};
watch(() => ({ isShow: isShow.value, quickTextId: props.quickTextId }), async (newValue) => {
  const { isShow: newIsShow, quickTextId: newId } = newValue;
  if (!newIsShow) return;
  resetFormValues(newId);

  if (!newId) return;
  await doActionWithWait('fetchQuickTextWait', async () => {
    const { data: { quickText } } = await api.getQuickText({
      request: {
        quickTextId: newId,
      },
    });

    orgQuickText.value = quickText;
    name.value = quickText.name;
    content.value = quickText.content;
  });
}, { immediate: true });

const title = computed(() => {
  return innerQuickTextId.value == null
    ? i18n.t('general.create.to', { item: i18n.t('quickText.title') })
    : i18n.t('general.edit.to', { item: i18n.t('quickText.title') })
});
const loading = computed(() => {
  return wait.is([KEY_OF_SAVE_WAIT, 'fetchQuickTextWait']);
});
const isChanged = computed(() => {
  const orgName = orgQuickText.value?.name ?? '';
  const orgContent = orgQuickText.value?.content ?? '';
  return orgName !== name.value || orgContent !== content.value;
});
watch(() => isChanged.value, (newValue) => {
  emit('update:isChanged', newValue);
}, { immediate: true });

// methods for FormDrawer
const handleCloseClick = async () => {
  if (isChanged.value) {
    const ok = await unsavedConfirm();
    if (!ok) return;
  }

  closeDrawer();
};
const handleDraftSaveClick = () => {
  save('draft');
};
const handleConfirmClick = () => {
  save('draft', () => {
    isConfirm.value = true;
  });
};

// methods for ConfirmDrawer
const handleSaveClick = () => {
  save('published', () => {
    closeDrawer();
  });
};
const handleEditClick = () => {
  isConfirm.value = false;
};

// ここから共通処理
const closeDrawer = () => {
  isShow.value = false;
};

const save = (status: QuickTextBody['status'], afterSave?: () => void) => {
  if (innerQuickTextId.value != null) {
    update(status, afterSave);
  } else {
    create(status, afterSave);
  }
};
const showSavedToast = () => {
  toast.show(
    i18n.t('general.done.of', { 
      action: i18n.t('general.save.text'),
    }),
  );
};
const makeQuickTextBody = (status: QuickTextBody['status']): QuickTextBody => ({
  name: name.value,
  content: content.value,
  status,
});
const create = async (status: QuickTextBody['status'], afterSave?: () => void) => {
  await doActionWithWait(KEY_OF_SAVE_WAIT, async () => {
    const { data } = await api.postQuickText({
      request: {
        quickTextBody: makeQuickTextBody(status),
      },
    });
    innerQuickTextId.value = data.id;
    if (afterSave) afterSave();
    showSavedToast();
    emit('afterSave');
  });
};
const update = async (status: QuickTextBody['status'], afterSave?: () => void) => {
  await doActionWithWait(KEY_OF_SAVE_WAIT, async () => {
    await api.putQuickText({
      request: {
        quickTextId: innerQuickTextId.value,
        quickTextBody: makeQuickTextBody(status),
      },
    });
    if (afterSave) afterSave();
    showSavedToast();
    emit('afterSave');
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isConfirm.value)
      ? (_openBlock(), _createBlock(ConfirmDrawer, {
          key: 0,
          "is-show": isShow.value,
          "onUpdate:isShow": _cache[0] || (_cache[0] = ($event: any) => ((isShow).value = $event)),
          name: name.value,
          content: content.value,
          title: title.value,
          loading: loading.value,
          "onClick:publish": handleSaveClick,
          "onClick:edit": handleEditClick
        }, null, 8, ["is-show", "name", "content", "title", "loading"]))
      : (_openBlock(), _createBlock(FormDrawer, {
          key: 1,
          "is-show": isShow.value,
          "onUpdate:isShow": _cache[1] || (_cache[1] = ($event: any) => ((isShow).value = $event)),
          name: name.value,
          "onUpdate:name": _cache[2] || (_cache[2] = ($event: any) => ((name).value = $event)),
          content: content.value,
          "onUpdate:content": _cache[3] || (_cache[3] = ($event: any) => ((content).value = $event)),
          "is-valid-name": isValidName.value,
          "onUpdate:isValidName": _cache[4] || (_cache[4] = ($event: any) => ((isValidName).value = $event)),
          "is-valid-content": isValidContent.value,
          "onUpdate:isValidContent": _cache[5] || (_cache[5] = ($event: any) => ((isValidContent).value = $event)),
          title: title.value,
          loading: loading.value,
          "onClick:draftSave": handleDraftSaveClick,
          "onClick:confirm": handleConfirmClick,
          "onClick:close": handleCloseClick
        }, null, 8, ["is-show", "name", "content", "is-valid-name", "is-valid-content", "title", "loading"]))
  ]))
}
}

})
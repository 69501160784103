<template>
  <div class="b-user-tooltip">
    <BPopover
      placement="top"
      width="150"
      trigger="hover"
      full
      :arrow="false"
    >
      <div class="b-user-tooltip-card">
        <div
          class="user-avater"
          :style="style"
        />
        <div class="user-text px-300 pb-300">
          {{ name }}
        </div>
      </div>
      <template #reference>
        <BAvatar
          :size="size"
          :image-url="imageUrl"
          :name="name"
          :user-id="userId"
        />
      </template>
    </BPopover>
  </div>
</template>

<script>
import defaultAvatarImage from '@/assets/default_avatar.png'

export default {
  props: {
    imageUrl: String,
    name: String,
    size: [Number, String],
    userId: Number,
  },
  data () {
    return {
      active: false,
      timerObj: null,
      defaultAvatar: defaultAvatarImage,
    }
  },
  computed: {
    style () {
      return {
        backgroundImage: `url('${this.avatarImage}')`,
      }
    },
    avatarImage () {
      if (this.imageUrl) {
        return this.imageUrl
      } else {
        return this.defaultAvatar
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .user-avater {
    width: 100%;
    height: 0;
    padding-top: 75%;
    margin-bottom: $basespace-300;
    background-size: cover;
    background-position: center;
  }
</style>

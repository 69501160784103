import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';
import { TSize } from '../../types';

type TProps = {
  size?: TSize;
  colorCode: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BColorPreview',
  props: {
    size: { default: 'medium' },
    colorCode: {}
  },
  emits: ['click'],
  setup(__props: any) {

const props = __props;


const style = computed(() => ({
  'background-color': props.colorCode,
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["color-preview", _ctx.size]),
    style: _normalizeStyle(style.value),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, null, 6))
}
}

})
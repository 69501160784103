import ApiService, { ApiArguments } from '@/api/api_service';
import {
  GetTriggerEventTypes200Response,
  TriggerEventTypeApi,
} from '@/api/openapi';

export class TriggerEventTypeApiService extends ApiService {
  api = new TriggerEventTypeApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true)
  }

  async getTriggerEventTypes(
    apiArguments?: ApiArguments<undefined>,
  ) {
    return this.checkResponse<GetTriggerEventTypes200Response>(
      this.api.getTriggerEventTypes(apiArguments?.options),
      apiArguments?.errorHandlers,
    );
  }
}

<template>
  <div class="filter-select">
    <BMenu>
      <template #activator>
        <BBtn
          flat
          size="small"
          @click="visibleFilterControlPanel"
        >
          <BCustomIcon
            class="mr-50"
            icon-class="b-filter"
            size="small"
          /><span>{{ $t('filterSelect.title') }}</span>
        </BBtn>
      </template>
      <FilterSelectCard />
    </BMenu>
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait'
import FilterSelectCard from './FilterSelectCard.vue'

export default {
  name: 'FilterSelect',
  components: {
    FilterSelectCard,
  },
  methods: {
    ...mapWaitingActions('userUi', {
      setFilterControlPanelVisibleAction: 'setFilterControlPanelVisibleWait',
    }),
    visibleFilterControlPanel () {
      this.setFilterControlPanelVisibleAction(true)
    },
  },
}
</script>

<style lang="scss" scoped>
  :deep(.filter-select-card) {
    @include m-fixed-width(320px);
    max-height: 550px;
    overflow: auto;
  }
</style>

import Waiting from '@/store/waiting'

export default class LeadViewActions {
  constructor (Api, types) {
    const actions = {
      async getLeadViewsAction ({ commit }, args) {
        await waiting.check('getLeadViewsAction', async () => {
          try {
            const res = await Api.getLeadViews(args)
            commit(types.SET_LEAD_VIEWS, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
      async getLeadViewAction ({ commit }, args) {
        await waiting.check('getLeadViewAction', async () => {
          try {
            const res = await Api.getLeadView(args)
            commit(types.SET_LEAD_VIEW, Object.values(res.data)[0])
          } catch (err) {
            commit(types.CLEAR_LEAD_VIEW)
            throw err
          }
        })
      },
      setLeadViewAction ({ commit }, args) {
        commit(types.SET_LEAD_VIEW, args)
      },
      clearLeadViewAction ({ commit }) {
        commit(types.CLEAR_LEAD_VIEW)
      },
      createLeadViewAction ({ dispatch }, args) {
        return waiting.check('createLeadViewAction', async () => {
          try {
            const createLeadViewResponse = await Api.createLeadView(args)
            await dispatch('getLeadViewsAction')
            return createLeadViewResponse.data
          } catch (err) {
            throw err
          }
        })
      },
      async updateLeadViewAction ({ dispatch }, args) {
        await waiting.check('updateLeadViewAction', async () => {
          try {
            await Api.updateLeadView(args)
            await dispatch('getLeadViewsAction')
          } catch (err) {
            throw err
          }
        })
      },
      async deleteLeadViewAction ({ dispatch }, args) {
        await waiting.check('deleteLeadViewAction', async () => {
          try {
            await Api.deleteLeadView(args)
            await dispatch('getLeadViewsAction')
          } catch (err) {
            throw err
          }
        })
      },
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

<template>
  <BContent unvisible-navbar>
    <Notification display="home" />
    <div
      class="home-hero"
      :style="styles"
    >
      <div class="home-block">
        <img
          :src="require('@/assets/bales_cloud_fire.svg')"
          alt=""
        >
        <div class="home-message-block">
          <span class="message fs-500 fw-bold">{{ $t('home.message') }}</span>
          <span class="sub-message fw-bold">{{ $t('home.subMessage') }}</span>
        </div>
      </div>
    </div>
    <BContainer class="pt-600">
      <BLayout>
        <BFlex
          class="pr-400"
          :span="16"
        >
          <div class="section mb-300">
            <div class="section-header">
              <div class="section-title mr-200">
                {{ $t('leadList.title') }}
              </div>
            </div>
            <div class="section-body">
              <RouterLink
                class="link-card"
                :to="{ name: 'LeadList' }"
              >
                <BCard
                  border
                  transition
                  data-test="leadList"
                  @click="clickHome"
                >
                  <BLayout>
                    <div class="lead-view-icon">
                      <img :src="require('@/assets/icon_lead_list.svg')">
                    </div>
                    <div class="lead-view-name">
                      <div class="fw-bold mb-50">
                        {{ $t('leadList.allTitle') }}
                      </div>
                      <div class="text-annotation">
                        {{ $t('leadList.allDescription') }}
                      </div>
                    </div>
                  </BLayout>
                </BCard>
              </RouterLink>
            </div>
          </div>
          <div class="section">
            <div class="section-body">
              <div class="column-headers">
                <BLayout>
                  <div class="lead-view-icon" />
                  <div class="lead-view-name">
                    <BBtn
                      text
                      size="small"
                      @click="changeSortOrder"
                    >
                      <span>リスト名</span>
                      <BIcon
                        v-if="leadViewSortKey.key === 'name'"
                        class="b-sort-icon"
                        size="small"
                        :class="{ 'b-sort-desc': leadViewSortKey.order === 'desc' }"
                      >
                        arrow_downward
                      </BIcon>
                    </BBtn>
                  </div>
                  <BLayout
                    class="ml-a"
                    justify-end
                    align-center
                  >
                    <div class="lead-view-date">
                      {{ $t('leadView.updatedAt') }}
                    </div>
                    <div class="lead-view-user">
                      {{ $t('leadView.updatedUser') }}
                    </div>
                    <div class="lead-view-action" />
                  </BLayout>
                </BLayout>
              </div>
              <VWait for="getLeadViewsWait">
                <template #waiting>
                  <BPlaceholder
                    v-loading="true"
                    height="20vh"
                    element-loading-background="transparent"
                  />
                </template>
                <TransitionGroup
                  v-if="sortedLeadViews.length > 0"
                  class="column layout"
                  name="list"
                  tag="div"
                >
                  <RouterLink
                    v-for="leadView in sortedLeadViews"
                    :key="'leadView' + leadView.id"
                    class="link-card"
                    :to="{ name: 'LeadList', query: { leadViewId: leadView.id} }"
                  >
                    <BCard
                      class="mb-200"
                      border
                      transition
                    >
                      <BLayout align-center>
                        <div class="lead-view-icon">
                          <img :src="require('@/assets/icon_lead_view.svg')">
                        </div>
                        <div class="lead-view-name">
                          <div class="fw-bold mb-50">
                            {{ leadView.name }}
                          </div>
                          <div class="text-annotation break-word-pre-wrap">
                            {{ leadView.description }}
                          </div>
                        </div>
                        <BLayout
                          class="ml-a"
                          justify-end
                          align-center
                        >
                          <div class="lead-view-date">
                            {{ getDateAndTimeWithFallBack(leadView.updatedAt) }}
                          </div>
                          <div class="lead-view-user">
                            <BUserTooltip
                              :image-url="leadView.updatedUserAvatarUrl"
                              :name="leadView.updatedUserName"
                              :user-id="leadView.updatedUserId"
                              :size="36"
                            />
                          </div>
                          <div
                            class="lead-view-action"
                            @click.stop=""
                          >
                            <BMoreMenu
                              trigger="hover"
                              :z-index100="true"
                            >
                              <BBtn
                                flat
                                fit
                                size="small"
                                @click="() => handleOpenUpdateModal(leadView)"
                              >
                                {{
                                  $t('general.edit.do')
                                }}
                              </BBtn>
                              <BPopover
                                v-if="leadView.isUsedByReservedBulkMail"
                                placement="top"
                                width="240"
                                trigger="hover"
                              >
                                <template #reference>
                                  <div>
                                    <BBtn
                                      flat
                                      fit
                                      size="small"
                                      disabled
                                    >
                                      {{ $t('general.delete.do') }}
                                    </BBtn>
                                  </div>
                                </template>
                                <div>{{ $t('mail.alreadyUsedReserve') }}</div>
                              </BPopover>
                              <BBtn
                                v-else
                                flat
                                fit
                                size="small"
                                @click="() => removeLeadView(leadView.id)"
                              >
                                {{ $t('general.delete.do') }}
                              </BBtn>
                            </BMoreMenu>
                          </div>
                        </BLayout>
                      </BLayout>
                    </BCard>
                  </RouterLink>
                </TransitionGroup>
                <BEmptyBox
                  v-else
                  class="px-600 py-600"
                  display-only
                >
                  <div class="break-word-pre-wrap">
                    {{ $t('leadView.empty') }}
                  </div>
                </BEmptyBox>
              </VWait>
              <LeadViewUpdateModal
                ref="leadViewUpdateModal"
                :lead-view="selectedLeadView"
                :delegate="leadViewUpdateModalDelegate"
              />
            </div>
          </div>
        </BFlex>
        <BFlex :span="8">
          <div class="section-header">
            <div class="section-title mr-200">
              {{ $t('home.member') }}
            </div>
            <RouterLink
              class="link-card"
              :to="{ name: 'Setting', state: { selectTab: 'member', selectTabTitle: $t('corpSettingApi.member') } }"
            >
              <BBtn
                size="small"
                fab
                flat
              >
                <BIcon size="small">
                  settings
                </BIcon>
              </BBtn>
            </RouterLink>
          </div>
          <div class="section-body">
            <BCard
              small
              border
            >
              <VWait for="getUsersWait">
                <div class="card-wrapper">
                  <div
                    v-for="user in activeUsers"
                    :key="`user-${user.id}`"
                    class="user-list-item"
                  >
                    <BAvatarText
                      :text="user.name"
                      :sub-text="user.email"
                      :image-url="user.avatarUrl"
                      :user-id="user.id"
                      truncate
                    />
                  </div>
                </div>
                <template #waiting>
                  <BPlaceholder
                    v-loading="true"
                    height="20vh"
                    element-loading-background="transparent"
                  />
                </template>
              </VWait>
            </BCard>
          </div>
        </BFlex>
      </BLayout>
    </BContainer>
  </BContent>
</template>

<script lang="ts">
import lodash from 'lodash'
import { defineComponent } from 'vue'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import LeadViewUpdateModal from '@/components/organisms/user/general/modal/leadView/LeadViewUpdateModal.vue'
import Notification from '@/components/organisms/user/Notification.vue'
import { useUsers } from '@/composable/user/user/users';
import errorHandler from '@/mixins/error_handler'
import intercom from '@/mixins/intercom'
import { altText } from '@/utils/alt'
import { formatShorterDateTime } from '@/utils/date-time'

export default defineComponent({
  name: 'Dashboard',
  components: {
    LeadViewUpdateModal,
    Notification,
  },
  mixins: [intercom, errorHandler],
  setup(props) {
    const { activeUsers } = useUsers()
    return {
      ...intercom.setup(props),
      activeUsers,
    }
  },
  data() {
    return {
      selectedLeadView: null, // MEMO: Modalオープン時に値付与、クローズ時にnull
      leadViewSortKey: {
        key: 'name', // leadView.name
        order: 'asc',
      },
      leadViewUpdateModalDelegate: {
        beforeClose: this.beforeLeadViewUpdateModalClose,
      },
    }
  },
  computed: {
    styles() {
      return {
        backgroundImage: `url("${require('@/assets/home_background_2.svg')}")`,
      }
    },
    ...mapGetters('user', ['currentUser', 'leadViews']),
    sortedLeadViews() {
      return lodash.orderBy(
        this.leadViews,
        (leadView) => {
          return leadView[this.leadViewSortKey.key]
        },
        this.leadViewSortKey.order,
      )
    },
  },
  created() {
    this.getUsersAction()
    if (this.leadViews.length > 0) {
      this.getLeadViewsBackgroundAction()
    } else {
      this.getLeadViewsAction()
    }
  },
  methods: {
    ...mapWaitingActions('user', {
      getUsersAction: 'getUsersWait',
      getLeadViewsAction: {
        action: 'getLeadViewsAction',
        loader: 'getLeadViewsWait',
      },
      getLeadViewsBackgroundAction: {
        action: 'getLeadViewsAction',
        loader: 'getLeadViewsBackgroundWait',
      },
      deleteLeadViewAction: 'deleteLeadViewWait',
    }),
    ...mapWaitingActions('userUi', {
      setLeadListSelectedColumnsAction: 'setLeadListSelectedColumnsWait',
      resetLeadListSelectedColumnsAction: 'resetLeadListSelectedColumnsWait',
      setLeadListSortKeyAction: 'setLeadListSortKeyWait',
      setLeadListSortOrderAction: 'setLeadListSortOrderWait',
    }),
    async removeLeadView(leadViewId) {
      const check = await this.$bitterAlert.show({
        title: this.$t('general.confirm.text'),
        text: this.$t('leadView.deleteCaution'),
      })
      if (!check) {
        return
      }
      await this.deleteLeadViewAction({
        leadViewId,
        errorHandlers: {
          422: this.defaultHandler,
        },
      })
    },
    handleOpenUpdateModal(leadView) {
      this.selectedLeadView = leadView
      this.$modal.show(this.$refs.leadViewUpdateModal.modalName)
    },
    changeSortOrder() {
      this.leadViewSortKey.key = 'name'
      this.leadViewSortKey.order = this.leadViewSortKey.order === 'asc' ? 'desc' : 'asc'
    },
    clickHome() {
      this.resetLeadListSelectedColumnsAction()
    },
    getDateAndTimeWithFallBack(date) {
      return altText(formatShorterDateTime(date))
    },
    beforeLeadViewUpdateModalClose() {
      this.selectedLeadView = null
    },
  },
})
</script>

<style lang="scss" scoped>
.home-hero {
  display: flex;
  align-items: center;
  height: 200px;
  background-color: #fafffd;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
  .home-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    padding-left: 64px;
    width: 992px;

    .home-message-block {
      color: #016667;
      display: flex;
      flex-direction: column;
      margin-left: 35px;

      .sub-message {
        font-size: 32px;
      }
    }
  }

}

.section-header {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: $basespace-200;

  .section-title {
    font-weight: 600;
    font-size: $fontsize-400;
  }
}

.card-wrapper {
  display: block;
  height: 100%;
  padding: $basespace-100;
  text-decoration: none;
  color: $textcolor-base;
}
.link-card {
  text-decoration: none;
  color: $textcolor-base;
}

.user-list-item {
  margin-bottom: $basespace-400;
  &:last-child {
    margin-bottom: 0;
  }
}

.b-sort-icon {
  transform: rotate(-180deg);
}

.b-sort-desc {
  transform: rotate(0);
}

.column-headers {
  font-size: $fontsize-100;
  padding: $basespace-100 $basespace-600;
  color: $textcolor-light;
}

.lead-view {
  &-icon {
    @include m-fixed-width(70px);
  }

  &-name {
    @include m-fixed-width(260px);
  }

  &-date {
    @include m-fixed-width(140px);
  }

  &-user {
    @include m-fixed-width(40px);
  }

  &-action {
    @include m-fixed-width(20px);
  }
}
</style>

<template>
  <FilterDetailPopover
    ref="popover"
    v-model:is-include="isInclude"
    :title="title"
    :width="260"
    is-switch
    :is-display-only="isDisplayOnly"
    :key-name="fieldKey"
    is-custom
    @hide="hide"
    @show="popoverShow"
  >
    <template #reference>
      <BLayout align-center>
        <BListItem>
          <template #header>
            <BLayout justify-space-between>
              <div class="item-title">
                {{ title }}
              </div>
              <div
                class="grouping-text"
                :class="{ 'is-include': incExc === 'inc', 'inc-text': incExc === 'inc', 'exc-text': incExc === 'exc' }"
              >
                {{ groupingText }}
              </div>
            </BLayout>
          </template>
          <div>{{ displayValue }}</div>
        </BListItem>
      </BLayout>
    </template>
    <BCheckbox
      class="my-100"
      :model-value="checkNoInput"
      :label="blankItem.label"
      :value="blankItem.value"
      @change="changeCheckNoInput"
    />
    <div class="number-input-container">
      <BNumberInput
        v-model="inputMinimum"
        :placeholder="$t('customField.number.minimum')"
        :disabled="checkNoInput"
      />
      <span class="number-input-assist-text">から</span>
    </div>
    <div class="number-input-container mt-300">
      <BNumberInput
        v-model="inputMaximum"
        :placeholder="$t('customField.number.maximum')"
        :disabled="checkNoInput"
      />
      <span class="number-input-assist-text">まで</span>
    </div>
  </FilterDetailPopover>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterDetailPopover from '@/components/organisms/user/leadList/filter/FilterDetailPopover.vue'
import { getInitialValue } from '@/mixins/filter_service/constants'
import queryParameter from '@/mixins/query_parameter'
import { getIncExc } from '@/utils/object'

export default {
  components: {
    FilterDetailPopover,
  },
  mixins: [
    queryParameter,
  ],
  props: {
    title: {
      type: String,
      default: '',
    },
    // FIXME: このComponentではvalueは使っていないが、他Componentとの整合性を取るためと前方互換のため保持している
    value: {
      type: Array,
      default () {
        return getInitialValue('typeNumber')
      },
    },
    customFieldItem: {
      type: Object,
      default () {
        return { inc: { or: getInitialValue('typeNumber') } }
      },
    },
    fieldKey: {
      type: String,
      require: true,
      default: '',
    },
    isDisplayOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'hide',
  ],
  data () {
    return {
      inputMinimum: null,
      inputMaximum: null,
      checkNoInput: false,
      isInclude: false,
    }
  },
  computed: {
    ...mapGetters('user', [
      'customFields',
    ]),
    ...mapGetters('userUi', [
      'leadListFilterParams',
    ]),
    blankItem () {
      return { label: this.$t('general.blankInput'), value: true }
    },
    groupingText () {
      if (this.isEmpty) return ''
      return this.$t(`general.filter.${this.incExc}`)
    },
    target () {
      return this.customFieldItem
    },
    targetDetail () {
      return this.target?.[this.incExc]?.or || getInitialValue('typeNumber')
    },
    incExc () {
      return getIncExc(this.target)
    },
    grouping () {
      return this.isInclude ? 'inc' : 'exc'
    },
    isEmpty() {
      return !Array.isArray(this.targetDetail)
        || this.targetDetail.length !== 3
        || this.isEmptyForNumberValue(this.targetDetail[0]) && this.isEmptyForNumberValue(this.targetDetail[1]) && !this.getBooleanEvenString(this.targetDetail[2])
    },
    displayValue () {
      if (this.isEmpty) return '-'
      if (this.getBooleanEvenString(this.targetDetail[2])) return '入力なし'
      const min = this.isEmptyForNumberValue(this.targetDetail[0]) ? this.$t('general.min') : this.targetDetail[0]
      const max = this.isEmptyForNumberValue(this.targetDetail[1]) ? this.$t('general.max') : this.targetDetail[1]
      return this.$t('general.range', { min, max })
    },
  },
  created() {
    this.isInclude = getIncExc(this.target) !== 'exc'
  },
  methods: {
    popoverShow () {
      if (Array.isArray(this.targetDetail) && this.targetDetail.length === 3) {
        this.inputMinimum = this.isEmptyForNumberValue(this.targetDetail[0]) ? null : parseInt(this.targetDetail[0])
        this.inputMaximum = this.isEmptyForNumberValue(this.targetDetail[1]) ? null : parseInt(this.targetDetail[1])
        this.checkNoInput = this.getBooleanEvenString(this.targetDetail[2])
      }

      this.isInclude = this.incExc !== 'exc'
    },
    hide () {
      this.$emit('hide', { [this.grouping]: { or: this.makeQueryObject() } })
    },
    makeQueryObject () {
      // フィルタの変更検知やクエリストリングのため、すべて文字列型として返す
      const min = isNaN(parseInt(this.inputMinimum)) ? 'null' : parseInt(this.inputMinimum).toString()
      const max = isNaN(parseInt(this.inputMaximum)) ? 'null' : parseInt(this.inputMaximum).toString()
      return [min, max, this.checkNoInput.toString()]
    },
    getBooleanEvenString(val) {
      if (val === 'true') {
        return true
      } else if (val === 'false') {
        return false
      } else if (typeof val === 'boolean') {
        return val
      } else {
        return false
      }
    },
    changeCheckNoInput(e) {
      this.checkNoInput = e === true
    },
    isEmptyForNumberValue(num) {
      return num === 'null'
    },
  },
}
</script>

<style lang="scss" scoped>
  .grouping-text {
    color: $basecolor-cold;
    margin-left: 2px;

    &.is-include {
      color: $basecolor-hot;
    }

    &.inc-text {
      min-width: 24px;
    }

    &.exc-text {
      min-width: 48px;
    }
  }

  .number-input-container {
    display: flex;
    .number-input {
      flex: 5;
    }
    .number-input-assist-text {
      flex: 1;
      text-align: center;
      line-height: 40px;
      padding-left: 4px;
    }
  }
</style>

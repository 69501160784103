import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "trigger-event-container" }

import { TriggerEventBody } from '@/api/openapi';
import TriggerEventCard from '@/components/organisms/user/trigger/TriggerEventCard.vue';
import { TTriggerEventFormMode } from './types';

type TProps = {
  triggerEvent: TriggerEventBody | null;
  editable: boolean;
};
type TEmit = {
  'click:triggerEvent': [triggerEvent: TriggerEventBody, mode: TTriggerEventFormMode];
};

export default /*@__PURE__*/_defineComponent({
  __name: 'TriggerEvent',
  props: {
    triggerEvent: {},
    editable: { type: Boolean }
  },
  emits: ["click:triggerEvent"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const handleSettingBtnClick = (mode: TTriggerEventFormMode) => {
  emit('click:triggerEvent', props.triggerEvent || {
    leadViewId: null,
    eventType: null,
    filterParams: {},
    eventDetail: {},
  }, mode);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TriggerEventCard, {
      "trigger-event": _ctx.triggerEvent,
      editable: _ctx.editable,
      "onClick:setting": handleSettingBtnClick
    }, null, 8, ["trigger-event", "editable"])
  ]))
}
}

})
<template>
  <BColorPreview
    class="mr-50"
    :color-code="colorCode"
    size="small"
  />
  {{ i18n.t(`sequence.status.${props.value}`) }}
</template>

<script lang="ts" setup>
import { ValueOf } from 'element-plus/es/components/table/src/table-column/defaults';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n'
import COLOR_CODES from '@/const/color_codes';
import { TSequenceStatus } from '@/types';

type TColor = {status: TSequenceStatus, colorCode: ValueOf<typeof COLOR_CODES>};

type TProps = {
  value: TSequenceStatus;
};

const props = defineProps<TProps>();

defineEmits(['click']);

const i18n = useI18n();

const colors: TColor[] = [
  {
    status: 'registered',
    colorCode: COLOR_CODES.GAIA,
  },
  {
    status: 'stopped',
    colorCode: COLOR_CODES.VENUS,
  },
  {
    status: 'done',
    colorCode: COLOR_CODES.URANUS,
  },
  {
    status: 'unregistered',
    colorCode: COLOR_CODES.NEPTUNE,
  },
];
const colorCode = computed(() => colors.find(c => c.status === props.value)?.colorCode);
</script>

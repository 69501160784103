import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "save-text" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "py-100" }
const _hoisted_4 = { class: "py-100" }
const _hoisted_5 = { class: "lead-delete" }
const _hoisted_6 = { class: "call-list-template" }
const _hoisted_7 = { class: "lead-list-control" }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BContentLoader = _resolveComponent("BContentLoader")!
  const _component_BCustomIcon = _resolveComponent("BCustomIcon")!
  const _component_LeadViewSelectPanel = _resolveComponent("LeadViewSelectPanel")!
  const _component_BCustomIconText = _resolveComponent("BCustomIconText")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_VWait = _resolveComponent("VWait")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BDivider = _resolveComponent("BDivider")!
  const _component_BCard = _resolveComponent("BCard")!
  const _component_BMenu = _resolveComponent("BMenu")!
  const _component_BMoreMenu = _resolveComponent("BMoreMenu")!
  const _component_LeadCsvImportStatus = _resolveComponent("LeadCsvImportStatus")!
  const _component_LeadExcelImportStatus = _resolveComponent("LeadExcelImportStatus")!
  const _component_LeadCsvOverwriteStatus = _resolveComponent("LeadCsvOverwriteStatus")!
  const _component_LeadExcelOverwriteStatus = _resolveComponent("LeadExcelOverwriteStatus")!
  const _component_LeadDeleteStatus = _resolveComponent("LeadDeleteStatus")!
  const _component_LeadMergeStatus = _resolveComponent("LeadMergeStatus")!
  const _component_CustomFieldDestroyStatus = _resolveComponent("CustomFieldDestroyStatus")!
  const _component_SfLeadDataCheckStatus = _resolveComponent("SfLeadDataCheckStatus")!
  const _component_LeadSfImportStatus = _resolveComponent("LeadSfImportStatus")!
  const _component_FilterSelect = _resolveComponent("FilterSelect")!
  const _component_ListColumnSelect = _resolveComponent("ListColumnSelect")!
  const _component_ListPageControl = _resolveComponent("ListPageControl")!
  const _component_LeadListChild = _resolveComponent("LeadListChild")!
  const _component_CsvImportModal = _resolveComponent("CsvImportModal")!
  const _component_ExcelImportModal = _resolveComponent("ExcelImportModal")!
  const _component_CsvOverwriteModal = _resolveComponent("CsvOverwriteModal")!
  const _component_ExcelOverwriteModal = _resolveComponent("ExcelOverwriteModal")!
  const _component_ExportModal = _resolveComponent("ExportModal")!
  const _component_LeadViewCreateModal = _resolveComponent("LeadViewCreateModal")!
  const _component_LeadViewUpdateModal = _resolveComponent("LeadViewUpdateModal")!
  const _component_BulkMailForm = _resolveComponent("BulkMailForm")!
  const _component_MailFormTransition = _resolveComponent("MailFormTransition")!
  const _component_AllLeadDeleteModal = _resolveComponent("AllLeadDeleteModal")!
  const _component_SfLeadDataCheckModal = _resolveComponent("SfLeadDataCheckModal")!
  const _component_SalesforceReportModal = _resolveComponent("SalesforceReportModal")!
  const _component_AssignOperatorControl = _resolveComponent("AssignOperatorControl")!
  const _component_BContent = _resolveComponent("BContent")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_BContent, { class: "content" }, {
    navbar: _withCtx(() => [
      _createVNode(_component_VWait, {
        class: _normalizeClass(["lead-view-area", { loaded: !_ctx.$wait.is('getLeadViewActionWait') }]),
        for: "none",
        visible: _ctx.$wait.is('getLeadViewActionWait')
      }, {
        waiting: _withCtx(() => [
          _createVNode(_component_BContentLoader, null, {
            default: _withCtx(() => _cache[16] || (_cache[16] = [
              _createElementVNode("rect", {
                x: "0",
                y: "0",
                rx: "3",
                ry: "3",
                width: "150",
                height: "10"
              }, null, -1)
            ])),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_BCustomIcon, {
            "icon-class": "b-list",
            class: "mt-100"
          }),
          _createVNode(_component_LeadViewSelectPanel, {
            class: "mb-300 ml-300",
            onAfterSetLeadView: _ctx.getCallTargetsAndMeta
          }, null, 8, ["onAfterSetLeadView"]),
          _createVNode(_component_BCustomIconText, {
            "text-size": "mini",
            hover: "",
            "icon-size": "large",
            "icon-class": "b-call-analytics",
            class: "ml-200",
            onClick: _ctx.moveAnalytics
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('callAnalytics.title')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          (_ctx.leadView.id)
            ? (_openBlock(), _createBlock(_component_BCustomIconText, {
                key: 0,
                hover: "",
                "text-size": "mini",
                "icon-size": "large",
                "icon-class": "b-list-update",
                class: "ml-200",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$modal.show('LeadViewUpdateModal')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.overwriteSave')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_BTooltip, { top: "" }, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('leadList.save.description')), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_BCustomIconText, {
                hover: "",
                "text-size": "mini",
                "icon-size": "large",
                "icon-class": "b-list-new",
                class: "ml-200",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$modal.show('leadViewCreateModal')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('leadList.save.title')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class", "visible"]),
      (_ctx.isAvailableSalesforceApiFeature)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_BBtn, {
              type: "primary",
              size: "mini",
              class: "mr-200",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$modal.show('SfLeadDataCheckModal')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('leadList.syncSalesforce.title')), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.visibleMailButton)
        ? (_openBlock(), _createBlock(_component_BDivider, {
            key: 1,
            direction: "vertical"
          }))
        : _createCommentVNode("", true),
      (_ctx.visibleMailButton)
        ? (_openBlock(), _createBlock(_component_BTooltip, {
            key: 2,
            top: "",
            content: _ctx.$t('mail.mailCreate')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BCustomIcon, {
                class: "mr-100 ml-100",
                size: "small",
                "icon-class": "b-mail",
                hover: "",
                onClick: _ctx.showBulkMailForm
              }, null, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["content"]))
        : _createCommentVNode("", true),
      _createVNode(_component_BDivider, { direction: "vertical" }),
      _createVNode(_component_BTooltip, {
        top: "",
        content: _ctx.$t('callList.import.title')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_BMenu, {
              modelValue: _ctx.isImportMenuVisible,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isImportMenuVisible) = $event)),
              placement: "bottom"
            }, {
              activator: _withCtx(() => [
                _createVNode(_component_BCustomIcon, {
                  class: "mr-100 ml-100",
                  size: "small",
                  "icon-class": "b-import",
                  hover: ""
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_BCard, { full: "" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_BBtn, {
                        flat: "",
                        fit: "",
                        size: "small",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showImportModal('callTargetSetting')))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('leadList.import.type.single')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_BBtn, {
                        flat: "",
                        fit: "",
                        size: "small",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showImportModal('CsvImportModal')))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('leadList.import.type.csv')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_BBtn, {
                        flat: "",
                        fit: "",
                        size: "small",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showImportModal('ExcelImportModal')))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('leadList.import.type.excel')), 1)
                        ]),
                        _: 1
                      }),
                      (_ctx.isAvailableSalesforceApiFeature)
                        ? (_openBlock(), _createBlock(_component_BBtn, {
                            key: 0,
                            flat: "",
                            fit: "",
                            size: "small",
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showImportModal('SalesforceReportModal')))
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('leadList.import.type.salesforce')), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]),
        _: 1
      }, 8, ["content"]),
      _createVNode(_component_BTooltip, {
        top: "",
        content: _ctx.$t('callList.update.title')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_BMenu, {
              modelValue: _ctx.isOverwriteMenuVisible,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.isOverwriteMenuVisible) = $event)),
              placement: "bottom"
            }, {
              activator: _withCtx(() => [
                _createVNode(_component_BCustomIcon, {
                  class: "update-icon",
                  size: "small",
                  "icon-class": "b-update",
                  hover: ""
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_BCard, { full: "" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_BBtn, {
                        flat: "",
                        fit: "",
                        size: "small",
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showOverwriteModal('CsvOverwriteModal')))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('callList.update.type.csv')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_BBtn, {
                        flat: "",
                        fit: "",
                        size: "small",
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showOverwriteModal('ExcelOverwriteModal')))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('callList.update.type.excel')), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]),
        _: 1
      }, 8, ["content"]),
      (_ctx.$can('owner'))
        ? (_openBlock(), _createBlock(_component_BTooltip, {
            key: 3,
            top: "",
            content: _ctx.$t('callList.export.title')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BCustomIcon, {
                class: "mx-100",
                size: "small",
                "icon-class": "b-export",
                hover: "",
                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$modal.show('LeadExportModal')))
              })
            ]),
            _: 1
          }, 8, ["content"]))
        : _createCommentVNode("", true),
      _createVNode(_component_BMoreMenu, {
        modelValue: _ctx.isDeleteMenuVisible,
        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.isDeleteMenuVisible) = $event)),
        placement: "bottom"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BBtn, {
            flat: "",
            fit: "",
            size: "small",
            onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.showDeleteModal('AllLeadDeleteModal')))
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('callTarget.destroyAll.doing')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    footer: _withCtx(() => [
      (_ctx.isExistCallTargets)
        ? (_openBlock(), _createBlock(_component_AssignOperatorControl, {
            key: 0,
            class: "fixed-bottom",
            "call-targets": _ctx.callTargets,
            "selected-call-target-ids": _ctx.selectedCallTargetIds
          }, null, 8, ["call-targets", "selected-call-target-ids"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_LeadCsvImportStatus, { onSuccess: _ctx.getCallTargets }, null, 8, ["onSuccess"]),
        _createVNode(_component_LeadExcelImportStatus, { onSuccess: _ctx.getCallTargets }, null, 8, ["onSuccess"]),
        _createVNode(_component_LeadCsvOverwriteStatus, { onSuccess: _ctx.getCallTargets }, null, 8, ["onSuccess"]),
        _createVNode(_component_LeadExcelOverwriteStatus, { onSuccess: _ctx.getCallTargets }, null, 8, ["onSuccess"]),
        _createVNode(_component_LeadDeleteStatus, { onSuccess: _ctx.getCallTargets }, null, 8, ["onSuccess"]),
        _createVNode(_component_LeadMergeStatus, { onSuccess: _ctx.getCallTargets }, null, 8, ["onSuccess"]),
        _createVNode(_component_CustomFieldDestroyStatus),
        (_ctx.isAvailableSalesforceApiFeature)
          ? (_openBlock(), _createBlock(_component_SfLeadDataCheckStatus, {
              key: 0,
              onSuccess: _ctx.getCallTargets
            }, null, 8, ["onSuccess"]))
          : _createCommentVNode("", true),
        (_ctx.isAvailableSalesforceApiFeature)
          ? (_openBlock(), _createBlock(_component_LeadSfImportStatus, {
              key: 1,
              onSuccess: _ctx.getCallTargets
            }, null, 8, ["onSuccess"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_ListPageControl, null, {
            default: _withCtx(() => [
              _createVNode(_component_FilterSelect),
              _createVNode(_component_ListColumnSelect)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["lead-list-area", { 'with-footer': _ctx.selectedCallTargetIds.length > 0 }])
        }, [
          _createVNode(_component_LeadListChild, {
            "call-targets": _ctx.callTargets,
            "call-targets-meta": _ctx.callTargetsMeta,
            "custom-fields": _ctx.customFields,
            "onUpdate:selectedCallTargetIds": _ctx.handleSelectedCallTargetIdsUpdate
          }, null, 8, ["call-targets", "call-targets-meta", "custom-fields", "onUpdate:selectedCallTargetIds"])
        ], 2)
      ]),
      _createVNode(_component_CsvImportModal),
      _createVNode(_component_ExcelImportModal),
      _createVNode(_component_CsvOverwriteModal),
      _createVNode(_component_ExcelOverwriteModal),
      (_ctx.$can('owner'))
        ? (_openBlock(), _createBlock(_component_ExportModal, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_LeadViewCreateModal),
      _createVNode(_component_LeadViewUpdateModal),
      _createVNode(_component_MailFormTransition, { "is-visible": _ctx.mailFormVisible }, {
        default: _withCtx(() => [
          _createVNode(_component_BulkMailForm, {
            "bulk-mail": _ctx.bulkMail,
            "onUpdate:bulkMail": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.bulkMail) = $event)),
            "can-duplicate": false,
            onCancel: _ctx.hiddenMailForm,
            onBulkMailChange: _cache[15] || (_cache[15] = ($event: any) => (_ctx.bulkMail = $event))
          }, null, 8, ["bulk-mail", "onCancel"])
        ]),
        _: 1
      }, 8, ["is-visible"]),
      _createVNode(_component_AllLeadDeleteModal),
      (_ctx.isAvailableSalesforceLinkFeature)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_SfLeadDataCheckModal),
            _createVNode(_component_SalesforceReportModal)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  })), [
    [_directive_loading, _ctx.loading]
  ])
}
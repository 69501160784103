import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bulk-mail-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormDrawer = _resolveComponent("FormDrawer")!
  const _component_ConfirmDrawer = _resolveComponent("ConfirmDrawer")!
  const _component_SendingDrawer = _resolveComponent("SendingDrawer")!
  const _component_SentDrawer = _resolveComponent("SentDrawer")!
  const _component_MailTemplateSelectModal = _resolveComponent("MailTemplateSelectModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.currentState === _ctx.state.form)
      ? (_openBlock(), _createBlock(_component_FormDrawer, _mergeProps({ key: 0 }, _ctx.formAttr, {
          onChangeCurrentStateToConfirm: _ctx.toConfirm,
          onSaveDraft: _ctx.saveDraft,
          onCancel: _ctx.closeDrawer,
          onEditForm: _ctx.editForm,
          onDeleteDraft: _ctx.deleteDraft,
          onDuplicate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('duplicate', _ctx.bulkMail)))
        }), null, 16, ["onChangeCurrentStateToConfirm", "onSaveDraft", "onCancel", "onEditForm", "onDeleteDraft"]))
      : (_ctx.currentState === _ctx.state.confirm)
        ? (_openBlock(), _createBlock(_component_ConfirmDrawer, _mergeProps({ key: 1 }, _ctx.confirmAttr, {
            ref: "confirmDrawer",
            onCancel: _ctx.closeDrawer,
            onChangeCurrentStateToForm: _ctx.toForm,
            onSend: _ctx.send,
            onReserve: _ctx.reserve
          }), null, 16, ["onCancel", "onChangeCurrentStateToForm", "onSend", "onReserve"]))
        : (_ctx.currentState === _ctx.state.sending)
          ? (_openBlock(), _createBlock(_component_SendingDrawer, {
              key: 2,
              onCancel: _ctx.closeDrawer,
              onSendingAnimationCompleted: _ctx.onSendingAnimationCompleted
            }, null, 8, ["onCancel", "onSendingAnimationCompleted"]))
          : (_ctx.currentState === _ctx.state.sent)
            ? (_openBlock(), _createBlock(_component_SentDrawer, _mergeProps({ key: 3 }, _ctx.confirmAttr, {
                onCancel: _ctx.closeDrawer,
                onDuplicate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('duplicate', _ctx.bulkMail)))
              }), null, 16, ["onCancel"]))
            : _createCommentVNode("", true),
    (_ctx.isAvailableMailTemplateFeature)
      ? (_openBlock(), _createBlock(_component_MailTemplateSelectModal, {
          key: 4,
          onClick: _ctx.applyMailTemplate
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}
import { TConfigType, formatUtcISO8601String, newDate, isISO8601String } from '@/utils/date-time'

type TReplacerFn = (key: unknown, value: TConfigType) => TConfigType

const fnReplacer = (replacer: TReplacerFn): TReplacerFn => {
  let fn = replacer || function (_key, value) { return value }
  fn = (key, value) => {
    if (isISO8601String(value)) {
      value = formatUtcISO8601String(value)
    }
    if (replacer) {
      value = replacer(key, value)
    }
    return value
  }

  return fn
}

const fnReviver = (reviver) => {
  return (key, value) => {
    if (isISO8601String(value)) {
      value = newDate(value)
    }
    if (reviver) {
      value = reviver(key, value)
    }
    return value
  }
}

export default {
  install (app) {
    app.config.globalProperties.$JSON = {
      stringify: (obj) => {
        return JSON.stringify(obj, fnReplacer(null))
      },
      parse: (text, reviver) => {
        return JSON.parse(text, fnReviver(reviver))
      },
    }
  },
}

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from 'vue';
import { CustomActionApiService } from '@/api/user/resources/custom_action';
import { useInputValidations } from '@/composable/input-validations';
import { useWait } from '@/composable/vue-wait';

type TEmit = {
  (event: 'afterCreate'): void;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'CustomActionCreatingForm',
  emits: ["afterCreate"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const { doActionWithWait } = useWait();

const { requiredRule } = useInputValidations();

const name = ref('');
const valid = ref(false);

const clear = () => {
  name.value = '';
  valid.value = false;
};


const handleSubmit = async () => {
  await doActionWithWait('creatingCustomActionWait', async () => {
    const api = new CustomActionApiService();
    await api.createCustomAction(
      {
        request: {
          postCustomActionBody: {
            name: name.value,
          },
        },
      },
    );
  });
  emit('afterCreate');
  clear();
}; 

return (_ctx: any,_cache: any) => {
  const _component_BInput = _resolveComponent("BInput")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!

  return (_openBlock(), _createBlock(_component_BLayout, {
    class: "custom-action-creating-form-container",
    "align-center": "",
    "justify-center": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BInput, {
        modelValue: name.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((name).value = $event)),
        valid: valid.value,
        "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => ((valid).value = $event)),
        class: "input-name mr-400",
        placeholder: _ctx.$t('customAction.name'),
        rules: [_unref(requiredRule)],
        "max-length": 255,
        onKeypressEnter: handleSubmit
      }, null, 8, ["modelValue", "valid", "placeholder", "rules"]),
      _createVNode(_component_BBtn, {
        type: "primary",
        disabled: !valid.value,
        loading: _ctx.$wait.is('creatingCustomActionWait'),
        onClick: handleSubmit
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.append.text')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "loading"])
    ]),
    _: 1
  }))
}
}

})
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "item-filter-area" }
const _hoisted_2 = { class: "item-filter-area-candidate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BRadio = _resolveComponent("BRadio")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.radioItems, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.value,
          class: "my-100"
        }, [
          _createVNode(_component_BRadio, {
            modelValue: _ctx.internalSelectedItem,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalSelectedItem) = $event)),
            label: item.label
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.displayValue), 1)
            ]),
            _: 2
          }, 1032, ["modelValue", "label"])
        ]))
      }), 128))
    ])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "title-field" }
const _hoisted_2 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BModalHeader = _resolveComponent("BModalHeader")!
  const _component_BTextarea = _resolveComponent("BTextarea")!
  const _component_BModalBody = _resolveComponent("BModalBody")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BModalFooter = _resolveComponent("BModalFooter")!
  const _component_BHeightAdjuster = _resolveComponent("BHeightAdjuster")!
  const _component_BModal = _resolveComponent("BModal")!

  return (_openBlock(), _createBlock(_component_BModal, { "modal-name": $options.modalName }, {
    default: _withCtx(() => [
      _createVNode(_component_BHeightAdjuster, null, {
        default: _withCtx(() => [
          _createVNode(_component_BModalHeader, { onModalClose: $options.hide }, null, 8, ["onModalClose"]),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('openaiGenarate.title')), 1),
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('openaiGenarate.description')), 1),
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('openaiGenarate.sourceDescription')), 1)
          ]),
          _createVNode(_component_BModalBody, null, {
            default: _withCtx(() => [
              _createVNode(_component_BTextarea, {
                modelValue: $data.generateBaseText,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.generateBaseText) = $event)),
                resize: "none",
                autosize: { minRows: 24, maxRows: 24 }
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_BModalFooter, null, {
            default: _withCtx(() => [
              _createVNode(_component_BLayout, { "justify-center": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_BBtn, {
                    type: "primary",
                    loading: $options.isLoading,
                    disabled: $options.isDisabled,
                    onClick: _withModifiers($options.generateContentByOpenai, ["stop"])
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t(`openaiGenarate.generate`)), 1)
                    ]),
                    _: 1
                  }, 8, ["loading", "disabled", "onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-name"]))
}
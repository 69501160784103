import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ml-50 fw-bold" }

import { computed, ref, watch, inject } from 'vue';
import { PriorityPriorityEnum } from '@/api/openapi';
import QuickTextSelectModal from '@/components/organisms/user/lead/modal/QuickTextSelectModal.vue';
import { useInsertText } from '@/composable/input';
import { useNextActionTypes } from '@/composable/user/nextActionType/next-action-types';
import { useUsers } from '@/composable/user/user/users';
import { KEY_OF_MODAL } from '@/injection-keys';
import BInput from '@/plugins/biscuet-materials/components/atoms/BInput.vue';

type TProps = {
  modelValue: TCommonNextAction;
  isValid: boolean;
  isShort?: boolean;
  callTargetId?: number | null;
  withOwner?: boolean;
};
type TEmit = {
  'update:modelValue': [modelValue: TCommonNextAction];
  'update:isValid': [isValid: boolean];
}
export type TCommonNextAction = TCommonNextActionWithoutOwner | TCommonNextActionWithOwner;
type TKeyOfCommonNextAction = keyof TCommonNextAction | keyof TCommonNextActionWithOwner;
type TCommonNextActionWithoutOwner = {
  actionType: string;
  priority: PriorityPriorityEnum;
  content: string;
};
type TCommonNextActionWithOwner = TCommonNextActionWithoutOwner & {
  ownerId: number;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'NextActionFormBody',
  props: {
    modelValue: {},
    isValid: { type: Boolean },
    isShort: { type: Boolean, default: false },
    callTargetId: { default: null },
    withOwner: { type: Boolean, default: true }
  },
  emits: ["update:modelValue", "update:isValid"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const isModelWithOwner = (model: TCommonNextAction): model is TCommonNextActionWithOwner => {
  return 'ownerId' in model;
};

const { nextActionTypes } = useNextActionTypes();
const { activeUsers } = useUsers();

const isNextActionContentValid = ref(true);

const actionType = computed({
  get: () => props.modelValue.actionType,
  set: (v) => updateFormValues('actionType', v),
});
const priority = computed({
  get: () => props.modelValue.priority,
  set: (v) => updateFormValues('priority', v),
});
const ownerId = computed({
  get: () => isModelWithOwner(props.modelValue) ? props.modelValue.ownerId : null,
  set: (v) => updateFormValues('ownerId', v),
});
const content = computed({
  get: () => props.modelValue.content,
  set: (v) => updateFormValues('content', v),
});

const itemClass = computed(() => ({
  'mb-200': props.isShort,
  'mb-300': !props.isShort,
}));

const contentTextarea = ref<InstanceType<typeof BInput>>(null);
const { insertText: insertTextToContent } = useInsertText(contentTextarea, content)

const handleQuickTextSelect = (content: string) => {
  insertTextToContent(content)
}

const quickTextSelectModalName = `quickTextSelectInNextActionFormBody-${crypto.randomUUID()}`

const modal = inject(KEY_OF_MODAL);
const handleQuickTextButtonClick = () => {
  modal.show(quickTextSelectModalName)
};

watch(
  () => props.modelValue,
  (val) => {
    const isValid = val.actionType != null
      && isNextActionContentValid.value
      && isModelWithOwner(val) ? val.ownerId != null : true;
    emit('update:isValid', isValid);
  },
  { immediate: true },
);

const updateFormValues = (key: TKeyOfCommonNextAction, value: unknown) => {
  const newModelValue = {
    ...props.modelValue,
    [key]: value,
  };

  emit('update:modelValue', newModelValue);
};

return (_ctx: any,_cache: any) => {
  const _component_BSelect = _resolveComponent("BSelect")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BPrioritySelect = _resolveComponent("BPrioritySelect")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BListItem, {
      class: _normalizeClass(itemClass.value)
    }, {
      header: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('nextAction.actionType.title')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_BSelect, {
          modelValue: actionType.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((actionType).value = $event)),
          items: _unref(nextActionTypes),
          "item-text": "name",
          "item-value": "id",
          fit: "",
          required: ""
        }, null, 8, ["modelValue", "items"])
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_BListItem, {
      class: _normalizeClass(itemClass.value)
    }, {
      header: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('general.priority.title')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_BPrioritySelect, {
          modelValue: priority.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((priority).value = $event)),
          fit: "",
          required: ""
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["class"]),
    (_ctx.withOwner)
      ? (_openBlock(), _createBlock(_component_BListItem, {
          key: 0,
          class: _normalizeClass(itemClass.value)
        }, {
          header: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('nextAction.owner')), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_BSelect, {
              modelValue: ownerId.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((ownerId).value = $event)),
              items: _unref(activeUsers),
              "item-text": "name",
              "item-value": "id",
              fit: "",
              required: ""
            }, null, 8, ["modelValue", "items"])
          ]),
          _: 1
        }, 8, ["class"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "dateTime"),
    _createVNode(_component_BListItem, {
      class: _normalizeClass(itemClass.value)
    }, {
      header: _withCtx(() => [
        (_ctx.callTargetId == null)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('nextAction.content')), 1))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        (_ctx.callTargetId != null)
          ? (_openBlock(), _createBlock(_component_BBtn, {
              key: 0,
              text: "",
              type: "success",
              onClick: handleQuickTextButtonClick
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BIcon, {
                  type: "success",
                  outlined: ""
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" article ")
                  ])),
                  _: 1
                }),
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('quickText.apply')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(BInput, {
          ref_key: "contentTextarea",
          ref: contentTextarea,
          modelValue: content.value,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((content).value = $event)),
          valid: isNextActionContentValid.value,
          "onUpdate:valid": _cache[4] || (_cache[4] = ($event: any) => ((isNextActionContentValid).value = $event)),
          type: "textarea",
          autosize: { minRows: 12, maxRows: 12 },
          "max-length": 1000,
          placeholder: _ctx.$t('nextAction.content'),
          resize: "none",
          validation: "",
          copyable: ""
        }, null, 8, ["modelValue", "valid", "placeholder"])
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(QuickTextSelectModal, {
      "modal-name": quickTextSelectModalName,
      "call-target-id": _ctx.callTargetId,
      onSelect: handleQuickTextSelect
    }, null, 8, ["call-target-id"])
  ], 64))
}
}

})
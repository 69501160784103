import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

const _hoisted_1 = { class: "url" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import LeadHistoryCard from '@/components/organisms/user/lead/history/LeadHistoryCard.vue';
import { TObject } from '@/types';
import { formatShorterDateTime } from '@/utils/date-time';

type TProps = {
  sendgridEvent: TObject & {
    localEvent: string;
    historiedAt: string;
    eventCount: number;
  }; // TODO: 型定義
  expand: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MailEventCard',
  props: {
    sendgridEvent: {},
    expand: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
const i18n = useI18n();

const title = computed(() => {
  if (props.sendgridEvent == null || props.sendgridEvent.localEvent == null || props.sendgridEvent.subject == null) return i18n.t('mailEvent.unknown')

  return i18n.t(`mailEvent.${props.sendgridEvent.localEvent}`, { subject: props.sendgridEvent.subject })
});

const isExistUrl = computed(() => {
  return props.sendgridEvent && props.sendgridEvent.localEvent === 'click' && typeof props.sendgridEvent.url === 'string'
});

const needsToggle = computed(() => {
  return isExistUrl.value || props.sendgridEvent.isFailed;
});

const historiedAt = computed(() => {
  return formatShorterDateTime(props.sendgridEvent.historiedAt)
});

const iconTooltip = computed(() => {
  if (props.sendgridEvent.eventCount <= 1) return '';
  if (!['open', 'click'].includes(props.sendgridEvent.localEvent) && !props.sendgridEvent.isFailed) return '';

  return i18n.t('mailNotificationEvent.eventCount', { count: props.sendgridEvent.eventCount });
});

const icon = computed<{ fileName: string; type: string; eventEmotion?: string; }>(() => {
  if (props.sendgridEvent == null || props.sendgridEvent.localEvent == null) {
    return { fileName: 'remove', type: 'hot' }
  }
  const iconSetting = {
    fileName: 'mail',
    type: 'hot',
    eventEmotion: undefined,
  }
  switch (props.sendgridEvent.localEvent) {
    case 'open':
      iconSetting.fileName = 'drafts'
      iconSetting.eventEmotion = 'positive'
      break
    case 'click':
      iconSetting.fileName = 'touch_app'
      iconSetting.eventEmotion = 'positive'
      break
    case 'delivered':
      iconSetting.fileName = 'golf_course'
      iconSetting.eventEmotion = 'positive'
      break
    default:
      break
  }
  return iconSetting
});

const attributes = computed(() => ({
  text: title.value,
  icon: icon.value.fileName,
  iconType: icon.value.type,
  expand: props.expand,
  toggle: needsToggle.value,
  canNotExpand: !needsToggle.value,
  isEvent: true,
  eventEmotion: icon.value.eventEmotion,
  iconTooltip: iconTooltip.value,
}));

return (_ctx: any,_cache: any) => {
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BError = _resolveComponent("BError")!

  return (_openBlock(), _createBlock(LeadHistoryCard, _normalizeProps(_guardReactiveProps(attributes.value)), {
    date: _withCtx(() => [
      _createElementVNode("div", null, _toDisplayString(historiedAt.value), 1)
    ]),
    default: _withCtx(() => [
      (isExistUrl.value)
        ? (_openBlock(), _createBlock(_component_BListItem, {
            key: 0,
            class: "url-container"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString(_ctx.$t('mailEvent.url')), 1)
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.sendgridEvent.url), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.sendgridEvent.isFailed)
        ? (_openBlock(), _createBlock(_component_BError, {
            key: 1,
            "error-title": _ctx.$t('mailEvent.failedInfoTitle'),
            "error-info": _ctx.$t(`mailEvent.${props.sendgridEvent.localEvent}Info`)
          }, null, 8, ["error-title", "error-info"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "trigger-action-container" }
const _hoisted_2 = { class: "b-connector" }

import { useI18n } from 'vue-i18n';
import { TriggerActionBody } from '@/api/openapi';
import TriggerActionCard from '@/components/organisms/user/trigger/TriggerActionCard.vue';
import { TMenu } from '@/plugins/biscuet-materials/components/molecules/connector';
import { TTriggerActionFormMode, TTriggerActionMenu, TriggerActionBodyExt } from './types';

type TProps = {
  triggerAction: TriggerActionBody | null;
  editable: boolean;
};
type TEmit = {
  'click:triggerAction': [triggerAction: TriggerActionBodyExt, mode: TTriggerActionFormMode];
};

export default /*@__PURE__*/_defineComponent({
  __name: 'TriggerAction',
  props: {
    triggerAction: {},
    editable: { type: Boolean }
  },
  emits: ["click:triggerAction"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const i18n = useI18n();
const menus: TMenu<TTriggerActionMenu> = [
  { key: 'trigger_action', label: i18n.t('trigger.actionSetting.types.triggerAction') },
];

const handleAddBtnClick = (menu: TTriggerActionMenu) => {
  if (menu === 'trigger_action') {
    emit('click:triggerAction', {
      approveUserId: null,
      actionType: null,
      actionDetail: {},
      sortOrder: null,
      appendIndex: props.triggerAction?.sortOrder,
    }, 'form');
  }
};

const handleUpdateBtnClick = (mode: TTriggerActionFormMode) => {
  emit('click:triggerAction', props.triggerAction, mode);
};

return (_ctx: any,_cache: any) => {
  const _component_BConnector = _resolveComponent("BConnector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BConnector, {
        size: "medium",
        "add-button-position": "middle",
        menus: menus,
        "disabled-add-button": !_ctx.editable,
        "onClick:addButton": handleAddBtnClick
      }, null, 8, ["disabled-add-button"])
    ]),
    (_ctx.triggerAction)
      ? (_openBlock(), _createBlock(TriggerActionCard, {
          key: 0,
          "trigger-action": _ctx.triggerAction,
          editable: _ctx.editable,
          "onClick:setting": handleUpdateBtnClick
        }, null, 8, ["trigger-action", "editable"]))
      : _createCommentVNode("", true)
  ]))
}
}

})
<template>
  <div class="free-tag-form">
    <BSelectMenu
      v-bind="attributes"
      data-test="freeTagFormMenu"
      @click-append="handleClickAppend"
      @click-delete="handleClickDelete"
    />
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait'

export default {
  props: {
    selectedIds: {
      type: Array,
      default () {
        return []
      },
    },
    candidates: Array,
    callTargetId: Number,
    loading: Boolean,
  },
  emits: [
    'change',
  ],
  computed: {
    attributes () {
      return {
        'candidates': this.candidates,
        'selectedIds': this.selectedIds,
        'loading': this.loading,
        'item-text': 'text',
      }
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      appendCallTargetFreeTagAction: 'appendCallTargetFreeTagWait',
      deleteCallTargetFreeTagAction: 'deleteCallTargetFreeTagWait',
    }),
    async handleClickAppend (freeTagId) {
      await this.appendCallTargetFreeTagAction({
        id: this.callTargetId,
        body: { freeTagId },
      })
      this.$emit('change')
    },
    async handleClickDelete (freeTagId) {
      await this.deleteCallTargetFreeTagAction({
        id: this.callTargetId,
        body: { freeTagId },
      })
      this.$emit('change')
    },
  },
}
</script>

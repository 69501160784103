import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "button-container" }

import { useRouter } from 'vue-router';
import { i18nGlobal as i18n } from '@/i18n';
import { bitterAlert } from '@/plugins/BBitterAlert';

type TProps = {
  canSave: boolean;
  loading: boolean;
  needConfirmationToLeave: boolean;
};
type TEmit = {
  'click:save': []
};


export default /*@__PURE__*/_defineComponent({
  __name: 'TriggerFooter',
  props: {
    canSave: { type: Boolean },
    loading: { type: Boolean },
    needConfirmationToLeave: { type: Boolean }
  },
  emits: ["click:save"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const router = useRouter();

const handleClickBack = async () => {
  if (props.needConfirmationToLeave) {
    const ok = await bitterAlert.show({
      title: i18n.t('general.confirm.text'),
      text: i18n.t('general.alert.of', { target: i18n.t('general.unsavedValue'), action: i18n.t('general.clear') }),
    });
    if (!ok) return;
  }
  router.push({ name: 'Triggers' });
};

const handleClickSave = () => {
  if (!props.canSave) return;
  emit('click:save');
};

return (_ctx: any,_cache: any) => {
  const _component_BBtn = _resolveComponent("BBtn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BBtn, {
      type: "none",
      loading: _ctx.loading,
      onClick: _withModifiers(handleClickBack, ["stop"])
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('general.back.text')), 1)
      ]),
      _: 1
    }, 8, ["loading"]),
    _createVNode(_component_BBtn, {
      disabled: !_ctx.canSave,
      loading: _ctx.loading,
      type: "primary",
      onClick: _withModifiers(handleClickSave, ["stop"])
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('general.save.text')), 1)
      ]),
      _: 1
    }, 8, ["disabled", "loading"])
  ]))
}
}

})
import Waiting from '@/store/waiting'

export default class SalesforceSettingActions {
  constructor(Api, types) {
    const actions = {
      // SalesForceBaseActionでの依存をなくしたのち削除する
      async getSalesforceSettingAction({ commit }, args) {
        return await waiting.check('getSalesforceSettingAction', async () => {
          try {
            const res = await Api.getSalesforceSetting(args)
            commit(types.SET_SALESFORCE_SETTING, res.data.salesforceSetting)
          } catch (err) {
            commit(types.CLEAR_SALESFORCE_SETTING)
            throw err
          }
        })
      },
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

<template>
  <BContent
    v-if="isAvailableBulkMailFeature"
    v-loading="$wait.is(['getBulkMailsWait'])"
    class="content"
  >
    <template #navbar>
      <div class="navbar-left">
        <span class="page-title fs-400 fw-bold">{{ $t('mail.title') }}</span>
        <button
          class="create-btn"
          @click="showNewForm"
        >
          <BIcon class="mail-icon">
            email
          </BIcon>
          <span class="ml-300">{{ $t('mail.create') }}</span>
        </button>
      </div>
      <div class="navbar-right">
        <BPagination
          v-model="currentPage"
          :length="allPageCount"
          :total-visible="5"
        />
      </div>
    </template>
    <div class="mt-50">
      <MailList
        :bulk-mails="bulkMails"
        @click="showEditForm"
        @duplicate="showDuplicatedNewForm"
      />
      <MailFormTransition :is-visible="formVisible">
        <BulkMailForm
          v-model:bulk-mail="bulkMail"
          :current-page="currentPage"
          :can-duplicate="true"
          @cancel="cancel"
          @duplicate="showDuplicatedNewForm"
          @change="resetOriginalBulkMail"
          @bulk-mail-change="bulkMail = $event"
        />
      </MailFormTransition>
      <OpenaiContentGenerate
        ref="openai"
        :set-content="setOpenAiContent"
      />
    </div>
  </BContent>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import Api from '@/api/user'
import OpenaiContentGenerate from '@/components/organisms/user/mail/bulk/drawer/state/form/OpenaiContentGenerate.vue'
import BulkMailForm from '@/components/organisms/user/mail/BulkMailForm.vue'
import MailFormTransition from '@/components/organisms/user/mail/common/drawer/MailFormTransition.vue'
import { makeQuillContent } from '@/components/organisms/user/mail/common/drawer/state/form/QuillContentEditor.vue';
import MailList from '@/components/organisms/user/mail/MailList.vue'
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import MAIL_STATUSES from '@/const/mail_statuses'
import Intercom from '@/mixins/intercom'
import { isSameObject } from '@/services/object-compare-service';

export default defineComponent({
  name: 'Mail',
  components: {
    MailList,
    BulkMailForm,
    MailFormTransition,
    OpenaiContentGenerate,
  },
  mixins: [Intercom],
  async beforeRouteLeave(to, from, next) {
    const confirmResult = await this.showConfirm();
    if (!confirmResult) return;
    next()
  },
  setup(props) {
    const { isAvailableBulkMailFeature } = useAvailableFeatureCheck()
    return {
      isAvailableBulkMailFeature,
      ...Intercom.setup(props),
    }
  },
  data() {
    return {
      formVisible: false,
      currentPage: 1,
      originalBulkMail: {},
      bulkMail: {},
    }
  },
  computed: {
    ...mapGetters('user', ['bulkMails', 'bulkMailsMeta']),
    isSentDrawer() {
      return this.bulkMail?.status === MAIL_STATUSES.SENT
    },
    allPageCount() {
      return this.bulkMailsMeta?.totalPages || 0;
    },
  },
  watch: {
    currentPage(newVal, oldVal) {
      if (newVal !== oldVal) this.getBulkMailsAction({ currentPage: newVal })
    },
  },
  created() {
    this.getBulkMailsAction({ currentPage: this.currentPage })
  },
  methods: {
    ...mapWaitingActions('user', {
      getBulkMailsAction: 'getBulkMailsWait',
      clearBulkMailAction: 'clearBulkMailActionWait',
      getDuplicatedBulkMailAction: 'getDuplicatedBulkMailsWait',
    }),
    showNewForm() {
      this.showEditForm() // 新規作成時なので引数は渡さない
    },

    async showDuplicatedNewForm(mail) {
      const confirmResult = await this.showConfirm()
      if (!confirmResult) return false

      this.hideForm()
      this.bulkMail = await this.getDuplicatedBulkMail(mail.id)
      this.resetOriginalBulkMail()
      this.formVisible = true
    },
    async showEditForm(mail = null) {
      const confirmResult = await this.showConfirm()
      if (!confirmResult) return false
      const confirmReservationCancelResult = await this.showConfirmReservationCancel(mail)
      if (!confirmReservationCancelResult) return false
      const alreadyOpenSameBulkMail = this.checkSameBulkMail(mail?.id || null)
      if (alreadyOpenSameBulkMail) return false

      this.hideForm()
      this.bulkMail = mail?.id ? await this.getBulkMail(mail.id) : {}
      this.resetOriginalBulkMail()
      setTimeout(() => this.formVisible = true, 200)
    },
    async getBulkMail(bulkMailId) {
      const res = await Api.getBulkMail({ bulkMailId })
      const bulkMail = Object.values(res.data)[0]
      return bulkMail
    },
    async getDuplicatedBulkMail(bulkMailId) {
      const bulkMail = await this.getBulkMail(bulkMailId)
      return {
        status: MAIL_STATUSES.DRAFT,
        campaignName: bulkMail.campaignName,
        subject: bulkMail.subject,
        content: bulkMail.content,
      }
    },
    async cancel() {
      const confirmResult = await this.showConfirm();
      if (!confirmResult) return;
      this.$refs.openai.cancel()
      this.hideForm()
    },
    async hideForm() {
      this.formVisible = false
      this.originalBulkMail = {}
      this.bulkMail = {}
    },
    async showConfirm() {
      let result = true
      const isSame = await isSameObject(this.originalBulkMail, this.bulkMail, true);
      if (!isSame) {
        result = await this.$bitterAlert.show({
          text: this.$t('mail.confirmShowForm'),
        })
      }
      return result
    },
    async showConfirmReservationCancel(mail){
      let result = true
      if (this.isPendingDrawer(mail)) {
        result = await this.$bitterAlert.show({
          text: this.$t('mail.confirmReservationCancel'),
        })
      }
      return result
    },
    resetOriginalBulkMail(){
      this.originalBulkMail = JSON.parse(JSON.stringify(this.bulkMail))
    },
    checkSameBulkMail(bulkMailId) {
      if (this.bulkMail.id == null || bulkMailId == null) return false
      return this.bulkMail.id === bulkMailId
    },
    setOpenAiContent(data) {
      this.bulkMail.content = makeQuillContent(data);
    },
    isPendingDrawer(mail) {
      return mail?.status === MAIL_STATUSES.PENDING
    },
  },
})
</script>

<style lang="scss" scoped>
  .navbar-left {
    display: flex;
    height: 100%;
    margin-top: 3px;
    .create-btn {
      position: relative;
      width: 150px;
      height: 37px;
      margin-top: 5px;
      margin-left: 30px;
      color: $textcolor-white;
      background-color: $basecolor-primary;
      border-radius: 20px;
      outline: none;
      .mail-icon {
        position: absolute;
        top: 10px;
        left: 15px;
        color: $textcolor-white;
      }
    }
    .page-title {
      display: block;
      margin-top: 12px;
    }
  }

  .navbar-right {
    margin-left: auto;
    margin-top: 3px;
  }

  .content {
    position: relative;
  }
</style>

<template>
  <BLayout
    column
  >
    <h3 class="mb-300">
      {{ $t('import.salesforce.reportSearchTitle') }}
    </h3>
    <BLayout
      align-center
    >
      <div>
        {{ $t('import.salesforce.reportSearchKey') }}
      </div>
      <BInput
        v-model="reportId"
        class="mx-200"
        :placeholder="$t('general.search.placeholder')"
        :disabled="loading"
      />
      <BBtn
        size="small"
        type="primary"
        :disabled="loading"
        @click="search"
      >
        <span>{{ $t('general.search.exec') }}</span>
      </BBtn>
    </BLayout>
    <h3
      v-if="Object.keys(headers).length > 0"
      class="my-200"
    >
      {{ $t('import.salesforce.reportResultTitle') }}
    </h3>
    <div v-if="Object.keys(headers).length > 0 && rows.length == 0">
      {{ $t('apiError.notFound') }}
    </div>
    <div
      v-if="Object.keys(headers).length > 0"
      class="import-confirm-list my-200"
    >
      <div class="lead-import-confirm-header">
        <span>{{ `${$t('import.salesforce.targetCount')}${reportCount}` }}</span>
      </div>
      <div
        v-if="Object.keys(headers).length > 0"
        class="lead-import-confirm-body"
      >
        <ElTable
          scrollbar-always-on="true"
          :data="rows"
        >
          <ElTableColumn
            v-for="(header, index) in headers"
            :key="index"
            :prop="index"
            :label="`${header} \n ${index}`"
            width="180px"
          />
        </ElTable>
      </div>
    </div>
  </BLayout>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import api from '@/api/admin/resources/salesforce';

type TProps = {
  id: number;
};
const props = defineProps<TProps>();

const loading = ref<boolean>(false)
const reportId = ref<string>('')
const rows = ref<Map<string, string>[]>([])
const reportCount = ref<number>(null)
const headers = ref<Map<string, string>[]>([])

const errorHandler = (error) => {
  rows.value = []
  reportCount.value = 0
  headers.value = []
  loading.value = false
}

const search = async () => {
  loading.value = true
  rows.value = []
  reportCount.value = 0
  headers.value = []
  const result = await api.searchSalesforceReport({
    reportId: reportId.value,
    clientId: props.id,
  }).catch(errorHandler)
  if (result == undefined) return;

  rows.value = result.data.sfReport.rows
  reportCount.value = result.data.sfReport.reportRowCount
  headers.value = result.data.sfReport.headers
  loading.value = false
}
</script>

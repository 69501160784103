<template>
  <div class="table-area">
    <div v-if="isLoading">
      <BPlaceholder
        v-loading="true"
        height="50vh"
        element-loading-background="transparent"
      />
    </div>
    <div v-else>
      <ElTable
        :data="adjustedUsers"
        height="45vh"
        :default-sort="{prop: 'id', order: 'ascending'}"
      >
        <ElTableColumn
          v-for="(header, index) in headers.slice(0,5)"
          :key="header.value"
          :prop="header.value"
          :label="header.text"
          sortable
          :width="columnsWidth[index]"
        />
        <ElTableColumn
          :label="headers[5].text"
          width="80"
        >
          <template #default="userProps1">
            <span>{{ $t(`user.enum.${userProps1.row.role}`) }}</span>
          </template>
        </ElTableColumn>
        <ElTableColumn
          :label="headers[6].text"
          width="100"
        >
          <template #default="userProps2">
            <span>{{ $t(`callStatus.enum.${userProps2.row.status}`) }}</span>
          </template>
        </ElTableColumn>
        <ElTableColumn
          :label="headers[7].text"
          width="90"
        >
          <template #default="userProps3">
            <span>{{ $t(`accountStatus.enum.${userProps3.row.activated}`) }}</span>
          </template>
        </ElTableColumn>
        <ElTableColumn
          :label="headers[8].text"
          width="60"
          header-align="center"
        >
          <template #default="userProps4">
            <BBtn
              fab
              text
              @click="() => openUserEditDialog(userProps4.row)"
            >
              <BIcon>edit</BIcon>
            </BBtn>
          </template>
        </ElTableColumn>
      </ElTable>
      <BLayout class="pt-300">
        <BFlex
          :span="4"
          align-self-center
        >
          <BLayout justify-center>
            <span>{{ pageStart }} - {{ pageEnd }} / {{ totalCount }}</span>
          </BLayout>
        </BFlex>
        <BFlex :span="16">
          <BLayout justify-center>
            <BPagination
              v-model="currentPage"
              :length="allPageCount"
              :total-visible="9"
            />
          </BLayout>
        </BFlex>
        <BFlex :span="4">
          <BSelect
            v-model="pageSize"
            class="mx-600"
            :items="pageSizeChoice"
          />
        </BFlex>
      </BLayout>
    </div>
    <FormDialog
      :dialog="userEditDialog"
      width="500"
    >
      <template #form>
        <UserForm
          v-bind="userProps"
          ref="userEditForm"
          :loading="$wait.is('updateUserWait')"
          :is-mine="false"
          @save="updateUser"
          @cancel="closeUserEditDialog"
        >
          <template #title>
            <span class="headline">{{ $t('userManagement.user') + $t('general.edit.text') }}</span>
          </template>
        </UserForm>
      </template>
    </FormDialog>
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import errorHandler from '@/mixins/error_handler'

export default {
  name: 'UsersList',
  mixins: [errorHandler],
  data () {
    return {
      userEditDialog: false,
      selectedUser: {
        id: 0,
        name: '',
        email: '',
      },
      currentPage: 1,
      pageSize: 20,
      pageSizeChoice: [
        { text: '20', value: 20 },
        { text: '35', value: 35 },
        { text: '50', value: 50 },
      ],
      columnsWidth: [80, 180, 140, 140, 300],
    }
  },
  computed: {
    ...mapGetters('admin', [
      'users',
    ]),
    isLoading () {
      return this.$wait.is(['getClientWait', 'updateClientWait', 'createUserWait', 'getUsersWait', 'getClientLimitSettingWait', 'updateClientLimitSettingWait'])
    },
    adjustedUsers () {
      return this.users.slice(this.pageStart - 1, this.pageEnd)
    },
    pageStart () {
      return this.pageSize * (this.currentPage - 1) + 1
    },
    pageEnd () {
      const defaultEnd = this.pageSize * this.currentPage
      return Math.min(defaultEnd, this.totalCount)
    },
    totalCount () {
      return this.users.length
    },
    allPageCount () {
      return Math.ceil(this.totalCount / this.pageSize)
    },
    headers () {
      return Object.entries(this.$tm('userManagement.headers')).map(([key, value]) => ({
        text: value,
        value: key,
      }))
    },
    userProps () {
      return this.selectedUser
    },
  },
  methods: {
    ...mapWaitingActions('admin', {
      updateUserAction: 'updateUserWait',
    }),
    openUserEditDialog (user) {
      this.selectedUser = user
      this.userEditDialog = true
    },
    closeUserEditDialog () {
      this.selectedUser = {}
      this.userEditDialog = false
      this.$refs.userEditForm.reset()
    },
    async updateUser (user) {
      await this.updateUserAction({
        clientId: this.selectedUser.clientId,
        id: this.selectedUser.id,
        body: {
          user,
          clientId: this.selectedUser.clientId,
        },
        errorHandlers: { 422: this.defaultHandler },
      })
      this.selectedUser = {}
      this.userEditDialog = false
      this.$refs.userEditForm.reset()
    },
  },
}
</script>

<style lang="scss" scoped>
  .table-area {
    height: 50vh;
  }
</style>

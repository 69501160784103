<template>
  <Lottie
    :options="defaultOptions"
    @anim-created="handleAnimation"
  />
</template>

<script>
import mailSendAsPaperPlane from '@/assets/lottie/mail-send-as-paper-plane.json'

export default {
  emits: [
    'complete',
  ],
  data () {
    return {
      defaultOptions: {
        animationData: mailSendAsPaperPlane,
        autoPlay: true,
        loop: false,
      },
      animationSpeed: 5,
    }
  },
  methods: {
    handleAnimation (anim) {
      anim.setSpeed(1.8)
      setTimeout(() => {
        this.$emit('complete')
      }, 4500)
    },
  },
}
</script>

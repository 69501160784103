<template>
  <BListItem>
    <template #header>
      <div>{{ label }}</div>
    </template>
    <div
      v-if="isSelected"
      class="selected-content"
      @click="reset"
    >
      {{ currentSelect.name }}
    </div>
    <BInput
      v-else
      v-model="searchWord"
      :disabled="disabled"
      @keypress-enter="searchBySearchWord"
      @blur.self="resetItems"
    >
      <template #prefix>
        <BElementIcon
          :name="icon"
          type="gray"
        />
      </template>
    </BInput>
    <div
      v-loading="isLoading"
      class="results"
    >
      <div
        v-for="(result, index) in convertSearchedItems"
        :key="index"
        class="result-content"
        @mousedown="() => setId(result)"
      >
        <div class="result-item">
          <span>{{ result.name }}</span>
        </div>
      </div>
    </div>
  </BListItem>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    selectedId: {
      type: String,
      required: true,
    },
    search: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:selectedId',
  ],
  data (){
    return {
      searchWord: '',
      searchedItems: [],
      currentSelect: {},
      isLoading: false,
      icon: 'Search',
    }
  },
  computed: {
    convertSearchedItems () {
      return this.searchedItems.map(sfData => {
        return { name: sfData.name, id: sfData.id }
      })
    },
    isSelected () {
      return this.currentSelect.hasOwnProperty('id')
    },
  },
  watch: {
    disabled(newVal) {
      if (newVal == true) this.reset()
    },
  },
  methods: {
    async searchBySearchWord() {
      if (this.searchWord.length == 0) return
      this.startLoading()
      this.searchedItems = []
      this.searchedItems = await this.search(this.searchWord) || []

      setTimeout(() => {
        this.endLoading()
      }, 300)

      if (this.searchedItems.length == 0) this.searchedItems = [{ name: this.$t('search.notFound', { text: this.searchWord }), id: 0 }]
    },
    setId(result){
      if (result.id == 0) return
      this.currentSelect = result
      this.$emit('update:selectedId', this.currentSelect.id)
      this.searchWord = this.currentSelect.name
      this.searchedItems = []
    },
    resetItems (){
      this.searchedItems = []
    },
    reset(){
      this.currentSelect = {}
      this.searchWord = ''
      this.searchedItems = []
      this.$emit('update:selectedId', '')
    },
    startLoading(){
      this.isLoading = true
      this.icon = 'Loading'
    },
    endLoading() {
      this.isLoading = false
      this.icon = 'Search'
    },
  },
}
</script>

<style lang="scss" scoped>
.result-content {
  border-radius: 0px;
  padding-left: 15px;
}

.result-item {
  padding: 10px 0px;
  position: relative;
  border-radius: 3px;
}

.results {
  padding: 0px 3px 0px 3px;
  overflow-y: scroll;
  cursor: pointer;
  max-height: 250px;
  -webkit-box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.selected-content {
  width: 100%;
  padding: 9px 10px;
  border-radius: 5px;

  &:hover {
    border: 1px solid $concrete;
  }
}
</style>

<template>
  <FilterDetailPopover
    :title="title"
    :is-display-only="isDisplayOnly"
    :key-name="keyName"
    @hide="$emit('hide', switchValue)"
    @show="popoverShow"
  >
    <template #reference>
      <BLayout align-center>
        <BListItem>
          <template #header>
            <span>{{ title }}</span>
          </template>
          <div>{{ value }}</div>
        </BListItem>
      </BLayout>
    </template>
    <div>
      <FilterSwitchBtn v-model="switchValue.inc.or" />
    </div>
  </FilterDetailPopover>
</template>

<script>
import lodash from 'lodash'
import FilterSwitchBtn from '@/components/organisms/user/FilterSwitchBtn.vue'
import FilterDetailPopover from '@/components/organisms/user/leadList/filter/FilterDetailPopover.vue'

export default {
  components: {
    FilterDetailPopover,
    FilterSwitchBtn,
  },
  props: {
    leadListFilterParams: {
      type: Object,
      default () {
        return {}
      },
    },
    isDisplayOnly: {
      type: Boolean,
    },
    keyName: {
      type: String,
      require: true,
    },
  },
  emits: [
    'hide',
  ],
  data () {
    return {
      switchValue: { inc: { or: null } },
    }
  },
  computed: {
    title () {
      return this.$t(`filterSelect.callTargetInfo.item.${this.keyName}`)
    },
    value () {
      const target = this.leadListFilterParams.targetItemsEmail
      if (target == null || target.inc.or == null) return '-'

      let string = ''
      if (target.inc.or === 'true' || target.inc.or === true) {
        string = 'あり'
      } else if (target.inc.or === 'false' || target.inc.or === false) {
        string = 'なし'
      }
      return string
    },
  },
  methods: {
    popoverShow () {
      this.switchValue = this.leadListFilterParams.targetItemsEmail.inc.or === null ? { inc: { or: true } } : lodash.cloneDeep(this.leadListFilterParams.targetItemsEmail)
    },
  },
}
</script>

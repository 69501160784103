import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';
import { TriggerEventBody } from '@/api/openapi';
import ConfirmDrawer from '@/components/organisms/user/trigger/triggerEventForm/ConfirmDrawer.vue';
import FormDrawer from '@/components/organisms/user/trigger/triggerEventForm/FormDrawer.vue';
import { TTriggerEventFormMode } from '../types';

type TProps = {
  editable: boolean;
};
type TEmits = {
  'click:cancel': [];
  'click:triggerEvent': [triggerEvent: TriggerEventBody, menu: TTriggerEventFormMode];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'TriggerEventForm',
  props: /*@__PURE__*/_mergeModels({
    editable: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
    "isOpen": { type: Boolean, ...{ default: false } },
    "isOpenModifiers": {},
    "mode": {},
    "modeModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["click:cancel", "click:triggerEvent"], ["update:modelValue", "update:isOpen", "update:mode"]),
  setup(__props: any, { emit: __emit }) {


const emit = __emit;
const triggerEvent = _useModel<TriggerEventBody | null>(__props, "modelValue");
const isOpen = _useModel<boolean>(__props, 'isOpen');
const mode = _useModel<TTriggerEventFormMode>(__props, 'mode');

const isOpenForm = computed(() => {
  return mode.value === 'form' && isOpen.value;
});

const isOpenConfirm = computed(() => {
  return mode.value === 'confirm' && isOpen.value;
});

const handleCancelClick = () => {
  emit('click:cancel');
};

const handleEditClick = () => {
  emit('click:triggerEvent', triggerEvent.value, 'form');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(FormDrawer, {
      modelValue: triggerEvent.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((triggerEvent).value = $event)),
      "is-open": isOpenForm.value,
      "onUpdate:isOpen": _cache[1] || (_cache[1] = ($event: any) => ((isOpenForm).value = $event)),
      "onClick:cancel": handleCancelClick
    }, null, 8, ["modelValue", "is-open"]),
    _createVNode(ConfirmDrawer, {
      modelValue: triggerEvent.value,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((triggerEvent).value = $event)),
      "is-open": isOpenConfirm.value,
      "onUpdate:isOpen": _cache[3] || (_cache[3] = ($event: any) => ((isOpenConfirm).value = $event)),
      editable: _ctx.editable,
      "onClick:cancel": handleCancelClick,
      "onClick:triggerEvent": handleEditClick
    }, null, 8, ["modelValue", "is-open", "editable"])
  ]))
}
}

})
<template>
  <div
    class="mail-body-editor-conteiner"
  >
    <QuillContentEditor
      class="quill-content-editor"
      :content="internalContent"
      :enable-attachment="showAttachmentIcon"
      @edit="editContent"
      @set-quill="quillInstance = $event"
      @click-attach-file="emit('click-attach-file')"
    />
    <PlaceholderMenu
      v-if="enablePlaceholder"
      v-model="isPlaceholderMenuOpen"
      class="insert-place-holder-menu"
      :content="internalContent"
      :is-mail="isMail"
      :unsubscribe-visible="unsubscribeVisible"
      @insert-item="insertPlaceholder"
    />
  </div>
</template>

<script lang="ts" setup>
import Quill from 'quill';
import { ref, computed } from 'vue';
import PlaceholderMenu from '@/components/organisms/user/general/PlaceholderMenu.vue';
import QuillContentEditor from '@/components/organisms/user/mail/common/drawer/state/form/QuillContentEditor.vue';

type TProps = {
  content: string;
  showAttachmentIcon?: boolean;
  isMail?: boolean;
  unsubscribeVisible?: boolean;
  enablePlaceholder?: boolean;
};
type TEmit = {
  (e: 'update:content', content: string): void;
  (e: 'click-attach-file'): void;
};

const props = withDefaults(defineProps<TProps>(), {
  showAttachmentIcon: false,
  isMail: true,
  unsubscribeVisible: false,
  enablePlaceholder: true,
});
const emit = defineEmits<TEmit>();

const internalContent = computed({
  get () {
    return props.content
  },
  set (newValue) {
    emit('update:content', newValue)
  },
});

const isPlaceholderMenuOpen = ref(false);

const quillInstance = ref<Quill>(null);

const editContent = (content: string) => {
  internalContent.value = content
  isPlaceholderMenuOpen.value = false
};

const insertPlaceholder = (placeHolder: string) => {
  // https://quilljs.com/docs/api/#getselection
  const range = quillInstance.value.getSelection()
  if (range) {
    // https://quilljs.com/docs/api/#inserttext
    quillInstance.value.insertText(range.index, placeHolder)
  } else {
    // User cursor is not in editor
    internalContent.value += placeHolder
  }
  isPlaceholderMenuOpen.value = false
};
</script>

<style lang="scss" scoped>
.mail-body-editor-conteiner {
  position: relative;

  .insert-place-holder-menu {
    position: absolute;
    top: 0;
    right: 0;
  }

  .quill-content-editor {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;


    :deep(.ql-container) {
      height: 430px;
      border: none;
    }
    :deep(.ql-toolbar) {
      border: none;
      border-bottom: 1px solid $concrete;
    }
  }
}
</style>

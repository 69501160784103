import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "notice success" }
const _hoisted_2 = { class: "notice error" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "pl-600 pb-200 target-object-name" }
const _hoisted_5 = { class: "modal-tab-box" }
const _hoisted_6 = { class: "tab-header" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 0,
  class: "column-content non-border select-column"
}
const _hoisted_10 = { class: "icon" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "column-content non-border bc-label" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 2 }
const _hoisted_16 = { key: 3 }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "footer" }

import { onMounted, ref } from 'vue';
import { SfObjectMappingService } from '@/api/user/resources/salesforce/sf_object_mapping';
import { i18nGlobal as i18n } from '@/i18n';

type TProps = {
  selectedObject?: string;
};
type TItem = {
  label: string;
  value: {
    bcKey: string;
    sfKey: string;
    dataType: string;
  };
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MappingColumn',
  props: {
    selectedObject: { default: '' }
  },
  emits: ['back'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const api = new SfObjectMappingService();
const mapping = ref({});
const sfFields = ref({});
const sfFieldsMap = ref({});
const sfMapInfo = ref({});
const sfColumnDataTypes = ref({});
const customFieldsDataTypes = ref({});
const sharedObjectMapping = ref({});
const innerColumnList = ref({});
const customFieldsMap = ref({});
const customActionsMap = ref({});
const useChangeImport = ref(false);
const currentTab = ref('');
const salesforceSettingLoading = ref(true);
const success = ref('')
const error = ref('')
// v-forで選択したときに、同一のkeyの場合に書き換わらないケースががあるため
const changeBoxCounter = ref(0)

const backAction = () => {
  emit('back');
}

const reset = (key: string) => {
  mapping.value[key].sf_column = ''
  // 変更が反映されないことがあるため、v-forのkeyを更新
  changeBoxCounter.value++
}

const changed = (item: TItem['value'] | undefined) => {
  // clearのときもchangedが動くのでitemが空になったときは返す
  if (item == null) {
    return
  }
  success.value = ''
  error.value = ''
  // selectItemsのvalueが帰ってくる
  mapping.value[item.bcKey].sf_column = item.sfKey
  // 実装上の都合で選択されたものの値がsfFieldsMapと異なるフォーマットで更新されてしまうので、
  // データ更新後にリストを初期化しなおす
  loadSfFieldsMap()
  // 変更が反映されないことがあるため、v-forのkeyを更新
  changeBoxCounter.value++
}

const selectItems = (bcKey): TItem[] => {
  const sfKeys = Object.values(mapping.value).map((v) => {
    return v.sf_column
  })
  let mapDataType = sfColumnDataTypes.value[bcKey] ?? 'string'
  if (bcKey.indexOf('custom') === 0) {
    mapDataType = customFieldsDataTypes.value[mapping.value[bcKey].data_type] ?? 'string'
    if (mapDataType == 'picklist') {
      mapDataType = 'string'
    }
  }
  const selectFilterItems = sfMapInfo.value[mapDataType]
  if (selectFilterItems == null) {
    return []
  }
  const sfMap = []
  for (const sfKey of Object.keys(selectFilterItems)) {
    // {'Company': {'label': '会社名', 'data_type': 'string'}}を{label: '会社名', options: 'Company', data_type: 'string'}のように変換
    if (sfKey !== 'Id'
      && (!sfKeys.includes(sfKey) || mapping.value[bcKey].sf_column === sfKey)
    ) {
      sfMap.push({ label: selectFilterItems[sfKey].sf_label, value: { bcKey, sfKey, dataType: selectFilterItems[sfKey].data_type } })
    }
  }
  return sfMap
}

const loadSfFieldsMap = () => {
  const sfMap = []
  for (const sfKey of Object.keys(sfFields.value)) {
    if (sfKey !== 'Id') {
      sfMap[sfKey] = sfFields.value[sfKey].label
    }
  }
  sfFieldsMap.value = sfMap
}

const upsertMapping = async () => {
  salesforceSettingLoading.value = true;
  api.upsertSfObjectMappingBody(
    {
      upsertSfObjectMappingBodyRequest: {
        targetObject: props.selectedObject,
        mapping: JSON.stringify(mapping.value),
      },
    },
  ).then(() => {
    success.value = i18n.t('general.success.to', { action: i18n.t('general.update') })
  }).catch(() => {
    error.value = i18n.t('general.fail.to', { action: i18n.t('general.update') })
  })
  salesforceSettingLoading.value = false;
}

const changeSyncMode = (target, bcKey) => {
  mapping.value[bcKey][target] = !mapping.value[bcKey][target]

  // importがfalseの場合はpriorityの設定を見直す
  if (
    target === 'bc_import'
    && mapping.value[bcKey][target] == false
  ) {
    mapping.value[bcKey].bc_priority = false
  } else if (
    target === 'bc_import'
    && mapping.value[bcKey][target] == true
    && mapping.value[bcKey].sf_import == false
  ) {
    mapping.value[bcKey].bc_priority = true
  } else if (
    target === 'sf_import'
    && mapping.value[bcKey].sf_import == false
    && mapping.value[bcKey].bc_import == true
  ) {
    mapping.value[bcKey].bc_priority = true
  }
}

const changePriority = (targetImport, bcKey) => {
  // 対象のimportがtrueじゃなければ変えない
  if (!mapping.value[bcKey][targetImport]) return

  if (targetImport === 'bc_import') {
    mapping.value[bcKey].bc_priority = true
  } else if (targetImport === 'sf_import') {
    mapping.value[bcKey].bc_priority = false
  }
}

const isCurrentTab = (tab) => {
  return currentTab.value == tab;
}

const setSelectTab = (tab, objectName) => {
  currentTab.value = tab;
  changeCurrentTab(objectName);
}

const changeCurrentTab = (objectName) => {
  Object.keys(mapping.value).forEach((key) => {
    mapping.value[key].bc_object = objectName;
  });
}

onMounted(async () => {
  const result = await api.getSfObjectMappings({ targetObject: props.selectedObject });
  mapping.value = result.data.sfObjectMappings.objectMappings;
  sfFields.value = result.data.sfObjectMappings.objectFields;
  sfMapInfo.value = result.data.sfObjectMappings.sfMapInfo;
  sfColumnDataTypes.value = result.data.sfObjectMappings.sfColumnDataTypes;
  customFieldsDataTypes.value = result.data.sfObjectMappings.customFieldsDataTypes;
  sharedObjectMapping.value = result.data.sfObjectMappings.sharedObjectMapping;
  innerColumnList.value = result.data.sfObjectMappings.innerColumnList;
  customFieldsMap.value = result.data.sfObjectMappings.customFieldsMap ?? {};
  customActionsMap.value = result.data.sfObjectMappings.customActionsMap ?? {};
  useChangeImport.value = innerColumnList.value == null;
  if (innerColumnList.value != null) {
    currentTab.value = Object.keys(innerColumnList.value)[0];
    changeCurrentTab(innerColumnList.value[currentTab.value]);
  }
  loadSfFieldsMap()
  salesforceSettingLoading.value = false;
})

return (_ctx: any,_cache: any) => {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BSelect = _resolveComponent("BSelect")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", null, _toDisplayString(success.value), 1),
      _createVNode(_component_BBtn, {
        text: "",
        position: "right",
        class: "close"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BIcon, {
            class: "px-200",
            size: "small",
            type: "white",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (success.value = ''))
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" close ")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ], 512), [
      [_vShow, success.value != '']
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(error.value), 1),
      _createVNode(_component_BBtn, {
        text: "",
        position: "right",
        class: "close"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BIcon, {
            class: "px-200",
            size: "small",
            type: "white",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (error.value = ''))
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" close ")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ], 512), [
      [_vShow, error.value != '']
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t(`sfObjectMappings.${_ctx.selectedObject}`)), 1),
      _withDirectives(_createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(innerColumnList.value, (objectName, currentTab) => {
            return (_openBlock(), _createElementBlock("div", {
              key: currentTab,
              class: _normalizeClass(["tab-item", { active: isCurrentTab(currentTab) }]),
              onClick: () => setSelectTab(currentTab, objectName)
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t(`import.salesforce.mappings.${currentTab}`)), 1)
            ], 10, _hoisted_7))
          }), 128))
        ])
      ], 512), [
        [_vShow, innerColumnList.value]
      ]),
      _createVNode(_component_BLayout, {
        class: "pl-600 pb-200 header",
        "justify-center": "",
        "align-center": ""
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_BListItem, { class: "column-content non-border radio-priority-area" }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createElementVNode("span", null, "SF優先", -1)
            ])),
            _: 1
          }, 512), [
            [_vShow, useChangeImport.value]
          ]),
          _createVNode(_component_BListItem, { class: "column-content non-border" }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("span", null, "Salesforce項目", -1)
            ])),
            _: 1
          }),
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "icon" }, null, -1)),
          _withDirectives(_createVNode(_component_BListItem, { class: "column-content non-border radio-priority-area" }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createElementVNode("span", null, "BC優先", -1)
            ])),
            _: 1
          }, 512), [
            [_vShow, useChangeImport.value]
          ]),
          _createVNode(_component_BListItem, { class: "column-content non-border" }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createElementVNode("span", null, "BALES CLOUD項目", -1)
            ])),
            _: 1
          }),
          _withDirectives(_createVNode(_component_BListItem, { class: "column-content non-border check-area" }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createElementVNode("span", null, "SF→BC", -1)
            ])),
            _: 1
          }, 512), [
            [_vShow, useChangeImport.value]
          ]),
          _withDirectives(_createVNode(_component_BListItem, { class: "column-content non-border check-area" }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createElementVNode("span", null, "SF←BC", -1)
            ])),
            _: 1
          }, 512), [
            [_vShow, useChangeImport.value]
          ])
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mapping.value, (value, bcKey) => {
        return (_openBlock(), _createBlock(_component_BLayout, {
          key: `${bcKey}-${changeBoxCounter.value}`,
          class: "pl-600 pb-200",
          "justify-center": "",
          "align-center": ""
        }, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_BListItem, { class: "radio-priority-area" }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("div", {
                  class: _normalizeClass(`column-content non-border radio-box
              radio-box-${value['bc_priority'] != true && value['sf_import'] ? 'active' : 'non-active'}`),
                  onClick: () => changePriority('sf_import', bcKey)
                }, [
                  _createVNode(_component_BIcon, {
                    class: "radio",
                    size: "b-icon-small"
                  }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode(" done ")
                    ])),
                    _: 1
                  })
                ], 10, _hoisted_8), [
                  [_vShow, sharedObjectMapping.value[bcKey] == null]
                ])
              ]),
              _: 2
            }, 1536), [
              [_vShow, useChangeImport.value]
            ]),
            _createVNode(_component_BListItem, null, {
              default: _withCtx(() => [
                (value['lock'])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(sfFieldsMap.value[value['sf_column']]), 1))
                  : (_openBlock(), _createBlock(_component_BSelect, {
                      key: 1,
                      modelValue: sfFieldsMap.value[value['sf_column']],
                      "onUpdate:modelValue": ($event: any) => ((sfFieldsMap.value[value['sf_column']]) = $event),
                      filterable: "",
                      clearable: "",
                      class: "select-column",
                      items: selectItems(bcKey),
                      "item-text": "label",
                      "item-value": "value",
                      fit: "",
                      disabled: sharedObjectMapping.value[bcKey] != null,
                      placeholder: sharedObjectMapping.value[bcKey] == null ? _ctx.$t('general.select.please') : _ctx.$t('sfObjectMappings.alreadyUsed'),
                      onClear: () => reset(bcKey),
                      onChange: changed
                    }, {
                      optionTemplate: _withCtx(({ item }) => [
                        _createElementVNode("span", {
                          class: _normalizeClass(["b-select", {'b-selected': item['value']['sfKey'] && value['sf_column'] == item['value']['sfKey']}])
                        }, _toDisplayString(item['label']), 3)
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "onUpdate:modelValue", "items", "disabled", "placeholder", "onClear"]))
              ]),
              _: 2
            }, 1024),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_BIcon, {
                class: "icon-sync",
                size: "b-icon-medium"
              }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createTextVNode(" swap_horiz ")
                ])),
                _: 1
              })
            ]),
            _withDirectives(_createVNode(_component_BListItem, { class: "radio-priority-area" }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("div", {
                  class: _normalizeClass(`column-content non-border radio-box
              radio-box-${value['bc_priority'] ? 'active' : 'non-active'}`),
                  onClick: () => changePriority('bc_import', bcKey)
                }, [
                  _createVNode(_component_BIcon, {
                    class: "radio",
                    size: "b-icon-small"
                  }, {
                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createTextVNode(" done ")
                    ])),
                    _: 1
                  })
                ], 10, _hoisted_11), [
                  [_vShow, !('sf_import_only' in value && value['sf_import_only'] == true) && sharedObjectMapping.value[bcKey] == null]
                ])
              ]),
              _: 2
            }, 1536), [
              [_vShow, useChangeImport.value]
            ]),
            _createVNode(_component_BListItem, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_12, [
                  (customFieldsMap.value[bcKey] != null)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(customFieldsMap.value[bcKey]['name']), 1))
                    : (customActionsMap.value[bcKey] != null)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(customActionsMap.value[bcKey]['name']), 1))
                      : (value['label'] != null)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(value['label']), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.$t(`import.salesforce.mappings.${bcKey}`)), 1))
                ])
              ]),
              _: 2
            }, 1024),
            _withDirectives(_createVNode(_component_BListItem, { class: "check-area" }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("div", {
                  class: _normalizeClass(`column-content non-border check-box
              check-box-${value['sf_import'] ? 'active' : 'non-active'}`),
                  onClick: () => changeSyncMode('sf_import', bcKey)
                }, [
                  _createVNode(_component_BIcon, {
                    class: "check",
                    size: "b-icon-small"
                  }, {
                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createTextVNode(" done ")
                    ])),
                    _: 1
                  })
                ], 10, _hoisted_17), [
                  [_vShow, sharedObjectMapping.value[bcKey] == null]
                ])
              ]),
              _: 2
            }, 1536), [
              [_vShow, useChangeImport.value]
            ]),
            _withDirectives(_createVNode(_component_BListItem, { class: "check-area" }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("div", {
                  class: _normalizeClass(`column-content non-border check-box
              check-box-${value['bc_import'] ? 'active' : 'non-active'}`),
                  onClick: () => changeSyncMode('bc_import', bcKey)
                }, [
                  _createVNode(_component_BIcon, {
                    class: "check",
                    size: "b-icon-small"
                  }, {
                    default: _withCtx(() => _cache[15] || (_cache[15] = [
                      _createTextVNode(" done ")
                    ])),
                    _: 1
                  })
                ], 10, _hoisted_18), [
                  [_vShow, !('sf_import_only' in value && value['sf_import_only'] == true) && sharedObjectMapping.value[bcKey] == null]
                ])
              ]),
              _: 2
            }, 1536), [
              [_vShow, useChangeImport.value]
            ])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ])), [
      [_directive_loading, salesforceSettingLoading.value]
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", {
        class: "btn-back",
        onClick: backAction
      }, _toDisplayString(_ctx.$t("sfObjectMappings.back")), 1),
      _createElementVNode("div", {
        class: "btn-save",
        onClick: upsertMapping
      }, _toDisplayString(_ctx.$t("sfObjectMappings.save")), 1)
    ])
  ]))
}
}

})
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "mt-100" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  class: "token-expire"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BContentBox = _resolveComponent("BContentBox")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_BContentBox, null, {
    content: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_BLayout, {
          "justify-center": "",
          column: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BListItem, {
              class: "connect-explain",
              innerHTML: _ctx.$t('corpSettingApi.zoom.explain')
            }, null, 8, ["innerHTML"]),
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('corpSettingApi.zoom.connectStatus')) + "：", 1),
              (!_ctx.isConnect)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('corpSettingApi.zoom.status.unconnect')), 1))
                : _createCommentVNode("", true),
              (_ctx.isConnect)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('corpSettingApi.zoom.status.connect')), 1))
                : _createCommentVNode("", true),
              (_ctx.isExpired)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('corpSettingApi.zoom.status.expired')), 1))
                : _createCommentVNode("", true)
            ]),
            (_ctx.isConnect)
              ? (_openBlock(), _createBlock(_component_BListItem, {
                  key: 0,
                  class: "mt-50"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('corpSettingApi.zoom.connectUserName')) + "：", 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.zoomSetting.connectUserName), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_BListItem, { class: "mt-300" }, {
              default: _withCtx(() => [
                (_ctx.isConnect)
                  ? (_openBlock(), _createBlock(_component_BBtn, {
                      key: 0,
                      type: "primary",
                      disabled: _ctx.settingLoading || _ctx.deleting || !_ctx.$can('owner'),
                      onClick: _ctx.unconnect
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('corpSettingApi.zoom.btnDisconnect')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"]))
                  : (_openBlock(), _createBlock(_component_BBtn, {
                      key: 1,
                      type: "primary",
                      disabled: _ctx.settingLoading || !_ctx.$can('owner'),
                      onClick: _ctx.connect
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('corpSettingApi.zoom.btnText')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"]))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])), [
        [_directive_loading, _ctx.settingLoading]
      ])
    ]),
    _: 1
  }))
}
export default class SfBaseActions {
  constructor() { }
  SalesForceBaseAction(actionFn) {
    return function (context, payload) {
      if (context.getters.salesforceSetting == undefined || !context.getters.availableFeatures.feature?.salesforce_link) return;

      return actionFn.call(this, context, payload).then((result) => {
        return result
      }).catch((err) => {
        if (err.response?.status == 401) {
          context.dispatch('user/getSalesforceSettingAction', payload, { root: true })
        }
        throw err
      })
    }
  }
}

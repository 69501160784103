<template>
  <div
    class="mail-list"
    :style="listStyle"
  >
    <div class="theader">
      <div class="table-row theader-row">
        <BSortText
          v-for="(cell, index) in headerList"
          :key="cell.key"
          class="table-cell header-cell"
          :style="getHeaderStyles(columnWidth[index], cell.key)"
          :item-text="cell.name"
          is-display-only
        />
      </div>
    </div>
    <div class="tbody">
      <div
        v-for="mail in bulkMails"
        :key="mail.id"
        class="table-row tbody-row"
        @click="() => $emit('click', mail)"
      >
        <div
          v-for="(column, index) in displayColumnNames"
          :key="column"
          class="table-cell"
          :style="getBodyStyles({ width: columnWidth[index], index: index })"
          :class="getBodyClasses({ status: mail.status, bold: shouldBold(column) })"
          @click="e => moveFilteredLeadList(e, mail, column)"
        >
          <template v-if="column === 'campaignName'">
            <div class="mb-50 fw-bold campaign-head-area">
              <div class="campaign-text-area">
                {{ getPropertyFallBackAltText(mail, 'campaignName') }}
              </div>
              <div class="campain-option-area">
                <BTooltip
                  top
                  :content="$t('general.duplicate.do')"
                >
                  <BCustomIcon
                    size="small"
                    icon-class="b-clone"
                    hover
                    @click.stop="() => $emit('duplicate', mail)"
                  />
                </BTooltip>
              </div>
            </div>
            <div class="status">
              <div
                class="badge"
                :class="{ [mail.status]: true }"
              />
              <span>{{ $t(`mail.status.${mail.status}`) }}</span>
            </div>
          </template>
          <template v-else-if="column === 'leadViewName'">
            {{ getPropertyFallBackAltText(mail, 'leadViewName') }}
          </template>
          <template v-else-if="column === 'sendAt'">
            {{ getSendAtWithDateAndTimeFilter(mail) }}
          </template>
          <template v-else-if="column === 'sentAt'">
            {{ getSentAtWithDateAndTimeFilter(mail) }}
          </template>
          <template v-else-if="column === 'sentUser'">
            <BUserTooltip
              v-if="mail.sentUser"
              :image-url="mail.sentUser.avatarUrl"
              :name="mail.sentUser.name"
              :user-id="mail.sentUser.id"
              :size="40"
            /><span v-else>-</span>
          </template>
          <template v-else-if="column === 'createdUser'">
            <BUserTooltip
              v-if="mail.createdUser"
              :image-url="mail.createdUser.avatarUrl"
              :name="mail.createdUser.name"
              :user-id="mail.createdUser.id"
              :size="40"
            /><span v-else>-</span>
          </template>
          <template v-else-if="isAvailableSalesforceApiFeature && column === 'isSfSync'">
            <BCheckbox
              :model-value="mail.isSfSync"
              class="sfSync"
              readonly
              center
            />
          </template>
          <template v-else>
            <span>{{ editToDisplayColumn(mail, column, shouldPercentage(column)) }}</span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait'
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import MAIL_STATUSES from '@/const/mail_statuses'
import filterService from '@/mixins/filter_service'
import BCheckbox from '@/plugins/biscuet-materials/components/atoms/BCheckbox.vue';
import { altText } from '@/utils/alt'
import { formatShorterDateTime } from '@/utils/date-time'
  
export default {
  components: { BCheckbox },
  mixins: [filterService],
  props: {
    bulkMails: {
      type: Array,
      default() {
        return []
      },
    },
  },
  emits: [
    'click',
    'duplicate',
  ],
  setup() {
    const { isAvailableSalesforceApiFeature } = useAvailableFeatureCheck()
    return {
      isAvailableSalesforceApiFeature,
    }
  },
  data() {
    return {
      columnWidth: [240, 240, 120, 120, 80, 80, 90, 100, 90, 100, 90, 130, 105, 130, 100, 120],
      columnNames: [
        'campaignName',
        'leadViewName',
        'sendAt',
        'sentAt',
        'sentUser',
        'createdUser',
        'sentCount',
        'deliverSentPercentage',
        'deliverCount',
        'openDeliverPercentage',
        'openCount',
        'clickOpenPercentage',
        'clickCount',
        'clickSentPercentage',
        'unsubscribedCount',
      ],
      sortKey: 'id',
      sortOrder: 'desc',
    }
  },
  computed: {
    displayColumnNames() {
      if (this.isAvailableSalesforceApiFeature) {
        return this.columnNames.concat(['isSfSync'])
      }

      return this.columnNames
    },
    headerList() {
      return this.displayColumnNames.map((column) => {
        return {
          key: column,
          name: this.$t(`mail.${column}`),
        }
      })
    },
    listStyle() {
      const totalWidth = this.columnWidth.reduce((accumulator, currentValue) => accumulator + currentValue)
      return `width: ${totalWidth}px; min-width: 100%; max-width: ${totalWidth}px;`
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      getCallTargetsAction: 'getCallTargetsActionWait',
    }),
    ...mapWaitingActions('userUi', {
      setLeadListFilterParamsAction: 'setLeadListFilterParamsWait',
      setLeadListPageParamsAction: 'setLeadListPageParamsWait',
    }),
    getHeaderStyles(width, key) {
      let style = `width: ${width}px; min-width: ${width}px; max-width: ${width}px;`
      if (key === 'campaignName') style += 'position:sticky;left:0;z-index:3;'
      if (key === this.sortKey) style += 'font-weight:bold;'
      return style
    },
    getBodyStyles({ width, index }) {
      let style = `width: ${width}px; min-width: ${width}px; max-width: ${width}px;`
      if (index === 0) style += 'position:sticky;left:0;z-index:1;'
      return style
    },
    shouldBold(property) {
      return ['sentCount', 'deliverCount', 'openCount', 'clickCount'].includes(property)
    },
    shouldPercentage(property) {
      return [
        'deliverSentPercentage',
        'openDeliverPercentage',
        'clickOpenPercentage',
        'clickSentPercentage',
      ].includes(property)
    },
    shouldHyphen(status) {
      return status === MAIL_STATUSES.DRAFT
               || status === MAIL_STATUSES.SENDING
               || status === MAIL_STATUSES.SENDING_FAILED
               || status === MAIL_STATUSES.SEND_REQUEST
               || status === MAIL_STATUSES.PENDING
               // sending_failed は モデルの enum なため、camelize されていない
    },
    editToDisplayColumn(bulkMail, property, isPercentage) {
      if (this.shouldHyphen(bulkMail.status)) {
        return '-'
      }
      if (isPercentage) {
        return `${bulkMail[property]}%`
      }
      return bulkMail[property].toLocaleString()
    },
    getBodyClasses({ status, bold }) {
      const classes = []
      if (bold && !this.shouldHyphen(status)) classes.push('count-text')
      return classes
    },
    getPropertyFallBackAltText(obj, property) {
      return altText(obj?.[property])
    },
    getSendAtWithDateAndTimeFilter(mail) {
      return altText(formatShorterDateTime(mail?.sendAt))
    },
    getSentAtWithDateAndTimeFilter(mail) {
      return altText(formatShorterDateTime(mail?.sentAt))
    },
    moveFilteredLeadList(e, mail, column) {
      if (this.shouldBold(column)) {
        e.stopPropagation()
        this.setQueryAndMovePage(mail.id, this.extractEventName(column))
      }
    },
    extractEventName(event) {
      if (event === 'sentCount') return ''
      return event === 'deliverCount' ? 'delivered' : event.replace('Count', '')
    },
    modifyEvent(event){
      return event === 'sentCount' ? '' : event
    },
    setQueryAndMovePage(bulkMailId, event) {
      event = this.modifyEvent(event)
      const eventName = this.extractEventName(event)
      const filterParams = {
        bulkMails: { inc: { or: [bulkMailId] } },
        bulkMailEvents: { inc: { or: eventName } },
      }
      this.setFilterAndMoveLeadList(filterParams)
    },
    isShowSfcolumn(column) {
      return this.isAvailableSalesforceApiFeature && column === 'isSfSync' || column !== 'isSfSync'
    },
  },
}
</script>

<style lang="scss" scoped>
  .table-row {
    display: flex;
    align-items: center;
    padding-right: $basespace-400;
  }

  .table-cell {
    padding-right: $basespace-200;
    padding-left: 10px;
    min-width: 80px;
    background-color: white;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &:first-child {
      padding-left: 30px;
    }
    &:not(:nth-child(-n + 3)) {
      // キャンペーン名, 送信先リスト, 送信日時は中央寄せしない
      text-align: center;
    }
    .sfSync {
      display: inline-block;
    }
  }

  .theader {
    height: 50px;
    position: sticky;
    top: 0;
    z-index: 2;
    cursor: text;
    &-row {
      height: inherit;
      background-color: white;
      border-bottom: 1px solid $bdcolor-base;
      padding-bottom: $basespace-100;
      color: $textcolor-light;
      font-size: $fontsize-100;
    }
  }

  .tbody {
    &-row {
      border-bottom: 1px solid $bdcolor-base;
      padding-top: $basespace-200;
      padding-bottom: $basespace-200;

      cursor: pointer;
      &:hover {
        background-color: $bgcolor-base;
      }
    }
  }

  .tbody-row:hover .table-cell {
    background-color: $bgcolor-base;
  }

  .status {
    display: flex;
    align-items: center;
    font-size: $fontsize-100;
    color: $textcolor-light;

    .badge {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 4px;

      &.draft, &.pending, &.send_request, &.sending, &.ready {
        background: $basecolor-warning;
      }

      &.sending_failed {
        background: $basecolor-error;
      }

      &.sent {
        background: $basecolor-success;
      }
    }
  }

  .campaign-head-area {
    display: flex;
    justify-content: space-between;
  }

  .campaign-text-area {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .count-text {
    font-weight: bold;
    &:hover {
      span {
        color: $basecolor-primary;
        border-bottom: 1px solid $basecolor-primary;
      }
    }
  }

  .header-cell:hover {
    font-weight: bold;
  }
</style>

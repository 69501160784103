<template>
  <MailDrawerTemplate
    :title="$t('mail.mailCreate')"
    :loading="$wait.is(['getBulkMailWait', 'deleteBulkMailWait'])"
    :can-delete="isSavedDraft"
    :can-duplicate="canDuplicate"
    @duplicate="$emit('duplicate')"
    @click-delete="deleteDraft"
  >
    <template #drawerHeaderAction>
      <BTooltip
        top
        :content="$t('mail.confirmInfo')"
      >
        <BBtn
          size="small"
          type="primary"
          outline
          :loading="isLoading"
          :disabled="!allValid"
          @click="toConfirm"
        >
          <span>{{ $t('mail.confirmContent') }}</span>
          <BIcon>keyboard_arrow_right</BIcon>
        </BBtn>
      </BTooltip>
    </template>
    <template #drawerBody>
      <div class="campaign">
        <BInput
          v-model="internalBulkMail.campaignName"
          v-model:valid="campaignValid"
          :placeholder="$t('mail.campaignPlaceholder')"
          :rules="[requiredRule]"
          :max-length="255"
        />
      </div>
      <div class="pop-up-box">
        <BBtn
          text
          class="template-button"
          @click="handleTemplateModalShow"
        >
          <BCustomIcon
            icon-class="b-template"
            type="accent"
          />
          <span class="ml-50 fw-bold">{{ $t('mail.applyMailTemplate') }}</span>
        </BBtn>
        <!-- NOTE: 自動生成の動線を一時的に消している -->
        <!--
          <BBtn
          text
          class="openai-button"
          @click="handleOpenaiModalShow"
          >
          <BIcon
          type="success"
          class="openai-icon"
          size="large"
          >
          psychology
          </BIcon>
          <span class="fw-bold">{{ $t('openaiGenarate.mailGenerate') }}</span>
          </BBtn> 
        -->
      </div>
      <MailContentEditor
        v-loading="isWaitOpenaiLoading"
        class="mb-600"
        :subject="internalBulkMail.subject"
        :content="internalBulkMail.content"
        @edit-subject="editBulkMail('subject', $event)"
        @edit-content="editBulkMail('content', $event)"
      />
      <BListItem class="mb-600">
        <template #header>
          <span>{{ $t('mail.to') }}</span>
        </template>
        <BSelect
          v-model="internalBulkMail.leadViewId"
          :items="leadViews"
          item-text="name"
          item-value="id"
          :placeholder="$t('mail.toPlaceholder')"
          filterable
          fit
          underline
        />
      </BListItem>
      <BListItem class="mb-600">
        <template #header>
          <span>{{ $t('mail.sender') }}</span>
        </template>
        <div class="input-group">
          <BInput
            v-model="internalBulkMail.fromName"
            v-model:valid="fromNameValid"
            class="name"
            :placeholder="$t('mail.mailName')"
            :rules="[requiredRule]"
            :max-length="255"
            underline
          />
          <BInput
            v-model="internalBulkMail.fromAddress"
            v-model:valid="fromAddressValid"
            class="address"
            :placeholder="$t('mail.mailAddress')"
            :rules="[requiredRule, emailRule]"
            :max-length="255"
            underline
          />
        </div>
      </BListItem>
      <BListItem class="mb-600">
        <template #header>
          <span>
            <div class="header-group"><span class="text">{{ $t('mail.reply') }}</span>
              <BCheckbox
                v-model="internalIsDisabledReplyForm"
                class="ml-100"
                :label="$t('mail.noSelectReplyTo')"
              />
            </div>
          </span>
        </template>
        <div
          v-if="!internalIsDisabledReplyForm"
          class="input-group"
        >
          <BInput
            v-model="internalBulkMail.replyName"
            v-model:valid="replyNameValid"
            class="name"
            :placeholder="$t('mail.mailName')"
            :rules="[requiredRule]"
            :max-length="255"
            underline
          />
          <BInput
            v-model="internalBulkMail.replyAddress"
            v-model:valid="replyAddressValid"
            class="address"
            :placeholder="$t('mail.mailAddress')"
            :rules="[requiredRule, emailRule]"
            :max-length="255"
            underline
          />
        </div>
      </BListItem>
      <BListItem class="mb-600">
        <template #header>
          <span>
            <div class="header-group"><span class="text">{{ $t('mail.reserve') }}</span>
              <BCheckbox
                v-model="internalIsSendNow"
                class="ml-100"
                :label="$t('mail.sendNow')"
              />
            </div>
          </span>
        </template>
        <div
          v-if="!internalIsSendNow"
          class="datetime-input-group"
        >
          <BInputDateTime
            v-model:date="internalSendAt"
            v-model:time="internalSendAt"
          />
          <p
            v-if="!isValidSendAt"
            class="send-at-error"
          >
            {{ $t('mail.sendAtError') }}
          </p>
        </div>
      </BListItem>
      <BListItem class="mb-600">
        <template #header>
          <span>
            <div class="header-group">
              <span class="text">{{ $t('mailNotificationEvent.formTitle') }}</span>
              <BTooltip top>
                <BIcon size="small">help</BIcon>
                <template #content>
                  <span>{{ $t('mailNotificationEvent.description') }}</span>
                </template>
              </BTooltip>
            </div>
          </span>
        </template>
        <BCheckbox
          v-model="internalBulkMail.open"
          class="ml-100 mt-100"
          :label="$t('mailNotificationEvent.open')"
        />
        <BCheckbox
          v-model="internalBulkMail.click"
          class="ml-100 mt-100"
          :label="$t('mailNotificationEvent.click')"
        />
      </BListItem>
    </template>
    <template #drawerFooter>
      <BLayout
        justify-center
      >
        <BBtn
          class="mr-100"
          flat
          @click="emitCancel"
        >
          {{ $t('general.close.text') }}
        </BBtn>
        <BBtn
          class="ml-100"
          type="primary"
          :loading="isLoading"
          @click="emitSaveDraft"
        >
          {{ $t('mail.saveDraft') }}
        </BBtn>
      </BLayout>
    </template>
  </MailDrawerTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import MailDrawerTemplate from '@/components/organisms/user/mail/common/drawer/MailDrawerTemplate.vue'
import MailContentEditor from '@/components/organisms/user/mail/common/drawer/state/form/MailContentEditor.vue'
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';
import inputValidation from '@/mixins/input_validation'
import { formatDateTime } from '@/utils/date-time';

export default {
  name: 'FormDrawer',
  components: {
    MailDrawerTemplate,
    MailContentEditor,
  },
  mixins: [inputValidation],
  props: {
    bulkMail: {
      type: Object,
      required: false,
      default: () => {},
    },
    isDisabledReplyForm: {
      type: Boolean,
      required: false,
    },
    isSendNow: {
      type: Boolean,
      default: false,
    },
    isValidSendAt: {
      type: Boolean,
    },
    canDuplicate: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'duplicate',
    'edit-form',
    'change-current-state-to-confirm',
    'cancel',
    'save-draft',
    'delete-draft',
  ],
  setup () {
    const { isAvailableMailTemplateFeature } = useAvailableFeatureCheck()
    return {
      isAvailableMailTemplateFeature,
    }
  },
  data () {
    return {
      campaignValid: false,
      fromNameValid: false,
      fromAddressValid: false,
      replyNameValid: false,
      replyAddressValid: false,
      internalBulkMail: this.bulkMail,
    }
  },
  computed: {
    ...mapGetters('user', [
      'leadViews',
    ]),
    internalIsDisabledReplyForm: {
      get () {
        return this.isDisabledReplyForm
      },
      set (v) {
        this.editEmit('isDisabledReplyForm', v)
      },
    },
    internalIsSendNow: {
      get () {
        return this.isSendNow
      },
      set (v) {
        this.editEmit('isSendNow', v)
      },
    },
    internalSendAt: {
      get () {
        return this.internalBulkMail.sendAt
      },
      set (sendAt) {
        this.editBulkMail('sendAt', formatDateTime(sendAt))
      },
    },
    isLoading () {
      return this.$wait.is(['createBulkMailWait', 'updateBulkMailWait', 'mailLeadCountWait'])
    },
    allValid () {
      return this.subjectAndContentValid
        && this.leadViewValid
        && this.campaignValid
        && this.fromNameValid
        && this.fromAddressValid
        && this.replyValid
        && this.isValidSendAt
    },
    selectedLeadView () {
      if (this.internalBulkMail.leadViewId == null || this.leadViews.length === 0) return ''
      return this.leadViews.find((leadView) => leadView.id === this.internalBulkMail.leadViewId)
    },
    subjectAndContentValid () {
      return this.internalBulkMail.subject.length > 0 && this.internalBulkMail.content.length > 0
    },
    replyValid () {
      if (this.isDisabledReplyForm === true) return true
      return this.replyNameValid && this.replyAddressValid
    },
    leadViewValid () {
      return !!this.selectedLeadView
    },
    isSavedDraft () {
      return !!this.internalBulkMail.id && typeof this.internalBulkMail.id === 'number'
    },
    isWaitOpenaiLoading() {
      return this.$wait.is(['generateContentByOpenaiWait'])
    },
  },
  methods: {
    handleOpenaiModalShow() {
      this.$modal.show('openai-modal')
    },
    editBulkMail(key, value) {
      this.internalBulkMail[key] = value;
    },
    editEmit (key, value) {
      const newVal = {
        isDisabledReplyForm: this.isDisabledReplyForm,
        isSendNow: this.isSendNow,
      }
      newVal[key] = value
      this.$emit('edit-form', newVal)
    },
    toConfirm () {
      this.$emit('change-current-state-to-confirm')
    },
    emitCancel () {
      this.$emit('cancel')
    },
    emitSaveDraft () {
      this.$emit('save-draft')
    },
    async deleteDraft () {
      const check = await this.$bitterAlert.show({
        title: this.$t('general.confirm.text'),
        text: this.$t('general.confirm.of', { action: this.$t('general.delete.text') }),
      })
      if (!check) { return }
      this.$emit('delete-draft')
    },
    handleTemplateModalShow() {
      if (!this.isAvailableMailTemplateFeature) {
        this.$bitterAlert.show({
          title: this.$t('general.error'),
          text: this.$t('mail.mailTemplateIsNotAvailable'),
          closeOnClickOutside: true,
          buttonsCancel: false,
        })
        return
      }
      this.$modal.show('MailTemplateSelectModal')
    },
  },
}
</script>

<style lang="scss" scoped>
  .campaign {
    margin-top: -$basespace-400;
    margin-right: -$basespace-400;
    margin-left: -$basespace-400;
    padding: $basespace-200 $basespace-400;
    background-color: $concrete-light;
  }
  .input-group {
    display: flex;
    .name {
      flex: 1;
      margin-right: 10px;
    }
    .address {
      flex: 2;
    }
  }
  .datetime-input-group {
    max-width: 340px;
    .send-at-error {
      display: block;
      color: $mars;
      margin-top: 10px;
      padding: 10px;
      padding-top: 0px;
    }
  }
  .header-group {
    font-size: $fontsize-100;
    color: $concrete-dark;
    margin-bottom: 4px;
    display: flex;
    line-height: 24px;
    .text {
      margin-right: 10px;
    }
  }
  .pop-up-box {
    border-bottom: 1px solid $bdcolor-base;
    margin: 0 -20px 16px -20px;
    padding: 4px 20px;
    .template-button {
      color: $basecolor-accent
    }
    .openai-button {
      color: $basecolor-accent;
      margin-left: 15px;
    }
  }
  .openai-icon {
    transform: scale(-1, 1);
    color: $basecolor-success;
  }
</style>

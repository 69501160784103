import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "setting-title" }
const _hoisted_2 = { class: "explain" }
const _hoisted_3 = { class: "activation-input" }

import { ref, onMounted, computed } from 'vue';
import { MailSignatureBody } from '@/api/openapi';
import { MailSignatureApiService } from '@/api/user/resources/mail_signature';
import MailBodyEditor from '@/components/organisms/user/mail/common/drawer/state/form/MailBodyEditor.vue';
import { useWait } from '@/composable/vue-wait';


export default /*@__PURE__*/_defineComponent({
  __name: 'MailSignature',
  setup(__props) {

const { doActionWithWait, wait } = useWait();

const defaultMailsignature = {
  isActive: false,
  content: '',
}

const mailSignature = ref<MailSignatureBody>(defaultMailsignature);

const fetchData = async () => {
  doActionWithWait('fetchMailSignature', async () => {
    const api = new MailSignatureApiService();
    const { data } = await api.getMailSignatureMe({
      errorHandlers: {
        404: () => {
          mailSignature.value = defaultMailsignature;
        },
      },
    });
    mailSignature.value = data;
  });
};

onMounted(() => {
  fetchData();
});

const save = () => {
  doActionWithWait('saveMailSignature', async () => {
    const api = new MailSignatureApiService();
    await api.putMailSignatureMe({
      request: {
        mailSignatureBody: mailSignature.value,
      },
    });
  });
};

const loading = computed(() => {
  return wait.is('fetchMailSignature');
});

const saving = computed(() => {
  return wait.is('saveMailSignature');
});

return (_ctx: any,_cache: any) => {
  const _component_BCheckbox = _resolveComponent("BCheckbox")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('mailSignature.title')), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('mailSignature.explain')), 1),
    _withDirectives((_openBlock(), _createBlock(_component_BLayout, {
      column: "",
      class: "form"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BListItem, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_BCheckbox, {
                modelValue: mailSignature.value.isActive,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((mailSignature.value.isActive) = $event)),
                label: _ctx.$t('mailSignature.activeationInputLable')
              }, null, 8, ["modelValue", "label"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_BListItem, { class: "mt-200" }, {
          default: _withCtx(() => [
            _createVNode(MailBodyEditor, {
              content: mailSignature.value.content,
              "onUpdate:content": _cache[1] || (_cache[1] = ($event: any) => ((mailSignature.value.content) = $event)),
              "enable-placeholder": false,
              class: "mail-body-editor"
            }, null, 8, ["content"])
          ]),
          _: 1
        }),
        _createVNode(_component_BListItem, { class: "mt-300" }, {
          default: _withCtx(() => [
            _createVNode(_component_BBtn, {
              type: "primary",
              disabled: loading.value,
              loading: saving.value,
              onClick: save
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.save.text')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "loading"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })), [
      [_directive_loading, loading.value || saving.value]
    ])
  ], 64))
}
}

})
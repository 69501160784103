import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "confirm-custom-field-destroy-status" }

import { AxiosResponse } from 'axios';
import { onBeforeMount, onBeforeUnmount, ref } from 'vue';
import { CustomFieldDestroyTask } from '@/api/openapi';
import { CustomFieldDestroyTaskApiService } from '@/api/user/resources/custom_field_destroy_task';
import ImportStatus from '@/components/organisms/user/leadImport/ImportStatus.vue';
import { useWait } from '@/composable/vue-wait';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomFieldDestroyStatus',
  setup(__props) {

const {
  doActionWithWait,
} = useWait();
const api = new CustomFieldDestroyTaskApiService();

const timeObj = ref<NodeJS.Timeout | null>(null);
const customFieldDestroyTasks = ref<CustomFieldDestroyTask[]>([]);

onBeforeMount(() => {
  fetchCustomFieldDestroyTasks();
  timeObj.value = setInterval(() => {
    fetchCustomFieldDestroyTasks();
  }, 30000)
});
onBeforeUnmount(() => {
  clearInterval(timeObj.value);
});

const fetchCustomFieldDestroyTasks = async () => {
  const { data } = await doActionWithWait<AxiosResponse<GetCustomFieldDestroyTasks200Response>>('getCustomFieldDestroyTaskTasksWait', async () => {
    return await api.getCustomFieldDestroyTasks({});
  });

  console.log('customFieldDestroyTasks', data, new Date());
  customFieldDestroyTasks.value = data;
};

const confirmTask = async (customFieldDestroyTaskId: number) => {
  await doActionWithWait('confirmCustomFieldDestroyTaskTaskWait', async () => {
    await api.confirmCustomFieldDestroyTask({ request: { customFieldDestroyTaskId } });
  });
  await fetchCustomFieldDestroyTasks();
};

return (_ctx: any,_cache: any) => {
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(customFieldDestroyTasks.value, (task) => {
      return (_openBlock(), _createBlock(ImportStatus, {
        key: task.id,
        "target-task": task,
        "progress-message": _ctx.$t('customField.destroyTask.status.progress', { customFieldName: task.customFieldName }),
        "error-message": _ctx.$t('customField.destroyTask.status.error', { customFieldName: task.customFieldName }),
        "can-show-error-modal": false,
        "success-message": _ctx.$t('customField.destroyTask.status.success', { customFieldName: task.customFieldName }),
        onConfirm: () => confirmTask(task.id)
      }, null, 8, ["target-task", "progress-message", "error-message", "success-message", "onConfirm"]))
    }), 128))
  ])), [
    [_directive_loading, _ctx.$wait.is('confirmCustomFieldDestroyTaskTaskWait')]
  ])
}
}

})
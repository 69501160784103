import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "lead-merge-status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_LeadLink = _resolveComponent("LeadLink")!
  const _component_ImportStatus = _resolveComponent("ImportStatus")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leadMergeTasks, (task) => {
      return (_openBlock(), _createBlock(_component_ImportStatus, {
        key: task.id,
        "target-task": task,
        "can-show-error-modal": false,
        onConfirm: () => _ctx.confirmTask(task.id),
        onSuccess: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('success')))
      }, {
        progressMessage: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('duplicatedList.status.progressMessage1')) + " ", 1),
          _createVNode(_component_LeadLink, {
            "call-target-id": task.callTargetId,
            tag: "span"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BBtn, {
                type: "warning",
                class: "link-to-call-target b-btn-progress",
                "btn-type": "button",
                outline: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formatCallTargetName(task)), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["call-target-id"]),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('duplicatedList.status.progressMessage2')), 1)
        ]),
        errorMessage: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.makeCreateDateMessage(task)) + " " + _toDisplayString(_ctx.$t('duplicatedList.status.errorMessage1')) + " ", 1),
          _createVNode(_component_LeadLink, {
            "call-target-id": task.callTargetId,
            tag: "span"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BBtn, {
                type: "error",
                class: "link-to-call-target",
                "btn-type": "button",
                outline: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formatCallTargetName(task)), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["call-target-id"]),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('duplicatedList.status.errorMessage2')), 1)
        ]),
        successMessage: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.makeCreateDateMessage(task)) + " " + _toDisplayString(_ctx.$t('duplicatedList.status.successMessage1')) + " ", 1),
          _createVNode(_component_LeadLink, {
            "call-target-id": task.callTargetId,
            tag: "span"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BBtn, {
                type: "success",
                class: "link-to-call-target",
                "btn-type": "button",
                outline: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formatCallTargetName(task)), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["call-target-id"]),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('duplicatedList.status.successMessage2')), 1)
        ]),
        _: 2
      }, 1032, ["target-task", "onConfirm"]))
    }), 128))
  ])), [
    [_directive_loading, _ctx.$wait.is('confirmLeadMergeTasksWait')]
  ])
}
<template>
  <div class="form-drawer">
    <MailDrawerTemplate
      :title="title"
      :width="600"
    >
      <template #drawerHeaderAction>
        <BTooltip
          top
          :content="$t('mail.confirmInfo')"
        >
          <BBtn
            size="small"
            type="primary"
            outline
            :loading="isLoading"
            :disabled="!isValid"
            @click="handleClickConfirm"
          >
            <span>{{ $t('mail.confirmContent') }}</span>
            <BIcon>keyboard_arrow_right</BIcon>
          </BBtn>
        </BTooltip>
      </template>
      <template #drawerBody>
        <FormDrawerBodyCommon
          :model-value="props.modelValue"
          :is-available-mail-template-feature="isAvailableMailTemplateFeature"
          :sender-data="senderData"
          @update:model-value="handleUpdateFormValuesCommon"
          @update:is-valid="handleUpdateIsValidFormValuesCommon"
          @close-openai="handleOpenAiCloseModal"
        >
          <ReservedAt
            v-model:reserved-at="reservedAt"
            @update:is-valid="handleUpdateIsValidReservedAt"
          />
        </FormDrawerBodyCommon>
      </template>
      <template #drawerFooter>
        <BLayout justify-center>
          <BBtn
            class="mr-100"
            type="primary"
            :loading="isLoading"
            :disabled="!isValid"
            @click="handleClickSaveDraft"
          >
            {{ $t('mail.saveDraft') }}
          </BBtn>
          <BBtn
            flat
            @click="emitCancel"
          >
            {{ $t('general.close.text') }}
          </BBtn>
        </BLayout>
      </template>
    </MailDrawerTemplate>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import ReservedAt from '@/components/organisms/user/mail/common/drawer/input/ReservedAt.vue'
import MailDrawerTemplate from '@/components/organisms/user/mail/common/drawer/MailDrawerTemplate.vue'
import { formatDateTime } from '@/utils/date-time';
import { TPartialFormLeadDirectMail, SenderData } from '../types';
import FormDrawerBodyCommon from './FormDrawerBodyCommon.vue';

type TProps = {
  modelValue: TPartialFormLeadDirectMail;
  isLoading: boolean;
  callTargetId: number | null;
  title: string;
  isAvailableMailTemplateFeature: boolean;
  senderData: SenderData;
};
type TEmit = {
  (event: 'update:modelValue', value: TPartialFormLeadDirectMail): void;
  (event: 'cancel'): void;
  (event: 'save-draft'): void;
  (event: 'change-current-state-to-confirm'): void;
  (event: 'close-openai', cancel: () => void): void;
};

const props =  defineProps<TProps>();
const emit = defineEmits<TEmit>();

const isValidFormValuesCommon = ref(false);
const isValidReservedAt = ref(false);

const reservedAt = computed({
  get: () => props.modelValue.reservedAt,
  set: (v) => updateFormValues('reservedAt', formatDateTime(v)),
});

const isValid = computed(() => isValidFormValuesCommon.value && isValidReservedAt.value);

const handleUpdateFormValuesCommon = (newValue: TPartialFormLeadDirectMail) => {
  emit('update:modelValue', newValue)
};
const updateFormValues = (key: keyof TPartialFormLeadDirectMail, value: unknown) => {
  emit('update:modelValue', {
    ...props.modelValue,
    [key]: value,
  })
};
const handleUpdateIsValidFormValuesCommon = (value: boolean) => {
  isValidFormValuesCommon.value = value;
};
const handleUpdateIsValidReservedAt = (value: boolean) => {
  isValidReservedAt.value = value;
};
const emitCancel = () => {
  emit('cancel')
};
const handleClickSaveDraft = () => {
  emit('save-draft')
};
const handleClickConfirm = () => {
  emit('change-current-state-to-confirm')
};
const handleOpenAiCloseModal = (cancel: () => void) => {
  emit('close-openai', cancel);
};
</script>

<style lang="scss" scoped>
.form-drawer {
  height: 100%
}
</style>

<template>
  <div class="lead-list-control">
    <BContainer
      v-if="Object.keys(callTargetsMeta || {}).length > 0"
      class="lead-list-control-inner"
      size="large"
    >
      <div class="display-range mr-200">
        <span>{{ displayRange }}</span>
      </div>
      <slot />
      <BPagination
        v-model="currentPage"
        class="ml-a"
        :length="allPageCount"
        :total-visible="5"
        circle
      />
    </BContainer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import { useAvailableFeatureCheck } from '@/composable/available-feature-check'
import queryParameter from '@/mixins/query_parameter'

export default defineComponent({
  mixins: [queryParameter],
  setup() {
    const { isAvailableBulkMailFeature } = useAvailableFeatureCheck()
    return {
      isAvailableBulkMailFeature,
    }
  },
  computed: {
    ...mapGetters('user', [
      'callTargets',
      'callTargetsMeta',
      'customFields',
      'leadView',
    ]),
    ...mapGetters('userUi', [
      'leadListPageParams',
      'leadListFilterParams',
    ]),
    currentPage: {
      get() {
        return this.leadListPageParams?.currentPage ? this.leadListPageParams.currentPage : 1
      },
      set(newVal) {
        const queryParams = {
          currentPage: newVal,
          currentDisplayNumber: this.currentDisplayNumber,
        }
        this.updateQueryParamsAndFetchCallTargets(queryParams)
      },
    },
    currentDisplayNumber: {
      get() {
        return this.leadListPageParams?.currentDisplayNumber
          ? this.leadListPageParams.currentDisplayNumber
          : 100
      },
      set(newVal) {
        const queryParams = {
          currentPage: 1,
          currentDisplayNumber: newVal,
        }
        this.updateQueryParamsAndFetchCallTargets(queryParams)
      },
    },
    begin() {
      return this.currentDisplayNumber * (this.currentPage - 1)
    },
    end() {
      return this.begin + this.currentDisplayNumber
    },
    displayBegin() {
      return this.begin + 1
    },
    displayEnd() {
      return Math.min(this.end, this.callTargetsMeta?.totalCount || 0)
    },
    allPageCount() {
      if (this.callTargetsMeta?.totalCount == null) return 0
      return Math.ceil(this.callTargetsMeta.totalCount / this.currentDisplayNumber)
    },
    displayRange() {
      if (this.displayEnd == 0) {
        return '0 - 0 / 0'
      }
      return `${this.displayBegin} - ${this.displayEnd} / ${this.callTargetsMeta?.totalCount}`
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      getCallTargetsAction: 'getCallTargetsWait',
      getCallTargetsMetaAction: 'getCallTargetsMetaWait',
    }),
    ...mapWaitingActions('userUi', {
      setLeadListFilterParamsAction: 'setLeadListFilterParamsWait',
      setLeadListPageParamsAction: 'setLeadListPageParamsWait',
    }),
    updateQueryParamsAndFetchCallTargets(queryParams) {
      this.setLeadListPageParamsAction(queryParams)
      this.setQueryParameter(queryParams)
      this.getCallTargetsAction()
      this.getCallTargetsMetaAction()
    },
  },
})
</script>

<style lang="scss" scoped>
.lead-list-control {
  @include m-fixed-height(60px);
  display: flex;
  align-items: center;
  border-top: 1px solid $bdcolor-light;
  border-bottom: 1px solid $bdcolor-light;

  &-inner {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.display-count-control {
  width: 80px;
}
</style>

<template>
  <BContentBox
    class="pb-800"
    is-footer
  >
    <template #content>
      <div class="content error-confirm">
        <BContainer>
          <div class="error-message mb-300">
            <div class="error-title-area">
              <div class="error-icon" />
              <div class="error-title">
                {{ $t('salesforceScheduleImport.list.import.error') }}
              </div>
            </div>
            <div>
              <span class="break-word-pre-wrap">{{ title() }}</span>
            </div>
            <BBtn
              class="sf-link"
              size="medium"
              flat
              @click="open(sfLink)"
            >
              {{ $t('salesforceScheduleImport.sfLink') }}
              <BIcon
                type="white"
              >
                open_in_new
              </BIcon>
            </BBtn>
          </div>
          <div
            v-if="importError != null"
            class="pt-200"
          >
            {{ $t('import.errors.systemColon') }} {{ importError }}
          </div>
          <div
            v-else-if="importMappingErrors && importMappingErrors.length > 0"
          >
            <div
              v-for="(error, index) in importMappingErrors"
              :key="index"
              class="error-row"
            >
              <div>{{ error.split('：')[0] }}</div>
              <div>{{ error.split('：')[1] }}</div>
            </div>
          </div>
          <ElTable
            v-else-if="importErrors != null && importErrors.length > 0"
            :data="importErrors"
            size="small"
          >
            <ElTableColumn type="expand">
              <template #default="props">
                <div
                  v-for="[key, val] in Object.entries(props.row.record)"
                  :key="key"
                  class="row-data"
                >
                  <BLayout
                    :key="`layout-${key}`"
                    align-center
                  >
                    <div
                      class="row-header"
                      :class="{ 'error-text': val.errors.length > 0 }"
                    >
                      {{ val.header }}
                    </div>
                    <div :class="{ 'error-text': val.errors.length > 0 }">
                      {{ getPropertyFallBackAltText(val, 'value') }}
                    </div>
                  </BLayout>
                </div>
              </template>
            </ElTableColumn>
            <ElTableColumn
              v-for="(header, index) in importErrorTargetHeaders"
              :key="index"
              :label="header.label"
            >
              <template #default="props">
                <div>{{ getTargetTitle(props.row.record, header.prop) }}</div>
              </template>
            </ElTableColumn>
            <ElTableColumn :label="$t('import.errors.content')">
              <template #default="props">
                <div
                  v-for="error in summaryErrors(props.row.record)"
                  :key="error"
                >
                  {{ error }}
                </div>
              </template>
            </ElTableColumn>
          </ElTable>
        </BContainer>
      </div>
    </template>
    <template #footer>
      <div class="footer">
        <BBtn
          size="medium"
          @click="backAction"
        >
          <BIcon
            size="medium"
          >
            keyboard_arrow_left
          </BIcon>
          {{ $t("sfObjectMappings.back") }}
        </BBtn>
        <BBtn
          size="medium"
          type="primary"
          @click="enableRetry"
        >
          {{ $t("salesforceScheduleImport.list.import.retry") }}
        </BBtn>
      </div>
    </template>
  </BContentBox>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { SfReportImportSettingsService } from '@/api/user/resources/salesforce/sf_report_import_settings';
import { altText } from '@/utils/alt';

const props = withDefaults(defineProps<TProps>(), {});

const emit = defineEmits(['back']);

const i18n = useI18n();
const sfApi = new SfReportImportSettingsService();

type TProps = {
  modelId: number;
  importErrors?: object[];
  importError?: string;
  importMappingErrors?: string[];
  sfLink?: string;
};
const backAction = () => {
  emit('back');
}

const importErrorTargetHeaders = () => {
  return [
    { label: i18n.t('import.columns.call_target_company_name'), prop: 'call_target_company_name' },
  ]
}

const summaryErrors = (record: object) => {
  return Object.values(record).flatMap((value) => {
    return value.errors.map(e => `${value.header} - ${e}`)
  })
}

const getPropertyFallBackAltText = (obj: object, property: string) => {
  return altText(obj?.[property])
}

const getTargetTitle = (record: object, target: string) => {
  return Object.values(record).find((r) => r.label === target)?.value || '-'
}

const open = (url: string) => {
  window.open(url, '_blank')
}

const enableRetry = async () => {
  await sfApi.sfReportImportSettingsEnable(
    { request: { sfReportImportSettingId: props.modelId } },
  );
  window.location.reload();
}

const title = () => {
  if (props.importMappingErrors && props.importMappingErrors.length) {
    return `${props.importMappingErrors[0]}\n${i18n.t('salesforceScheduleImport.list.import.errorMapping')}`;
  }
  if (props.importErrors && props.importErrors.length) {
    return i18n.t('import.errorMessage');
  }
  return `${props.importError}\n${i18n.t('salesforceScheduleImport.list.import.errorMapping')}`;
}
</script>

<style lang="scss" scoped>
.error-confirm {
  padding-bottom: 120px;
}

.error-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $basecolor-error;
  font-size: $fontsize-200;
  padding: $basespace-200 $basespace-600;
  background-color: rgba($basecolor-error, 0.1);
  border-radius: 4px;

  .error-title-area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .error-icon {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #FF6465;
    }
    .error-title {
      color: #000000;
      font-size: 14px;
      font-weight: 400;
      margin: 0 8px;
    }
  }
}

.row-data {
  padding: $basespace-100 0;
}

.row-header {
  color: $textcolor-light;
  @include m-fixed-width(200px);
  padding-right: $basespace-400;
}

.error-text {
  color: $basecolor-error;
}

.sf-link {
  background-color: #FF6465 !important;
  color: #FFFFFF !important;
}

.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 310px);
  height: 63px;
  background-color: #F6F6F6;;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  > button {
    margin: 0 12px;
  }
}
.error-row {
  display: flex;
  border-bottom: 1px solid #E3E3E3;
  justify-content: flex-start;
  padding: 20px 0;
  font-size: 14px;
  > div {
    width: 200px;
    text-align: left;
  }
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, unref as _unref } from "vue"

const _hoisted_1 = { class: "theader" }
const _hoisted_2 = { class: "table-row theader-row" }
const _hoisted_3 = { class: "tbody" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { class: "rate" }
const _hoisted_7 = { class: "rate" }
const _hoisted_8 = { class: "rate" }
const _hoisted_9 = { class: "rate" }
const _hoisted_10 = { class: "rate" }

import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router';
import { SequenceMaster } from '@/api/openapi';
import { SequenceMasterApiService } from '@/api/user/resources/sequence_master';
import { useUsers } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { KEY_OF_MODAL } from '@/injection-keys';
import { bitterAlert } from '@/plugins/BBitterAlert';


export const KEY_OF_DELETE_SEQUENCE_MASTER_WAIT = 'deleteSequenceMasterWait';
export const KEY_OF_CHANGE_SEQUENCE_MASTER_OWNER_WAIT = 'changeSequenceMasterOwnerWait';

type TProps = {
  sequenceMasters: SequenceMaster[];
};
type TEmits = {
  (e: 'afterDelete'): void;
  (e: 'afterChangeOwner'): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SequenceMasterList',
  props: {
    sequenceMasters: {}
  },
  emits: ["afterDelete", "afterChangeOwner"],
  setup(__props: any, { emit: __emit }) {


const emit = __emit;

const router = useRouter();

const modal = inject(KEY_OF_MODAL);
const i18n = useI18n();
const api = new SequenceMasterApiService();
const selectedSequenceMasterId = ref<number>();
const {
  doActionWithWait,
} = useWait();

const headers = [
  { key: 'name', name: i18n.t('sequence.name'), class: ['name-container'] },
  { key: 'summary.all', name: i18n.t('sequence.summary.all'), class: ['summary'] },
  { key: 'summary.registered', name: i18n.t('sequence.summary.registered'), class: ['summary'] },
  { key: 'summary.stopped', name: i18n.t('sequence.summary.stopped'), class: ['summary'] },
  { key: 'summary.done', name: i18n.t('sequence.summary.done'), class: ['summary'] },
  { key: 'summary.associatedOpportunity', name: i18n.t('sequence.summary.associatedOpportunity'), class: ['summary'] },
  { key: 'summary.unregistered', name: i18n.t('sequence.summary.unregistered'), class: ['summary'] },
  { key: 'divider1', name: '', class: ['column-divider'] },
  { key: 'mailAggregates.all', name: i18n.t('sequence.mailAggregates.all'), class: ['summary', 'mail-aggregate'], info: i18n.t('sequence.mailAggregates.allInfo') },
  { key: 'mailAggregates.sent', name: i18n.t('sequence.mailAggregates.sent'), class: ['summary', 'mail-aggregate'], info: i18n.t('sequence.mailAggregates.sentInfo') },
  { key: 'mailAggregates.opened', name: i18n.t('sequence.mailAggregates.opened'), class: ['summary', 'mail-aggregate'], info: i18n.t('sequence.mailAggregates.openedInfo') },
  { key: 'mailAggregates.clicked', name: i18n.t('sequence.mailAggregates.clicked'), class: ['summary', 'mail-aggregate'], info: i18n.t('sequence.mailAggregates.clickedInfo') },
  { key: 'mailAggregates.replied', name: i18n.t('sequence.mailAggregates.replied'), class: ['summary', 'mail-aggregate'], info: i18n.t('sequence.mailAggregates.repliedInfo') },
  { key: 'mailAggregates.failed', name: i18n.t('sequence.mailAggregates.failed'), class: ['summary', 'mail-aggregate'], info: i18n.t('sequence.mailAggregates.failedInfo') },
  { key: 'divider2', name: '', class: ['column-divider'] },
  { key: 'createdUser', name: i18n.t('sequence.createdUser'), class: ['user'] },
  { key: 'updatedUser', name: i18n.t('sequence.updatedUser'), class: ['user'] },
  { key: 'owner', name: i18n.t('sequence.owner'), class: ['user'] },
];

const displayRate = (rate: number) => `(${rate.toFixed(1)}%)`;
const moveToEdit = (id: number) => router.push({ name: 'Sequence', params: { id } });
const moveToDuplicate = (duplicateId: number) => router.push({ name: 'SequenceNew', query: { duplicateId } });
const { activeUsers } = useUsers();
const handleDeleteButtonClick = async (sequenceMaster: SequenceMaster) => {
  const ok = await bitterAlert.show({
    text: i18n.t('sequence.message.confirmWhetherToDelete', { target: sequenceMaster.name }),
  });
  if (!ok) return;
  await deleteSequenceMaster(sequenceMaster.id);
  emit('afterDelete');
};

const deleteSequenceMaster = async (sequenceMasterId: number) => {
  await doActionWithWait(KEY_OF_DELETE_SEQUENCE_MASTER_WAIT, async () => {
    await api.deleteSequenceMaster({
      request: { sequenceMasterId },
    });
  });
};

const handleSetOperatorModal = async (sequenceMasterId: number) => {
  selectedSequenceMasterId.value = sequenceMasterId;
  modal.show('setOperatorInSequenceInMasterList')
}

const assignOperator = async (ownerId: number) => {
  const ok = await bitterAlert.show({
    text: i18n.t('sequence.message.confirmChangeSequenceOwner'),
  });
  if (!ok) {
    modal.hide('setOperatorInSequenceInMasterList');
    return;
  }
  
  await doActionWithWait(KEY_OF_CHANGE_SEQUENCE_MASTER_OWNER_WAIT, async () => {
    await api.changeSequenceMasterOwner({
      request: {
        changeSequenceMasterOwnerBody: {
          sequenceMasterId: selectedSequenceMasterId.value,
          ownerId,
        },
      },
    })
  });
  modal.hide('setOperatorInSequenceInMasterList');
  emit('afterChangeOwner');
}

return (_ctx: any,_cache: any) => {
  const _component_BSortText = _resolveComponent("BSortText")!
  const _component_BCustomIcon = _resolveComponent("BCustomIcon")!
  const _component_BTooltip = _resolveComponent("BTooltip")!
  const _component_BUserTooltip = _resolveComponent("BUserTooltip")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BPopover = _resolveComponent("BPopover")!
  const _component_BMoreMenu = _resolveComponent("BMoreMenu")!
  const _component_SetOperator = _resolveComponent("SetOperator")!
  const _component_BModal = _resolveComponent("BModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(headers, (cell) => {
          return _createVNode(_component_BSortText, {
            key: cell.key,
            class: _normalizeClass(["table-cell header-cell", cell.class]),
            "item-text": cell.name,
            info: cell.info,
            "is-display-only": ""
          }, null, 8, ["class", "item-text", "info"])
        }), 64)),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "table-cell menu" }, null, -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sequenceMasters, (sequenceMaster) => {
        return (_openBlock(), _createElementBlock("div", {
          key: sequenceMaster.id,
          class: "table-row tbody-row",
          onClick: () => moveToEdit(sequenceMaster.id)
        }, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(headers, (column) => {
            return _createElementVNode("div", {
              key: column.key,
              class: _normalizeClass(["table-cell", column.class])
            }, [
              (column.key === 'name')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(sequenceMaster.name), 1),
                    _createVNode(_component_BTooltip, {
                      top: "",
                      content: _ctx.$t('general.duplicate.do')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BCustomIcon, {
                          size: "small",
                          "icon-class": "b-clone",
                          hover: "",
                          onClick: _withModifiers(() => moveToDuplicate(sequenceMaster.id), ["stop"])
                        }, null, 8, ["onClick"])
                      ]),
                      _: 2
                    }, 1032, ["content"])
                  ], 64))
                : (column.key === 'summary.all')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(sequenceMaster.numberOfInstances), 1)
                    ], 64))
                  : (column.key === 'summary.registered')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _createTextVNode(_toDisplayString(sequenceMaster.numberOfRegistered), 1)
                      ], 64))
                    : (column.key === 'summary.stopped')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                          _createTextVNode(_toDisplayString(sequenceMaster.numberOfStopped), 1)
                        ], 64))
                      : (column.key === 'summary.done')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                            _createTextVNode(_toDisplayString(sequenceMaster.numberOfDone), 1)
                          ], 64))
                        : (column.key === 'summary.associatedOpportunity')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                              _createTextVNode(_toDisplayString(sequenceMaster.numberOfOpportunities), 1)
                            ], 64))
                          : (column.key === 'summary.unregistered')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                                _createTextVNode(_toDisplayString(sequenceMaster.numberOfUnregistered), 1)
                              ], 64))
                            : (column.key === 'mailAggregates.all')
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
                                  _createTextVNode(_toDisplayString(sequenceMaster.mailAggregates.numberOfAll), 1)
                                ], 64))
                              : (column.key === 'mailAggregates.sent')
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 8 }, [
                                    _createTextVNode(_toDisplayString(sequenceMaster.mailAggregates.numberOfSent) + " ", 1),
                                    _createElementVNode("div", _hoisted_6, _toDisplayString(displayRate(sequenceMaster.mailAggregates.sentPerAll)), 1)
                                  ], 64))
                                : (column.key === 'mailAggregates.opened')
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 9 }, [
                                      _createTextVNode(_toDisplayString(sequenceMaster.mailAggregates.numberOfOpened) + " ", 1),
                                      _createElementVNode("div", _hoisted_7, _toDisplayString(displayRate(sequenceMaster.mailAggregates.openedPerSent)), 1)
                                    ], 64))
                                  : (column.key === 'mailAggregates.clicked')
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 10 }, [
                                        _createTextVNode(_toDisplayString(sequenceMaster.mailAggregates.numberOfClicked) + " ", 1),
                                        _createElementVNode("div", _hoisted_8, _toDisplayString(displayRate(sequenceMaster.mailAggregates.clickedPerSent)), 1)
                                      ], 64))
                                    : (column.key === 'mailAggregates.replied')
                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 11 }, [
                                          _createTextVNode(_toDisplayString(sequenceMaster.mailAggregates.numberOfReplied) + " ", 1),
                                          _createElementVNode("div", _hoisted_9, _toDisplayString(displayRate(sequenceMaster.mailAggregates.repliedPerSent)), 1)
                                        ], 64))
                                      : (column.key === 'mailAggregates.failed')
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 12 }, [
                                            _createTextVNode(_toDisplayString(sequenceMaster.mailAggregates.numberOfFailed) + " ", 1),
                                            _createElementVNode("div", _hoisted_10, _toDisplayString(displayRate(sequenceMaster.mailAggregates.failedPerAll)), 1)
                                          ], 64))
                                        : (column.key === 'createdUser')
                                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 13 }, [
                                              (sequenceMaster.createdUser)
                                                ? (_openBlock(), _createBlock(_component_BUserTooltip, {
                                                    key: 0,
                                                    "image-url": sequenceMaster.createdUser.avatarUrl,
                                                    name: sequenceMaster.createdUser.name,
                                                    "user-id": sequenceMaster.createdUser.id,
                                                    size: 40
                                                  }, null, 8, ["image-url", "name", "user-id"]))
                                                : _createCommentVNode("", true)
                                            ], 64))
                                          : (column.key === 'updatedUser')
                                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 14 }, [
                                                (sequenceMaster.updatedUser)
                                                  ? (_openBlock(), _createBlock(_component_BUserTooltip, {
                                                      key: 0,
                                                      "image-url": sequenceMaster.updatedUser.avatarUrl,
                                                      name: sequenceMaster.updatedUser.name,
                                                      "user-id": sequenceMaster.updatedUser.id,
                                                      size: 40
                                                    }, null, 8, ["image-url", "name", "user-id"]))
                                                  : _createCommentVNode("", true)
                                              ], 64))
                                            : (column.key === 'owner')
                                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 15 }, [
                                                  (sequenceMaster.owner)
                                                    ? (_openBlock(), _createBlock(_component_BUserTooltip, {
                                                        key: 0,
                                                        "image-url": sequenceMaster.owner.avatarUrl,
                                                        name: sequenceMaster.owner.name,
                                                        "user-id": sequenceMaster.owner.id,
                                                        size: 40
                                                      }, null, 8, ["image-url", "name", "user-id"]))
                                                    : _createCommentVNode("", true)
                                                ], 64))
                                              : _createCommentVNode("", true)
            ], 2)
          }), 64)),
          _createElementVNode("div", {
            class: "table-cell menu",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }, [
            _createVNode(_component_BMoreMenu, { "z-index100": true }, {
              default: _withCtx(() => [
                _createVNode(_component_BPopover, {
                  placement: "top",
                  width: "240",
                  trigger: "hover",
                  disabled: sequenceMaster.deletable,
                  content: _ctx.$t('general.alreadyUsed')
                }, {
                  reference: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createVNode(_component_BBtn, {
                        flat: "",
                        fit: "",
                        size: "small",
                        disabled: !sequenceMaster.deletable,
                        onClick: () => handleDeleteButtonClick(sequenceMaster)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.delete.text')), 1)
                        ]),
                        _: 2
                      }, 1032, ["disabled", "onClick"])
                    ])
                  ]),
                  _: 2
                }, 1032, ["disabled", "content"]),
                _createElementVNode("div", null, [
                  _createVNode(_component_BBtn, {
                    flat: "",
                    fit: "",
                    size: "small",
                    onClick: () => handleSetOperatorModal(sequenceMaster.id)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('sequence.owner')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_BModal, {
              "modal-name": "setOperatorInSequenceInMasterList",
              width: "380px",
              height: "100px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SetOperator, {
                  "current-operator-id": sequenceMaster.owner?.id,
                  "operator-users": _unref(activeUsers),
                  "needs-withdraw-option": false,
                  onAssignOperator: assignOperator
                }, null, 8, ["current-operator-id", "operator-users"])
              ]),
              _: 2
            }, 1024)
          ])
        ], 8, _hoisted_4))
      }), 128))
    ])
  ], 64))
}
}

})
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "home-block" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "home-message-block" }
const _hoisted_4 = { class: "message fs-500 fw-bold" }
const _hoisted_5 = { class: "sub-message fw-bold" }
const _hoisted_6 = { class: "section mb-300" }
const _hoisted_7 = { class: "section-header" }
const _hoisted_8 = { class: "section-title mr-200" }
const _hoisted_9 = { class: "section-body" }
const _hoisted_10 = { class: "lead-view-icon" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "lead-view-name" }
const _hoisted_13 = { class: "fw-bold mb-50" }
const _hoisted_14 = { class: "text-annotation" }
const _hoisted_15 = { class: "section" }
const _hoisted_16 = { class: "section-body" }
const _hoisted_17 = { class: "column-headers" }
const _hoisted_18 = { class: "lead-view-name" }
const _hoisted_19 = { class: "lead-view-date" }
const _hoisted_20 = { class: "lead-view-user" }
const _hoisted_21 = { class: "lead-view-icon" }
const _hoisted_22 = ["src"]
const _hoisted_23 = { class: "lead-view-name" }
const _hoisted_24 = { class: "fw-bold mb-50" }
const _hoisted_25 = { class: "text-annotation break-word-pre-wrap" }
const _hoisted_26 = { class: "lead-view-date" }
const _hoisted_27 = { class: "lead-view-user" }
const _hoisted_28 = { class: "break-word-pre-wrap" }
const _hoisted_29 = { class: "section-header" }
const _hoisted_30 = { class: "section-title mr-200" }
const _hoisted_31 = { class: "section-body" }
const _hoisted_32 = { class: "card-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notification = _resolveComponent("Notification")!
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BCard = _resolveComponent("BCard")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_BPlaceholder = _resolveComponent("BPlaceholder")!
  const _component_BUserTooltip = _resolveComponent("BUserTooltip")!
  const _component_BPopover = _resolveComponent("BPopover")!
  const _component_BMoreMenu = _resolveComponent("BMoreMenu")!
  const _component_BEmptyBox = _resolveComponent("BEmptyBox")!
  const _component_VWait = _resolveComponent("VWait")!
  const _component_LeadViewUpdateModal = _resolveComponent("LeadViewUpdateModal")!
  const _component_BFlex = _resolveComponent("BFlex")!
  const _component_BAvatarText = _resolveComponent("BAvatarText")!
  const _component_BContainer = _resolveComponent("BContainer")!
  const _component_BContent = _resolveComponent("BContent")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_BContent, { "unvisible-navbar": "" }, {
    default: _withCtx(() => [
      _createVNode(_component_Notification, { display: "home" }),
      _createElementVNode("div", {
        class: "home-hero",
        style: _normalizeStyle(_ctx.styles)
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("img", {
            src: require('@/assets/bales_cloud_fire.svg'),
            alt: ""
          }, null, 8, _hoisted_2),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('home.message')), 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('home.subMessage')), 1)
          ])
        ])
      ], 4),
      _createVNode(_component_BContainer, { class: "pt-600" }, {
        default: _withCtx(() => [
          _createVNode(_component_BLayout, null, {
            default: _withCtx(() => [
              _createVNode(_component_BFlex, {
                class: "pr-400",
                span: 16
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('leadList.title')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_RouterLink, {
                        class: "link-card",
                        to: { name: 'LeadList' }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_BCard, {
                            border: "",
                            transition: "",
                            "data-test": "leadList",
                            onClick: _ctx.clickHome
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_BLayout, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_10, [
                                    _createElementVNode("img", {
                                      src: require('@/assets/icon_lead_list.svg')
                                    }, null, 8, _hoisted_11)
                                  ]),
                                  _createElementVNode("div", _hoisted_12, [
                                    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('leadList.allTitle')), 1),
                                    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('leadList.allDescription')), 1)
                                  ])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_BLayout, null, {
                          default: _withCtx(() => [
                            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "lead-view-icon" }, null, -1)),
                            _createElementVNode("div", _hoisted_18, [
                              _createVNode(_component_BBtn, {
                                text: "",
                                size: "small",
                                onClick: _ctx.changeSortOrder
                              }, {
                                default: _withCtx(() => [
                                  _cache[2] || (_cache[2] = _createElementVNode("span", null, "リスト名", -1)),
                                  (_ctx.leadViewSortKey.key === 'name')
                                    ? (_openBlock(), _createBlock(_component_BIcon, {
                                        key: 0,
                                        class: _normalizeClass(["b-sort-icon", { 'b-sort-desc': _ctx.leadViewSortKey.order === 'desc' }]),
                                        size: "small"
                                      }, {
                                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                                          _createTextVNode(" arrow_downward ")
                                        ])),
                                        _: 1
                                      }, 8, ["class"]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _createVNode(_component_BLayout, {
                              class: "ml-a",
                              "justify-end": "",
                              "align-center": ""
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('leadView.updatedAt')), 1),
                                _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('leadView.updatedUser')), 1),
                                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "lead-view-action" }, null, -1))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _createVNode(_component_VWait, { for: "getLeadViewsWait" }, {
                        waiting: _withCtx(() => [
                          _withDirectives(_createVNode(_component_BPlaceholder, {
                            height: "20vh",
                            "element-loading-background": "transparent"
                          }, null, 512), [
                            [_directive_loading, true]
                          ])
                        ]),
                        default: _withCtx(() => [
                          (_ctx.sortedLeadViews.length > 0)
                            ? (_openBlock(), _createBlock(_TransitionGroup, {
                                key: 0,
                                class: "column layout",
                                name: "list",
                                tag: "div"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedLeadViews, (leadView) => {
                                    return (_openBlock(), _createBlock(_component_RouterLink, {
                                      key: 'leadView' + leadView.id,
                                      class: "link-card",
                                      to: { name: 'LeadList', query: { leadViewId: leadView.id} }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_BCard, {
                                          class: "mb-200",
                                          border: "",
                                          transition: ""
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_BLayout, { "align-center": "" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("div", _hoisted_21, [
                                                  _createElementVNode("img", {
                                                    src: require('@/assets/icon_lead_view.svg')
                                                  }, null, 8, _hoisted_22)
                                                ]),
                                                _createElementVNode("div", _hoisted_23, [
                                                  _createElementVNode("div", _hoisted_24, _toDisplayString(leadView.name), 1),
                                                  _createElementVNode("div", _hoisted_25, _toDisplayString(leadView.description), 1)
                                                ]),
                                                _createVNode(_component_BLayout, {
                                                  class: "ml-a",
                                                  "justify-end": "",
                                                  "align-center": ""
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.getDateAndTimeWithFallBack(leadView.updatedAt)), 1),
                                                    _createElementVNode("div", _hoisted_27, [
                                                      _createVNode(_component_BUserTooltip, {
                                                        "image-url": leadView.updatedUserAvatarUrl,
                                                        name: leadView.updatedUserName,
                                                        "user-id": leadView.updatedUserId,
                                                        size: 36
                                                      }, null, 8, ["image-url", "name", "user-id"])
                                                    ]),
                                                    _createElementVNode("div", {
                                                      class: "lead-view-action",
                                                      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                                                    }, [
                                                      _createVNode(_component_BMoreMenu, {
                                                        trigger: "hover",
                                                        "z-index100": true
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_BBtn, {
                                                            flat: "",
                                                            fit: "",
                                                            size: "small",
                                                            onClick: () => _ctx.handleOpenUpdateModal(leadView)
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createTextVNode(_toDisplayString(_ctx.$t('general.edit.do')), 1)
                                                            ]),
                                                            _: 2
                                                          }, 1032, ["onClick"]),
                                                          (leadView.isUsedByReservedBulkMail)
                                                            ? (_openBlock(), _createBlock(_component_BPopover, {
                                                                key: 0,
                                                                placement: "top",
                                                                width: "240",
                                                                trigger: "hover"
                                                              }, {
                                                                reference: _withCtx(() => [
                                                                  _createElementVNode("div", null, [
                                                                    _createVNode(_component_BBtn, {
                                                                      flat: "",
                                                                      fit: "",
                                                                      size: "small",
                                                                      disabled: ""
                                                                    }, {
                                                                      default: _withCtx(() => [
                                                                        _createTextVNode(_toDisplayString(_ctx.$t('general.delete.do')), 1)
                                                                      ]),
                                                                      _: 1
                                                                    })
                                                                  ])
                                                                ]),
                                                                default: _withCtx(() => [
                                                                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('mail.alreadyUsedReserve')), 1)
                                                                ]),
                                                                _: 1
                                                              }))
                                                            : (_openBlock(), _createBlock(_component_BBtn, {
                                                                key: 1,
                                                                flat: "",
                                                                fit: "",
                                                                size: "small",
                                                                onClick: () => _ctx.removeLeadView(leadView.id)
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString(_ctx.$t('general.delete.do')), 1)
                                                                ]),
                                                                _: 2
                                                              }, 1032, ["onClick"]))
                                                        ]),
                                                        _: 2
                                                      }, 1024)
                                                    ])
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["to"]))
                                  }), 128))
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_BEmptyBox, {
                                key: 1,
                                class: "px-600 py-600",
                                "display-only": ""
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t('leadView.empty')), 1)
                                ]),
                                _: 1
                              }))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_LeadViewUpdateModal, {
                        ref: "leadViewUpdateModal",
                        "lead-view": _ctx.selectedLeadView,
                        delegate: _ctx.leadViewUpdateModalDelegate
                      }, null, 8, ["lead-view", "delegate"])
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_BFlex, { span: 8 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t('home.member')), 1),
                    _createVNode(_component_RouterLink, {
                      class: "link-card",
                      to: { name: 'Setting', state: { selectTab: 'member', selectTabTitle: _ctx.$t('corpSettingApi.member') } }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BBtn, {
                          size: "small",
                          fab: "",
                          flat: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BIcon, { size: "small" }, {
                              default: _withCtx(() => _cache[5] || (_cache[5] = [
                                _createTextVNode(" settings ")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ]),
                  _createElementVNode("div", _hoisted_31, [
                    _createVNode(_component_BCard, {
                      small: "",
                      border: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_VWait, { for: "getUsersWait" }, {
                          waiting: _withCtx(() => [
                            _withDirectives(_createVNode(_component_BPlaceholder, {
                              height: "20vh",
                              "element-loading-background": "transparent"
                            }, null, 512), [
                              [_directive_loading, true]
                            ])
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_32, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeUsers, (user) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: `user-${user.id}`,
                                  class: "user-list-item"
                                }, [
                                  _createVNode(_component_BAvatarText, {
                                    text: user.name,
                                    "sub-text": user.email,
                                    "image-url": user.avatarUrl,
                                    "user-id": user.id,
                                    truncate: ""
                                  }, null, 8, ["text", "sub-text", "image-url", "user-id"])
                                ]))
                              }), 128))
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
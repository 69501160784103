import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ValueOf } from 'element-plus/es/components/table/src/table-column/defaults';
import { computed } from 'vue';
import { TriggerTargetStatus } from '@/api/openapi';
import COLOR_CODES from '@/const/color_codes';

type TColor = { status: TriggerTargetStatus, colorCode: ValueOf<typeof COLOR_CODES> };

type TProps = {
  value: TriggerTargetStatus;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'TriggerTargetStatusPreview',
  props: {
    value: {}
  },
  emits: ['click'],
  setup(__props: any) {

const props = __props;



const colors: TColor[] = [
  {
    status: 'ready',
    colorCode: COLOR_CODES.GAIA,
  },
  {
    status: 'executing',
    colorCode: COLOR_CODES.VENUS,
  },
  {
    status: 'completed',
    colorCode: COLOR_CODES.URANUS,
  },
  {
    status: 'failed',
    colorCode: COLOR_CODES.MARS,
  },
  {
    status: 'stopped',
    colorCode: COLOR_CODES.NEPTUNE,
  },
];
const colorCode = computed(() => colors.find(c => c.status === props.value)?.colorCode);

return (_ctx: any,_cache: any) => {
  const _component_BColorPreview = _resolveComponent("BColorPreview")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BColorPreview, {
      class: "mr-50",
      "color-code": colorCode.value,
      size: "small"
    }, null, 8, ["color-code"]),
    _createTextVNode(" " + _toDisplayString(_ctx.$t(`trigger.target.status.enum.${props.value}`)), 1)
  ], 64))
}
}

})
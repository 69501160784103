import Waiting from '@/store/waiting'

export default class CustomFieldActions {
  constructor (Api, types) {
    const actions = {
      async getCustomFieldsAction ({ commit }, args) {
        await waiting.check('getCustomFieldsAction', async () => {
          try {
            const res = await Api.getCustomFields(args)
            commit(types.SET_CUSTOM_FIELDS, Object.values(res.data)[0])
          } catch (err) {
            throw err
          }
        })
      },
      async createCustomFieldAction ({ dispatch }, args) {
        await waiting.check('createCustomFieldAction', async () => {
          await Api.createCustomField(args)
          try {
            await dispatch('getCustomFieldsAction', args)
          } catch (err) {
            throw err
          }
        })
      },
      async updateCustomFieldAction ({ dispatch }, args) {
        await waiting.check('updateCustomFieldAction', async () => {
          await Api.updateCustomField(args)
          try {
            await dispatch('getCustomFieldsAction', args)
          } catch (err) {
            throw err
          }
        })
      },
      async updateCustomFieldsAction ({ dispatch }, args) {
        await waiting.check('updateCustomFieldsAction', async () => {
          await Api.updateCustomFields(args)
          try {
            await dispatch('getCustomFieldsAction', args)
          } catch (err) {
            throw err
          }
        })
      },
      async deleteCustomFieldAction ({ dispatch }, args) {
        await waiting.check('deleteCustomFieldAction', async () => {
          await Api.deleteCustomField(args)
          try {
            await dispatch('getCustomFieldsAction', args)
          } catch (err) {
            throw err
          }
        })
      },
      async deleteCustomFieldOptionAction ({ dispatch }, args) {
        await waiting.check('deleteCustomFieldOptionAction', async () => {
          await Api.deleteCustomFieldOption(args)
          try {
            await dispatch('getCustomFieldsAction', args)
          } catch (err) {
            throw err
          }
        })
      },
    }
    const waiting = new Waiting(actions)
    this.actions = actions
  }
}

<template>
  <div class="sequence-next-action-container">
    <BDrawerMenu
      v-if="sequenceStep"
      v-model="internalIsOpen"
      :title="$t('sequence.step.types.nextAction')"
      @cancel="handleDrawerCancel"
    >
      <BListItem class="mb-300">
        <template #header>
          <span>{{ $t('nextAction.actionType.title') }}</span>
        </template>
        <div class="mt-100">
          {{ nextActionType?.name }}
        </div>
      </BListItem>
      <div
        v-if="doneAt"
        class="time-items"
      >
        <BListItem class="mb-300">
          <template #header>
            <span>{{ $t('nextAction.doneAt') }}</span>
          </template>
          <div class="mt-100">
            {{ doneAt }}
          </div>
        </BListItem>
      </div>
      <BListItem class="mb-300">
        <template #header>
          <span>{{ $t('sequence.nextAction.nextActionContent') }}</span>
        </template>
        <div class="mt-100 next-action-content">
          {{ sequenceStep.nextActionContent }}
        </div>
      </BListItem>
    </BDrawerMenu>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref, watch } from 'vue';
import { NextActionTypeOrDefaultType } from '@/api/user/models/next_action_types';
import { formatDateTime } from '@/utils/date-time';
import { TFormNextActionSequenceStep } from './types';

type TProps = {
  sequenceStep?: TFormNextActionSequenceStep;
  isOpen?: boolean;
  nextActionType?: NextActionTypeOrDefaultType;
};
type TEmits = {
  'click:cancel': [];
};

const props = withDefaults(defineProps<TProps>(), {
  sequenceStep: null,
  isOpen: false,
  nextActionType: null,
});
const emit = defineEmits<TEmits>();
const internalIsOpen = ref(props.isOpen);

const doneAt = computed<string>(() => {
  return formatDateTime(props.sequenceStep?.doneAt);
});

watch(() => props.isOpen, (newValue) => {
  nextTick(() => {
    internalIsOpen.value = newValue;
  })
});

const handleDrawerCancel = () => {
  emit('click:cancel');
};
</script>

<style lang="scss" scoped>
.sequence-next-action-container {
  position: relative;
  height: 100%;

  .time-items {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .footer {
    padding: 0 20px;
    height: 48px;
    background-color: $bgcolor-dark;
    display: flex;
  }

  .next-action-content {
    white-space: pre-wrap;
  }
}
</style>

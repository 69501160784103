import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mail-reminder-header" }
const _hoisted_2 = { class: "mail-reminder-header-item header-title" }
const _hoisted_3 = { class: "mail-reminder-header-item number-of-items" }
const _hoisted_4 = { class: "mail-reminder-body" }

import { onBeforeMount, ref } from 'vue';
import { CallTargetApiGetMailRemindItemsRequest, MailRemindItem } from '@/api/openapi';
import apis from '@/api/user';
import { CallTargetApiService } from '@/api/user/resources/call_target';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReservedMailReminder',
  setup(__props) {

const mailRemindItems = ref<MailRemindItem[]>([]);
const currentUserId = ref<number>();

const handleAfterOpen = () => {
  fetchMasterData();
}

onBeforeMount(async () => {
  await fetchCurrentUser();
  await fetchMasterData();
})

const fetchMailReminder = async () => {
  const userId = currentUserId.value;
  const reserved = true;
  const request: CallTargetApiGetMailRemindItemsRequest = {
    userId,
    reserved,
  }
  const callTargetApi = new CallTargetApiService()
  mailRemindItems.value = (await callTargetApi.getMailRemindItems({ request })).data.mailRemindItems
}

const fetchCurrentUser = async () => {
  const res = await apis.getCurrentUser();
  currentUserId.value = res.data.currentUser.id;
};

const fetchMasterData = async () => {
  await fetchMailReminder();
}

return (_ctx: any,_cache: any) => {
  const _component_BCustomIcon = _resolveComponent("BCustomIcon")!
  const _component_ReservedMailReminderTimeline = _resolveComponent("ReservedMailReminderTimeline")!
  const _component_BIconWithActionMenu = _resolveComponent("BIconWithActionMenu")!

  return (_openBlock(), _createBlock(_component_BIconWithActionMenu, {
    "badge-count": mailRemindItems.value.length,
    "auto-fetch-interval": 30000,
    onAutoFetchIntervalPassed: fetchMailReminder,
    onAfterOpen: handleAfterOpen
  }, {
    icon: _withCtx(() => [
      _createVNode(_component_BCustomIcon, { "icon-class": "b-mail-remind" })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('reservedMailReminder.title')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(mailRemindItems.value.length) + "件", 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ReservedMailReminderTimeline, { items: mailRemindItems.value }, null, 8, ["items"])
      ])
    ]),
    _: 1
  }, 8, ["badge-count"]))
}
}

})
<template>
  <LeadLink
    :call-target-id="callTargetId"
    tag="div"
    class="reminder-item"
  >
    <div class="time">
      {{ callBackReservedAt }}
    </div>
    <div>
      <div class="send-to">
        <div class="company-name">
          {{ item.companyName }}
        </div>
        <div class="send-to-name">
          {{ sendToName }}
        </div>
      </div>
      <div class="subject">
        {{ item.subject }}
      </div>
    </div>
  </LeadLink>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { MailRemindItem } from '@/api/openapi';
import LeadLink from '@/components/organisms/user/general/LeadLink.vue';
import { i18nGlobal as i18n } from '@/i18n';
import { formatShortTime } from '@/utils/date-time';

type TProps = {
  item: MailRemindItem;
};

const props = withDefaults(defineProps<TProps>(), {});

const callTargetId = computed(() => {
  return props.item.callTargetId
})

const callBackReservedAt = computed(() => {
  return formatShortTime(props.item.reservedAt)
})

const sendToName = computed(() => props.item.fullName ? `${props.item.fullName}${i18n.t('reservedMailReminder.nameSuffix')}` : '')
</script>

<style lang="scss" scoped>
$modal-content-width: 466px;
.reminder-item {
  display: flex;
  color: $textcolor-base;
  text-decoration: none;
  padding: $basespace-200;
  height: 74px;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid $bdcolor-light;
  width: 578px;

  &:hover {
    background-color: $bgcolor-base;
  }
  .time {
    color: $textcolor-light;
    font-size: $fontsize-200;
    width: 36px;
    min-width: 36px;
    margin: 0 $basespace-400 0 $basespace-600;
  }
  .send-to {
    width: $modal-content-width;
    display: flex;
    .company-name, .send-to-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .company-name {
      max-width: 70%;
      margin-right: $basespace-200;
    }
    .send-to-name {
      max-width: calc(30% - #{$basespace-200});
    }
  }
  .subject {
    width: $modal-content-width;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 7px;
  }
}
</style>
